/* eslint-disable react/jsx-fragments */
import React from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Icon,
  Button,
  Position,
  Locality,
  Flex,
  Tooltip,
  Input,
  Text,
  Spacing
} from '@ui'
import {
  calendarUtil,
  miscUtil,
  timeUtil,
  numberUtil,
  multiSelectUtil,
  permissionsUtil
} from '@app/util'
import { formatHours } from '@app/util/format-hours'
import { PERMISSIONS } from '@app/const'
import '../section/index.scss'
import Event from '../event'
import connect from './connect'
import { MonthPlannedMinutesCell } from './components/month-planned-minutes-cell'
import { BalancingPeriodPlannedMinutesCell } from './components/balancing-period-planned-minutes-cell'
import { MonthContractMinutesCell } from './components/month-contract-minutes-cell'
import { BalancingPeriodContractMinutesCell } from './components/balancing-period-contract-minutes-cell'
import { ContractStartEnd } from './components/contract-start-end'

function isDateInThePast (date) {
  if (date === null) {
    return false
  }

  if (date === undefined) {
    return false
  }

  return moment(date).isBefore(moment())
}

class Row extends React.Component {
  constructor (props) {
    super(props)
    this.getRowHTML = this._getRowHTML.bind(this)
    this.getTooltipContent = this._getTooltipContent.bind(this)
    this.ref = React.createRef()
  }

  shouldComponentUpdate (nextProps, nextState) {
    // update if the row stopped/started being visible
    if (this.props.notVisible !== nextProps.notVisible) {
      return true
    }

    if (miscUtil.safeStringify(this.props.calendar) !== miscUtil.safeStringify(nextProps.calendar)) {
      return true
    }

    // update if computed row height changes
    if (this.props.computedRowHeight !== nextProps.computedRowHeight) {
      return true
    }

    // only check anything below if the row is visible
    if (!nextProps.notVisible) {
      // update if chalendar's date is changed
      if (this.props.calendar?.date !== nextProps.calendar?.date) {
        return true
      }

      // update if calendar's date or contextMenuForColumn changed
      if (miscUtil.safeStringify(this.props.calendar?.contextMenuForColumn) !== miscUtil.safeStringify(nextProps.calendar?.contextMenuForColumn)) {
        if (this.props.calendar?.contextMenuForColumn && this.props.calendar?.contextMenuForColumn.rowEmployee === this.props.row.employee) {
          return true
        }
        if (nextProps.calendar?.contextMenuForColumn && nextProps.calendar?.contextMenuForColumn.rowEmployee === this.props.row.employee) {
          return true
        }
      }

      // update if we selected/unselected some multiselect day target in this row
      if (nextProps.calendarMultiSelect) {
        if (nextProps.calendarMultiSelect?.targets?.some(t => t.userId === nextProps.row.employee)) return true
        if (this.props.calendarMultiSelect?.targets?.some(t => t.userId === this.props.row.employee)) return true
        if (this.props.calendarMultiSelect?.isSelectingTargets !== nextProps.calendarMultiSelect?.isSelectingTargets) return true
      }

      // update if we have context menu for column open and events start or end being added
      if (nextProps.calendar?.contextMenuForColumn) {
        if (nextProps.isCreatingShiftFromContextMenu !== this.props.isCreatingShiftFromContextMenu) return true
        if (nextProps.isCreatingEventsFromMultiSelect !== this.props.isCreatingEventsFromMultiSelect) return true
      }

      // update if props.day changed
      if (this.props.day !== nextProps.day) {
        return true
      }

      // update if relevant part of the calendar.roleStats changes
      const currentRoleStats = this.props.row.labelData.user && this.props.calendar?.roleStats && this.props.calendar?.roleStats[this.props.row.labelData.user.id]
      const nextRoleStats = nextProps.row.labelData.user && nextProps.calendar?.roleStats && nextProps.calendar?.roleStats[nextProps.row.labelData.user.id]
      if (miscUtil.safeStringify(currentRoleStats) !== miscUtil.safeStringify(nextRoleStats)) {
        return true
      }

      // update if this row changes
      if (this.props.row.events.length !== nextProps.row.events.length) return true
      for (var i = 0; i < this.props.row.events.length; i++) {
        if (this.props.row.events[i].length !== nextProps.row.events[i].length) {
          return true
        }
        for (var j = 0; j < this.props.row.events[i].length; j++) {
          if (miscUtil.hasEventChanged(this.props.row.events[i][j], nextProps.row.events[i][j])) {
            return true
          }
        }
      }

      if (miscUtil.safeStringify(this.props.row.labelData.user) !== miscUtil.safeStringify(nextProps.row.labelData.user)) {
        return true
      }
      if (this.props.row.labelData.title !== nextProps.row.labelData.title) {
        return true
      }

      // update if number of closed days changes
      if (this.props.workspaceEvents.filter(ev => ev.type === 'closed').length !== nextProps.workspaceEvents.filter(ev => ev.type === 'closed').length) {
        return true
      }

      // update if holidays change
      if (miscUtil.safeStringify(this.props.holidays) !== miscUtil.safeStringify(nextProps.holidays)) {
        return true
      }

      // update if calendar search changes
      if (miscUtil.safeStringify(this.props.calendarSearch) !== miscUtil.safeStringify(nextProps.calendarSearch)) {
        const oldRes = this.props.row && this.props.calendarSearch.results.find(r => r.id === this.props.row.employee)
        const newRes = nextProps.row && nextProps.calendarSearch.results.find(r => r.id === nextProps.row.employee)
        if (oldRes !== newRes) return true
      }

      // update if calendar's sortedBy setting changed
      if (this.props.sortedBy !== nextProps.sortedBy) {
        return true
      }

      if (nextProps.isCycleCalendar) {
        if (miscUtil.safeStringify(this.props.cycleDetail.calendar?.contextMenuForColumn) !== miscUtil.safeStringify(nextProps.cycleDetail.calendar?.contextMenuForColumn)) {
          return true
        }
        if (miscUtil.safeStringify(this.props?.cycleGroups) !== miscUtil.safeStringify(nextProps?.cycleGroups)) {
          return true
        }
        if (miscUtil.safeStringify(this.props.cycleDetail.calendar?.contextMenuForColumn?.expandedTemplateList) !== miscUtil.safeStringify(nextProps.cycleDetail.calendar?.contextMenuForColumn?.expandedTemplateList)) {
          return true
        }
      }
    }

    return false
  }

  componentDidUpdate (prevProps, prevState) {
    const { setComputedRowHeight, computedRowHeight, idx, row } = this.props

    if (setComputedRowHeight && (typeof idx !== typeof undefined && typeof idx !== typeof null)) {
      // update the computedRowHeight according to actual rendered height
      setTimeout(() => {
        const boundingBox = (this.ref && this.ref.current) ? this.ref.current.getBoundingClientRect() : {}
        if (!computedRowHeight || (computedRowHeight && boundingBox.height && computedRowHeight !== boundingBox.height)) {
          setComputedRowHeight(idx, boundingBox.height)
        }

        // if computed height is over 200px, add the 'is-tall' class to the row
        if (boundingBox.height >= 200) {
          this.ref.current.classList.add('is-tall')
        }
      }, 200)

      // set computedRowHeight back to null when the max number of shifts in any one day is reduced. if we do that, the height will be recomputed again by the code above ^
      // this ensures that the row height is recomputed when we need to reduce it: for example when we remove some events from it, or when we switch calendar month to one with fewer shifts in that row
      if (Math.max(...row.events.map(col => col.length)) < Math.max(...prevProps.row.events.map(col => col.length))) {
        setTimeout(() => { setComputedRowHeight(idx, null) }, 0)
      }

      // else if (currentColRowHeight > Math.max(...prevProps.row.events.map(col => col.length))) {
      //   console.log("updating")
      //   setTimeout(() => { setComputedRowHeight(idx, SINGLE_EVENT_HEIGHT * 16 * currentColRowHeight) }, 0)
      // }
    }
  }

  _getTooltipContent (data) {
    const { calendar, setCalendar, setCycleDetailCalendar, setModal, createShift, workspace, isCreatingShiftFromContextMenu, isCreatingEventsFromMultiSelect, isCycleCalendar, cycleDetail, calendarMultiSelect } = this.props
    const rows = []
    const shiftTemplates = (workspace && workspace.shiftTemplates && workspace.shiftTemplates.length) ? workspace.shiftTemplates : []

    /* employee */
    if (!calendarMultiSelect?.isSelectingTargets && data.row.employee && !['unassigned', 'offers'].includes(data.row.employee)) {
      rows.push(
        <div
          className='evt-tt-r-employee'
          key={rows.length}
        >
          <span className='k'>{t('COL_TOOLTIP_EMPLOYEE')}</span>
          <span className='v'>{data.row.labelData.user ? data.row.labelData.user.name : ''}</span>
        </div>
      )
    }

    /* position */
    if (!calendarMultiSelect?.isSelectingTargets && data.row.sortData && data.row.sortData.sortedBy === 'SORT_BY_POSITION' && data.row.labelData.positions) {
      const pos = data.row.labelData.positions.find(p => p.id === data.row.sortData.id)
      if (pos) {
        rows.push(
          <div className='evt-tt-r-position' key={rows.length}>
            <span className='k'>{t('POSITION')}</span>
            <span className='v'><Position {...Object.assign({}, pos, { skill: undefined })} /></span>
          </div>
        )
      }
    }

    /* locality */
    if (!calendarMultiSelect?.isSelectingTargets && data.row.sortData && data.row.sortData.sortedBy === 'SORT_BY_LOCALITY') {
      rows.push(
        <div className='evt-tt-r-locality' key={rows.length}>
          <span className='k'>{t('LOCALITY')}</span>
          <span className='v'><Locality id={data.row.sortData.id} /></span>
        </div>
      )
    }

    /* date */
    if (!calendarMultiSelect?.isSelectingTargets) {
      rows.push(
        <div className='evt-tt-r-date' key={rows.length}>
          <span className='k'>{t('DATE')}</span>
          <span className='v'>{moment(calendar.date).startOf(calendar.view).add(calendar.view === 'day' ? 0 : data.colIdx, 'days').format('D. M.')}</span>
        </div>
      )
    }

    /* insert events count */
    if (calendarMultiSelect?.isSelectingTargets) {
      rows.push(
        <div className='evt-tt-r-insert' key={rows.length}>
          <Text weight={Text.WEIGHTS.BOLD} color={Text.COLORS.PRIMARY} type={Text.TYPES.BODY_MEDIUM}>
            {calendarMultiSelect.action === 'copy'
              ? t('MULTI_ACTION_COPY_CONFIRM', { x: calendarMultiSelect.targets.length })
              : calendarMultiSelect.action === 'applyShiftTemplate'
                ? t('MULTI_ACTION_COPY_CONFIRM', { x: calendarMultiSelect.targets.length })
                : null}
          </Text>
        </div>
      )
    }

    // =============================
    // CONTEXT MENU BUTTON HANDLERS:
    // =============================

    // add shift handler
    const handleAddShift = (e) => {
      const { isCycleCalendar, cycleDetail, setCycleDetailLastSaved, cycleGroups } = this.props
      e.preventDefault()
      e.stopPropagation()
      if (isCycleCalendar) {
        setCycleDetailCalendar({
          ...cycleDetail,
          calendar: {
            contextMenuForColumn: undefined
          }
        })
      } else {
        if (calendar.contextMenuForColumn) setCalendar({ contextMenuForColumn: undefined })
      }
      if (isCycleCalendar) {
        setModal('extra-shift',
          Object.assign({
            type: 'cycle',
            colId: data.colIdx,
            groupId: cycleGroups[data.groupIdx].groupId,
            eventId: 0,
            afterSubmit: (p) => {
              if (p && p.data) setCycleDetailLastSaved(p.data)
            }
          },
          // if we have some object stored in store.cycleDetail.lastSavedCycleShift, send it to 'extra-shift' modal's props, so its values are used as defaults in the modal
          (cycleDetail.lastSavedCycleShift && cycleDetail.lastSavedCycleShift.period
            ? Object.assign({}, cycleDetail.lastSavedCycleShift, {
              hour: timeUtil.asMinutes(cycleDetail.lastSavedCycleShift.period.start) / 60,
              hourDuration: moment(cycleDetail.lastSavedCycleShift.period.end).diff(cycleDetail.lastSavedCycleShift.period.start, 'minutes') / 60,
              period: undefined
            })
            : {}))
        )
      } else {
        setModal('extra-shift', {
          type: 'live',
          day: moment(calendar.date).startOf(calendar.view).add(calendar.view === 'day' ? 0 : data.colIdx, 'days').format('YYYY-MM-DD'),
          hour: calendar.view === 'day' ? data.colIdx : null,
          userId: (data.row.employee && data.row.employee !== 'unassigned' && data.row.employee !== 'offers') ? data.row.employee : null,
          positionId: data.row.sortData && data.row.sortData.sortedBy === 'SORT_BY_POSITION'
            ? data.row.sortData.id
            : undefined,
          localityId: data.row.sortData && data.row.sortData.sortedBy === 'SORT_BY_LOCALITY'
            ? data.row.sortData.id
            : undefined,
          startingOffer: (data.row.employee === 'offers'),
          afterSubmit: data.row.employee === 'offers'
            ? (res) => {
              if (res && res.length) {
                this.props.setModal('offers', {
                  selectedShiftIds: [res[0].id],
                  action: 'create',
                  createType: 'drop'
                })
              }
            }
            : undefined
        })
      }
    }

    // expands or collapses the list of shift templates in the context menu
    const handleExpandTemplateList = (e) => {
      const { isCycleCalendar } = this.props
      e.preventDefault()
      e.stopPropagation()
      if (isCycleCalendar) {
        setCycleDetailCalendar({
          contextMenuForColumn: {
            ...cycleDetail.calendar.contextMenuForColumn,
            expandedTemplateList: (cycleDetail.calendar.contextMenuForColumn.expandedTemplateList ? undefined : true)
          }
        })
      } else {
        const newContextMenuVal = Object.assign({}, calendar.contextMenuForColumn, { expandedTemplateList: (calendar.contextMenuForColumn.expandedTemplateList ? undefined : true) })
        if (miscUtil.safeStringify(calendar.contextMenuForColumn) !== miscUtil.safeStringify(newContextMenuVal)) setCalendar({ contextMenuForColumn: newContextMenuVal })
      }
    }

    // add shift from shift template handler
    const handleAddShiftFromTemplate = (e, tpl) => {
      const { idx, setCycleDetail } = this.props
      e.preventDefault()
      e.stopPropagation()

      if (!isCreatingShiftFromContextMenu) { // don't do anything if we're already creating a shift. this function is messy if it's called twice for some reason.
        if (isCycleCalendar) {
          const start = moment(tpl.shift.period.start).diff(moment(tpl.shift.period.start).startOf('day'), 'minutes')
          const end = moment(tpl.shift.period.end).diff(moment(tpl.shift.period.end).startOf('day'), 'minutes')
          const cycleData = { ...tpl.shift }

          cycleData.start = start + data.colIdx * 1440
          cycleData.end = end + data.colIdx * 1440
          cycleData.duration = Math.abs(end - start)
          cycleData.period = {
            start: moment(calendar.date).add(data.colIdx, 'days').add(start, 'minutes'),
            end: moment(calendar.date).add(data.colIdx, 'days').add(end, 'minutes')
          }
          cycleData.id = idx + '_' + data.colIdx + '_' + 0
          cycleData.employees = 1
          if (cycleData.note) delete cycleData.note

          setCycleDetail({
            type: 'shift',
            data: cycleData
          })
          if (isCycleCalendar && cycleDetail?.calendar?.contextMenuForColumn?.colId === data.colIdx && cycleDetail?.calendar?.contextMenuForColumn?.groupId === idx) {
            setCycleDetailCalendar({
              contextMenuForColumn: undefined
            })
          }
        } else {
          const day = moment(calendar.date).startOf(calendar.view).add(calendar.view === 'day' ? 0 : data.colIdx, 'days').format('YYYY-MM-DD')
          calendarUtil.createShiftFromShiftTemplate(
            tpl,
            (data.row.employee && data.row.employee !== 'unassigned' && data.row.employee !== 'offers') ? data.row.employee : null,
            day,
            createShift,
            (res) => {
              const columnUniqueId = calendarUtil.getColIdentifier(day, data.row.employee)
              if (calendar && calendar.contextMenuForColumn && calendar.contextMenuForColumn.columnUniqueId === columnUniqueId && calendar.contextMenuForColumn.colIdx === data.colIdx) {
                setCalendar({ contextMenuForColumn: undefined })
              }
            }
          )
        }
      }
    }

    // opens a modal to create a new shift template
    const handleCreateShiftTemplate = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setModal('extra-shift-template')
      const newContextMenuVal = Object.assign({}, calendar.contextMenuForColumn, { expandedTemplateList: undefined })
      if (miscUtil.safeStringify(calendar.contextMenuForColumn) !== miscUtil.safeStringify(newContextMenuVal)) setCalendar({ contextMenuForColumn: newContextMenuVal })
    }

    // add absence handler
    const handleAddAbsence = (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (calendar.contextMenuForColumn) setCalendar({ contextMenuForColumn: undefined })

      setModal('extra-unavailability', {
        day: moment(calendar.date).startOf(calendar.view).add(calendar.view === 'day' ? 0 : data.colIdx, 'days').format('YYYY-MM-DD'),
        hour: calendar.view === 'day' ? data.colIdx : null,
        userId: (data.row.employee && data.row.employee !== 'unassigned' && data.row.employee !== 'offers') ? data.row.employee : null,
        isEmployeeCalendar: false,
        newAvailabilityOrTimeOff: 'timeOff',
        employeeCalendarShifts: this.props.employeeShifts
      })
    }

    // multiselect insert to targets handler
    const handleMultiSelectInsert = (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (calendarMultiSelect?.action) multiSelectUtil.multiSelectExecuteAction(calendarMultiSelect.action)
    }

    // multiselect cancel selection handler
    const handleMultiSelectCancel = (e) => {
      e.preventDefault()
      e.stopPropagation()
      multiSelectUtil.multiSelectClear()
    }

    return (
      <>
        {rows}
        <div className='evt-tt-cm'>

          {/* add shift */}
          {!calendarMultiSelect?.isSelectingTargets && (
            <div
              key='context_add_shift'
              onMouseDown={handleAddShift}
              onTouchStart={handleAddShift}
            >
              <Icon ico='plus' />
              {t('COL_TOOLTIP_CM_ADD_SHIFT')}
            </div>
          )}

          {/* add shift from shift template */}
          {!calendarMultiSelect?.isSelectingTargets && (
            <div
              key='context_add_shift_from_tmp'
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleExpandTemplateList(e)
              }}
              onTouchStart={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleExpandTemplateList(e)
              }}
            >
              <Icon ico='plus' />
              {t('COL_TOOLTIP_CM_ADD_SHIFT_FROM_TPL')}
              {(isCycleCalendar && cycleDetail.calendar?.contextMenuForColumn?.expandedTemplateList) || calendar?.contextMenuForColumn?.expandedTemplateList ? (
                <div className='ds-c-evt-tt-shift-template-list'>
                  {isCreatingShiftFromContextMenu
                    ? <Icon ico={Icon.ICONS.loader} />
                    : shiftTemplates.length
                      ? shiftTemplates.map(tpl => {
                        return (
                          <div
                            key={tpl.id}
                            onMouseDown={(e) => { handleAddShiftFromTemplate(e, tpl) }}
                            onTouchStart={(e) => { handleAddShiftFromTemplate(e, tpl) }}
                          >
                            {tpl.name}
                          </div>
                        )
                      })
                      : (
                        <div
                          onMouseDown={(e) => { handleCreateShiftTemplate(e) }}
                          onTouchStart={(e) => { handleCreateShiftTemplate(e) }}
                        >
                          {t('COL_TOOLTIP_CM_CREATE_TPL')}
                        </div>
                      )}
                </div>
              ) : null}
            </div>
          )}

          {/* add timeOff */}
          {!['unassigned', 'offers'].includes(data.row.employee) && !isCycleCalendar && !calendarMultiSelect?.isSelectingTargets && (
            <div
              key='context_add_absence'
              onMouseDown={handleAddAbsence}
              onTouchStart={handleAddAbsence}
            >
              <Icon ico='plus' />
              {t('COL_TOOLTIP_CM_ADD_ABSENCE')}
            </div>
          )}

          {/* multiselect insert to targets */}
          {calendarMultiSelect?.isSelectingTargets && (
            isCreatingEventsFromMultiSelect
              ? <Flex><Icon ico={Icon.ICONS.loader} /></Flex>
              : (
                <div
                  key='context_ms_insert_to_targets'
                  onMouseDown={handleMultiSelectInsert}
                  onTouchStart={handleMultiSelectInsert}
                >
                  <Icon ico={Icon.ICONS.check} />
                  {t('APPLY_TEMPLATE_APPLY')}
                </div>)
          )}

          {/* multiselect cancel */}
          {calendarMultiSelect?.isSelectingTargets && !isCreatingEventsFromMultiSelect && (
            <div
              key='context_ms_cancel'
              onMouseDown={handleMultiSelectCancel}
              onTouchStart={handleMultiSelectCancel}
            >
              <Icon ico={Icon.ICONS.close1} />
              {t('CANCEL')}
            </div>
          )}

        </div>
      </>
    )
  }

  _getRowHTML (row, idx, isHighlighted) {
    const {
      calendar, calendarSearch, setModal, setCalendarMultiSelect, setCalendar, workspaceEvents, holidays, isEmployeeCalendar,
      whichSection, labelWidthRem, workspace, isCycleCalendar, showRepeat, period, isSettingCalendarMultiselect,
      setCycleDetailLastSaved, setCycleDetailCalendar, cycleDetail, evtIdx, cycleGroupsCallback, sortedBy, calendarMultiSelect, cycleGroups
    } = this.props
    // const { sortData } = row
    // const positions = this.props.positions || []
    if (!calendar || !calendarSearch) return null
    const isTopSection = row.employee === 'unassigned' || row.employee === 'offers'

    let onClickHandler = () => { }
    if (row.labelData.title && [t('OPEN_SHIFTS'), t('CONTESTS')].includes(row.labelData.title) && row.events) {
      const evts = row.events.reduce((a, s) => { return a.concat(s) }, []).map(s => Object.assign({}, s.data, { day: moment(s.data.period.start).format('YYYY-MM-DD') }))
      if (evts && evts.length) {
        onClickHandler = (e) => {
          setCalendarMultiSelect({
            sourceEvents: evts
          })
        }
      }
    }

    // prepare the starts and ends of contracts that this row's user has in displayed calendar period
    const contractStarts = {}
    const contractEnds = {}
    if (row.labelData.userHasContractsInCalendarPeriod && row.labelData.user.contracts && calendar.view !== 'day') {
      row.labelData.user.contracts.forEach(con => {
        if (!con.period) return false
        if (moment(con.period.start).isSameOrAfter(moment(calendar.date).startOf(calendar.view), 'day') && moment(con.period.start).isSameOrBefore(moment(calendar.date).endOf(calendar.view), 'day')) {
          contractStarts[moment(con.period.start).format('YYYY-MM-DD')] = { type: con.type, minutes: con.options?.minutesOfWorkPerMonth }
        }
        if (moment(con.period.end).isSameOrAfter(moment(calendar.date).startOf(calendar.view), 'day') && moment(con.period.end).isSameOrBefore(moment(calendar.date).endOf(calendar.view), 'day')) {
          contractEnds[moment(con.period.end).format('YYYY-MM-DD')] = { type: con.type, minutes: con.options?.minutesOfWorkPerMonth }
        }
      })
    }
    const desiredEmployee = this.props.employees[row.employee]
    const contractInSelectedMonth = desiredEmployee?.contracts ? desiredEmployee?.contracts.filter((contract) => calendarUtil.isPeriodsOverlap(contract?.period, {
      start: moment(calendar.date).startOf(calendar.view),
      end: moment(calendar.date).endOf(calendar.view)
    })) : []

    const hasFixedHoursContract = workspace?.contractTypes
      ?.filter(ctp => ctp.id === row.labelData?.user?.contractType || contractInSelectedMonth.some((item) => ctp.id === item.type))
      .some((item) => item?.rules?.fixedWorkHours)

    const hasContractWithVacationFund = workspace?.contractTypes
      ?.filter(ctp => ctp.id === row.labelData?.user?.contractType || contractInSelectedMonth.some((item) => ctp.id === item.type))
      .some((item) => item?.rules?.vacationFund)

    const empStats = (!isEmployeeCalendar && !isTopSection && calendar && calendar.roleStats) ? calendar.roleStats[row.employee] : null

    const {
      monthPlannedMinutesByContract,
      monthPlannedMinutesByPosition,
      monthContractMinutesByContract,
      balancingPeriodContractMinutesByContract,
      balancingPeriodPlannedMinutesByContract,
      balancingPeriodPlannedMinutesByPosition
    } = empStats || {}

    const wsBalancingPeriod = workspace?.settings?.balancingPeriod
    const wsBalancingPeriodRelevant = (!!wsBalancingPeriod && (wsBalancingPeriod.unit !== 'month' || wsBalancingPeriod.amount !== 1))

    let vacationTaken = (workspace?.displayVacationsAsDays)
      ? empStats?.yearVacationDays
      : empStats?.yearVacationMinutes
    let vacationFund = (workspace?.displayVacationsAsDays)
      ? empStats?.yearVacationDays + empStats?.yearVacationRemainingDays
      : empStats?.yearVacationMinutes + empStats?.yearVacationRemainingMinutes

    if (workspace?.displayVacationsAsDays) {
      vacationFund = numberUtil.round2decimals(vacationFund)
      vacationTaken = numberUtil.round2decimals(vacationTaken)
    } else {
      vacationTaken = timeUtil.asHour(vacationTaken)
      vacationFund = timeUtil.asHour(vacationFund)
    }

    const isExternalEmployeeRow = Boolean(row && row.labelData && row.labelData.user && row.labelData.user.external)
    const hasEmployeesAccess = permissionsUtil.canRead(PERMISSIONS.WORKSPACE.EMPLOYEES)

    let rowLabelTitle = row?.labelData?.title
    const dayDisplaysNote = isEmployeeCalendar && workspaceEvents.find((evt) => evt.type === 'note' && moment(evt.period.start).format('YYYY-MM-DD') === row.day)
    if (dayDisplaysNote) {
      rowLabelTitle = (
        <Tooltip
          anchor={<Text color={Text.COLORS.PRIMARY} weight={Text.WEIGHTS.BOLD}>{rowLabelTitle}</Text>}
        >
          <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_16}>
            <Text>
              {dayDisplaysNote.description}
            </Text>
          </Spacing>
        </Tooltip>
      )
    }

    const rowPositions = row.labelData.user?.positions
    const rowLocalities = row.labelData.user?.localities
    const rowContracts = row.labelData.user?.contracts

    const multiplePositions = rowPositions?.length > 1
    const multipleLocalities = rowLocalities?.length > 1
    const multipleContracts = rowContracts?.filter(c => !isDateInThePast(c?.period?.end)).length > 1

    const rowLabel = (
      <div
        className={
          'ds-c-row-label row_' +
          row.employee +
          (isEmployeeCalendar && [6, 7].includes(moment(row.day).isoWeekday()) ? ' is-weekend' : '') +
          (isTopSection ? ' is-top-section' : '')
        }
        style={{
          width: (labelWidthRem).toString() + 'rem',
          minWidth: (labelWidthRem).toString() + 'rem',
          maxWidth: (labelWidthRem).toString() + 'rem',
          borderLeft: (row && row.labelData && row.labelData.user && row.labelData.user.employeeWarnings && row.labelData.user.employeeWarnings.filter(w => w.type !== 'dummy').length ? '0.43ex solid #ce0b24' : '0.43ex solid transparent')
        }}
        onMouseDown={onClickHandler}
        data-testid={`calendar-row-header-${row.employee}`}
      >
        <div className='ds-c-row-label-left' />
        <div className='ds-c-row-label-right'>
          <div className='ds-c-row-title'>
            {isCycleCalendar && cycleGroupsCallback
              ? (
                <Input
                  style={Input.STYLES.UNDERLINE}
                  size={Input.SIZES.SMALL}
                  type={Input.TYPES.TEXT}
                  value={rowLabelTitle}
                  onChange={val => {
                    const data = { id: idx, name: val }
                    cycleGroupsCallback(data)
                  }}
                />)
              : rowLabelTitle}
          </div>
          <div className='ds-c-row-text'>
            {isTopSection || isCycleCalendar ? row.labelData.text : null}
            {(!isTopSection && calendar && calendar.roleStats && empStats) // eslint-disable-next-line
              ? (
                <table className='ds-c-row-label-hrs-table'>
                  <tbody>
                    <tr>
                      {/* plán měsíc, v závorce počet hodin přes čas (= pouze směny označené jako mimořádné), tvar (+16) */}
                      <MonthPlannedMinutesCell
                        sortData={row.sortData}
                        monthContractMinutes={empStats.monthContractMinutes}
                        monthPlannedMinutes={empStats.monthPlannedMinutes}
                        monthOverTimeMinutes={empStats.monthOverTimeMinutes}
                        monthContractMinutesByContract={monthContractMinutesByContract}
                        monthPlannedMinutesByContract={monthPlannedMinutesByContract}
                        monthPlannedMinutesByPosition={monthPlannedMinutesByPosition}
                      />
                      {/* plán VO */}
                      {wsBalancingPeriodRelevant && hasFixedHoursContract && (
                        <BalancingPeriodPlannedMinutesCell
                          sortData={row.sortData}
                          balancingPeriodContractMinutes={empStats.balancingPeriodContractMinutes}
                          balancingPeriodPlannedMinutes={empStats.balancingPeriodPlannedMinutes}
                          balancingPeriodContractMinutesByContract={balancingPeriodContractMinutesByContract}
                          balancingPeriodPlannedMinutesByContract={balancingPeriodPlannedMinutesByContract}
                          balancingPeriodPlannedMinutesByPosition={balancingPeriodPlannedMinutesByPosition}
                        />
                      )}
                      {/* suma vybrané dovolné v daném roce */}
                      {hasContractWithVacationFund && (
                        <td>
                          <span className='no-arrow small' />
                          {workspace?.displayVacationsAsDays ? `${vacationTaken} ${t('DAY_SHORTEST')}` : formatHours(vacationTaken)}
                        </td>
                      )}
                    </tr>
                    <tr>
                      {/* měsíční úvazek */}
                      <MonthContractMinutesCell
                        sortData={row.sortData}
                        monthContractMinutes={empStats.monthContractMinutes}
                        multipleContracts={multipleContracts}
                        multiplePositions={multiplePositions}
                        multipleLocalities={multipleLocalities}
                      />
                      {/* fond VO */}
                      {wsBalancingPeriodRelevant && hasFixedHoursContract && (
                        <BalancingPeriodContractMinutesCell
                          sortData={row.sortData}
                          balancingPeriodContractMinutes={empStats.balancingPeriodContractMinutes}
                          multipleContracts={multipleContracts}
                          multiplePositions={multiplePositions}
                          multipleLocalities={multipleLocalities}
                        />
                      )}
                      {/* celkový fond dovolene v daném roce */}
                      {hasContractWithVacationFund && (
                        <td>
                          <span className='no-arrow small' />
                          {workspace?.displayVacationsAsDays ? `${vacationFund} ${t('DAY_SHORTEST')}` : formatHours(vacationFund)}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>)
              : null}
          </div>
        </div>
      </div>
    )

    const maxVerticalShift = row.events.reduce((acc, eventColumn) => {
      const columnMax = eventColumn.reduce((evAcc, event) => Math.max(evAcc, event.data.verticalShift ?? 0), 0)

      return Math.max(acc, columnMax)
    }, 0)

    // HERE
    return (
      <div
        ref={this.ref}
        style={{
          // minHeight: this.props.computedRowHeight ? (this.props.computedRowHeight + 'px') : undefined,
          height: calendar.view === 'day' ? `${(maxVerticalShift + 1) * 3.1875}rem` : undefined
        }}
        className={`ds-c-row ${row.className || ''} ${row.isDayToday ? 'is-day-today' : ''} ${isHighlighted ? 'is-highlighted' : ''}`}
      >
        {/* Row label */}
        {
          whichSection === 'bottom' && !isEmployeeCalendar && !isExternalEmployeeRow && hasEmployeesAccess
            ? (
              <div className='ds-c-row-label-modal-wrapper' onClick={() => setModal('employee-profile', { userId: row?.labelData?.user?.id })}>
                {rowLabel}
              </div>
            )
            : rowLabel
        }

        {/* Columns */}
        {
          row.events.map((col, colIdx) => {
            const day = isCycleCalendar
              ? moment(calendar.date).add(calendar.view !== 'day' ? colIdx : 0, 'days').format('YYYY-MM-DD')
              : moment(calendar.date).startOf(calendar.view).add(calendar.view !== 'day' ? colIdx : 0, 'days').format('YYYY-MM-DD')
            const isLockedDay = miscUtil.isMyCalendarLocked(moment(day).endOf('day'), workspace)
            const isWeekend = isEmployeeCalendar
              ? [6, 7].includes(moment(row.day).isoWeekday())
              : [6, 7].includes(moment(day).isoWeekday())
            const isHoliday = !isEmployeeCalendar && holidays.find((hol) => hol.type === 'public' && moment(hol.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'))
            const isPast = moment(day).isBefore(moment(), 'day')
            const isToday = moment(day).isSame(moment(), 'day')
            const dayIsClosed = !isEmployeeCalendar && workspaceEvents.find((evt) => evt.type === 'closed' && moment(evt.period.start).format('YYYY-MM-DD') === day)
            const columnUniqueId = calendarUtil.getColIdentifier(day, row.employee)
            let firstShiftInColAlreadyRendered = false
            const isCyclePreview = showRepeat && colIdx >= period
            const isSelectedAsTarget = calendarMultiSelect?.isSelectingTargets && calendarMultiSelect?.targets?.some(t => t.date === day && t.userId === row?.employee)

            // prepare the column tooltip, if needed according to calendar.contextMenuForColumn
            let tooltipHTML = null
            if (
              // display column tooltip in Cycle Calendar
              (isCycleCalendar && cycleDetail.calendar?.contextMenuForColumn?.groupId === idx && cycleDetail.calendar?.contextMenuForColumn?.colId === colIdx) ||
              // display column tooltip in Manager Calendar
              (!isEmployeeCalendar && calendar && calendar.contextMenuForColumn && calendar.contextMenuForColumn.columnUniqueId === columnUniqueId && calendar.contextMenuForColumn.colIdx === colIdx)
            ) {
              tooltipHTML = (
                <div className='evt-tt'>
                  {this.getTooltipContent({ row, colIdx, groupIdx: idx, dayDisplaysNote })}
                </div>
              )
            }

            // compute the current balancing period start & end, with respect to this row's date, so it can be displayed in calendar
            const cbp = timeUtil.getCurrentBalancingPeriod(wsBalancingPeriod, moment(day))
            const isStartOfBalancingPeriod = cbp && cbp.end && moment(day, 'YYYY-MM-DD').isSame(moment(cbp.end), 'day')

            // prepare the hover buttons
            const hoverBtns = (
              <div className='ds-c-col-hover-btns'>
                {/* the "+" plus button in most cols */}
                {!calendarMultiSelect?.isSelectingTargets && !isExternalEmployeeRow && !isCyclePreview && (!isLockedDay || isEmployeeCalendar) && (
                  <Button
                    onClick={(e) => {
                      // don't open anything if an Event's context menu tooltip was recently closed (within a few milliseconds)
                      if (window.dsMsTooltipWasRecentlyClosed && (Date.now() - parseInt(window.dsMsTooltipWasRecentlyClosed) <= 500)) {
                        e.stopPropagation()
                        e.preventDefault()
                        return false
                      }

                      if (!isEmployeeCalendar && isCycleCalendar) {
                        setModal('extra-shift',
                          Object.assign({
                            type: 'cycle',
                            colId: colIdx,
                            groupId: cycleGroups[idx].groupId,
                            eventId: 0,
                            afterSubmit: (p) => {
                              if (p && p.data) setCycleDetailLastSaved(p.data)
                            }
                          },
                          // if we have some object stored in store.cycleDetail.lastSavedCycleShift, send it to 'extra-shift' modal's props, so its values are used as defaults in the modal
                          (cycleDetail.lastSavedCycleShift && cycleDetail.lastSavedCycleShift.period
                            ? Object.assign({}, cycleDetail.lastSavedCycleShift, {
                              hour: timeUtil.asMinutes(cycleDetail.lastSavedCycleShift.period.start) / 60,
                              hourDuration: moment(cycleDetail.lastSavedCycleShift.period.end).diff(cycleDetail.lastSavedCycleShift.period.start, 'minutes') / 60,
                              period: undefined
                            })
                            : {}))
                        )
                        return
                      }
                      if (!isEmployeeCalendar && !isCycleCalendar) {
                        setModal('extra-shift', {
                          type: 'live',
                          day: moment(calendar.date).startOf(calendar.view).add(calendar.view === 'day' ? 0 : colIdx, 'days').format('YYYY-MM-DD'),
                          hour: calendar.view === 'day' ? colIdx : null,
                          userId: (row.employee && row.employee !== 'unassigned' && row.employee !== 'offers') ? row.employee : null,
                          startingOffer: (row.employee === 'offers'),
                          afterSubmit: row.employee === 'offers'
                            ? (res) => {
                              if (res && res.length) {
                                this.props.setModal('offers', {
                                  selectedShiftIds: [res[0].id],
                                  newlyCreatedShifts: res, // send newly created shift to this modal via prop, because it's probably not yet in store when the modal renders (websockets are not instant)
                                  action: 'create',
                                  createType: 'drop'
                                })
                              }
                            }
                            : undefined
                        })
                      } else {
                        setModal('extra-unavailability', {
                          day: moment(row.day).format('YYYY-MM-DD'),
                          hour: (calendar.view === 'day' || isEmployeeCalendar) ? colIdx : null,
                          userId: row.employee,
                          isEmployeeCalendar: true,
                          overSidebar: true,
                          newAvailabilityOrTimeOff: 'unavailability',
                          employeeCalendarShifts: this.props.employeeShifts
                        })
                      }
                    }}
                    onContextMenu={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (isCycleCalendar) {
                        setCycleDetailCalendar({
                          contextMenuForColumn: {
                            groupId: cycleGroups[idx].groupId,
                            colId: colIdx,
                            eventId: evtIdx
                          }
                        })
                      } else {
                        if (!calendar.contextMenuForColumn || calendar.contextMenuForColumn.columnUniqueId !== columnUniqueId) {
                          setCalendar(Object.assign({}, calendar, {
                            contextMenuForColumn: {
                              columnUniqueId,
                              rowEmployee: row.employee,
                              colIdx
                            }
                          }))
                        }
                      }
                    }}
                    ico={Icon.ICONS.plus}
                  />)}

                {/* the extra 'template-apply' button in 'unassigned' row cols */}
                {!calendarMultiSelect?.isSelectingTargets && !isEmployeeCalendar && row.employee === 'unassigned' && !isLockedDay && (
                  <Button
                    onClick={(e) => {
                      // don't open anything if an Event's context menu tooltip was recently closed (within a few milliseconds)
                      if (window.dsMsTooltipWasRecentlyClosed && (Date.now() - parseInt(window.dsMsTooltipWasRecentlyClosed) <= 500)) {
                        e.stopPropagation()
                        e.preventDefault()
                        return false
                      }

                      setModal('template-apply', {
                        date: moment(calendar.date).startOf(calendar.view).add(calendar.view === 'day' ? 0 : colIdx, 'days').format('YYYY-MM-DD')
                      })
                      if (calendar.contextMenuForColumn) setCalendar({ contextMenuForColumn: undefined })
                      e.stopPropagation()
                    }}
                    onContextMenu={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setCalendar({
                        contextMenuForColumn: {
                          columnUniqueId,
                          rowEmployee: row.employee,
                          colIdx
                        }
                      })
                    }}
                    ico={Icon.ICONS.open}
                  />
                )}

                {/* the 'select'/'selected' button while we're multiselecting copy targets */}
                {calendarMultiSelect?.isSelectingTargets && row.employee !== 'offers' && (
                  <Button
                    disabled={isSettingCalendarMultiselect}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()

                      const isAlreadySelected = calendarMultiSelect?.targets?.some(t => t.userId === row.employee && t.date === day)
                      if (isAlreadySelected) {
                        // remove from selection
                        setCalendarMultiSelect(undefined, undefined, { userId: row.employee, date: day })
                      } else {
                        // add to selection
                        setCalendarMultiSelect(undefined, { userId: row.employee, date: day })
                      }
                    }}
                    onContextMenu={
                      calendarMultiSelect?.targets?.some(t => t.userId === row.employee && t.date === day) /* only allow opening context menu here when user right-clicks on *selected* target */
                        ? (e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          if (!calendar.contextMenuForColumn || calendar.contextMenuForColumn.columnUniqueId !== columnUniqueId) {
                            setCalendar({
                              contextMenuForColumn: {
                                columnUniqueId,
                                rowEmployee: row.employee,
                                colIdx
                              }
                            })
                          }
                        }
                        : (e) => { e.stopPropagation(); e.preventDefault() /* otherwise just block browser's default right-click functionality here */ }
                    }
                    ico={calendarMultiSelect?.targets?.some(t => t.userId === row.employee && t.date === day)
                      ? Icon.ICONS.check
                      : Icon.ICONS.duplicate}
                    label={calendarMultiSelect?.targets?.some(t => t.userId === row.employee && t.date === day)
                      ? t('MULTI_SELECTED')
                      : t('CHOOSE')}
                  />
                )}

              </div>
            )

            return (
              <div
                className={
                  'ds-c-col' +
                  ' is-view-' + calendar.view +
                  (isWeekend ? ' is-weekend' : '') +
                  (isHoliday ? ' is-holiday' : '') +
                  (isPast && !isCycleCalendar ? ' is-past' : '') +
                  (isToday ? ' is-today' : '') +
                  (isStartOfBalancingPeriod ? ' is-start-of-balancing-period' : '') +
                  (dayIsClosed && !isCycleCalendar ? ' is-closed' : '') +
                  (isEmployeeCalendar ? '' : ' ds-droppable') +
                  (isEmployeeCalendar ? '' : ' ' + calendarUtil.getColIdentifier(day)) +
                  (isEmployeeCalendar ? '' : ' ' + columnUniqueId) +
                  (isCyclePreview ? ' is-preview' : '') +
                  (isSelectedAsTarget ? ' is-ms-selected' : '')
                }
                key={'row_col_' + idx.toString() + '_' + colIdx.toString()}
                data-droppable={miscUtil.safeStringify({ employee: row.employee, colIdx })}
                onDragEnter={(e) => {
                  e.target.classList.add('ds-droppable-hovered') // add ds-droppable-hovered here
                }}
                onDragLeave={(e) => {
                  e.target.classList.remove('ds-droppable-hovered') // remove ds-droppable-hovered from here
                }}
                onContextMenu={(e) => {
                  // prevent default browser's right-click handler on the column itself
                  e.preventDefault()
                  // if we have context menu for whatever event/column open, close it when we click directly on the empty column space
                  if (calendar.contextMenuForColumn) setCalendar({ contextMenuForColumn: undefined })
                }}
                data-testid={`dayIndex-${colIdx}-userId-${row.employee}`}
              >
                <ContractStartEnd
                  contractStarts={contractStarts}
                  contractEnds={contractEnds}
                  day={day}
                />

                {/* Events */}
                {col.map((evt, evtIdx) => {
                  let isTransparent = false
                  if (row.sortData) {
                    if (!isTransparent && evt.type === 'shift' && row?.sortData?.sortedBy === 'SORT_BY_POSITION' && evt.data.positionId !== row.sortData.id) isTransparent = true
                    if (!isTransparent && evt.type === 'shift' && row?.sortData?.sortedBy === 'SORT_BY_LOCALITY' && evt.data.localityId !== row.sortData.id) isTransparent = true
                    if (!isTransparent && evt.type === 'shift' && row?.sortData?.sortedBy === 'SORT_BY_CONTRACT' && evt.data.contractType !== row.sortData.id) isTransparent = true
                  }

                  let isFirstShiftInDay = false
                  if (!firstShiftInColAlreadyRendered && evt.type === 'shift') {
                    isFirstShiftInDay = true
                    firstShiftInColAlreadyRendered = true
                  }
                  return (
                    <Event
                      type={evt.type}
                      data={evt.data}
                      verticalShift={evt.data.verticalShift}
                      key={'evt_' + idx.toString() + '_' + colIdx.toString() + '_' + evtIdx.toString()}
                      day={day}
                      isLockedDay={isLockedDay}
                      rowEmployee={row.employee}
                      isCycleCalendar={isCycleCalendar ?? false}
                      cycleCalendar={calendar}
                      isEmployeeCalendar={isEmployeeCalendar}
                      isTransparent={isTransparent}
                      isCyclePreview={isCyclePreview}
                      idx={idx}
                      cycleGroups={cycleGroups}
                      evtIdx={evtIdx}
                      isExternalEmployeeRow={isExternalEmployeeRow}
                      isFirstShiftInDay={isFirstShiftInDay}
                      whichSection={whichSection}
                      sortedBy={sortedBy}
                    />
                  )
                }
                )}

                {/* Hover Buttons */}
                {tooltipHTML
                  ? (
                    <Tooltip
                      clickable
                      openOnMount
                      anchor={hoverBtns}
                      width='11.875rem'
                      closeCallback={() => {
                        // when the tooltip is closed, set the window.dsMsTooltipWasRecentlyClosed flag to current timestamp.
                        // it's used to prevent opening the editation modals when clicked on hoverbtns immediatelly after the event tooltip is closed.
                        window.dsMsTooltipWasRecentlyClosed = Date.now()
                        console.log('setting dsMsTooltipWasRecentlyClosed', window.dsMsTooltipWasRecentlyClosed)
                      }}

                    >
                      {tooltipHTML}
                    </Tooltip>
                  )
                  : hoverBtns}
              </div>
            )
          })
        }
      </div>
    )
  }

  // adds 'verticalShift' property to mutually overlapping events
  // in the row, which is then used to prevent shift overlapping in 'day' view
  _getDayRowWithoutOverlapping (rowParam) {
    const row = Object.assign({}, rowParam)
    if (!row.events || !row.events.filter(ev => ev.length).length) return row // skip computation if there are no cols with events in this row

    // misc. functions
    const overlaps = (e1, e2) => {
      return moment(e1.event.data.period.end).isAfter(e2.event.data.period.start) && moment(e1.event.data.period.start).isBefore(e2.event.data.period.end)
    }
    const earlierOf = (mom1, mom2) => {
      return moment(mom1).isBefore(mom2) ? mom1 : mom2
    }
    const laterOf = (mom1, mom2) => {
      return moment(mom1).isAfter(mom2) ? mom1 : mom2
    }

    // get row's events in a straight 1D array, sorted by start hour, for easier traversal
    const flatEvts = []
    row.events.forEach((col, hr) => {
      col.forEach((evt, evtIdx) => {
        flatEvts.push({
          event: evt,
          colIdx: hr,
          evtIdx: evtIdx
        })
      })
    })

    // find the groups of overlapping events
    const groups = []
    flatEvts.forEach((evt, evtFlatIdx) => {
      let addedToGroup = false
      groups.forEach((group, grpIdx) => {
        group.evts.forEach((evtInGrp) => {
          if (overlaps(evt, evtInGrp) && !groups[grpIdx].evts.find(ev => ev.event.data.id === evt.event.data.id)) {
            groups[grpIdx].evts.push(evt)
            groups[grpIdx].start = earlierOf(evt.event.data.period.start, groups[grpIdx].start)
            groups[grpIdx].end = laterOf(evt.event.data.period.end, groups[grpIdx].end)
            addedToGroup = true
          }
        })
      })
      if (!addedToGroup) {
        groups.push({
          evts: [evt],
          start: evt.event.data.period.start,
          end: evt.event.data.period.end
        })
      }
    })

    // set each shift's verticalShift to it's index in the group
    groups.forEach((group) => {
      const grpStartTimes = [] // however, if more events start at the same time, only the first one of them should have the verticalShift
      group.evts.forEach((evtInGrp, idxInGrp) => {
        const startTimeStr = moment(row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data.period.start).format()

        // A compromise I'm willing to make

        const newData = Object.assign({}, row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data)
        newData.verticalShift = idxInGrp
        row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data = newData
        grpStartTimes.push(startTimeStr)

        // if (!grpStartTimes.includes(startTimeStr)) {
        //   const newData = Object.assign({}, row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data)
        //   newData.verticalShift = idxInGrp
        //   row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data = newData
        //   grpStartTimes.push(startTimeStr)
        // } else {
        //   // console.log('skipping', row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data)
        //   const newData = Object.assign({}, row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data)
        //   newData.verticalShift = 0
        //   row.events[evtInGrp.colIdx][evtInGrp.evtIdx].data = newData
        //   grpStartTimes.push(startTimeStr)
        // }
      })
    })

    return row
  }

  render () {
    let row = this.props.row
    const isHighlighted = !this.props.isEmployeeCalendar && this.props.calendarSearch && this.props.calendarSearch.results.find(r => r.id === row.employee)

    if (this.props.notVisible) {
      return <div className={'ds-c-row ' + (isHighlighted ? 'is-highlighted' : '')} style={{ minHeight: this.props.computedRowHeight ? (this.props.computedRowHeight + 'px') : undefined }} />
    }

    const { idx, isEmployeeCalendar, calendar } = this.props
    if (isEmployeeCalendar || calendar.view === 'day') {
      row = Object.assign({}, this._getDayRowWithoutOverlapping(row))
    }

    const res = this.getRowHTML(row, idx, isHighlighted)

    return res
  }
}

export default connect(Row)
