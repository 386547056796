import React, { useState } from 'react'
import { Dropdown } from '@ui'
import { setLocale } from '@app/ac'
import { useDispatch } from 'react-redux'
import { getLanguage } from '@app/util/i18n'
import config from '@app/const/config'

export const LanguagePicker = () => {
  const [locale, setLocalLocale] = useState(getLanguage())
  const dispatch = useDispatch()
  const languageOptions = config.supportedLanguages

  return (
    <Dropdown
      style={Dropdown.STYLES.BORDERLESS}
      singleSelect
      type={Dropdown.TYPES.VARIABLE}
      value={languageOptions.find((option) => option.value === locale)}
      options={languageOptions}
      onChange={({ value }) => {
        dispatch(setLocale(value))
        setLocalLocale(value)
      }}
    />
  )
}
