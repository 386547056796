import './index.scss'
import React from 'react'
import { apiEndpoints } from '@app/const/config'
import connect from './connect'

class AdminPanel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      session: null
    }

    setTimeout(() => this.setState({ loaded: true }), 200)
  }

  _authLoaded () {
    const child = document.getElementById('adminAuth').contentWindow
    child.postMessage('ds-admin-auth', '*')
    window.onmessage = (e) => {
      try {
        if (e.data.key === 'ds-admin-session') {
          console.log('Received ds-admin session', e.data.data)
          this.setState({ session: e.data.data || null })
        }
      } catch (error) {
        console.log('AdminPanel._authLoaded() method raised an error.', error)
      }
    }
  }

  render () {
    const { location: { hostname } } = window
    const { [hostname]: { admin } } = apiEndpoints

    const { app } = this.props
    if (!admin) {
      return null
    }

    if (this.state.loaded && this.state.session) {
      const rootElem = document.getElementById('ds-root')
      if (rootElem) rootElem.classList.add('has-admin-panel')
    }

    return this.state.loaded ? (
      <div className='ds-adminpanel-wrapper'>
        <iframe id='adminAuth' src={`${admin}auth.html`} onLoad={this._authLoaded.bind(this)} width='1' height='1' />
        {this.state.session ? (
          <div className='ds-adminpanel' style={{ backgroundColor: this.state.session.env.color }}>
            <div className='ds-adminpanel-left'>
              <div className='ds-adminpanel-item'>
                <span>WS:&nbsp;</span>
                {app.workspaceId ? (
                  <a
                    href={`${admin}#!/db/workspaces/${app.workspaceId}`} rel='noopener noreferrer'
                    target='_blank'
                  >{app.workspaceId}
                  </a>
                ) : (
                  <span>(null)</span>
                )}
              </div>
              {app.organizationId && (
                <div className='ds-adminpanel-item'>
                  <span>Org:&nbsp;</span>
                  <a
                    href={`${admin}#!/db/organizations/${app.organizationId}`} rel='noopener noreferrer'
                    target='_blank'
                  >{app.organizationId}
                  </a>
                </div>
              )}
              {app.me ? (
                <div className='ds-adminpanel-item'>User:&nbsp;
                  {app.me.id ? (
                    <div>
                      <a
                        href={`${admin}#!/db/users/${app.me.id}`} rel='noopener noreferrer'
                        target='_blank'
                      >{app.me.id}
                      </a>
                      <small>&nbsp;({app.me.email})</small>
                    </div>
                  ) : (
                    <span>(null)</span>
                  )}
                </div>
              ) : null}
            </div>
            <div className='ds-adminpanel-right'>
              <div className='ds-adminpanel-item'>
                <a href={`${admin}#!/`} rel='noopener noreferrer' target='_blank'>Go to Admin</a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null
  }
}

export default connect(AdminPanel)
