import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation TemplateCreateMutation (
  $workspace: ID!,
  $name: String!,
  $color: String,
  $days: [Int]
  $requirements: [TemplateRequirementInput]
) {
  templateCreate (
    workspace: $workspace
    data: {
      name: $name
      color: $color
      requirements: $requirements
      days: $days
    }
  ) {
    id
  }
}`

async function requestTemplateCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('template-create', errors)
  }
  return data.templateCreate
}

export default requestTemplateCreate
