import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModal } from '@app/ac'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    close: () => setModal(null)
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
