import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `mutation CyclesPlanMutation (
  $workspace: ID!,
  $period: PeriodInput!
  $cycles: [ID!]
  $users: [CyclePlanUsers]
  $createUnassigned: Boolean
  $fromUnassigned: Boolean
  $overrides: [CyclePlanOverrides]
) {
  cyclesPlan (
    workspace: $workspace
    period: $period
    cycles: $cycles
    users: $users
    createUnassigned: $createUnassigned
    fromUnassigned: $fromUnassigned
    overrides: $overrides
  ) {
    shifts {
      id
    }
    notAddedShifts {
      ${q.SHIFT_PARAMS_BASIC}
    }
  }
}`

async function requestCyclesPlan (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('cycles-plan', errors)
  }
  return data.cyclesPlan
}

export default requestCyclesPlan
