"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNullableTableConfig = exports.peekEditedTableCellValue = exports.getTableCellValues = exports.getTableCellRow = void 0;
const util_1 = require("@app/util");
const getTableCellRow = (e) => {
    var _a, _b;
    return (_b = (_a = e.cell) === null || _a === void 0 ? void 0 : _a.row) === null || _b === void 0 ? void 0 : _b.original;
};
exports.getTableCellRow = getTableCellRow;
const getTableCellValues = (e) => {
    var _a, _b;
    return (_b = (_a = e.cell) === null || _a === void 0 ? void 0 : _a.row) === null || _b === void 0 ? void 0 : _b.values;
};
exports.getTableCellValues = getTableCellValues;
const peekEditedTableCellValue = (e, id) => e.allEditedRows.find((aer) => aer.id === id);
exports.peekEditedTableCellValue = peekEditedTableCellValue;
const createNullableTableConfig = (config) => config.filter(util_1.isValue);
exports.createNullableTableConfig = createNullableTableConfig;
