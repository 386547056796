import React, { useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

import {
  Button,
  Input,
  Select,
  Modal,
  Flex,
  Spacing
} from '@ui'

import connect from './connect'

const AreaCreate = ({
  close,
  countries,
  organizationId,
  createArea
}) => {
  const [form, setForm] = useState({
    organization: organizationId,
    country: undefined,
    name: undefined
  })

  const countriesOptions = countries.map((country) => ({
    value: country.id,
    label: country.code
  }))

  return (
    <Modal
      size={Modal.SIZES.S}
      footerContent={
        <Flex stretch>
          <Button
            onClick={close}
            label={t('CLOSE')}
            size={Button.SIZES.FULL}
          />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            onClick={() => createArea(form)}
            label={t('SAVE')}
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
          />
        </Flex>
      }
      headerContent={<div className='ds-title'>{t('ORG_SP_WORKSPACES_AREAS_ADD_NEW')}</div>}
      sections={[
        <>
          <Input
            size={Input.SIZES.FULL_WIDTH}
            label={t('ORG_SP_WORKSPACES_AREAS_NAME_LABEL')}
            placeholder={t('ORG_SP_WORKSPACES_AREAS_NAME_PLACEHOLDER')}
            onChange={(val) => setForm({ ...form, name: val })}
            value={form.name}
          />
          <Select
            label={t('ORG_SP_WORKSPACES_COUNTRIES')}
            placeholder={t('CHOOSE')}
            onChange={(val) => setForm({ ...form, country: val })}
            options={countriesOptions}
            value={form.country}
          />
        </>
      ]}
    />
  )
}

AreaCreate.propTypes = {
  close: PropTypes.func.isRequired
}
AreaCreate.defaultProps = {}

export default connect(AreaCreate)
