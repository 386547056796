import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    app: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    adminPanel: (data) => {
      return async (dispatch) => {
        return dispatch({ type: 'show-admin-panel', data })
      }
    }
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
