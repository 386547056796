import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ShiftDeleteMutation (
  $workspace: ID!,
  $shift: ID!
) {
  shiftDelete (
    workspace: $workspace
    id: $shift
  )
}`

async function requestShiftDelete (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-delete', errors)
  }
  return data.shiftDelete
}

export default requestShiftDelete
