import { useState } from 'react'
import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react-dom-interactions'
import { autoUpdate, flip } from '@floating-ui/react-dom'

export const useFloatable = (callback) => {
  const [open, toggleOpen] = useState(false)
  const { x, y, context, reference, floating, strategy } = useFloating({
    open,
    onOpenChange: handleOpen,
    strategy: 'fixed',
    placement: 'bottom-start',
    middleware: [flip()],
    whileElementsMounted: (reference, floating, update) =>
      autoUpdate(reference, floating, update, {
        animationFrame: true
      })
  })

  function handleOpen (state) {
    // onClose callback
    if (!state && !!callback) callback()
    toggleOpen(state)
  }

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      outsidePointerDown: true
    })
  ])
  const floatAnchor = { ...getReferenceProps({ ref: reference }) }
  const floatContainer = {
    ...getFloatingProps({
      ref: floating,
      style: {
        position: strategy,
        left: x ?? 0,
        top: y ?? 0
      }
    })
  }
  return {
    open,
    setOpen: handleOpen,
    floatAnchor,
    floatContainer
  }
}
