import { fetchRequest, errorUtil } from '@app/util'
import q from '../query'

const query = `query OrganizationBasic($organizationId: ID!) {
  organization(id: $organizationId) {
    ${q.ORGANIZATION_PARAMS_BASIC}

    members {
      id
      email
      name
      telephone
    }

    countries {
      ${q.ORGANIZATION_COUNTRY_PARAMS_BASIC}
    }
  }
}`

async function requestOrganizationBasic (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrganizationBasic
