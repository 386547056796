import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation AreaUpdate($organization: ID!, $area: ID!, $name: String!) {
  areaUpdate(organization: $organization, area: $area, name: $name) {
    id
  }
}`

async function requestAreaUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data
}

export default requestAreaUpdate
