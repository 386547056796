import store from '../store'
import { requestShiftApproveMulti } from '../request/'
import addError from '../ac/add-error'
import isLoading from './is-loading'

export default (shifts) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !shifts.length === 0) return false

    dispatch(isLoading('update-shift-approve-multi'))

    let approved = []
    let result = null

    const chunkSize = 1000 // if there are many shifts for approval, do it in multiple requests, because there's a limit for reasonable request size
    for (let i = 0; i < shifts.length; i += chunkSize) {
      const chunk = shifts.slice(i, i + chunkSize)
      result = await requestShiftApproveMulti(
        {
          workspace: workspaceId,
          shifts: chunk
        },
        auth
      )
      if (result && !result.error && result.length) {
        approved = approved.concat(result)
      }
    }

    if (result && result.error) {
      dispatch(addError(result.error))
    }

    dispatch(isLoading('update-shift-approve-multi', true))
    return approved
  }
}
