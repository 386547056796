import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestTimeOffsUser (variables, token) {
  const query = `query TimeOffsUserQuery (
    $user: ID!,
    $workspace: ID!,
    $period: PeriodFreeInput!
    $categoryId: String
  ) {
    user (
      id: $user
    ) {
      timeOffs (
        period: $period
        workspace: $workspace
        categoryId: $categoryId
      ) ${q.TIME_OFF}
    }
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('timeOffs', errors)
  }
  return data.user.timeOffs
}

export default requestTimeOffsUser
