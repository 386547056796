import isLoading from './is-loading'
import requestShifts from '../request/shifts'
import addError from '../ac/add-error'
import loadEventWarnings from '../ac/load-event-warnings'
import setShifts from '../action/set-shifts'
import store from '../store'
import { NO_LOCALITY } from '@app/const/globals'
import moment from 'moment'
import { permissionsUtil } from '@app/util'
import { PERMISSIONS } from '@app/const'

export default ({
  period,
  users,
  warningsHidden,
  locality,
  forManagerCalendar
}) => {
  // NOTE: The 'users' prop can be either:
  // - an array of user IDs (we only load shifts of users from that array)
  // - a string with a single user ID (we only load shifts of that one user)
  // - undefined/null (we load shifts of all users in the WS)
  return async (dispatch) => {
    const { auth, workspaceId, shifts, workspaces } = store.getState()
    if (!workspaceId || !period) return
    const ws = workspaces?.find(w => w.id === workspaceId)

    // get the shifts for the period from BE
    dispatch(isLoading('load-shifts'))
    let resultShifts = await requestShifts({
      id: workspaceId,
      period,
      user: !users ? undefined : (typeof users === 'string' ? users : undefined),
      users: !users ? undefined : (typeof users === 'string' ? undefined : users),
      locality: (typeof locality !== 'undefined')
        ? locality === NO_LOCALITY
          ? null
          : locality
        : undefined,
      useOrFilter: Boolean((typeof locality !== 'undefined') && (typeof users !== 'undefined')) // we use OR (conjunction) filtering logic when we have locality and users filters enabled (this normally happens due to workspace.enforcedLocality)
    }, auth, {
      // IF we're loading shifts for manager calendar
      // AND current user has READ-ONLY calendar access
      // AND WS.settings.showOnlyCommittedInReadOnlyCalendar is true
      // THEN make sure we request only committed shifts
      includeUncommitted: (forManagerCalendar && !permissionsUtil.canWrite(PERMISSIONS.CALENDAR))
        ? (!ws?.settings?.showOnlyCommittedInReadOnlyCalendar)
        : undefined
    })

    if (resultShifts.error) {
      dispatch(addError(resultShifts.error))
    } else {
      // add all the other, previously loaded shifts to the list
      resultShifts = resultShifts.concat(shifts.filter(os => !resultShifts.find(rs => rs.id === os.id)))

      await dispatch(setShifts(resultShifts))

      // and asynchronously get the warnings for those shifts
      if (!warningsHidden) {
        let warningsPeriod = Object.assign({}, period)

        // skip the past shifts (if the 'attendance' plugin is not enabled)
        const ws = workspaces.find(w => w.id === workspaceId)
        if (!ws || !ws.plugins || !ws.plugins.find(p => p.plugin === 'attendance' && p.enabled)) {
          if (moment(warningsPeriod.start).isBefore(moment().startOf('day'))) warningsPeriod.start = moment().startOf('day')
          if (moment(warningsPeriod.end).isSameOrBefore(warningsPeriod.start)) warningsPeriod = null
        }

        if (warningsPeriod) {
          dispatch(loadEventWarnings(warningsPeriod, users))
        }
      }
    }
    dispatch(isLoading('load-shifts', true))
    return resultShifts
  }
}
