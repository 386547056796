"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserDetailsCustomAttributes = void 0;
const user_details_1 = require("@app/reducer/user-details");
const store_1 = require("@app/store");
const user_details_custom_attributes_1 = require("../../request/user-details-custom-attributes");
const employee_card_context_1 = require("../employee-card-context");
const useEmployeeCardData_1 = require("./useEmployeeCardData");
const useUserDetailsCustomAttributes = () => {
    var _a;
    const { workspace, employee } = (0, employee_card_context_1.useEmployeeCard)();
    const dispatch = (0, store_1.useRootDispatch)();
    const { mutate, data } = (0, useEmployeeCardData_1.useEmployeeCardData)({
        actionName: 'user-details-custom-attributes',
        requestor: () => __awaiter(void 0, void 0, void 0, function* () { var _b, _c, _d; return yield (0, user_details_custom_attributes_1.requestUserDetailsCustomAttributes)({ workspaceId: (_b = workspace === null || workspace === void 0 ? void 0 : workspace.id) !== null && _b !== void 0 ? _b : '', userId: (_d = (_c = employee.user) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '' }); }),
        reducer: (data) => dispatch((0, user_details_1.setUserCustomAttributes)(data === null || data === void 0 ? void 0 : data.customAttributes)),
        selector: (state) => state.userDetails.customAttributes,
        // @ts-expect-error
        mutator: (inputData) => __awaiter(void 0, void 0, void 0, function* () { var _e, _f, _g; return yield (0, user_details_custom_attributes_1.mutateUserDetailsCustomAttributes)({ workspaceId: (_e = workspace === null || workspace === void 0 ? void 0 : workspace.id) !== null && _e !== void 0 ? _e : '', userId: (_g = (_f = employee.user) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : '', data: { customAttributes: inputData } }); }),
        listenDeps: [workspace === null || workspace === void 0 ? void 0 : workspace.id, (_a = employee.user) === null || _a === void 0 ? void 0 : _a.id],
    });
    return [data, mutate];
};
exports.useUserDetailsCustomAttributes = useUserDetailsCustomAttributes;
