export const workspaceNotifications = {
  employee: [
    {
      label: 'MANAGER_EMPLOYEE_JOINED_WORKSPACE',
      value: 'employee_manager_employee_joined_workspace'
    },
    {
      label: 'MANAGER_EMPLOYEE_BIRTHDAY',
      value: 'employee_manager_employee_birthday'
    }
  ],
  transfers: [
    {
      label: 'OFFER_REQUEST_TRANSFER_BULK',
      value: 'transfers_offer_request_transfer_bulk'
    },
    {
      label: 'OFFER_TRANSFER_CHOSEN_MANAGER',
      value: 'transfers_offer_transfer_chosen_manager'
    }
  ],
  timeoffs: [
    {
      label: 'TIMEOFF_APPROVE_REQUEST',
      value: 'timeoffs_timeoff_approve_request'
    },
    {
      label: 'TIMEOFF_REQUEST_APPROVED_AUTOMATICALLY',
      value: 'timeoffs_timeoff_request_approved_automatically'
    }
  ],
  shifts: [
    {
      label: 'OFFER_APPROVE_RESULT_AUTO',
      value: 'shifts_offer_approve_result_auto'
    },
    {
      label: 'OFFER_SWAP_SUCCESSFUL_RESULT',
      value: 'shifts_offer_swap_successful_result'
    },
    {
      label: 'OFFER_APPROVE_REQUEST',
      value: 'shifts_offer_approve_request'
    },
    {
      label: 'OFFER_APPROVE_RESULT',
      value: 'shifts_offer_approve_result'
    },
    {
      label: 'OFFER_UNRESOLVED',
      value: 'shifts_offer_unresolved'
    },
    {
      label: 'OFFER_APPROVE_RESULT_FIRST',
      value: 'shifts_offer_approve_result_first'
    },
    {
      label: 'ANNOUNCE_HPP_WITHOUT_PLAN',
      value: 'shifts_announce_hpp_without_plan'
    },
    {
      label: 'ANNOUNCE_AUTOPLANNING',
      value: 'shifts_announce_autoplanning'
    }
  ],
  attendance: [
    {
      label: 'AREA_MANAGER_ATTENDANCE_APPROVAL',
      value: 'attendance_area_manager_attendance_approval'
    },
    {
      label: 'ANNOUNCE_UNAPPROVED_ATTENDANCE',
      value: 'attendance_announce_unapproved_attendance'
    },
    {
      label: 'ATTENDANCE_MISSING_RECORDS_ON_TERMINAL',
      value: 'attendance_terminal_checks'
    }
  ],
  communication: [
    {
      label: 'NEWS_FEED_NEW_MESSAGE',
      value: 'communication_news_feed_new_message'
    }
  ]
}
