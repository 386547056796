import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

const SIDES = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  TOP: 'top'
})

const COLORS = Object.freeze({
  LIGHT_BLUE1: 'light_blue1',
  TRANSPARENT: 'transparent'
})

const SIZES = Object.freeze({
  FULL_WIDTH: 'full-width',
  FULL_HEIGHT: 'full-height'
})

export const Separator = ({
  side,
  color,
  children,
  size
}) => (
  <div className={classNames(
    'ds-separator',
    side,
    color,
    size,
    {
      'no-child': !children
    }
  )}
  >
    {children}
  </div>
)

Separator.propTypes = {
  side: PropTypes.oneOf(Object.values(SIDES)),
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  children: PropTypes.node
}
Separator.defaultProps = {
  side: SIDES.BOTTOM,
  color: COLORS.LIGHT_BLUE1,
  size: null,
  children: null
}

Separator.SIDES = SIDES
Separator.SIZES = SIZES
Separator.COLORS = COLORS
