import './index.scss'
import React from 'react'
import connect from './connect'
import moment from 'moment'
import { Button, Icon } from '@ui'

class WeekSelect extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selected: null,
      displayedMonth: (this.props.calendar && this.props.calendar.date) ? moment(this.props.calendar.date).format('YYYY-MM') : moment().format('YYYY-MM')
    }
  }

  componentDidMount () {
    if (this.props.value) {
      this.setState((s) => Object.assign({}, s, { selected: moment(this.props.value).startOf('week').format('YYYY-MM-DD') }))
    }
    if (this.props.executeOnMonthSelect) this.props.executeOnMonthSelect(moment(this.state.displayedMonth).format('YYYY-MM-DD'))
  }

  getCalRows () {
    const rows = []
    let i = 0
    let forward = moment(this.state.displayedMonth).add(i, 'weeks').startOf('week')
    let back = moment(this.state.displayedMonth).add(-i, 'weeks').endOf('week')

    while (forward.format('YYYY-MM') === this.state.displayedMonth || back.format('YYYY-MM') === this.state.displayedMonth) {
      const rowBack = []
      const rowForward = []
      for (var j = 0; j < 7; j++) {
        rowBack.push({
          dayNum: back.clone().startOf('week').add(j, 'days').format('D'),
          thisMonth: back.clone().startOf('week').add(j, 'days').format('YYYY-MM') === this.state.displayedMonth,
          isToday: back.clone().startOf('week').add(j, 'days').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        })
        rowForward.push({
          dayNum: forward.clone().startOf('week').add(j, 'days').format('D'),
          thisMonth: forward.clone().startOf('week').add(j, 'days').format('YYYY-MM') === this.state.displayedMonth,
          isToday: forward.clone().startOf('week').add(j, 'days').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        })
      }
      if (back.format('YYYY-MM') === this.state.displayedMonth) {
        rows.unshift({ days: rowBack, date: back.clone().startOf('week') })
      }
      if (forward.format('YYYY-MM') === this.state.displayedMonth) {
        rows.push({ days: rowForward, date: forward.clone().startOf('week') })
      }

      i += 1
      forward = moment(this.state.displayedMonth).add(i, 'weeks').startOf('week')
      back = moment(this.state.displayedMonth).add(-i, 'weeks').endOf('week')
    }

    // make sure we don't have duplicate weeks in the list
    if (rows.length >= 2 && rows[0].date.format('YYYY-MM-DD') === rows[1].date.format('YYYY-MM-DD')) {
      rows.shift()
    }

    return rows
  }

  selectWeek (d) {
    const origSelection = this.state.selected

    // prevent selecting pst weeks if props.preventPast
    if (this.props.forbiddenPast && moment(d).startOf('week').isBefore(moment())) return

    // set the state
    this.setState((s) => Object.assign({}, s, { selected: moment(d).format('YYYY-MM-DD') }))

    // onChange(d) call
    if (moment(d).format('YYYY-MM-DD') !== origSelection && this.props.onChange) this.props.onChange(moment(d).format('YYYY-MM-DD'))
  }

  render () {
    const { className } = this.props
    const cN = ['ds-week-select']
    if (className) cN.push(className)
    const rows = this.getCalRows()
    let titleTxt = moment(this.state.displayedMonth).format('MMMM YYYY')
    titleTxt = titleTxt.charAt(0).toUpperCase() + titleTxt.slice(1)

    return (
      <div className={cN.join(' ')}>
        <div className='ds-week-select-controls'>
          <Button
            style={Button.STYLES.LIGHT}
            ico={Icon.ICONS.arrowLeft}
            onClick={(e) => {
              const newMonth = moment(this.state.displayedMonth, 'YYYY-MM').add(-1, 'month')
              this.setState((s) => Object.assign({}, s, { displayedMonth: newMonth.format('YYYY-MM') }))

              if (this.props.executeOnMonthSelect) this.props.executeOnMonthSelect(newMonth.format('YYYY-MM-DD'))
              e.stopPropagation()
            }}
          />
          <div className='ds-title'>{titleTxt}</div>
          <Button
            style={Button.STYLES.LIGHT}
            ico={Icon.ICONS.arrowRight}
            onClick={(e) => {
              const newMonth = moment(this.state.displayedMonth, 'YYYY-MM').add(1, 'month')
              this.setState((s) => Object.assign({}, s, { displayedMonth: newMonth.format('YYYY-MM') }))

              if (this.props.executeOnMonthSelect) this.props.executeOnMonthSelect(newMonth.format('YYYY-MM-DD'))
              e.stopPropagation()
            }}
          />
        </div>

        <div className='ds-week-select-cal-weekdays'>
          <div className='ds-week-select-cal-weekdays-day'>{moment().startOf('week').add(0, 'days').format('ddd')}</div>
          <div className='ds-week-select-cal-weekdays-day'>{moment().startOf('week').add(1, 'days').format('ddd')}</div>
          <div className='ds-week-select-cal-weekdays-day'>{moment().startOf('week').add(2, 'days').format('ddd')}</div>
          <div className='ds-week-select-cal-weekdays-day'>{moment().startOf('week').add(3, 'days').format('ddd')}</div>
          <div className='ds-week-select-cal-weekdays-day'>{moment().startOf('week').add(4, 'days').format('ddd')}</div>
          <div className='ds-week-select-cal-weekdays-day'>{moment().startOf('week').add(5, 'days').format('ddd')}</div>
          <div className='ds-week-select-cal-weekdays-day'>{moment().startOf('week').add(6, 'days').format('ddd')}</div>
        </div>

        {rows.map((r, iw) =>
          <div
            key={'week_' + iw.toString()}
            className={['ds-week-select-cal-week', this.state.selected === r.date.format('YYYY-MM-DD') ? 'selected-week' : null, this.props.forbiddenPast && r.date.startOf('week').isBefore(moment()) ? 'is-past-forbidden' : null].join(' ')}
            onClick={(e) => {
              this.selectWeek(r.date)
            }}
          >
            {r.days.map((d, id) =>
              <div
                key={'day_' + iw.toString() + '_' + id.toString()}
                className={['ds-week-select-cal-day', d.thisMonth ? null : 'other-month', d.isToday ? 'today' : null].join(' ')}
              >
                {d.dayNum}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default connect(WeekSelect)
