"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkspace = void 0;
const store_1 = require("@app/store");
const selector = (state) => ({ workspace: state.workspaces.find(ws => ws.id === state.workspaceId) });
const useWorkspace = () => {
    const { workspace } = (0, store_1.useRootSelector)(selector);
    return workspace;
};
exports.useWorkspace = useWorkspace;
