import { fetchRequest, errorUtil } from '@app/util'

const query = `query WorkspaceRolesArchived ($workspaceId: ID!, $users: [ID!]) {
  workspace(id: $workspaceId) {
    roles (archived: true, users: $users) {
      userId
      customData {
          firstName
          lastName
          telephone
      }
      user {
          firstName
          lastName
          email
          telephone
      }
      terminateDate
    }
  }
}`

async function requestWorkspaceRolesArchived (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('workspace-roles-archived', errors)
  }
  return data.workspace.roles
}

export default requestWorkspaceRolesArchived
