import {
  SetFeedAction,
  AddFeedAction,
  UpdateFeedAction,
  DeleteFeedAction,
  CommentFeedAction,
  LikeFeedAction,
  ReadFeedAction,
  PinFeedAction
} from '../action/set-news-feed'
import { ApolloClientFetch } from '@app/util/apollo-client-fetch'
import { FEED_ITEM_QUERY } from '@app/request/query'
import { gql } from '@apollo/client'
import { loadWorkspaceNames } from '@app/ac'
import store from '../store'

export const AddFeed = (data = {}) => {
  return async (dispatch) => {
    await dispatch(AddFeedAction(data))
  }
}

export const UpdateFeed = (data = {}) => {
  return async (dispatch) => {
    await dispatch(UpdateFeedAction(data))
  }
}

export const SetFeeds = (data) => {
  const { auth, userId } = data
  return (dispatch) => {
    ApolloClientFetch(auth, 'MyNewsFeed')
      .query({
        query: gql`${FEED_ITEM_QUERY}`,
        variables: {
          userId
        }
      })
      .then(res => {
        const { workspaces } = store.getState()
        const { data: { user: { feed } } } = res
        dispatch(SetFeedAction(feed))

        // if the WS names of some feed items are unknown, load them
        if (Array.isArray(res?.data?.user?.feed)) {
          const wsIdsWithNoNames = []
          res.data.user.feed.forEach(f => {
            const ws = workspaces.find(w => w.id === f.workspaceId)
            if (ws?.id && (typeof ws?.name !== 'string')) {
              if (!wsIdsWithNoNames.includes(f.workspaceId)) wsIdsWithNoNames.push(f.workspaceId)
            }
          })
          if (wsIdsWithNoNames.length) dispatch(loadWorkspaceNames(wsIdsWithNoNames))
        }
      })
  }
}

export const DeleteFeed = (data = {}) => {
  return async (dispatch) => {
    await dispatch(DeleteFeedAction(data))
  }
}

export const CommentFeed = (data = {}) => {
  return async (dispatch) => {
    await dispatch(CommentFeedAction(data))
  }
}

export const LikeFeed = (data = {}) => {
  return async (dispatch) => {
    await dispatch(LikeFeedAction(data))
  }
}

export const ReadFeed = (data = {}) => {
  return async (dispatch) => {
    await dispatch(ReadFeedAction(data))
  }
}

export const PinFeed = (data = {}) => {
  return async (dispatch) => {
    await dispatch(PinFeedAction(data))
  }
}
