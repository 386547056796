"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValue = exports.isNull = exports.isNotNull = exports.isUndefined = exports.isDefined = void 0;
/**
* @see isValue
*/
const isDefined = (value) => value !== undefined;
exports.isDefined = isDefined;
/**
* @see isValue
*/
const isUndefined = (value) => value === undefined;
exports.isUndefined = isUndefined;
/**
* @see isValue
*/
const isNotNull = (value) => value !== null;
exports.isNotNull = isNotNull;
/**
* @see isValue
*/
const isNull = (value) => value === null;
exports.isNull = isNull;
const isValue = (value) => (0, exports.isDefined)(value) && (0, exports.isNotNull)(value);
exports.isValue = isValue;
