import isLoading from './is-loading'
import requestShiftsEmployee from '../request/shifts-employee'
import addError from '../ac/add-error'
import setShifts from '../action/set-shifts'
import store from '../store'

export default ({
  period,
  user
}) => {
  return async (dispatch) => {
    const { auth, workspaceId, shifts } = store.getState()
    if (!workspaceId || !period || !user) return

    // get the shifts for the period from BE
    dispatch(isLoading('load-employee-shifts'))
    let resultShifts = await requestShiftsEmployee({
      id: user,
      workspace: workspaceId,
      period
    }, auth)

    if (resultShifts.error) {
      dispatch(addError(resultShifts.error))
    } else {
      // add all the other, previously loaded shifts to the list
      resultShifts = resultShifts.concat(shifts.filter(os => !resultShifts.find(rs => rs.id === os.id)))

      await dispatch(setShifts(resultShifts))
    }
    dispatch(isLoading('load-employee-shifts', true))
    return resultShifts
  }
}
