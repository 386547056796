import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon } from '@ui'

import './style.scss'

const TYPES = Object.freeze({
  MAIN: 'main',
  SUB: 'sub'
})

export const NavItem = ({
  ico,
  text,
  number,
  isActive,
  onClick,
  type,
  dark,
  disabled
}) => (
  <div
    onClick={onClick}
    className={classNames(
      'nav-item',
      type,
      { isActive, dark, disabled }
    )}
  >
    <div className='container'>
      <Icon
        ico={ico}
        color={isActive || dark ? Icon.COLORS.WHITE : Icon.COLORS.INHERIT}
        size={type === 'sub' ? Icon.SIZES.LARGE : Icon.SIZES.MEDIUM}
      />
      <div className='text'>
        {text}
      </div>
    </div>
    {!!number && (<div className='number'>{number > 99 ? '99+' : number}</div>)}
  </div>
)

NavItem.TYPES = TYPES

NavItem.propTypes = {
  ico: PropTypes.oneOf(Object.values(Icon.ICONS)),
  text: PropTypes.string,
  number: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(TYPES)),
  dark: PropTypes.bool,
  disabled: PropTypes.bool
}
NavItem.defaultProps = {
  type: TYPES.MAIN
}
