
// filter out keys with their own reducers
function objectWithoutKeys (obj, skip) {
  const filteredKeys = Object.keys(obj).filter((key) => !skip.includes(key))
  return filteredKeys.reduce((o, key) => {
    o[key] = obj[key]
    return o
  }, {})
}

export default {
  objectWithoutKeys
}
