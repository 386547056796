import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query DummyShiftCandidatesQuery (
  $workspaceId: ID!,
  $data: ShiftData!,
  $includeNonSuitable: Boolean,
  $filterByLocality: Boolean,
  $filterByLocalities: [ID!],
  $filterByPosition: Boolean,
  $filterByPositions: [ID!],
  $filterBySkill: Boolean,
  $filterByWarnings: Boolean
) {
  dummyShift(workspace: $workspaceId, data: $data) {
    offerCandidates (
      includeNonSuitable: $includeNonSuitable
      filterByLocality: $filterByLocality
      filterByLocalities: $filterByLocalities
      filterByPosition: $filterByPosition
      filterByPositions: $filterByPositions
      filterBySkill: $filterBySkill
      filterByWarnings: $filterByWarnings
    ) ${q.DUMMY_CANDIDATE_DETAIL}
  }
}`

async function requestShiftCandidatesDummy (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-candidates', errors)
  }
  return data.dummyShift.offerCandidates
}

export default requestShiftCandidatesDummy
