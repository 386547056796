"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatHours = void 0;
const formatHours = (hours) => {
    if (Number.isNaN(hours)) {
        return '';
    }
    const _hours = Math.floor(hours);
    const _minutes = Math.round(Math.abs(hours) % 1 * 60);
    const _minutesString = _minutes < 10 ? `0${_minutes}` : _minutes.toString();
    return `${_hours}:${_minutesString}`;
};
exports.formatHours = formatHours;
