import React, { useState } from 'react'
import { t } from 'i18next'

import {
  Modal,
  Button,
  Input,
  Select,
  ColorPicker,
  Text,
  Flex,
  Spacing
} from '@ui'

import { getCountryOptions } from '@app/component/pages/organization/helpers'
import connect from './connect'

const PositionCreate = ({
  close,
  createPosition,
  organizationId,
  organization
}) => {
  const [form, setForm] = useState({
    organization: organizationId,
    country: undefined,
    data: {
      name: null,
      color: null
    }
  })

  return (
    <Modal
      size={Modal.SIZES.M}
      footerContent={
        <Flex stretch>
          <Button
            onClick={close}
            size={Button.SIZES.FULL}
            label={t('CLOSE')}
          />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            onClick={() => createPosition(form)}
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
            label={t('SAVE')}
          />
        </Flex>
      }
      headerContent={<div className='ds-title'>{t('ORG_SP_WORKSPACES_POSITIONS_ADD_NEW')}</div>}
      sections={[
        <>
          <Input
            label={t('ORG_SP_WORKSPACES_POSITIONS_NAME_LABEL')}
            placeholder={t('ORG_SP_WORKSPACES_POSITIONS_NAME_PLACEHOLDER')}
            onChange={(val) => setForm({ ...form, data: { ...form.data, name: val } })}
            value={form.data.name}
          />
          <Select
            label={t('ORG_SP_WORKSPACES_COUNTRIES')}
            placeholder={t('CHOOSE')}
            onChange={(val) => setForm({ ...form, country: val })}
            options={getCountryOptions(organization.countries)}
            value={form.country}
          />
          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.VERTICAL}>
            <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>
              {t('COLOR')}
            </Text>
          </Spacing>
          <ColorPicker
            value={form.data.color}
            onChange={(val) => setForm({ ...form, data: { ...form.data, color: val } })}
          />
        </>
      ]}
    />
  )
}

PositionCreate.propTypes = {}
PositionCreate.defaultProps = {}

export default connect(PositionCreate)
