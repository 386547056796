import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import setModal from '@app/ac/set-modal'

const mapStateToProps = ({ organization, organizationId, auth }) => {
  return {
    countries: organization.countries,
    organizationId,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
