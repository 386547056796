import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation RequirementDeleteMutation (
  $workspace: ID!,
  $template: ID!,
  $requirement: ID!
) {
  requirementDelete (
    workspace: $workspace
    template: $template
    requirement: $requirement
  ) {
    id
  }
}`

async function requestRequirementDelete (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('requirement-delete', errors)
  }
  return data.requirementDelete
}

export default requestRequirementDelete
