import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestLocalityAssign from '@app/request/locality-assign'
import setEmployees from '../action/set-employees'
import setEmployeeDetail from '../action/set-employee-detail'

export default (userId, localityId) => {
  return async (dispatch) => {
    const {
      auth,
      workspaceId
    } = store.getState()
    if (!workspaceId || !localityId) return

    dispatch(isLoading('assign-locality'))

    const result = await requestLocalityAssign({
      locality: localityId,
      workspace: workspaceId,
      user: userId
    }, auth)

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    } else {
      const { employees, employeeDetail } = store.getState()

      const newEmps = Object.assign({}, employees)
      const newLocs = (newEmps[userId] && newEmps[userId].localities) ? newEmps[userId].localities.concat([localityId]) : [localityId]
      if (newEmps[userId]) newEmps[userId] = Object.assign({}, newEmps[userId], { localities: newLocs })
      await dispatch(setEmployees(newEmps))

      if (employeeDetail && employeeDetail.id === userId) {
        await dispatch(setEmployeeDetail(Object.assign({}, employeeDetail, { localities: newLocs })))
      }
    }

    dispatch(isLoading('assign-locality', true))
    return result
  }
}
