import isLoading from './is-loading'
import requestWorkspaceEventCreate from '../request/workspace-event-create'
import loadWorkspaceEvents from '@app/ac/load-workspace-events'
import addError from '../ac/add-error'
import store from '../store'
import moment from 'moment'

export default (data) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !data) return

    dispatch(isLoading('create-workspace-event'))
    const result = await requestWorkspaceEventCreate({ workspaceId, data }, auth)
    if (result.error) {
      dispatch(addError(result.error))
      dispatch(isLoading('create-workspace-event', true))
      return false
    }

    dispatch(isLoading('create-workspace-event', true))

    // reload
    dispatch(loadWorkspaceEvents({ type: null, period: { start: moment(data.period.start).startOf('day'), end: moment(data.period.start).endOf('day') } }))

    return result
  }
}
