import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation LocalityDeleteMutation (
  $workspace: ID!,
  $id: ID!
) {
  localityDelete (
    workspace: $workspace
    id: $id
  )
}`

async function requestLocalityDelete (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('locality-delete', errors)
  }
  return data.localityDelete
}

export default requestLocalityDelete
