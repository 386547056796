import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

// doesNotWorkDaysOfWeek is [1..7]
const query = `mutation UserInviteMutation (
  $workspace: ID!,
  $mergeWith: ID!,
  $email: String!,
  $userData: UserInputData,
  $roleData: RoleInputData,
) {
  userInvite (
    workspace: $workspace
    mergeWith: $mergeWith
    email: $email,
    roleData: $roleData
    userData: $userData
  ) {
    id
  }
}`

async function requestUserInvite (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('invite-user', errors)
  }
  return data.userInvite
}

export default requestUserInvite
