import React, { Fragment } from 'react'
import moment from 'moment'
import { t } from 'i18next'
import {
  DaySelect,
  Radio,
  Color,
  Tooltip,
  Button,
  Modal,
  Flex,
  Spacing,
  Link
} from '@ui'
import { notification, propsUtil } from '@app/util'
import { Shifts } from '@core'

import connect from './connect'
import './index.scss'

class TemplateApplyLive extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: null,
      dates: props.date ? [props.date] : [],
      selectingDates: false
    }
    this.handleOnSubmit = this._onSubmit.bind(this)
    this.getListSectionHeader = this._getListSectionHeader.bind(this)
  }

  componentDidMount () {
    const { templates, selectingDates } = this.props

    if (templates && templates.length === 1) {
      this.setState((s) =>
        Object.assign({}, s, {
          selected: templates[0].id
        })
      )
    }

    setTimeout(() => {
      if (selectingDates) {
        this.setState((s) =>
          Object.assign({}, s, {
            selectingDates: true
          })
        )
      }
    }, 500)
  }

  async _onSubmit () {
    const { dates, selected } = this.state
    const { setModal, applyTemplateLive } = this.props

    for (let i = 0; i < dates.length; i++) {
      await applyTemplateLive(selected, dates[i])
    }
    setModal(null)
  }

  _getListSectionHeader (columns) {
    return (
      <div className='ds-sd-th'>
        <div className='ds-sd-thc ds-sda-thc-name'>
          <span className='ds-sd-th-label'>{columns[0]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-work-time'>
          <span className='ds-sd-th-label'>{columns[1]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-shift-count'>
          <span className='ds-sd-th-label'>{columns[2]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-assign' />
      </div>
    )
  }

  render () {
    const { selected, selectingDates, dates } = this.state
    const { setModal, isLoading, date, templates } = this.props

    if (!date || !templates) return null

    return (
      <Modal
        className='ds-template-apply-live'
        size={Modal.SIZES.L}
        isLoading={isLoading}
        headerContent={
          <div className='ds-title'>{t('APPLY_TEMPLATE_LIVE_TITLE')}</div>
        }
        sections={[
          <Fragment key={0}>
            <div className='ds-sd-list'>
              <div className='ds-sd-templates-list'>
                {this.getListSectionHeader([
                  t('NAME_V2'),
                  t('WORK_TIME'),
                  t('SHIFT_COUNT')
                ])}

                {templates.map((tpl) => {
                  const tplDetails = propsUtil.getTemplateDetails(tpl)
                  return (
                    <div key={tpl.id} className='ds-sd-tb-wrapper'>
                      <div
                        className={[
                          'ds-sd-tb',
                          selected === tpl.id ? 'is-selected' : null
                        ].join(' ')}
                        onClick={() => this.setState({ selected: tpl.id })}
                      >
                        <div className='ds-sd-tbc ds-sda-tbc-radio'>
                          <Radio value={selected === tpl.id} />
                        </div>
                        <div className='ds-sd-tbc ds-sda-tbc-name'>
                          <Color src={tpl} />
                          {tpl.name}
                        </div>
                        <div className='ds-sd-tbc ds-sda-tbc-work-time'>
                          {tplDetails.start} - {tplDetails.end}
                        </div>
                        <div className='ds-sd-tbc ds-sda-tbc-shift-count'>
                          {tplDetails.shiftCount}
                        </div>
                        <Tooltip
                          className='ds-preview-tooltip'
                          anchor={<div className='ds-link'>{t('PREVIEW')}</div>}
                        >
                          <Shifts
                            readOnly
                            sm
                            hideShiftsCountSection
                            shifts={tpl.requirements}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )
                })}

                <div
                  key='new_template'
                  className='ds-sd-tb-wrapper is-new-template'
                >
                  <Link
                    to='/workspace/day-templates/'
                    type={Link.TYPES.ROUTER_LINK}
                  >
                    <div className='ds-sd-tb'>+ {t('EDIT_TEMPLATES')}</div>
                  </Link>
                </div>
              </div>
            </div>
          </Fragment>,
          <Fragment key={1}>
            <div className='ds-tal-dates'>
              <div
                className='ds-tal-dates-summary'
                style={{
                  textAlign: selectingDates ? 'center' : undefined
                }}
              >
                {t('APPLY_TEMPLATE_APPLYING_TO_DATES') + ': '}
                {dates && dates.length
                  ? dates.map((d) => moment(d).format('D.M.YYYY')).join(', ')
                  : t('NOT_SET')}
                &nbsp;(
                <div
                  className='ds-link'
                  onClick={() => {
                    this.setState((s) =>
                      Object.assign({}, s, {
                        selectingDates: !selectingDates
                      })
                    )
                  }}
                >
                  {' ' +
                    (selectingDates
                      ? t('HIDE').toLowerCase()
                      : t('APPLY_TEMPLATE_EDIT_DATES').toLowerCase())}
                </div>
                )
              </div>
              {selectingDates ? (
                <DaySelect
                  forbiddenPast
                  values={dates.map((d) => moment(d))}
                  onChange={(v) => {
                    this.setState((s) => Object.assign({}, s, { dates: v }))
                  }}
                />
              ) : null}
            </div>
          </Fragment>
        ]}
        footerContent={
          <Flex justify={Flex.POSITION.END} grow={1}>
            <Button
              label={t('CLOSE')}
              onClick={() => setModal(null)}
              size={Button.SIZES.LARGE}
            />
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            <Button
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
              color={Button.COLORS.GREEN}
              label={t('APPLY_TEMPLATE_APPLY')}
              disabled={isLoading || !selected || !dates.length}
              loading={isLoading}
              onClickActiveWhenDisabled
              onClick={() => {
                if (isLoading || !selected || !dates.length) {
                  if (!selected) {
                    notification.error({
                      title: '',
                      message: 'APPLY_TEMPLATE_SELECT_FIRST'
                    })
                  }
                } else {
                  this.handleOnSubmit()
                }
              }}
            />
          </Flex>
        }
      />
    )
  }
}

export default connect(TemplateApplyLive)
