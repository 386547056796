import isLoading from './is-loading'
import setCycleDetail from '../action/set-cycle-detail'
import store from '../store'
import { notification } from '@app/util'

export default (cycle) => {
  return async (dispatch) => {
    const { cycleDetail } = store.getState()

    if (!cycle) return
    dispatch(isLoading('delete-cycle-detail'))
    cycleDetail.data.forEach((detail, index) => {
      if (detail.data.id === cycle.data.id) {
        cycleDetail.data.splice(index, 1)
      }
    })
    await dispatch(setCycleDetail({ calendar: cycleDetail.calendar, data: cycleDetail.data, lastSavedCycleShift: cycleDetail.lastSavedCycleShift }))
    dispatch(isLoading('delete-cycle-detail', true))
    notification.success({ code: 'success' })
  }
}
