import isLoading from './is-loading'
import requestOffersWorkspace from '../request/offers-workspace'
import addError from '../ac/add-error'
import setOffers from '../action/set-offers'
import store from '../store'

export default (period) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()

    dispatch(isLoading('load-offers'))
    const result = await requestOffersWorkspace({ workspace: workspaceId, onlyUnresolved: true, period }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
    } else {
      dispatch(setOffers(result))
    }

    dispatch(isLoading('load-offers', true))
  }
}
