import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query ShiftExchangesQuery (
  $id: ID!,
  $period: PeriodInput
) {
  shift(id: $id) {
    exchanges (
      period: $period
    ) {
      id
      period {
        start
        end
      }
      userId
      userName
      positionId
      localityId
      warnings ${q.EVENT_WARNING}
    }
  }
}`

async function requestShiftExchangesReal (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-exchanges-real', errors)
  }
  return data.shift.exchanges
}

export default requestShiftExchangesReal
