import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  firstLoad,
  setLocale,
  login,
  logout
} from '@app/ac'

const mapStateToProps = ({
  auth,
  me,
  workspaces,
  workspaceId,
  locale
}) => {
  return {
    auth,
    workspaces,
    workspaceId,
    me,
    locale
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setLocale,
    logout,
    firstLoad,
    login
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
