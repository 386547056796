import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { colorUtil, numberUtil } from '@app/util'

import './TextWithProgress.scss'

export const TextWithProgress = ({ value }) => {
  const progressColor = colorUtil.getProgressColor(value)
  return (
    <div
      className={classNames('text-with-progress')}
      style={{
        progressColor
      }}
    >
      <div className='value'>{numberUtil.round2decimals(value) + ' %'}</div>
      <div className='line'>
        <div
          className='line-value'
          style={{
            width: value ? (parseInt(value) / 100) * 80 : 0,
            backgroundColor: progressColor
          }}
        >
          {' '}
        </div>
      </div>
    </div>
  )
}

TextWithProgress.propTypes = {
  value: PropTypes.number
}
TextWithProgress.defaultProps = {
  value: 0
}
