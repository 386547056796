import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation LogoutMutation {
  logout
}`

async function logout (token) {
  const response = await fetchRequest({
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('logout', errors)
  }
  return data.logout
}

export default logout
