import isLoading from './is-loading'
import addError from '../ac/add-error'
import setEmployees from '../action/set-employees'
import loadEmployees from '../ac/load-employees'
import store from '../store'
import requestWorkspaceRolesArchived from '@app/request/workspace/roles-archived'

export default () => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    let { employees } = store.getState()

    // make sure we have the non-external employees loaded first - otherwise, we could
    // desync and overwrite non-external emps with the result of the external emps query
    if (!employees || Object.keys(employees).length === 0) {
      employees = await dispatch(loadEmployees())
    }

    // load all the archived employees from API request workspace.roles(archived: true)
    await dispatch(isLoading('load-all-archived-employees'))
    const result = await requestWorkspaceRolesArchived({
      workspaceId
    }, auth)
    if (result.error) {
      dispatch(addError(result.error))
      dispatch(isLoading('load-all-archived-employees', true))
      return
    }
    if (!result?.length) return

    const loadedEmployees = result.map(u => {
      const fn = u.customData?.firstName || u.user?.firstName
      const ln = u.customData?.lastName || u.user?.lastName
      let name = ''
      if (fn) name += fn
      if (ln) {
        if (name !== '') name += ' '
        name += ln
      }
      if (name === '') name = u.user?.email

      return {
        external: true,
        terminateDate: u.terminateDate,
        id: u.userId,
        email: u.user.email,
        telephone: u.customData?.telephone || u.user?.telephone,
        firstName: fn,
        lastName: ln,
        name
      }
    })

    // merge loaded employees with the ones we already had in store (this prevents
    // the deletion of some extra employee data loaded asynchronously by other requests,
    // such as employee warnings)
    const finalEmps = Object.assign({}, employees)
    loadedEmployees.forEach((ee) => {
      if (!finalEmps[ee.id]) {
        finalEmps[ee.id] = ee
      }
    })

    // save merged employee data to store
    await dispatch(setEmployees(finalEmps))
    await dispatch(isLoading('load-all-archived-employees', true))

    return loadedEmployees
  }
}
