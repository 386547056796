import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.offers, action) => {
  switch (action.type) {
    case aT.SET_OFFERS:
      return action.data
    case aT.RESET_STORE:
      return initial.offers
    case aT.SWITCH_WORKSPACE:
      return initial.offers
    default:
      return state
  }
}
