import store from '../store'
import isLoading from './is-loading'
import requestCalendarExportToken from '@app/request/calendar-export-token'
import addError from '../ac/add-error'
import setExportToken from '../action/set-export-token'
import moment from 'moment'

export default (format, startDate, endDate, type, userId) => {
  return async (dispatch) => {
    const { auth, workspaceId, exportToken } = store.getState()
    if (!workspaceId) return

    let period = null
    if (startDate && endDate) {
      period = {
        start: moment(startDate).startOf('day'),
        end: moment(endDate).endOf('day')
      }
    }

    let resUrl
    const resToken = exportToken || {}
    dispatch(isLoading('load-export-token'))
    const result = await requestCalendarExportToken({
      user: userId || undefined,
      workspace: userId ? undefined : workspaceId,
      format,
      period,
      type
    }, auth)
    if (result.error) {
      await dispatch(addError(result.error))
    } else {
      resUrl = result.shortUrl || result.url
      // ICAL url save
      if (format === 'ical') {
        const webcalUrl = encodeURIComponent(`webcal://${result.shortUrl.replace('https://', '')}`)
        const googleUrl = `https://calendar.google.com/calendar/r?cid=${webcalUrl}`
        resToken.googleImportUrl = googleUrl
        await dispatch(setExportToken({
          icsUrl: result.shortUrl,
          googleImportUrl: googleUrl,
          pdfUrl: exportToken && exportToken.pdfUrl ? exportToken.pdfUrl : null,
          xlsxUrl: exportToken && exportToken.xlsxUrl ? exportToken.xlsxUrl : null
        }))
      }
      // PDF url save
      if (format === 'pdf') {
        await dispatch(setExportToken({
          icsUrl: exportToken && exportToken.icsUrl ? exportToken.icsUrl : null,
          googleImportUrl: exportToken && exportToken.googleImportUrl ? exportToken.googleImportUrl : null,
          pdfUrl: resUrl,
          xlsxUrl: exportToken && exportToken.xlsxUrl ? exportToken.xlsxUrl : null
        }))
      }
      // XLSX (Excel) url save
      if (format === 'xlsx') {
        await dispatch(setExportToken({
          icsUrl: exportToken && exportToken.icsUrl ? exportToken.icsUrl : null,
          googleImportUrl: exportToken && exportToken.googleImportUrl ? exportToken.googleImportUrl : null,
          pdfUrl: exportToken && exportToken.pdfUrl ? exportToken.pdfUrl : null,
          xlsxUrl: resUrl
        }))
      }
    }

    await dispatch(isLoading('load-export-token', true))
    return { url: resUrl, googleImportUrl: resToken && resToken.googleImportUrl ? resToken.googleImportUrl : null }
  }
}
