import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationContractTypes($contractTypesCountry2: String!) {
  contractTypes(country: $contractTypesCountry2) {
    id
    name
    country
  }
}`

async function requestOrgContractTypes (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.contractTypes
}

export default requestOrgContractTypes
