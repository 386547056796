import React, { useEffect, useState } from 'react'
import {
  Alert,
  Checkbox,
  Dropdown,
  Flex,
  Input,
  Spacing,
  Text
} from '@ui'
import { t } from 'i18next'
import moment from 'moment'

export const RepeatTimeOff = ({
  onChange,
  value,
  day,
  isMultipleDays,
  setError,
  hasError,
  removeError,
  title,
  disabledEditation
}) => {
  const [open, setOpen] = useState(false)
  const dayWord = moment(day).format('dddd')
  const repeatOptions = [
    {
      label: t('EVERY_WEEK_IN') + ' ' + dayWord,
      value: 'dayOfWeek',
      subValue: day && parseInt(moment(day).format('d'))
    },
    {
      label: t('EVERY_X_DAY_IN_MONTH', { x: day && parseInt(moment(day).format('D')) }),
      value: 'dayOfMonth',
      subValue: day && parseInt(moment(day).format('D'))
    },
    {
      label: t('EVERY_14_DAYS') + ' ' + dayWord,
      value: 'everyNDays',
      subValue: 14
    }
  ]
  const validateDuration = ({ type, newDate }) => {
    const date = moment(newDate)
    const startDay = moment(day)
    const numberOfRepetitions = (
      (type === 'dayOfWeek' && date.diff(startDay, 'week')) ||
      (type === 'dayOfMonth' && date.diff(startDay, 'month')) ||
      (type === 'everyNDays' && date.diff(startDay, 'month') * 2)
    )

    if (numberOfRepetitions > 365) {
      setError('repeat-over-limit')
    } else {
      removeError()
    }
  }

  useEffect(() => {
    if (isMultipleDays && open) {
      setOpen(false)
    }
    if (value && !open) {
      setOpen(true)
    }
  }, [isMultipleDays, value])
  return (
    <>
      <Flex align={Flex.POSITION.CENTER}>
        <Checkbox
          checked={open}
          disabled={disabledEditation || isMultipleDays}
          onChange={() => {
            if (open) onChange(null)
            if (!open) {
              onChange({
                type: repeatOptions[0].value,
                value: repeatOptions[0].subValue,
                until: moment(day).add(1, 'year').format()
              })
            }
            setOpen(!open)
          }}
        >
          <Text
            text={t('REPEAT') + ' ' + title().toLowerCase()}
            type={Text.TYPES.BODY_LARGE}
            color={isMultipleDays ? Text.COLORS.GRAY_LIGHT : Text.COLORS.BLACK}
          />
        </Checkbox>
      </Flex>
      {open && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_16} />
          <Flex align={Flex.POSITION.CENTER}>
            <Dropdown
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.LIGHT}
              size={Dropdown.SIZES.FULL_WIDTH}
              singleSelect
              disableSort
              disabled={disabledEditation}
              label={t('REPEAT')}
              value={repeatOptions.find((item) => item.value === value?.type)}
              options={repeatOptions}
              onChange={(option) => {
                validateDuration({ type: option.value, newDate: value.until })
                onChange({ ...value, type: option.value, value: option.subValue })
              }}
            />
            <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
            <Input
              size={Input.SIZES.FULL_WIDTH}
              label={t('UNTIL_WHEN')}
              type='date'
              disabled={disabledEditation}
              onChange={(v) => {
                validateDuration({ type: value.type, newDate: v })
                onChange({ ...value, until: moment(v).format() })
              }}
              value={moment(value?.until).format('YYYY-MM-DD')}
            />
          </Flex>
          {hasError && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_6} />
              <Alert text={t('CANT_CREATE_365_REPEATS')} type={Alert.TYPES.ERROR} size={Alert.SIZES.FULL_WIDTH} />
            </>
          )}
        </>
      )}
    </>
  )
}

RepeatTimeOff.propTypes = {}
RepeatTimeOff.defaultProps = {}
