import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import { withPlugin } from '@core/hoc/withPlugin'
import {
  handleTimeOffConflict,
  setModal,
  acceptOrDenyRequest,
  deleteUnavailability,
  setAvailabilityOrTimeOff,
  setCalendarMultiSelect,
  createTimeOffRequest
} from '@app/ac'

const mapStateToProps = ({
  loading, availabilities, timeOffs,
  employees, workspaces, workspaceId,
  organizationId, auth, calendar, me,
  positions, shifts
}) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    availabilities,
    timeOffs,
    employees,
    positions,
    shifts,
    isLoading: loading.includes('load-availabilities-and-time-offs') || loading.includes('set-availability-or-time-off') || loading.includes('clear-unavailable') || loading.includes('create-unavailability-request'),
    workspace: ws,
    workspaceId,
    organizationId,
    auth,
    calendar,
    me
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setAvailabilityOrTimeOff,
    setModal,
    setCalendarMultiSelect,
    createTimeOffRequest,
    handleTimeOffConflict,
    acceptOrDenyRequest,
    deleteUnavailability
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
