import React from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  CategoryTag,
  Spacing,
  Table,
  Text
} from '@ui'

export const OverviewTable = ({
  period,
  timeOffs,
  unavCategories,
  employees
}) => {
  const tableConfig = [
    {
      key: 'userId',
      tlKey: t('EMPLOYEE'),
      sortable: true,
      filterable: true,
      rowToHTML: (row) => employees[row.userId]?.name || null,
      rowToStr: (row) => employees[row.userId]?.name || '-'
    },
    {
      key: 'type',
      tlKey: t('TYPE'),
      sortable: true,
      filterable: true,
      rowToHTML: (row) => (
        <CategoryTag
          category={unavCategories.find((cat) => cat.id === row.categoryId)}
        />
      ),
      rowToStr: (row) => {
        const category = unavCategories.find((cat) => cat.id === row.categoryId)
        return category && category.title
      }
    },
    {
      key: 'period',
      tlKey: t('REQUEST_UNAV_PERIOD'),
      sortable: true,
      filterable: true,
      rowToHTML: (row) => moment(row.period.start).format('dd D.M.'),
      rowToStr: (row) => moment(row.period.start).format('dd D.M.')
    }
  ]

  const tableData = timeOffs.filter((item) => (
    moment(period?.start).isSame(item?.period.start) ||
    moment(period?.start).isAfter(item?.period.start)
  ) && moment(period?.start).isBefore(item?.period.end))

  return (
    <>
      <Spacing size={Spacing.SIZES.SIZE_20} />
      <Table
        tableName='timeoff-overview'
        extraContent={
          <Text
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
            text={t('TIMEOFF_OVERVIEW')}
          />
        }
        rows={tableData}
        showSelection={false}
        tableConfig={tableConfig}
        pagination={false}
        showActionBar={false}
        size={Table.SIZE.LEAN}
      />
    </>
  )
}

OverviewTable.propTypes = {}
OverviewTable.defaultProps = {}
