import { errorUtil, fetchRequest } from '@app/util'

const query = `query OrganizationEmployeeCount($organizationId: ID!) {
  organization(id: $organizationId) {
    countries {
      id
      positions {
        id
        employeeCount
      }
    }
  }
}`

async function requestPositionEmployeeCount (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data.organization
}

export default requestPositionEmployeeCount
