import isLoading from './is-loading'
import requestWorkspaceEventDelete from '../request/workspace-event-delete'
import loadWorkspaceEvents from '@app/ac/load-workspace-events'
import addError from '../ac/add-error'
import store from '../store'

export default (data) => {
  return async (dispatch) => {
    const { auth, workspaceId, workspaceEvents } = store.getState()
    if (!workspaceId || !data || !data.id) return

    const evt = workspaceEvents.find((ev) => ev.id === data.id)
    const reloadPeriod = evt ? evt.period : null

    dispatch(isLoading('delete-workspace-event'))
    const result = await requestWorkspaceEventDelete({ workspaceId, id: data.id }, auth)
    if (result.error) {
      dispatch(addError(result.error))
      dispatch(isLoading('delete-workspace-event', true))
      return false
    }

    dispatch(isLoading('delete-workspace-event', true))

    // reload
    if (reloadPeriod) {
      dispatch(loadWorkspaceEvents({ type: null, period: reloadPeriod }))
    }

    return result
  }
}
