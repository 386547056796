import './index.scss'
import HeaderCol from './col'
import React from 'react'
import { Dropdown, Flex, Icon, Spacing, Tooltip } from '@ui'
import connect from './connect'
import { t } from 'i18next'
import miscUtil from '@app/util/misc'
import { SORT_BY } from '../sort-by'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  shouldComponentUpdate (nextProps, nextState) {
    // update if anything relevant in calendar changes
    if (this.props.cols.length !== nextProps.cols.length) {
      return true
    }
    if (miscUtil.safeStringify(this.props.customCalendar && this.props.cols ? this.props.cols : '') !== miscUtil.safeStringify(nextProps.customCalendar && nextProps.cols ? nextProps.cols : '')) {
      return true
    }
    if ((this.props.calendar ? this.props.calendar.date : '') !== (nextProps.calendar ? nextProps.calendar.date : '')) {
      return true
    }
    if ((this.props.calendar ? this.props.calendar.view : '') !== (nextProps.calendar ? nextProps.calendar.view : '')) {
      return true
    }
    if (miscUtil.safeStringify(this.props.calendar ? this.props.calendar.loadedPeriods : '') !== miscUtil.safeStringify(nextProps.calendar ? nextProps.calendar.loadedPeriods : '')) {
      return true
    }

    // update if workspace events change
    if (miscUtil.safeStringify(this.props.workspaceEvents) !== miscUtil.safeStringify(nextProps.workspaceEvents)) {
      return true
    }

    // update if workspace warnings filter change
    if (miscUtil.safeStringify(this.props.warningsByDate) !== miscUtil.safeStringify(nextProps.warningsByDate)) {
      return true
    }

    // update if workspace localities change
    if (miscUtil.safeStringify(this.props.localities) !== miscUtil.safeStringify(nextProps.localities)) {
      return true
    }

    // update if sortedBy changes
    if (this.props.sortedBy !== nextProps.sortedBy) {
      return true
    }

    // update if custom style changes
    if (this.props.style !== nextProps.style) {
      return true
    }

    return false
  }

  render () {
    const { className, cols, withoutTooltip, customChildren, noSort, sortedBy, handleSortChange, localities, style, customLabel, customCalendar, showRepeat, period, warningsByDate } = this.props
    const calendar = this.props.customCalendar || this.props.calendar
    if (!cols || !cols.length) return null
    const cN = ['ds-c-header']
    if (className) cN.push(className)
    const labelWidthRem = 9.5

    const sortOpts = [
      { label: t('SORT_BY_NAME'), value: SORT_BY.NAME },
      { label: t('POSITION'), value: SORT_BY.POSITION },
      (localities && localities.length ? { label: t('LOCALITIES'), value: SORT_BY.LOCALITY } : null),
      { label: t('SORT_BY_CONTRACT'), value: SORT_BY.CONTRACT },
      { label: t('SORT_BY_DAY_PART'), value: SORT_BY.DAY_PART },
      {
        label: (
          <Flex align={Flex.POSITION.CENTER}>
            <span>{t('SORT_BY_CUSTOM')}</span>
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            <Tooltip
              anchor={<Icon ico={Icon.ICONS.question} size={Icon.SIZES.SMALL} />}
              text={t('SORT_BY_CUSTOM_HELP')}
            />
          </Flex>),
        value: SORT_BY.CUSTOM
      }
    ].filter(el => !!el)

    if (calendar?.view === 'day' || sortedBy === SORT_BY.SHIFT_START) sortOpts.push({ label: t('SORT_BY_SHIFT_START'), value: SORT_BY.SHIFT_START })

    return (
      <div className={cN.join(' ')} style={style}>
        <div
          className={`${!noSort ? 'ds-c-sort-selection' : 'ds-c-header-empty-placeholder'} ${customLabel ? 'ds-c-row-label' : ''}`} style={{
            width: (labelWidthRem).toString() + 'rem',
            minWidth: (labelWidthRem).toString() + 'rem',
            maxWidth: (labelWidthRem).toString() + 'rem'
          }}
          onClick={(e) => {
            const ttip = document.querySelector('.ds-c-sort-selection .dropdown-container')
            if (ttip) {
              ttip.click()
              e.stopPropagation()
            }
          }}
        >
          {!noSort
            ? (
              <div className='ds-c-sort-selection-sorted-by'>
                {t('SORTED_BY')}
              </div>
            )
            : null}

          {customLabel && noSort && (
            <span>{customLabel}</span>
          )}

          {!noSort
            ? (
              <Dropdown
                label={null}
                singleSelect
                sortedOptions={false}
                size={Dropdown.SIZES.MEDIUM}
                type={Dropdown.TYPES.VARIABLE}
                style={Dropdown.STYLES.BORDERLESS}
                options={sortOpts}
                value={[sortOpts.find(opt => opt.value === sortedBy)].filter(Boolean)}
                placeholder={t(sortedBy)}
                onChange={(opt) => {
                  window.localStorage.setItem('ds-calendar-sort', opt.value)
                  handleSortChange(opt.value)
                }}
              />
            )
            : null}

        </div>

        {cols.map((col, colIdx) => (
          <HeaderCol
            key={'header-col-' + colIdx.toString()}
            col={col}
            colIdx={colIdx}
            cols={cols}
            isCyclePreview={showRepeat && period && colIdx >= period}
            withoutTooltip={withoutTooltip ?? false}
            customChildren={customChildren ?? false}
            customCalendar={customCalendar ?? null}
            labelWidthRem={labelWidthRem}
            warnings={(warningsByDate && Object.keys(warningsByDate).includes((col?.date)?.format('YYYY-MM-DD'))) ? warningsByDate[(col.date).format('YYYY-MM-DD')] : undefined}
          />
        ))}

      </div>
    )
  }
}

export default connect(Header)
