import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation PluginCreate (
    $target: PluginTargetInput!
    $plugin: String!
    $data: PluginInputData!
  ) {
    pluginCreate (
      target: $target
      plugin: $plugin
      data: $data
    ) {
      id
      plugin
      enabled
      options
    }
}`

async function requestPluginCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('plugin-create', errors)
  }
  return data.pluginCreate
}

export default requestPluginCreate
