import isLoading from './is-loading'
import addError from './add-error'
import requestVerifyMfaFactor from '@app/request/verify-mfa-factor'

export default (params) => {
  return async (dispatch) => {
    dispatch(isLoading('verify-mfa-factor'))
    const result = await requestVerifyMfaFactor(params)
    if (result.error) {
      dispatch(addError(result.error))
    }

    dispatch(isLoading('verify-mfa-factor', true))
    return result
  }
}
