import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation Mutation($organization: ID!, $country: ID!, $data: CountryUpdateInput) {
  countryUpdate(organization: $organization, country: $country, data: $data) {
    id
    currency
    currencyRate
    hoursOfWorkPerMonth
    fullTimePercentage
  }
}`

async function requestCountryUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data
}

export default requestCountryUpdate
