import isLoading from './is-loading'
import store from '../store'
import setEnforcedLocality from '@app/action/set-enforced-locality'

export default (newLocalityId) => {
  return async (dispatch) => {
    const { enforcedLocality } = store.getState()

    if (!newLocalityId || newLocalityId === enforcedLocality) return null

    await dispatch(isLoading('set-enforced-locality'))
    await dispatch(setEnforcedLocality(newLocalityId))
    await dispatch(isLoading('set-enforced-locality', true))
  }
}
