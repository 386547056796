import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.holidays, action) => {
  switch (action.type) {
    case aT.SET_HOLIDAYS:
      return action.data
    case aT.RESET_STORE:
      return initial.holidays
    case aT.SWITCH_WORKSPACE:
      return initial.holidays
    default:
      return state
  }
}
