import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { setModal } from '@app/ac'

const mapStateToProps = ({ workspaces, workspaceId }) => {
  const ws = workspaces.find(w => w.id === workspaceId)
  return {
    workspace: ws
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setModal
}, dispatch)

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
