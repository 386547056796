import { fetchRequest, errorUtil } from '@app/util'
import q from '../query'

const query = `query OrganizationTransferDetails(
   $organizationId: ID!,
   $filter: OrganizationFilter!
   $period: PeriodInput!,
   ) {
  organization(id: $organizationId) {
    transfersDetails(period: $period, filter: $filter) {
      ${q.TRANSFER_DETAILS_PARAMS}
    }
  }
}`

async function requestOrganizationTransfersDetails (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrganizationTransfersDetails
