import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import {
  Button,
  Input,
  Select,
  Dropdown,
  Icon,
  Modal,
  Flex,
  Spacing
} from '@ui'

import connect from './connect'
import {
  getCountryOptions,
  getWorkspaceOptions
} from '@app/component/pages/organization/helpers'

const TransferGroupCreate = ({
  close,
  organizationId,
  organization,
  createTransferGroup
}) => {
  const [form, setForm] = useState({
    organization: organizationId,
    name: undefined,
    country: undefined,
    workspace: []
  })
  const countryOptions = getCountryOptions(organization.countries)
  const selectedCountry = countryOptions.find(c => c.value === form.country)
  const workspaceOptions = getWorkspaceOptions(organization.countries).filter(ws => ws.groupTitle === selectedCountry?.label)

  const handleCreateTransferGroup = (form) => {
    const reqObj = {
      organization: form.organization,
      country: form.country,
      data: {
        name: form.name,
        workspaces: form.workspace.map(ws => {
          return { workspaceId: ws.value }
        })
      }
    }
    createTransferGroup(reqObj)
  }
  useEffect(() => {
    if (form.workspace.length > 0) {
      setForm({ ...form, workspace: [] })
    }
  }, [form.country])
  return (
    <Modal
      size={Modal.SIZES.M}
      footerContent={
        <Flex stretch>
          <Button
            onClick={close}
            size={Button.SIZES.FULL}
            label={t('CLOSE')}
          />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            onClick={() => handleCreateTransferGroup(form)}
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
            label={t('SAVE')}
          />
        </Flex>
      }
      headerContent={<div className='ds-title'>{t('ORG_SP_TRANSFERS_GROUPS_ADD_NEW')}</div>}
      sections={[
        <>
          <Input
            label={t('ORG_SP_TRANSFERS_GROUPS_ADD_NAME_LABEL')}
            placeholder={t('ORG_SP_WORKSPACES_WORKSPACE_NAME_PLACEHOLDER')}
            onChange={(val) => setForm({ ...form, name: val })}
            value={form.name}
          />
          <Select
            label={t('ORG_SP_TRANSFERS_GROUPS_ADD_COUNTRY_LABEL')}
            placeholder={t('CHOOSE')}
            onChange={(val) => setForm({ ...form, country: val })}
            options={countryOptions}
            value={form.country}
          />
          <Dropdown
            label={t('WORKSPACES')}
            size={Dropdown.SIZES.LARGE}
            type={Dropdown.TYPES.VARIABLE}
            options={workspaceOptions}
            icon={<Icon ico='search' />}
            value={form.workspace.map(elem => ({
              label: elem.label,
              value: elem.value
            }))}
            placeholder={t('SEARCH')}
            searchable
            onChange={(val) => setForm({ ...form, workspace: val })}
          />
        </>
      ]}
    />
  )
}

TransferGroupCreate.propTypes = {
  close: PropTypes.func.isRequired
}
TransferGroupCreate.defaultProps = {}

export default connect(TransferGroupCreate)
