import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation WorkspaceDeleteMutation($id: ID!) {
  workspaceDelete (
    id: $id
  )}`

async function requestWorkspaceDelete (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data.workspaceDelete
}

export default requestWorkspaceDelete
