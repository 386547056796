import aT from '../const/action-type'

export default (data) => {
  return { type: aT.SET_NOTIFICATIONS, data }
}

export const ReadNotificationsAction = () => {
  return { type: aT.READ_NOTIFICATIONS }
}

export const ResolveNotificationAction = (data) => {
  return { type: aT.RESOLVE_NOTIFICATIONS, data }
}

export const loadMoreNotifications = (notifications) => {
  return { type: aT.LOAD_MORE_NOTIFICATIONS, data: notifications }
}
