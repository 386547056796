"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTable = void 0;
const __1 = require("..");
const i18n_1 = require("../i18n");
const parseTable = (columns, selectedRows) => {
    const isStringANumber = (s) => {
        const numPattern = /^-?\d+(\.\d+)?$/;
        return numPattern.test(s);
    };
    const filteredColumns = columns.filter(col => col.tlKey && col.tlKey !== '');
    const config = filteredColumns.map((col) => ({ label: typeof col.tlKey === 'string' ? (0, i18n_1.t)(col.tlKey) : __1.miscUtil.getNodeText(col.tlKey), key: col.key }));
    const reducedRows = selectedRows.map(r => {
        const ret = {};
        filteredColumns.forEach(col => {
            var _a, _b, _c, _d, _e, _f;
            ret[col.key] = (((_a = col.rowToFilterType) === null || _a === void 0 ? void 0 : _a.call(col, r.original).length) && col.rowToFilterType(r.original)[0].label)
                ? col.rowToFilterType(r.original).map((item) => item.label).join(' ')
                : col.rowToStr
                    ? col.rowToStr(r.original)
                    : r.original[col.key];
            const currentLanguageUsesCommaSeparator = (_b = new Intl.NumberFormat((0, i18n_1.getLanguage)()).format(0.1)) === null || _b === void 0 ? void 0 : _b.includes(',');
            const s = (_e = (_d = (_c = ret[col.key]) === null || _c === void 0 ? void 0 : _c.toString()) === null || _d === void 0 ? void 0 : _d.replace(',', '.')) === null || _e === void 0 ? void 0 : _e.replace(/\s/g, '');
            const number = parseFloat(s);
            const string = ret[col.key] ? ret[col.key].toString().trim() : '';
            ret[col.key] = !isStringANumber(s)
                ? string
                : currentLanguageUsesCommaSeparator
                    ? (_f = number === null || number === void 0 ? void 0 : number.toString()) === null || _f === void 0 ? void 0 : _f.replace('.', ',')
                    : number;
        });
        return ret;
    });
    return [config, reducedRows];
};
exports.parseTable = parseTable;
