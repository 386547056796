import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `mutation ShiftCopyMutation (
  $workspace: ID!,
  $id: ID!,
  $targetDates: [String!]!,
  $copyAssign: Boolean
) {
  shiftCopy (
    workspace: $workspace
    id: $id,
    targetDates: $targetDates,
    copyAssign: $copyAssign
  ) ${q.SHIFT_BASIC}
}`

async function requestShiftCopy (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-copy', errors)
  }
  return data.shiftCreate
}

export default requestShiftCopy
