import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query EmployeesExternalQuery (
  $id: ID!
  $period: PeriodInput
  $userIds: [ID!]
) {
  workspace (
    id: $id
  ) {
    externalMembers (
      period: $period
      userIds: $userIds
    ) {
      userId
      firstName
      lastName
      email
      telephone
      period {
        start
        end
      }
      updated
    }
  }
}`

async function requestEmployeesExternal (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('employees-external', errors)
  }
  return data.workspace
}

export default requestEmployeesExternal
