import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import applyTemplateLive from '@app/ac/apply-template-live'

const mapStateToProps = ({ workspaceId, templates, loading }) => {
  return {
    workspaceId,
    templates,
    isLoading: loading.includes('apply-template-live')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    applyTemplateLive,
    setModal
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
