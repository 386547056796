import store from '../store'
import isLoading from './is-loading'
import loadTemplates from './load-templates'
import requestRequirementDelete from '../request/requirement-delete'
import addError from '../ac/add-error'
import { notification } from '@app/util'

export default (templateId, shift) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !templateId || !shift) return

    dispatch(isLoading('delete-shift'))

    const result = await requestRequirementDelete({
      workspace: workspaceId,
      template: templateId,
      requirement: shift.id
    }, auth)
    if (result && result.error) {
      await dispatch(addError(result.error))
    } else {
      notification.success({ code: 'success' })
    }

    await dispatch(loadTemplates())
    dispatch(isLoading('delete-shift', true))
  }
}
