import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query TemplatesQuery ($id: ID!) {
  workspace(
    id: $id
  ) {
    templates {
      id
      type
      name
      color
      days
      requirements {
        id
        duration
        start
        positionId
        localityId
        idealSkill
        pauses {
          start
          duration
        }
        pausesFixed
        agenda {
          ${q.SHIFT_AGENDA_PARAMS}
        }
      }
    }
  }
}`

async function requestTemplates (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('templates', errors)
  }
  return data.workspace.templates
}

export default requestTemplates
