import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import setModal from '@app/ac/set-modal'
import setCalendarMultiSelect from '@app/ac/set-calendar-multi-select'
import updateLiveShift from '@app/ac/update-live-shift'
import deleteLiveShift from '@app/ac/delete-live-shift'
import setOffers from '@app/action/set-offers'

const mapStateToProps = ({ employees, shifts, offers, positions, workspaces, workspaceId, calendar, auth }) => {
  const ws = workspaces.find(w => w.id === workspaceId)
  return {
    calendar,
    employees,
    shifts,
    offers,
    positions,
    workspace: ws,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    setCalendarMultiSelect,
    updateLiveShift,
    deleteLiveShift,
    setOffers
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
