import React, { useEffect } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Position,
  Checkbox,
  Flex,
  Icon,
  Spacing
} from '@ui'
import ShiftAttribute from '@ui/shift-attribute'
import { notification, calendarUtil } from '@app/util'

import styles from './index.scss'
import connect from './connect'

import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const ListShifts = (props) => {
  const {
    // from store:
    positions,
    workspace,
    employees,
    // from component's properties:
    relevantShifts,
    setRelevantShifts,
    disabled,
    noShiftsContent,
    subtitleTxt
  } = props

  const toggleShiftSelectionByClick = (e, shift) => {
    if (shift.hasOffer && shift.hasOffer.status === 'resolvedWaiting') {
      notification.error({ title: '', message: t('OFF_SUBMIT_PROBLEM.cantSelectResolvedWaiting') })
      return false // shifts with 'resolvedWaiting' offer cannot be selected
    }

    if (!disabled) {
      setRelevantShifts(relevantShifts.map(rs => {
        if (rs.id !== shift.id) {
          return rs
        } else {
          return Object.assign({}, rs, { isSelected: !shift.isSelected })
        }
      }))
    }
  }

  // on mount
  useEffect(() => {
    // if there are some offers with status 'resolvedWaiting', move them to the end of the list
    if (relevantShifts && relevantShifts.length > 1 && relevantShifts.find(rs => rs.hasOffer && rs.hasOffer.status === 'resolvedWaiting')) {
      const newRS = [...relevantShifts]
      newRS.sort((a, b) => { return ((a.hasOffer && a.hasOffer.status === 'resolvedWaiting') && (!b.hasOffer || b.hasOffer.status !== 'resolvedWaiting')) ? 1 : -1 })
      setRelevantShifts(newRS)
    }
  }, [])

  const singleSelectedShift = (relevantShifts.filter(s => s.isSelected).length === 1) ? relevantShifts.find(s => s.isSelected) : null

  return (
    <div className={cx('ds-mod-list-shifts')}>

      <div style={{
        position: 'relative',
        display: 'block',
        minHeight: '100%',
        paddingBottom: '2.4ex'
      }}
      > {/* this wrapper here ensures we can properly position the 'shift ID' div that's visible if we have exactly one shift selected */}

        <div className={cx('ds-mod-row')}>
          <div className={cx('ds-title')}>
            {t('OFF_SHIFTS_LIST_TITLE')}
          </div>
        </div>

        {(relevantShifts.length === 0) && noShiftsContent}

        {relevantShifts.length > 1 && subtitleTxt && (
          <div className={cx('ds-mod-row')}>

            <Checkbox
              className={cx('ds-mod-lss-subtitle-check')}
              checked={relevantShifts.filter(s => s.isSelected).length > 0}
              disabled={disabled}
              onChange={(e) => {
                if (relevantShifts.filter(s => s.isSelected).length > 0) {
                  setRelevantShifts(relevantShifts.map(rs => Object.assign({}, rs, { isSelected: undefined })))
                } else {
                  setRelevantShifts(relevantShifts.map(rs => {
                    if (rs.hasOffer && rs.hasOffer.status === 'resolvedWaiting') {
                      return Object.assign({}, rs, { isSelected: false })
                    } else {
                      return Object.assign({}, rs, { isSelected: true })
                    }
                  }))
                }
              }}
            >
              <div className={cx('ds-mod-list-shifts-subtitle')}>
                {t(subtitleTxt) + ' (' + relevantShifts.filter(rs => !(rs.hasOffer && rs.hasOffer.status === 'resolvedWaiting')).length.toString() + ')'}
              </div>
            </Checkbox>

          </div>
        )}

        {relevantShifts.map((s, sidx) => {
          const pos = positions ? positions.find(p => p.id === s.positionId) : null
          const loc = s.localityId
            ? workspace && workspace.localities && workspace.localities.find(l => l.id === s.localityId)
            : null
          const emp = s.userId
            ? employees[s.userId]
            : null
          const master = s.hasOffer && s.hasOffer.transfer && s.hasOffer.transfer.master
          const attribs = calendarUtil.getShiftAttributes(s, workspace)

          return (
            <div
              key={sidx}
              className={cx('ds-mod-ls-shift', { 'is-selected': s.isSelected, 'is-disabled': disabled })}
              onClick={(e) => {
                toggleShiftSelectionByClick(e, s)
              }}
            >
              <Flex alignItems={Flex.POSITION.CENTER}>
                {!(s.hasOffer && s.hasOffer.status === 'resolvedWaiting') && (
                  <>
                    <Checkbox
                      className='ds-mod-lss-check'
                      checked={s.isSelected}
                      disabled={disabled}
                      onChange={(e) => {
                        toggleShiftSelectionByClick(e, s)
                      }}
                    />
                    <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                  </>
                )}

                {s.hasOffer && s.hasOffer.status !== 'resolved' && (
                  <div className={cx('ds-mod-lss-row')}>
                    <div className={cx('ds-mod-lss-offer-type')}>
                      <Icon ico={
                        s.hasOffer.type === 'drop'
                          ? (s.userId ? 'assign' : 'offer')
                          : 'switch'
                      }
                      />
                      {t(s.hasOffer.type === 'drop'
                        ? (s.userId ? 'OFF_TYPE_DROP_ASSIGNED' : 'OFF_TYPE_DROP_UNASSIGNED')
                        : 'OFF_TYPE_SWAP')}
                    </div>
                  </div>
                )}
              </Flex>
              <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.VERTICAL} />

              {/* position */}
              <div className={cx('ds-mod-lss-row')}>
                {master
                  ? <Position name={master.positionName} color='495063' />
                  : <Position {...pos} />}
              </div>

              {/* workspace name, if it's external shift */}
              {master && (
                <div className={cx('ds-mod-lss-row')}>
                  <Position name={t('OFF_LIST_TRANSFER_TAG') + (master?.workspaceName ? (': ' + master.workspaceName) : '')} color='495063' />
                </div>
              )}

              {/* date */}
              <div className={cx('ds-mod-lss-row')}>
                {moment(s.period.start).format('dddd D.M.')}
              </div>

              {/* time */}
              <div className={cx('ds-mod-lss-row')}>
                {moment(s.period.start).format('HH:mm') + ' - ' + moment(s.period.end).format('HH:mm')}
              </div>

              {/* shift attributes */}
              {(attribs && attribs.length) ? (
                <div className={cx('ds-mod-lss-row')}>
                  {attribs.map((flag, fidx) => <ShiftAttribute key={'sf_' + fidx} attribute={flag} hideDeletion />)}
                </div>
              ) : null}

              {/* locality */}
              {loc && (
                <div className={cx('ds-mod-lss-row')}>
                  {loc.name}
                </div>
              )}

              {/* employee, if it's assigned */}
              {emp && (
                <div className={cx('ds-mod-lss-row')}>
                  {emp.name}
                </div>
              )}

              {/* offer's reason */}
              {s.hasOffer && s.hasOffer.reason && (
                <div className={cx('ds-mod-lss-row')}>
                  <div className={cx('ds-mod-lss-offer-reason')}>
                    <span>{t('OFF_LIST_REASON')}</span>
                    {s.hasOffer.reason}
                  </div>
                </div>
              )}

              {/* offer progress data */}
              {s.hasOffer && s.hasOffer.status !== 'resolved' && s.hasOffer.announceAction && s.hasOffer.announceAction.result && s.hasOffer.announceAction.result.replies && (
                <div className={cx('ds-mod-lss-row')}>
                  <div className={cx('ds-mod-lss-offer-replies')}>
                    <div>
                      <div
                        className={cx('ds-mod-lor-title')}
                        style={{ marginBottom: '0.8ex' }}
                      >{t('OFF_LIST_REPLIES')}
                      </div>
                      <span
                        className={cx('ds-mod-lor-pos', { 'is-zero': s.hasOffer.announceAction.result.replies.filter(r => r.reply === 'yes').length === 0 })}
                      >{s.hasOffer.announceAction.result.replies.filter(r => r.reply === 'yes').length}
                      </span>
                      <span
                        className={cx('ds-mod-lor-neg', { 'is-zero': s.hasOffer.announceAction.result.replies.filter(r => r.reply === 'no').length === 0 })}
                      >{s.hasOffer.announceAction.result.replies.filter(r => r.reply === 'no').length}
                      </span>
                      <span
                        className={cx('ds-mod-lor-noreply', { 'is-zero': [...new Set(s.hasOffer.announceAction.targetUsers)].length - s.hasOffer.announceAction.result.replies.length === 0 })}
                      >{[...new Set(s.hasOffer.announceAction.targetUsers)].length - s.hasOffer.announceAction.result.replies.length}
                      </span>
                      {s.hasOffer.transfer && !s.hasOffer.transfer.master &&
                        <div className={cx('ds-mod-lor-transfer')}>{t('OFF_LIST_TRANSFER')}</div>}
                    </div>
                    {s.hasOffer.announceAction.deadline ? (
                      <div>
                        <div
                          className={cx('ds-mod-lor-title')}
                          style={{ textAlign: 'right' }}
                        >{t('OFF_LIST_DEADLINE')}
                        </div>
                        <div className={cx('ds-mod-lor-deadline')}>
                          {moment(s.hasOffer.announceAction.deadline).format('H:mm')}
                          <br />
                          {moment(s.hasOffer.announceAction.deadline).format('dddd D.M.')}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

            </div>
          )
        })}

        {singleSelectedShift && singleSelectedShift.id && (
          <div
            style={{ position: 'absolute', left: '1ex', bottom: '1ex', fontSize: '65%', color: 'rgb(204, 204, 204)' }}
          >
            id: {singleSelectedShift.id}
          </div>
        )}
      </div>
    </div>
  )
}

export default connect(ListShifts)
