import isLoading from './is-loading'
import {
  requestAvailabilitiesUser,
  requestAvailabilitiesWorkspace,
  requestTimeOffsUser,
  requestTimeOffsWorkspace
} from '../request'
import addError from '../ac/add-error'
import {
  setAvailabilities,
  setTimeOffs
} from '../action'
import store from '../store'
import moment from 'moment'

export default (period, options = {}) => {
  return async (dispatch) => {
    const {
      // priority of these 4 props:
      // - is user is set, it's used
      // - then, if usersForTimeOffs or usersForTimeAvailabilities is set, we use that (depending on the query)
      // - if none of those are set, we use users
      user,
      usersForTimeOffs,
      usersForTimeAvailabilities,
      users,

      dontLoadAvailabilities,
      dontLoadTimeOffs
    } = options
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !period) return

    await dispatch(isLoading('load-availabilities-and-time-offs'))

    // get the unavs for the period from BE
    const resultAvails = !dontLoadAvailabilities
      ? (user
        ? await requestAvailabilitiesUser({
          user,
          workspace: workspaceId,
          period
        }, auth)
        : await requestAvailabilitiesWorkspace({
          workspace: workspaceId,
          period,
          users: usersForTimeAvailabilities || users
        }, auth)
      )
      : []

    // get the timeOffs for the period from BE
    const resultTimeOffs = !dontLoadTimeOffs
      ? (user
        ? await requestTimeOffsUser({
          user,
          workspace: workspaceId,
          period
        }, auth)
        : await requestTimeOffsWorkspace({
          workspace: workspaceId,
          period,
          users: usersForTimeOffs || users
        }, auth)
      )
      : []

    if (resultAvails.error) {
      dispatch(addError(resultAvails.error))
    } else if (resultTimeOffs.error) {
      dispatch(addError(resultTimeOffs.error))
    } else {
      // keep the store availability/timeOff records that are not from requested period
      const availabilitiesOutsidePeriod = store.getState().availabilities.filter(rec => moment(rec?.period?.start).isBefore(period.start) || moment(rec?.period?.start).isAfter(period.end))
      const timeOffsOutsidePeriod = store.getState().timeOffs.filter(rec => moment(rec?.period?.start).isBefore(period.start) || moment(rec?.period?.start).isAfter(period.end))

      // and replace the requested period by the request results + save the new arrays to store
      await dispatch(setAvailabilities(availabilitiesOutsidePeriod.concat(resultAvails || [])))
      await dispatch(setTimeOffs(timeOffsOutsidePeriod.concat(resultTimeOffs || [])))
    }
    await dispatch(isLoading('load-availabilities-and-time-offs', true))

    return resultAvails.error || resultTimeOffs.error || resultAvails.concat(resultTimeOffs)
  }
}
