import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ShiftDeleteMultiMutation (
  $workspace: ID!,
  $shifts: [ID!]!
) {
  shiftDeleteMulti (
    workspace: $workspace
    ids: $shifts
  )
}`

async function requestShiftDeleteMulti (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-delete-multi', errors)
  }
  return data.shiftDeleteMulti
}

export default requestShiftDeleteMulti
