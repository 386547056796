import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import setModal from '@app/ac/set-modal'

const mapStateToProps = ({ language, auth, workspaceId, workspaces, organization, loading }) => {
  const ws = workspaces && workspaces.find(w => w.id === workspaceId)
  return {
    ws,
    workspaceId,
    language,
    auth,
    organization,
    isLoading: loading.includes('load-export-token')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    loadWorkspaceDetail
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
