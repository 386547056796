import deepmerge from 'deepmerge'
import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.organization, action) => {
  switch (action.type) {
    case aT.SET_ORG:
      return action.data
    case aT.RESET_STORE:
      return initial.organization
    case aT.SET_ORG_CALENDAR_LOCKING_SETTINGS:
      return deepmerge(state, { settings: { customCalendarLocking: action.data } })
    default:
      return state
  }
}
