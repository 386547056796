import isLoading from './is-loading'
import { t } from 'i18next'

import { Alert } from '@ui'

import { setModal } from '@app/ac'
import { requestAvailabilityDeleteMulti, requestTimeOffDeleteMulti } from '@app/request'
import { multiSelectUtil, notification } from '@app/util'
import store from '@app/store'

export default (details, isUnavailability, afterSubmit) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()

    dispatch(setModal('confirm', {
      title: t('MULTI_ACTION_DEL_CONFIRM_TITLE'),
      type: Alert.TYPES.ERROR,
      confirmLabel: t('DELETE'),
      repeatingTimeOffs: true,
      onConfirm: async (props) => {
        if (isUnavailability) {
          await requestAvailabilityDeleteMulti({
            data: [{ id: details.id, recurrenceRemoveFollowing: !!props?.recurrence }],
            workspace: workspaceId
          }, auth).then((r) => {
            dispatch(setModal(null))
            if (!r.error) notification.success({ code: 'success' })
          })
        } else {
          await requestTimeOffDeleteMulti({
            data: [{ id: details.id, recurrenceRemoveFollowing: !!props?.recurrence }],
            workspace: workspaceId
          }, auth).then((r) => {
            dispatch(setModal(null))
            if (!r.error) notification.success({ code: 'success' })
          })
        }
        // if an 'afterSubmit' function was provided, call it here
        if (afterSubmit) await afterSubmit()
      },
      onConfirmBonusOptions: (details?.recurrence)
        ? [{
          label: t('DELETE_SELECTED'),
          onConfirmFnParamsObject: { recurrence: false }
        },
        {
          label: t('DELETE_REPEATING'),
          onConfirmFnParamsObject: { recurrence: true }
        }]
        : undefined,
      onConfirmBonusOptionsTitle: t('REPEATING_TIMEOFFS'),
      cancelLabel: t('CANCEL'),
      onCancel: () => {},
      subtitle: t('MULTI_ACTION_DEL_CONFIRM_DELETE_SUBTITLE_X', { x: 1 }),
      recordsList: [details],
      buttonColor: 'red',
      overSidebar: true
    }))

    multiSelectUtil.multiSelectClear()

    await dispatch(await isLoading('create-plan', true))
  }
}
