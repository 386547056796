import isLoading from './is-loading'
import requestPlanCreate from '../request/plan-create'
import addError from '../ac/add-error'
import store from '../store'

export default (form) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!form) return
    dispatch(isLoading('create-plan'))
    const result = await requestPlanCreate({
      workspace: workspaceId,
      data: form.data
    }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
    }
    await dispatch(await isLoading('create-plan', true))
  }
}
