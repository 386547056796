import { selectedMonth } from './helpers'
import { t } from 'i18next'
import { monthList } from '@app/util/mappers'

export const EXPORT_FORM = 'exportForm'

// Fields DropDown
export const MONTH_TYPE_VALUE = 'monthType'

// Fields Radios
export const ENTRY_TYPE_VALUE = 'entryType'
export const SELECTED_ENTRY_TYPE = 'selectedEntryType'
export const SAVE_AS_TYPE_VALUE = 'saveAsType'
export const SELECTED_SAVE_AS_TYPE = 'selectedSaveAsType'
export const SELECTED_LOCALITY_VALUE = 'selectedLocality'
export const SORTING_EMP_TYPE_VALUE = 'sortingEmployeesType'
export const COLOR_TYPE_VALUE = 'colorType'

// Fields Checkboxes
export const SHIFTS = 'shifts'
export const POSITION = 'position'
export const UNAVAILABILITY = 'unavailability'
export const LOCALITY = 'locality'
export const EMPLOYEES_WITHOUT_SHIFTS = 'employeesWithoutShifts'
export const NOTES_DAY = 'notesDay'
export const NOTES_SHIFT = 'notesShift'
export const HIDDEN_EMPLOYEES = 'includeHidden'
export const SIGNATURE = 'includeSignature'
export const STATISTICS = 'includeStatistics'

export const INITIAL_FORM_VALUES = {
  [ENTRY_TYPE_VALUE]: 'grid',
  [MONTH_TYPE_VALUE]: selectedMonth(monthList(t)).value,
  [SAVE_AS_TYPE_VALUE]: 'xlsx',
  [SHIFTS]: true,
  [POSITION]: false,
  [UNAVAILABILITY]: false,
  [LOCALITY]: false,
  [EMPLOYEES_WITHOUT_SHIFTS]: false,
  [NOTES_DAY]: false,
  [NOTES_SHIFT]: false,
  [HIDDEN_EMPLOYEES]: false,
  [SIGNATURE]: false,
  [STATISTICS]: false,
  [SELECTED_LOCALITY_VALUE]: null,
  [SORTING_EMP_TYPE_VALUE]: 'alph',
  [COLOR_TYPE_VALUE]: 'colourless'
}
