import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (state = initial.shiftDetail, action) => {
  switch (action.type) {
    case aT.SET_SHIFT_DETAIL:
      return Object.assign({}, action.data)
    case aT.RESET_STORE:
      return initial.shiftDetail
    case aT.SWITCH_WORKSPACE:
      return initial.shiftDetail
    default:
      return state
  }
}
