import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation RequirementUpdateMutation (
  $workspace: ID!,
  $template: ID!,
  $requirement: ID!,
  $position: ID!,
  $locality: ID,
  $start: Int!,
  $duration: Int!,
  $idealSkill: Int,
  $pauses: [PauseData!],
  $pausesFixed: Boolean,
  $agenda: [ShiftAgendaTaskInput!]
) {
  requirementUpdate (
    workspace: $workspace
    template: $template
    requirement: $requirement
    data: {
      position: $position
      locality: $locality
      start: $start
      duration: $duration
      idealSkill: $idealSkill
      pauses: $pauses
      pausesFixed: $pausesFixed
      agenda: $agenda
    }
  ) {
    id
  }
}`

async function requestRequirementUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('requirement-update', errors)
  }
  return data.requirementUpdate
}

export default requestRequirementUpdate
