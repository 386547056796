import React, { cloneElement, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import classNames from 'classnames/bind'

import { Icon } from '@ui'
import { FileItem } from '@app/component/ui/file-item'

import styles from './index.scss'

const cx = classNames.bind(styles)

export const DropFiles = ({
  onChange,
  accept,
  multiple,
  children,
  iconSize,
  loading,
  ...rest
}) => {
  const [state, setState] = useState({
    files: []
  })
  const { files } = state

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.find((item) => item.toDelete === true)) {
      setState({ files: acceptedFiles.filter((item) => item.toDelete !== true) })
      onChange(acceptedFiles.filter((item) => item.toDelete !== true))
    } else {
      setState({ files: [...files, ...acceptedFiles] })
      onChange([...files, ...acceptedFiles])
    }
  }, [files])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isFileTooLarge
  } = useDropzone({
    accept,
    multiple,
    onDrop
  })

  return (
    <div {...getRootProps()} className={cx('ds-drop-files')}>
      {loading && (
        <div className={cx('loading')}>
          <Icon ico={Icon.ICONS.loadingAnimated} />
        </div>
      )}
      <input {...getInputProps()} />
      {!files?.length ? !isDragActive && (
        cloneElement(children, {
          ...rest
        })
      ) : files.map((file, k) => {
        const ext = file.name && file.name.split('.')[file.name.split('.').length - 1]
        return (
          <FileItem
            key={`${1 + k}`}
            name={file.name}
            size={file.size}
            isDelete
            ext={ext}
            onClick={(e) => {
              e.stopPropagation()
              const index = files.indexOf(file)
              const FileArray = files.slice(0)
              onDrop([...FileArray.filter((item, j) => j !== index), { toDelete: true }])
            }}
          />
        )
      })}
      {isDragActive && !isDragReject && <div className={cx('ds-drop-files-msg')}>{t('NF_DROP_YOUR_FILES')}</div>}
      {isDragReject && <div className={cx('ds-drop-files-msg')}>{t('NF_FILE_NOT_SUPPORTED')}</div>}
      {isFileTooLarge && <div className={cx('ds-drop-files-msg')}>{t('NF_LARGE_FILE')}</div>}
    </div>
  )
}
DropFiles.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  iconSize: PropTypes.number,
  accept: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  loading: PropTypes.bool
}
DropFiles.defaultProps = {
  children: null,
  iconSize: null,
  multiple: false,
  loading: false
}
