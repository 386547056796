import React, { Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import { notification, calendarUtil } from '@app/util'
import {
  Modal,
  Button,
  Input,
  Textarea,
  Icon
} from '@ui'

import connect from './connect'

class EditShiftAttribute extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      detailsState: {},
      errors: []
    }
    this.saveAttribute = this._saveAttribute.bind(this)
    this.deleteAttribute = this._deleteAttribute.bind(this)
    this.canSave = this._canSave.bind(this)
    this.getDetails = this._getDetails.bind(this)
    this.setVal = this._setVal.bind(this)
    this.getDefaultDetails = this._getDefaultDetails.bind(this)
  }

  // if clickDeleteButton prop is true, wait for the loading to stop and then click the delete button
  componentDidUpdate (prevProps) {
    const { workspace, attributeId } = this.props
    if (
      this.props.clickDeleteButton &&
      !this.getDetails().readOnly &&
      prevProps.isLoading &&
      !this.props.isLoading
    ) {
      setTimeout(() => {
        if (
          workspace &&
          workspace.shiftAttributes &&
          workspace.shiftAttributes.find((att) => att.id === attributeId)
        ) {
          const el = document.querySelector('.ds-delete-attribute-btn')
          if (el) el.click()
        }
      }, 100)
    }
  }

  componentDidMount () {
    const { newAttribute } = this.props

    // reset the state if creating a new attribute
    if (newAttribute) {
      this.setState((s) =>
        Object.assign({}, s, { detailsState: this.getDefaultDetails() })
      )
    } else {
      // load the attribute details (from store) if not creating a new one
      this.setState((s) =>
        Object.assign({}, s, {
          detailsState: this.getDetails()
        })
      )
    }

    // load some shifts if they're not loaded
    if (!this.props.shifts || !this.props.shifts.length) {
      this.props.loadShifts({
        period: {
          start: moment().add(-90, 'days'),
          end: moment().add(90, 'days')
        },
        warningsHidden: true
      })
    }

    // load employees if they're not loaded
    if (!this.props.employees || !Object.keys(this.props.employees).length) {
      this.props.loadEmployees()
    }
  }

  _getDefaultDetails () {
    const { newAttributeName, newAttributeShortName, newDescription } =
      this.props
    return {
      id: null,
      name: newAttributeName || null,
      shortName: newAttributeShortName || null,
      description: newDescription || null
    }
  }

  _saveAttribute () {
    const { newAttribute, attributeId, setModal, saveShiftAttribute } =
      this.props
    const { detailsState } = this.state

    // create new attribute or update an existing one
    saveShiftAttribute(
      detailsState,
      newAttribute ? undefined : attributeId
    ).then((res) => {
      if (!res.error) {
        // success notification
        notification.success({
          code: newAttribute ? 'attributeCreated' : 'attributeUpdated'
        })
        setModal(null)
      }
    })
  }

  _canSave () {
    const { detailsState, errors } = this.state
    if (!detailsState) return false
    if (!detailsState.name) return false // name is required
    if (!detailsState.shortName) return false // short name is required
    if (detailsState.shortName.length > 3) return false // short name must be at most 3 characters
    if (errors && errors.length) return false
    return true
  }

  _deleteAttribute () {
    const {
      attributeId,
      setModal,
      shifts,
      deleteShiftAttribute
    } = this.props
    if (attributeId) {
      const shiftsWithAttribute = shifts
        ? shifts.filter(
          (s) =>
            s.customAttributes &&
              s.customAttributes.find((att) => att.attributeId === attributeId)
        )
        : []

      if (shiftsWithAttribute.length) {
        // if some shifts have this attribute, display a confirmation modal

        setModal('confirm', {
          title: t('ATTRIBUTE_CONFIRM_DELETE_TITLE'),
          subtitle: t('ATTRIBUTE_CONFIRM_DELETE_SUBTITLE'),
          recordsList: shiftsWithAttribute,
          confirmLabel: t('DELETE'),
          onConfirm: () => {
            deleteShiftAttribute(attributeId).then(() => {
              this.props.close()
            })
          },
          cancelLabel: t('CLOSE'),
          onCancel: () => {},
          overSidebar: true
        })
      } else {
        // if no emps have this attribute, just delete it
        deleteShiftAttribute(attributeId).then(() => {
          this.props.close()
        })
      }
    }
  }

  _getDetails () {
    const { workspace, newAttribute, attributeId } = this.props
    if (!workspace || !workspace.shiftAttributes) return
    if (!newAttribute && !attributeId) return

    return attributeId
      ? calendarUtil
        .getSupportedShiftAttributes(workspace)
        .find((c) => c.id === attributeId) || {}
      : this.getDefaultDetails()
  }

  _setVal (key, val) {
    // remove the 'name-missing' error if added a name
    if (key === 'name' && val && val !== '') {
      if (this.state.errors.includes('nameMissing')) {
        this.setState((s) =>
          Object.assign({}, s, {
            errors: this.state.errors.filter((e) => e !== 'nameMissing')
          })
        )
      }
    }

    // remove the 'short-name-missing' error if added a short name
    if (key === 'shortName' && val && val !== '') {
      if (this.state.errors.includes('shortNameMissing')) {
        this.setState((s) =>
          Object.assign({}, s, {
            errors: this.state.errors.filter((e) => e !== 'shortNameMissing')
          })
        )
      }

      // remove the 'short-name-too-long' error if new short name is no longer too long
      if (this.state.errors.includes('shortNameLong') && val.length <= 3) {
        this.setState((s) =>
          Object.assign({}, s, {
            errors: this.state.errors.filter((e) => e !== 'shortNameLong')
          })
        )
      }
    }

    // ensure that the abbreviation is uppercase
    if (key === 'shortName' && val && val !== '') {
      val = val.toUpperCase()
    }

    // set the value
    this.setState((prevState) => {
      const detailsState = Object.assign({}, prevState.detailsState)
      detailsState[key] = val
      return { detailsState }
    })
  }

  render () {
    const { isLoading, newAttribute } = this.props
    const details = this.state.detailsState
    const errors = this.state.errors

    const daysWithShifts = []
    if (details && details.shifts) {
      for (var i = 0; i < details.length; i++) {
        daysWithShifts.push([])
      }
      details.shifts.forEach((s) => {
        // note: shift's s.start is a number of minutes since details.startDate
        const idx = Math.floor(s.start / 1440)
        if (idx < daysWithShifts.length) {
          daysWithShifts[idx].push(s)
        }
      })
    }

    return (
      <Modal
        size={Modal.SIZES.M}
        isLoading={isLoading || !details}
        headerContent={
          <div className='ds-title'>
            {newAttribute
              ? t('ATTRIBUTES_SHIFT_CREATE_TITLE')
              : t('ATTRIBUTES_SHIFT_EDIT_TITLE')}
          </div>
        }
        extraHeaderButtons={
          newAttribute
            ? []
            : [
              <Icon
                key='delete-attribute'
                ico={Icon.ICONS.delete}
                color={Icon.COLORS.RED}
                onClick={(e) => this.deleteAttribute()}
                size={Icon.SIZES.SMALL}
              />]
        }
        sections={[
          <Fragment key={0}>
            {/* Name */}
            <Input
              label={t('NAME_V2')}
              size={Input.SIZES.FULL_WIDTH}
              autoFocus={newAttribute}
              type='text'
              hasError={errors && errors.find((e) => e === 'nameMissing')}
              placeholder={t('MODAL_ATTRIBS_NAME_PLACEHOLDER')}
              onChange={(v) => {
                this.setVal('name', v)
              }}
              value={details.name}
            />
            {/* Short Name */}
            <Input
              label={t('MODAL_ATTRIBS_SHORT_NAME')}
              size={Input.SIZES.FULL_WIDTH}
              type='text'
              hasError={
                errors &&
                errors.find((e) => e === 'shortNameMissing' || e === 'shortNameLong')
              }
              maxLength={3}
              placeholder={t('MODAL_ATTRIBS_SHORT_NAME_PLACEHOLDER')}
              onChange={(v) => {
                this.setVal('shortName', v)
              }}
              value={details.shortName}
            />
            {/* Description */}
            <Textarea
              label={t('NOTE')}
              size={Input.SIZES.FULL_WIDTH}
              maxLength={100}
              placeholder={t('MODAL_ATTRIBS_DESC_PLACEHOLDER')}
              onChange={(v) => {
                this.setVal('description', v)
              }}
              value={details.description}
            />
            {errors && errors.length ? (
              <div className='ds-modal-content-section ds-ea-errors-section'>
                {errors.map((e) => (
                  <div key={e} className='ds-err'>
                    {t('MODAL_ATTRIBS_ERR.' + e)}
                  </div>
                ))}
              </div>
            ) : null}
          </Fragment>
        ]}
        footerContent={
          <>
            <Button
              label={t('CLOSE')}
              onClick={() => this.props.close()}
              size={Button.SIZES.LARGE}
            />
            <Button
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
              color={newAttribute ? Button.COLORS.GREEN : Button.COLORS.PRIMARY}
              label={t('SAVE')}
              disabled={!this.canSave()}
              onClickActiveWhenDisabled
              onClick={() => {
                // save or create the attribute if possible
                if (this.canSave()) this.saveAttribute()

                // check for potential errors
                if (
                  !details.name ||
                  details.name === '' ||
                  !details.shortName ||
                  details.shortName === '' ||
                  (details.shortName && details.shortName.length > 3)
                ) {
                  const newErrs = this.state.errors
                  if (
                    !this.state.errors.includes('nameMissing') &&
                    (!details.name || details.name === '')
                  ) {
                    newErrs.push('nameMissing')
                  }
                  if (
                    !this.state.errors.includes('shortNameMissing') &&
                    (!details.shortName || details.shortName === '')
                  ) {
                    newErrs.push('shortNameMissing')
                  }
                  if (
                    !this.state.errors.includes('shortNameLong') &&
                    details.shortName &&
                    details.shortName.length > 3
                  ) {
                    newErrs.push('shortNameLong')
                  }
                  this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                }
              }}
            />
          </>
        }
      />
    )
  }
}

export default connect(EditShiftAttribute)
