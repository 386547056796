import { errorUtil, fetchRequest } from '@app/util'

const query = `mutation OrganizationPositionCreate(
  $organization: ID!,
  $country: ID!
  $data: PositionCreateInput!
) {
  organizationPositionCreate (
    organization: $organization
    country: $country
    data: $data
  ) {
    id
    name
    color
  }
}`

async function requestPositionCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data
}

export default requestPositionCreate
