"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAC = void 0;
/**
 * DO NOT TOUCH VARGS TYPES
 *
 * Simplified type:\
 * CreateAC = <VARGS, T>( (Dispatch, ...VARGS) => T ) => <VARGS, T>(...VARGS) => (Dispatch) => Promise&lt;T>
 *
 * @example <caption>Single argument action creator</caption>
 * // Default T is void
 * const setUnreadMessages = createAC<[number]>((dispatch, unreadCount) => {
 *  dispatch(setUnreadCountAction(unreadCount))
 * })
 *
 * @example <caption>Multiple arguments action creator with return type</caption>
 * const addShiftAttributes = createAC<[string[], string[]], {}>((dispatch, shiftIds, attribIds) => {
 *  // Code here
 * return {}
 * })
 *
 * @author https://github.com/milansav
 */
const createAC = (e) => 
// "action arguments wrapper" function
(...args) => 
// dispatch injection
(dispatch) => {
    // finally your code
    // @ts-expect-error
    return e(dispatch, ...args);
};
exports.createAC = createAC;
