import moment from 'moment'

import requestShiftCreate from '@app/request/shift-create'
import { notification } from '@app/util'

import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'

export default (day, form) => {
  return async (dispatch) => {
    const { auth, workspaceId, workspaceEvents } = store.getState()
    if (!workspaceId) return

    const start = moment(day).set({ hour: Math.floor(form.start / 60), minute: Math.floor(form.start % 60) }).toISOString()
    let end = moment(day).set({ hour: Math.floor(form.end / 60), minute: Math.floor(form.end % 60) }).toISOString()
    if (moment(end).format() <= moment(start).format()) end = moment(end).add(1, 'day').toISOString()

    dispatch(isLoading('create-shift'))
    const count = Math.abs(form.employees || 1)
    const result = []
    for (let i = 0; i < count; i++) {
      const res = await requestShiftCreate({
        user: form.user,
        workspace: workspaceId,
        position: form.position,
        locality: form.locality,
        idealSkill: form.idealSkill,
        start,
        end,
        pauses: form.pauses,
        pausesFixed: form.pausesFixed,
        customAttributes: form.customAttributes,
        note: form.note,
        overTime: form.overTime,
        standBy: form.standBy,
        standByActivities: form.standByActivities,
        contractType: form.contractType,
        contractId: form.contractId,
        agenda: form.agenda
      }, auth)
      result.push(res)
      if (res.error) dispatch(addError(res.error))
    }

    // if the shift is put into closed day, warn the user
    if (result && result.length && !result.find(r => r.error) && result[0].period) {
      const closedEv = workspaceEvents.find((ev) => ev.type === 'closed' && moment(result[0].period.start).isBefore(ev.period.end) && moment(result[0].period.end).isAfter(ev.period.start))
      if (closedEv) {
        notification.warn({ code: 'addedShiftInClosedDay' })
      }
    }

    dispatch(isLoading('create-shift', true))
    return result
  }
}
