import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationCountryUpdate(
  $countryUpdateOrganization2: ID!,
  $countryUpdateCountry2: ID!,
  $data: CountryUpdateInput
  ) {
  countryUpdate(organization: $countryUpdateOrganization2, country: $countryUpdateCountry2, data: $data) {
    bonuses {
      sales {
        minKPI
        maxKPI
        step
        positions {
          positionId
          amount
        }
      }
      mealVouchers {
        rules {
          contractType
          condition
          calculationType
          shiftDurationMinutes
          amount
        }
      }
      inventory {
        positions {
          positionId
          amount
        }
        workTimePercentage
      }
    }
  }
}`

async function requestOrgBonusSettingsUpdate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.countryUpdate
}

export default requestOrgBonusSettingsUpdate
