import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.tablesConfig, action) => {
  switch (action.type) {
    case aT.SET_TABLES_CONFIG:
      return action.data
    default:
    {
      return state
    }
  }
}
