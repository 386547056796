import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestLocalityCreate from '../request/locality-create'
import requestLocalityUpdate from '../request/locality-update'
import loadWorkspaceDetail from './load-workspace-detail'

export default (data, localityId) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !data) return

    dispatch(isLoading('save-locality'))
    let result = null
    if (localityId) {
      // if 'localityId' is set, we're modifying the existing cycle
      result = await requestLocalityUpdate({
        id: localityId,
        workspace: workspaceId,
        data: {
          name: data.name,
          shortName: data.shortName,
          address: data.address,
          note: data.note
        }
      }, auth)
    } else {
      // otherwise, we're just creating a new cycle
      result = await requestLocalityCreate({
        workspace: workspaceId,
        data: {
          name: data.name,
          shortName: data.shortName,
          address: data.address,
          note: data.note
        }
      }, auth)
    }

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('save-locality', true))
    return result
  }
}
