import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationBonusesSet($organization: ID!, $bonuses: [EmployeeBonusesInput!]!) {
  organizationBonusesSet(organization: $organization, bonuses: $bonuses) {
    month
  }
}`

async function requestOrgBonusSet (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationBonusesSet
}

export default requestOrgBonusSet
