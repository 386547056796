import React, { useEffect } from 'react'

import { unreadNotifications } from '@app/util/notifications'
import { Icon, NavItem } from '@ui'
import { t } from 'i18next'

import connect from '../connect'

export const NotificationItem = connect(({
  sidebar,
  notifications,
  setSidebar
}) => {
  const isNotificationOpen = sidebar?.type === 'notification'

  useEffect(() => {
    // update the number of notifications in tab title
    const location = window.location.host.split('.')[1]
    const unreadNotifs = notifications && notifications.length && notifications.map((item) => !item.read).filter(Boolean).length
    const numMatches = document.title.match(/\((.*?)\)/)
    if (parseInt(numMatches && numMatches[0]) !== parseInt(unreadNotifs)) {
      if (!isNaN(unreadNotifs) && parseInt(unreadNotifs) !== 0) {
        const withNotification = '(' + unreadNotifs + ') Dayswaps'
        document.title = withNotification
        if (location === 'dev') document.title = 'DEV ' + withNotification
        if (location === 'stage') document.title = 'STAGE ' + withNotification
      } else {
        document.title = 'Dayswaps'
        if (location === 'dev') document.title = 'DEV - Dayswaps'
        if (location === 'stage') document.title = 'STAGE - Dayswaps'
      }
    }
  }, [notifications.length])
  return (
    <NavItem
      ico={Icon.ICONS.bell}
      text={t('NOTIFICATIONS')}
      activeItem={isNotificationOpen}
      onClick={() => !isNotificationOpen ? setSidebar('notification') : setSidebar(null)}
      path='#notification'
      number={unreadNotifications(notifications)}
    />
  )
})
