"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsPluginEnabled = void 0;
const react_redux_1 = require("react-redux");
const useIsPluginEnabled = (pluginName, defaultValue = false) => {
    var _a;
    const { workspaces, workspaceId } = (0, react_redux_1.useSelector)(({ workspaces, workspaceId }) => ({ workspaces, workspaceId }));
    const desiredWs = workspaces.find((ws) => ws.id === workspaceId) || { plugins: [] };
    // @ts-ignore
    const desiredPlugin = ((_a = desiredWs === null || desiredWs === void 0 ? void 0 : desiredWs.plugins) === null || _a === void 0 ? void 0 : _a.find((plugin) => plugin.plugin === pluginName)) || { enabled: defaultValue };
    return desiredPlugin === null || desiredPlugin === void 0 ? void 0 : desiredPlugin.enabled;
};
exports.useIsPluginEnabled = useIsPluginEnabled;
