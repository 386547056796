import store from '../store'
import isLoading from './is-loading'
import requestPositionAssignDelete from '../request/position-assign-delete'
import addError from '../ac/add-error'

export default (user, position, contract) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return

    dispatch(isLoading('assign-position-delete'))
    const result = await requestPositionAssignDelete({
      workspace: workspaceId,
      user,
      position,
      contract
    }, auth)
    if (result.error) {
      dispatch(addError(result.error))
    }
    dispatch(isLoading('assign-position-delete', true))
    return result
  }
}
