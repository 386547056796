import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import './body.scss'

export const Body = ({ children, className, ...rest }) => (
  <div className={`ds-body ${className}`}>
    {cloneElement(children, {
      ...rest
    })}
  </div>
)
Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
Body.defaultTypes = {
  children: null,
  className: ''
}
