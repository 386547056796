import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import { Icon } from '@ui'
import Loading from '@app/image/loader-inline.svg'

import styles from './index.scss'

const cx = classNames.bind(styles)

export const Label = ({
  title,
  onClick,
  onMouseOver,
  onMouseOut,
  icon,
  className,
  isLoading,
  style
}) =>
  <span
    style={style}
    onClick={onClick}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    className={cx(`label ${className}`, { isLoading })}
  >
    {!isLoading ? (icon && <Icon ico={icon} />) : <Loading />}{title}
  </span>
Label.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  style: PropTypes.object
}
Label.defaultProps = {
  onClick: () => {},
  onMouseOver: () => {},
  onMouseOut: () => {},
  style: {},
  icon: '',
  isLoading: false,
  className: ''
}
