import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationRoleUpdate(
  $organizationRoleUpdateOrganization2: ID!,
  $organizationRoleUpdateRole2: ID!,
  $countries: [ID!],
  $name: String,
  $areas: [ID],
  $permissions: [OrganizationPermission!]
) {
  organizationRoleUpdate(
    organization: $organizationRoleUpdateOrganization2,
    role: $organizationRoleUpdateRole2,
    countries: $countries,
    name: $name,
    areas: $areas,
    permissions: $permissions) {
      id
    }
}`

async function requestOrganizationRoleUpdate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationRoleUpdate
}

export default requestOrganizationRoleUpdate
