import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'

import { Text } from '@ui'

import './nav-bar.scss'

export const NavBar = ({ children, title, ...rest }) => (
  <div className='ds-nav-bar'>
    <Text type={Text.TYPES.HEADLINE1}>
      {title}
    </Text>
    {children && cloneElement(children, {
      ...rest
    })}
  </div>
)
NavBar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}
NavBar.defaultTypes = {
  children: null,
  title: ''
}
