import moment from 'moment'
import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.notifications, action) => {
  switch (action.type) {
    case aT.SET_NOTIFICATIONS:
      return [...action.data]
    case aT.LOAD_MORE_NOTIFICATIONS:
      return [...state, ...action.data]
    case aT.READ_NOTIFICATIONS:
      return state.map((item) => !item.read ? ({ ...item, read: moment().toISOString() }) : ({ ...item }))
    case aT.RESOLVE_NOTIFICATIONS:
      return state.map((item) => item.id === action.data ? ({ ...item, resolved: moment().toISOString() }) : ({ ...item }))
    case aT.RESET_STORE:
      return initial.notifications
    case aT.SWITCH_WORKSPACE:
      return initial.notifications
    default:
      return state
  }
}
