import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query NotificationsQuery (
  $limit : Int = 20,
  $offset: Int = 0
) {
  me {
    notifications (
      limit: $limit,
      offset: $offset
    ) ${q.NOTIFICATION}
  }
}`

async function requestNotifications (variables, token) {
  const response = await fetchRequest({ variables, query, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('notifications', errors)
  }
  return data.me.notifications
}

export default requestNotifications
