"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetch = void 0;
const client_1 = require("@apollo/client");
const util_1 = require("@app/util");
/**
 * @argument T - The type of the data returned by the query
 * @argument V - The type of the variables used in the query
 */
const useFetch = (query, { variables = {}, returnKey = undefined, defaultReturn = undefined, onError = () => null, onCompleted = () => null, skipInitial = false, }) => {
    const { loading, error, data, refetch, previousData } = (0, client_1.useQuery)(query, {
        variables,
        onError,
        onCompleted,
        skip: skipInitial,
    });
    const newData = () => {
        const returnKeyPreviousData = returnKey && previousData ? previousData[returnKey] : previousData;
        const returnKeyData = returnKey && data ? data[returnKey] : data;
        const previousOrDefault = previousData ? returnKeyPreviousData : defaultReturn;
        if (error) {
            return previousOrDefault;
        }
        if (!data) {
            return previousOrDefault;
        }
        return returnKeyData;
    };
    return {
        data: newData(),
        error: util_1.errorUtil.reduceErrors(error),
        loading,
        refetch,
    };
};
exports.useFetch = useFetch;
