import aT from '../const/action-type'

export const SetFeedAction = (data) => {
  return { type: aT.SET_NEWS_FEED, data }
}

export const AddFeedAction = (data) => {
  return { type: aT.ADD_NEWS_FEED, data }
}

export const UpdateFeedAction = (data) => {
  return { type: aT.UPDATE_NEWS_FEED, data }
}

export const DeleteFeedAction = (data) => {
  return { type: aT.DELETE_NEWS_FEED, data }
}

export const CommentFeedAction = (data) => {
  return { type: aT.COMMENT_NEWS_FEED, data }
}

export const LikeFeedAction = (data) => {
  return { type: aT.LIKE_NEWS_FEED, data }
}

export const ReadFeedAction = (data) => {
  return { type: aT.READ_NEWS_FEED, data }
}

export const PinFeedAction = (data) => {
  return { type: aT.PIN_NEWS_FEED, data }
}
