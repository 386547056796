import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import setModal from '@app/ac/set-modal'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import loadShifts from '@app/ac/load-shifts'

const mapStateToProps = ({ workspaceId, workspaces, auth, calendar }) => {
  return {
    workspaceId,
    workspaces,
    calendar,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadWorkspaceDetail,
    loadShifts,
    setModal
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
