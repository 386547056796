import React, { Fragment } from 'react'
import moment from 'moment'
import { t } from 'i18next'

import { Icon, DaySelect, Button, Modal } from '@ui'

import './index.scss'
import connect from './connect'

class CopyUnavailability extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      targets: [],
      copyEmployees: true
    }
  }

  async copyUnavailabilityToTargets () {
    const { targets } = this.state
    const { details, setAvailabilityOrTimeOff, setModal } = this.props

    const evts = []
    for (let i = 0; i < targets.length; i++) {
      const sDateStr = targets[i]
      const sTime = moment(
        sDateStr + 'T' + moment(details.period.start).format('HH:mm:ss'),
        'YYYY-MM-DDTHH:mm:ss'
      )
      const eTime = moment(
        sDateStr + 'T' + moment(details.period.end).format('HH:mm:ss'),
        'YYYY-MM-DDTHH:mm:ss'
      )
      if (eTime.isSameOrBefore(sTime)) eTime.add(1, 'day')
      evts.push({
        period: {
          start: sTime,
          end: eTime
        },
        periodType: details.periodType,
        user: details.userId,
        available: details.available,
        note: details.note
      })
    }
    await setAvailabilityOrTimeOff({ evts })

    setModal(null)
  }

  render () {
    const { targets } = this.state
    const {
      setModal,
      isLoading,
      details
    } = this.props
    const copyButtonDisabled =
      !targets || !targets.length || isLoading

    return (
      <Modal
        className='ds-copy-shift'
        size={Modal.SIZES.L}
        isLoading={isLoading}
        headerContent={
          <div className='ds-title'>
            {t('COPY_UNAV' + (details?.available ? '_AVAIL' : ''))}
          </div>
        }
        sections={[
          <Fragment key={0}>
            <div className='ds-es-subtitle ds-subtitle target-select-section'>
              <div className='target-selection-subtitle'>
                {t('COPY_UNAV_SUBTITLE')}
              </div>
              <DaySelect
                value={null}
                forbiddenPast
                allowMultiselect
                onChange={(v) => {
                  this.setState((s) => Object.assign({}, s, { targets: v }))
                  document.getElementsByClassName(
                    'ds-copy-modal-errors'
                  )[0].style.display = 'none'
                }}
              />
            </div>
          </Fragment>,
          <Fragment key={1}>
            <div
              className='ds-copy-modal-errors'
              style={{ display: 'none' }}
            >
              <Icon ico={Icon.ICONS.warning} />
              {t('COPY_DAY_NO_TARGETS_ERR')}
            </div>
          </Fragment>
        ]}
        footerContent={
          <>
            <Button
              label={t('CLOSE')}
              size={Button.SIZES.LARGE}
              onClick={() => {
                setModal(null)
              }}
            />
            <Button
              label={t('COPY')}
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
              onClickActiveWhenDisabled
              disabled={copyButtonDisabled}
              loading={isLoading}
              onClick={() => {
                if (copyButtonDisabled) {
                  document.getElementsByClassName(
                    'ds-copy-modal-errors'
                  )[0].style.display = 'block'
                } else {
                  this.copyUnavailabilityToTargets()
                }
              }}
            />
          </>
        }
      />
    )
  }
}

export default connect(CopyUnavailability)
