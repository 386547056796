export const organizationPermissions = {
  dashboard: [
    {
      label: 'ORG_SP_DASHBOARD',
      value: 'dashboard'
    }
  ],
  workspaces: [
    {
      label: 'ORG_SP_WORKSPACES_PERFORMANCE',
      value: 'workspaces_performance'
    },
    {
      label: 'ORG_SP_WORKSPACES_COUNTRIES',
      value: 'workspaces_countries'
    },
    {
      label: 'ORG_SP_WORKSPACES_AREAS',
      value: 'workspaces_areas'
    },
    {
      label: 'WORKSPACES',
      value: 'workspaces_workspaces'
    },
    {
      label: 'POSITIONS',
      value: 'workspaces_positions'
    },
    {
      label: 'ORG_SP_WORKSPACES_PLAN_REVENUE',
      value: 'workspaces_revenue'
    },
    {
      label: 'ORG_SP_WORKSPACES_NORMS',
      value: 'workspaces_norms'
    },
    {
      label: 'ORG_SP_WORKSPACES_PRODUCTIVITY',
      value: 'workspaces_production'
    },
    {
      label: 'ORG_SP_WORKSPACES_FULLTIME_PLAN',
      value: 'workspaces_full_time_plans'
    },
    {
      label: 'ORG_SP_WORKSPACES_HOLIDAYS',
      value: 'workspaces_holidays'
    }
  ],
  transfers: [
    {
      label: 'ORG_SP_TRANSFERS_OVERVIEW',
      value: 'transfers_overview'
    },
    {
      label: 'SB_TRANSFERS',
      value: 'transfers_details'
    },
    {
      label: 'GROUPS',
      value: 'transfers_groups'
    }
  ],
  bonuses: [
    {
      label: 'ORG_SP_BONUSES',
      value: 'bonuses_overview'
    },
    {
      label: 'ORG_BONUSES_MEAL_TICKETS',
      value: 'bonuses_meal_vouchers'
    },
    {
      label: 'ORG_BONUSES_SALE',
      value: 'bonuses_sales'
    },
    {
      label: 'ORG_BONUSES_STOCKTAKING',
      value: 'bonuses_inventory'
    },
    {
      label: 'ATTENDANCE',
      value: 'payroll_attendance'
    },
    {
      label: 'ATTENDANCE_LVL2',
      value: 'payroll_attendance_lvl2'
    },
    {
      label: 'ATTENDANCE_LVL3',
      value: 'payroll_attendance_lvl3'
    }
  ],
  reports: [
    {
      label: 'REPORTS',
      value: 'reports'
    }
  ],
  people: [
    {
      label: 'ORG_SP_PEOPLE',
      value: 'people'
    }
  ],
  settings: [
    {
      label: 'SETTINGS',
      value: 'settings'
    }
  ],
  billing: [
    {
      label: 'ORG_SP_BILLING',
      value: 'billing'
    }
  ]
}
