import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationInventoryBonusesGenerate(
  $organization: ID!,
  $workspaces: [ID!]!,
  $period: PeriodInput!
  ) {
  organizationInventoryBonusesGenerate(
    organization: $organization,
    workspaces: $workspaces,
    period: $period
  )
}`

async function requestOrgInventoryBonusGenerate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationInventoryBonusesGenerate
}

export default requestOrgInventoryBonusGenerate
