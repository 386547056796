import React, { Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { RadioGroup, Radio } from 'react-radio-group'

import {
  Button,
  Icon,
  WeekSelect,
  DaySelect,
  Modal,
  Flex,
  Spacing
} from '@ui'

import connect from './connect'
import './index.scss'

class CopyPeriod extends React.Component {
  constructor (props) {
    super(props)
    const calendar = Object.assign({}, props.calendar, props.forceCalendarSettings)
    const v = calendar.view !== 'month' ? calendar.view : 'week'
    const dps = calendar.view === 'month'
    this.state = {
      targets: [],
      copyEmployees: true,
      view: v,
      period: {
        start: calendar.date ? moment(calendar.date).startOf(v) : null,
        end: calendar.date ? moment(calendar.date).endOf(v) : null
      },
      displayPeriodSelection: dps
    }
  }

  render () {
    const { view, period, targets, displayPeriodSelection, copyEmployees } = this.state
    const { setModal, workspaceId, copyShiftPeriod, isLoading } = this.props

    if (!view || !period.start || !period.end) return null
    const tr = true
    const copyButtonDisabled = !targets || !targets.length || isLoading

    return (
      <Modal
        size={Modal.SIZES.L}
        className='ds-copy-period'
        isLoading={isLoading}
        headerContent={
          <div className='ds-title'>
            {t('COPY_' + view.toUpperCase()) + ' ' /* 'COPY_WEEK', 'COPY_WEEK_SUBTITLE', 'COPY_DAY', 'COPY_DAY_SUBTITLE' */}
            {moment(period.start).format('D.M.')}
            {moment(period.end).format('YYYY-MM-DD') !== moment(period.start).format('YYYY-MM-DD') ? ' - ' + moment(this.state.period.end).format('D.M.') : null}
          </div>
        }
        sections={[
          <Fragment key={0}>
            {displayPeriodSelection
              ? (
                <div className='ds-date-selection-block'>
                  <div className='target-selection-subtitle'>{t('COPY_WEEK_SELECT_WEEK_TO_COPY')}</div>
                  <WeekSelect
                    value={period.start}
                    onChange={(v) => {
                      const ns = this.state
                      ns.period = {
                        start: moment(v).startOf('week'),
                        end: moment(v).endOf('week')
                      }
                      this.setState((s) => Object.assign({}, s, ns))
                    }}
                  />
                </div>
              )
              : null}
            {displayPeriodSelection
              ? <div className='ds-date-selection-block'><Icon ico='arrow-right' /></div>
              : null}

            {view === 'week'
              ? (
                <div className='ds-date-selection-block'>
                  <div className='target-selection-subtitle'>{t('COPY_' + view.toUpperCase() + '_SUBTITLE')}</div>
                  <WeekSelect
                    value={null}
                    forbiddenPast
                    onChange={(v) => {
                      this.setState((s) => Object.assign({}, s, { targets: [v] }))
                      document.getElementsByClassName('ds-copy-modal-errors')[0].style.display = 'none'
                    }}
                  />
                </div>
              )
              : null}

            {view === 'day'
              ? (
                <div className='ds-date-selection-block'>
                  <div className='target-selection-subtitle'>{t('COPY_' + view.toUpperCase() + '_SUBTITLE')}</div>
                  <DaySelect
                    value={null}
                    forbiddenPast
                    allowMultiselect
                    onChange={(v) => {
                      this.setState((s) => Object.assign({}, s, { targets: v }))
                    }}
                  />
                </div>
              )
              : null}
          </Fragment>,
          <Fragment key={1}>
            <div className='ds-mcs-col'>
              <div className='ds-mcs-opt'>{t('COPY_EMPLOYEES')}</div>
              <RadioGroup
                selectedValue={copyEmployees}
                onChange={(val) => this.setState((s) => Object.assign({}, s, { copyEmployees: !copyEmployees }))}
              >
                <label
                  key='copyEmployees_true'
                  className='ds-radio ds-mcs-opt-sub'
                >
                  <Radio value={tr} />
                  {t('COPY_EMPLOYEES_SUBTITLE')}
                </label>
                <label
                  key='copyEmployees_false'
                  className='ds-radio ds-mcs-opt-sub'
                >
                  <Radio value={false} />
                  {t('COPY_EMPLOYEES_SUBTITLE_DISABLED')}
                </label>
              </RadioGroup>
            </div>
          </Fragment>,
          <Fragment key={2}>
            <Flex direction={Flex.DIRECTION.COLUMN}>
              <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.CENTER}>
                <Icon ico='info' /> {t('COPY_PERIOD_INFO')}
              </Flex>
              <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_16} />
              <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.CENTER}>
                <Icon ico={Icon.ICONS.warning} />
                {t('COPY_' + view.toUpperCase() + '_NO_TARGETS_ERR')}
              </Flex>
            </Flex>
          </Fragment>
        ]}
        footerContent={
          <>
            <Button
              label={t('CLOSE')}
              size={Button.SIZES.LARGE}
              onClick={() => { setModal(null) }}
            />
            <Button
              label={t('COPY')}
              size={Button.SIZES.LARGE}
              onClickActiveWhenDisabled
              disabled={copyButtonDisabled}
              loading={isLoading}
              onClick={() => {
                if (copyButtonDisabled) {
                  document.getElementsByClassName('ds-copy-modal-errors')[0].style.display = 'block'
                } else {
                  copyShiftPeriod({
                    workspace: workspaceId,
                    period: period,
                    targetDates: targets.map((t) => moment(t).format('YYYY-MM-DD')),
                    copyAssign: copyEmployees
                  })
                }
              }}
            />
          </>
        }
      />
    )
  }
}

export default connect(CopyPeriod)
