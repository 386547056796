import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationTransferGroupUpdate(
  $organization: ID!,
  $data: TransferGroupOrganizationInput!
) {
  organizationTransferGroupUpdate(
    organization: $organization,
    data: $data) {
      id
      name
      workspaces {
        workspaceId
      }
    }
}`

async function requestOrganizationTransfersGroupUpdate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationTransferGroupUpdate
}

export default requestOrganizationTransfersGroupUpdate
