import React, { Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import { notification, sortUtil } from '@app/util'
import {
  Icon,
  Tooltip,
  Button,
  Input,
  Modal,
  Dropdown,
  Text,
  Spacing,
  EmployeeList
} from '@ui'

import connect from './connect'

class EditLocality extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      detailsState: {},
      assignedEmps: [],
      errors: []
    }
  }

  componentDidMount () {
    const {
      // connected from store:
      workspace,
      // passed from props:
      localityId,
      newLocality
    } = this.props

    // reset the state if creating a new locality
    if (newLocality) {
      this.setState((s) => Object.assign({}, s, { detailsState: this.getDefaultDetails() }))
    } else {
      const loc = workspace.localities.find(l => l.id === localityId)
      // load the locality details (from store) if not creating a new one
      this.setState((s) => Object.assign({}, s, {
        detailsState: this.getDetails(),
        assignedEmps: (loc && loc.assigns) ? loc.assigns.map(la => la.userId) : []
      }))
    }

    // load some shifts if they're not loaded
    if (!this.props.shifts || !this.props.shifts.length) {
      this.props.loadShifts({
        period: {
          start: moment().add(-90, 'days'),
          end: moment().add(90, 'days')
        },
        warningsHidden: true
      })
    }
  }

  getDefaultDetails = () => {
    return {
      id: null,
      name: null,
      shortName: null,
      address: null,
      note: null
    }
  }

  saveLocalityFn = () => {
    const { newLocality, localityId, setModal, saveLocality, unassignLocality, assignLocality, workspace } = this.props
    const { detailsState } = this.state

    // create new locality or update an existing one
    saveLocality(detailsState, newLocality ? undefined : localityId).then(async (res) => {
      if (res.error) return

      const originalLoc = (!newLocality && localityId && workspace.localities)
        ? workspace.localities.find(l => l.id === localityId)
        : null

      // unassign original emps that are no longer selected
      if (originalLoc) {
        for (const assign of originalLoc.assigns) {
          if (!this.state.assignedEmps.includes(assign.userId)) {
            await unassignLocality(assign.userId, localityId)
          }
        }
      }

      // assign new emps that are not in the original employee list
      if (originalLoc) {
        for (const empId of this.state.assignedEmps) {
          if (
            !originalLoc.assigns ||
            !originalLoc.assigns.find((ass) => ass.userId === empId)
          ) {
            await assignLocality(empId, localityId)
          }
        }
      } else {
        if (res.id) {
          for (const empId of this.state.assignedEmps) {
            await assignLocality(empId, res.id)
          }
        }
      }

      await this.props.loadWorkspaceDetail(workspace.id)

      // success notification
      notification.success({ code: newLocality ? 'localityCreated' : 'localityUpdated' })
      setModal(null)
    })
  }

  canSave = () => {
    const { detailsState, errors } = this.state
    if (!detailsState) return false
    if (!detailsState.name) return false // name is required
    if (!detailsState.shortName) return false // short name is required
    if (errors && errors.length) return false
    return true
  }

  deleteLocality = () => {
    const {
      // passed from props:
      localityId,
      employees,
      // connected from store:
      deleteLocality,
      setModal,
      loadEmployees,
      shifts
    } = this.props
    if (localityId) {
      const empsWithLocality = employees
        ? Object.values(employees).filter(
          (e) => e.localities && e.localities.includes(localityId)
        )
        : []
      const shiftsWithLocality = shifts ? shifts.filter(s => s.localityId === localityId) : []

      if (empsWithLocality.length || shiftsWithLocality.length) {
        // if some emps have this locality, display a confirmation modal

        let subtitle = 'LOCALITY_CONFIRM_DELETE_SUBTITLE_BOTH'
        if (!empsWithLocality.length) subtitle = 'LOCALITY_CONFIRM_DELETE_SUBTITLE_SHIFTS'
        if (!shiftsWithLocality.length) subtitle = 'LOCALITY_CONFIRM_DELETE_SUBTITLE_EMPS'

        setModal('confirm', {
          title: t('LOCALITY_CONFIRM_DELETE'),
          subtitle: t(subtitle),
          recordsList: (empsWithLocality || []).concat(shiftsWithLocality || []),
          confirmLabel: t('DELETE'),
          onConfirm: () => {
            deleteLocality(localityId).then(() => {
              loadEmployees()
              this.props.close()
            })
          },
          cancelLabel: t('CLOSE'),
          onCancel: () => {},
          overSidebar: true
        })
      } else {
        // if no emps have this locality, just delete it
        deleteLocality(localityId).then(() => {
          this.props.close()
        })
      }
    }
  }

  getDetails = () => {
    const { workspace, newLocality, localityId } = this.props
    if (!workspace || !workspace.localities) return
    if (!newLocality && !localityId) return

    return localityId ? workspace.localities.find(c => c.id === localityId) : this.getDefaultDetails()
  }

  setVal = (key, val) => {
    // remove the 'name-missing' error if added a name
    if (key === 'name' && val && val !== '') {
      if (this.state.errors.includes('nameMissing')) {
        this.setState((s) => Object.assign({}, s, { errors: this.state.errors.filter(e => e !== 'nameMissing') }))
      }
    }

    // remove the 'short-name-missing' error if added a short name
    if (key === 'shortName' && val && val !== '') {
      if (this.state.errors.includes('shortNameMissing')) {
        this.setState((s) => Object.assign({}, s, { errors: this.state.errors.filter(e => e !== 'shortNameMissing') }))
      }
    }

    // ensure that the abbreviation is uppercase
    if (key === 'shortName' && val && val !== '') {
      val = val.toUpperCase()
    }

    // set the value
    this.setState(prevState => {
      const detailsState = Object.assign({}, prevState.detailsState)
      detailsState[key] = val
      return { detailsState }
    })
  }

  render () {
    const {
      // connected from store:
      isLoading,
      // passed from props:
      newLocality,
      employees
    } = this.props
    const details = this.state.detailsState
    const errors = this.state.errors

    if (!employees) {
      throw new Error('The employees prop was not passed to edit-locality modal! This is required.')
    }

    const daysWithShifts = []
    if (details && details.shifts) {
      for (var i = 0; i < details.length; i++) {
        daysWithShifts.push([])
      }
      details.shifts.forEach(s => {
        // note: shift's s.start is a number of minutes since details.startDate
        const idx = Math.floor(s.start / 1440)
        if (idx < daysWithShifts.length) {
          daysWithShifts[idx].push(s)
        }
      })
    }

    const employeesArray = Object.values(employees).filter((employee) => !employee.external)

    let selectedEmployees = []
    if (employeesArray.length && this.state.assignedEmps) {
      selectedEmployees = employeesArray.filter((employee) =>
        this.state.assignedEmps.includes(employee.id)
      )
    }

    return (
      <Modal
        testid='locality-editor-modal'
        size={Modal.SIZES.M}
        isLoading={isLoading || !details}
        headerContent={
          <div className='ds-title'>
            {newLocality ? t('LOCALITY_CREATE_LOCALITY') : t('LOCALITY_EDIT_LOCALITY')}
          </div>
        }
        extraHeaderButtons={
          !newLocality
            ? [
              <Tooltip
                key='delete-loc'
                position={Tooltip.POSITIONS.BOTTOM}
                anchor={
                  <Icon
                    testid='delete-locality-button'
                    ico={Icon.ICONS.delete}
                    color={Icon.COLORS.RED}
                    onClick={(e) => this.deleteLocality()}
                    size={Icon.SIZES.SMALL}
                  />
                }
              >
                {t('LOCALITY_DELETE_LOCALITY')}
              </Tooltip>
            ]
            : []
        }
        sections={[
          <Fragment key={0}>
            {/* Name */}
            <Input
              label={t('NAME_V2')}
              testid='locality-name-input'
              size={Input.SIZES.FULL_WIDTH}
              autoFocus={newLocality}
              type='text'
              hasError={errors && errors.find((e) => e === 'nameMissing')}
              errorMessage={
                errors && errors.find((e) => e === 'nameMissing')
                  ? t('LOCALITY_ERR.nameMissing')
                  : null
              }
              placeholder={t('LOCALITY_NAME_PLACEHOLDER')}
              onChange={(v) => {
                this.setVal('name', v)
              }}
              value={details.name}
            />

            {/* Abbreviation */}
            <Input
              label={t('SHORT')}
              testid='locality-short-name-input'
              size={Input.SIZES.FULL_WIDTH}
              type='text'
              hasError={errors && errors.find((e) => e === 'shortNameMissing')}
              errorMessage={
                errors && errors.find((e) => e === 'shortNameMissing')
                  ? t('LOCALITY_ERR.shortNameMissing')
                  : null
              }
              maxLength={3}
              placeholder={t('LOCALITY_SHORT_NAME_PLACEHOLDER')}
              onChange={(v) => {
                this.setVal('shortName', v)
              }}
              value={details.shortName}
            />

            {/* Address */}
            <Input
              label={t('NOTE')}
              testid='locality-note-input'
              size={Input.SIZES.FULL_WIDTH}
              type='text'
              placeholder={t('LOCALITY_ADDRESS_PLACEHOLDER')}
              onChange={(v) => {
                this.setVal('address', v)
              }}
              value={details.address}
            />

            {/* Employees */}
            {employeesArray.length ? (
              <>
                <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.VERTICAL}>
                  <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>
                    {t('ASSIGNED_EMPLOYEES')}
                  </Text>
                </Spacing>
                <Dropdown
                  label={null}
                  testid='locality-employee-select'
                  searchable
                  size={Dropdown.SIZES.FULL_WIDTH}
                  type={Dropdown.TYPES.VARIABLE}
                  style={Dropdown.STYLES.OUTLINED}
                  options={sortUtil.sortEmployees(employeesArray).map((employee) => ({
                    value: employee.id,
                    label: employee.name
                  }))}
                  value={selectedEmployees.map((employee) => ({
                    value: employee.id,
                    label: employee.name
                  }))}
                  placeholder={<div>{t('SELECT_EMPLOYEE')}</div>}
                  onChange={(selected) => {
                    this.setState((state) => ({
                      ...state,
                      assignedEmps: selected.map((option) => option.value)
                    }))
                  }}
                  sortedOptions={false}
                />
              </>
            ) : null}
            <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4} />
            <EmployeeList
              employees={sortUtil.sortEmployees(selectedEmployees)}
              onRemove={(employee) =>
                this.setState((state) => ({
                  ...state,
                  assignedEmps: this.state.assignedEmps.filter((em) => em !== employee.id)
                }))}
            />
          </Fragment>
        ]}
        footerContent={
          <>
            <Button
              label={newLocality ? t('CLOSE') : t('CLOSE')}
              onClick={() => this.props.close()}
              size={Button.SIZES.LARGE}
            />
            <Button
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
              color={newLocality ? Button.COLORS.GREEN : Button.COLORS.PRIMARY}
              label={t('SAVE')}
              testid='locality-editor-primary-button'
              disabled={!this.canSave()}
              onClickActiveWhenDisabled
              onClick={() => {
                // save or create the locality if possible
                if (this.canSave()) this.saveLocalityFn()

                // check for potential errors
                if (
                  !details.name ||
                  details.name === '' ||
                  !details.shortName ||
                  details.shortName === ''
                ) {
                  const newErrs = this.state.errors
                  if (
                    !this.state.errors.includes('nameMissing') &&
                    (!details.name || details.name === '')
                  ) {
                    newErrs.push('nameMissing')
                  }
                  if (
                    !this.state.errors.includes('shortNameMissing') &&
                    (!details.shortName || details.shortName === '')
                  ) {
                    newErrs.push('shortNameMissing')
                  }
                  this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                }
              }}
            />
            {details.id ? (
              <div
                style={{
                  position: 'absolute',
                  left: '1ex',
                  bottom: '1ex',
                  fontSize: '65%',
                  color: 'rgb(204, 204, 204)'
                }}
              >
                id: {details.id}
              </div>
            ) : null}
          </>
        }
      />
    )
  }
}

export default connect(EditLocality)
