import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import aT from '@app/const/action-type'

import { createWorkspace } from '@app/ac'

const mapStateToProps = ({
  loading,
  auth,
  organization
}) => {
  return {
    auth,
    isLoading: loading.includes(aT.SET_WORKSPACES),
    countries: organization.countries || []
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createWorkspace
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
