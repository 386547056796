import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'

const mapStateToProps = ({ workspaces, workspaceId, calendar, loading }) => {
  return {
    calendar,
    workspace: workspaces.find(ws => ws.id === workspaceId),
    isLoading: loading.includes('create-shift') || loading.includes('create-shift-template') || loading.includes('update-shift-template')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
