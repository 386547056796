import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const COLORS = Object.freeze({
  RED: 'danger',
  GREEN: 'success',
  ORANGE: 'warning',
  BLACK: 'default'
})

export const Status = ({
  color,
  status,
  info
}) => {
  return (
    <div className={'status ' + color}>
      <div className='statusText'>{status}</div>
      {info && <div className='infoText'>{info}</div>}
    </div>
  )
}

Status.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  status: PropTypes.string,
  info: PropTypes.string
}
Status.defaultProps = {
  color: COLORS.BLACK,
  status: null,
  info: null
}

Status.COLORS = COLORS
