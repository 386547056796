import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (state = initial.positions, action) => {
  switch (action.type) {
    case aT.SET_POSITIONS:
      return [...action.data]
    case aT.RESET_STORE:
      return initial.positions
    case aT.SWITCH_WORKSPACE:
      return initial.positions
    default:
      return state
  }
}
