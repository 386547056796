import store from '@app/store'

export const getCountryOptions = (countries) => countries.map((country) => ({
  label: country.code,
  value: country.id
}))

export const getAreaOptions = (countries) => countries.map((country) => {
  return country.areas.map((area) => ({
    groupTitle: country.code,
    label: area.name,
    value: area.id
  }))
}).flat()

export const getTransferGroupsOptions = (transferGroups) => {
  return transferGroups?.map((gp) => ({
    label: gp.name,
    value: gp.id
  }))
}

export const getWorkspaceOptions = (countries) => countries.map((country) => {
  return country.areas.map((area) => {
    return area.workspaces.map((workspace) => {
      return {
        groupTitle: area.name,
        label: workspace.name,
        value: workspace.id,

        enforceLocalities: workspace.enforceLocalities,
        localities: workspace.localities
      }
    })
  }).flat()
}).flat()

// saves an object 'obj' in local storage under key 'uniqueKey'
export const saveObjectInLocalStorage = (uniqueKey, obj, callbackFn = null) => {
  const { organizationId } = store.getState()
  let stored = window.localStorage.getItem('ds-org-persistent-state')
  if (stored) {
    stored = JSON.parse(stored)
  } else {
    stored = {}
  }
  stored[organizationId + '_' + uniqueKey] = obj
  window.localStorage.setItem('ds-org-persistent-state', JSON.stringify(stored))
}

// returns either the 'defaultObject' or its cached version from local storage, if it's there, under the 'uniqueKey'
export const getObjectFromLocalStorage = (uniqueKey, defaultObject) => {
  const { organizationId } = store.getState()
  const stored = window.localStorage.getItem('ds-org-persistent-state')
  if (stored && JSON.parse(stored)[organizationId + '_' + uniqueKey]) {
    return JSON.parse(stored)[organizationId + '_' + uniqueKey]
  } else {
    return defaultObject
  }
}

export const decideOptions = (showBy, countries) => {
  switch (showBy) {
    case 'countries':
      return getCountryOptions(countries)
    case 'areas':
      return getAreaOptions(countries)
    case 'workspaces':
      return getWorkspaceOptions(countries)
    default:
      return []
  }
}
