import moment from 'moment'
import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestShift (variables, token, options) {
  const query = `query ShiftQuery (
    $shift: ID!,
  ) {
    shift (
      id: $shift
      uncommitted: true
    ) {
      ${q.SHIFT_PARAMS_BASIC}
      offer ${q.OFFER}
      transfer {
        ${q.OFFER_PARAMS_TRANSFER}
      }
      ${(options?.withCandidates) ? q.SHIFT_PARAMS_CANDIDATES : ''}
      ${(options?.withHistory) ? q.SHIFT_PARAMS_HISTORY : ''}
    }
  }`

  variables.exchangePeriod = {
    start: moment().toISOString(),
    end: moment().add(1, 'months').toISOString()
  }
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    // return errUtil.apiError('shift', errors)
  }
  return data?.shift
}

export default requestShift
