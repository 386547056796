import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip } from '@ui'

import './style.scss'

export const HelpTooltip = ({
  text,
  link,
  linkText,
  children
}) => (
  <div className='help-tooltip'>
    <Tooltip
      anchor={
        <Icon
          ico={Icon.ICONS.question}
          size={Icon.SIZES.SMALL}
        />
      }
      position={Tooltip.POSITIONS.RIGHT}
      width='21rem'
      className='help-tooltip-content'
    >
      <div>{text || children}</div>
      {link && (
        <a href={link} target='_blank' rel='noopener noreferrer' className='link'>
          <span>{linkText}</span>
          <Icon ico={Icon.ICONS.arrowRight} size={Icon.SIZES.SMALL} />
        </a>
      )}
    </Tooltip>
  </div>
)

HelpTooltip.propTypes = {
  text: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.node
}
HelpTooltip.defaultProps = {
  text: null,
  link: null,
  linkText: null,
  children: null
}
