import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query RoleStatsMultiQuery (
  $id: ID!
  $referenceDate: Datetime
  $userIds: [ID!]
  $splitByContractTypes: Boolean
  $splitByPositions: Boolean
) {
  workspace (
    id: $id
  ) {
    members (
      userIds: $userIds
    ) {
      roles {
        userId
        stats (
          referenceDate: $referenceDate
          splitByContractTypes: $splitByContractTypes
          splitByPositions: $splitByPositions
        ) {
          monthPlannedMinutes
          monthPlannedMinutesByContract {
            contractType
            minutes
          }
          monthPlannedMinutesByPosition {
            positionId
            minutes
          }
          monthOverTimeMinutes
          monthContractMinutes
          monthContractMinutesByContract {
            contractType
            minutes
          }
          balancingPeriodPlannedMinutes
          balancingPeriodPlannedMinutesByContract {
            contractType
            minutes
          }
          balancingPeriodPlannedMinutesByPosition {
            positionId
            minutes
          }
          balancingPeriodContractMinutes
          balancingPeriodContractMinutesByContract {
            contractType
            minutes
          }
          yearVacationMinutes
          yearVacationRemainingMinutes
          yearVacationDays
          yearVacationRemainingDays
        }
      }
    }
  }
}`

async function requestRoleStatsMulti (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('role-stats', errors)
  }
  return data.workspace
}

export default requestRoleStatsMulti
