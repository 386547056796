/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import classNames from 'classnames/bind'
import {
  Icon,
  Loader,
  Radio,
  Checkbox,
  Tooltip,
  Spacing
} from '@ui'
import { miscUtil, sortUtil } from '@app/util'

import requestShiftCandidatesDummy from '@app/request/shift-candidates-dummy'
import requestShiftCandidatesTransfer from '@app/request/shift-candidates-transfer'
import requestShiftExchangesReal from '@app/request/shift-exchanges-real'
import requestShiftExchangesDummy from '@app/request/shift-exchanges-dummy'

import styles from './index.scss'
const cx = classNames.bind(styles)

const enforcedLocality = miscUtil.getEnforcedLocality()

// ====================================================================
// Loading candidates for a single shift (doesn't need to exist) asynchronously
// ====================================================================
export const loadCandidatesForShift = async (selectedSingleShift, opts) => {
  let resCandidates = []
  let resCandidatesTransfer = []
  if (!selectedSingleShift) {
    return {
      candidates: resCandidates,
      candidatesTransfer: resCandidatesTransfer
    }
  }

  if (opts.beforeLoadCallback) opts.beforeLoadCallback()

  // sanitize the selectedSingleShift for the following requests
  if (selectedSingleShift.localityId && !opts.workspace.localities.find(l => l.id === selectedSingleShift.localityId)) selectedSingleShift.localityId = undefined

  if (opts.offerType === 'drop') {
    const cRes = await requestShiftCandidatesDummy({
      filterByLocality: Array.isArray(opts.filterByLocality) ? undefined : opts.filterByLocality,
      filterByLocalities: Array.isArray(opts.filterByLocality) ? opts.filterByLocality : undefined,
      filterByPosition: Array.isArray(opts.filterByPosition) ? undefined : opts.filterByPosition,
      filterByPositions: Array.isArray(opts.filterByPosition) ? opts.filterByPosition : undefined,
      filterBySkill: opts.filterBySkill,
      filterByWarnings: opts.filterByWarnings,
      includeNonSuitable: true,
      workspaceId: opts.workspace && opts.workspace.id,
      data: {
        period: selectedSingleShift.period,
        user: selectedSingleShift.userId,
        position: selectedSingleShift.positionId,
        locality: selectedSingleShift.localityId,
        idealSkill: selectedSingleShift.idealSkill,
        pauses: selectedSingleShift.pauses,
        pausesFixed: selectedSingleShift.pausesFixed,
        overTime: selectedSingleShift.overTime,
        agenda: selectedSingleShift.agenda
      }
    }, opts.auth)
    resCandidates = cRes

    if (opts.loadTransfers) {
      const ctRes = await requestShiftCandidatesTransfer({
        workspaceId: opts.workspace && opts.workspace.id,
        data: {
          period: selectedSingleShift.period,
          user: selectedSingleShift.userId,
          position: selectedSingleShift.positionId,
          locality: selectedSingleShift.localityId,
          idealSkill: selectedSingleShift.idealSkill,
          pauses: selectedSingleShift.pauses,
          pausesFixed: selectedSingleShift.pausesFixed,
          overTime: selectedSingleShift.overTime,
          agenda: selectedSingleShift.agenda
        }
      }, opts.auth)
      resCandidatesTransfer = ctRes
    }
  }

  if (opts.offerType === 'swap') {
    const ceRes = opts.realShiftId
      ? await requestShiftExchangesReal({
        workspaceId: opts.workspace && opts.workspace.id,
        id: opts.realShiftId
      }, opts.auth)
      : await requestShiftExchangesDummy({
        workspaceId: opts.workspace && opts.workspace.id,
        data: {
          period: selectedSingleShift.period,
          user: selectedSingleShift.userId,
          position: selectedSingleShift.positionId,
          locality: selectedSingleShift.localityId,
          idealSkill: selectedSingleShift.idealSkill,
          pauses: selectedSingleShift.pauses,
          pausesFixed: selectedSingleShift.pausesFixed,
          overTime: selectedSingleShift.overTime,
          agenda: selectedSingleShift.agenda
        }
      }, opts.auth)
    resCandidates = ceRes
  }

  if (opts.afterLoadCallback) opts.afterLoadCallback()

  return {
    candidates: (resCandidates && !resCandidates.error) ? resCandidates : [],
    candidatesTransfer: (resCandidatesTransfer && !resCandidatesTransfer.error) ? resCandidatesTransfer : []
  }
}

// ====================================================================
// Rendering the List of candidates for unapproved or not yet existing Offer
// ====================================================================
export const getNewOfferCandidatesHTML = (opts) => {
  // required opts:
  //    employees, positions, optOfferType, optTransfers, selectedSingleShift, candidates, candidatesTransfer, extraIncludes, extraExcludes, selectedTransferWorkspaces,
  //    setExtraIncludes, setExtraExcludes, setSelectedTransferWorkspaces, workspace, localitiesAreProbablyRelevant, warningsEnabled

  // render one candidate or candidate exchange shift
  const renderOneCand = (c) => {
    const usr = opts?.employees[c.userId]

    // drop candidates rendering
    if (opts.optOfferType === 'drop') {
      const usersPositionsAtTimeOfShift = miscUtil.getPositionsOnRole(usr, moment(opts.selectedSingleShift.period.start))
      const posAssign = usersPositionsAtTimeOfShift.find(p => p.id === opts.selectedSingleShift.positionId)

      const isSuitable = opts.candidates.find(can => can.suitable && can.userId === c.userId)
      const isChecked = !opts.extraExcludes.includes(c.userId) && (isSuitable || opts.extraIncludes.includes(c.userId))

      return (
        <tr key={c.userId}>
          <td>
            <Checkbox
              checked={Boolean(isChecked)}
              onChange={() => {
                if (isChecked) {
                  if (isSuitable) opts.setExtraExcludes(opts.extraExcludes.concat([c.userId]))
                  opts.setExtraIncludes(opts.extraIncludes.filter(o => o !== c.userId))
                } else {
                  opts.setExtraExcludes(opts.extraExcludes.filter(o => o !== c.userId))
                  if (!isSuitable) opts.setExtraIncludes(opts.extraIncludes.concat([c.userId]))
                }
              }}
            >
              {c.userName}
            </Checkbox>

          </td>
          <td>{Math.round(c.score * 100)}</td>
          <td>
            {(posAssign && (!opts.selectedSingleShift.idealSkill || (posAssign.skill === opts.selectedSingleShift.idealSkill)))
              ? <Icon ico={Icon.ICONS.check} />
              : (
                <Tooltip
                  className={cx('ds-candidate-data-tt')}
                  position={Tooltip.POSITIONS.BOTTOM}
                  anchor={<Icon ico={Icon.ICONS.close2} />}
                >
                  <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_AUDIENCE_NO_POSITION')}</Spacing>
                </Tooltip>
              )}
          </td>
          {opts.localitiesAreProbablyRelevant && (
            <td>
              {(!opts.selectedSingleShift.localityId || (enforcedLocality ? !!usr : usr?.localities.includes(opts.selectedSingleShift.localityId)))
                ? <Icon ico={Icon.ICONS.check} />
                : (
                  <Tooltip
                    className={cx('ds-candidate-data-tt')}
                    position={Tooltip.POSITIONS.BOTTOM}
                    anchor={<Icon ico={Icon.ICONS.close2} />}
                  >
                    <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_AUDIENCE_NO_LOCALITY')}</Spacing>
                  </Tooltip>
                )}

            </td>
          )}
          {opts.warningsEnabled && (
            <td>
              {c.warnings.length === 0
                ? <Icon ico={Icon.ICONS.check} />
                : (
                  <Tooltip
                    className={cx('ds-candidate-data-tt')}
                    position={Tooltip.POSITIONS.BOTTOM}
                    anchor={<Icon ico={Icon.ICONS.close2} />}
                  >
                    <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{c.warnings.map(w => t('WARNING_' + w.name)).join(', ')}</Spacing>
                  </Tooltip>

                )}
            </td>
          )}
        </tr>
      )
    }

    // swap exchange shift candidates rendering
    if (opts.optOfferType === 'swap') {
      const swapIsChecked = opts.extraIncludes.includes(c.id)
      if (!c.period) return null
      return (
        <tr key={c.id}>
          <td className={cx('ds-mod-swap-cand-td')}>
            <Checkbox
              checked={swapIsChecked}
              onChange={() => {
                if (swapIsChecked) {
                  opts.setExtraIncludes(opts.extraIncludes.filter(o => o !== c.id))
                } else {
                  opts.setExtraIncludes(opts.extraIncludes.concat([c.id]))
                }
              }}
            >
              <div className={cx('ds-mod-swap-cand-1')}>{c.userName}</div>
              <div className={cx('ds-mod-swap-cand-2')}>{moment(c.period.start).format('D.M.YYYY HH:mm - ') + moment(c.period.end).format('HH:mm')}</div>
            </Checkbox>

          </td>
          {opts.localitiesAreProbablyRelevant && (
            <td>
              {(!opts.selectedSingleShift.localityId || (usr && usr.localities && usr.localities.includes(opts.selectedSingleShift.localityId)))
                ? <Icon ico={Icon.ICONS.check} />
                : (
                  <Tooltip
                    className={cx('ds-candidate-data-tt')}
                    position={Tooltip.POSITIONS.BOTTOM}
                    anchor={<Icon ico={Icon.ICONS.close2} />}
                  >
                    <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_AUDIENCE_NO_LOCALITY')}</Spacing>
                  </Tooltip>
                )}

            </td>
          )}
          {opts.warningsEnabled && (
            <td>
              {c.warnings.length === 0
                ? <Icon ico={Icon.ICONS.check} />
                : (
                  <Tooltip
                    className={cx('ds-candidate-data-tt')}
                    position={Tooltip.POSITIONS.BOTTOM}
                    anchor={<Icon ico={Icon.ICONS.close2} />}
                  >
                    <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{c.warnings.map(w => t('WARNING_' + w.name)).join(', ')}</Spacing>
                  </Tooltip>

                )}
            </td>
          )}
        </tr>
      )
    }
  }

  return (
    <Fragment>
      {/* suitable candidates from the API */}
      {opts.optOfferType === 'drop' && (
        <div className={cx('ds-mod-row')}>
          <table>
            <thead>
              <tr>
                <th className={cx('ds-mod-row-th-suitable')}>
                  <Checkbox
                    checked={opts.extraExcludes.length !== opts.candidates.filter(cn => cn.suitable === true).length}
                    onChange={() => {
                      if (opts.extraExcludes.length === 0) {
                        opts.setExtraExcludes(opts.candidates.filter(cn => cn.suitable === true).map(c => c.userId))
                      } else {
                        opts.setExtraExcludes([])
                      }
                    }}
                    disabled={opts.candidates.filter(cn => cn.suitable === true).length === 0}
                  >
                    {t('OFF_AUDIENCE_SUITABLE_X', { x: opts.candidates.filter(cn => cn.suitable === true).length })}
                  </Checkbox>
                </th>
                <th><Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico={Icon.ICONS.score} />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_SCORE')}</Spacing></Tooltip></th>
                <th><Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico={Icon.ICONS.positions} />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_POSITION')}</Spacing></Tooltip></th>
                {opts.localitiesAreProbablyRelevant && (<th><Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico='location' />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_LOCALITY')}</Spacing></Tooltip></th>)}
                {opts.warningsEnabled && (<th><Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico={Icon.ICONS.warning} />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_WARNINGS')}</Spacing></Tooltip></th>)}
              </tr>
            </thead>
            <tbody>
              {opts.candidates.filter(cn => cn.suitable === true).map(c => renderOneCand(c))}
            </tbody>
          </table>
        </div>
      )}

      {/* other people from my WS */}
      {opts.optOfferType === 'drop' && opts.candidates.filter(cn => cn.suitable === false).length
        ? (
          <div className={cx('ds-mod-row')}>
            <table>
              <thead>
                <tr>
                  <th className={cx('ds-mod-row-th-others')}>
                    <Checkbox
                      checked={opts.extraIncludes.length !== 0}
                      onChange={() => {
                        if (opts.extraIncludes.length === 0) {
                          opts.setExtraIncludes(opts.candidates.filter(cn => cn.suitable === false).map(c => c.userId))
                        } else {
                          opts.setExtraIncludes([])
                        }
                      }}
                      disabled={opts.candidates.filter(cn => cn.suitable === false).length === 0}
                    >
                      {t('OFF_AUDIENCE_OTHERS_X', { x: opts.candidates.filter(cn => cn.suitable === false).length })}
                    </Checkbox>
                  </th>
                  <th />
                  <th />
                  {opts.localitiesAreProbablyRelevant && (<th />)}
                  {opts.warningsEnabled && (<th />)}
                </tr>
              </thead>
              <tbody>
                {opts.candidates.filter(cn => cn.suitable === false).map(c => renderOneCand(c))}
              </tbody>
            </table>
          </div>
        )
        : null}

      {/* transfer / external candidates */}
      {opts.optOfferType === 'drop' && opts.candidatesTransfer.length
        ? (
          <div className={cx('ds-mod-row')}>
            <table>
              <thead>
                <tr>
                  <th className={cx('ds-mod-row-th-transfers')}>
                    <Checkbox
                      checked={Boolean(opts.selectedTransferWorkspaces?.length) || opts.selectedTransferWorkspaces === null}
                      onChange={() => {
                        if (!opts.selectedTransferWorkspaces?.length) {
                          opts.setSelectedTransferWorkspaces(opts.candidatesTransfer.map(ct => ct.workspaceId))
                        } else {
                          opts.setSelectedTransferWorkspaces([])
                        }
                      }}
                    >
                      {t('OFF_AUDIENCE_TRANSFER_X', { x: opts.candidatesTransfer.length })}
                    </Checkbox>
                  </th>
                </tr>
              </thead>
              <tbody>
                {opts.candidatesTransfer.map(c => {
                  const trWS = opts.workspace && opts.workspace.transferGroups && opts.workspace.transferGroups.length && opts.workspace.transferGroups.reduce((a, s) => { return a.concat(s.workspaces.filter(ws => !a.find(w => w.name === ws.name))) }, []).find(w => w.id === c.workspaceId)
                  const isChecked = (opts.selectedTransferWorkspaces === null) || (Array.isArray(opts.selectedTransferWorkspaces) && opts.selectedTransferWorkspaces.includes(trWS.id))
                  return (
                    <tr key={c.workspaceId}>
                      <td className={cx('ds-mod-row-td-transfer-ws')}>
                        <Checkbox
                          checked={Boolean(isChecked)}
                          onChange={() => {
                            if (isChecked) {
                              opts.setSelectedTransferWorkspaces(opts.selectedTransferWorkspaces.filter(wid => wid !== trWS.id))
                            } else {
                              opts.setSelectedTransferWorkspaces(opts.selectedTransferWorkspaces.concat([trWS.id]))
                            }
                          }}
                        >
                          {t('OFF_AUDIENCE_TRANSFER_WS_XY', { x: (trWS && trWS.name), y: c.candidatesCount })}
                        </Checkbox>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
        : null}

      {opts.optOfferType === 'swap' && (
        <div className={cx('ds-mod-row')}>
          <table>
            <thead>
              <tr>
                <th className={cx('ds-mod-row-th-swaps')}>
                  <Checkbox
                    checked={opts.extraIncludes.length !== 0}
                    onChange={() => {
                      if (opts.extraIncludes.length === 0) {
                        opts.setExtraIncludes(opts.candidates.map(c => c.id))
                      } else {
                        opts.setExtraIncludes([])
                      }
                    }}
                    disabled={opts.candidates.length === 0}
                  >
                    {t('OFF_AUDIENCE_SWAPS_X', { x: opts.candidates.length })}
                  </Checkbox>
                </th>
                {opts.localitiesAreProbablyRelevant && (<th><Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico='location' />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_LOCALITY')}</Spacing></Tooltip></th>)}
                {opts.warningsEnabled && (<th><Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico={Icon.ICONS.warning} />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_WARNINGS')}</Spacing></Tooltip></th>)}
              </tr>
            </thead>
            <tbody>
              {opts.candidates.map(c => renderOneCand(c))}
            </tbody>
          </table>
        </div>
      )}

    </Fragment>
  )
}

// ====================================================================
// Rendering the List of candidates for existing Offer
// ====================================================================
export const getExistingOfferCandidatesHTML = (offer, opts) => {
  // required opts:
  //   shift, localitiesAreProbablyRelevant, workspace, positions, employees
  // optional opts:
  //   candidateDetails, candidateDetailsTransfer, transferSlaves, loadingDetails, selectedCandidateId, handleSelectCandidate

  const detailsLoader = opts.loadingDetails
    ? <Loader size={Loader.SIZES.SMALL} />
    : null
  const warningsAreRelevantForThisShift = offer && offer.audience && offer.audience.options && offer.audience.options.filterByWarnings

  // render one candidate or candidate exchange shift
  const renderOneCand = (c) => {
    if (!c) return null
    const usr = opts?.employees[c.userId]

    const usersPositionsAtTimeOfShift = miscUtil.getPositionsOnRole(usr, moment(opts.shift.period.start))
    const posAssign = usersPositionsAtTimeOfShift.find(p => p.id === opts.shift.positionId)

    const details = opts.candidateDetails.find(cd => cd.userId === c.userId) || opts.candidateDetails.find(cd => cd.id === c)

    // drop candidates rendering
    if (offer.type === 'drop') {
      return (
        <tr key={c.userId}>
          <td>
            <Radio value={opts.selectedCandidateId === c.userId} onClick={() => { if (opts.handleSelectCandidate) opts.handleSelectCandidate(c.userId) }}>
              {c.userName}
            </Radio>
          </td>
          <td>{(details && !isNaN(details.score)) ? Math.round(details.score * 100) : null}</td>
          <td>{details ? <Icon ico={(posAssign && (!opts.shift.idealSkill || (posAssign.skill === opts.shift.idealSkill))) ? Icon.ICONS.check : Icon.ICONS.close2} /> : null}</td>
          {details && opts.localitiesAreProbablyRelevant && (
            <td>
              {(!opts.shift.localityId || (enforcedLocality ? !!usr : usr?.localities.includes(opts.shift.localityId)))
                ? <Icon ico={Icon.ICONS.check} />
                : (
                  <Tooltip
                    className={cx('ds-candidate-data-tt')}
                    position={Tooltip.POSITIONS.BOTTOM}
                    anchor={<Icon ico={Icon.ICONS.close2} />}
                  >
                    <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_AUDIENCE_NO_LOCALITY')}</Spacing>
                  </Tooltip>
                )}
            </td>)}

          {warningsAreRelevantForThisShift && (<td>{details ? <Icon ico={details.warnings.length === 0 ? Icon.ICONS.check : Icon.ICONS.close2} /> : null}</td>)}
        </tr>
      )
    }

    // swap exchange shift candidates rendering
    if (offer.type === 'swap') {
      return (
        <tr key={c}>
          <td className={cx('ds-mod-swap-cand-td')}>
            <Radio value={opts.selectedCandidateId === c} onClick={() => { if (opts.handleSelectCandidate) opts.handleSelectCandidate(c) }}>
              <div className={cx('ds-mod-swap-cand-1')}>{details && details.userName}</div>
              <div className={cx('ds-mod-swap-cand-2')}>{details && (moment(details?.period?.start).format('D.M.YYYY HH:mm - ') + moment(details?.period?.end).format('HH:mm'))}</div>
            </Radio>
          </td>
          {details && opts.localitiesAreProbablyRelevant && (<td><Icon ico={(!opts.shift.localityId || details.localityId === opts.shift.localityId) ? Icon.ICONS.check : Icon.ICONS.close2} /></td>)}
          <td>{details ? <Icon ico={(!details?.warnings || details.warnings.length === 0) ? Icon.ICONS.check : Icon.ICONS.close2} /> : null}</td>
        </tr>
      )
    }
  }

  // if (offer.type === 'drop') {
  let candsYes = []
  let candsNo = []
  let candsNoResponse = []
  let candsOthers = []

  const arr = offer.type === 'drop'
    ? offer.announceAction.targetUsers
    : offer.swaps.map(sw => sw.shiftId)

  arr.forEach(cId => {
    const ans = offer.type === 'drop'
      ? offer.announceAction.result.replies.find(rep => rep.userId === cId)
      : offer.announceAction.result.replies.find(rep => rep.userId === (offer.swaps.find(sw => sw.shiftId === cId) || {}).userId)

    const cand = offer.type === 'drop'
      ? opts.candidateDetails.find(cd => cd.userId === cId)
      : cId
    if (cand) {
      if (ans) {
        if (ans.reply === 'yes') {
          if (offer.type === 'drop') {
            // 'yes' answers for 'drop' offer
            candsYes.push(cand)
          } else {
            // 'yes' answers for 'swap' offer
            if (ans.data && ans.data.shift && ans.data.shift === cand) {
              candsYes.push(cand)
            } else {
              candsNo.push(cand)
            }
          }
        } else {
          // 'no' answers
          candsNo.push(cand)
        }
      } else {
        // without answer
        candsNoResponse.push(cand)
      }
    }
  })

  if (offer.type === 'drop') {
    candsOthers = opts.candidateDetails.filter(ee => !arr.includes(ee.userId))
  }

  // sort the arrays (of type === drop)
  if (offer.type === 'drop') {
    candsYes = candsYes.map(c => {
      const details = opts.candidateDetails && opts.candidateDetails.find(cd => (offer.type === 'drop' && cd.userId === c.id))
      return Object.assign({}, c, { score: details && details.score })
    })
    candsNo = candsNo.map(c => {
      const details = opts.candidateDetails && opts.candidateDetails.find(cd => (offer.type === 'drop' && cd.userId === c.id))
      return Object.assign({}, c, { score: details && details.score })
    })
    candsNoResponse = candsNoResponse.map(c => {
      const details = opts.candidateDetails && opts.candidateDetails.find(cd => (offer.type === 'drop' && cd.userId === c.id))
      return Object.assign({}, c, { score: details && details.score })
    })

    sortUtil.sortEmployees(candsYes).sort((a, b) => { return a.score > b.score ? -1 : 1 })
    sortUtil.sortEmployees(candsNo).sort((a, b) => { return a.score > b.score ? -1 : 1 })
    sortUtil.sortEmployees(candsNoResponse).sort((a, b) => { return a.score > b.score ? -1 : 1 })
  }

  const filteredTransferWorkspaces = (Array.isArray(opts.candidateDetailsTransfer) ? opts.candidateDetailsTransfer : []).filter(c => {
    if (Array.isArray(offer?.transfer?.slaves)) {
      if (!offer.transfer.slaves.some(s => s.workspaceId === c.workspaceId)) return false
    }
    return true
  })

  return (
    <Fragment>
      {/* replied YES */}
      <div className={cx('ds-mod-row', { 'has-zero': candsYes.length === 0 })}>
        <table>
          <thead>
            <tr>
              <th className={cx('ds-mod-row-th-replied-yes')}>
                {candsYes.length > 0 && detailsLoader}
                {t('OFF_DETAIL_AUDIENCE_YES_X', { x: candsYes.length })}
              </th>
              <th>{!opts.loadingDetails && (offer.type === 'drop') && (<Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico={Icon.ICONS.score} />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_SCORE')}</Spacing></Tooltip>)}</th>
              <th>{!opts.loadingDetails && (offer.type === 'drop') && (<Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico={Icon.ICONS.positions} />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_POSITION')}</Spacing></Tooltip>)}</th>
              {!opts.loadingDetails && opts.localitiesAreProbablyRelevant && (<th><Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico='location' />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_LOCALITY')}</Spacing></Tooltip></th>)}
              {warningsAreRelevantForThisShift && (<th>{!opts.loadingDetails && (<Tooltip className={cx('ds-candidate-data-tt')} position={Tooltip.POSITIONS.BOTTOM} anchor={<Icon ico={Icon.ICONS.warning} />}><Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_4}>{t('OFF_CAND_METRIC_WARNINGS')}</Spacing></Tooltip>)}</th>)}
            </tr>
          </thead>
          <tbody>
            {opts.loadingDetails
              ? null
              : candsYes.map(c => renderOneCand(c))}
          </tbody>
        </table>
      </div>

      {/* replied NO */}
      <div className={cx('ds-mod-row', { 'has-zero': candsNo.length === 0 })}>
        <table>
          <thead>
            <tr>
              <th className={cx('ds-mod-row-th-replied-no')}>
                {candsNo.length > 0 && detailsLoader}
                {t('OFF_DETAIL_AUDIENCE_NO_X', { x: candsNo.length })}
              </th>
              {offer.type === 'drop' && <th />}
              {offer.type === 'drop' && <th />}
              {opts.localitiesAreProbablyRelevant && (<th />)}
              {warningsAreRelevantForThisShift && (<th />)}
            </tr>
          </thead>
          <tbody>
            {opts.loadingDetails
              ? null
              : candsNo.map(c => renderOneCand(c))}
          </tbody>
        </table>
      </div>

      {/* no reply */}
      <div className={cx('ds-mod-row', { 'has-zero': candsNoResponse.length === 0 })}>
        <table>
          <thead>
            <tr>
              <th className={cx('ds-mod-row-th-no-reply')}>
                {candsNoResponse.length > 0 && detailsLoader}
                {t('OFF_DETAIL_AUDIENCE_NORESPONSE_X', { x: candsNoResponse.length })}
              </th>
              {offer.type === 'drop' && <th />}
              {offer.type === 'drop' && <th />}
              {opts.localitiesAreProbablyRelevant && (<th />)}
              {warningsAreRelevantForThisShift && (<th />)}
            </tr>
          </thead>
          <tbody>
            {opts.loadingDetails
              ? !candsOthers.length ? <tr><td><Loader size={Loader.SIZES.SMALL} /></td></tr> : null
              : candsNoResponse.map(c => renderOneCand(c))}
          </tbody>
        </table>
      </div>

      {/* others (employees that are not in candidates list) */}
      {(offer.type === 'drop' && candsOthers.length) ? (
        <div className={cx('ds-mod-row', { 'has-zero': candsOthers.length === 0 })}>
          <table>
            <thead>
              <tr>
                <th className={cx('ds-mod-row-th-no-reply')}>
                  {candsOthers.length > 0 && detailsLoader}
                  {t('OFF_AUDIENCE_OTHERS_X', { x: candsOthers.length })}
                </th>
                {offer.type === 'drop' && <th />}
                {offer.type === 'drop' && <th />}
                {opts.localitiesAreProbablyRelevant && (<th />)}
                {warningsAreRelevantForThisShift && (<th />)}
              </tr>
            </thead>
            <tbody>
              {opts.loadingDetails
                ? <tr><td><Loader size={Loader.SIZES.SMALL} /></td></tr>
                : candsOthers.map(c => renderOneCand(c))}
            </tbody>
          </table>
        </div>

      ) : null}

      {/* transfer / external candidates */}
      {offer.type === 'drop' && filteredTransferWorkspaces.length && !opts.loadingDetails
        ? (
          <div className={cx('ds-mod-row')}>
            <table>
              <thead>
                <tr>
                  <th className={cx('ds-mod-row-th-transfers')}>
                    {t('OFF_AUDIENCE_TRANSFER_X', { x: filteredTransferWorkspaces.length })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTransferWorkspaces.map(c => {
                  const trWS = opts.workspace && opts.workspace.transferGroups && opts.workspace.transferGroups.length && opts.workspace.transferGroups.reduce((a, s) => { return a.concat(s.workspaces.filter(ws => !a.find(w => w.name === ws.name))) }, []).find(w => w.id === c.workspaceId)
                  return (
                    <tr key={c.workspaceId}>
                      <td className={cx('ds-mod-row-td-transfer-ws-show')}>{t('OFF_AUDIENCE_TRANSFER_WS_XY', { x: (trWS && trWS.name), y: c.candidatesCount })}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
        : null}
    </Fragment>
  )
  // }
}

// ====================================================================
// Dropdown options with offer types
// ====================================================================
export const getOfferTypeOptions = (selectedSingleShift, selectedShifts) => {
  return selectedSingleShift // action types that are allowed with exactly one shift selected
    ? [
      {
        value: 'drop',
        label: (
          <div className={cx('ds-mod-opt', 'ds-mod-opt-drop')}>
            <Icon ico={selectedSingleShift.userId ? 'assign' : 'offer'} />
            <div>
              <div className={cx('ds-opt-l1')}>{t(selectedSingleShift.userId ? 'OFF_TYPE_DROP_ASSIGNED' : 'OFF_TYPE_DROP_UNASSIGNED')}</div>
              <div className={cx('ds-opt-l2')}>{t(selectedSingleShift.userId ? 'OFF_TYPE_DROP_ASSIGNED_SUBTITLE' : 'OFF_TYPE_DROP_UNASSIGNED_SUBTITLE')}</div>
            </div>
          </div>),
        buttonLabel: t(selectedSingleShift.userId ? 'OFF_TYPE_DROP_ASSIGNED' : 'OFF_TYPE_DROP_UNASSIGNED')
      },
      (selectedSingleShift.userId ? {
        value: 'swap',
        label: (
          <div className={cx('ds-mod-opt', 'ds-mod-opt-swap')}>
            <Icon ico={Icon.ICONS.switch} />
            <div>
              <div className={cx('ds-opt-l1')}>{t('OFF_TYPE_SWAP')}</div>
              <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_SWAP_SUBTITLE')}</div>
            </div>
          </div>),
        buttonLabel: t('OFF_TYPE_SWAP')
      } : null),
      (selectedSingleShift.userId ? {
        value: 'unassign',
        label: (
          <div className={cx('ds-mod-opt', 'ds-mod-opt-unassign')}>
            <Icon ico={Icon.ICONS.unoccupied} />
            <div>
              <div className={cx('ds-opt-l1')}>{t('UNASSIGNED')}</div>
              <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_FREE_SUBTITLE')}</div>
            </div>
          </div>),
        buttonLabel: t('UNASSIGNED')
      } : null),
      {
        value: 'delete',
        label: (
          <div className={cx('ds-mod-opt', 'ds-mod-opt-delete')}>
            <Icon ico={Icon.ICONS.delete} />
            <div>
              <div className={cx('ds-opt-l1')}>{t('DELETE_SHIFT')}</div>
              <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_DELETE_SUBTITLE')}</div>
            </div>
          </div>),
        buttonLabel: t('DELETE_SHIFT')
      }
    ].filter(Boolean)
    : selectedShifts.length > 1 // action types allowed when multiple shifts are selected
      ? [
        {
          value: 'drop',
          label: (
            <div className={cx('ds-mod-opt', 'ds-mod-opt-drop')}>
              <Icon ico={selectedShifts.find(s => s.userId) ? 'assign' : 'offer'} />
              <div>
                <div className={cx('ds-opt-l1')}>{t(selectedShifts.find(s => s.userId) ? 'OFF_TYPE_DROP_ASSIGNED_MULTI' : 'OFF_TYPE_DROP_MULTI')}</div>
                <div className={cx('ds-opt-l2')}>{t(selectedShifts.find(s => s.userId) ? 'OFF_TYPE_DROP_ASSIGNED_MULTI_SUBTITLE' : 'OFF_TYPE_DROP_MULTI_SUBTITLE')}</div>
              </div>
            </div>),
          buttonLabel: t('OFF_TYPE_DROP_MULTI')
        },
        (selectedShifts.find(s => s.userId) ? {
          value: 'swap',
          label: (
            <div className={cx('ds-mod-opt', 'ds-mod-opt-swap')}>
              <Icon ico={Icon.ICONS.switch} />
              <div>
                <div className={cx('ds-opt-l1')}>{t('OFF_TYPE_SWAP_MULTI')}</div>
                <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_SWAP_MULTI_SUBTITLE')}</div>
              </div>
            </div>),
          buttonLabel: t('OFF_TYPE_SWAP_MULTI')
        } : null),
        {
          value: 'unassign',
          label: (
            <div className={cx('ds-mod-opt', 'ds-mod-opt-unassign')}>
              <Icon ico={Icon.ICONS.unoccupied} />
              <div>
                <div className={cx('ds-opt-l1')}>{t('OFF_TYPE_FREE_MULTI')}</div>
                <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_FREE_MULTI_SUBTITLE')}</div>
              </div>
            </div>),
          buttonLabel: t('OFF_TYPE_FREE_MULTI')
        },
        {
          value: 'delete',
          label: (
            <div className={cx('ds-mod-opt', 'ds-mod-opt-delete')}>
              <Icon ico={Icon.ICONS.delete} />
              <div>
                <div className={cx('ds-opt-l1')}>{t('OFF_TYPE_DELETE_MULTI')}</div>
                <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_DELETE_MULTI_SUBTITLE')}</div>
              </div>
            </div>),
          buttonLabel: t('OFF_TYPE_DELETE_MULTI')
        }
      ].filter(Boolean)
      : []
}
