import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestTimeOffsWorkspace (variables, token) {
  const query = `query TimeOffsWorkspaceQuery (
    $workspace: ID!,
    $period: PeriodInput!
    $categoryIds: [String!],
    $users: [ID!]
  ) {
    workspace (
      id: $workspace
    ) {
      timeOffs (
        period: $period
        categoryIds: $categoryIds
        users: $users
      ) ${q.TIME_OFF}
    }
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('timeoffs', errors)
  }
  return data.workspace.timeOffs
}

export default requestTimeOffsWorkspace
