"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSortedBy = exports.SORT_BY = void 0;
exports.SORT_BY = Object.freeze({
    NAME: 'SORT_BY_NAME',
    SHIFT_START: 'SORT_BY_SHIFT_START',
    POSITION: 'SORT_BY_POSITION',
    LOCALITY: 'SORT_BY_LOCALITY',
    CONTRACT: 'SORT_BY_CONTRACT',
    DAY_PART: 'SORT_BY_DAY_PART',
    CUSTOM: 'SORT_BY_CUSTOM',
});
const getSortedBy = () => window.localStorage.getItem('ds-calendar-sort');
exports.getSortedBy = getSortedBy;
