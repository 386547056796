import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

async function requestCalendarExportToken (variables, token) {
  const query = `query CalendarExportTokenQuery (
    $workspace: ID
    $user: ID
    $format: CalendarExportFormat!
    $period: PeriodInput
    $type: String
  ) {
    calendarExportToken (options: {
      format: $format
      workspace: $workspace
      user: $user
      period: $period
      type: $type
    }) {
      ${(variables.format && (variables.format === 'pdf' || variables.format === 'xlsx')) ? 'url' : 'shortUrl'}
    }
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('calendar-export-token', errors)
  }
  return data.calendarExportToken
}

export default requestCalendarExportToken
