import React from 'react'
import moment from 'moment'
import { t } from 'i18next'

import {
  Icon,
  Spacing,
  Tooltip
} from '@ui'

export const ExtraHeaderButtons = ({
  newAvailabilityOrTimeOff,
  disabledEditation,
  processRequest,
  day,
  isEmployeeCalendar,
  details,
  setCalendarMultiSelect,
  setModal,
  deleteUnavailability
}) => {
  const onCopyClick = () => {
    if (isEmployeeCalendar) {
      setModal('copy-unavailability', { details: details })
    } else {
      setCalendarMultiSelect({
        action: 'copy',
        isSelectingTargets: true,
        sourceEvents: [{ ...details, day: moment(details.period.start).format('YYYY-MM-DD') }],
        targets: []
      })
      setModal(null)
    }
  }

  if (newAvailabilityOrTimeOff || disabledEditation) return null
  return (
    <>
      {day !== 'day-template' && !processRequest && (
        <>
          <Tooltip
            key='btn-copy'
            position={Tooltip.POSITIONS.BOTTOM}
            anchor={
              <Icon
                size={Icon.SIZES.SMALL}
                ico={Icon.ICONS.duplicate}
                color={Icon.COLORS.PRIMARY}
                onClick={onCopyClick}
              />
            }
          >
            <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_8}>
              {t('COPY_UNAV' + (details?.available ? '_AVAIL' : ''))}
            </Spacing>
          </Tooltip>
          <Spacing key='space' type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />
        </>
      )}
      {!processRequest && (
        <Tooltip
          key='btn-delete'
          position={Tooltip.POSITIONS.BOTTOM}
          anchor={
            <Icon
              testid='delete-timeoff-button'
              size={Icon.SIZES.SMALL}
              color={Icon.COLORS.RED}
              ico={Icon.ICONS.delete}
              onClick={deleteUnavailability}
            />
          }
        >
          <Spacing
            type={Spacing.TYPES.BOTH}
            size={Spacing.SIZES.SIZE_8}
          >
            {t('DELETE_UNAV' + (details?.available ? '_AVAIL' : ''))}
          </Spacing>
        </Tooltip>
      )}
    </>
  )
}

ExtraHeaderButtons.propTypes = {}
ExtraHeaderButtons.defaultProps = {}
