import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { setSidebar, setModal } from '@app/ac'
import sendInvites from '@app/ac/send-invites'
import resetErrors from '@app/ac/reset-errors'

const mapStateToProps = ({ employees, positions, loading, workspaces, workspaceId, errors }) => {
  return {
    employees,
    positions,
    isLoading: loading.includes('send-invites') || loading.includes('load-positions'),
    workspace: workspaces.find((ws) => ws.id === workspaceId),
    errors: errors.filter(err => ['email-check', 'user-create', 'role-create', 'role-update'].includes(err.request))
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    sendInvites,
    resetErrors,
    setSidebar
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
