import { notification } from '@app/util'

import addError from '../action/add-error'
import setAuth from '../action/set-auth'
import eT from '../const/error-type'

export default (error) => {
  return async (dispatch) => {
    if (error.type === eT.API_ERR_INVALID_TOKEN) {
      dispatch(setAuth(null))
    }
    dispatch(addError(error))
    // display the error for a few seconds in top-right corner notification,
    // if it has some text message
    notification.error(error)
  }
}
