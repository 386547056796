import { fetchRequest, errorUtil } from '@app/util'
import q from '../query'

const query = `query WorkspaceBonusesQuery ($workspaceId: ID!, $period: PeriodInput!, $filter: OrganizationFilter!) {
  workspace(id: $workspaceId) {
    organization {
      bonuses(period: $period, filter: $filter) ${q.BONUS_ITEM}
    }
  }
}`

async function requestWorkspaceBonuses (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('workspace-bonuses', errors)
  }
  return data.workspace.organization.bonuses
}

export default requestWorkspaceBonuses
