import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query ChangedShiftsCountQuery (
  $workspaceId: ID!
  $locality: ID
) {
  workspace (
    id: $workspaceId
  ) {
    changedShiftsCount (locality: $locality)
  }
}`

async function requestChangedShiftsCount (vars, token) {
  const response = await fetchRequest({
    variables: vars,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('changed-shifts-count', errors)
  }
  return data.workspace
}

export default requestChangedShiftsCount
