import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation NewMfaFactorMutation($factorName: String!, $type: MFAMethods!, $param: String) {
    newMfaFactor(factorName: $factorName, type: $type, param: $param) {
        sid
        type
        binding {
            secret
            uri
        }
    }
}
`

async function requestNewMfaFactor (variables) {
  const response = await fetchRequest({ query, variables })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('new-mfa-factor', errors)
  }
  return data.newMfaFactor
}

export default requestNewMfaFactor
