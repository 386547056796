import React from 'react'
import connect from './connect'

import { PositionEditorSidebar } from './position-editor'
import { NotificationsSidebar } from './notifications'
import PlanningSidebar from './planning'

export const SidebarContainer = connect(({ sidebar, onClose }) => (
  <>
    {sidebar &&
      React.cloneElement(
        (() => {
          switch (sidebar.type) {
            case 'position-editor':
              return <PositionEditorSidebar />
            case 'notification':
              return <NotificationsSidebar />
            case 'planning':
              return <PlanningSidebar />
            default:
              return <> </>
          }
        })(),
        { onClose, sidebarData: sidebar.data }
      )}
  </>
))
