import { fetchRequest, errorUtil } from '@app/util'

const positionsQuery = `
positions {
  positionId
  productionDemand
  actualProduction
  warning
}`

async function requestWorkspaceActualProduction (
  variables,
  token,
  { withPositions = false } = {}
) {
  const query = `query ActualProductionQuery ($workspaceId: ID!, $period: PeriodInput!) {
    workspace(id: $workspaceId) {
      actualProduction(period: $period) {
        date
        ${withPositions ? positionsQuery : ''}
        totalProduction {
          productionDemand
          actualProduction
          warning
        }
      }
    }
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.workspace.actualProduction
}

export default requestWorkspaceActualProduction
