import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation OffersCancel (
    $workspace: ID!
    $offers: [ID!]!
  ) {
    offerCancelMulti (
      workspace: $workspace
      offers: $offers
    ){
      id
    }
  }`

async function requestOffersCancel (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('offers-cancel', errors)
  }
  return data.offerCancelMulti
}

export default requestOffersCancel
