import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationTransferGroups($organizationId: ID!) {
  organization(id: $organizationId) {
    transferGroups {
      id
      name
      workspaces {
        workspaceId
      }
    }
  }
}`

async function requestOrganizationTransfersGroups (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrganizationTransfersGroups
