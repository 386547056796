import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import setCalendar from '@app/ac/set-calendar'
import copyShift from '@app/ac/copy-shift'
import setAvailabilityOrTimeOff from '@app/ac/set-availability-or-time-off'
import addShiftAttributes from '@app/ac/add-shift-attributes'
import setCalendarMultiSelect from '@app/ac/set-calendar-multi-select'
import setCalendarFilters from '@app/ac/set-calendar-filters'
import setCalendarSearch from '@app/ac/set-calendar-search'
import loadRoleStatsMulti from '@app/ac/load-role-stats-multi'
import loadShifts from '@app/ac/load-shifts'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import deleteLiveShift from '@app/ac/delete-live-shift'
import updateLiveShift from '@app/ac/update-live-shift'
import createPlan from '@app/ac/create-plan'

import {
  setEnforcedLocality,
  setModal,
  setSidebar,
  workspaceSwitch
} from '@app/ac'

const mapStateToProps = ({ me, calendar, calendarMultiSelect, calendarFilters, shifts, employees, templates, positions, offers, workspaces, workspaceId, auth, loading }) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    me,
    userId: me?.id,
    shifts,
    templates,
    calendar: calendar || {},
    calendarMultiSelect,
    calendarFilters,
    offers,
    employees,
    workspace: ws,
    workspaceId,
    auth,
    positions,
    isLoadingRelevantData: [
      // map the relevant current loading keys to localazy keys that will be displayed above the calendar as a list of data types being currently loaded
      (loading.includes('load-employees') || loading.includes('load-external-employees')) ? 'EMPLOYEES' : null,
      loading.includes('load-shifts') ? 'EMPLOYEE_PROFILE_CARD_SHIFTS' : null,
      loading.includes('load-availabilities-and-time-offs') ? 'TIMEOFFS' : null,
      loading.includes('load-role-stats-multi') ? 'STATISTICS' : null,
      loading.includes('load-offers') ? 'OFFERS' : null,
      loading.includes('load-workspace-events') ? 'NOTES_DAY' : null,
      loading.includes('load-holidays') ? 'ORG_SP_WORKSPACES_HOLIDAYS' : null
    ].filter(Boolean)
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setCalendar,
    setModal,
    setSidebar,
    setCalendarMultiSelect,
    setCalendarFilters,
    setCalendarSearch,
    loadRoleStatsMulti,
    loadShifts,
    loadWorkspaceDetail,
    addShiftAttributes,
    copyShift,
    setAvailabilityOrTimeOff,
    deleteLiveShift,
    updateLiveShift,
    createPlan,
    setEnforcedLocality,
    workspaceSwitch
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
