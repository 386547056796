"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUnreadMessages = void 0;
const set_unread_count_1 = require("@app/action/set-unread-count");
const create_ac_1 = require("./util/create-ac");
exports.setUnreadMessages = (0, create_ac_1.createAC)((dispatch, unreadCount) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, set_unread_count_1.setUnreadCountAction)(unreadCount));
}));
