import './index.scss'
import React from 'react'
import connect from './connect'
import {
  Tooltip,
  Button,
  Icon
} from '@ui'
import { t } from 'i18next'

const ControlItem = (props) => {
  const { label, boldLabel, mouseLabel, keys, mouseButton } = props
  return (
    <div className='ds-help-controls-row'>
      <div className='ds-help-controls-label-div'>
        <span className='ds-help-controls-row-label-bold'>{boldLabel}</span>
        <span className='ds-help-controls-row-label'>{' '}{label}</span>
      </div>
      {keys ? <div className='ds-help-key-group'>
        {keys.map((k, id) => <div key={`key-${id}`} className='ds-help-key-div'>
          <span>{k}</span>
          {/* eslint-disable-next-line */}
        </div>)}
        {/* eslint-disable-next-line */}
      </div> : null}
      {mouseButton ? <div className='ds-help-mouse-div'>
        <span className='ds-help-mouse-label'>{mouseLabel}</span>
        <div className='ds-help-mouse-object'>
          <div className={mouseButton === 'right' ? 'ds-help-mouse-button-right' : 'ds-help-mouse-button-left'} />
        </div>
        {/* eslint-disable-next-line */}
      </div> : null}
    </div>
  )
}

const HelpNavigationItem = (props) => {
  const { anchor, className } = props
  return (
    <Tooltip
      clickable
      className={(className || '') + ' ds-schedule-help-tt'}
      anchor={anchor}
      position={Tooltip.POSITIONS.RIGHT_BOTTOM}
    >
      <div className='ds-help-controls-section'>
        <span className='ds-help-controls-label'>{t('SCHEDULE_HELP_CONTROLS_LABEL')}</span>
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_CHOOSE')} label={t('SCHEDULE_HELP_CONTROLS_SHIFT')} mouseLabel='1x' mouseButton='left' />
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_OPEN')} label={t('SCHEDULE_HELP_CONTROLS_SHIFT')} mouseLabel='2x' mouseButton='left' />
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_MOVE')} label={t('SCHEDULE_HELP_CONTROLS_SHIFT')} mouseLabel={t('SCHEDULE_HELP_CONTROLS_CLICK_HOLD')} mouseButton='left' />
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_QUICK_MENU')} label={t('SCHEDULE_HELP_CONTROLS_OF_SHIFT')} mouseLabel='1x' mouseButton='right' />
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_COPY')} label={t('SCHEDULE_HELP_CONTROLS_SELECTED_SHIFT')} keys={['Ctrl', 'C']} />
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_PASTE')} label={t('SCHEDULE_HELP_CONTROLS_SELECTED_SHIFT')} keys={['Ctrl', 'V']} />
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_CONFIRM')} label={t('SCHEDULE_HELP_CONTROLS_EVENT')} keys={['↵ Enter']} />
        <ControlItem boldLabel={t('SCHEDULE_HELP_CONTROLS_CLOSE')} label={t('SCHEDULE_HELP_CONTROLS_WINDOW') + ' / ' + t('SCHEDULE_HELP_CONTROLS_CANCEL') + ' ' + t('SCHEDULE_HELP_CONTROLS_ACTION')} keys={['Esc']} />
      </div>
      <div className='ds-help-redirect-section'>
        <div className='ds-help-section-label-row'>
          <span>{t('SCHEDULE_HELP_CENTER_LABEL')}</span>
          <Button
            label={t('SCHEDULE_HELP_CENTER_BTN')}
            style={Button.STYLES.UNDERLINED}
            color={Button.COLORS.WHITE}
            ico={Icon.ICONS.arrowRight}
            onClick={(e) => {
              var win = window.open(t('SCHEDULE_HELP_CENTER_URL'), '_blank')
              win.focus()
              e.stopPropagation()
            }}
          />
        </div>
        <p>{t('SCHEDULE_HELP_CENTER_TXT')}</p>
      </div>
      <div className='ds-help-support-section'>
        <div className='ds-help-section-label-row'>
          <span>{t('SCHEDULE_HELP_SUPPORT_LABEL')}</span>
          <Button
            label={t('SCHEDULE_HELP_SUPPORT_BTN')}
            ico={Icon.ICONS.arrowRight}
            onClick={(e) => {
              window.Intercom('show')
            }}
          />
        </div>
        <p>{t('SCHEDULE_HELP_SUPPORT_TXT')}</p>
      </div>
    </Tooltip>
  )
}

export default connect(HelpNavigationItem)
