/* global localStorage */
// todo polyfill localStorage
import miscUtil from '@app/util/misc'

function get (key) {
  const value = localStorage[key]
  if (!value) return null
  return JSON.parse(value)
}

function set (key, value) {
  localStorage[key] = miscUtil.safeStringify(value)
}

export default {
  get,
  set
}
