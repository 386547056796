import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation CountryCreateMutation(
  $organization: ID!
  $code: String!
  $currency: String!
) {
  countryCreate (
    organization: $organization
    code: $code
    currency: $currency
  ) {
    id
    code
    currency
    areas {
      id
    }
  }
}`

async function requestCountryCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data
}

export default requestCountryCreate
