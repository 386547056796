import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.calendarFilters, action) => {
  switch (action.type) {
    case aT.SET_CALENDAR_FILTERS:
      return action.data
    default:
    {
      // remove the old, deprecated calendarFilter types (userId='offers' and oserId='unassigned')
      return (state || []).map(s => {
        if (s.userId === 'offers') return null
        if (s.userId === 'unassigned') return null
        return s
      }).filter(Boolean)
    }
  }
}
