import React, { useEffect, useState } from 'react'
import { GhostItem } from '@ui'

export const LoadingTable = ({ withHead }) => {
  const [delayed, setDelay] = useState(false)
  useEffect(() => {
    const t = setTimeout(() => setDelay(true), 200)
    return () => { clearTimeout(t) }
  }, [])
  return delayed && (
    <div className='table-loading'>
      {withHead && <div className='loading-header' />}
      {[1, 2, 3, 4, 5, 6, 7].map((row) => (
        <div className='ghost-row' key={row}>
          <GhostItem big />
          {[1, 2, 3, 4, 5].map((item) => <GhostItem key={item} />)}
        </div>
      ))}
    </div>
  )
}
