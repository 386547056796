import setSidebar from '../action/set-sidebar'

export default (type, data = {}) => {
  return async (dispatch) => {
    if (!type) {
      return dispatch(setSidebar(null))
    }
    dispatch(setSidebar({
      type,
      data
    }))
  }
}
