import React from 'react'
import { Dropdown } from '@ui'
import { t } from 'i18next'

class SkillSelect extends React.Component {
  render () {
    const options = [
      { label: t('SKILL_LETTER_1') + ': ' + t('SKILL_NAME_1'), value: 1 },
      { label: t('SKILL_LETTER_2') + ': ' + t('SKILL_NAME_2'), value: 2 },
      { label: t('SKILL_LETTER_3') + ': ' + t('SKILL_NAME_3'), value: 3 },
      { label: t('SKILL_LETTER_4') + ': ' + t('SKILL_NAME_4'), value: 4 },
      { label: t('SKILL_LETTER_5') + ': ' + t('SKILL_NAME_5'), value: 5 }
    ]
    if (this.props.includeAny) options.unshift({ label: t('SKILL_ANY'), value: 'any' })

    const selectedOpt = options.find(o => (o.value || 'any') === this.props.value)

    return (
      <Dropdown
        label={this.props.label}
        sortedOptions={false}
        singleSelect
        type={Dropdown.TYPES.VARIABLE}
        style={Dropdown.STYLES.LIGHT}
        options={options}
        value={options.find(o => o.value === this.props.value)}
        placeholder={selectedOpt?.label}
        hasError={this.props.hasError}
        onChange={(opt) => {
          if (opt.value === 'any') {
            this.props.onChange(null)
          } else {
            this.props.onChange(opt.value)
          }
        }}
        disabled={this.props.disabled}
      />
    )
  }
}

SkillSelect.defaultProps = {
  includeAny: true,
  disabled: false,
  value: null,
  label: null,
  hasError: false,
  className: '',
  onChange: () => {},
  onClick: () => {}
}

export default SkillSelect
