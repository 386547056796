import React from 'react'

export const Row = ({
  style,
  className,
  children,
  rowOnMouseLeave,
  rowOnMouseEnter,
  ...rest
}) => (
  <div
    style={style}
    onMouseLeave={rowOnMouseLeave || undefined}
    onMouseEnter={rowOnMouseEnter || undefined}
    className={className}
    {...rest}
  >
    {children}
  </div>
)
