import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'

const mapStateToProps = ({ shifts }) => {
  return {
    shifts
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
