import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { FieldProvider } from '../../field-provider'

const propTypes = {
  children: PropTypes.node,
  label: PropTypes.string
}

const defaultProps = {
  children: null,
  label: ''
}

const FormFieldContent = ({ children, label, ...rest }) => cloneElement(children, {
  ...rest
})

FormFieldContent.propTypes = propTypes
FormFieldContent.defaultProps = defaultProps

export const FormField = ({ children, label, ...rest }) => (
  <FieldProvider {...rest}>
    <FormFieldContent label={label}>{children}</FormFieldContent>
  </FieldProvider>
)

FormField.propTypes = propTypes
FormField.defaultProps = defaultProps
