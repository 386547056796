import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (
  state = initial.errors,
  action
) => {
  switch (action.type) {
    case aT.ADD_ERROR:
      return [...state, action.data]
    case aT.RESET_ERRORS:
      return initial.errors
    case aT.RESET_STORE:
      return initial.errors
    case aT.SWITCH_WORKSPACE:
      return initial.errors
    default:
      return state
  }
}
