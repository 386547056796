import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationProductionSet(
  $organization: ID!,
  $production: [WorkspaceProductionInput!]!
) {
    organizationProductionSet(
      organization: $organization,
      production: $production
    ) {
      amount
      workspaceId
      date
    }
}`

async function requestOrganizationProductionUpdate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationProductionSet
}

export default requestOrganizationProductionUpdate
