import isLoading from './is-loading'
import requestWarnings from '../request/warnings'
import addError from './add-error'
import setShifts from '../action/set-shifts'
import setTimeOffs from '../action/set-time-offs'
import store from '../store'

export default (warningsPeriod, user) => {
  return async (dispatch) => {
    const { auth, workspaces, workspaceId, employees } = store.getState()
    let { loading, shifts, timeOffs } = store.getState()
    if (!workspaceId || !warningsPeriod) return

    // don't load anything if 'laborlaw' plugin isn't enabled
    const ws = workspaces?.find(w => w.id === workspaceId)
    if (!ws || !ws.plugins || !ws?.plugins?.find(p => p.plugin === 'laborlaw' && p.enabled)) return

    // if 'user' is external, don't do anything. we don't compute warnings for external
    // users, because they don't have a contract with us
    if (user && (!employees[user] || employees[user].external)) return

    // if load-event-warnings is already running, try waiting for it to finish first
    if (loading.includes('load-event-warnings')) {
      let w = 0
      const maxW = 60
      while (loading.includes('load-event-warnings') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      // if the wait was successful, just end this method
      if (w < maxW) {
        dispatch(isLoading('load-event-warnings', true))
        return
      }
    }

    dispatch(isLoading('load-event-warnings'))

    const isLoadingShiftsOrTimeOffs = () => {
      return (loading.includes('load-shifts') || loading.includes('load-availabilities-and-time-offs'))
    }

    // if we have no shifts & 'load-shifts' is running, try waiting for it to finish first
    shifts = store.getState().shifts
    if ((!shifts || !shifts.length) && isLoadingShiftsOrTimeOffs()) {
      let w1 = 0
      const maxW1 = 10
      while (isLoadingShiftsOrTimeOffs() && w1 < maxW1) {
        w1++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      shifts = store.getState().shifts
    }

    // if we have no timeOffs & 'load-availabilities-and-time-offs' is running, try waiting for it to finish first
    timeOffs = store.getState().timeOffs
    if ((!timeOffs || !timeOffs.length) && isLoadingShiftsOrTimeOffs()) {
      let w2 = 0
      const maxW2 = 10
      while (isLoadingShiftsOrTimeOffs() && w2 < maxW2) {
        w2++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      timeOffs = store.getState().timeOffs
    }

    // if there still are no shifts and no timeOffs, just end this - no reason to request warnings if there are no events
    if ((!timeOffs || !timeOffs.length) && (!shifts || !shifts.length)) {
      dispatch(isLoading('load-event-warnings', true))
      return
    }

    // get the warnings from BE
    const resultWarnings = []
    const reqRes = await requestWarnings({ id: workspaceId, period: warningsPeriod, users: user ? [user] : undefined }, auth)
    if (reqRes.error) {
      dispatch(addError(reqRes.error))
    } else {
      resultWarnings.push(...reqRes)
    }

    // update the shifts in store
    await dispatch(setShifts(store.getState().shifts.map(s => {
      const resWarns = (resultWarnings && !resultWarnings.error) ? resultWarnings?.find(w => w.eventId === s.id) : null
      if (resWarns) {
        return Object.assign({}, s, { warnings: resWarns.warnings })
      } else {
        return s
      }
    })))

    // update the timeOffs in store
    await dispatch(setTimeOffs(store.getState().timeOffs.map(s => {
      const resWarns = (resultWarnings && !resultWarnings.error) ? resultWarnings?.find(w => w.eventId === s.id) : null
      if (resWarns) {
        return Object.assign({}, s, { warnings: resWarns.warnings })
      } else {
        return s
      }
    })))

    dispatch(isLoading('load-event-warnings', true))
  }
}
