import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestCycleDelete from '../request/cycle-delete'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'

export default (cycleId) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !cycleId) return

    dispatch(isLoading('delete-cycle'))

    let result = null
    result = await requestCycleDelete({
      id: cycleId,
      workspace: workspaceId
    }, auth)

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('delete-cycle', true))
    return result
  }
}
