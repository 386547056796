import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationHourModifiersSet($organization: ID!, $modifiers: [WorkspaceHourModifierInput!]!) {
  organizationHourModifiersSet(organization: $organization, modifiers: $modifiers)
}`

async function requestOrgHoursModifierSet (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationHourModifiersSet
}

export default requestOrgHoursModifierSet
