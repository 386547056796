import React from 'react'
import { Flex, Link, Spacing } from '@ui'
import { LogoWide, OtherBackground } from '@app/image'
import { LanguagePicker } from '@app/component/pages/other/LanguagePicker'

import './OtherPagesLyout.style.scss'

export const OtherPagesLayout = ({
  background,
  backgroundContent,
  children,
  offline,
  absoluteContent
}) => (
  <div className='other-layout'>
    <div
      className='other-bg'
      style={{ backgroundImage: `url(${background || OtherBackground})` }}
    >
      {backgroundContent && (
        <div>
          {backgroundContent}
        </div>
      )}
    </div>
    <div className='other-content'>
      <Flex justify={Flex.POSITION.SPC_BETWEEN} stretch>
        <Spacing size={Spacing.SIZES.SIZE_48} type={Spacing.TYPES.HORIZONTAL} />
        {offline ? <LogoWide /> : (
          <Link
            type={Link.TYPES.ROUTER_LINK}
            to='/'
          >
            <LogoWide />
          </Link>
        )}
        <LanguagePicker />
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_48} />
      {children}
    </div>
    {absoluteContent}
  </div>
)
