import React, { Fragment } from 'react'
import { t } from 'i18next'

import {
  Icon,
  Button,
  Modal,
  Flex,
  Spacing
} from '@ui'

import connect from './connect'
import './index.scss'

class PlanningFinished extends React.Component {
  _getUnassignedPlannedShifts (props) {
    const unassignedPlannedShifts = []

    props.selectedShifts.forEach((ss) => {
      if (
        props.shifts.filter((s) => s.userId === null).find((s) => s.id === ss)
      ) {
        unassignedPlannedShifts.push(ss)
      }
    })
    return unassignedPlannedShifts
  }

  componentDidMount () {
    const { setModal } = this.props

    if (this._getUnassignedPlannedShifts(this.props).length === 0) {
      setModal(null)
    }
  }

  render () {
    const { close, selectedShifts } = this.props

    // count the shifts that the planned could not assign
    const unassignedPlannedShifts = this._getUnassignedPlannedShifts(this.props)

    return (
      <Modal
        className='ds-planning-finished'
        size={Modal.SIZES.S}
        close={close}
        headerContent={
          <div className='ds-title'> {t('PLANNING_FINISHED')}</div>
        }
        sections={[
          <Fragment key={0}>
            {unassignedPlannedShifts.length ? (
              <div className='ds-planning-wrapper-text'>
                <Spacing
                  type={Spacing.TYPES.VERTICAL}
                  size={Spacing.SIZES.SIZE_8}
                >
                  <Flex
                    align={Flex.POSITION.CENTER}
                    justify={Flex.POSITION.CENTER}
                  >
                    <Icon lg ico='info' />
                  </Flex>
                </Spacing>
                <Spacing
                  type={Spacing.TYPES.VERTICAL}
                  size={Spacing.SIZES.SIZE_8}
                >
                  <Flex align={Flex.POSITION.CENTER}>
                    {t('PLANNING_FINISHED_COULDNT_ASSIGN_X', {
                      x: unassignedPlannedShifts.length,
                      y: selectedShifts.length
                    })}
                  </Flex>
                </Spacing>
                <Spacing
                  type={Spacing.TYPES.VERTICAL}
                  size={Spacing.SIZES.SIZE_8}
                >
                  <Flex align={Flex.POSITION.CENTER}>
                    {t('PLANNING_FINISHED_COULDNT_ASSIGN_MORE_TXT')}
                  </Flex>
                </Spacing>
              </div>
            ) : null}
          </Fragment>
        ]}
        footerContent={
          <Button
            onClick={close}
            label={t('DONE')}
          />
        }
      />
    )
  }
}

export default connect(PlanningFinished)
