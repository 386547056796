/* eslint-disable react/jsx-fragments */
import styles from './index.scss'
import connect from './connect'
import TabCreate from './tab-create'
import TabApproveRequest from './tab-approve-request'
import TabShow from './tab-show'
import { useState, useEffect, Fragment } from 'react'
import {
  Link,
  Loader,
  Text
} from '@ui'
import { t } from 'i18next'
import { Modal } from '@ui/modal'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

/*
PROPS REFERENCE:

- selectedShiftIds: array of IDs of shifts that are selected/multiselected.
- newlyCreatedShifts: array of shifts that were created just before this modal was opened and might not be present in store yet. only used when this modal is opened as 'afterSubmit' callback of the 'extra-shift' modal
- action:
    1. create: we're creating new offer for selected shift(s)
    2. approve: we're approving an offer with status 'managerRequestApproval' on selected shift
    3. show: we're displaying the info about an offer (usually with status 'employeeReplies')
    4. resolve: we're resolving an offer with status 'managerResultApproval' on selected shift
- createType:
    1. swap
    2. drop
- createUpdateBefore: an update applied to shift before we create the offer
*/

const Offers = (props) => {
  const {
    // from component's properties:
    action,
    selectedShiftIds,
    newlyCreatedShifts,
    createUpdateBefore,
    createType,
    afterClose,
    // from store:
    offers,
    shifts
  } = props

  const getRelevantOffers = (forAction) => {
    if (forAction === 'approve') return offers.filter(off => off.status === 'managerRequestApproval')
    if (forAction === 'show') return offers.filter(off => off.status === 'employeeReplies' || off.status === 'resolvedWaiting')
    if (forAction === 'resolve') return offers.filter(off => off.status === 'managerResultApproval')
    return []
  }

  const getRelevantShifts = (forAction) => {
    if (forAction === 'create') {
      if (selectedShiftIds && selectedShiftIds.length) return shifts.filter(s => !offers.find(o => o.status !== 'resolved' && o.shiftId === s.id) && selectedShiftIds.includes(s.id)).map(s => Object.assign({}, s, { isSelected: true }))
      return []
    }

    const relevantOffers = getRelevantOffers(forAction)
    let ret = shifts.map(s => {
      const off = relevantOffers.find(off => off.shiftId === s.id)
      if (!off) return null
      if (selectedShiftIds && selectedShiftIds.length && selectedShiftIds.includes(s.id) && (off.status !== 'resolvedWaiting' /* shifts with resolvedWaiting offers cannot be selected */)) {
        return Object.assign({}, s, { hasOffer: off, isSelected: true })
      } else {
        return Object.assign({}, s, { hasOffer: off })
      }
    }).filter(Boolean)

    // if exactly 1 shift is selected, move it to the top
    if (selectedShiftIds && selectedShiftIds.length === 1) {
      ret = [ret.find(r => r.id === selectedShiftIds[0])].concat(ret.filter(r => r.id !== selectedShiftIds[0]))
    }

    return ret.filter(Boolean)
  }

  const [selectedTab, setSelectedTab] = useState(action)
  const [relevantShifts, setRelevantShifts] = useState(getRelevantShifts(action))
  const [renderChildButtons, setRenderChildButtons] = useState()
  const [isProcessingAndCannotClose, setIsProcessingAndCannotClose] = useState(false) // either false or a progress percentage - if it's not false, the modal displays progress and cannot be closed

  // on mount
  useEffect(() => {
    const missingSelectedShifts = selectedShiftIds.filter(sid => !shifts.find(s => s.id === sid))
    if (missingSelectedShifts && missingSelectedShifts.length && newlyCreatedShifts && newlyCreatedShifts.length) {
      const newRelShifts = [...relevantShifts]
      newlyCreatedShifts.filter(s => missingSelectedShifts.includes(s.id)).forEach(s => {
        newRelShifts.push(Object.assign({}, s, { isSelected: true }))
      })
      setRelevantShifts(newRelShifts)
    }
  }, [])

  const getRenderChildButtons = (childBtns) => {
    setRenderChildButtons(childBtns)
  }

  return (
    <Modal
      size={Modal.SIZES.XL}
      className='ds-mod-offers'
      afterClose={afterClose}
      noClose={isProcessingAndCannotClose !== false}
      headerContent={
        <Fragment>
          {/* Title */}
          <div className={cx('ds-mo-top')}>
            <div className={cx('ds-title')}>{t('OFF_MODAL_TITLE')}</div>
          </div>
        </Fragment>
      }
      extraHeaderButtons={
        isProcessingAndCannotClose === false
          ? [
            <Link
              key='link0'
              type={Link.TYPES.ROUTER_LINK}
              to='/settings/contest-settings'
            >
              <Text color={Text.COLORS.PRIMARY}>
                {t('OFF_MODAL_SETTINGS')}
              </Text>
            </Link>
          ] : undefined
      }
      sections={[
        <Fragment key={0}>
          {isProcessingAndCannotClose !== false
            ? <Loader position={Loader.POSITIONS.STATIC} text={isProcessingAndCannotClose + '%'} />
            : (
              <>
                {/* Tabs */}
                <div className='ds-mo-tabs'>
                  <div className={cx('ds-mo-tab', { 'is-selected-tab': selectedTab === 'create' })} onClick={() => { setSelectedTab('create'); setRelevantShifts(getRelevantShifts('create')) }}>
                    {t('OFF_TAB_CREATE')}
                    <span className={cx('ds-mo-tab-num', { 'is-dark-blue-badge': getRelevantShifts('create').length })}>
                      {getRelevantShifts('create').length}
                    </span>
                  </div>

                  <div className={cx('ds-mo-tab', { 'is-selected-tab': selectedTab === 'approve' })} onClick={() => { setSelectedTab('approve'); setRelevantShifts(getRelevantShifts('approve')) }}>
                    {t('OFF_TAB_APPROVE')}
                    <span className={cx('ds-mo-tab-num', { 'is-red-badge': getRelevantShifts('approve').length })}>
                      {getRelevantShifts('approve').length}
                    </span>
                  </div>

                  <div className={cx('ds-mo-tab', { 'is-selected-tab': selectedTab === 'show' })} onClick={() => { setSelectedTab('show'); setRelevantShifts(getRelevantShifts('show')) }}>
                    {t('OFF_TAB_SHOW')}
                    <span className={cx('ds-mo-tab-num', { 'is-dark-blue-badge': getRelevantShifts('show').length })}>
                      {getRelevantShifts('show').length}
                    </span>
                  </div>

                  <div className={cx('ds-mo-tab', { 'is-selected-tab': selectedTab === 'resolve' })} onClick={() => { setSelectedTab('resolve'); setRelevantShifts(getRelevantShifts('resolve')) }}>
                    {t('OFF_TAB_RESOLVE')}
                    <span className={cx('ds-mo-tab-num', { 'is-red-badge': getRelevantShifts('resolve').length })}>
                      {getRelevantShifts('resolve').length}
                    </span>
                  </div>
                </div>

                {selectedTab === 'create' && (
                  <TabCreate
                    relevantShifts={relevantShifts}
                    setRelevantShifts={setRelevantShifts}
                    createUpdateBefore={createUpdateBefore}
                    createType={createType}
                    renderChildBtns={(childBtns) => getRenderChildButtons(childBtns)}
                    setIsProcessingAndCannotClose={setIsProcessingAndCannotClose}
                  />
                )}

                {selectedTab === 'approve' && (
                  <TabApproveRequest
                    relevantShifts={relevantShifts}
                    setRelevantShifts={setRelevantShifts}
                    renderChildBtns={(childBtns) => getRenderChildButtons(childBtns)}
                  />
                )}

                {selectedTab === 'show' && (
                  <TabShow
                    relevantShifts={relevantShifts}
                    setRelevantShifts={setRelevantShifts}
                    renderChildBtns={(childBtns) => getRenderChildButtons(childBtns)}
                  />
                )}

                {selectedTab === 'resolve' && (
                  <TabShow
                    relevantShifts={relevantShifts}
                    setRelevantShifts={setRelevantShifts}
                    isResolveTab
                    renderChildBtns={(childBtns) => getRenderChildButtons(childBtns)}
                  />
                )}
              </>)}
        </Fragment>
      ]}
      footerContent={
        isProcessingAndCannotClose === false &&
          <>
            {renderChildButtons?.map((buttons, idx) => (
              <Fragment key={idx}>
                {buttons}
              </Fragment>
            ))}
          </>
      }
    />
  )
}

export default connect(Offers)
