import moment from 'moment'
import { SHIFT_MORNING_START_TIME, SHIFT_AFTERNOON_START_TIME, SHIFT_NIGHT_START_TIME } from '@app/const/globals'

// make(3) = [0, 1, 2]
// make(3, null) = [null, null, null]
function make (num, value) {
  if (!num) return []
  return [...Array(num)].map((n, idx) => {
    if (value !== undefined) {
      return value
    }

    return idx
  })
}

// ['00', ..., '23']
function hours () {
  return make(24).map((idx) => {
  // left pad
    return idx < 10 ? `0${idx}` : `${idx}`
  })
}

// minutes as percent of a day
function relMin (minutes) {
  const value = 100 * (minutes / (24 * 60))
  return `${value}%`
}

function periodToStart (period) {
  const start = moment(period.start)
  const dayStart = moment(period.start).startOf('day')
  const diff = start.diff(dayStart, 'minutes')
  return diff
}

function periodToEnd (period) {
  const end = moment(period.end)
  const dayStart = moment(period.end).startOf('day')
  const diff = end.diff(dayStart, 'minutes')
  return diff
}

function periodToDuration (period) {
  if (!period) return 0
  const start = moment.utc(period.start)
  const end = moment.utc(period.end)
  const diff = end.diff(start, 'minutes')
  return diff
}

function relPeriodStart (period) {
  const start = moment(period.start)
  const dayStart = moment(period.start).startOf('day')
  const diff = start.diff(dayStart, 'minutes')
  return relMin(diff)
}

function relPeriodDuration (period) {
  const start = moment.utc(period.start)
  const end = moment.utc(period.end)
  const diff = end.diff(start, 'minutes')
  return relMin(diff)
}

// '10:00' -> 600
function asMinutes (time) {
  const t = moment(time)
  return (t.hours() * 60) + t.minutes()
}

function asHour (minutes) {
  const hour = Math.round((minutes / 60) * 100) / 100
  if (isNaN(hour) === false) {
    return hour
  }
  return 0
}

function formatMinutes (minutes) {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
}

function formatHours (hours) {
  const totalMinutes = hours * 60
  const displayHours = Math.floor(totalMinutes / 60)
  const mins = totalMinutes % 60
  return `${String(displayHours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
}

function earliest (shifts) {
  if (!shifts) return null
  return shifts.reduce((acc, sf) => {
    if (acc > sf.start) {
      return sf.start
    }
    return acc
  }, 24 * 60)
}

function latest (shifts) {
  if (!shifts) return null
  return shifts.reduce((acc, sf) => {
    const end = sf.start + sf.duration
    if (end > acc) {
      return end
    }
    return acc
  }, 0)
}

function earliestLive (shifts) {
  return shifts.reduce((acc, sf) => {
    if (!acc) {
      return sf.period ? sf.period.start : null
    }

    if (moment(acc).isAfter(sf.period.start)) {
      return sf.period ? sf.period.start : null
    }

    return acc
  }, null)
}

function latestLive (shifts) {
  return shifts.reduce((acc, sf) => {
    if (!acc) {
      return sf.period ? sf.period.end : null
    }

    if (moment(acc).isBefore(sf.period.end)) {
      return sf.period ? sf.period.end : null
    }

    return acc
  }, null)
}

function liveShiftTime (shift) {
  return `${moment(shift.period.start).format('HH:mm')} - ${moment(shift.period.end).format('HH:mm')}`
}

function shiftTime (shift) {
  return `${formatMinutes(shift.start)} - ${formatMinutes(shift.start + shift.duration)}`
}

// user specific
function monthlyTotals (shifts, date) {
  const result = {
    shiftHours: 0,
    finishedShiftHours: 0
  }
  if (!shifts) return result

  const d = date ? moment(date) : moment()
  const currentMonth = shifts.filter(sf => {
    return d.startOf('month').isBefore(sf.period.start) && d.endOf('month').isAfter(sf.period.start)
  })

  currentMonth.forEach(sf => {
    let dur = sf.duration
    if (sf.pauses && sf.pauses.length) dur -= sf.pauses.reduce((a, s) => { return a + s.duration }, 0)
    dur = Math.max(0, dur)
    result.shiftHours += dur
    if (moment().isAfter(moment(sf.period.end))) {
      result.finishedShiftHours += dur
    }
  })

  result.shiftHours = Math.round(result.shiftHours / 60 * 100) / 100
  result.finishedShiftHours = Math.round(result.finishedShiftHours / 60 * 100) / 100

  return result
}

function periodThisMonth () {
  return {
    start: moment().startOf('month').toISOString(),
    end: moment().endOf('month').toISOString()
  }
}

function isPast (d) {
  return moment(d).isBefore(moment().startOf('day'))
}

function isToday (d) {
  return moment(d).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
}

function getDayParts () {
  return [
    { start: SHIFT_MORNING_START_TIME, end: SHIFT_AFTERNOON_START_TIME, conditionOperator: 'AND', name: 'SORT_DAY_PART_MORNING', ico: 'morning' },
    { start: SHIFT_AFTERNOON_START_TIME, end: SHIFT_NIGHT_START_TIME, conditionOperator: 'AND', name: 'SORT_DAY_PART_AFTERNOON', ico: 'sun' },
    { start: SHIFT_NIGHT_START_TIME, end: SHIFT_MORNING_START_TIME, conditionOperator: 'OR', name: 'SORT_DAY_PART_NIGHT', ico: 'moon' }
  ]
}

// filter the list of events by given day part
// expected event format example: { type: 'shift', data: { id: '...', period: { ... }, duration: 480 } }
function getEventsFilteredByDayPart (events, dp, typeFilter, onlyCheckIfOneExists = false) {
  let oneFound = false
  const res = events.filter((ev) => {
    if (onlyCheckIfOneExists && oneFound) return false
    if (typeFilter && ev.type && ev.type !== typeFilter) return false
    let timeCond = false
    const dur = ev.data.duration || moment.duration(moment(ev.data.period.end).diff(ev.data.period.start), 'minutes')
    const middleTime = moment(ev.data.period.start).add(dur / 2, 'minutes')
    if (dp.conditionOperator === 'AND') {
      if (
        middleTime.format('HH:mm:ss') >= dp.start &&
                  middleTime.format('HH:mm:ss') < dp.end
      ) timeCond = true
    }
    if (dp.conditionOperator === 'OR') {
      if (
        middleTime.format('HH:mm:ss') >= dp.start ||
                  middleTime.format('HH:mm:ss') < dp.end
      ) timeCond = true
    }
    if (timeCond) oneFound = true
    return timeCond
  })

  return onlyCheckIfOneExists ? oneFound : res
}

function getDayPartOfEvent (ev) {
  const dayParts = getDayParts()
  const dur = ev.dur || moment(ev.period.end).diff(ev.period.start, 'minutes')
  const middleTime = moment(ev.period.start).add(dur / 2, 'minutes')

  let ret = null
  dayParts.forEach((dp) => {
    if (ret) return
    let timeCond = false
    if (dp.conditionOperator === 'AND') {
      if (middleTime.format('HH:mm') >= dp.start && middleTime.format('HH:mm') < dp.end) timeCond = true
    }
    if (dp.conditionOperator === 'OR') {
      if (middleTime.format('HH:mm') >= dp.start || middleTime.format('HH:mm') < dp.end) timeCond = true
    }
    if (timeCond) {
      ret = dp
    }
  })
  return ret
}

function getBusinessDaysInMonth (date/* , holidays */) {
  const ret = []
  for (var d = moment(date).startOf('month').startOf('day'); d.isSame(date, 'month'); d.add(1, 'day')) {
    if (
      ![6, 7].includes(d.isoWeekday())
      // && !holidays.find(h => moment(h.date).isSame(d, 'day')) // soo, I found out that Mon-Fri are always considered work days, even if they're a holiday
    ) {
      ret.push(d.format('YYYY-MM-DD'))
    }
  }
  return ret
}

function getInitialCalendarDate () {
  const storeDate = window.localStorage.getItem('ds-calendar-date')
  if (storeDate && moment(storeDate).isValid()) {
    // if we have the last date stored in the store, use that
    return moment(storeDate).format('YYYY-MM-DD')
  } else {
    // otherwise, default to the start of next month
    return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD')
  }
}

function getWorkhoursFromUnavailabilities (unavs, categories, normalDailyWork) {
  const consideredCategories = categories ? categories.filter(uc => uc.consider).map(uc => uc.id) : []
  let workHoursFromUnavs = 0.0
  unavs.filter(u => consideredCategories.includes(u.categoryId)).forEach(u => {
    if (moment(u.period.end).diff(u.period.start, 'minutes') <= (normalDailyWork / 2)) {
      // short unavs (less or equal than half of the work day)
      workHoursFromUnavs += normalDailyWork / 2
    } else {
      // long unavs (more than half of the work day)
      workHoursFromUnavs += normalDailyWork
    }
  })
  return workHoursFromUnavs
}

// returns a default breaks that should be added to the shift based on its duration and contract type
function getDefaultShiftBreaks ({ details, workspace }) {
  // if we have automatic pauses retrieved from DummyShift query, just use that. it's more valid info, because it's BE telling us what it will actually create on the shift
  if (details.automaticPauses) return details.automaticPauses

  if (!workspace || !workspace.contractTypes || !workspace.contractTypes.some(c => c.rules && c.rules.shiftBreak)) return []

  const fallbackDuration = 30
  const startMin = (details && details.period && moment(details.period.start).isValid() && moment(details.period.end).isValid() && moment(details.period.end).isAfter(details.period.start))
    ? Math.floor(moment(details.period.end).diff(details.period.start, 'minutes') / 60 / 2) * 60
    : 240

  const shiftDuration = details.duration
  const cTypeId = details.contractType || workspace.contractTypes[0].id
  const contractObj = workspace.contractTypes.find(c => c.id === cTypeId)
  const pauseRules = contractObj?.rules?.shiftBreak

  // if 'afterWorkTime' rule exists
  if (pauseRules && pauseRules.afterWorkTime) {
    if (shiftDuration <= pauseRules.afterWorkTime) return []
    if (pauseRules.duration) return [{ duration: pauseRules.duration, start: startMin }]
    if (!pauseRules.duration) return [{ duration: fallbackDuration, start: startMin }]
  }

  // if 'afterWorkTime' rule doesn't exist
  if (!pauseRules || !pauseRules.afterWorkTime) {
    if (pauseRules && pauseRules.duration) return [{ duration: pauseRules.duration, start: startMin }]
    if (!pauseRules || !pauseRules.duration) return [{ duration: fallbackDuration, start: startMin }]
  }

  return []
}

// computes the current balancing period with respect to today's date (or specified date), from balancingPeriod in WS settings
function getCurrentBalancingPeriod (balancingPeriod, referenceDate = moment()) {
  let currentBalancingPeriodStart = null
  let currentBalancingPeriodEnd = null

  if (balancingPeriod && balancingPeriod.start && balancingPeriod.amount && balancingPeriod.unit) {
    const getCurrentBalancingPeriodEnd = (cbpStart) => {
      return cbpStart.clone().add(balancingPeriod.amount, balancingPeriod.unit)
    }

    currentBalancingPeriodStart = moment(balancingPeriod.start)
    if (currentBalancingPeriodStart.isBefore(referenceDate)) {
      while (getCurrentBalancingPeriodEnd(currentBalancingPeriodStart).isBefore(referenceDate)) {
        currentBalancingPeriodStart.add(balancingPeriod.amount, balancingPeriod.unit)
      }
    } else {
      while (currentBalancingPeriodStart.isAfter(referenceDate)) {
        currentBalancingPeriodStart.subtract(balancingPeriod.amount, balancingPeriod.unit)
      }
    }
    currentBalancingPeriodEnd = getCurrentBalancingPeriodEnd(currentBalancingPeriodStart)
  }

  if (currentBalancingPeriodStart && currentBalancingPeriodEnd) {
    return {
      start: currentBalancingPeriodStart,
      end: currentBalancingPeriodEnd
    }
  } else {
    return null
  }
}

export default {
  hours,
  relMin,
  relPeriodStart,
  relPeriodDuration,
  periodToStart,
  periodToEnd,
  periodToDuration,
  asMinutes,
  asHour,
  formatMinutes,
  formatHours,
  earliest,
  latest,
  earliestLive,
  latestLive,
  liveShiftTime,
  shiftTime,
  monthlyTotals,
  periodThisMonth,
  getDayParts,
  getEventsFilteredByDayPart,
  getInitialCalendarDate,
  getDefaultShiftBreaks,
  isPast,
  isToday,
  getBusinessDaysInMonth,
  getWorkhoursFromUnavailabilities,
  getDayPartOfEvent,
  getCurrentBalancingPeriod
}
