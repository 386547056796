import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { createRole } from '@app/ac'

const mapStateToProps = ({
  loading,
  me,
  organizationId,
  auth,
  organization
}) => {
  const org = me.organizations && me.organizations.find((organization) => organization.id === organizationId)

  return {
    organizationId,
    me,
    auth,
    organizationName: org.name,
    isLoading: loading.includes('me') || loading.includes('workspaces') || loading.includes('workspace-detail'),
    countries: organization.countries || []
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createRole
}, dispatch)

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
