import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import { Err, Icon } from '@ui'

import './style.scss'
import { phoneData } from './phoneData'
import { useFloatable } from '@app/util'

const STYLES = Object.freeze({
  DEFAULT: 'default',
  UNDERLINE: 'underline',
  BORDERLESS: 'borderless'
})

const SIZES = Object.freeze({
  MEDIUM: 'medium',
  LARGE: 'large',
  FULL_WIDTH: 'full-width'
})

export const PhoneInput = ({
  label,
  style,
  size,
  disabled,
  errorMessage,
  hasError,
  placeholder,
  value,
  onChange,
  required,
  tabIndex,
  autoFocus,
  onBlur,
  onFocus,
  autocomplete,
  name,
  ref,
  onKeyPress,
  onKeyDown,
  onSubmit,
  onClick,
  selfValidate
}) => {
  const floatable = useFloatable()
  const [code, setCode] = useState('420')
  const [error, setError] = useState(false)

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 13) onSubmit()
  }
  const valueArray = value ? value.split('-') : [null, null]
  const isValid = (newValue) => {
    const notValid = /^[0-9]{6,14}$/.test(newValue)
    setError(!notValid)
  }
  useEffect(() => {
    value && setCode(valueArray[0])
  }, [value])
  return (
    <div
      onClick={onClick}
      className={classNames(
        'phone-input',
        style,
        size,
        {
          disabled,
          'has-error': hasError || error
        }
      )}
    >
      {label && <div className='input-label'>{label}</div>}
      <div className='group-container'>
        <div className='input-container'>
          <div className='phone-dropdown' {...floatable.floatAnchor}>
            {code ? '+' + code : '#'}
            {!disabled && <Icon ico={Icon.ICONS.arrowDown} />}
          </div>
          <input
            ref={ref}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            value={valueArray[1] ? valueArray[1] : ''}
            required={required}
            tabIndex={tabIndex}
            type='tel'
            pattern='^[0-9]{6,14}$'
            autoComplete={autocomplete}
            name={name}
            id={name}
            onChange={(e) => {
              selfValidate && isValid(e.target.value)
              onChange(code + '-' + e.target.value)
            }}
            onKeyPress={(e) => onKeyPress(e)}
            onKeyDown={(e) => onKeyDown ? onKeyDown(e) : handleKeyDown(e)}
            onBlur={() => onBlur(code + '-' + valueArray[1])}
            onFocus={onFocus}
          />
        </div>
      </div>
      {floatable.open && (
        <div className='phone-options' {...floatable.floatContainer}>
          {phoneData.map((item) => (
            <div
              className={'phone-item ' + (item.dialCode === valueArray[0] ? 'active' : '')}
              key={item.isoCode}
              onClick={() => {
                onChange(item.dialCode + '-' + (valueArray[1] ? valueArray[1] : ''))
                setCode(item.dialCode)
                floatable.setOpen(false)
              }}
            >
              {`${item.name} +${item.dialCode}`}
            </div>
          ))}
        </div>
      )}
      {(hasError && (errorMessage || error)) && <Err message={errorMessage || t('API_ERR_BAD_PHONE')} />}
    </div>
  )
}

PhoneInput.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  required: PropTypes.bool,
  selfValidate: PropTypes.bool,
  tabIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
  autocomplete: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func
}
PhoneInput.defaultProps = {
  label: null,
  style: STYLES.DEFAULT,
  size: SIZES.LARGE,
  disabled: false,
  value: '',
  placeholder: '731 000 000',
  selfValidate: false,
  hasError: false,
  autocomplete: 'off',
  name: null,
  errorMessage: null,
  autoFocus: false,
  tabIndex: 1,
  required: false,
  onClick: () => {},
  onKeyPress: () => null,
  onKeyDown: null,
  onChange: () => {},
  onSubmit: () => {},
  onBlur: () => {}
}
PhoneInput.STYLES = STYLES
PhoneInput.SIZES = SIZES
