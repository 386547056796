import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ActionReplyMultiMutation (
  $actions: [ID!]!
  $reply: String!
  $data: JSON
) {
  actionReplyMulti (
    actions: $actions
    reply: $reply
    data: $data
  ) {
    result
    data
  }
}`

async function requestActionReply (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('action-reply-multi', errors)
  }
  return data.actionReplyMulti
}

export default requestActionReply
