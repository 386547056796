import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation requestCreateTimeOffRequest (
    $workspace: ID!
    $user: ID!
    $category: ID!
    $period: PeriodInput!
    $periodType: PeriodType
    $atTheStartOfShift: Boolean
    $note: String
    $attachments: [ID!]
    $shift: ID
  ) {
  timeOffCreateRequest (
    workspace: $workspace
    user: $user
    category: $category
    period: $period
    periodType: $periodType
    atTheStartOfShift: $atTheStartOfShift
    note: $note
    attachments: $attachments
    shift: $shift
  ) {
    id
  }
}`

async function requestCreateTimeOffRequest (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('actions', errors)
  }
  return data
}

export default requestCreateTimeOffRequest
