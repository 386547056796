import eT from '../const/error-type'

// the validator returns null if everything is ok
// otherwise an object with error details

function required (value, field) {
  if (value) return null
  return {
    field,
    value,
    type: eT.FORM_ERR_MISSING_REQUIRED
  }
}

function email (value, field) {
  if (/\S+@\S+\.\S+/.test(value)) return null
  return {
    field,
    value,
    type: eT.FORM_ERR_INVALID_EMAIL
  }
}

function password (value, field) {
  if (value.length > 7) return null
  return {
    field,
    value,
    type: eT.FORM_ERR_SHORT_PASSWORD
  }
}

function passwordsMatch (value, valueRepeated, field) {
  if (value === valueRepeated) return null
  return {
    field,
    valueRepeated,
    type: eT.FORM_ERR_MISMATCHED_PASSWORD
  }
}

function name (value, field) {
  if (value && value.match(' ')) {
    return null
  }

  return {
    field,
    value,
    type: eT.FORM_ERR_INVALID_NAME
  }
}

function requiredEmailOrName (value, field) {
  const requiredErr = required(value, field)
  if (requiredErr) {
    return requiredErr
  }

  if (email(value) || name(value)) return null

  return {
    field,
    value,
    type: eT.FORM_ERR_NOT_EMAIL_OR_NAME
  }
}

function requiredEmail (value, field) {
  const requiredErr = required(value, field)
  if (requiredErr) {
    return requiredErr
  }

  return email(value, field)
}

function requiredWorkspaceName (value, field) {
  const requiredErr = required(value, field)
  if (requiredErr) {
    return requiredErr
  }
  if (value.trim().length >= 2) return null
  return {
    field,
    value,
    type: eT.FORM_ERR_INVALID_NAME
  }
}

function requiredPosition (value, field) {
  if (value && value !== 'newPosition') return null
  return {
    field,
    value,
    type: eT.FORM_ERR_MISSING_REQUIRED_POSITION
  }
}

function requiredPhone (value, field) {
  const requiredErr = required(value, field)
  if (requiredErr) {
    return requiredErr
  }

  // '^[0-9]{1,4}-[0-9]{6,14}$'
  if (/^[0-9]{1,4}-[0-9]{6,14}/.test(value) === false) {
    return {
      field,
      value,
      type: eT.FORM_ERR_BAD_PHONE
    }
  } else {
    return null
  }
}

function requiredPassword (value, field) {
  const requiredErr = required(value, field)
  if (requiredErr) {
    return {
      field,
      value,
      type: eT.FORM_ERR_MISSING_PASSWORD
    }
  }

  return password(value, field)
}

function requiredAgree (value, field) {
  const requiredErr = required(value, field)
  if (requiredErr || (value !== true && value !== 'true')) {
    return {
      field,
      value,
      type: eT.FORM_ERR_MUST_AGREE_TERMS
    }
  }
}

function lastManager (field) {
  return {
    field,
    type: eT.FORM_ERR_LAST_MANAGER
  }
}

export default {
  required,
  email,
  name,
  password,
  passwordsMatch,
  requiredEmail,
  requiredEmailOrName,
  requiredWorkspaceName,
  requiredPhone,
  requiredPassword,
  requiredPosition,
  requiredAgree,
  lastManager,
  isEmail: (v) => !email(v)
}
