import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation AvailabilityCreateMultiMutation (
  $workspace: ID!,
  $data: [AvailabilityCreateData!]!
) {
  availabilityBulkCreate (
    workspace: $workspace
    data: $data
  ) {
    id
  }
}`

async function requestAvailabilityCreateMulti (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('availability-create-multi', errors)
  }
  return data.availabilityBulkCreate
}

export default requestAvailabilityCreateMulti
