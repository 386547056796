import { fetchRequest, errorUtil } from '@app/util'
import q from '../query'

const query = `query OrganizationCountries($organizationId: ID!) {
  organization(id: $organizationId) {
    countries {
      ${q.ORGANIZATION_COUNTRY_PARAMS_DETAILED}
    }
  }  
}`

async function requestOrgCountries (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrgCountries
