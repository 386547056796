import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'

const mapStateToProps = ({ language, auth, workspaceId, loading }) => {
  return {
    workspaceId,
    language,
    auth,
    isLoading: loading.includes('load-export-token')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
