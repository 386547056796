import store from '../store'
import isLoading from './is-loading'
import { requestOrgSubscriptionInvoices } from '../request'
import addError from '../ac/add-error'

export default (id, form) => {
  return async (dispatch) => {
    const { auth, organizationId } = store.getState()
    if (!organizationId) return

    dispatch(isLoading('load-sub-invoices'))
    const result = await requestOrgSubscriptionInvoices({
      organizationId: organizationId
    }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      return
    }
    dispatch(isLoading('load-sub-invoices', true))
    return result
  }
}
