import React from 'react'
import { Icon } from '@ui'
import { t } from 'i18next'

export const NoColumns = () => (
  <div className='no-columns-text'>
    <Icon
      size={Icon.SIZES.LARGE}
      ico={Icon.ICONS.warning}
    />
    <div>{t('NO_COLUMNS')}</div>
  </div>
)
