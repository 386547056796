import aT from '../const/action-type'

export const AnnounceShiftAction = (data) => {
  return { type: aT.ANNOUNCE_SHIFTS_SORT, data }
}

export const UpdateAnnounceShiftFromAction = (data) => {
  return { type: aT.UPDATE_ANNOUNCE_SHIFT_FROM, data }
}

export const UpdateAnnounceShiftToAction = (data) => {
  return { type: aT.UPDATE_ANNOUNCE_SHIFT_TO, data }
}

export const UpdateAnnounceShiftByPositionAction = (data) => {
  return { type: aT.UPDATE_ANNOUNCE_BY_POSITION, data }
}

export const UpdateAnnounceShiftByLocalityAction = (data) => {
  return { type: aT.UPDATE_ANNOUNCE_BY_LOCALITY, data }
}

export const UpdateAnnounceShiftByPeopleAction = (data) => {
  return { type: aT.UPDATE_ANNOUNCE_BY_PEOPLE, data }
}

export const RevertAnnounceShiftAction = (data) => {
  return { type: aT.REVERT_ANNOUNCE, data }
}
