import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import createShift from '@app/ac/create-shift'
import setAvailabilityOrTimeOff from '@app/ac/set-availability-or-time-off'

const mapStateToProps = ({ workspaceId, loading }) => {
  return {
    workspaceId,
    isLoading: loading.includes('create-shift') || loading.includes('clear-unavailable') || loading.includes('set-unavailable')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    createShift,
    setAvailabilityOrTimeOff
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
