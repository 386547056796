import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation LoginMutation ($username: String!, $password: String!, $code: String, $type: MFAMethods) {
  login (
    username: $username
    password: $password
    code: $code
    type: $type
  ) {
    token
    mfaEnabled
    mfaMethods
  }
}`

async function requestLogin (variables) {
  const response = await fetchRequest({ query, variables })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('login', errors)
  }
  return data.login
}

export default requestLogin
