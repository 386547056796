import aT from '../const/action-type'
import initial from '../store/initial'

export default (
  state = initial.enforcedLocality,
  action
) => {
  switch (action.type) {
    case aT.SET_ENFORCED_LOCALITY:
      return action.data
    case aT.RESET_STORE:
      return initial.enforcedLocality
    default:
      return state
  }
}
