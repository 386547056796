import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation RoleContractCreateMutation (
  $workspace: ID!,
  $user: ID!,
  $contractData: RoleContractInput!
) {
  roleContractCreate (
    workspace: $workspace
    user: $user
    data: $contractData
  ) {
    id
  }
}`

async function requestRoleContractCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('role-contract-create', errors)
  }
  return data.roleContractCreate
}

export default requestRoleContractCreate
