import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ShiftTemplateUpdateMutation (
  $workspace: ID!,
  $name: String!,
  $data: ShiftData,
  $id: ID!
) {
  shiftTemplateUpdate (
    workspace: $workspace
    name: $name
    id: $id
    data: $data
  ) {
    id
  }
}`

async function requestShiftTemplateUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-template-update', errors)
  }
  return data.shiftTemplateUpdate
}

export default requestShiftTemplateUpdate
