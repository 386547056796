import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestAvailabilitiesWorkspace (variables, token) {
  const query = `query AvailabilitiesWorkspaceQuery (
    $workspace: ID!,
    $period: PeriodInput!
    $users: [ID!]
  ) {
    workspace (
      id: $workspace
    ) {
      availabilities (
        period: $period
        users: $users
      ) ${q.AVAILABILITY}
    }
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('availabilities', errors)
  }
  return data.workspace.availabilities
}

export default requestAvailabilitiesWorkspace
