import { fetchRequest, errorUtil, permissionsUtil } from '@app/util'
import q from '../query'
import { PERMISSIONS } from '@app/const'

async function requestWorkspace (wsId, token) {
  const query = `query WorkspaceQuery (
    $id: ID!
  ) {
    workspace (
      id: $id
    ) {
      id
      name
      ${q.WORKSPACE_PARAMS_DETAILS}
      ${permissionsUtil.canRead(PERMISSIONS.WORKSPACE.EMPLOYEES) ? `invites ${q.INVITE}` : ''}
      ${permissionsUtil.canRead(PERMISSIONS.SETTINGS.SETTINGSOFFERS) ? q.TRANSFER_GROUPS : ''}
    }
  }`

  const response = await fetchRequest({ query, variables: { id: wsId }, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('workspace', errors)
  }
  return data.workspace
}

export default requestWorkspace
