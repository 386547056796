import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { CSVLink } from 'react-csv'

import { Button, Icon } from '@ui'
import { parseTable } from '@app/util/export/parse-table'

export const Export = ({
  tableName,
  selectedRows,
  columns,
  exportFileName
}) => {
  const [config, reducedRows] = parseTable(columns, selectedRows)

  return (
    <>
      {selectedRows.length ? (
        <CSVLink
          data={reducedRows}
          headers={config}
          filename={`${exportFileName || tableName}.csv`}
          target='_blank'
        >
          <Button
            ico={Icon.ICONS.export}
            bold
            label={t('TABLE_EXPORT_CSV')}
          />
        </CSVLink>
      ) : (
        <Button
          disabled
          bold
          ico={Icon.ICONS.export}
          label={t('TABLE_EXPORT_CSV')}
        />
      )}
    </>
  )
}

Export.propTypes = {
  selectedRows: PropTypes.array,
  tableName: PropTypes.string.isRequired,
  exportFileName: PropTypes.string
}
Export.defaultProps = {
  selectedRows: []
}
