import {
  requestNotificationsGroupCreate,
  requestNotificationsGroupUpdate,
  requestNotificationsGroupDelete
} from '@app/request'

import {
  isLoading,
  addError,
  loadWorkspaceDetail
} from '@app/ac'

import store from '../store'

export const createNotificationsGroup = (data) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !data) return

    dispatch(isLoading('create-notifications-group'))

    let result = null
    result = await requestNotificationsGroupCreate({
      workspaceId: workspaceId,
      data: data
    }, auth)

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('create-notifications-group', true))
    return result
  }
}

export const deleteNotificationsGroup = (id) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !id) return

    dispatch(isLoading('delete-notifications-group'))

    let result = null
    result = await requestNotificationsGroupDelete({
      id: id,
      workspaceId: workspaceId
    }, auth)

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('delete-notifications-group', true))
    return result
  }
}

export const updateNotificationsGroup = (data) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !data) return
    dispatch(isLoading('update-notifications-group'))

    let result = null
    result = await requestNotificationsGroupUpdate({
      workspaceId: workspaceId,
      data: data
    }, auth)
    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('update-notifications-group', true))
    return result
  }
}
