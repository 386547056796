import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query DummyShiftExchangesQuery (
  $workspaceId: ID!,
  $data: ShiftData!,
  $period: PeriodInput
) {
  dummyShift(workspace: $workspaceId, data: $data) {
    exchanges (
      period: $period
    ) {
      id
      period {
        start
        end
      }
      userId
      userName
      positionId
      localityId
      warnings ${q.EVENT_WARNING}
    }
  }
}`

async function requestShiftExchangesDummy (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-exchanges-dummy', errors)
  }
  return data.dummyShift.exchanges
}

export default requestShiftExchangesDummy
