import React from 'react'
import { t } from 'i18next'
import moment from 'moment'

import { Switcher, Text } from '@ui'

export const HeaderContent = ({
  isRequestingAbsence,
  newAvailabilityOrTimeOff,
  isEmployeeCalendar,
  details,
  userId,
  isAttendancePage,
  afterSubmit,
  isPluginEnabled,
  title,
  setModal,
  day,
  hour
}) => {
  const switcherOptions = [
    {
      value: 'extra-shift',
      label: isAttendancePage ? t('ADD_SWITCHER_ATTENDANCE') : details &&
        details.period &&
        moment(details.period.start).isBefore(moment()) &&
        isPluginEnabled('attendance') ? t('ADD_SWITCHER_ATTENDANCE') : t('ADD_SWITCHER_SHIFT')
    },
    {
      value: false,
      label: title
    }
  ]

  return (
    <>
      {/* HEADER / TITLE + SWITCHER */}
      <Text
        type={Text.TYPES.BODY_LARGE}
        weight={Text.WEIGHTS.BOLD}
      >
        {isRequestingAbsence ? t('REQUEST_UNAVAILABILITY_MODAL_TITLE') : (
          <>
            {newAvailabilityOrTimeOff && (t('ADD_UNAV_OR_SHIFT') + ' ' + title.toLowerCase())}
            {!newAvailabilityOrTimeOff && (t('EDIT') + ' ' + title.toLowerCase())}
          </>
        )}
      </Text>

      {/* Switcher: Add Shift/Unavailability */}
      {newAvailabilityOrTimeOff && !isEmployeeCalendar && (
        <Switcher
          value={false}
          onSelect={(value) => !!value && setModal(
            'extra-shift',
            {
              day: details.period && details.period.start ? moment(details.period.start).format('YYYY-MM-DD') : day,
              hour: hour,
              userId: details.userId || userId,
              type: 'live',
              newAttendance: isAttendancePage,
              afterSubmit: afterSubmit
            })}
          options={switcherOptions}
        />
      )}
    </>
  )
}

HeaderContent.propTypes = {}
HeaderContent.defaultProps = {}
