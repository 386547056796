import React from 'react'
import moment from 'moment'
import { t } from 'i18next'

import {
  Button,
  Flex,
  Icon,
  Spacing,
  Tooltip
} from '@ui'
import { UploadButton } from '@app/component/core'
import {
  miscUtil,
  useAccessRole
} from '@app/util'
import { PERMISSIONS } from '@app/const'

export const Attachments = ({
  attachments,
  setVal,
  employees,
  workspaceId,
  auth
}) => {
  const { canWrite } = useAccessRole()
  return (
    <>
      <Flex align={Flex.POSITION.END}>
        <UploadButton
          workspaceId={workspaceId}
          auth={auth}
          onUpload={(files) => setVal('attachments', attachments ? [...attachments, ...files] : files)}
        />
        <Spacing size={Spacing.SIZES.SIZE_6} />
        {attachments && attachments.map((att) => {
          const attType = att.filename && att.filename.split('.')
          const author = employees[att.author]
          let icoName = attType[attType.length - 1]
          if (!Object.keys(Icon.ICONS).includes(icoName)) {
            icoName = 'file'
          }
          return (
            <Tooltip
              key={att.id}
              anchor={
                <Icon
                  ico={Icon.ICONS[icoName]}
                  onClick={() => miscUtil.downloadFile(att.url)}
                  size={Icon.SIZES.EXTRA}
                />
              }
            >
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                <Flex direction={Flex.DIRECTION.COLUMN}>
                  <div>{att.filename}</div>
                  <div>{att.size} B</div>
                  <div>{moment(att.created).format('MM.DD.YYYY')}</div>
                  <div>{author?.name}</div>
                  <Spacing size={Spacing.SIZES.SIZE_8} />
                  {(canWrite(PERMISSIONS.CALENDAR) || canWrite(PERMISSIONS.BACKOFFICE.ATTENDANCE) || canWrite(PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS)) && (
                    <Button
                      ico={Icon.ICONS.delete}
                      label={t('DELETE')}
                      style={Button.STYLES.UNDERLINED}
                      color={Button.COLORS.RED}
                      onClick={() => setVal(
                        'attachments',
                        attachments.filter((item) => item.id !== att.id)
                      )}
                    />
                  )}
                </Flex>
              </Spacing>
            </Tooltip>
          )
        }
        )}
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_16} />
    </>
  )
}

Attachments.propTypes = {}
Attachments.defaultProps = {}
