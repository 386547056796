import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationFullTimePlansSet($organization: ID!, $fullTimePlans: [WorkspaceFullTimePlanInput!]!) {
  organizationFullTimePlansSet(organization: $organization, fullTimePlans: $fullTimePlans) {
    workspaceId
    amount
  }
}`

async function requestOrgFullTimePlansSet (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationFullTimePlansSet
}

export default requestOrgFullTimePlansSet
