"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.query = exports.mutate = exports.useMutate = exports.useFetch = exports.client = void 0;
const apollo_client_1 = require("./apollo-client");
Object.defineProperty(exports, "client", { enumerable: true, get: function () { return apollo_client_1.client; } });
const useFetch_1 = require("./useFetch");
Object.defineProperty(exports, "useFetch", { enumerable: true, get: function () { return useFetch_1.useFetch; } });
const useMutate_1 = require("./useMutate");
Object.defineProperty(exports, "useMutate", { enumerable: true, get: function () { return useMutate_1.useMutate; } });
const mutate_1 = require("./mutate");
Object.defineProperty(exports, "mutate", { enumerable: true, get: function () { return mutate_1.mutate; } });
const query_1 = require("./query");
Object.defineProperty(exports, "query", { enumerable: true, get: function () { return query_1.query; } });
