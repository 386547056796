import isLoading from './is-loading'
import requestHolidays from '../request/holidays'
import addError from '../ac/add-error'
import setHolidays from '../action/set-holidays'
import store from '../store'
import moment from 'moment'

export default (variables) => {
  return async (dispatch) => {
    const { auth, workspaceId, workspaces, holidays, me } = store.getState()
    if (!workspaceId) return
    if (!variables.period || !variables.period.start || !variables.period.end) return

    const ws = workspaces.find(ws => ws.id === workspaceId)
    if (!ws) return

    dispatch(isLoading('load-holidays'))
    let params = { country: ws.country, lang: me.language }
    if (variables) params = Object.assign({}, params, variables)

    // get the holidays for the period from BE
    let resultHolidays = await requestHolidays(params, auth)
    if (resultHolidays.error) {
      dispatch(addError(resultHolidays.error))
      dispatch(isLoading('load-holidays', true))
    }

    // and add all the other, previously loaded events to the list
    resultHolidays = ((resultHolidays && resultHolidays.length) ? resultHolidays : []).concat(holidays.filter(h => moment(h.date).isBefore(variables.period.start) || moment(h.date).isAfter(variables.period.end)))
    await dispatch(setHolidays(resultHolidays))

    dispatch(isLoading('load-holidays', true))
    return resultHolidays
  }
}
