import isLoading from './is-loading'
import setCalendarMultiSelect from '../action/set-calendar-multi-select'
import store from '../store'
import miscUtil from '@app/util/misc'

export default (update, addToSelection, removeFromSelection) => {
  return async (dispatch) => {
    dispatch(isLoading('set-calendar-multi-select'))

    // delete the window.lastDragStartEvt when calendar MS is updated - that variable is used by the DragSelect (implemented in the Schedule component)
    if (window.lastDragStartEvt) delete window.lastDragStartEvt

    let calendarMultiSelect = Object.assign({}, (store.getState().calendarMultiSelect || {}))

    // whenever sources change, reset the action and targets
    if (update && typeof update.sourceEvents !== typeof undefined && miscUtil.safeStringify(update.sourceEvents) !== miscUtil.safeStringify(calendarMultiSelect.sourceEvents)) {
      if (typeof update.action === typeof undefined || calendarMultiSelect.action === update.action) {
        update = Object.assign({}, update, { action: null, targets: [], isSelectingTargets: false })
      }
    }

    // whenever the action changes, reset the targets
    if (update && typeof update.action !== typeof undefined && miscUtil.safeStringify(update.action) !== miscUtil.safeStringify(calendarMultiSelect.action)) {
      update = Object.assign({}, update, { targets: [] })
    }

    calendarMultiSelect = Object.assign({}, calendarMultiSelect, (update || {}))
    calendarMultiSelect.isActive = calendarMultiSelect?.sourceEvents?.length || calendarMultiSelect.action || update?.isActive

    if (addToSelection) {
      if (calendarMultiSelect.isSelectingTargets) {
        calendarMultiSelect.targets.push(addToSelection)
      } else {
        calendarMultiSelect.sourceEvents.push(addToSelection)
      }
    }

    if (removeFromSelection) {
      if (calendarMultiSelect.isSelectingTargets) {
        calendarMultiSelect.targets = calendarMultiSelect.targets.filter(t => t.date !== removeFromSelection.date || t.userId !== removeFromSelection.userId)
      } else {
        calendarMultiSelect.sourceEvents = calendarMultiSelect.sourceEvents.filter(src => src.id !== removeFromSelection)
      }
    }

    // add offer data to sourceEvent shifts as 'hasOffer' prop
    const offers = store.getState().offers
    if (offers && offers.length) {
      for (var i = 0; i < calendarMultiSelect.sourceEvents.length; i++) {
        if (calendarMultiSelect.sourceEvents[i].positionId) {
          const off = offers.find(o => o.shiftId === calendarMultiSelect.sourceEvents[i].id)
          if (off) calendarMultiSelect.sourceEvents[i].hasOffer = off
        }
      }
    }

    // update the store.calendarMultiSelect
    await dispatch(setCalendarMultiSelect(calendarMultiSelect))
    await dispatch(isLoading('set-calendar-multi-select', true))

    return calendarMultiSelect
  }
}
