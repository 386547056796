import store from '../store'
import requestMeta from '@app/request/meta'
import config from '../const/config'
import miscUtil from '@app/util/misc'

function info (e) {
  if (e) {
    if (!e.shiftKey) return
    if (!e.ctrlKey && !e.metaKey) return
  }

  const state = store.getState()
  window.open().document.write([
    `<strong>Workspace ID: ${state.workspaceId}</strong>`,
    '<br />',
    `<strong>User ID: ${state.me.id}</strong>`,
    '<br />',
    `<strong>Organization ID: ${state.organizationId}</strong>`,
    '<br />',
    '<br />',
    '<strong>Store:</strong>',
    `<pre>${miscUtil.safeStringify(store.getState(), null, 2)}</pre>`
  ].join(''))
}

async function meta () {
  return {
    backend: Object.assign(await requestMeta(), { url: config.backend })
  }
}

export default {
  info,
  meta
}
