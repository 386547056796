import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation CycleUpdateMutation (
  $workspace: ID!,
  $id: ID!,
  $data: CycleData!
) {
  cycleUpdate (
    workspace: $workspace
    id: $id
    data: $data
  ) {
    id
  }
}`

async function requestCycleUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('cycle-update', errors)
  }
  return data.cycleUpdate
}

export default requestCycleUpdate
