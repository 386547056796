import { getLanguage } from '@app/util/i18n'

function calculatePercentage ({ baseValue, currentValue, round = true }) {
  let res = parseFloat((currentValue / baseValue) * 100)
  if (round) res = res.toFixed(0)
  if (res === Infinity) return 100
  if (isNaN(res) === false) return res
  return 0
}

function removeZeroBefore (number) {
  if (isNaN(number)) return ''
  return number.toString().replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '')
}

const round2decimals = (number, localize = true) => {
  const roundWith2Decimals = Math.round(number * 100) / 100
  return localize ? localizeNumber(roundWith2Decimals) : roundWith2Decimals
}

function localizeNumber (number) {
  const userLocale = getLanguage()
  const asNumber = parseFloat(number)
  if (isNaN(asNumber)) return number
  return new Intl.NumberFormat(userLocale).format(number)
}

function localizeAsCurrency (number, {
  currency,
  currencyDisplay = 'symbol',
  minimumFractionDigits = 0,
  locale = getLanguage()
} = {}) {
  const asNumber = parseFloat(number)
  if (isNaN(asNumber)) return number
  if (currency == null) currency = 'czk'

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay,
    minimumFractionDigits
  })

  return formatter.format(number)
}

function localizeAsHours (number, { locale = getLanguage() } = {}) {
  const asNumber = parseFloat(number)
  if (isNaN(asNumber)) return number

  const formatter = new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'hour',
    minimumFractionDigits: 0
  })

  return formatter.format(number)
}

function getCurrencySymbol ({
  currency,
  locale = getLanguage()
} = {}) {
  const PLACEHOLDER_NUMBER = 42
  if (currency == null) currency = 'czk'

  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    currencyDisplay: 'symbol'
  })

  return formatter.formatToParts(PLACEHOLDER_NUMBER).filter(part => part.type === 'currency')[0]?.value
}

export default {
  calculatePercentage,
  round2decimals,
  localizeNumber,
  localizeAsCurrency,
  localizeAsHours,
  getCurrencySymbol,
  removeZeroBefore
}
