import isLoading from './is-loading'
import requestTemplates from '../request/templates'
import addError from '../ac/add-error'
import setTemplates from '../action/set-templates'
import store from '../store'
import loadPositions from './load-positions'

let lastPendingWs = null
export default () => {
  return async (dispatch) => {
    const { auth, workspaceId, positions } = store.getState()
    if (!workspaceId) return
    // same ws is already being requested
    if (lastPendingWs === workspaceId) return

    lastPendingWs = workspaceId
    dispatch(isLoading('load-templates'))
    const result = await requestTemplates({ id: workspaceId }, auth)
    if (result.error) {
      dispatch(addError(result.error))
      dispatch(isLoading('load-templates', true))
      return
    }

    // if there is a shiftReq in this template with non-existent position, try to re-load the positions
    let positionMissing = false
    result.filter((tpl) => {
      return tpl.type === 'day'
    }).map((tpl) => {
      tpl.requirements.forEach((req) => {
        if (!req.positionId || (positions && positions.length > 0 && !positions.map((p) => p.id).includes(req.positionId))) {
          positionMissing = true
        }
      })
    })
    if (positionMissing) {
      await dispatch(await loadPositions())
    }

    // there is already another request in flight
    if (lastPendingWs !== workspaceId) return
    lastPendingWs = null

    // dispatch(setTemplates(withReq))
    dispatch(setTemplates(
      result.filter((tpl) => {
        return tpl.type === 'day'
      }).map(tpl => Object.assign({}, tpl, { requirements: tpl.requirements.map(req => Object.assign({}, req, { hash: req.id })) }))
    ))

    dispatch(isLoading('load-templates', true))
  }
}
