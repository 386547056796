import store from '@app/store'
import {
  createUserAttributes,
  requestRoleUpdate,
  requestUserUpdate,
  requestPositionAssignUpdate,
  updateUserAttributes,
  requestRoleContractUpdate
} from '@app/request'
import {
  loadPositions,
  createContract,
  assignPosition,
  unassignPosition,
  assignLocality,
  unassignLocality,
  setLocale
} from '@app/ac'
import { setWorkspaces } from '@app/action'
import {
  miscUtil,
  permissionsUtil
} from '@app/util'
import { PERMISSIONS } from '@app/const'
import moment from 'moment'
import fetchRequest from '../util/fetch'
import { AcRoleAssign, AcRoleAssignDelete } from '@app/queries'

export const updateEmployeeCard = (
  state,
  userId,
  isMe
) => {
  return async (dispatch) => {
    const { auth, workspaceId, employeeDetail, locale, workspaces } = store.getState()
    const resList = []
    const newAttribute = state.userAttributes?.find((att) => att.id === 'new')
    const newContract = state.contracts?.find((att) => att.id === 'new')
    const hasSection = (key) => Array.from(state.editedSections).some((item) => item === key)

    // fix: if WS manager changes their own name on their User object and there is some other name on their WS Role object, also change the name on that Role object
    if (isMe && permissionsUtil.canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES)) {
      if (state?.form?.data?.firstName && state?.form?.role?.customData?.firstName && state?.form?.data?.firstName !== state?.form?.role?.customData?.firstName) {
        state.form.role.customData.firstName = state.form.data.firstName
        state.editedSections.add('role')
      }
      if (state?.form?.data?.lastName && state?.form?.role?.customData?.lastName && state?.form?.data?.lastName !== state?.form?.role?.customData?.lastName) {
        state.form.role.customData.lastName = state.form.data.lastName
        state.editedSections.add('role')
      }
    }

    const updateRole = async (data) => {
      // fix: delete customAttributes with null value before sending a request, because that's not valid according to BE's GQL schema
      let updatedCA = data?.customAttributes?.length ? [...data.customAttributes] : []
      if (data?.customAttributes?.length) {
        await Promise.all(data.customAttributes.map(async (ca, i) => {
          if (typeof ca.value === typeof null) updatedCA[i] = null
        }))
      }
      updatedCA = updatedCA.filter(Boolean)

      // fix: delete custom attributes that are no longer defined on WS as userAttributes
      const ws = store.getState().workspaces.find(w => w.id === workspaceId)
      updatedCA = updatedCA.filter(ca => ws?.userAttributes?.some(ua => ua.id === ca.attributeId))

      // fix: if we're trying to assign a cycle that no longer exists on WS, don't assign it
      if (data.cycleId && ws && !ws?.cycles?.some(c => c.id === data.cycleId)) {
        delete data.cycleId
        if (typeof data.cycleGroup !== 'undefined') delete data.cycleGroup
      }

      // fix: convert the birthdate from 'YYYY-MM-DD' to UTC format before sending to API
      const updatedBirthdate = typeof data.birthdate === 'string' && data.birthdate.length === 10
        ? moment(data.birthdate, 'YYYY-MM-DD')
        : data.birthdate

      // fix: don't send Role.terminalPIN prop to API - it cannot be updated by roleUpdate mutation (there's a different mutation for it that generates a random PIN)
      if (typeof data.terminalPIN !== typeof undefined) {
        delete data.terminalPIN
      }

      await requestRoleUpdate({
        workspace: workspaceId,
        user: userId,
        data: Object.assign({}, data, { customAttributes: updatedCA, birthdate: updatedBirthdate })
      }, auth)
    }
    const createAttribute = async () => {
      const tempShort = `${Date.now()}`
      const attribute = await createUserAttributes({
        ...newAttribute,
        type: 'string',
        workspaceId,
        shortName: tempShort.slice(-3)
      }, auth)

      const customCopy = [...state.form.role.customAttributes]
      const index = customCopy.findIndex((att) => att.attributeId === 'new')
      customCopy[index] = {
        ...customCopy[index],
        attributeId: attribute.id,
        value: ''
      }
      const newWorkspaces = [...workspaces]
      const wsToEditIndex = newWorkspaces.findIndex((ws) => ws.id === workspaceId)
      newWorkspaces[wsToEditIndex].userAttributes = [
        ...newWorkspaces[wsToEditIndex].userAttributes,
        attribute
      ]
      await dispatch(setWorkspaces(newWorkspaces))
      return attribute
    }
    const updateAttributes = async (newlyCreatedAttribute) => {
      const ws = store.getState().workspaces.find(w => w.id === workspaceId)
      const attributes = state.userAttributes ? state.userAttributes.filter((att) => att.id !== 'new') : []
      if (newlyCreatedAttribute) {
        attributes.push({
          id: newlyCreatedAttribute.id,
          customId: null,
          name: newlyCreatedAttribute.name,
          type: newlyCreatedAttribute.type
        })
      }

      for (const att of attributes) {
        const originalAtt = ws?.userAttributes?.find(a => a.id === att.id)
        const isAttChanged = (!originalAtt || miscUtil.safeStringify(originalAtt) !== miscUtil.safeStringify(Object.assign({}, originalAtt, att)))
        if (isAttChanged) {
          await updateUserAttributes({
            ...att,
            workspaceId
          }, auth)
        }
      }
    }
    const handleCreateContract = async () => {
      const res = await dispatch(createContract(userId, {
        type: newContract.type,
        period: {
          start: moment(newContract.period.start).startOf('day'),
          end: newContract.period.end ? moment(newContract.period.end).endOf('day') : null
        },
        options: newContract.options,
        contractId: newContract.contractId
      }))

      if (res?.error) {
        resList.push(res)
      } else {
        return res?.id
      }
    }
    const handleUpdateContract = async () => {
      const contracts = state.contracts.filter((con) => con.id !== 'new')
      return Promise.all(contracts.map(async (con) => {
        const conUpdate = {
          workspace: workspaceId,
          user: userId,
          contract: con.id,
          contractData: {
            type: con.type,
            period: {
              start: con.period?.start ? con.period.start : null,
              end: con.period?.end ? con.period.end : null
            },
            options: con.options,
            contractId: con.contractId
          }
        }

        // check if the contract needs to be updated (if it was changed at all)
        const originalCon = employeeDetail.contracts.find(c => c.id === con.id)
        const compareA = originalCon
        if (compareA?.period?.start) compareA.period.start = moment(compareA.period.start).format('YYYY-MM-DD')
        if (compareA?.period?.end) compareA.period.end = moment(compareA.period.end).format('YYYY-MM-DD')
        const compareB = Object.assign({}, originalCon, conUpdate.contractData)
        if (compareB?.period?.start) compareB.period.start = moment(compareB.period.start).format('YYYY-MM-DD')
        if (compareB?.period?.end) compareB.period.end = moment(compareB.period.end).format('YYYY-MM-DD')
        delete compareB.workspace
        const needToUpdate = miscUtil.safeStringify(compareA) !== miscUtil.safeStringify(compareB)

        if (needToUpdate) {
          // fix format of period.start & period.end before sending to API
          if (conUpdate.contractData?.period?.start) conUpdate.contractData.period.start = moment(conUpdate.contractData.period.start).startOf('day')
          if (conUpdate.contractData?.period?.end) conUpdate.contractData.period.end = moment(conUpdate.contractData.period.end).endOf('day')

          const res = await requestRoleContractUpdate(conUpdate, auth)
          if (res?.error) {
            resList.push(res.error)
          }
        }
      }))
    }
    const handlePositionsUpdate = async (createdNewContractId) => {
      await Promise.all(state.contracts?.map(async (con) => {
        const conId = con.id === 'new' ? createdNewContractId : con.id
        const originalCon = employeeDetail.contracts.find(c => c.id === conId)
        const originalKeys = (originalCon?.positions || []).map((pos) => pos.positionId)
        const editedKeys = (con.positions || []).map((pos) => pos.positionId)

        // update changed positions (skill & productionForecast)
        if (con.id !== 'new') {
          for (const p of (con.positions || [])) {
            const originalConPos = (originalCon.positions || []).find(op => op.positionId === p.positionId)
            if (originalConPos && miscUtil.safeStringify(p) !== miscUtil.safeStringify(originalConPos)) {
              const res = await requestPositionAssignUpdate({
                workspace: workspaceId,
                user: userId,
                contract: conId,
                position: p.positionId,
                skill: p.skill ? parseInt(p.skill) : null,
                productionForecast: p.productionForecast ? parseFloat(p.productionForecast) : null
              }, auth)
              if (res?.error) {
                resList.push(res.error)
              }
            }
          }
        }

        const toAdd = editedKeys.filter((key) => !originalKeys.includes(key))
        const toRemove = con.id === 'new'
          ? []
          : [...toAdd, ...originalKeys].filter((key) => !editedKeys.includes(key))

        // add new positions
        for (const pos of toAdd) {
          await dispatch(assignPosition(userId, pos, conId, false))
        }

        // remove deleted positions
        for (const pos of toRemove) {
          await dispatch(unassignPosition(userId, pos, conId))
        }
      }))
      loadPositions()
    }
    const handleAcRoleAssignment = async () => {
      const assign = state.form.accessRoles.toAssign
      const unAssign = state.form.accessRoles.toUnAssign
      let problems = false
      if (unAssign.length) {
        const deleteRes = await fetchRequest({
          query: AcRoleAssignDelete,
          variables: {
            workspace: workspaceId,
            roles: unAssign.map((item) => ({
              roleId: item.id,
              userId: userId
            }))
          }
        })
        if (deleteRes?.errors) problems = true
      }
      if (assign.length && !problems) {
        await fetchRequest({
          query: AcRoleAssign,
          variables: {
            workspace: workspaceId,
            roles: assign.map((item) => ({
              roleId: item.id,
              userId: userId
            }))
          }
        })
      }
    }

    const handleLocalitiesUpdate = async () => {
      const originalKeys = employeeDetail.localities.map((pos) => pos.id)
      const editedKeys = state.localities.map((pos) => pos.id)
      const toAdd = editedKeys.filter((key) => !originalKeys.includes(key))
      const toRemove = [...toAdd, ...originalKeys].filter((key) => !editedKeys.includes(key))
      for (const loc of toAdd) {
        await dispatch(assignLocality(userId, loc))
      }
      for (const loc of toRemove) {
        await dispatch(unassignLocality(userId, loc))
      }
    }

    // START THE UPDATE

    // update/create user attributes
    let newlyCreatedAttribute = null
    if (newAttribute && newAttribute.name) {
      newlyCreatedAttribute = await createAttribute(newAttribute)
    }
    await updateAttributes(newlyCreatedAttribute)

    // update role
    if (hasSection('role')) {
      const updRole = { ...state.form.role }
      if (newlyCreatedAttribute) {
        const newIdx = updRole?.customAttributes.findIndex(att => att.attributeId === 'new') || -1
        if (newIdx !== -1) updRole.customAttributes[newIdx].attributeId = newlyCreatedAttribute.id
      }
      await updateRole(updRole)
        .then((res) => {
          if (res?.error) resList.push(res)
        })
      await handleAcRoleAssignment()
    }

    // update user
    if (hasSection('data')) {
      await requestUserUpdate({
        id: userId,
        data: state.form.data
      }, auth).then((res) => {
        if (res?.error) resList.push(res)
      })
      if (state.form?.data.language !== locale) dispatch(setLocale(state.form?.data.language))
    }

    // update contracts
    let createdNewContractId = null
    if (newContract) {
      createdNewContractId = await handleCreateContract(newContract)
    }
    if (hasSection('contracts')) {
      await handleUpdateContract()
      await handlePositionsUpdate(createdNewContractId)
      await handleLocalitiesUpdate()
    }
    return resList
  }
}
