import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const mapStateToProps = ({ employees, positions, workspaces, workspaceId, me }) => {
  const ws = workspaces.find(w => w.id === workspaceId)
  return {
    myId: me?.id,
    employees,
    positions,
    unavailabilityCategories: ws ? ws.unavailabilityCategories : []
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    // ...
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
