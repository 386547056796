import store from '../store'
import isLoading from './is-loading'
import requestRoleContractCreate from '../request/role-contract-create'
import addError from '../ac/add-error'

export default (user, data) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return

    dispatch(isLoading('create-contract'))

    const result = await requestRoleContractCreate({
      workspace: workspaceId,
      user,
      contractData: data
    }, auth)

    if (result.error) {
      dispatch(addError(result.error))
    }
    dispatch(isLoading('create-contract', true))

    return result
  }
}
