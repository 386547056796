import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { createArea, setModal } from '@app/ac'

const mapStateToProps = ({
  loading,
  organizationId,
  organization
}) => {
  return {
    loading,
    organizationId,
    countries: organization.countries || []
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    close: () => setModal(null),
    createArea
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
