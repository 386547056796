import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation RoleContractUpdateMutation (
  $workspace: ID!,
  $user: ID!,
  $contract: ID!,
  $contractData: RoleContractInput!
) {
  roleContractUpdate (
    workspace: $workspace
    user: $user
    id: $contract
    data: $contractData
  ) {
    id
  }
}`

async function requestRoleContractUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('role-contract-update', errors)
  }
  return data.roleContractUpdate
}

export default requestRoleContractUpdate
