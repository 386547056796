import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './GhostItem.style.scss'

export const GhostItem = ({ big }) => (
  <div className='ghost-item'>
    {big && <div className='head' />}
    <div className={classNames('ghost-body ', { big: 'big' })}>
      <div className='body1' />
      <div className='body2' />
    </div>
  </div>
)

GhostItem.propTypes = { big: PropTypes.bool }
GhostItem.defaultProps = { big: false }
