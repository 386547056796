import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation LocalityAssignMutation (
  $workspace: ID!,
  $locality: ID!,
  $user: ID!
) {
  localityAssign (
    workspace: $workspace
    locality: $locality
    user: $user
  ) {
    id
  }
}`

async function requestLocalityAssign (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('locality-assign', errors)
  }
  return data.localityAssign
}

export default requestLocalityAssign
