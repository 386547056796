import isLoading from './is-loading'
import setCycleDetail from '../action/set-cycle-detail'
import store from '../store'

export default (calendar) => {
  return async (dispatch) => {
    const { cycleDetail } = store.getState()

    if (!calendar) return
    dispatch(isLoading('set-cycle-detail-calendar'))
    await dispatch(setCycleDetail({ calendar: calendar, data: cycleDetail.data, lastSavedCycleShift: cycleDetail.lastSavedCycleShift }))

    dispatch(isLoading('set-cycle-detail-calendar', true))
  }
}
