import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query EmployeeWarningsQuery (
  $workspaceId: ID!
  $users: [ID!]!
) {
  workspace (
    id: $workspaceId
  ) {
    employeeWarnings (
      users: $users
    ) {
      userId
      userName
      warnings ${q.EMPLOYEE_WARNING}
      workspaceId
    }
  }
}`

async function requestEmployeeWarnings (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('employees', errors)
  }
  return data.workspace.employeeWarnings
}

export default requestEmployeeWarnings
