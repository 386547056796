import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

async function requestShiftCoworkers (variables, token, includeCandidatesAndExchanges = true) {
  const query = `query ShiftCoworkersQuery (
    $shift: ID!
  ) {
    shift (
      id: $shift
      uncommitted: true
    ) {
      coworkers {
        user {
          id
          name
          firstName
          lastName
          email
        }
        shifts {
          period {
            start
            end
          }
        }
      }
    }
  }`

  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-coworkers', errors)
  }
  return data.shift.coworkers
}

export default requestShiftCoworkers
