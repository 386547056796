import isLoading from './is-loading'
import addError from '../ac/add-error'
import setEmployees from '../action/set-employees'
import loadEmployees from '../ac/load-employees'
import store from '../store'
import requestEmployeesExternal from '@app/request/employees-external'
import requestWorkspaceRolesArchived from '@app/request/workspace/roles-archived'
import {
  calendarUtil,
  permissionsUtil
} from '@app/util'
import moment from 'moment'
import { PERMISSIONS } from '@app/const'

export default ({
  // when calling this function, one must pass either 'loadForEvents' or 'loadForPeriod'. if we pass both, we'll first load for the period and then also for the events.
  loadForEvents, // array of events (shifts or timeOffs) assigned to unknown people, who we need to load
  loadForPeriod // period in which there are some shifts of unknown people (this DOESN'T load unknown people with only timeOffs and no shifts!)
}) => {
  return async (dispatch) => {
    if ((!loadForEvents && !loadForPeriod) || (loadForEvents && !loadForEvents?.length) || (loadForPeriod && (!moment(loadForPeriod?.start).isValid() || !moment(loadForPeriod?.start).isValid()))) {
      throw new Error('The loadExternalEmployees() function must be called with either valid, non-empty loadForEvents or loadForPeriod prop.')
    }

    const { auth, workspaceId, organization } = store.getState()
    let { employees } = store.getState()

    // to load workspace.externalMembers we either need to have read access to emps on the WS, or we must have correct permissions
    // in the workspace's organization (when we're some ORG manager, looking at several workspaces in which we have no Role in DS PRO)
    if (!permissionsUtil.canRead(PERMISSIONS.WORKSPACE.EMPLOYEES) && (!organization || !organization.allAllowedWorkspaces || !organization.allAllowedWorkspaces.includes(workspaceId))) {
      return []
    }

    // make sure we have the non-external employees loaded first - otherwise, we could
    // desync and overwrite non-external emps with the result of the external emps query
    if (!employees || Object.keys(employees).length === 0) {
      employees = await dispatch(loadEmployees())
    }

    // prepare the period for the 1st request
    const period = loadForPeriod || calendarUtil.getShortestPeriodCoveringEvents(loadForEvents)

    // 1) load external employees for the period from API request workspace.externalMembers
    // NOTE: this only returns people who have some shift in that period. timeOffs are not considered.
    await dispatch(isLoading('load-external-employees'))
    const result1 = await requestEmployeesExternal({ id: workspaceId, period }, auth)

    if (result1.error) {
      dispatch(addError(result1.error))
      dispatch(isLoading('load-external-employees', true))
      return
    }

    const loadedEmployees = result1.externalMembers.map((u) => {
      let name = ''
      if (u.firstName) name += u.firstName
      if (u.lastName) {
        if (name !== '') name += ' '
        name += u.lastName
      }
      if (name === '') name = u.email

      // return the store object representing this employee
      return {
        external: true,
        id: u.userId,
        email: u.email,
        telephone: u.telephone,
        firstName: u.firstName,
        lastName: u.lastName,
        name
      }
    })

    // 2) if we're loading for events (loadForEvents) and there are still some events
    // of unknown people after the previous load, we load the archived roles via workspace.roles() API request
    if (loadForEvents) {
      employees = store.getState().employees
      const eventsOfStillUnknownPeople = loadForEvents.filter(evt => {
        return (evt.userId && !loadedEmployees.some(lemp => lemp.id === evt.userId) && !Object.keys(employees).includes(evt.userId))
      })
      if (eventsOfStillUnknownPeople.length) {
        const result2 = await requestWorkspaceRolesArchived({
          workspaceId,
          users: [...new Set(eventsOfStillUnknownPeople.map(evt => evt.userId))]
        }, auth)
        if (result2.error) {
          dispatch(addError(result2.error))
          dispatch(isLoading('load-external-employees', true))
          return
        }
        if (result2?.length) {
          result2.forEach(u => {
            const fn = u.customData?.firstName || u.user?.firstName
            const ln = u.customData?.lastName || u.user?.lastName
            let name = ''
            if (fn) name += fn
            if (ln) {
              if (name !== '') name += ' '
              name += ln
            }
            if (name === '') name = u.user?.email

            loadedEmployees.push({
              external: true,
              id: u.userId,
              email: u.user.email,
              telephone: u.customData?.telephone || u.user?.telephone,
              firstName: fn,
              lastName: ln,
              name
            })
          })
        }
      }
    }

    // merge loaded employees with the ones we already had in store (this prevents
    // the deletion of some extra employee data loaded asynchronously by other requests,
    // such as employee warnings)
    const finalEmps = Object.assign({}, employees)
    loadedEmployees.forEach((ee) => {
      if (finalEmps[ee.id]) {
        const eeWithoutExternalFlag = Object.assign({}, ee)
        delete eeWithoutExternalFlag.external // don't flag existing employees as external

        finalEmps[ee.id] = Object.assign({}, finalEmps[ee.id], eeWithoutExternalFlag)
      } else {
        finalEmps[ee.id] = ee
      }
    })

    // save merged employee data to store
    await dispatch(setEmployees(finalEmps))
    await dispatch(isLoading('load-external-employees', true))

    return loadedEmployees
  }
}
