import React from 'react'
import PropTypes from 'prop-types'

import { colorUtil } from '@app/util'

import './info-box.scss'

const SIZES = Object.freeze({
  SMALL: 'small',
  LARGE: 'large'
})
const SIZE_MAPPER = Object.freeze({
  small: 32,
  large: 40
})

export const ProgressCircle = ({ progress, colorfulProgress, size }) => {
  const sizeAsNumber = SIZE_MAPPER[size]
  const strokeWidth = 2
  const normalizedRadius = (sizeAsNumber / 2) - strokeWidth
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - Math.min(progress, 100) / 100 * circumference

  const progressColor = colorfulProgress ? colorUtil.getProgressColor(progress) : '#0045FF'

  return (
    <div className='progress'>
      <svg
        height={sizeAsNumber}
        width={sizeAsNumber}
      >
        <circle
          stroke='#D5D5D9'
          fill='transparent'
          strokeWidth={strokeWidth}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={sizeAsNumber / 2}
          cy={sizeAsNumber / 2}
        />
        <circle
          stroke={progressColor}
          fill='transparent'
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={sizeAsNumber / 2}
          cy={sizeAsNumber / 2}
        />
      </svg>
      <div className={`progress-value ${size}`}>
        {progress}%
      </div>
    </div>
  )
}

ProgressCircle.propTypes = {
  progress: PropTypes.number.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)).isRequired,
  colorfulProgress: PropTypes.bool
}
ProgressCircle.defaultProps = {
  progress: 0,
  colorfulProgress: false
}
