import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation RemoveAttribute($workspaceId: ID!, $id: ID!) {
    userAttributeRemove(workspace: $workspaceId, id: $id)
}`

async function deleteUserAttributes (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors } = response
  if (errors) {
    return errorUtil.apiError('', errors)
  }
  return response.userAttributeRemove
}

export default deleteUserAttributes
