import { Icon } from '@ui'

import { timeUtil, useAccessRole } from '@app/util'
import Schedule from '@app/component/pages/schedule'
import { PERMISSIONS, PERMISSIONS_PRO } from '@app/const/permissions-pool'

export const useRoutingConfig = ({
  auth,
  isPluginEnabled,
  setModal,
  workspaceId,
  isOrgHidden,
  isOrgOwner,
  subscription,
  workspace,
  me
}) => {
  const { canRead, orgCanAccess } = useAccessRole()
  const isPaidOrTrial = subscription?.paid || subscription?.trial

  const isAuthed = !!auth
  const myLocalities = workspace?.localities?.filter(l => l?.assigns?.some(ass => ass.userId === me?.id))
  const limitedToOneLocality = workspace?.enforceLocalities && myLocalities.length === 1

  const onPluginClick = (plugin) => {
    setModal('plugin-detail', { plugin: { plugin } })
  }

  const attendanceModal = !isPluginEnabled('attendance') ? () => onPluginClick('attendance') : null
  const reportsModal = !isPluginEnabled('reports') ? () => onPluginClick('reports') : null
  const laborlawModal = !isPluginEnabled('laborlaw') ? () => onPluginClick('laborlaw') : null
  const offersModal = !isPluginEnabled('offers') ? () => onPluginClick('offers') : null
  const apikeysModal = !isPluginEnabled('apikeys') ? () => onPluginClick('apikeys') : null
  const mfaModal = !isPluginEnabled('mfa') ? () => onPluginClick('mfa') : null
  const dayswapsproModal = !isPluginEnabled('dayswapspro') ? () => onPluginClick('dayswapspro') : null

  return ({
    app: [
      {
        allowed: isPaidOrTrial && canRead(PERMISSIONS.CALENDAR),
        component: Schedule,
        link: '/schedule/month/' + timeUtil.getInitialCalendarDate(),
        path: '/schedule',
        label: 'CALENDAR',
        containerConfig: {
          noScroll: true,
          noAnimation: true
        },
        ico: Icon.ICONS.calendar
      },
      {
        allowed: isPaidOrTrial && canRead(PERMISSIONS.CALENDAR),
        component: Schedule,
        path: '/schedule/:view/:date',
        containerConfig: {
          noScroll: true,
          noAnimation: true
        }
      },
      {
        allowed: isPaidOrTrial && canRead(PERMISSIONS.WORKSPACE),
        path: '/workspace',
        label: 'WORKSPACE',
        ico: Icon.ICONS.building,
        subpages: [
          {
            groupTitle: 'SUBNAV_SECTION_STRUCTURE',
            allowed: canRead(PERMISSIONS.WORKSPACE.EMPLOYEES),
            componentPath: 'workspace/employees',
            path: '/employees',
            label: 'PEOPLES',
            ico: Icon.ICONS.people
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.CUSTOMATTRIBUTES),
            componentPath: 'workspace/custom-attributes',
            path: '/custom-attributes',
            label: 'CUSTOM_ATTRIBUTES',
            ico: Icon.ICONS.badge
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.POSITIONS),
            componentPath: 'workspace/positions',
            path: '/job-positions',
            label: 'POSITIONS',
            ico: Icon.ICONS.positions
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.LOCALITIES) && isPluginEnabled('localities'),
            componentPath: 'workspace/localities',
            path: '/localities',
            label: 'LOCALITIES',
            ico: Icon.ICONS.people
          },
          {
            groupTitle: 'SUBNAV_SECTION_PLANNING',
            allowed: canRead(PERMISSIONS.WORKSPACE.TIMEOFFCATEGORIES),
            componentPath: 'workspace/timeoff-categories',
            path: '/timeoff-categories',
            label: 'WS_SETTINGS_CARD_UNAV_CATEGORIES_NAV',
            ico: Icon.ICONS.absence
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.SHIFTTEMPLATES),
            componentPath: 'workspace/shift-templates',
            path: '/shift-templates',
            label: 'SHIFT_TEMPLATES',
            ico: Icon.ICONS.template
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.SHIFTTEMPLATES),
            componentPath: 'workspace/day-templates/editor',
            path: '/day-templates/:action/:id'
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.SHIFTTEMPLATES),
            componentPath: 'workspace/day-templates/editor',
            path: '/day-templates/:action'
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.DAYTEMPLATES),
            componentPath: 'workspace/day-templates',
            path: '/day-templates',
            label: 'DAY_TEMPLATES',
            ico: Icon.ICONS.template2
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.CYCLES),
            componentPath: 'workspace/cycles/editor',
            path: '/cycles/:action/:id'
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.CYCLES),
            componentPath: 'workspace/cycles/editor',
            path: '/cycles/:action'
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.CYCLES),
            componentPath: 'workspace/cycles',
            path: '/cycles',
            label: 'CYCLES',
            ico: Icon.ICONS.cycle
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.SHIFTATTRIBUTES),
            componentPath: 'settings/attributes',
            path: '/attributes',
            label: 'ATTRIBUTES_NAV_NAME',
            ico: Icon.ICONS.badge
          },
          {
            allowed: canRead(PERMISSIONS.WORKSPACE.GROUPS),
            componentPath: 'workspace/groups',
            path: '/groups',
            label: 'GROUPS_TITLE',
            ico: Icon.ICONS.covid
          }
        ]
      },
      {
        allowed: isPaidOrTrial && canRead(PERMISSIONS.BACKOFFICE),
        link: isPluginEnabled('attendance') ? '/backoffice/attendance' : '/backoffice/unavailability-requests',
        path: '/backoffice',
        label: 'BACKOFFICE',
        ico: Icon.ICONS.computer,
        subpages: [
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.ATTENDANCE),
            disabled: !isPluginEnabled('attendance'),
            componentPath: 'backoffice/attendance',
            path: '/attendance',
            label: 'ATTENDANCE',
            ico: Icon.ICONS.attendance,
            onClick: attendanceModal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.BONUSES),
            disabled: !isPluginEnabled('attendance'),
            componentPath: 'backoffice/bonuses',
            path: '/bonuses',
            label: 'BONUSES',
            ico: Icon.ICONS.cash,
            onClick: attendanceModal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.TERMINALS) && isPluginEnabled('terminal'),
            componentPath: 'backoffice/terminals',
            path: '/terminals',
            label: 'TERMINAL',
            ico: Icon.ICONS.terminal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS),
            groupTitle: 'BACKOFFICE_REQUESTS',
            componentPath: 'backoffice/unavailability-requests',
            path: '/unavailability-requests',
            label: 'BACKOFFICE_UNAVAILABILITY_REQUESTS',
            ico: Icon.ICONS.absence
          },
          {
            groupTitle: 'REPORTS',
            allowed: canRead(PERMISSIONS.BACKOFFICE.REPORT_EMPLOYEE),
            disabled: !isPluginEnabled('reports'),
            componentPath: 'backoffice/reports/employee',
            path: '/reports/emp',
            label: 'EMPLOYEES',
            ico: Icon.ICONS.person,
            onClick: reportsModal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.REPORT_WORKSPACE) && !limitedToOneLocality,
            disabled: !isPluginEnabled('reports'),
            componentPath: 'backoffice/reports/workspace',
            path: '/reports/ws',
            label: 'WORKSPACE',
            ico: Icon.ICONS.home,
            onClick: reportsModal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.REPORT_WARNINGS),
            disabled: !isPluginEnabled('laborlaw'),
            componentPath: 'backoffice/reports/warnings',
            path: '/reports/warnings',
            label: 'WARNINGS_TITLE',
            ico: Icon.ICONS.law,
            onClick: laborlawModal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.REPORT_OVERTIME),
            disabled: !isPluginEnabled('reports'),
            componentPath: 'backoffice/reports/overtime',
            path: '/reports/overtime',
            label: 'REPORTS_OVERTIME',
            ico: Icon.ICONS.star,
            onClick: reportsModal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.REPORT_CONTACTS),
            disabled: !isPluginEnabled('reports'),
            componentPath: 'backoffice/reports/covid',
            path: '/reports/coworker-contacts',
            label: 'COVID_REPORT_TITLE_SHORT',
            ico: Icon.ICONS.covid,
            onClick: reportsModal
          },
          {
            allowed: canRead(PERMISSIONS.BACKOFFICE.REPORT_REVENUE),
            disabled: !isPluginEnabled('dayswapspro') || !isPluginEnabled('reports'),
            componentPath: 'backoffice/reports/revenue',
            path: '/reports/revenue',
            label: 'ORG_SP_WORKSPACES_PERFORMANCE',
            ico: Icon.ICONS.performance,
            onClick: !isPluginEnabled('dayswapspro') ? dayswapsproModal : reportsModal
          }
        ]
      },
      {
        allowed: isPaidOrTrial && canRead(PERMISSIONS.COMMUNICATION) && (
          (isPluginEnabled('communication') && (canRead(PERMISSIONS.EMP_CALENDAR) || canRead(PERMISSIONS.EMP_DASHBOARD) || canRead(PERMISSIONS.MNG_CALENDAR))) || (isPluginEnabled('chat') && me?.chatToken)
        ),
        link: isPluginEnabled('chat') && me?.chatToken && canRead(PERMISSIONS.COMMUNICATION.CHAT) ? '/communication/chat' : '/communication/news-feed',
        path: '/communication',
        label: 'COMMUNICATION',
        ico: Icon.ICONS.chat,
        subpages: [
          {
            allowed: (canRead(PERMISSIONS.COMMUNICATION.CHAT) && isPluginEnabled('chat') && Boolean(me?.chatToken)),
            componentPath: 'communication/chat',
            path: '/chat/:channelId'
          },
          {
            allowed: (canRead(PERMISSIONS.COMMUNICATION.CHAT) && isPluginEnabled('chat') && Boolean(me?.chatToken)),
            componentPath: 'communication/chat',
            path: '/chat',
            label: 'CHAT',
            ico: Icon.ICONS.chat
          },
          {
            allowed: canRead(PERMISSIONS.COMMUNICATION.NEWS_FEED) && (
              (isPluginEnabled('communication') && (canRead(PERMISSIONS.EMP_CALENDAR) || canRead(PERMISSIONS.EMP_DASHBOARD) || canRead(PERMISSIONS.MNG_CALENDAR)))
            ),
            componentPath: 'communication/news-feed',
            path: '/news-feed',
            label: 'NEWS_FEED',
            ico: Icon.ICONS.comment
          }]
      },
      {
        allowed: isPaidOrTrial && canRead(PERMISSIONS.SETTINGS),
        link: '/settings/basic',
        path: '/settings',
        label: 'SETTINGS',
        atBottom: true,
        ico: Icon.ICONS.settings,
        subpages: [
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSBASIC),
            componentPath: 'settings/basic',
            path: '/basic',
            label: 'WS_SETTINGS_CARD_BASIC_SETTINGS_NAV',
            ico: Icon.ICONS.settingsFull
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSBASIC),
            componentPath: 'settings/permissions/Permissions',
            path: '/permissions',
            label: 'PERMISSIONS',
            ico: Icon.ICONS.settings2
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSBASIC),
            componentPath: 'settings/mfa',
            path: '/mfa',
            label: 'MFA',
            disabled: !isPluginEnabled('mfa'),
            onClick: !isPluginEnabled('mfa') ? mfaModal : null,
            ico: Icon.ICONS.lock
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSNOTIFICATIONS),
            componentPath: 'settings/notifications',
            path: '/notifications',
            label: 'NOTIFICATION',
            ico: Icon.ICONS.bell
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSEMPLOYEES),
            componentPath: 'settings/employee',
            path: '/employee-preferences',
            label: 'EMPLOYEES',
            ico: Icon.ICONS.person
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSOFFERS),
            disabled: !isPluginEnabled('offers'),
            componentPath: 'settings/offers',
            path: '/contest-settings',
            label: 'OFFERS',
            ico: Icon.ICONS.offer,
            onClick: !isPluginEnabled('offers') ? offersModal : null
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSCAPACITIES) && isPluginEnabled('capacityplanning'),
            componentPath: 'settings/capacities',
            path: '/capacities',
            label: 'CAPACITY',
            ico: Icon.ICONS.capacity
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.SETTINGSATTENDANCETERMINALS),
            disabled: (!isPluginEnabled('attendance') && !isPluginEnabled('terminal')),
            componentPath: 'settings/AttendanceAndTerminals',
            path: '/attendance-and-terminals',
            label: 'WS_SETTINGS_ATTENDANCE_AND_TERMINALS',
            ico: Icon.ICONS.attendance,
            onClick: (!isPluginEnabled('attendance') && !isPluginEnabled('terminal')) ? attendanceModal : null
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.APIKEYS),
            disabled: !isPluginEnabled('apikeys'),
            componentPath: 'settings/apikeys',
            path: '/api-keys',
            label: 'WS_SETTINGS_APIKEYS_HEADER',
            ico: Icon.ICONS.api,
            onClick: !isPluginEnabled('apikeys') ? apikeysModal : null
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.AUDITLOGS),
            componentPath: 'settings/AuditLog',
            path: '/audit-log',
            label: 'WS_SETTINGS_AUDIT_LOG',
            ico: Icon.ICONS.law
          },
          {
            groupTitle: 'WS_SETTINGS_CARD_PLUGINS_SETTINGS',
            allowed: canRead(PERMISSIONS.SETTINGS.PLUGINS),
            componentPath: 'settings/plugin-settings',
            path: '/all',
            label: 'ORG_SP_SETTINGS_MODULES_ALL',
            ico: Icon.ICONS.properties
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.PLUGINS),
            componentPath: 'settings/plugin-settings',
            path: '/internal',
            label: 'DAYSWAPS',
            ico: Icon.ICONS.dayswaps
          },
          {
            allowed: canRead(PERMISSIONS.SETTINGS.PLUGINS),
            componentPath: 'settings/plugin-settings',
            path: '/external',
            label: 'ORG_SP_SETTINGS_MODULES_EXTERNAL',
            ico: Icon.ICONS.external
          }
        ]
      },
      {
        allowed: canRead(PERMISSIONS.EMP_CALENDAR) && !!workspaceId,
        componentPath: 'my-timetable',
        path: '/my-timetable',
        label: 'MY_EMP_CALENDAR',
        ico: Icon.ICONS.clock,
        containerConfig: {
          noPadding: true
        }
      },
      {
        allowed: isAuthed && !!workspaceId,
        componentPath: 'my-notification',
        path: '/my-notification',
        label: null
      },
      {
        allowed: canRead(PERMISSIONS.EMP_DASHBOARD) && !!workspaceId,
        componentPath: 'my-dashboard',
        path: '/dashboard-emp',
        label: 'MY_OVERVIEW',
        ico: Icon.ICONS.clipboard
      }
    ],
    pro: [
      {
        allowed: isAuthed && isPaidOrTrial && orgCanAccess(PERMISSIONS_PRO.WORKSPACES),
        path: '/workspaces',
        label: 'WORKSPACES',
        ico: Icon.ICONS.home,
        subpages: [
          {
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.PERFORMANCE),
            componentPath: 'organization/workspaces/Revenue',
            path: '/revenue',
            label: 'ORG_SP_WORKSPACES_PERFORMANCE',
            ico: Icon.ICONS.performance
          },
          {
            groupTitle: 'SUBNAV_SECTION_STRUCTURE',
            path: '/countries',
            componentPath: 'organization/workspaces/Countries',
            label: 'ORG_SP_WORKSPACES_COUNTRIES',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.COUNTRIES),
            ico: Icon.ICONS.country
          },
          {
            path: '/areas',
            componentPath: 'organization/workspaces/Areas',
            label: 'ORG_SP_WORKSPACES_AREAS',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.AREAS),
            ico: Icon.ICONS.area
          },
          {
            path: '/workplace',
            componentPath: 'organization/workspaces/Workspace',
            label: 'WORKSPACES',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.WORKSPACES),
            ico: Icon.ICONS.home
          },
          {
            path: '/positions',
            componentPath: 'organization/workspaces/Positions',
            label: 'POSITIONS',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.POSITIONS),
            ico: Icon.ICONS.positions
          },
          {
            groupTitle: 'SETTINGS',
            path: '/plan',
            componentPath: 'organization/workspaces/RevenuePlan',
            label: 'ORG_SP_WORKSPACES_PLAN_REVENUE',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.REVENUE),
            ico: Icon.ICONS.turnover
          },
          {
            path: '/norms',
            label: 'ORG_SP_WORKSPACES_NORMS',
            componentPath: 'organization/workspaces/Norms',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.NORMS),
            ico: Icon.ICONS.standards
          },
          {
            path: '/productivity',
            componentPath: 'organization/workspaces/Productivity',
            label: 'ORG_SP_WORKSPACES_PRODUCTIVITY',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.PRODUCTION),
            ico: Icon.ICONS.productivity
          },
          {
            path: '/fulltime-plan',
            componentPath: 'organization/workspaces/FulltimePlan',
            label: 'ORG_SP_WORKSPACES_FULLTIME_PLAN',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.PLANS),
            ico: Icon.ICONS.hoursDivision
          },
          {
            path: '/holidays',
            componentPath: 'organization/workspaces/Holidays',
            label: 'ORG_SP_WORKSPACES_HOLIDAYS',
            allowed: orgCanAccess(PERMISSIONS_PRO.WORKSPACES.HOLIDAYS),
            ico: Icon.ICONS.holidays
          }
        ]
      },
      {
        allowed: isAuthed && isPaidOrTrial && orgCanAccess(PERMISSIONS_PRO.TRANSFERS),
        path: '/transfers',
        label: 'SB_TRANSFERS',
        ico: Icon.ICONS.exchange,
        subpages: [
          {
            path: '/overview',
            label: 'ORG_SP_TRANSFERS_OVERVIEW',
            componentPath: 'organization/transfers/Overview',
            allowed: orgCanAccess(PERMISSIONS_PRO.TRANSFERS.OVERVIEW),
            ico: Icon.ICONS.overview
          },
          {
            path: '/details',
            label: 'SB_TRANSFERS',
            componentPath: 'organization/transfers/Details',
            allowed: orgCanAccess(PERMISSIONS_PRO.TRANSFERS.DETAILS),
            ico: Icon.ICONS.exchange
          },
          {
            groupTitle: 'SETTINGS',
            path: '/groups',
            componentPath: 'organization/transfers/Groups',
            label: 'GROUPS',
            allowed: orgCanAccess(PERMISSIONS_PRO.TRANSFERS.GROUPS),
            ico: Icon.ICONS.people
          }
        ]
      },
      {
        allowed: isAuthed && isPaidOrTrial && orgCanAccess(PERMISSIONS_PRO.PAYROLL),
        path: '/bonuses',
        label: 'ORG_SP_WAGES',
        ico: Icon.ICONS.cash,
        subpages: [
          {
            path: '/locked-until',
            label: 'APPROVED',
            componentPath: 'organization/bonuses/LockedUntil',
            allowed: (orgCanAccess(PERMISSIONS_PRO.PAYROLL.ATTENDANCE) || orgCanAccess(PERMISSIONS_PRO.PAYROLL.ATTENDANCE_LVL2) || orgCanAccess(PERMISSIONS_PRO.PAYROLL.ATTENDANCE_LVL3)),
            ico: Icon.ICONS.template
          },
          {
            path: '/attendance',
            label: 'ATTENDANCE',
            componentPath: 'organization/bonuses/attendance',
            allowed: (orgCanAccess(PERMISSIONS_PRO.PAYROLL.ATTENDANCE) || orgCanAccess(PERMISSIONS_PRO.PAYROLL.ATTENDANCE_LVL2) || orgCanAccess(PERMISSIONS_PRO.PAYROLL.ATTENDANCE_LVL3)),
            ico: Icon.ICONS.overview
          },
          {
            path: '/overview',
            label: 'BONUSES',
            componentPath: 'organization/bonuses/Overview',
            allowed: orgCanAccess(PERMISSIONS_PRO.PAYROLL.BONUSES_OVERVIEW),
            ico: Icon.ICONS.overview
          },
          {
            groupTitle: 'SETTINGS',
            path: '/sale',
            label: 'ORG_BONUSES_SALE',
            componentPath: 'organization/bonuses/Sale',
            allowed: orgCanAccess(PERMISSIONS_PRO.PAYROLL.BONUSES_SALES),
            ico: Icon.ICONS.items
          },
          {
            path: '/meal-tickets',
            label: 'ORG_BONUSES_MEAL_TICKETS',
            componentPath: 'organization/bonuses/MealTickets',
            allowed: orgCanAccess(PERMISSIONS_PRO.PAYROLL.BONUSES_MEAL_VOUCHERS),
            ico: Icon.ICONS.items
          },
          {
            path: '/stocktaking',
            label: 'ORG_BONUSES_STOCKTAKING',
            componentPath: 'organization/bonuses/Stocktaking',
            allowed: orgCanAccess(PERMISSIONS_PRO.PAYROLL.BONUSES_INVENTORY),
            ico: Icon.ICONS.items
          }
        ]
      },
      {
        allowed: isAuthed && isPaidOrTrial && orgCanAccess(PERMISSIONS_PRO.REPORTS),
        path: '/reports',
        label: 'REPORTS',
        ico: Icon.ICONS.report,
        subpages: [
          {
            allowed: true,
            path: '/employee-report',
            label: 'EMPLOYEES',
            componentPath: 'organization/reports/EmployeeReport',
            ico: Icon.ICONS.person
          },
          {
            allowed: true,
            path: '/workspace-report',
            label: 'WORKSPACE',
            componentPath: 'organization/reports/WorkspaceReport',
            ico: Icon.ICONS.home
          },
          {
            allowed: true,
            path: '/overtime-report',
            label: 'REPORTS_OVERTIME',
            componentPath: 'backoffice/reports/overtime',
            ico: Icon.ICONS.star
          },
          {
            allowed: true,
            path: '/warnings-report',
            label: 'WARNINGS_TITLE',
            componentPath: 'backoffice/reports/warnings',
            ico: Icon.ICONS.law
          }
        ]
      },
      {
        allowed: isAuthed && isPaidOrTrial && (isOrgOwner || orgCanAccess(PERMISSIONS_PRO.PEOPLE)),
        path: '/people',
        label: 'ORG_SP_PEOPLE',
        ico: Icon.ICONS.people,
        subpages: [
          {
            allowed: true,
            path: '/country-manage',
            label: 'ORG_SP_COUNTRY_MANAGER',
            componentPath: 'organization/people/CountryManager',
            ico: Icon.ICONS.countryManager
          },
          {
            allowed: true,
            path: '/area-manage',
            label: 'ORG_SP_AREA_MANAGER',
            componentPath: 'organization/people/AreaManager',
            ico: Icon.ICONS.areaManager
          },
          {
            allowed: true,
            path: '/workspace-manage',
            label: 'ORG_SP_WORKSPACE_MANAGER',
            componentPath: 'organization/people/WorkspaceManager',
            ico: Icon.ICONS.shopManager
          },
          {
            allowed: true,
            path: '/accountant',
            label: 'ORG_SP_PEOPLE_ACCOUNTANT',
            componentPath: 'organization/people/Accountant',
            ico: Icon.ICONS.accountant
          },
          {
            allowed: isOrgOwner,
            groupTitle: 'SETTINGS',
            path: '/permissions',
            componentPath: 'organization/people/Permissions',
            label: 'ORG_SP_PEOPLE_PERMISSIONS',
            ico: Icon.ICONS.access
          },
          {
            allowed: isOrgOwner,
            path: '/admins',
            label: 'ORG_SP_PEOPLE_ADMINS',
            componentPath: 'organization/people/Admins',
            ico: Icon.ICONS.group
          }
        ]
      }
    ],
    additional: [
      {
        allowed: isOrgOwner || canRead(PERMISSIONS.SETTINGS),
        componentPath: 'paywall',
        path: '/paywall'
      },
      {
        allowed: true,
        componentPath: 'other/reset-password',
        path: '/reset-password',
        containerConfig: {
          hideNav: true,
          noPadding: true
        }
      },
      {
        allowed: true,
        componentPath: 'other/accept-invite',
        path: '/accept-invite',
        containerConfig: {
          noPadding: true,
          hideNav: true
        }
      },
      {
        allowed: true,
        componentPath: 'admin-redirect',
        path: '/auth/:token',
        containerConfig: {
          hideNav: true
        }
      },
      {
        allowed: true,
        componentPath: 'admin-redirect',
        path: '/ws/:wsId'
      },
      {
        allowed: true,
        componentPath: 'cycle-presets',
        path: '/cycle-presets',
        containerConfig: {
          hideNav: true
        }
      },
      {
        allowed: true,
        componentPath: 'other/registration',
        path: '/registration/:step',
        containerConfig: {
          hideNav: true,
          noPadding: true
        }
      },
      {
        allowed: true,
        componentPath: 'other/registration',
        path: '/registration',
        containerConfig: {
          hideNav: true,
          noPadding: true
        }
      },
      {
        allowed: auth,
        componentPath: 'no-workspace',
        path: '/no-workspace',
        containerConfig: {
          hideNav: false // this cannot be enabled, because we have some users with no WS, but with ORG roles and they need Navigation to get to DS PRO (or to manage their account)
        }
      },
      {
        allowed: true,
        componentPath: 'terminal',
        path: '/t',
        containerConfig: {
          hideNav: true,
          noPadding: true
        }
      },
      {
        allowed: true,
        componentPath: 'terminal',
        path: 't/:wsId/:terminalId',
        containerConfig: {
          hideNav: true,
          noPadding: true
        }
      }
    ]
  })
}
