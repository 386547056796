import React from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { gql } from '@apollo/client'

import { monthList } from '@app/util/mappers'
import { ApolloClientFetch } from '@app/util/apollo-client-fetch'
import { CALENDAR_EXPORT_TOKEN } from '@app/request/query'
import { Button, Modal, Select } from '@ui'

import connect from './connect'

class ExportUnavRequests extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedMonth: moment().format('YYYY-MM')
    }
  }

  render () {
    const { selectedMonth } = this.state
    const { setModal, isLoading, language, auth, workspaceId, close } =
      this.props

    return (
      <Modal
        className='ds-export-unav-requests'
        size={Modal.SIZES.S}
        headerContent={
          <div className='ds-title'> {t('REQUEST_UNAV_MODAL_TITLE')}</div>
        }
        isLoading={isLoading}
        sections={[
          <Select
            key={0}
            label={t('REQUEST_UNAV_MODAL_MONTH')}
            options={[...monthList(t)]}
            value={selectedMonth}
            onChange={(v) => {
              this.setState((s) => Object.assign({}, s, { selectedMonth: v }))
            }}
          />
        ]}
        footerContent={
          <>
            <Button
              label={t('CLOSE')}
              onClick={() => {
                setModal(null)
              }}
            />
            <Button
              style={Button.STYLES.CONTAINED}
              label={t('REQUEST_UNAV_MODAL_SUBMIT')}
              disabled={isLoading}
              loading={isLoading}
              onClick={(e) => {
                const variables = {
                  format: 'pdf',
                  workspace: workspaceId,
                  type: 'grid',
                  pdfFromXlsx: true,
                  period: {
                    start: moment(selectedMonth, 'YYYY-MM')
                      .startOf('month')
                      .toISOString(),
                    end: moment(selectedMonth, 'YYYY-MM')
                      .endOf('month')
                      .toISOString()
                  },
                  language,
                  withPositions: false,
                  includeUnavailabilities: true,
                  includeShifts: false,
                  splitByLocality: false,
                  showEveryoneOnWs: false,
                  dayNotes: true,
                  shiftNotes: false
                }
                ApolloClientFetch(auth, 'CalendarExportTokenQuery')
                  .query({
                    query: gql`
                      ${CALENDAR_EXPORT_TOKEN}
                    `,
                    variables
                  })
                  .then((res) => {
                    const {
                      data: {
                        calendarExportToken: { url }
                      }
                    } = res
                    window.open(url, '_blank')
                    close()
                  })
              }}
            />
          </>
        }
      />
    )
  }
}

export default connect(ExportUnavRequests)
