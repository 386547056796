import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import moment from 'moment'
import {
  Table,
  Position,
  CategoryTag,
  Text
} from '@ui'
import { t } from 'i18next'

const RecordsList = ({
  myId,
  records,
  employees,
  positions,
  unavailabilityCategories
}) => {
  const tableConfig = [
    (records.some(r => (typeof r.userId !== typeof undefined || (r.id && r.email)))
      ? records.length === 1 && records[0].userId === myId
        ? null
        : {
          key: 'userId',
          tlKey: 'EMPLOYEE',
          sortable: records.length > 1,
          filterable: records.length > 1,
          rowToHTML: (row) => {
            if (!Object.keys(employees).includes((row.userId || row.id))) return null
            return employees[(row.userId || row.id)].name
          },
          rowToStr: (row) => {
            if (!Object.keys(employees).includes((row.userId || row.id))) return ''
            return employees[(row.userId || row.id)].name
          }
        } : null),
    (records.some(r => typeof r.period !== typeof undefined)
      ? {
        key: 'period',
        tlKey: 'WHEN',
        sortable: records.length > 1,
        filterable: records.length > 1,
        rowToHTML: (row) => {
          if (!row.period) return null
          return (
            <>
              {moment(row.period.start).format('ddd D.M.')}
              <br />
              {moment(row.period.start).format('HH:mm - ') + moment(row.period.end).format('HH:mm')}
            </>)
        },
        rowToStr: (row) => {
          if (!row.period) return ''
          return moment(row.period.start).format('D.M.YYYY HH:mm')
        }
      } : null),
    records.some(
      (r) =>
        typeof r.positions !== typeof undefined ||
          typeof r.positionId !== typeof undefined
    )
      ? {
        key: 'positions',
        tlKey: 'POSITION',
        sortable: records.length > 1,
        filterable: records.length > 1,
        rowToHTML: (row) => {
          if (row.categoryId || typeof row.available === 'boolean') return <Text>-</Text> // don't try rendering a position if this row is a timeOff/availability
          const isPositionId = typeof row.positionId !== typeof undefined
          if (isPositionId) {
            const pos = positions.find((p) => p.id === row.positionId)
            return pos ? <Position {...pos} /> : <Text>-</Text>
          }
          return (
            <>
              {row.positions?.map((upos) => {
                const pos = positions.find((p) => p.id === upos.id)
                return pos ? <Position {...pos} /> : <Text>-</Text>
              })}
            </>
          )
        },
        rowToStr: (row) => {
          if (row.categoryId || typeof row.available === 'boolean') return '-' // don't try rendering a position if this row is a timeOff/availability
          const isPositionId = typeof row.positionId !== typeof undefined
          if (isPositionId) {
            const pos = positions.find((p) => p.id === row.positionId)
            return pos ? pos.name : '-'
          }
          return row.positions
            ?.map((upos) => {
              const pos = positions.find((p) => p.id === upos.id)
              return pos.name
            })
            .join(', ')
        }
      }
      : null,
    (records.some(r => (typeof r.categoryId !== typeof undefined)) || (records.some(r => (typeof r.available === 'boolean' && r.available) && records.some(r => (typeof r.available === 'boolean' && !r.available))))
      ? {
        key: 'categoryId',
        tlKey: 'CATEGORY',
        sortable: records.length > 1,
        filterable: records.length > 1,
        rowToHTML: (row) => {
          if (row.positionId) return <Text>-</Text> // don't try rendering a category if this row is a shift
          const cat = unavailabilityCategories.find(c => c.id === row.categoryId)
          return <CategoryTag category={cat || { tag: t('UNAVAILABILITY' + (row.available ? '_AVAIL' : '')), title: t('UNAVAILABILITY' + (row.available ? '_AVAIL' : '')) }} />
        },
        rowToStr: (row) => {
          if (row.positionId) return '-' // don't try rendering a category if this row is a shift
          const cat = unavailabilityCategories.find(c => c.id === row.categoryId)
          return cat ? cat.title : t('UNAVAILABILITY' + (row.available ? '_AVAIL' : ''))
        }
      } : null),
    (records.some(r => !!r.recurrence)
      ? {
        key: 'period2',
        tlKey: 'RECURRENCE',
        sortable: records.length > 1,
        filterable: records.length > 1,
        rowToHTML: (row) => row.recurrence ? t('IS_REPEATING') : t('IS_NONREPEATING'),
        rowToStr: (row) => row.recurrence
      } : null),
    (records.some(r => !!r.transferDescription)
      ? {
        key: 'transferDescription',
        tlKey: 'MA_TRANSFER',
        sortable: records.length > 1,
        filterable: records.length > 1,
        rowToHTML: (r) => r.transferDescription,
        rowToStr: (r) => r.transferDescription
      } : null)
  ].filter(Boolean)

  return (
    <div className='confirm-record-list-wrapper'>
      <Table
        isNonResponsive
        maxHeight='22rem'
        type={Table.TYPES.FILL}
        tableName='confirmModalRecordsListTable'
        showSelection={false}
        tableConfig={tableConfig}
        rows={records}
        showActionBar={false}
        size={Table.SIZE.SLIM}
        pagination={false}
      />
    </div>)
}

RecordsList.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default connect(RecordsList)
