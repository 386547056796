"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TerminalCreate = void 0;
const react_1 = __importStar(require("react"));
const react_redux_1 = require("react-redux");
const _ui_1 = require("@ui");
const queries_1 = require("@app/queries");
const ac_1 = require("@app/ac");
const util_1 = require("@app/util");
const const_1 = require("@app/const");
const TerminalCreate = ({ workspaceId, currentTerminals, onClose }) => {
    const enabled = (0, util_1.useIsPluginEnabled)(const_1.pluginsConfig.NAMES.LOCALITIES);
    const localities = (0, react_redux_1.useSelector)(({ workspaces, workspaceId }) => (workspaces.find((ws) => ws.id === workspaceId).localities || []));
    const usedLocalities = currentTerminals.map((item) => item === null || item === void 0 ? void 0 : item.localityId);
    const availableLocalities = localities.filter((loc) => !usedLocalities.includes(loc.id));
    const [state, setState] = (0, react_1.useState)({
        locality: localities.length ? localities[0].id : null,
        pin: null,
        errorPin: false,
    });
    const dispatch = (0, react_redux_1.useDispatch)();
    const { mutation } = (0, util_1.useMutate)(queries_1.TerminalsCreate, {
        variables: {
            workspace: workspaceId,
            data: {
                pin: parseInt(state.pin),
                localityId: enabled ? (state.locality || undefined) : undefined,
            },
        },
        onCompleted: () => {
            onClose();
            dispatch((0, ac_1.setModal)(null));
        },
    });
    const locOptions = availableLocalities.map((loc) => ({
        label: react_1.default.createElement(_ui_1.Locality, { id: loc.id }),
        value: loc.id,
    }));
    locOptions.push({
        label: react_1.default.createElement(react_1.default.Fragment, null, (0, util_1.t)('DROPDOWN_NOT_SELECTED')),
        value: null,
    });
    const updatePinError = (v = state.pin) => {
        const regex = /^[0-9]{6}$/;
        if (!regex.test((v || '').toString())) {
            setState(Object.assign(Object.assign({}, state), { errorPin: true, pin: v }));
            return true;
        }
        else {
            setState(Object.assign(Object.assign({}, state), { errorPin: false, pin: v }));
            return false;
        }
    };
    return (react_1.default.createElement(_ui_1.Modal, { size: _ui_1.Modal.SIZES.S, headerContent: react_1.default.createElement(_ui_1.Text, { text: (0, util_1.t)('ADD_TERMINAL'), type: _ui_1.Text.TYPES.BODY_LARGE, weight: _ui_1.Text.WEIGHTS.BOLD }), footerContent: react_1.default.createElement(_ui_1.Flex, { stretch: true },
            react_1.default.createElement(_ui_1.Button, { onClick: () => dispatch((0, ac_1.setModal)(null)), size: _ui_1.Button.SIZES.FULL, label: (0, util_1.t)('CLOSE') }),
            react_1.default.createElement(_ui_1.Spacing, { size: _ui_1.Spacing.SIZES.SIZE_6, type: _ui_1.Spacing.TYPES.HORIZONTAL }),
            react_1.default.createElement(_ui_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    const hasError = yield updatePinError();
                    if (!hasError) {
                        yield mutation();
                    }
                    else {
                        util_1.notification.error({ code: null, message: 'TERMINAL_INVALID_ADMIN_PIN_FORMAT' });
                    }
                }), style: _ui_1.Button.STYLES.CONTAINED, size: _ui_1.Button.SIZES.FULL, label: (0, util_1.t)('SAVE'), bold: false })) },
        react_1.default.createElement(_ui_1.Spacing, { size: _ui_1.Spacing.SIZES.SIZE_16, type: _ui_1.Spacing.TYPES.BOTH },
            enabled && (react_1.default.createElement(_ui_1.Dropdown, { label: (0, util_1.t)('LOCALITY'), type: _ui_1.Dropdown.TYPES.VARIABLE, size: _ui_1.Dropdown.SIZES.FULL_WIDTH, style: _ui_1.Dropdown.STYLES.LIGHT, onChange: ({ value }) => setState(Object.assign(Object.assign({}, state), { locality: value })), options: locOptions, value: locOptions.find((loc) => loc.value === state.locality), singleSelect: true, cb: undefined })),
            react_1.default.createElement(_ui_1.Input, { label: (0, util_1.t)('PIN_CODE'), value: state.pin, size: _ui_1.Input.SIZES.FULL_WIDTH, type: _ui_1.Input.TYPES.NUMBER, onChange: (value) => {
                    updatePinError(value);
                }, hasError: state.errorPin, innerRef: undefined }))));
};
exports.TerminalCreate = TerminalCreate;
