import { bindActionCreators, compose } from 'redux'

import { setModal } from '@app/ac'
import { withPlugin } from '@core/hoc/withPlugin'

import { connect } from 'react-redux'

const mapStateToProps = ({ modal }) => {
  return {
    modal
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    close: () => setModal(null)
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
