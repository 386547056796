"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMutate = void 0;
const client_1 = require("@apollo/client");
/**
 * @argument T - The type of the data returned by the query
 * @argument V - The type of the variables used in the query
 */
const useMutate = (query, { variables, onError = () => { }, onCompleted = () => { }, }) => {
    const [mutation, { data, error, loading }] = (0, client_1.useMutation)(query, {
        variables,
        onError,
        onCompleted,
    });
    return {
        mutation,
        data,
        error,
        loading,
    };
};
exports.useMutate = useMutate;
