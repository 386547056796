import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import { t } from 'i18next'

import miscUtil from '@app/util/misc'
import requestShiftAssignments from '@app/request/shift-assignments'
import {
  Button,
  RadioGroup,
  Text,
  Modal,
  Flex,
  Spacing,
  HelpTooltip,
  Loader,
  PeriodSelect
} from '@ui'

import { Auction } from './Auction'
import { Autoplanning } from './Autoplanning'
import { Cycles } from './Cycles'

import connect from './connect'
import { calendarUtil } from '@app/util'

export const Planning = connect((
  {
    setCalendarMultiSelect,
    setModal,
    setSidebar,
    shifts,
    workspace,
    unavailabilities,
    positions,
    workspaceId,
    loadedPeriods,
    auth,
    createPlan,
    createCyclesPlan,
    isPluginEnabled
  }) => {
  const [selectedPeriod, setSelectedPeriod] = useState({ start: moment().add(1, 'month').startOf('month'), end: moment().add(1, 'month').endOf('month') })
  const [isInvalidEnd, setIsInvalidEnd] = useState(false)
  const [planning, setPlanning] = useState(null)
  const [planningShifts, setPlanningShifts] = useState(shifts ? [...shifts] : null)
  // Autoplaning state
  const [autoPlanningLoc, setAutoPlanningLoc] = useState('no')
  // Cycles state
  const [selectedCycles, setSelectedCycles] = useState([])
  const [isFromUnassigned, setFromUnassigned] = useState(false)
  const [isProcessingAndCannotClose, setIsProcessingAndCannotClose] = useState(false) // either false or a progress percentage - if it's not false, the modal displays progress and cannot be closed

  useEffect(() => {
    handleSections()
  }, [miscUtil.safeStringify(selectedPeriod)])

  useEffect(() => {
    let startIsBetween = false
    let endIsBetween = false

    loadedPeriods.forEach((period) => {
      if (moment(selectedPeriod.start).isBetween(moment(period.start).format('YYYY-MM-DD'), moment(period.end).format('YYYY-MM-DD'))) {
        startIsBetween = true
      }
      if (moment(selectedPeriod.end).isBetween(moment(period.start).format('YYYY-MM-DD'), moment(period.end).format('YYYY-MM-DD'))) {
        endIsBetween = true
      }
    })

    if (!startIsBetween || !endIsBetween) {
      let date = null
      if (!startIsBetween && endIsBetween) {
        date = {
          start: moment(selectedPeriod.start).startOf('month'),
          end: moment(selectedPeriod.start).endOf('month')
        }
      }
      if (startIsBetween && !endIsBetween) {
        date = {
          start: moment(selectedPeriod.end).startOf('month'),
          end: moment(selectedPeriod.end).endOf('month')
        }
      }
      if (!startIsBetween && !endIsBetween) {
        date = {
          start: moment(selectedPeriod.start).startOf('month'),
          end: moment(selectedPeriod.end).endOf('month')
        }
      }
      requestShiftAssignments({
        workspaceId,
        period: date
      }, auth).then((res) => {
        const filteredRes = Array.isArray(res) ? res.filter(a => moment(a.period.start).isAfter(date.start.startOf('day')) && a.userId === null && (!a.offer || a.offer.status === 'resolved')) : []
        setPlanningShifts([...planningShifts, ...filteredRes])
      })
    }
  }, [miscUtil.safeStringify(selectedPeriod.start.format('M')), miscUtil.safeStringify(selectedPeriod.end.format('M'))])

  const RADIO_OPT = {
    cycle: t('CYCLES'),
    auction: t('PLAN_SETUP_MODAL_RADIO_AUCTION'),
    auto: t('PLAN_SETUP_MODAL_RADIO_AUTO')
  }
  // const isCyclesOnWS = !!workspace?.cycles?.length
  const isForbiddenToday = planning === RADIO_OPT.auto || planning === RADIO_OPT.auction
  const isInvalidStart = isForbiddenToday && moment().startOf('day').isSameOrAfter(moment(selectedPeriod.start))

  let validShifts = []
  let isPlanningDisabled = false
  let withoutPosition = false

  if (!positions || !positions.find(p => !p.archived)) {
    withoutPosition = true
  }

  if (!isPlanningDisabled) {
    validShifts = planningShifts.filter(s =>
      !s.userId &&
      !s.hasOffer &&
      s.period && moment(s.period.start).startOf('day').isSameOrAfter(moment(selectedPeriod.start).startOf('day')) &&
      moment(s.period.end).startOf('day').isSameOrBefore(moment(selectedPeriod.end).startOf('day'))
    )
    let filteredShifts = validShifts?.map(a => { return { id: a.id, date: moment(a.period.start).format('YYYY-MM-DD') } })
    filteredShifts = filteredShifts?.map(a => a.id).filter((val, ind, s) => s.indexOf(val) === ind)
    validShifts = filteredShifts?.map(fil => validShifts.find(val => fil === val.id))

    if (!planningShifts || validShifts.length === 0) {
      isPlanningDisabled = true
    }
  }

  const sections = [
    <Fragment key={0}>
      <Flex direction={Flex.DIRECTION.COLUMN}>
        <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('PERIOD')}</Text>
        <Spacing size={Spacing.SIZES.SIZE_6} />
        <div style={{ width: '16.5rem' }}>
          <PeriodSelect
            forbiddenToday={isForbiddenToday}
            forbiddenPast
            style={{ width: '16.5rem' }}
            forbiddenTooltipText={t('PLAN_SETUP_MODAL_FORBIDDEN_DAY')}
            value={selectedPeriod}
            onChange={(period) => {
              const periodObj = {
                start: moment(period.start.format('YYYY-MM-DD'))
              }
              if (period.end) {
                periodObj.end = moment(period.end.format('YYYY-MM-DD'))
              }
              setSelectedPeriod({ ...selectedPeriod, ...periodObj })
              setIsInvalidEnd(!moment(period.end).isValid())
            }}
          />
        </div>
        <Spacing size={Spacing.SIZES.SIZE_16} />
        <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('PLAN_SETUP_MODAL_PLAN_TYPE')}</Text>
        <Spacing size={Spacing.SIZES.SIZE_10} />
        <Flex align={Flex.POSITION.CENTER}>
          <RadioGroup
            options={[
              {
                value: RADIO_OPT.cycle,
                label: t('CYCLES')
              },
              (isPluginEnabled('offers') ? ({
                value: RADIO_OPT.auction,
                label: t('PLAN_SETUP_MODAL_RADIO_AUCTION')
              }) : null),
              {
                value: RADIO_OPT.auto,
                label: t('PLAN_SETUP_MODAL_RADIO_AUTO')
              }
            ].filter(Boolean)}
            onChange={(val) => setPlanning(val)}
            value={planning}
          />
          <Spacing size={Spacing.SIZES.SIZE_4} />
        </Flex>
      </Flex>
    </Fragment>
  ]

  const filterShiftsByLoc = () => {
    let shiftsByLoc = [...validShifts]
    if (autoPlanningLoc !== 'any') {
      if (autoPlanningLoc === 'no') {
        shiftsByLoc = validShifts.filter(a => !a.localityId)
      } else {
        shiftsByLoc = validShifts.filter(a => a.localityId === autoPlanningLoc)
      }
    }
    return shiftsByLoc
  }

  const isSubmitDisabled = () => {
    let disabled = true
    if (planning) {
      if (planning === RADIO_OPT.auction) {
        disabled = isInvalidStart || validShifts.length === 0 || isInvalidEnd
      }
      if (planning === RADIO_OPT.auto) {
        disabled = isInvalidStart || filterShiftsByLoc().length === 0 || isInvalidEnd || withoutPosition
      }
      if (planning === RADIO_OPT.cycle) {
        const withoutEmp = !!selectedCycles.find(cycle => cycle.assigns.length === 0)
        const emptyEmp = !!selectedCycles.find(cycle => cycle.assigns.find(ass => ass.id.includes('new')))
        disabled = selectedCycles.length === 0 || isInvalidEnd || withoutEmp || emptyEmp
      }
    }
    return disabled
  }

  const isCycleWithoutEmp = () => {
    const withoutEmp = []

    selectedCycles.forEach(cycle => {
      const cycleName = cycle?.assigns.length === 0 ? cycle.title : null
      if (cycleName) withoutEmp.push(cycleName)
    })

    return withoutEmp
  }

  const handleSections = () => {
    if (isProcessingAndCannotClose !== false) {
      return [<Loader key='-1' position={Loader.POSITIONS.STATIC} text={isProcessingAndCannotClose + '%'} />]
    }

    if (planning === RADIO_OPT.cycle) {
      sections.push(
        <Fragment key={1}>
          <Spacing size={Spacing.SIZES.SIZE_8} />
          <Cycles
            selectedCycles={selectedCycles}
            isWithoutEmp={isCycleWithoutEmp()}
            isInvalidEnd={isInvalidEnd}
            isFromUnassigned={isFromUnassigned}
            cb={(val) => setSelectedCycles(val)}
            fromUnassigned={(val) => {
              setFromUnassigned(val)
            }}
          />
        </Fragment>
      )
    }
    if (planning === RADIO_OPT.auction) {
      sections.push(
        <Fragment key={1}>
          <Spacing size={Spacing.SIZES.SIZE_8} />
          <Auction
            withoutPosition={withoutPosition}
            isPlanningDisabled={isPlanningDisabled}
            isInvalidStart={isInvalidStart}
            isInvalidEnd={isInvalidEnd}
            validShifts={validShifts}
          />
        </Fragment>
      )
    }
    if (planning === RADIO_OPT.auto) {
      sections.push(
        <Fragment key={1}>
          <Spacing size={Spacing.SIZES.SIZE_8} />
          <Autoplanning
            locality={autoPlanningLoc}
            isPlanningDisabled={isPlanningDisabled}
            isInvalidStart={isInvalidStart}
            isInvalidEnd={isInvalidEnd}
            validShifts={filterShiftsByLoc()}
            cb={(val) => setAutoPlanningLoc(val)}
          />
        </Fragment>
      )
    }
    return sections
  }

  const handleSubmit = async () => {
    if (planning === RADIO_OPT.auction) {
      setModal('offers', {
        selectedShiftIds: validShifts.map(s => s.id),
        action: 'create',
        createType: 'drop'
      })
    }
    if (planning === RADIO_OPT.auto) {
      const shiftsByLoc = filterShiftsByLoc()
      let srcEvts = []
      if (shiftsByLoc.length) {
        srcEvts = srcEvts.concat(shiftsByLoc.map((evtData) => {
          return Object.assign({}, evtData, { day: moment(evtData.period.start).format('YYYY-MM-DD') })
        }))
        setCalendarMultiSelect({
          sourceEvents: srcEvts
        }).then(() => {
          createPlan({
            type: 'selected',
            data: {
              selectedShifts: srcEvts.map(evt => evt.id),
              period: calendarUtil.getShortestPeriodCoveringEvents(srcEvts)
              // nothing else is specified here, which makes the planner use defaults
            }
          })
          setSidebar('planning')
          setModal(null)
        })
      }
    }
    if (planning === RADIO_OPT.cycle) {
      setIsProcessingAndCannotClose(0)
      createCyclesPlan(
        selectedPeriod,
        selectedCycles.flatMap(c => c.id),
        selectedCycles.flatMap(c => c.assigns.flatMap(ass => ({
          userId: ass.id,
          cycleId: ass.cycleId,
          groupId: ass.cycleGroup
        }))),
        false,
        isFromUnassigned,
        selectedCycles.flatMap(c => c.overrides),
        (daysPlannedSoFar, daysToPlan) => {
          // this callback is called after each processed batch - and we use it to update the progress percentage
          setIsProcessingAndCannotClose(Math.round(daysPlannedSoFar / daysToPlan * 100))
        }
      ).then(res => {
        setIsProcessingAndCannotClose(false)
        setModal(null)
      })
    }
  }

  return (
    <Modal
      noClose={isProcessingAndCannotClose !== false}
      headerContent={
        <>
          <div className='ds-title'>
            {t('PLAN_SETUP_MODAL_LABEL')}
          </div>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
          <HelpTooltip
            text={!isFromUnassigned ? t('PLAN_SETUP_MODAL_TOOLTIP') : t('PLAN_SETUP_MODAL_TOOLTIP_CYCLE_UNASSIGNED')}
            link='https://help.dayswaps.com/'
            linkText='help.dayswaps.com'
          />
        </>
      }
      size={Modal.SIZES.L}
      sections={handleSections()}
      footerContent={
        isProcessingAndCannotClose === false &&
          <>
            <Button
              label={t('CLOSE')}
              onClick={() => setModal(null)}
              size={Button.SIZES.LARGE}
            />
            <Button
              label={t('PLANNING_BTN_LABEL')}
              disabled={isSubmitDisabled()}
              onClick={() => {
                handleSubmit()
              }}
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
            />
          </>
      }
    />
  )
})
