import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { t } from 'i18next'
import classNames from 'classnames'

import { useFloatable } from '@app/util'
import { Icon } from '@ui'

import './style.scss'

export const YearSelect = ({
  onChange,
  value,
  label,
  forbiddenPast,
  forbiddenFuture,
  forbiddenToday,
  fullWidth = false
}) => {
  const floatable = useFloatable()
  const [state, setState] = useState({
    startYear: value || moment(),
    years: []
  })
  useEffect(() => {
    const localYears = []
    for (let i = 4; i > 0; i--) {
      localYears.push(moment(state.startYear).subtract(i, 'year'))
    }
    localYears.push(state.startYear)
    for (let i = 1; i < 8; i++) {
      localYears.push(moment(state.startYear).add(i, 'year'))
    }
    setState({
      ...state,
      years: localYears
    })
  }, [state.startYear])
  return (
    <div className={classNames('year-select', { fullWidth })}>
      {label ? <div className='ys-label'>{label}</div> : null}
      <div className='input' {...floatable.floatAnchor}>
        {value ? moment(value).format('YYYY') : t('CHOOSE')}
      </div>
      {floatable.open && (
        <div className='calendar' {...floatable.floatContainer}>
          <div className='header'>
            <Icon
              ico={Icon.ICONS.arrowLeft}
              onClick={() => setState({
                ...state,
                startYear: moment(state.startYear).subtract(12, 'year')
              }
              )}
            />
            <div>
              {moment(state.years[0]).format('YYYY')}
              {' - '}
              {moment(state.years[11]).format('YYYY')}
            </div>
            <Icon
              ico={Icon.ICONS.arrowRight}
              onClick={() => setState({
                ...state,
                startYear: moment(state.startYear).add(12, 'year')
              }
              )}
            />
          </div>
          <div className='content'>
            {state.years.map((year) => {
              let isDisabled = false
              if (forbiddenPast && moment(year).isBefore(moment(), 'year')) isDisabled = true
              if (forbiddenFuture && moment(year).isAfter(moment(), 'year')) isDisabled = true
              if (forbiddenToday && moment(year).isSame(moment(), 'year')) isDisabled = true
              return (
                <div
                  className={classNames(
                    'item',
                    { selected: moment(year).format('YYYY') === moment(value).format('YYYY') },
                    { disabled: isDisabled }
                  )}
                  key={year}
                  onClick={() => {
                    if (!isDisabled) {
                      onChange(year)
                      floatable.setOpen(false)
                    }
                  }}
                >
                  {moment(year).format('YYYY')}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const PropTypesDate = PropTypes.object

YearSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypesDate]),
  label: PropTypes.string,
  forbiddenPast: PropTypes.bool,
  forbiddenFuture: PropTypes.bool,
  forbiddenToday: PropTypes.bool,
  fullWidth: PropTypes.bool
}
YearSelect.defaultProps = {
  value: null,
  label: null,
  forbiddenPast: false,
  forbiddenFuture: false,
  forbiddenToday: false,
  fullWidth: false
}
