import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import loadShifts from '@app/ac/load-shifts'

const mapStateToProps = (state) => {
  const { workspaces, workspaceId, organization, auth } = state
  return {
    workspaces,
    workspaceId,
    dsProEnabled: !(organization?.hidden),
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadWorkspaceDetail,
    loadShifts
  }, dispatch)
}

export default (component) => {
  return compose(connect(mapStateToProps, mapDispatchToProps))(component)
}
