import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `mutation ShiftUpdateMultiMutation (
  $workspace: ID!,
  $shifts: [ID!]!,
  $data: ShiftUpdateData!
) {
  shiftUpdateMulti (
    workspace: $workspace
    ids: $shifts
    data: $data
  ) ${q.SHIFT_BASIC}
}`

async function requestShiftUpdateMulti (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-update-multi', errors)
  }
  return data.shiftUpdateMulti
}

export default requestShiftUpdateMulti
