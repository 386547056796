import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation PositionAssignMutation (
  $workspace: ID!,
  $user: ID!,
  $position: ID!
  $contract: ID!
) {
  positionAssign (
    workspace: $workspace
    user: $user
    position: $position
    contract: $contract
  ) {
    positionId
  }
}`

async function requestPositionAssign (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('position-assign', errors)
  }
  return data.positionAssign
}

export default requestPositionAssign
