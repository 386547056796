import store from '../store'
import moment from 'moment'
import {
  requestShiftDeleteMulti,
  requestShiftUpdate,
  requestTimeOffDeleteMulti
} from '@app/request'
import requestShiftCreateMulti from '@app/request/shift-create-multi'
import { setModal } from '@app/ac'
import { miscUtil, notification } from '@app/util'

export default (
  conflictingShifts,
  resultTimeOffs,
  actionType
) => {
  const isExactlyOneWholeDayTimeOff = Array.isArray(resultTimeOffs) && resultTimeOffs.length === 1 && moment(resultTimeOffs[0]?.period?.end).diff(resultTimeOffs[0]?.period?.start, 'hours') === 24

  return async (dispatch) => {
    const { auth, workspaceId, workspaces } = store.getState()
    const workspace = workspaces.find((ws) => ws.id === workspaceId)

    // (START) SHORTENING SHIFTS
    if (actionType === 'short-shift') {
      // first merge periods of the TimeOffs, so that we can reliably determine which shifts are fully covered by TimeOffs (and should be deleted).
      // if we didn't use merged periods, one shift might be covered by 2 timeOffs together, and we wouldn't know it's actually covered.
      const mergedResultTimeOffPeriods = miscUtil.getMergedEventPeriods(resultTimeOffs)

      // [1] get all the shifts that are completely covered by timeOffs (these will be deleted)
      const shiftsCoveredByTimeOffs = conflictingShifts.filter((shift) => mergedResultTimeOffPeriods.some((timeOffPeriod) => {
        if (isExactlyOneWholeDayTimeOff) {
          // specail case requested by users: if exactly one whole-day (24 hours long) timeOff is being added (via right-click on shift -> add TimeOff), delete all the shifts from the same day that
          // overlap it, even if they're not fully covered (i.e. night shifts that cover midnight should be deleted, not shortened, when user adds a whole-day timeOff over them)
          return (moment(shift.period.start).isSameOrAfter(timeOffPeriod.start) || moment(shift.period.end).isSameOrBefore(timeOffPeriod.end)) && moment(timeOffPeriod.start).clone().add(5, 'minutes').isSame(shift.period.start, 'day')
        } else {
          return moment(shift.period.start).isSameOrAfter(timeOffPeriod.start) && moment(shift.period.end).isSameOrBefore(timeOffPeriod.end)
        }
      }))

      // [2] get all the shifts with only start covered by a timeOff (their beginning will be cut off)
      const remainingShifts = conflictingShifts.filter((shift) => !shiftsCoveredByTimeOffs.some((item) => shift.id === item.id))
      const shiftsWithConflictingStart = remainingShifts.map((shift) => {
        const shiftContractType = workspace.contractTypes.find((contractType) => contractType.id === shift.contractType)
        const completeShiftTime = shift.stats.reduce((ac, current) => ac + current.workMinutes, 0)

        const match = resultTimeOffs.find((timeOff) => (
          (moment(shift.period.start).isAfter(timeOff.period.start) || moment(shift.period.start).isSame(timeOff.period.start)) &&
          (moment(timeOff.period.end).isBetween(shift.period.start, shift.period.end))
        ))
        let newMatch = { ...match }
        let pauseDuration = 0
        if (shift.pausesFixed && completeShiftTime >= 2 * (shiftContractType?.rules?.pauses?.afterWorkTime || 0)) {
          pauseDuration = shift.pauses.reduce((sum, pause) => sum + pause.duration, 0)
          const newPeriod = {
            start: newMatch?.period?.start,
            end: moment(newMatch?.period?.end).add(pauseDuration / 2, 'minutes').format()
          }
          newMatch = {
            ...newMatch,
            workMinutesBy: 'custom',
            workMinutes: moment.duration(moment(newPeriod.end).diff(moment(newPeriod.start))).asMinutes(),
            period: newPeriod
          }
          delete newMatch.recurrence
        }
        return match ? ({
          shift,
          timeOff: newMatch,
          pauseDuration
        }) : null
      })

      // [3] get all the shifts with only end covered by a timeOff (their end will be cut off)
      const shiftWithConflictingEnd = remainingShifts.map((shift) => {
        let match = resultTimeOffs.find((timeOff) => (
          (moment(shift.period.end).isBefore(timeOff.period.end) || moment(shift.period.end).isSame(timeOff.period.end)) &&
          (moment(timeOff.period.start).isBetween(shift.period.start, shift.period.end))
        ))

        if (isExactlyOneWholeDayTimeOff) {
          // specail case requested by users: if exactly one whole-day (24 hours long) timeOff is being added (via right-click on shift -> add TimeOff), only touch the shifts from that day
          if (!(moment(resultTimeOffs[0]?.period?.start).clone().add(5, 'minutes').isSame(shift.period.start, 'day'))) match = false
        }

        return match ? ({
          shift,
          timeOff: match
        }) : null
      })

      // [4] get all the shifts that contain the whole timeOff (they will be divided into two)
      const shiftsContainingTimeoff = remainingShifts.map((shift) => {
        const match = resultTimeOffs.find((timeOff) => moment(shift.period.start).isBefore(timeOff.period.start) && moment(shift.period.end).isAfter(timeOff.period.end))
        return match ? ({
          shift,
          timeOff: match
        }) : null
      })

      // start preparing all the shift updates, creations and deletions
      const shiftsToDelete = shiftsCoveredByTimeOffs
      const shiftsToUpdate = []
      const shiftsToCreate = []

      // if shift's start is inside timeOff ([2]), move its start to timeOff's end
      shiftsWithConflictingStart.filter((item) => !!item).forEach(({ shift, timeOff }) => {
        shiftsToUpdate.push({
          ...shift,
          period: {
            start: timeOff.period.end,
            end: shift.period.end
          }
        })
      })

      // if shift's end is inside timeOff ([3]), move its end to timeOff's start
      shiftWithConflictingEnd.filter((item) => !!item).forEach(({ shift, timeOff }) => {
        shiftsToUpdate.push({
          ...shift,
          period: {
            ...shift.period,
            end: timeOff.period.start
          }
        })
      })

      // if shift covers entire timeOff ([4]), divide it into two shifts (update one and create one)
      shiftsContainingTimeoff.filter((item) => !!item).forEach(({ shift, timeOff }) => {
        shiftsToUpdate.push({
          ...shift,
          period: {
            ...shift.period,
            end: timeOff.period.start
          }
        })
        shiftsToCreate.push({
          ...shift,
          pausesFixed: false,
          pauses: [], // we cannot just copy the pauses off the original shift here, since they can end ou outside the new shift's period, which causes BE to return error
          period: {
            ...shift.period,
            start: timeOff.period.end
          }
        })
      })

      // execute all shift updates
      await Promise.all(shiftsToUpdate.map(async (shift) => {
        return await requestShiftUpdate({
          workspace: workspaceId,
          shift: shift.id,
          data: {
            period: shift.period,
            pausesFixed: shift.pausesFixed
          }
        }, auth)
      }))

      // execute all shift deletions
      if (shiftsToDelete.length) {
        await requestShiftDeleteMulti({
          workspace: workspaceId,
          shifts: shiftsToDelete.map((shift) => shift.id)
        }, auth)
      }

      // execute all shift creations
      if (shiftsToCreate.length) {
        const res = await requestShiftCreateMulti({
          workspace: workspaceId,
          items: shiftsToCreate.map((shift) => {
            return ({
              agenda: shift.agenda,
              contractType: shift.contractType,
              customAttributes: shift.customAttributes,
              idealSkill: shift.idealSkill,
              locality: shift.localityId,
              note: shift.note,
              pauses: shift.pauses,
              pausesFixed: shift.pausesFixed,
              period: shift.period,
              position: shift.positionId,
              overTime: shift.overTime,
              standByActivities: shift.standByActivities,
              standBy: shift.standBy,
              user: shift.userId
            })
          })
        }, auth)
        if (res.errors) {
          notification.success({ code: 'success' })
        }
      }

      // close the modal
      dispatch(setModal(null))
    }
    // (END) SHORTENING SHIFTS

    // (START) UNASSIGNING SHIFTS
    if (actionType === 'unassign-shift') {
      await Promise.all(conflictingShifts.map(async (shift) => {
        return await requestShiftUpdate({
          workspace: workspaceId,
          shift: shift.id,
          data: {
            user: null
          }
        }, auth)
      }))

      dispatch(setModal(null))
    }

    // (END) UNASSIGNING SHIFTS

    // (START) OFFERING SHIFTS
    if (actionType === 'offer-shift') {
      dispatch(setModal('offers', {
        selectedShiftIds: conflictingShifts.map(s => s.id),
        action: 'create',
        createType: 'drop',
        afterClose: () => {
          const timeOffsToDelete = resultTimeOffs
          requestTimeOffDeleteMulti({
            workspace: workspaceId,
            data: timeOffsToDelete.map((timeOff) => ({ id: timeOff.id, recurrenceRemoveFollowing: false }))
          }, auth)
        }
      }))
    }
    // (END) OFFERING SHIFTS

    // (START) DELETING TIMEOFFS
    if (actionType === 'delete-timeoffs') {
      const timeOffsToDelete = resultTimeOffs
      const res = await requestTimeOffDeleteMulti({
        workspace: workspaceId,
        data: timeOffsToDelete.map((timeOff) => ({ id: timeOff.id, recurrenceRemoveFollowing: false }))
      }, auth)
      dispatch(setModal(null))
      if (!res.errors) {
        notification.success({ code: 'success' })
      }
    }
    // (END) DELETING TIMEOFFS

    // (START) DOING NOTHING
    if (actionType === 'ignore') {
      dispatch(setModal(null))
      notification.success({ code: 'success' })
    }
    // (END) DOING NOTHING
  }
}
