"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unwrapMaybe = void 0;
const is_defined_1 = require("../is-defined");
const unwrapMaybe = (t) => {
    if ((0, is_defined_1.isNull)(t))
        return undefined;
    return t;
};
exports.unwrapMaybe = unwrapMaybe;
