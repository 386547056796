import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationWorkspaceManagers($filter: OrganizationFilter!, $organizationId: ID!) {
  organization(id: $organizationId) {
    workspaceManagers(filter: $filter) {
      id
      name
      email
      telephone
      workspaceId
      country {
        id
        code
      }
      area {
        id
        name
      }
      workspaceName
    }
  }
}`

async function requestOrganizationWorkspaceManagers (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization.workspaceManagers
}

export default requestOrganizationWorkspaceManagers
