import moment from 'moment'

import { notification } from '@app/util'

import store from '../store'
import { requestShiftUpdateMulti, requestShiftUpdate } from '../request/'
import addError from '../ac/add-error'
import setShifts from '../action/set-shifts'

import isLoading from './is-loading'

export default (shift, formUpdate, shiftsMulti, dontUpdateBeforeWSS) => {
  return async (dispatch) => {
    const { auth, workspaceId, shifts, workspaceEvents } = store.getState()
    if (!workspaceId || (!shift && !shiftsMulti)) return false
    const form = Object.assign({}, formUpdate)

    dispatch(isLoading('update-live-shift'))

    // prepare the update data for the request
    let usr
    if (form.userId || form.userId === null) {
      usr = form.userId
    }
    if (form.selId || form.selId === null) {
      usr = form.selId
    }
    if ((typeof form.idealSkill !== 'undefined') && !form.skill) form.skill = form.idealSkill
    if (form.skill) form.skill = parseInt(form.skill)

    const update = {
      userId: usr,
      period: form.period ? {
        start: form.period.start ? moment(form.period.start).format() : undefined,
        end: form.period.end ? moment(form.period.end).format() : undefined
      } : undefined,
      positionId: form.positionId,
      locality: form.localityId,
      idealSkill: form.skill,
      pauses: form.pauses,
      pausesFixed: form.pausesFixed,
      customAttributes: form.customAttributes,
      note: form.note,
      overTime: form.overTime,
      standBy: form.standBy,
      standByActivities: form.standByActivities,
      agenda: form.agenda,
      contractType: form.contractType,
      contractId: form.contractId,
      warnings: undefined
    }
    if (typeof update.userId === typeof undefined) delete update.userId
    if (typeof update.idealSkill === typeof undefined) delete update.idealSkill
    if (typeof update.period === typeof undefined) delete update.period
    if (typeof update.pauses === typeof undefined) delete update.pauses
    if (typeof update.pausesFixed === typeof undefined) delete update.pausesFixed
    if (typeof update.customAttributes === typeof undefined) delete update.customAttributes
    if (typeof update.note === typeof undefined) delete update.note
    if (typeof update.overTime === typeof undefined) delete update.overTime
    if (typeof update.standBy === typeof undefined) delete update.standBy
    if (typeof update.standByActivities === typeof undefined) delete update.standByActivities
    if (typeof update.locality === typeof undefined) delete update.locality
    if (typeof update.positionId === typeof undefined) delete update.positionId
    if (typeof update.contractType === typeof undefined) delete update.contractType
    if (typeof update.contractId === typeof undefined) delete update.contractId
    if (typeof update.agenda === typeof undefined) delete update.agenda

    // if we're removing user from the shift, also make sure we delete the warnings
    if (typeof update.userId === typeof null) update.warnings = []

    // Calendar look & feel:
    // We update the shift in the store right away, even before making
    // the API request, so the GUI feels fast. The shift will get updated
    // in store again with the real data from BE once we get the websocket message.
    // In case of error, we revert it back.
    let originalShiftData = shiftsMulti ? [] : {}
    if (!dontUpdateBeforeWSS) {
      dispatch(setShifts(
        shifts.map((sf) => {
          if (sf.id === shift || (shiftsMulti && shiftsMulti.includes(sf.id))) {
            if (shiftsMulti) {
              originalShiftData.push(Object.assign({}, sf))
            } else {
              originalShiftData = Object.assign({}, sf)
            }
            return Object.assign({}, sf, update)
          } else {
            return sf
          }
        })
      ))
    }

    // make the API request
    let result
    if (!shift && shiftsMulti?.length) {
      result = await requestShiftUpdateMulti({
        workspace: workspaceId,
        shifts: shiftsMulti,
        data: {
          user: update.userId,
          period: update.period,
          idealSkill: update.idealSkill,
          position: update.positionId,
          locality: update.locality,
          pauses: update.pauses,
          pausesFixed: update.pausesFixed,
          customAttributes: update.customAttributes,
          note: update.note,
          overTime: update.overTime,
          standBy: update.standBy,
          standByActivities: update.standByActivities,
          contractType: update.contractType,
          contractId: update.contractId,
          agenda: update.agenda
        }
      }, auth)
    } else {
      result = await requestShiftUpdate({
        workspace: workspaceId,
        shift,
        data: {
          user: update.userId,
          period: update.period,
          idealSkill: update.idealSkill,
          position: update.positionId,
          locality: update.locality,
          pauses: update.pauses,
          pausesFixed: update.pausesFixed,
          customAttributes: update.customAttributes,
          note: update.note,
          overTime: update.overTime,
          standBy: update.standBy,
          standByActivities: update.standByActivities,
          contractType: update.contractType,
          contractId: update.contractId,
          agenda: update.agenda
        }
      }, auth)
    }

    // revert the original shift state in store in case of error
    if (result && result.error) {
      dispatch(addError(result.error))

      if (!dontUpdateBeforeWSS) {
        await dispatch(setShifts(
          shifts.map((sf) => {
            if (sf.id === shift || (shiftsMulti && shiftsMulti.includes(sf.id))) {
              if (shiftsMulti) {
                const originalFromShiftData = originalShiftData.find(os => os.id === sf.id)
                return Object.assign({}, sf, originalFromShiftData || {})
              } else {
                return Object.assign({}, sf, originalShiftData)
              }
            } else {
              return sf
            }
          })
        ))
      }
    }

    // if the shift is put into closed day, warn the user
    if (result && !result.error && update.period) {
      const closedEv = workspaceEvents.find((ev) => ev.type === 'closed' && moment(update.period.start).isBefore(ev.period.end) && moment(update.period.end).isAfter(ev.period.start))
      if (closedEv) {
        notification.warn({ code: 'addedShiftInClosedDay' })
      }
    }

    dispatch(isLoading('update-live-shift', true))
    return result
  }
}
