import { requestOrganizationTransfersGroupUpdate, requestOrganizationTransfersGroupDelete, requestOrganizationTransfersGroupCreate } from '@app/request'
import { setOrg } from '@app/action'

import { addError, setModal } from '../ac'
import store from '../store'

import isLoading from './is-loading'

export const createTransferGroup = (form) => {
  return async (dispatch) => {
    const { auth, organization } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = (newItem) => ({
      ...oldOrg,
      transferGroups: [
        ...oldOrg.transferGroups,
        { id: newItem.id, name: newItem.name, workspaces: newItem.workspaces }
      ]
    })

    dispatch(setOrg(newOrg({ id: '1', name: form.data.name, workspaces: form.data.workspaces })))
    const result = await requestOrganizationTransfersGroupCreate(form, auth)
    dispatch(isLoading('org-transfer-groups-create'))
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(...oldOrg))
    } else {
      dispatch(setOrg(newOrg(result)))
      dispatch(setModal(null))
    }
    dispatch(isLoading('org-transfer-groups-create', true))
  }
}

export const updateTransferGroups = (form) => {
  return async (dispatch) => {
    const { auth, organization } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = (newItem) => ({
      ...oldOrg,
      transferGroups: organization.transferGroups.map((tg) => {
        if (tg.id === form.data.id) {
          return { id: newItem.id, name: newItem.name, workspaces: newItem.workspaces }
        } else {
          return tg
        }
      })
    }
    )

    dispatch(setOrg(newOrg({ id: '1', name: form.data.name, workspaces: form.data.workspaces })))
    const result = await requestOrganizationTransfersGroupUpdate(form, auth)
    dispatch(isLoading('org-transfer-groups-update'))
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(...oldOrg))
    } else {
      dispatch(setOrg(newOrg(result)))
      dispatch(setModal(null))
    }
    dispatch(isLoading('org-transfer-groups-update', true))
  }
}

export const deleteTransferGroups = (id) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = {
      ...oldOrg,
      transferGroups: organization.transferGroups.filter((tg) => tg.id !== id)
    }

    dispatch(setOrg(newOrg))
    const result = await requestOrganizationTransfersGroupDelete({ organization: organizationId, id: id }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    } else {
      dispatch(setModal(null))
    }
    dispatch(isLoading('org-transfer-groups-delete', true))
  }
}
