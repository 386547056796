import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `mutation ShiftCreateMutation (
  $workspace: ID!,
  $position: ID!,
  $locality: ID,
  $start: Datetime!,
  $end: Datetime!,
  $user: ID,
  $idealSkill: Int
  $pauses: [PauseData!],
  $pausesFixed: Boolean,
  $customAttributes: [CustomAttributeValueInput!],
  $note: String,
  $overTime: OverTimeInput,
  $standBy: Boolean
  $standByActivities: [StandByActivityInput!]
  $contractType: String
  $contractId: ID
  $agenda: [ShiftAgendaTaskInput!]
) {
  shiftCreate (
    workspace: $workspace
    data: {
      period: {
        start: $start
        end: $end
      }
      position: $position
      locality: $locality
      idealSkill: $idealSkill
      user: $user
      pauses: $pauses
      pausesFixed: $pausesFixed
      customAttributes: $customAttributes
      note: $note
      overTime: $overTime
      standBy: $standBy
      standByActivities: $standByActivities
      contractType: $contractType
      contractId: $contractId
      agenda: $agenda
    }
  ) ${q.SHIFT_BASIC}
}`

async function requestShiftCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-create', errors)
  }
  return data.shiftCreate
}

export default requestShiftCreate
