import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestShiftAttributeCreate from '../request/shift-attribute-create'
import requestShiftAttributeUpdate from '../request/shift-attribute-update'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'

export default (data, attributeId) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !data) return

    dispatch(isLoading('save-shift-attribute'))
    let result = null
    if (attributeId) {
      // if 'attributeId' is set, we're modifying the existing attribute
      result = await requestShiftAttributeUpdate({
        id: attributeId,
        workspace: workspaceId,
        name: data.name,
        shortName: data.shortName,
        description: data.description
      }, auth)
    } else {
      // otherwise, we're just creating a new attribute
      result = await requestShiftAttributeCreate({
        workspace: workspaceId,
        type: 'boolean',
        name: data.name,
        shortName: data.shortName,
        description: data.description
      }, auth)
    }

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('save-shift-attribute', true))
    return result || {}
  }
}
