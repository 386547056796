import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useRoutingConfig } from '@app/routing'
import loadMoreNotifications from '@app/ac/load-more-notifications'
import {
  Text,
  Spacing,
  NotificationCard,
  Flex
} from '@ui'
import connect from './connect'

import './notifications-infinite-list.scss'

function _NotificationsInfiniteList ({
  notifications,
  onOpenNotification,
  auth,
  isPluginEnabled,
  setModal,
  workspaceId,
  workspace,
  me,
  subscription,
  isOrgOwner,
  isOrgHidden
}) {
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const loading = useSelector(state => state.loading).includes('load-notifications')
  const dispatch = useDispatch()

  const routes = useRoutingConfig({
    auth,
    isPluginEnabled,
    setModal,
    workspaceId,
    workspace,
    me,
    subscription,
    isOrgHidden,
    isOrgOwner
  })

  const allAllowedRouteURIs = routes.additional.filter((item) => item.allowed).map(r => r.path)
  routes.app.forEach(r => {
    if (r.allowed && r.path) allAllowedRouteURIs.push(r.path)
    if (r.subpages?.length) {
      r.subpages.forEach(sp => {
        if (sp.allowed && sp.path) allAllowedRouteURIs.push(r.path.replace(/\/$/, '') + '/' + sp.path.replace(/^\//, ''))
      })
    }
  })

  const isAllowedURI = (uri) => {
    const uriToRegex = (pattern) => {
      const regexString = '^' + pattern
        .replace(/\/:([^/]+)/g, '(?:/[^/]+)?') // Replace each "/:param" with an optional "/[^/]+" match.
        .replace(/:[^/]+/g, '[^/]+') + '$' // Replace standalone ":param" with "[^/]+" to match segments.
      return new RegExp(regexString)
    }
    const [path] = uri.split('?')
    return allAllowedRouteURIs.some((allowed) => uriToRegex(allowed).test(path))
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: async () => {
      const hasNextPage = await dispatch(loadMoreNotifications())
      setHasNextPage(hasNextPage)
    },
    rootMargin: '0px 0px 400px 0px'
  })

  let currentDate

  return (
    <div ref={rootRef} className='ds-notifications-infinite-list'>
      {notifications.map((notification, index) => {
        const createdDate = moment(notification.created)

        const isNewDate =
          currentDate == null || moment(currentDate).isSame(createdDate, 'day') === false

        if (isNewDate) {
          currentDate = createdDate
        }

        return (
          <React.Fragment key={notification.id}>
            {isNewDate && (
              <>
                {index === 0 ? null : (
                  <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_16} />
                )}
                <Text
                  type={Text.TYPES.BODY_MEDIUM}
                  color={Text.COLORS.BLACK}
                  weight={Text.WEIGHTS.BOLD}
                >
                  {moment(notification.created).format('D.M. dddd')}
                </Text>
                <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_12} />
              </>
            )}
            <NotificationCard
              notification={notification}
              onOpen={
                (notification.message.uri && isAllowedURI(notification.message.uri))
                  ? () => onOpenNotification(notification)
                  : undefined
              }
            />
            <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8} />
          </React.Fragment>
        )
      })}
      <Flex justify='center' stretch>
        <div ref={sentryRef} />
      </Flex>
    </div>
  )
}

export const NotificationsInfiniteList = connect(_NotificationsInfiniteList)
