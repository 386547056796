import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation LocalityAssignDeleteMutation (
  $workspace: ID!,
  $locality: ID!,
  $user: ID!
) {
  localityAssignDelete (
    workspace: $workspace
    locality: $locality
    user: $user
  ) {
    id
  }
}`

async function requestLocalityUnassign (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('locality-unassign', errors)
  }
  return data.localityAssignDelete
}

export default requestLocalityUnassign
