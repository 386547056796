/* eslint-disable react/jsx-fragments */
import React from 'react'
import { t } from 'i18next'
import classNames from 'classnames/bind'

import {
  Loader,
  Button,
  Text,
  Icon
} from '@ui'
import Event from '@ui/calendar/common/event'
import { PERMISSIONS } from '@app/const'
import { permissionsUtil } from '@app/util'
import connect from './connect'
import styles from './index.scss'
const cx = classNames.bind(styles)

class ShiftTemplates extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      displayedPage: 0
    }
  }

  render () {
    const { labelWidthRem, workspace, cols, setModal, isLoading, style } = this.props
    const { displayedPage } = this.state
    const templates = (workspace.shiftTemplates && workspace.shiftTemplates.length) ? workspace.shiftTemplates : []

    // paging for template list
    const maxTemplatesPerPage = cols.length
    let displayedTemplates = templates.slice(displayedPage * maxTemplatesPerPage, (displayedPage + 1) * maxTemplatesPerPage)
    displayedTemplates = displayedTemplates.sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    })

    return (
      <div className={cx('ds-c-stmpl-row ds-c-row')} style={style}>
        {/* loader */}
        {isLoading ? <div className={cx('ds-c-stmpl-row-loader-overlay')}><Loader size={Loader.SIZES.SMALL} /></div> : null}

        {/* left panel with the row label */}
        <div
          className={cx('ds-c-row-label')}
          style={{
            width: (labelWidthRem).toString() + 'rem',
            minWidth: (labelWidthRem).toString() + 'rem',
            maxWidth: (labelWidthRem).toString() + 'rem'
          }}
        >
          <span>{t('CALENDAR_ROWS_TEMPLATES')}</span>
          {(templates.length > maxTemplatesPerPage) && (
            <div className='ds-c-stmpl-paging'>
              <div
                className={'ds-c-stmpl-paging-btn' + (((displayedPage + 1) * maxTemplatesPerPage >= templates.length) ? ' is-disabled' : '')}
                onClick={() => {
                  if ((displayedPage + 1) * maxTemplatesPerPage >= templates.length) return false
                  this.setState(s => Object.assign({}, s, { displayedPage: displayedPage + 1 }))
                }}
              >
                <svg viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4.26563 1.03125C4.125 0.890625 3.89063 0.890625 3.75 1.03125L0.703126 4.0625C0.562501 4.21875 0.5625 4.45312 0.703125 4.59375L1.0625 4.95312C1.20313 5.09375 1.4375 5.09375 1.59375 4.95312L4 2.54687L6.42188 4.95312C6.57813 5.09375 6.8125 5.09375 6.95313 4.95312L7.3125 4.59375C7.45313 4.45312 7.45313 4.21875 7.3125 4.0625L4.26563 1.03125Z' />
                </svg>
              </div>
              <div
                className={'ds-c-stmpl-paging-btn' + (displayedPage <= 0 ? ' is-disabled' : '')}
                onClick={() => {
                  if (displayedPage <= 0) return false
                  this.setState(s => Object.assign({}, s, { displayedPage: displayedPage - 1 }))
                }}
              >
                <svg viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M3.73438 4.96875C3.875 5.10938 4.10938 5.10938 4.25 4.96875L7.29688 1.9375C7.4375 1.78125 7.4375 1.54688 7.29688 1.40625L6.9375 1.04688C6.79688 0.90625 6.5625 0.90625 6.40625 1.04688L4 3.45312L1.57812 1.04688C1.42188 0.90625 1.1875 0.90625 1.04688 1.04688L0.6875 1.40625C0.546875 1.54688 0.546875 1.78125 0.6875 1.9375L3.73438 4.96875Z' />
                </svg>
              </div>
            </div>
          )}
        </div>

        {/* the list of templates displayed as Events */}
        {displayedTemplates.length
          ? displayedTemplates.map((tpl, idx) => {
            if (!tpl.shift) return null
            if (idx >= maxTemplatesPerPage) return null // only display limited number of templates

            return (
              <div
                key={tpl.id}
                className={cx('ds-c-col')}
                style={{
                  width: 'calc((100% - ' + (labelWidthRem).toString() + 'rem) / ' + (cols.length || 1).toString() + ')',
                  minWidth: 'calc((100% - ' + (labelWidthRem).toString() + 'rem) / ' + (cols.length || 1).toString() + ')',
                  maxWidth: 'calc((100% - ' + (labelWidthRem).toString() + 'rem) / ' + (cols.length || 1).toString() + ')'
                }}
              >
                <Event
                  type='shiftTemplate'
                  data={Object.assign({}, tpl.shift, {
                    id: tpl.id,
                    shiftTemplateName: tpl.name
                  })}
                  key={'tpl_' + tpl.id}
                  idx={idx}
                />
              </div>
            )
          })
          : <div className='ds-c-stmpl-no-templates'><Text type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.GRAY}>{t('TPL_ROW_NO_TEMPLATES')}</Text></div>}

        {/* button for adding a new template */}
        {(displayedTemplates.length < maxTemplatesPerPage && permissionsUtil.canWrite(PERMISSIONS.WORKSPACE.SHIFTTEMPLATES)) ? (
          <div
            className={cx('ds-c-col')}
            style={{
              width: 'calc((100% - ' + (labelWidthRem).toString() + 'rem) / ' + (cols.length || 1).toString() + ')',
              minWidth: 'calc((100% - ' + (labelWidthRem).toString() + 'rem) / ' + (cols.length || 1).toString() + ')',
              maxWidth: 'calc((100% - ' + (labelWidthRem).toString() + 'rem) / ' + (cols.length || 1).toString() + ')'
            }}
          >
            <div className={cx('ds-c-col-hover-btns')}>
              <Button
                onClick={(e) => {
                  setModal('extra-shift-template')
                }}
                ico={Icon.ICONS.plus}
              />
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(ShiftTemplates)
