"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstOrUndefined = void 0;
const util_1 = require("@app/util");
const getFirstOrUndefined = (arr) => arr.reduce((acc, curr) => {
    if ((0, util_1.isDefined)(acc))
        return acc;
    return curr;
}, undefined);
exports.getFirstOrUndefined = getFirstOrUndefined;
