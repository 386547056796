import {
  requestAreaCreate,
  requestAreaDelete,
  requestAreaUpdate,
  requestOrgCountries
} from '@app/request'
import { setOrg } from '@app/action'

import aT from '../const/action-type'
import { addError, setModal } from '../ac'
import store from '../store'

import isLoading from './is-loading'

export const createArea = (form) => {
  return async (dispatch) => {
    const { auth, organization } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = (newItem) => ({
      ...oldOrg,
      countries: oldOrg.countries.map((country) => {
        if (country.id === form.country) {
          return {
            ...country,
            areas: [
              ...country.areas,
              newItem
            ]
          }
        } else {
          return country
        }
      })
    })
    dispatch(setOrg(newOrg({ id: '1', workspaces: [], ...form })))
    dispatch(isLoading(aT.SET_WORKSPACES))
    const result = await requestAreaCreate(form, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    } else {
      dispatch(setOrg(newOrg(result.areaCreate)))
      dispatch(setModal(null))
    }
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}

export const deleteArea = (id) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = {
      ...oldOrg,
      countries: oldOrg.countries.map((country) => {
        const isTheOne = country.areas.some((area) => area.id === id)
        if (isTheOne) {
          return {
            ...country,
            areas: country.areas.filter((area) => area.id !== id)
          }
        } else {
          return country
        }
      })
    }

    dispatch(isLoading(aT.SET_WORKSPACES))
    dispatch(setOrg(newOrg))
    const result = await requestAreaDelete({ area: id, organization: organizationId }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    } else {
      dispatch(setModal(null))
    }
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}

export const updateArea = (form) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()

    dispatch(isLoading(aT.SET_WORKSPACES))
    const requestMap = form.map(async (item) => {
      return requestAreaUpdate({
        organization: organizationId,
        area: item.rowId,
        name: item.name
      }, auth)
    })
    Promise.all(requestMap)
      .catch(() => null)
      .finally(async () => {
        const newCountries = await requestOrgCountries({ organizationId }, auth)
        if (newCountries && newCountries.error) {
          dispatch(addError(newCountries.error))
        } else {
          dispatch(setOrg({
            ...organization,
            countries: newCountries.countries
          }))
        }
      })
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}
