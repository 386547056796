import React from 'react'
import moment from 'moment'
import { t } from 'i18next'
import {
  Flex,
  Link,
  Icon,
  Spacing,
  Text
} from '@ui'
import { miscUtil } from '@app/util'
import { STYLES } from '@app/const'

import './PaywallItemStyle.scss'

export const PaywallItem = ({ subscription, isOrg, isLoading }) => {
  const calculateSubscriptionDays = () => {
    const beforeLoadDate = '9999-09-09'
    const now = moment().format('YYYY-MM-DD')
    const expiration = moment(subscription?.expiration || beforeLoadDate)
    return expiration.diff(now, 'days')
  }

  const getSubscriptionNavLabel = () => {
    let color = STYLES.colors.primary
    let text = t('PAYWALL_DAYS_MIN_FOUR')

    if (subscription?.trial) {
      if (paywallExpiration > 2 && paywallExpiration <= 7) {
        color = STYLES.colors.orange
      }
      if (paywallExpiration <= 2) {
        color = STYLES.colors.red
      }
    }
    if (subscription?.trial === false && paywallExpiration < 4) {
      color = STYLES.colors.red
    }

    if (paywallExpiration <= 1) {
      text = t('PAYWALL_DAY')
    }
    if (paywallExpiration > 1 && paywallExpiration <= 4) {
      text = t('PAYWALL_DAYS_MAX_FOUR')
    }

    return {
      color: color,
      text: text
    }
  }

  const paywallExpiration = calculateSubscriptionDays()
  const paywallNav = getSubscriptionNavLabel()

  if (
    (subscription?.trial) ||
    (paywallExpiration <= 3 && !subscription?.paid)
  ) {
    return (
      <Flex justify={Flex.POSITION.CENTER}>
        <Link
          type={Link.TYPES.ON_CLICK}
          onClick={() => { if (!isLoading) miscUtil.openStripeUI() }}
        >
          <Spacing size={Spacing.SIZES.SIZE_4} />
          <div className='paywall-item' style={{ background: paywallNav.color }}>
            {isLoading
              ? <Icon ico={Icon.ICONS.loader} color={Icon.COLORS.WHITE} />
              : subscription?.trial && paywallExpiration > 0 ? (
                <Flex direction={Flex.DIRECTION.COLUMN} align={Flex.POSITION.CENTER}>
                  <Text
                    weight={Text.WEIGHTS.BOLD}
                    text={t('PAYWALL_TRIAL').toUpperCase()}
                    type={Text.TYPES.BODY_SMALL}
                    color={Text.COLORS.WHITE}
                  />
                  <Text
                    type={Text.TYPES.BODY_SMALL}
                    text={`${paywallExpiration} ${paywallNav.text}`}
                    color={Text.COLORS.WHITE}
                  />
                </Flex>
              ) : (
                <Text
                  type={Text.TYPES.BODY_SMALLEST}
                  align={Text.ALIGN.CENTER}
                  text={t('PAYWALL_PAYMENT_FAILED')}
                  color={Text.COLORS.WHITE}
                />
              )}
          </div>
        </Link>
      </Flex>
    )
  }
}

PaywallItem.propTypes = {}
PaywallItem.defaultProps = {}
