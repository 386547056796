import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation AreaMoveWorkspace($organization: ID!, $area: ID!, $workspace: ID!) {
  areaMoveWorkspace(organization: $organization, area: $area, workspace: $workspace) {
    id
  }
}`

async function requestWorkspaceAreaMove (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('workspace-update', errors)
  }
  return data.areaMoveWorkspace
}

export default requestWorkspaceAreaMove
