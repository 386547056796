import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query DummyShiftWarningsAndData ($workspaceId: ID!, $data: ShiftData!, $id: ID) {
  dummyShift(workspace: $workspaceId, data: $data, id: $id) {
    warnings ${q.EVENT_WARNING}
    pauses { start, duration }
    stats {
      ${q.SHIFT_STATS_BASIC}
    }
    contractId
  }
}`

async function requestShiftDummyWarningsAndData (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-dummy-warnings', errors)
  }
  return data.dummyShift
}

export default requestShiftDummyWarningsAndData
