import { addLoading, finishedLoading } from '../action'

export default (what, isFinished = false) => {
  return async (dispatch) => {
    if (isFinished) {
      return dispatch(finishedLoading(what))
    }
    return dispatch(addLoading(what))
  }
}
