import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import loadShiftDetail from '@app/ac/load-shift-detail'

const mapStateToProps = ({ loading, positions, workspaceId, auth }) => {
  return {
    workspaceId,
    positions,
    isLoading: loading.includes('create-offer'),
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadShiftDetail
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
