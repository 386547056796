import { fetchRequest, errorUtil } from '@app/util'

const query = `query FullTimePlans($filter: OrganizationFilter!, $year: Year!, $organizationId: ID!) {
  organization(id: $organizationId) {
    fullTimePlans(filter: $filter, year: $year) {
      date
      workspaceId
      amount
    }
  }
}`

async function requestOrgFullTimePlans (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    errorUtil.apiError('organization', errors)
    return []
  }
  return data.organization.fullTimePlans
}

export default requestOrgFullTimePlans
