"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatedUserEmail = exports.setUserVacation = exports.setRoleTerminateDate = exports.setRole = exports.updatedUserContracts = exports.updatedUserContract = exports.setUserEmployeeWarnings = exports.setUserTimeOffs = exports.setUserShifts = exports.setUserPositions = exports.setUserCustomData = exports.setUserCustomAttributes = exports.setUserContracts = exports.setUserRoleStats = exports.setUserTerminalPIN = exports.setEmployeeId = exports.setUserDetails = exports.unsetUser = void 0;
const util_1 = require("@app/util");
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {};
const userDetailSlice = (0, toolkit_1.createSlice)({
    name: 'userDetails',
    initialState,
    reducers: {
        unsetUser: () => initialState,
        setEmployeeId: (state, action) => {
            state.employeeId = action.payload;
        },
        setUserTerminalPIN: (state, action) => {
            state.terminalPIN = action.payload;
        },
        setUserDetails: (state, action) => {
            state.user = action.payload;
        },
        setUserRoleStats: (state, action) => {
            state.stats = action.payload;
        },
        setUserContracts: (state, action) => {
            state.contracts = action.payload;
        },
        updatedUserEmail: (state, action) => {
            state.user.email = action.payload;
        },
        updatedUserContract: (state, action) => {
            if ((0, util_1.isUndefined)(state.contracts))
                state.contracts = [];
            const index = state.contracts.findIndex((contract) => contract.id === action.payload.id);
            if (index !== -1) {
                state.contracts[index] = action.payload;
                return;
            }
            state.contracts.push(action.payload);
        },
        updatedUserContracts: (state, action) => {
            action.payload.forEach((contract) => {
                var _a;
                if ((0, util_1.isUndefined)(state.contracts) || (0, util_1.isNull)(action.payload))
                    return;
                const index = (_a = state.contracts) === null || _a === void 0 ? void 0 : _a.findIndex((c) => c.id === contract.id);
                if (index !== -1) {
                    state.contracts[index] = contract;
                    return;
                }
                state.contracts.push(contract);
            });
        },
        setUserPositions: (state, action) => {
            state.positions = action.payload;
        },
        setUserCustomAttributes: (state, action) => {
            state.customAttributes = action.payload;
        },
        setUserCustomData: (state, action) => {
            state.customData = action.payload;
        },
        setUserShifts: (state, action) => {
            state.shifts = action.payload;
        },
        setUserTimeOffs: (state, action) => {
            state.timeOffs = action.payload;
        },
        setUserEmployeeWarnings: (state, action) => {
            state.employeeWarnings = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setRoleTerminateDate: (state, action) => {
            state.role.terminateDate = action.payload;
        },
        setUserVacation: (state, action) => {
            state.vacation = action.payload;
        },
    },
});
_a = userDetailSlice.actions, exports.unsetUser = _a.unsetUser, exports.setUserDetails = _a.setUserDetails, exports.setEmployeeId = _a.setEmployeeId, exports.setUserTerminalPIN = _a.setUserTerminalPIN, exports.setUserRoleStats = _a.setUserRoleStats, exports.setUserContracts = _a.setUserContracts, exports.setUserCustomAttributes = _a.setUserCustomAttributes, exports.setUserCustomData = _a.setUserCustomData, exports.setUserPositions = _a.setUserPositions, exports.setUserShifts = _a.setUserShifts, exports.setUserTimeOffs = _a.setUserTimeOffs, exports.setUserEmployeeWarnings = _a.setUserEmployeeWarnings, exports.updatedUserContract = _a.updatedUserContract, exports.updatedUserContracts = _a.updatedUserContracts, exports.setRole = _a.setRole, exports.setRoleTerminateDate = _a.setRoleTerminateDate, exports.setUserVacation = _a.setUserVacation, exports.updatedUserEmail = _a.updatedUserEmail;
exports.default = userDetailSlice.reducer;
