import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query OffersWorkspaceQuery (
  $workspace: ID!
  $onlyUnresolved: Boolean,
  $period: PeriodFreeInput
) {
  workspace(
    id: $workspace
  ) {
    offers (onlyUnresolved: $onlyUnresolved, period: $period) ${q.OFFER}
  }
}`

async function requestOffersWorkspace (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('offers', errors)
  }
  return data.workspace.offers
}

export default requestOffersWorkspace
