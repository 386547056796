
import React, { Fragment } from 'react'
import moment from 'moment'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'
import classNames from 'classnames/bind'

import { ApolloClientFetch } from '@app/util/apollo-client-fetch'
import q, {
  ANNOUNCE_SHIFTS,
  REVERT_SHIFTS
} from '@app/request/query'
import {
  timeUtil,
  viewUtil,
  getUniqueChangesMapper,
  miscUtil,
  sortUtil
} from '@app/util'
import {
  GLOBAL_POSITION,
  GLOBAL_LOCALITY,
  GLOBAL_DATEPICKER,
  GLOBAL_SINGLE_PERSON,
  VALUES
} from '@app/const/globals'
import {
  Avatar,
  Button,
  Input,
  Label,
  Color,
  Text,
  Modal,
  Flex,
  Spacing,
  Dropdown
} from '@ui'

import connect from './connect'
import styles from './index.scss'
import {
  SHIFT_ASSIGN,
  SHIFT_CHECKBOXES,
  INITIAL_FORM_VALUES,
  SHIFT_ANNOUNCE_FORM
} from './constants'
const SHIFT_ANNOUNCE_BY_PROP = 'announceByProperty'

const cx = classNames.bind(styles)

const CHANGED_SHIFTS_QUERY = `query ChangedShiftsQuery (
  $workspace: ID!
  $locality: ID
) {
  workspace(
    id: $workspace
  ) {
    changedShifts( locality: $locality ) {
      shift {
        ${q.SHIFT_PARAMS_BASIC}
        revisions {
          created
        }
      }
      changes {
        type
        diff
        created
      }
    }
  }
}`

class PublishChanges extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    AnnounceShift: PropTypes.func.isRequired,
    UpdateAnnounceShiftFrom: PropTypes.func.isRequired,
    UpdateAnnounceShiftTo: PropTypes.func.isRequired,
    UpdateAnnounceShiftByPosition: PropTypes.func.isRequired,
    UpdateAnnounceShiftByLocality: PropTypes.func.isRequired,
    UpdateAnnounceShiftByPeople: PropTypes.func.isRequired,
    auth: PropTypes.string.isRequired,
    changeFormField: PropTypes.func.isRequired,
    employees: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    workspaceId: PropTypes.string.isRequired,
    workspaces: PropTypes.array.isRequired,
    announceShifts: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    const { initialize } = props

    this.state = {
      isLoading: false
    }

    this.enforcedLocality = miscUtil.getEnforcedLocality()

    initialize({ ...INITIAL_FORM_VALUES, [SHIFT_ANNOUNCE_BY_PROP]: GLOBAL_DATEPICKER })
  }

  componentDidMount () {
    const { auth, workspaceId, AnnounceShift } = this.props
    ApolloClientFetch(auth, 'ChangedShiftsQuery')
      .query({
        query: gql`
          ${CHANGED_SHIFTS_QUERY}
        `,
        variables: {
          workspace: workspaceId,
          locality: this.enforcedLocality || undefined
        }
      })
      .then((res) => {
        const {
          data: {
            workspace: { changedShifts }
          }
        } = res
        AnnounceShift(changedShifts)
        this.setState({ ...this.state, isLoading: true })
      })
  }

  componentDidUpdate (prevProps) {
    const {
      UpdateAnnounceShiftByPosition,
      announceShifts: { periodStart, periodEnd },
      UpdateAnnounceShiftFrom,
      UpdateAnnounceShiftTo,
      UpdateAnnounceShiftByLocality,
      UpdateAnnounceShiftByPeople
    } = this.props
    const shiftCheckboxes = this.props[VALUES] ? this.props[VALUES][SHIFT_CHECKBOXES] : []
    const announceByProperty = this.props[VALUES] ? this.props[VALUES][SHIFT_ANNOUNCE_BY_PROP] : []

    if (
      (prevProps[VALUES] &&
        prevProps[VALUES][SHIFT_CHECKBOXES].length !==
          shiftCheckboxes.length) ||
      (prevProps[VALUES] && prevProps[VALUES][SHIFT_ANNOUNCE_BY_PROP] !== announceByProperty)
    ) {
      switch (true) {
        case announceByProperty === GLOBAL_DATEPICKER:
          UpdateAnnounceShiftFrom({ val: periodStart, periodEnd })
          UpdateAnnounceShiftTo({ val: periodEnd, periodStart })
          break
        case announceByProperty === GLOBAL_POSITION:
          UpdateAnnounceShiftByPosition({
            shiftCheckboxes,
            periodEnd,
            periodStart
          })
          break
        case announceByProperty === GLOBAL_LOCALITY:
          UpdateAnnounceShiftByLocality({
            shiftCheckboxes,
            periodEnd,
            periodStart
          })
          break
        case announceByProperty === GLOBAL_SINGLE_PERSON:
          UpdateAnnounceShiftByPeople({
            shiftCheckboxes,
            periodEnd,
            periodStart
          })
          break
        default:
          return false
      }
    }
  }

  componentWillUnmount () {
    const { AnnounceShift } = this.props
    AnnounceShift([])
  }

  onSubmit = () => {
    const {
      close,
      auth,
      workspaceId,
      announceShifts: { announce }
    } = this.props
    return ApolloClientFetch(auth, 'ShiftsPublishMutation')
      .mutate({
        mutation: gql`
          ${ANNOUNCE_SHIFTS}
        `,
        variables: {
          workspaceId,
          ids: [...new Set( // making sure there are no duplacates in the IDs array
            (Array.isArray(announce) ? announce : [])
              .map((item) =>
                item.positions
                  .map((pos) => pos.shifts)
                  .map((shifts) => shifts.flat(1))
                  .flat(1)
              )
              .flat(1)
              .map((shift) => shift.shift.id)
          )]
        }
      })
      .then(() => {
        close()
      })
  }

  revertShift = (shift, date, posId) => {
    const { auth, workspaceId, RevertAnnounceShift, close } = this.props
    const {
      shift: {
        id,
        period: { start, end }
      }
    } = shift

    ApolloClientFetch(auth, 'RevertShiftsMutation')
      .mutate({
        mutation: gql`
          ${REVERT_SHIFTS}
        `,
        variables: {
          workspaceId,
          ids: id,
          period: {
            start,
            end
          }
        }
      })
      .then(() => {
        RevertAnnounceShift({ date, posId, id })
        if (!this.countShifts()) {
          close()
        }
      })
  }

  countShifts = () => {
    const {
      announceShifts: { announce }
    } = this.props
    return (
      announce &&
      announce.length &&
      announce
        .map(
          (item) =>
            item.positions.length &&
            item.positions
              .map((pos) => pos.shifts.length)
              .reduce((a, b) => a + b)
        )
        .reduce((a, b) => a + b)
    )
  }

  render () {
    const {
      employees,
      positions,
      announceShifts: { announce, periodStart, periodEnd },
      UpdateAnnounceShiftFrom,
      UpdateAnnounceShiftTo,
      UpdateAnnounceShiftByPosition,
      UpdateAnnounceShiftByLocality,
      UpdateAnnounceShiftByPeople,
      [VALUES]: values,
      workspaces,
      workspaceId,
      changeFormField,
      handleSubmit,
      submitting,
      undo
    } = this.props
    const { isLoading } = this.state
    const { announceByProperty, shiftCheckboxes } = values || {
      ...INITIAL_FORM_VALUES
    }
    const placeholder =
      announceByProperty === GLOBAL_POSITION
        ? 'NF_SELECT_POSITION'
        : announceByProperty === GLOBAL_SINGLE_PERSON
          ? 'NF_ASSIGN_PEOPLE'
          : 'NF_SELECT_LOCALITY'

    // re-arrange the announce array into undoShifts array according to last shift revision time if we're going to display the 'undo' verion of this modal
    const undoShifts = []
    if (undo && announce && !!announce.length) {
      announce.forEach((d, k) => {
        if (d.positions) {
          d.positions.forEach((p) => {
            p.shifts
              .filter(
                (s) => s.shift && s.shift.revisions && s.shift.revisions.length
              )
              .forEach((s) => {
                undoShifts.push(
                  Object.assign({}, s, {
                    lastRevisionTime:
                      s.shift.revisions[s.shift.revisions.length - 1].created
                  })
                )
              })
          })
        }
      })
      undoShifts.sort((a, b) => {
        return a < b ? 1 : -1
      })
    }

    const getShiftsHTML = (shiftsArray) => {
      return shiftsArray.map((cs) => {
        const ee = employees && employees[cs.shift.userId]
        const changes = getUniqueChangesMapper(cs.changes).map((c) => {
          switch (c.type) {
            case 'created':
              return t('SHIFT_WAS_CREATED')
            case 'deleted':
              return t('SHIFT_WAS_DELETED')
            case 'userAssigned':
              return t('SHIFT_WAS_UPDATED')
            case 'userChanged':
              return t('SHIFT_USER_ASSIGNED')
            case 'opened':
              return t('SHIFT_USER_CHANGED')
            case 'periodChanged':
              return t('SHIFT_WAS_OPENED')
            case 'pausesChanged':
              return t('SHIFT_PERIOD_CHANGED')
            case 'pausesFixedChanged':
              return t('SHIFT_PAUSES_CHANGED')
            case 'idealSkillChanged':
              return t('SHIFT_PAUSES_FIXED_CHANGED')
            case 'localityIdChanged':
              return t('SHIFT_IDEAL_SKILL_CHANGED')
            case 'updated':
              return t('LOCALITY_ID_CHANGED')
            default:
              return ''
          }
        })

        return (
          <div key={cs.shift.id} className='ds-acc-row'>
            <div className={cx('ds-acc-shift', { 'is-open': !ee })}>
              <Avatar profile={ee} />
              <div className='ds-accs-details'>
                {ee ? (
                  <div className='ds-accs-name'>
                    {viewUtil.shortName(ee.name)}
                  </div>
                ) : (
                  <div className='ds-accs-name'>{t('ASSIGN')}</div>
                )}
                <div className='ds-accs-time'>
                  {timeUtil.liveShiftTime(cs.shift)}
                </div>
              </div>
            </div>
            <div className='ds-acc-changes'>
              {changes.length > 1 ? changes.map(item => item + ', ').join('') : changes}
              <Label
                onClick={() =>
                  this.revertShift(cs, moment(cs.shift.period.start).format('YYYY-MM-DD'), cs.shift.positionId)}
                title={t('REVERT_CHANGE')}
                icon='back'
              />
            </div>
          </div>
        )
      })
    }

    const optsPropTypes = [
      {
        value: GLOBAL_POSITION,
        label: t('GLOBAL_POSITION')
      },
      {
        value: GLOBAL_SINGLE_PERSON,
        label: t('NF_MAN')
      },
      {
        value: GLOBAL_DATEPICKER,
        label: t('NF_BY_DAY')
      },
      {
        value: GLOBAL_LOCALITY,
        label: t('NF_LOCATION')
      }
    ]

    let opts = []
    if (announceByProperty === GLOBAL_SINGLE_PERSON) {
      opts = sortUtil
        .sortEmployees(employees)
        .map((emp) => {
          return {
            value: emp.id,
            label: emp.name,
            id: emp.id
          }
        })
    }
    if (announceByProperty === GLOBAL_POSITION) {
      opts = positions
        .filter(p => !p.archived)
        .map((p) => {
          return {
            value: p.id,
            label: p.name,
            id: p.id
          }
        })
    }
    if (announceByProperty === GLOBAL_LOCALITY) {
      const ws = workspaces?.find(w => w.id === workspaceId)
      if (ws) {
        opts = ws.localities
          .map((l) => {
            return {
              value: l.id,
              label: l.name,
              id: l.id
            }
          })
      }
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit)} name={SHIFT_ANNOUNCE_FORM}>
        <Modal
          className='ds-publish-changes'
          size={Modal.SIZES.L}
          headerContent={
            <div className='ds-title'>
              {t(undo ? 'UNDO_X_CHANGES' : 'PUBLISH_X_CHANGES', {
                x: this.countShifts()
              })}
            </div>
          }
          sections={[
            <Fragment key={0}>
              <Flex direction={Flex.DIRECTION.COLUMN}>
                <Flex align={Flex.POSITION.END}>
                  <Input
                    size={Input.SIZES.FULL_WIDTH}
                    label={t('PUBLISH_CHANGES_PERIOD')}
                    type='date'
                    value={moment(periodStart).format('YYYY-MM-DD')}
                    onChange={(val) => {
                      if (announceByProperty === GLOBAL_DATEPICKER) {
                        UpdateAnnounceShiftFrom({ val, periodEnd })
                      }
                      if (announceByProperty === GLOBAL_POSITION) {
                        UpdateAnnounceShiftByPosition({
                          shiftCheckboxes,
                          periodEnd,
                          periodStart: val
                        })
                      }
                      if (announceByProperty === GLOBAL_LOCALITY) {
                        UpdateAnnounceShiftByLocality({
                          shiftCheckboxes,
                          periodEnd,
                          periodStart: val
                        })
                      }
                      if (announceByProperty === GLOBAL_SINGLE_PERSON) {
                        UpdateAnnounceShiftByPeople({
                          shiftCheckboxes,
                          periodEnd,
                          periodStart: val
                        })
                      }
                    }}
                  />
                  <div className='ds-mtc-dash-separator'>-</div>
                  <Input
                    size={Input.SIZES.FULL_WIDTH}
                    type='date'
                    value={moment(periodEnd).format('YYYY-MM-DD')}
                    onChange={(val) => {
                      if (announceByProperty === GLOBAL_DATEPICKER) {
                        UpdateAnnounceShiftTo({ val, periodStart })
                      }
                      if (announceByProperty === GLOBAL_POSITION) {
                        UpdateAnnounceShiftByPosition({
                          shiftCheckboxes,
                          periodEnd: val,
                          periodStart
                        })
                      }
                      if (announceByProperty === GLOBAL_LOCALITY) {
                        UpdateAnnounceShiftByLocality({
                          shiftCheckboxes,
                          periodEnd: val,
                          periodStart
                        })
                      }
                      if (announceByProperty === GLOBAL_SINGLE_PERSON) {
                        UpdateAnnounceShiftByPeople({
                          shiftCheckboxes,
                          periodEnd: val,
                          periodStart
                        })
                      }
                    }}
                  />
                </Flex>

                {!this.enforcedLocality && (
                  <>
                    <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4}>
                      <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM}>{t('PUBLISH_CHANGES_AUDIENCE')}</Text>
                    </Spacing>

                    <Dropdown
                      label={null}
                      singleSelect
                      size={Dropdown.SIZES.FULL_WIDTH}
                      type={Dropdown.TYPES.VARIABLE}
                      style={Dropdown.STYLES.LIGHT}
                      options={optsPropTypes}
                      value={[optsPropTypes.find(pt => pt.value === announceByProperty)].filter(Boolean)}
                      onChange={(option) => {
                        changeFormField(SHIFT_ANNOUNCE_BY_PROP, option.value)
                        changeFormField(SHIFT_ASSIGN, '')
                        changeFormField(SHIFT_CHECKBOXES, [])
                      }}
                    />

                    {(values && !!values.announceByProperty && !~[GLOBAL_DATEPICKER].indexOf(values.announceByProperty)) && (
                      <>
                        <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4} />
                        <Dropdown
                          label={null}
                          searchable
                          size={Dropdown.SIZES.FULL_WIDTH}
                          type={Dropdown.TYPES.VARIABLE}
                          style={Dropdown.STYLES.LIGHT}
                          options={opts}
                          value={
                            values?.shiftCheckboxes?.map(cb => { return { value: cb.id, label: cb.label } }) ||
                            INITIAL_FORM_VALUES?.shiftCheckboxes?.map(cb => { return { value: cb.id, label: cb.label } }) ||
                            []
                          }
                          placeholder={<>{t(placeholder)}</>}
                          onChange={(selected) => {
                            changeFormField(SHIFT_CHECKBOXES, selected.map(s => { return { id: s.value, label: s.label } }))
                          }}
                        />
                      </>
                    )}
                  </>)}

              </Flex>
            </Fragment>,
            <Fragment key={1}>
              <Flex direction={Flex.DIRECTION.COLUMN}>
                {/* 'publish' version of this modal */}
                {!undo &&
                (announce && !!announce.length ? (
                  announce.map((d, k) => {
                    return (
                      !!d.changes && (
                        <Flex
                          direction={Flex.DIRECTION.COLUMN}
                          key={`${1 + k}`}
                        >
                          <Spacing
                            type={Spacing.TYPES.VERTICAL}
                            size={Spacing.SIZES.SIZE_12}
                          >
                            <div className='ds-acc-date-title'>
                              {moment(d.date).format('D. MMMM')}
                              <div className='ds-tm'>
                                ({d.changes}{' '}
                                {t(
                                  d.changes === 1
                                    ? 'CHANGE_SINGULAR'
                                    : 'CHANGES'
                                )}
                                )
                              </div>
                            </div>
                            {d.positions.map((p) => {
                              const pos =
                                positions &&
                                positions.find((pp) => pp.id === p.id)
                              return (
                                !!p.shifts.length && (
                                  <div key={p.id} className='ds-acc-position'>
                                    <Color src={pos} />
                                    <div className='ds-acc-position-title'>
                                      {pos && pos.name} {p.shifts.length}x
                                    </div>
                                    {getShiftsHTML(p.shifts)}
                                  </div>
                                )
                              )
                            })}
                          </Spacing>
                        </Flex>
                      )
                    )
                  })
                ) : (
                  <span>{t('NOTHING_TO_ANNOUNCE')}</span>
                ))}

                {/* 'undo' version of this modal */}
                {undo &&
                (undoShifts && !!undoShifts.length ? (
                  <div className='ds-acc-date'>
                    <div className='ds-acc-date-title'>
                      <div className='ds-tm'>
                        {t(
                          undoShifts.length === 1
                            ? 'CAN_UNDO_CHANGES_ON_X_SHIFTS_SINGULAR'
                            : 'CAN_UNDO_CHANGES_ON_X_SHIFTS',
                          { x: undoShifts.length }
                        )}
                      </div>
                    </div>
                    <div className='ds-acc-position'>
                      {getShiftsHTML(undoShifts)}
                    </div>
                  </div>
                ) : (
                  <span>{t('NOTHING_TO_UNDO')}</span>
                ))}
              </Flex>
            </Fragment>
          ]}
          footerContent={
            <>
              {!undo && (
                <Button
                  size={Button.SIZES.LARGE}
                  style={Button.STYLES.CONTAINED}
                  color={Button.COLORS.GREEN}
                  htmlType={Button.HTML_TYPES.SUBMIT}
                  loading={submitting}
                  label={t('SAVE_AND_SEND')}
                  disabled={!announce.length}
                />
              )}

              {undo && (
                <Button
                  loading={submitting}
                  label={t('CANCEL')}
                  size={Button.SIZES.LARGE}
                  style={Button.STYLES.OUTLINED}
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.close()
                  }}
                />
              )}
            </>
          }
          isLoading={!isLoading || submitting}
        />
      </form>
    )
  }
}

export default connect(PublishChanges)
