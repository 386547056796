import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ShiftAttributeCreateMutation (
  $workspace: ID!,
  $name: String!,
  $shortName: String!,
  $type: CustomAttributeValueType!
  $description: String
) {
  shiftAttributeCreate (
    workspace: $workspace
    name: $name
    shortName: $shortName
    type: $type
    description: $description
  ) {
    id
  }
}`

async function requestShiftAttributeCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-attribute-create', errors)
  }
  return data.shiftAttributeCreate
}

export default requestShiftAttributeCreate
