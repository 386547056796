import { t } from 'i18next'

function _getCharWidth (w, c) {
  if (c === 'W' || c === 'M') w += 15
  else if (c === 'w' || c === 'm') w += 12
  else if (c === 'I' || c === 'i' || c === 'l' || c === 't' || c === 'f') w += 4
  else if (c === 'r') w += 8
  else if (c === c.toUpperCase()) w += 12
  else w += 10
  return w
}

function shortName (name) {
  if (!name) return null
  if (name.includes('@') && name.includes('.')) return name

  const allowedWidthBeforeShortening = 100
  const nameWidth = name.split('').reduce(_getCharWidth, 0)
  if (nameWidth <= allowedWidthBeforeShortening) return name

  const spl = name.split(' ')
  if (spl.length > 0) {
    return spl[0][0] + '. ' + spl.slice(1).join(' ')
  } else {
    return name
  }
}

function skillNumberToLetter (num) {
  return t('SKILL_LETTER_' + num.toString())
}

function skillNumberToColor (num) {
  if (parseInt(num) === 2) return '#c9c9c9'
  if (parseInt(num) === 3) return '#eec406'
  return '#fff'
}

function skillNumberToTextColor (num) {
  if (parseInt(num) === 1) return '#888'
  return '#fff'
}

export default {
  shortName,
  skillNumberToLetter,
  skillNumberToColor,
  skillNumberToTextColor
}
