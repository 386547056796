import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationTransfers(
   $organizationId: ID!,
   $filter: OrganizationFilter!
   $period: PeriodInput!,
   ) {
  organization(id: $organizationId) {
    transfers(period: $period, filter: $filter) {
      id
      name
      workspaces {
        id
        name
        receivedHours
        receivedExpenses {
          amount
          currency
        }
        borrowedHours
        borrowedExpenses {
          amount
          currency
        }
        totalHours
        totalExpenses {
          amount
          currency
        }
      }
    }
  }
}`

async function requestOrganizationTransfers (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrganizationTransfers
