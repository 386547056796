import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import assignCycle from '@app/ac/assign-cycle'

const mapStateToProps = ({ workspaceId, loading, workspaces }) => {
  const ws = workspaces.find(ws => ws.id === workspaceId)
  return {
    workspace: ws,
    isLoading: loading.includes('workspace-detail') || loading.includes('load-employees') || loading.includes('assign-cycle')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    assignCycle
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
