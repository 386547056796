import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query TimeOffAttachments($timeOffId: ID!, $workspaceId: ID, $userId: ID) {
  timeOff(id: $timeOffId, workspace: $workspaceId, user: $userId) {
    attachments {
      fileId
      file {
        id
        filename
        size
        url
        author
        created
      }
    }
  }
}
`

async function requestTimeOffAttachments (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('', errors)
  }
  return data.timeOff.attachments
}

export default requestTimeOffAttachments
