import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation Mutation($organization: ID!, $transfers: [TransferIgnoreBonusSet!]!) {
  organizationTransferIgnoreBonusSet(organization: $organization, transfers: $transfers) {
    shiftId
    ignoreBonus
  }
}`

async function requestOrgTransferCalculateBonus (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationTransferIgnoreBonusSet
}

export default requestOrgTransferCalculateBonus
