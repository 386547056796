import React from 'react'
import { t } from 'i18next'

import { Button, Icon } from '@ui'

import { TableConfigTooltip } from './TableConfigTooltip'
import { Export } from './Export'

import './style.scss'

export const ActionBar = ({
  showActionBar,
  editedRows,
  extraButtonContent,
  allColumns,
  setHiddenColumns,
  exportHidden,
  exportFileName,
  tableName,
  selectedFlatRows,
  getTransformedColumns,
  onBatchActions,
  batchActionsDisabled,
  hiddenColumns,
  showConfig
}) => {
  return (
    <div className='ds-table-detachable-node'>
      <div className='table-action-bar'>
        {showActionBar && editedRows.length === 0 && (
          <>
            {extraButtonContent && (
              <div className='with-extra'>
                {extraButtonContent}
              </div>
            )}
            <div className='static-buttons'>
              {showConfig && (
                <TableConfigTooltip
                  allColumns={allColumns}
                  setHiddenColumns={setHiddenColumns}
                  hiddenColumns={hiddenColumns}
                />
              )}
              {!exportHidden && (
                <Export
                  tableName={tableName}
                  selectedRows={selectedFlatRows}
                  columns={getTransformedColumns}
                  exportFileName={exportFileName}
                />
              )}
              {onBatchActions && (
                <Button
                  disabled={batchActionsDisabled || !selectedFlatRows.length}
                  ico={Icon.ICONS.edit}
                  onClick={() => onBatchActions(selectedFlatRows)}
                  label={t('TABLE_BATCH_EDIT')}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
