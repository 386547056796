import React from 'react'
import { Row } from '@ui/table/components/Row'
import { Cell } from './Cell'
import { calcStickyProperties } from '@ui/table/helpers'

export const TableFooter = ({
  dataSource,
  prepareRow,
  rowOnMouseLeave,
  extraRow
}) => (
  <div className='table-footer'>
    {dataSource.map((row, i) => {
      prepareRow(row)
      return (
        <Row
          key={'tab_' + row.id}
          rowOnMouseLeave={rowOnMouseLeave}
          className='table-footer'
          {...row.getRowProps()}
        >
          {row.cells.map((cell, cellIndex) => {
            const {
              isNextSticky,
              isLast,
              stickyOffset
            } = calcStickyProperties(cellIndex, row?.cells)

            return (
              <Cell
                key={'tab_' + row.id + '_' + cell.column.id}
                cell={cell}
                originalRow={row.original}
                isExtraRow={extraRow}
                idOfExtraRow={i}
                isSticky={cell.column.sticky || (isNextSticky && cell.column.id === 'selection')}
                isLast={isLast}
                stickyOffset={stickyOffset}
              />
            )
          })}
        </Row>
      )
    })}
  </div>
)
