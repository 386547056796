import store from '../store'
import isLoading from './is-loading'
import requestShiftPeriodCopy from '../request/shift-period-copy'
import addError from './add-error'
import setModal from './set-modal'

export default (variables) => {
  return async (dispatch) => {
    if (!variables) return
    const { auth } = store.getState()

    await dispatch(isLoading('shift-period-copy'))
    const result = await requestShiftPeriodCopy(variables, auth)
    if (!result || result.error) {
      dispatch(addError(result.error))
    } else {
      await dispatch(setModal(null))
    }
    await dispatch(isLoading('shift-period-copy', true))
  }
}
