import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setWorkspaceId from '@app/ac/set-workspace-id'
import loadPositions from '@app/ac/load-positions'

const mapStateToProps = ({ organization, workspaceId, auth }) => {
  return {
    countries: organization.countries,
    allAllowedWorkspaces: organization ? organization.allAllowedWorkspaces : null,
    workspaceId,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setWorkspaceId,
    loadPositions
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
