/* eslint-disable react/jsx-fragments */
import { Fragment, useEffect, useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { gql } from '@apollo/client'

import { PRODUCTION_DEMAND_SET } from '@app/request/query'
import { requestWorkspaceActualProduction } from '@app/request'
import {
  Text,
  Icon,
  Position,
  Tooltip
} from '@ui'
import { ApolloClientFetch } from '@app/util/apollo-client-fetch'
import { calendarUtil, numberUtil, miscUtil } from '@app/util'

import connect from './connect'
import './index.scss'

const Capacities = ({ calendar, positions, dateStringsInPeriod, labelWidthRem, style, auth, workspaceId, positionsWithResource, shiftsWithResourceHash }) => {
  const [data, setData] = useState([])
  const [valBeforeEdit, setValBeforeEdit] = useState(null)
  const [loadWarningsTimer, setLoadWarningsTimer] = useState(setTimeout(() => {}, 0))
  const [inputHasFocus, setInputHasFocus] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const [isDataAlreadyLoaded, setIsDataAlreadyLoaded] = useState(false) // this is makes sure we don't unnecessarily load the initial data again later
  const [isDataLoading, setIsDataLoading] = useState([]) // this makes sure we don't make the same data request multiple times in parallel

  const loadDataFromBE = () => {
    const vars = {
      workspaceId,
      period: calendarUtil.getReloadPeriod(calendar)
    }

    if (!isDataLoading?.includes(miscUtil.safeStringify(vars))) {
      setIsDataLoading(isDataLoading.filter(r => r !== miscUtil.safeStringify(vars)).concat([miscUtil.safeStringify(vars)]))
      requestWorkspaceActualProduction(vars, auth, { withPositions: true }).then(response => {
        let loadedData = response

        // this is just a complicated way to prevent the load from overwriting an input value
        // while that input is focused. if the input is focused, it's current value overrides
        // the corresponding place in the loaded data array
        const focusedInput = document.activeElement
        if (focusedInput && focusedInput.dataset.date && focusedInput.dataset.position) {
          loadedData = loadedData.map(ld => {
            if (ld.date === focusedInput.dataset.date) {
              const newPositions = ld.positions.map(ldp => {
                if (ldp.positionId === focusedInput.dataset.position) {
                  return Object.assign({}, ldp, { productionDemand: focusedInput.value })
                } else {
                  return ldp
                }
              })
              if (!newPositions.find(np => np.positionId === focusedInput.dataset.position)) newPositions.push({ positionId: focusedInput.dataset.position, productionDemand: focusedInput.value })
              return {
                date: ld.date,
                positions: newPositions
              }
            } else {
              return ld
            }
          })
          if (!loadedData.find(ld => ld.date === focusedInput.dataset.date)) {
            loadedData.push({ date: focusedInput.dataset.date, positions: [{ positionId: focusedInput.dataset.position, productionDemand: focusedInput.value }] })
          }
        }

        if (response.length) {
        // if 'totalProduction' is set in at least one day, add its values to 'positions' array (with positionId: 'totalProduction') for easier rendering
          if (loadedData.some((d) => d.totalProduction)) {
            loadedData = loadedData.map((ld) => {
              const newPos = [...ld.positions]
              newPos.unshift(
                Object.assign({}, ld.totalProduction, { positionId: 'totalProduction' })
              )
              return Object.assign({}, ld, { positions: newPos })
            })
          }
          setData(loadedData)
          if (!isDataAlreadyLoaded) setIsDataAlreadyLoaded(true)
        }
        setIsDataLoading(isDataLoading.filter(r => r !== miscUtil.safeStringify(vars)))
      })
    }
  }

  const saveDataToBE = (dates, positionId, newVal) => {
    clearTimeout(loadWarningsTimer)

    ApolloClientFetch(auth, 'ProductionDemandSet')
      .mutate({
        mutation: gql`${PRODUCTION_DEMAND_SET}`,
        variables: {
          workspaceId,
          dates,
          positionId: positionId === 'totalProduction' ? null : positionId,
          productionUnits: (newVal ? parseInt(newVal) : null)
        }
      })
      .then(res => {
        // reload the data & warnings after a moment (but reset that timer whenever something else changes)
        setLoadWarningsTimer(setTimeout(loadDataFromBE, 2000))
      })
  }

  // called on component mount
  useEffect(() => {
    if (data.length === 0) loadDataFromBE()
  }, [])

  // called when the calendar date or shiftsWithResourceHash changes
  useEffect(() => {
    setTimeout(() => { loadDataFromBE() }, 200)
  }, [calendar, shiftsWithResourceHash])

  const displayedPositionsWithResource = [...positionsWithResource]
  displayedPositionsWithResource.sort((a, b) => { return a.name.localeCompare(b.name) })

  if (!displayedPositionsWithResource.some(dp => dp.id === 'totalProduction')) {
    displayedPositionsWithResource.unshift({
      id: 'totalProduction',
      name: t('CAPP_TOTAL_PROD')
    })
  }

  // if we have 2 or fewer rows to display, adjust the calendar sections' max height accordingly, so we don't display a lot of unnecessary empty space there
  useEffect(() => {
    if (isDataAlreadyLoaded && displayedPositionsWithResource.length <= 2) {
      const calSectionsEl = document.querySelector('.ds-c-sections')
      if (calSectionsEl) {
        const newCalendarSectionsMaxHeight = calendarUtil.getComputedCalendarSectionsHeight(calendar, { capacitiesheight: (Math.max(displayedPositionsWithResource.length, 1) * 2.875) + 'rem' })
        calSectionsEl.style.maxHeight = newCalendarSectionsMaxHeight
      }
    }
  }, [isDataAlreadyLoaded])

  const customStyle = Object.assign({}, style)
  if (isExpanded) {
    const h = Math.min(displayedPositionsWithResource.length * 2.875, 40)
    customStyle.height = h.toString() + 'rem'
    customStyle.minHeight = h.toString() + 'rem'
    customStyle.maxHeight = h.toString() + 'rem'
  }

  return (
    <Fragment>
      <div className={'ds-c-capacities is-view-' + calendar.view + (isExpanded ? ' is-expanded' : '')} style={customStyle}>
        <div
          className='ds-c-row-label'
          style={{
            width: (labelWidthRem).toString() + 'rem',
            minWidth: (labelWidthRem).toString() + 'rem',
            maxWidth: (labelWidthRem).toString() + 'rem'
          }}
        >
          {displayedPositionsWithResource.map(p => {
            const demandSum = data.reduce((a, s) => { return a + (s.positions.filter(po => po.positionId === p.id).reduce((a2, s2) => { return a2 + (s2.productionDemand || 0) }, 0)) }, 0)
            const actualProdSum = data.reduce((a, s) => { return a + (s.positions.filter(po => po.positionId === p.id).reduce((a2, s2) => { return a2 + (s2.actualProduction || 0) }, 0)) }, 0)
            return (
              <div className='ds-c-cap-pos-row' key={p.id}>
                {p.id === 'totalProduction'
                  ? <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM}>{t('CAPP_TOTAL_PROD')}</Text>
                  : <Position {...p} />}

                <div className='ds-c-cap-pos-row-sum'>
                  {numberUtil.round2decimals(actualProdSum)} / {numberUtil.round2decimals(demandSum)}
                  {p.productionResourceType && p.productionResourceType.name
                    ? (' ' + p.productionResourceType.name)
                    : p.id === 'totalProduction'
                      ? (' ' + t('HOUR_SHORT'))
                      : null}
                  {(p.id === 'totalProduction' && demandSum)
                    ? ' (' + Math.round(actualProdSum / demandSum * 100).toString() + '%)'
                    : null}
                </div>

              </div>
            )
          })}
        </div>

        {data.map((day, colIdx) => {
          const widthDef = 'calc((100% - ' + labelWidthRem.toString() + 'rem) / ' + data.length.toString() + ')'
          return (
            <div
              key={'ds-c-cap-seg-' + day.date.toString()}
              className='ds-c-cap-segment'
              style={{
                width: widthDef,
                minWidth: widthDef,
                maxWidth: widthDef
              }}
            >
              {displayedPositionsWithResource.map((p, posIdx) => {
                const inpPos = data[colIdx].positions.find(pp => pp.positionId === p.id)
                const inputVal = (p.id === 'totalProduction')
                  ? ((inpPos && inpPos.productionDemand) || 0)
                  : inpPos && inpPos.productionDemand
                const inputWarn = inpPos && inpPos.warning
                const valueUnset = (typeof inputVal === typeof null || typeof inputVal === typeof undefined || inputVal === '')
                const uniqueSegmentId = day.date.toString() + '_' + p.id

                let arrow = null
                if (inpPos && inpPos.actualProduction > inpPos.productionDemand) arrow = 'up'
                if (inpPos && inpPos.actualProduction < inpPos.productionDemand) arrow = 'down'

                const input = (
                  <input
                    onPaste={(e) => {
                      // copied from https://gist.github.com/torjusb/7d6baf4b68370b4ef42f

                      // get the clipboard text
                      var clipText = e.clipboardData.getData('Text') || e.clipboardData.getData('text/plain')

                      // Parse the pasted text from Excel into rows.
                      // Pasted text is usually separated by a new line for each row,
                      // but a single cell can contain multiple lines, which is what
                      // we pars out in the first `replace`.
                      //
                      // We find all text within double-quotes ('"') which has new
                      // lines, and put the text within the quotes into capture
                      // groups. For each match, we replace its contents again, by
                      // removing the new lines with spaces.
                      //
                      // Then lastly, once we've joined all the multi line cells, we
                      // split the entire pasted content on new lines, which gives
                      // us an array of each row.
                      //
                      // Since Windows usually uses weird line-endings, we need to
                      // ensure we check for each of the different possible
                      // line-endings in every regexp.
                      //
                      // It also handles cells which contains quotes. There appears
                      // to be two ways this is handled. In Google Docs, quotes within
                      // cells are always doubled up when pasting, so " becomes "".
                      // In Libre Office, the quotes are not normal quotes, some
                      // other character is used, so we don't need to handle it any
                      // differently.
                      var clipRows = clipText.replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/mg, function (match, p1) {
                        // This function runs for each cell with multi lined text.
                        return p1
                        // Replace any double double-quotes with a single double-quote
                          .replace(/""/g, '"')
                        // Replace all new lines with spaces.
                          .replace(/\r\n|\n\r|\n|\r/g, ' ')
                      }).split(/\r\n|\n\r|\n|\r/g) // Split each line into rows

                      // more simplistic version of the above process is here:
                      // const clipRows = clipText.split('\n')

                      // split rows into columns
                      for (var i = 0; i < clipRows.length; i++) {
                        clipRows[i] = clipRows[i].split(String.fromCharCode(9))
                      }

                      const updates = []
                      if (clipRows.length && (clipRows.length > 1 || clipRows[0].length > 1)) {
                        // e.target.value = clipRows[0][0]

                        clipRows.forEach((row, rowIdx) => {
                          if (posIdx + rowIdx >= displayedPositionsWithResource.length) return
                          const pos = displayedPositionsWithResource[posIdx + rowIdx]

                          row.forEach((c, cidx) => {
                            if (colIdx + cidx >= data.length) return
                            const val = c
                            const dt = data[colIdx + cidx].date
                            const existUpdIdx = updates.findIndex(upd => upd.positionId === pos.id && upd.value === val)
                            if (existUpdIdx !== -1) {
                              updates[existUpdIdx].dates.push(dt)
                            } else {
                              updates.push({
                                positionId: pos.id,
                                value: val,
                                dates: [dt]
                              })
                            }
                          })
                        })

                        const batchUpd = async () => {
                          for (var i = 0; i < updates.length; i++) {
                            // clearTimeout(loadWarningsTimer)

                            const upd = updates[i]
                            await ApolloClientFetch(auth, 'ProductionDemandSet')
                              .mutate({
                                mutation: gql`${PRODUCTION_DEMAND_SET}`,
                                variables: {
                                  workspaceId,
                                  dates: upd.dates,
                                  positionId: upd.positionId,
                                  productionUnits: (upd.value ? parseInt(upd.value) : null)
                                }
                              })
                            await loadDataFromBE()
                          }
                        }
                        batchUpd()
                      }
                    }}

                    style={{ width: (0.7 + Math.max((inputVal || '').toString().length, 1)).toString() + 'ch' }}
                    tabIndex={50 + (31 * posIdx) + colIdx}
                    value={!isNaN(inputVal) ? inputVal : ''}
                    placeholder={valueUnset ? t('CAPP_ROW_PLACEHOLDER') : ''}
                    data-date={day.date}
                    data-position={p.id}
                    onFocus={(e) => {
                      setValBeforeEdit(e.target ? e.target.value : null)
                      setInputHasFocus(uniqueSegmentId)
                    }}
                    onChange={(e) => {
                      const val = e.target ? e.target.value : null
                      if (isNaN(parseInt(val)) && val) return false
                      const newdata = JSON.parse(JSON.stringify(data))
                      let found = false
                      newdata[colIdx].positions = newdata[colIdx].positions.map(pp => {
                        if (pp.positionId === p.id) {
                          found = true
                          return Object.assign({}, pp, { productionDemand: parseInt(val) })
                        } else {
                          return pp
                        }
                      })
                      if (!found) newdata[colIdx].positions.push({ positionId: p.id, productionDemand: parseInt(val) })
                      setData(newdata)
                    }}
                    onBlur={(e) => {
                      const val = e.target ? e.target.value : null

                      if ((valBeforeEdit || val) && parseInt(val) !== parseInt(valBeforeEdit)) {
                        saveDataToBE([day.date], p.id, val ? parseInt(val) : val)
                      }

                      setInputHasFocus(null)
                    }}
                    onClick={(e) => { e.target.select() }}
                  />
                )

                const dotsTooltipAnchor = <Fragment><div className='ds-actions-dot' /><div className='ds-actions-dot' /><div className='ds-actions-dot' /></Fragment>
                const actualProductionNumbers = (
                  <Fragment>
                    {(inpPos ? inpPos.actualProduction : 0) || 0}
                    {arrow === 'up'
                      ? ' ▲'
                      : arrow === 'down'
                        ? ' ▼'
                        : ' ◄'}
                  </Fragment>
                )

                return (
                  <div
                    key={colIdx.toString() + '_' + p.id}
                    className={
                      'ds-c-cap-segment-input-wrap' +
                    (valueUnset ? ' no-value' : '') +
                    (inputHasFocus === uniqueSegmentId ? ' has-focus' : '')
                    }
                    onClick={(e) => {
                    // when clicked anywhere on this element, focus the input within it
                      if (e.currentTarget) {
                        const el = e.currentTarget.querySelector('input')
                        if (el) el.click()
                      }
                    }}
                  >
                    <div className={'ds-c-cap-segment-top' + (inputWarn ? (' has-warn-' + inputWarn) : '') + (valueUnset ? (' has-value-unset') : '')}>

                      {valueUnset
                        ? inpPos && inpPos.actualProduction
                          ? actualProductionNumbers
                          : <span className='ds-c-cap-segment-top-dash'>-</span>

                        : actualProductionNumbers}

                      {calendar && calendar.view === 'month' && p.id !== 'totalProduction' && (
                        <div className='segment-actions'>
                          <Tooltip
                            position={Tooltip.POSITIONS.TOP}
                            clickable
                            className='ds-c-cap-segment-top-actions-anchor'
                            anchor={dotsTooltipAnchor}
                          >
                            <div onClick={() => {
                              const dates = []
                              let d = moment(calendar.date).startOf('month')
                              while (d.isSame(moment(calendar.date), 'month')) {
                                dates.push(d.format('YYYY-MM-DD'))
                                d = d.add(1, 'day')
                              }
                              saveDataToBE(dates, p.id, inputVal)
                            }}
                            >
                              <Icon ico={Icon.ICONS.duplicate} />
                              {t('CAPP_COPY_VALUE')}
                            </div>

                          </Tooltip>
                        </div>
                      )}
                    </div>

                    <div className={'ds-c-cap-inner-input-wrap' + (valueUnset ? ' no-value' : '')}>
                      {((inputVal || '').toString().length < 5) && <span className='ds-c-cap-input-slash'>/</span>}
                      {input}
                    </div>

                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      {displayedPositionsWithResource.length >= 3 ? (
        <div className='ds-c-cap-expand' onClick={() => { if (isExpanded) { setIsExpanded(false) } else { setIsExpanded(true) } }}>
        ⇳
        </div>
      ) : null}
    </Fragment>
  )
}

export default connect(Capacities)
