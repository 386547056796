import { Component } from 'react'
import connect from './connect'
import pluginsConfig from '@app/const/plugin-config'
import {
  notification,
  calendarUtil
} from '@app/util'
import requestPluginUpdate from '@app/request/plugin-update'
import requestPluginCreate from '@app/request/plugin-create'
import { t } from 'i18next'

export const withPlugin = (WrappedComponent) => {
  class Wrapper extends Component {
    isPluginEnabled = (pluginName = '', defaultValue = false) => {
      // 'dayswapspro' is an exception - it's enabled if and only if !organization.hidden
      if (pluginName === 'dayswapspro') {
        const { dsProEnabled } = this.props
        return dsProEnabled
      }

      const { workspaces, workspaceId } = this.props
      const { plugins } = workspaces.find((ws) => ws.id === workspaceId) || { plugins: [] }
      const { enabled } = (plugins && plugins.find((plugin) => plugin.plugin === pluginName)) || { enabled: defaultValue }

      return enabled
    }

    isPreventedByPlugin = (functionName) => {
      if (!functionName) return false
      const pluginsThatPreventFn = pluginsConfig.SUPPORTED_PLUGINS.filter(p => p.preventsFunctions && p.preventsFunctions.includes(functionName))
      if (pluginsThatPreventFn.length && pluginsThatPreventFn.some(p => this.isPluginEnabled(p.id))) {
        return true
      }
      return false
    }

    togglePlugin = async (plugin, calendar) => {
      // 'dayswapspro' is an exception - it can only be enabled/disabled in admin for the whole organization
      if (plugin.plugin === 'dayswapspro') return false

      const { workspaces, workspaceId, loadShifts, loadWorkspaceDetail, auth } = this.props

      const ws = workspaces.find(w => w.id === workspaceId)
      if (!ws) return false

      const isAdmin = (document.querySelector('.ds-adminpanel') || window.location.hostname === 'localhost' || window.location.hostname === 'app.local.dayswaps.top')
      if (!isAdmin && this.isPreventedByPlugin('pluginActivateDeactivate')) {
        notification.warn({ code: t('functionBlockedByPlugin') })
        return false
      }

      const createPlugin = !ws.plugins.find(p => p.plugin === plugin.plugin)
      const createOrEnable = createPlugin ? 'pluginCreate' : 'pluginEnable'
      const val = !plugin.enabled

      const performSave = async (createOrEnable, val, params) => {
        const body = {
          target: {
            model: 'workspace',
            id: workspaceId
          },
          plugin: params.plugin.plugin,
          data: {
            options: createOrEnable === 'pluginCreate' ? params.plugin.options : undefined,
            enabled: val
          }
        }
        if (createOrEnable === 'pluginEnable') await requestPluginUpdate(body, auth)
        if (createOrEnable === 'pluginCreate') await requestPluginCreate(body, auth)
        if (params.plugin.plugin === 'laborlaw' && calendar?.loadedPeriods && calendar.loadedPeriods.length) {
          loadShifts(calendarUtil.getReloadPeriod(calendar), undefined, null)
        }

        await loadWorkspaceDetail(workspaceId)
      }

      await performSave(createOrEnable, val, { plugin })
    }

    render () {
      return <WrappedComponent isPluginEnabled={this.isPluginEnabled} isPreventedByPlugin={this.isPreventedByPlugin} togglePlugin={this.togglePlugin} {...this.props} />
    }
  }

  return connect(Wrapper)
}
