import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './style.scss'

const SIZES = Object.freeze({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA: 'extra'
})

const COLORS = Object.freeze({
  PRIMARY: 'primary',
  SUCCESS: 'success',
  RED: 'red',
  ORANGE: 'orange',
  WHITE: 'white',
  DARK_BLUE: 'dark-blue',
  BLACK: 'black',
  INHERIT: 'inherit'
})

export const Icon = ({
  ico,
  color,
  size,
  onClick,
  disabled,
  testid
}) => {
  const ImportedIconRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [currentIco, setCurrentIco] = useState(ico)

  useEffect(() => {
    let isMounted = true

    if (isMounted) setCurrentIco(ico)
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(`@ui/icon/icons/${ico}.svg`)
        ).default
      } catch (err) {
        return null
      } finally {
        if (isMounted) setLoading(false)
      }
    }
    importIcon()
    return () => { isMounted = false }
  }, [ico])

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef
    return (
      <div
        data-testid={testid}
        className={classNames(
          'ds-ico',
          currentIco,
          size,
          color,
          {
            disabled: disabled,
            'with-hover': !!onClick
          }
        )}
        onClick={!disabled ? onClick : () => null}
      >
        <ImportedIcon />
      </div>
    )
  } else {
    return (
      <div className={'ds-ico ' + size} />
    )
  }
}

const ICONS = Object.freeze({
  plus: 'plus',
  minus: 'minus',
  edit: 'edit',
  view: 'view',
  duplicate: 'duplicate',
  export: 'export',
  personAdd: 'person-add',
  filters: 'filters',
  search: 'search',
  check: 'check',
  star: 'star',
  warning: 'warning',
  arrowLeft: 'arrow-left',
  arrowRight: 'arrow-right',
  arrowDown: 'arrow-down',
  arrowUp: 'arrow-up',
  work: 'work',
  shift: 'shift',
  dots: 'dots',
  properties: 'properties',
  back: 'back',
  calendarOutlined: 'calendar-outlined',
  template: 'template',
  send: 'send',
  print: 'print',
  lock: 'lock',
  unlock: 'unlock',
  open: 'open',
  close1: 'close1',
  display: 'display',
  publish: 'publish',
  save: 'save',
  clock: 'clock',
  calendar: 'calendar',
  comment: 'comment',
  close2: 'close2',
  settings: 'settings',
  settingsFull: 'settings-full',
  arrowLongRight: 'arrowLongRight',
  country: 'country',
  hide: 'hide',
  hash: 'hash',
  change: 'change',
  history: 'history',
  capacity: 'capacity',
  attachment: 'attachment',
  pin: 'pin',
  update: 'update',
  like: 'like',
  calculator: 'calculator',
  text: 'text',
  number: 'number',
  checkboxes: 'checkboxes',
  singleSelect: 'single-select',
  multiSelect: 'multi-select',
  success: 'success',
  info: 'info',
  upload: 'upload',
  question: 'question',
  wildcard: 'wildcard',
  tip: 'tip',
  activate: 'activate',
  bell: 'bell',
  home: 'home',
  computer: 'computer',
  chat: 'chat',
  lifebuoy: 'lifebuoy',
  dashboard: 'dashboard',
  exchange: 'exchange',
  cash: 'cash',
  report: 'report',
  help: 'help',
  modules: 'modules',
  payroll: 'payroll',
  access: 'access',
  covid: 'covid',
  location: 'location',
  positions: 'positions',
  badge: 'badge',
  cycle: 'cycle',
  api: 'api',
  performance: 'performance',
  area: 'area',
  settings2: 'settings2',
  terminal: 'terminal',
  external: 'external',
  dayswaps: 'dayswaps',
  deactivation: 'deactivation',
  activation: 'activation',
  invoice: 'invoice',
  template2: 'template2',
  turnover: 'turnover',
  standards: 'standards',
  productivity: 'productivity',
  hoursDivision: 'hours-division',
  holidays: 'holidays',
  overview: 'overview',
  values: 'values',
  items: 'items',
  checkboxChecked: 'checkbox-checked',
  checkboxUnchecked: 'checkbox-unchecked',
  checkboxInterminate: 'checkbox-interminate',
  radioUnchecked: 'radio-unchecked',
  radioChecked: 'radio-checked',
  person: 'person',
  countryManager: 'country-manager',
  areaManager: 'area-manager',
  shopManager: 'shop-manager',
  group: 'group',
  people: 'people',
  absence: 'absence',
  attendance: 'attendance',
  switch: 'switch',
  unoccupied: 'unoccupied',
  offer: 'offer',
  delete: 'delete',
  close4: 'close4',
  chat2: 'chat2',
  email: 'email',
  phone: 'phone',
  phonePlus: 'phone-plus',
  insurance: 'insurance',
  connection: 'connection',
  loader: 'loader',
  accountant: 'accountant',
  blank: 'blank',
  coffee: 'coffee',
  break: 'break',
  leave: 'leave',
  sick: 'sick',
  time: 'time',
  world: 'world',

  // Old icons still in Use
  dayswapsLogo: 'dayswaps-logo',
  lockOpen: 'lock-open',
  standbyPlugin: 'standby-plugin',
  menuDots: 'menu-dots',
  chart: 'chart',
  cancel: 'cancel',
  clipboard: 'clipboard',
  link: 'link',
  stats: 'stats',
  monitor: 'monitor',
  standby: 'standby',
  score: 'score',
  deselect: 'deselect',
  assign: 'assign',
  building: 'building',
  vacation: 'vacation',
  morning: 'morning',
  sun: 'sun',
  moon: 'moon',
  law: 'law',
  sort: 'sort',

  // file type icons
  docx: 'file-docx',
  jpg: 'file-jpg',
  jpeg: 'file-jpg',
  pdf: 'file-pdf',
  png: 'file-png',
  xlsx: 'file-xlsx',
  zip: 'file-zip',
  file: 'file' // fallback for other file types
})

Icon.propTypes = {
  ico: PropTypes.oneOf(Object.values(ICONS)),
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  testid: PropTypes.string
}
Icon.defaultProps = {
  ico: null,
  color: COLORS.INHERIT,
  size: SIZES.MEDIUM,
  onClick: null,
  disabled: false,
  testid: undefined
}
Icon.SIZES = SIZES
Icon.COLORS = COLORS
Icon.ICONS = ICONS
