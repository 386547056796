import React, { useEffect, useState } from 'react'
import {
  Dropdown,
  Input,
  Position
} from '@ui'
import moment from 'moment'
import { t } from 'i18next'
import { calendarUtil } from '@app/util'

export const ShiftTemplates = ({
  workspace,
  positions,
  type,
  shiftDetail,
  detailsState,
  disabledEditation,
  newTemplateName,
  setNewTemplateName,
  applyTemplate,
  errors,
  setErrors
}) => {
  // options for template selection dropdown
  const optsTemplates = workspace
    ? (workspace.shiftTemplates || [])
      .map(tpl => {
        const tplPos = (positions && positions.length && tpl.shift)
          ? positions.find(p => p.id === tpl.shift.positionId)
          : null
        return {
          value: tpl.name,
          label: (
            <>
              <span><b>{tpl.name}</b></span>
              <span>{moment(tpl.shift.period.start).format('HH:mm')}</span>
              <span>-</span>
              <span>{moment(tpl.shift.period.end).format('HH:mm')}</span>
              <span className='ds-es-tpl-col-pause'>{(tpl.shift && tpl.shift.pausesFixed)
                ? ((tpl.shift && tpl.shift.pauses) ? tpl.shift.pauses.reduce((a, s) => { return a + s.duration }, 0) : 0) + t('MINUTE_SHORT')
                : t('SHIFT_TEMPLATE_PAUSES_AUTO')}
              </span>
              <span><Position sm {...tplPos} /></span>
              <span>{calendarUtil.getShiftMetaText(tpl.shift, workspace)}</span>
            </>),
          id: tpl.id
        }
      })
    : []

  const getMatchingTemplate = () => {
    // don't match or do anything if we're editing/creating a shift template via 'edit-shift-template'/'extra-shift-template' modal (at /workspace/shift-templates page)
    if (type === 'shiftTemplate') return false

    // set the contents of 'details' accordingly
    let dets = detailsState
    if (type !== 'template' && shiftDetail) {
      dets = Object.assign({}, shiftDetail, detailsState) // for live, try using shiftDetails
    }
    if (!dets.period && dets.start && dets.duration) {
      const pd = dets.day ? moment(dets.day) : moment().startOf('day')
      dets.period = {
        start: pd.clone().add(dets.start, 'minutes'),
        end: pd.clone().add((dets.start + dets.duration), 'minutes')
      }
    }

    // check if the shift matches some shift template
    return calendarUtil.getShiftsTemplate(dets, workspace)
  }

  // refresh matching template whenever needed
  const [matchingTemplate, setMatchingTemplate] = useState(getMatchingTemplate())
  useEffect(() => { setMatchingTemplate(getMatchingTemplate()) }, [shiftDetail])
  useEffect(() => { setTimeout(() => { setMatchingTemplate(getMatchingTemplate()) }, 200) }, [detailsState])

  return (
    <>
      <div style={{ fontWeight: '700', margin: '0.25rem 0' }}>
        {t(type === 'shiftTemplate' ? 'SHIFT_TEMPLATES_MODAL_NAME' : 'SHIFT_TEMPLATE')}
      </div>
      <div className='ds-shiftinfo-col-value-tpl' style={{ alignItems: 'flex-end', display: 'flex' }}>
        <div className='ds-es-tpl'>
          {type === 'shiftTemplate'
            ? (
              <>
                {/* editing or creating a shift template from /workspace/templates page via 'edit-shift-template' or 'extra-shift-template' modal */}
                <Input
                  className='ds-es-tpl-new-name'
                  placeholder={t('SHIFT_TEMPLATE_NEW_PLACEHOLDER')}
                  onChange={(v) => {
                    setNewTemplateName(v)

                    // remove the 'shift-template-name-missing' error when user types in some template name
                    if (v && v.trim() !== '' && errors.includes('shift-template-name-missing')) {
                      const newErrs = errors || []
                      newErrs.splice(newErrs.indexOf('shift-template-name-missing'), 1)
                      setErrors(newErrs)
                    }
                  }}
                  value={newTemplateName}
                  disabled={disabledEditation}
                  hasError={errors && errors.includes('shift-template-name-missing')}
                  errorMessage={errors && errors.includes('shift-template-name-missing') ? t('SHIFT_TEMPLATE_NAME_REQUIRED') : null}
                  size={Input.SIZES.LARGE}
                />
              </>
            )
            : (
              <Dropdown
                singleSelect
                searchable
                sortedOptions={false}
                size={Dropdown.SIZES.LARGE}
                type={Dropdown.TYPES.VARIABLE}
                style={Dropdown.STYLES.LIGHT}
                options={optsTemplates}
                value={matchingTemplate ? [optsTemplates.find(t => t.id === matchingTemplate.id)].filter(Boolean) : []}
                placeholder={(matchingTemplate && matchingTemplate.shift)
                  ? matchingTemplate.name
                  : t('SHIFT_TEMPLATE_NOTPL')}
                onChange={(v) => {
                  const tpl = (workspace.shiftTemplates || []).find(tp => tp.id === v.id)
                  if (tpl) {
                    applyTemplate(tpl)
                  }
                }}
                disabled={disabledEditation}
              />
            )}
        </div>
      </div>
    </>
  )
}
