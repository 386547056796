import isLoading from './is-loading'
import setCycleDetail from '../action/set-cycle-detail'
import store from '../store'

export default (lastSavedCycleShift) => {
  return async (dispatch) => {
    const { cycleDetail } = store.getState()

    dispatch(isLoading('set-cycle-detail-last-saved'))
    await dispatch(setCycleDetail({ calendar: cycleDetail.calendar, data: cycleDetail.data, lastSavedCycleShift: lastSavedCycleShift }))

    dispatch(isLoading('set-cycle-detail-last-saved', true))
  }
}
