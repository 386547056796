import React from 'react'
import { t } from 'i18next'

import { Icon, Modal, Flex } from '@ui'

import connect from './connect'
import './index.scss'

const UnsupportedBrowser = ({ browser }) => (
  <Modal
    noClose
    size={Modal.SIZES.S}
    className='ds-unsupported-browser'
    headerContent={
      <div className='ds-title'>{t('UNSUPPORTED_BROWSER_TITLE')}</div>
    }
    sections={[
      <Flex key={0}>
        <Icon ico='cancel' size={Icon.SIZES.LARGE} />
        <div className='ds-ub-subtitle'>
          {t('UNSUPPORTED_BROWSER_SUBTITLE') + ' '}
          <span
            className='ds-link'
            onClick={() => {
              const win = window.open(
                'https://www.google.com/chrome/',
                '_blank'
              )
              win.focus()
            }}
          >
            {t('UNSUPPORTED_BROWSER_SUBTITLE_CTA')}
          </span>
        </div>
      </Flex>
    ]}
    footerContent={
      <>
        {browser ? (
          <span>{browser.name} ver. {browser.version}</span>
        ) : null}
      </>
    }
  />
)

export default connect(UnsupportedBrowser)
