"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const ac_1 = require("@app/ac");
const mapStateToProps = ({ tablesConfig }) => {
    return {
        tablesConfig
    };
};
const mapDispatchToProps = (dispatch) => {
    return (0, redux_1.bindActionCreators)({
        updateTablesConfig: ac_1.updateTablesConfig
    }, dispatch);
};
exports.default = (component) => {
    return (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps)(component);
};
