import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `mutation ShiftDeleteStandByActivityMutation (
  $workspace: ID!,
  $shift: ID!,
  $activity: StandByActivityInput!
) {
  shiftDeleteStandByActivity (
    workspace: $workspace
    shift: $shift
    activity: $activity
  ) ${q.SHIFT_BASIC}
}`

async function requestShiftDeleteStandByActivity (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-delete-standby-activity', errors)
  }
  return data.shiftDeleteStandByActivity
}

export default requestShiftDeleteStandByActivity
