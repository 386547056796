import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { setModal, createCountry } from '@app/ac'

const mapStateToProps = ({
  workspaceId,
  organization,
  organizationId
}) => {
  return {
    workspaceId,
    countries: organization.countries || [],
    organizationId
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    close: () => setModal(null),
    createCountry
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
