import { requestWorkspace } from '@app/request'

import isLoading from './is-loading'
import {
  addError,
  setModal
} from '../ac'
import setOrganizationId from './set-organization-id'
import setWorkspaces from '../action/set-workspaces'
import store from '../store'
import { setOrg } from '@app/action'

export default (id, silent, shouldNotWait = false) => {
  return async (dispatch) => {
    const { auth, workspaces } = store.getState()
    let { loading } = store.getState()
    if (!id || !workspaces) return

    // if workspace-detail is already running, try waiting for it to finish first
    if (loading.includes('workspace-detail') && !shouldNotWait) {
      let w = 0
      const maxW = 60
      while (loading.includes('workspace-detail') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      if (w < maxW) return workspaces.find(w => w.id === id)
    }

    if (!silent) dispatch(isLoading('workspace-detail'))
    const a = await requestWorkspace(id, auth)
    const resultWS = { ...a }

    if (resultWS.error) {
      dispatch(addError(resultWS.error))
      if (!silent) dispatch(isLoading('workspace-detail', true))
    }

    if (resultWS.id) {
      let newWorkspaces = [...workspaces]
      if (!newWorkspaces.some(ws => ws.id === resultWS.id)) {
        newWorkspaces.push(resultWS)
      } else {
        newWorkspaces = workspaces.map(ws => ws.id === resultWS.id ? resultWS : ws)
      }
      await dispatch(setWorkspaces(newWorkspaces))
      if (resultWS?.organization?.settings) {
        const { organization } = store.getState()
        const newOrg = (newItem) => ({
          ...organization,
          settings: newItem?.settings,
          hidden: newItem?.hidden
        })
        await dispatch(setOrg(newOrg(resultWS?.organization)))

        // set store.organizationId to organizationId of loaded workspace
        await dispatch(setOrganizationId(resultWS?.organizationId))
      }

      // display uncloseable "setup-mfa" modal if needed
      const { me } = store.getState()
      const wsRole = me?.roles?.find(r => r.workspaceId === resultWS.id)
      const requiredWsMethods = resultWS.settings?.mfa?.allowedMethods || []
      const hasAtleastOneMethod = me?.mfa?.factors?.some(f => requiredWsMethods.includes(f.type))
      const showMFAModal = wsRole?.enforceMfa && (!me?.mfa?.enabled || !hasAtleastOneMethod)

      if (showMFAModal) {
        dispatch(setModal('setup-mfa', { mode: 'setup', noClose: true, showNav: false, wsId: resultWS._id }))
      }
    }
    if (!silent) dispatch(isLoading('workspace-detail', true))

    return resultWS
  }
}
