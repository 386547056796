import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation NotificationsGroupCreate(
  $workspaceId: ID!, 
  $data: NotificationsGroupSettingsInput!
) {
  notificationsGroupCreate (
    workspaceId: $workspaceId,
    data: $data
  ) {
    id
    name
  }
}`

async function requestNotificationsGroupCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('notifications-group-create', errors)
  }
  return data.notificationsGroupCreate
}

export default requestNotificationsGroupCreate
