import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestCycleCreate from '../request/cycle-create'
import requestCycleUpdate from '../request/cycle-update'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import moment from 'moment'

export default (data, cycleId) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !data) return

    dispatch(isLoading('save-cycle'))
    let result = null
    if (cycleId) {
      // if 'cycleId' is set, we're modifying the existing cycle
      result = await requestCycleUpdate({
        id: cycleId,
        workspace: workspaceId,
        data: {
          title: data.title,
          startDate: moment(data.startDate).format('YYYY-MM-DD'),
          length: parseInt(data.length),
          shifts: data.shifts || []
        }
      }, auth)
    } else {
      // otherwise, we're just creating a new cycle
      result = await requestCycleCreate({
        workspace: workspaceId,
        data: {
          title: data.title,
          startDate: moment(data.startDate).format('YYYY-MM-DD'),
          length: parseInt(data.length),
          shifts: data.shifts.filter(s => s.start < (data.length * 1440)) || []
        }
      }, auth)
    }

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('save-cycle', true))
    return result
  }
}
