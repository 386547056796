import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation OfferResolve (
    $workspace: ID!
    $offer: ID!
    $user: ID!
    $shift: ID
  ) {
    offerResolve (
      workspace: $workspace
      offer: $offer
      user: $user
      shift: $shift
    ){
      id
    }
  }`

async function requestOfferResolve (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('offer-resolve', errors)
  }
  return data.offerResolve
}

export default requestOfferResolve
