import setWorkspaces from '@app/action/set-workspaces'
import store from '../store'

export default () => {
  return async (dispatch) => {
    const { workspaces, workspaceId } = store.getState()
    if (!workspaceId || !workspaces) return

    const newWorkspaces = workspaces.map(ws => {
      return ws.id === workspaceId
        ? Object.assign({}, ws, { currentPlan: null })
        : ws
    })
    await dispatch(setWorkspaces(newWorkspaces))
  }
}
