import isLoading from './is-loading'
import setCycleDetail from '../action/set-cycle-detail'
import store from '../store'

export default (cycle) => {
  return async (dispatch) => {
    const { cycleDetail } = store.getState()

    if (!cycle) return

    dispatch(isLoading('set-cycle-detail'))
    cycleDetail.data.push(cycle)
    await dispatch(setCycleDetail({ calendar: cycleDetail.calendar, data: cycleDetail.data, lastSavedCycleShift: cycleDetail.lastSavedCycleShift }))
    dispatch(isLoading('set-cycle-detail', true))
  }
}
