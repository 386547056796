import fetchRequest from '../../util/fetch'
import errUtil from '../../util/error'
import q from '../query'

const query = `mutation OrganizationTransferCreateMutation (
  $organization: ID!
  $fromWorkspace: ID!
  $toWorkspace: ID!
  $data: ShiftData!
) {
  organizationTransferCreate (
    organization: $organization
    fromWorkspace: $fromWorkspace
    toWorkspace: $toWorkspace
    data: $data
  ) {
    ${q.TRANSFER_DETAILS_PARAMS}
  }
}`

async function requestOrganizationTransferCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('organization', errors)
  }
  return data.organizationTransferCreate
}

export default requestOrganizationTransferCreate
