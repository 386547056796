import store from '@app/store'
import aT from '@app/const/action-type'
import { setOrg } from '@app/action'
import { requestOrganizationBasic, requestOrganizationDetailed, requestOrganizationTransfersGroups } from '@app/request'
import setOrganizationId from './set-organization-id'
import isLoading from './is-loading'
import { addError } from './index'

export const getOrganization = (loadingEnabled = true) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()
    if (!organizationId) return
    const oldOrg = { ...organization }

    loadingEnabled && dispatch(isLoading(aT.SET_ORG))

    // load the basic organization data synchronously (relatively fast query, crucial data for everything)
    const a = await requestOrganizationBasic({ organizationId }, auth)
    const basicDataResult = { ...a }
    if (basicDataResult) {
      if (basicDataResult && basicDataResult.error) {
        await dispatch(addError(basicDataResult.error))
        await dispatch(setOrg(oldOrg))
      } else {
        // also get the list of all the workspaces in ORG that the current user has access to and add it under store.organization.allAllowedWorkspaces
        const allAllowedWorkspaces = []
        if (basicDataResult?.countries?.length) {
          basicDataResult.countries.forEach(country => {
            if (country.areas) {
              country.areas.forEach(area => {
                if (area.workspaces) {
                  area.workspaces.forEach(ws => {
                    if (!allAllowedWorkspaces.includes(ws.id)) allAllowedWorkspaces.push(ws.id)
                  })
                }
              })
            }
          })
        }
        basicDataResult.allAllowedWorkspaces = allAllowedWorkspaces
        await dispatch(setOrg(basicDataResult))

        // set store.organizationId to organizationId of loaded workspace
        await dispatch(setOrganizationId(basicDataResult.id))
      }
    }
    loadingEnabled && dispatch(isLoading(aT.SET_ORG, true))

    // load the transferGroups organization data
    loadingEnabled && dispatch(isLoading(aT.SET_ORG_TRANSFERS_GROUPS))
    let transfersGroupsData = null
    requestOrganizationTransfersGroups({ organizationId }, auth).then(transfersGroupsDataResult => {
      if (transfersGroupsDataResult && transfersGroupsDataResult.error) {
        dispatch(addError(transfersGroupsDataResult.error))
      } else {
        transfersGroupsData = transfersGroupsDataResult
        dispatch(setOrg(Object.assign({}, basicDataResult, transfersGroupsDataResult)))
      }
      loadingEnabled && dispatch(isLoading(aT.SET_ORG_TRANSFERS_GROUPS, true))
    })

    // load the detailed organization data asynchronously in the background (slow query)
    loadingEnabled && dispatch(isLoading(aT.SET_ORG_DETAILS))
    requestOrganizationDetailed({ organizationId }, auth).then(detailedDataResult => {
      if (detailedDataResult && detailedDataResult.error) {
        dispatch(addError(detailedDataResult.error))
      } else {
        dispatch(setOrg(Object.assign({}, basicDataResult, transfersGroupsData, detailedDataResult)))
      }
      loadingEnabled && dispatch(isLoading(aT.SET_ORG_DETAILS, true))
    })
  }
}
