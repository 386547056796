import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation NotificationsGroupRemove(
  $id: ID!, 
  $workspaceId: ID!, 
) {
  notificationsGroupRemove (
    id: $id,
    workspaceId: $workspaceId,
  )
}`

async function requestNotificationsGroupDelete (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('notifications-group-delete', errors)
  }
  return data.notificationsGroupRemove
}

export default requestNotificationsGroupDelete
