import isLoading from './is-loading'
import addError from '../ac/add-error'
import store from '../store'

import { requestCreateTimeOffRequest } from '@app/request'

export default (variables) => {
  return async (dispatch) => {
    const { auth } = store.getState()

    dispatch(isLoading('create-unavailability-request'))
    const resultActions = await requestCreateTimeOffRequest(variables, auth)
    if (resultActions.error) {
      dispatch(addError(resultActions.error))
      dispatch(isLoading('create-unavailability-request', true))
    }

    dispatch(isLoading('create-unavailability-request', true))
    return resultActions
  }
}
