import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation WorkspaceEventCreateMutation (
    $workspaceId: ID!
    $data: EventData!
  ) {
  eventCreate(
    workspace: $workspaceId
    data: $data
  ) {
    id
  }
}`

async function requestWorkspaceEventCreate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('workspace-event-create', errors)
  }
  return data.eventCreate
}

export default requestWorkspaceEventCreate
