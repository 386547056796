"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.removedUserAttribute = exports.unsetUserAttributes = exports.updatedUserAttribute = exports.addedUserAttribute = exports.setUserAttributes = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    userAttributes: [],
};
const userAttributesSlice = (0, toolkit_1.createSlice)({
    name: 'userAttributes',
    initialState,
    reducers: {
        setUserAttributes: (state, action) => {
            state.userAttributes = action.payload;
        },
        updatedUserAttribute: (state, action) => {
            const _temp = state.userAttributes.filter(ua => ua.id !== action.payload.id);
            state.userAttributes = [..._temp, action.payload];
        },
        addedUserAttribute: (state, action) => {
            state.userAttributes.push(action.payload);
        },
        unsetUserAttributes: (state) => {
            state.userAttributes = [];
        },
        removedUserAttribute: (state, action) => {
            state.userAttributes = state.userAttributes.filter((ua) => ua.id !== action.payload);
        },
    },
});
exports.default = userAttributesSlice.reducer;
_a = userAttributesSlice.actions, exports.setUserAttributes = _a.setUserAttributes, exports.addedUserAttribute = _a.addedUserAttribute, exports.updatedUserAttribute = _a.updatedUserAttribute, exports.unsetUserAttributes = _a.unsetUserAttributes, exports.removedUserAttribute = _a.removedUserAttribute;
