import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { apiEndpoints } from '@app/const/config'

const { location: { hostname } } = window
const { [hostname]: { backend } } = apiEndpoints

// DEPRECATED - delete usages

export const ApolloClientFetch = (auth, requestName) => {
  const Options = {
    cache: new InMemoryCache({
      addTypename: false
    }),
    link: new HttpLink({
      uri: requestName ? (backend.replace(/\/$/, '') + '/' + requestName) : backend,
      headers: auth && {
        Authorization: `Bearer ${auth}`
      }
    })
  }
  return new ApolloClient({
    ...Options
  })
}
