export const ALLOWED_FILES = [
  {
    mime: 'application/pdf',
    ext: 'pdf'
  },
  {
    mime: 'application/zip',
    ext: 'zip'
  },
  {
    mime: 'image/png',
    ext: 'png'
  },
  {
    mime: 'image/jpeg',
    ext: 'jpg'
  },
  {
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ext: 'xlsx'
  },
  {
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ext: 'docx'
  }
]
