import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import setCalendar from '@app/ac/set-calendar'
import toggleTargetCalendarMultiSelect from '@app/ac/toggle-target-calendar-multi-select'
import createWorkspaceEvent from '@app/ac/create-workspace-event'
import deleteWorkspaceEvent from '@app/ac/delete-workspace-event'
import clearLiveDay from '@app/ac/clear-live-day'

const mapStateToProps = ({ calendar, workspaceEvents, holidays, workspaceId, workspaces }) => {
  const ws = workspaces && workspaceId ? workspaces.find(w => w.id === workspaceId) : null
  return {
    workspace: ws,
    localities: ws ? ws.localities : null,
    workspaceEvents,
    holidays,
    calendar
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    setCalendar,
    clearLiveDay,
    createWorkspaceEvent,
    deleteWorkspaceEvent,
    toggleTargetCalendarMultiSelect
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
