import { fetchRequest, notification } from '@app/util'

const query = `mutation Mutation($country: ID!, $data: ExternalContactInput!, $organization: ID!) {
  organizationExternalContactCreate(country: $country, data: $data, organization: $organization) {
    id
  }
}`

async function requestExternalContactCreate (variables, token) {
  const response = await fetchRequest({ query, variables, token })

  const { errors, data } = await response
  if (errors) {
    notification.error({ message: 'something went wrong' })
    return false
  }
  return data.organizationExternalContactCreate
}

export default requestExternalContactCreate
