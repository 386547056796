import React, { useState } from 'react'
import { t } from 'i18next'
import {
  Alert,
  Button,
  Flex,
  Modal,
  RadioGroup,
  Spacing,
  Text
} from '@ui'
import RecordsList from '@app/component/modals/confirm/records-list'
import connect from './connect'
import { miscUtil } from '@app/util'

export const ActionsTimeOffConflict = connect(({
  conflictingShifts,
  setModal,
  resultTimeOffs,
  handleTimeOffConflict,
  organizationId,
  isPluginEnabled
}) => {
  const radioOptions = [
    miscUtil.getFilteredContentForOrganization('timeoff_conflict_dropdown_option4', {
      label: t('TIMEOFF_CONFLICT_ACTION4'),
      value: 'unassign-shift'
    }),
    (isPluginEnabled('offers')
      ? miscUtil.getFilteredContentForOrganization('timeoff_conflict_dropdown_option5', {
        label: t('TIMEOFF_CONFLICT_ACTION5'),
        value: 'offer-shift'
      })
      : null),

    // {
    //  label: t('TIMEOFF_CONFLICT_ACTION2'),
    //  value: 'delete-timeoffs'
    // },

    miscUtil.getFilteredContentForOrganization('timeoff_conflict_dropdown_option3', {
      label: t('TIMEOFF_CONFLICT_ACTION3'),
      value: 'ignore'
    }),
    miscUtil.getFilteredContentForOrganization('timeoff_conflict_dropdown_option1', {
      label: t('TIMEOFF_CONFLICT_ACTION1'),
      value: 'short-shift'
    })

  ].filter(Boolean)

  const lastSelectedType = window.localStorage.getItem('ds-selected-conflict-resolution-type')

  const [actionType, setType] = useState(lastSelectedType && radioOptions.some(ro => ro.value === lastSelectedType) ? lastSelectedType : radioOptions[0].value)
  const [isSaving, setIsSaving] = useState(false)

  const handleSave = () => {
    setIsSaving(true)
    handleTimeOffConflict(
      conflictingShifts,
      resultTimeOffs,
      actionType
    ).then(() => {
      setIsSaving(false)
    })
  }

  const afterCloseFn = () => {
    handleTimeOffConflict(
      conflictingShifts,
      resultTimeOffs,
      'delete-timeoffs'
    )
  }

  return (
    <Modal
      afterClose={afterCloseFn}
      size={Modal.SIZES.L}
      noClose
      headerContent={
        <Text
          text={t('CONFLICTING_SHIFTS_TITLE')}
          type={Text.TYPES.BODY_LARGE}
          weight={Text.WEIGHTS.BOLD}
        />
      }
      footerContent={
        <Flex grow={1} justify={Flex.POSITION.END}>
          {/* <Button
            label={t('CANCEL')}
            onClick={() => {
              afterCloseFn()
              setModal(null)
            }}
          /> */}
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            label={t('CONFIRM')}
            style={Button.STYLES.CONTAINED}
            onClick={handleSave}
            loading={isSaving}
            disabled={isSaving}
          />
        </Flex>
      }
    >
      <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
        <Alert
          text={t('SAVE_UNAV_CONFLICTS_SHIFTS_SUBTITLE')} S
          size={Alert.SIZES.FULL_WIDTH}
          type={Alert.TYPES.WARNING}
        />
        <Spacing size={Spacing.SIZES.SIZE_16} />
        <RecordsList records={conflictingShifts} />
        <Spacing size={Spacing.SIZES.SIZE_16} />
        <RadioGroup
          label={t('ACTIONS') + ' (' + conflictingShifts.length + ')'}
          direction={RadioGroup.DIRECTIONS.VERTICAL}
          value={actionType}
          onChange={(v) => {
            setType(v)
            window.localStorage.setItem('ds-selected-conflict-resolution-type', v)
          }}
          options={radioOptions}
        />
      </Spacing>
    </Modal>
  )
})
