import { createBrowserHistory } from 'history'

const ownHistory = createBrowserHistory()

const navigate = (path) => ownHistory.push(path)

export default {
  navigate,
  history: ownHistory
}
