import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query DummyShiftCandidatesTransferQuery (
  $workspaceId: ID!,
  $data: ShiftData!
) {
  dummyShift(workspace: $workspaceId, data: $data) {
    transferCandidates {
      workspaceId
      candidatesCount
    }
  }
}`

async function requestShiftCandidatesTransfer (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-candidates-transfer', errors)
  }
  return data.dummyShift.transferCandidates
}

export default requestShiftCandidatesTransfer
