import {
  requestWorkspaceCreate,
  requestWorkspaceDelete,
  requestWorkspaceUpdate,
  requestOrgCountries,
  requestWorkspaceAreaMove
} from '@app/request'
import { setOrg } from '@app/action'

import aT from '../const/action-type'
import { addError, setModal, getOrganization } from '../ac'
import store from '../store'

import isLoading from './is-loading'

export const createWorkspace = (form) => {
  return async (dispatch) => {
    const { auth, organizationId } = store.getState()
    const result = await requestWorkspaceCreate({
      ...form,
      area: form.area.value,
      organization: organizationId
    }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
    } else {
      await dispatch(getOrganization())
      dispatch(setModal(null))
    }
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}

export const deleteWorkspace = (id, countryId) => {
  return async (dispatch) => {
    const { auth, organization } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = {
      ...organization,
      countries: organization.countries.map((country) => {
        if (country.id === countryId) {
          return {
            ...country,
            areas: country.areas.map((area) => ({
              ...area,
              workspaces: area.workspaces.filter((workspace) => workspace.id !== id)
            }))
          }
        } else {
          return country
        }
      })
    }
    dispatch(isLoading(aT.SET_WORKSPACES))
    dispatch(setOrg(newOrg))
    const result = await requestWorkspaceDelete({ id: id }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    } else {
      dispatch(setModal(null))
    }
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}

export const updateWorkspace = (form) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()

    dispatch(isLoading(aT.SET_WORKSPACES))
    const requestMap = []
    form.map(async (item) => {
      if (item.name) {
        requestMap.push(
          requestWorkspaceUpdate({
            id: item.rowId,
            data: {
              name: item.name
            }
          }, auth)
        )
      }
      if (item.areaId) {
        requestMap.push(
          requestWorkspaceAreaMove({
            organization: organizationId,
            area: item.areaId,
            workspace: item.rowId
          }, auth)
        )
      }
    })
    Promise.all(requestMap)
      .catch(() => null)
      .finally(async () => {
        const newCountries = await requestOrgCountries({ organizationId }, auth)
        if (newCountries && newCountries.error) {
          dispatch(addError(newCountries.error))
        } else {
          dispatch(setOrg({
            ...organization,
            countries: newCountries.countries
          }))
        }
      })
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}
