import { fetchRequest, errorUtil } from '@app/util'

const query = `query requestOrgSubscriptionInvoices($organizationId: ID!) {
  organization(id: $organizationId) {
    subscriptionInvoices {
      invoiceNumber
      status
      dueDate
      pdf
      total
    }
  }
}`

async function requestOrgSubscriptionInvoices (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization subscription', errors)
  }
  return data.organization?.subscriptionInvoices
}

export default requestOrgSubscriptionInvoices
