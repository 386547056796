import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (state = initial.exportToken, action) => {
  switch (action.type) {
    case aT.SET_EXPORT_TOKEN:
      if (!action.data) {
        return initial.exportToken
      }
      return action.data
    case aT.RESET_STORE:
      return initial.exportToken
    case aT.SWITCH_WORKSPACE:
      return initial.exportToken
    default:
      return state
  }
}
