import React, { Component, Fragment } from 'react'
import { t } from 'i18next'
import { gql } from '@apollo/client'
import moment from 'moment'

import { CALENDAR_EXPORT_TOKEN } from '@app/request/query'
import { ApolloClientFetch } from '@app/util/apollo-client-fetch'
import { VALUES } from '@app/const/globals'
import { FormField } from '@core'
import {
  Button,
  Dropdown,
  Checkbox,
  Modal,
  Text,
  Radio,
  Icon,
  Flex,
  Spacing, MonthSelect
} from '@ui'

import connect from './connect'
import {
  EXPORT_FORM,
  ENTRY_TYPE_VALUE,
  SELECTED_ENTRY_TYPE,
  MONTH_TYPE_VALUE,
  SAVE_AS_TYPE_VALUE,
  SELECTED_SAVE_AS_TYPE,
  SHIFTS,
  POSITION,
  UNAVAILABILITY,
  LOCALITY,
  EMPLOYEES_WITHOUT_SHIFTS,
  NOTES_DAY,
  NOTES_SHIFT,
  HIDDEN_EMPLOYEES,
  SIGNATURE,
  INITIAL_FORM_VALUES,
  SELECTED_LOCALITY_VALUE,
  SORTING_EMP_TYPE_VALUE,
  COLOR_TYPE_VALUE,
  STATISTICS
} from './constants'
import './index.scss'

class ExportExcel extends Component {
  constructor (props) {
    super(props)
    const { initialize } = props

    initialize({ ...INITIAL_FORM_VALUES })
    this.state = {}
  }

  onSubmit = (formValues) => {
    const {
      me: { language },
      workspaceId,
      auth,
      setModal
    } = this.props
    const {
      [ENTRY_TYPE_VALUE]: entry,
      [MONTH_TYPE_VALUE]: month,
      [SAVE_AS_TYPE_VALUE]: saveAs,
      [SHIFTS]: shift,
      [POSITION]: position,
      [UNAVAILABILITY]: unavailability,
      [LOCALITY]: locality,
      [EMPLOYEES_WITHOUT_SHIFTS]: employeesWithoutShifts,
      [NOTES_DAY]: notesDay,
      [NOTES_SHIFT]: notesShift,
      [HIDDEN_EMPLOYEES]: includeHidden,
      [SIGNATURE]: includeSignature,
      [STATISTICS]: includeStatistics,
      [SELECTED_LOCALITY_VALUE]: selectedLocality,
      [SORTING_EMP_TYPE_VALUE]: sortingEmployeesType,
      [COLOR_TYPE_VALUE]: printColor
    } = formValues

    const variables = {
      format: saveAs,
      workspace: workspaceId,
      type: entry,
      pdfFromXlsx: saveAs !== 'xlsx',
      period: {
        start: moment(month, 'YYYY-MM').startOf('month').toISOString(),
        end: moment(month, 'YYYY-MM').endOf('month').toISOString()
      },
      language,
      withPositions: position,
      includeUnavailabilities: unavailability,
      includeShifts: shift,
      splitByLocality: locality,
      showEveryoneOnWs: employeesWithoutShifts,
      dayNotes: notesDay,
      shiftNotes: notesShift,
      includeHidden,
      includeSignature,
      includeStatistics,
      selectedLocality: selectedLocality?.value ? selectedLocality.value : null,
      sortingEmployeesType: sortingEmployeesType,
      isPrintColored: printColor === 'colored'
    }

    ApolloClientFetch(auth, 'CalendarExportTokenQuery')
      .query({
        query: gql`
          ${CALENDAR_EXPORT_TOKEN}
        `,
        variables
      })
      .then((res) => {
        const {
          data: {
            calendarExportToken: { url }
          }
        } = res
        window.open(url, '_blank')
        setModal(null)
      })
  }

  render () {
    const {
      isLoading,
      changeFormField,
      [VALUES]: values,
      handleSubmit,
      localities,
      setModal
    } = this.props

    const optsFormat = [
      {
        label: t('CALENDAR'),
        value: 'grid'
      },
      {
        label: t('SEZNAM'),
        value: 'basic'
      }
    ]

    const printColorType = [
      {
        label: t('PRINT_COLOR_BW'),
        value: 'colourless'
      },
      {
        label: t('PRINT_COLOR_COLORED'),
        value: 'colored'
      }
    ]

    const sortingFormat = [
      {
        label: t('SORT_ALPH'),
        value: 'alph'
      },
      {
        label: t('CUSTOM'),
        value: 'own'
      }
    ]

    const optsFileType = [
      {
        label: 'Excel (.xlsx)',
        value: 'xlsx'
      },
      values && values[SELECTED_ENTRY_TYPE] !== t('SEZNAM') // PDF format for 'basic' export is not possible
        ? {
          label: 'PDF (.pdf)',
          value: 'pdf'
        }
        : null
    ].filter(Boolean)

    const handleInitSelectedLocality = async () => {
      const { localities } = this.props
      if (!localities || !localities.length) return null

      const resObj = { label: localities[0].name, value: localities[0].id }
      await changeFormField(SELECTED_LOCALITY_VALUE, resObj)

      return resObj
    }

    return (
      <form name={EXPORT_FORM} onSubmit={handleSubmit(this.onSubmit)}>
        <Modal
          size={Modal.SIZES.M}
          className='ds-export-excel'
          headerContent={<>{t('TABLE_EXPORT')}</>}
          sections={[
            <Fragment key={0}>
              <div className='ds-exp-notice-wrapper'>
                <Text
                  className='ds-exp-notice-text'
                  key='notice'
                  type={Text.TYPES.BODY_MEDIUM}
                  weight={Text.WEIGHTS.BOLD}
                  color={Text.COLORS.WARNING}
                >
                  <Flex align={Flex.POSITION.CENTER}>
                    <Icon ico='info' />
                    {t('PRINT_NOTE')}
                  </Flex>
                </Text>
              </div>
              <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8} />
              <Flex
                direction={Flex.DIRECTION.COLUMN}
                justify={Flex.POSITION.SPC_EVENLY}
              >
                <Flex
                  direction={Flex.DIRECTION.COLUMN}
                  justify={Flex.POSITION.SPC_BETWEEN}
                >
                  <Spacing size={Spacing.SIZES.SIZE_8} />
                  <MonthSelect
                    onChange={(val) => {
                      return new Promise((resolve) => {
                        resolve(
                          changeFormField(MONTH_TYPE_VALUE, moment(val).format('YYYY-MM'))
                        )
                      })
                    }}
                    value={values ? values[MONTH_TYPE_VALUE] : null}
                    label={t('MONTH')}
                  />
                  <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_6} />
                  <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8}>
                    <Text
                      weight={Text.WEIGHTS.BOLD}
                      type={Text.TYPES.BODY_MEDIUM}
                    >
                      {t('EXPORT_FORMAT')}
                    </Text>
                  </Spacing>
                  <Flex direction={Flex.DIRECTION.ROW}>
                    {optsFormat.map((format, index) => {
                      return (
                        <Fragment key={index}>
                          <Radio
                            value={values && values[ENTRY_TYPE_VALUE] === format.value} label={format.label}
                            onClick={async () => {
                              await changeFormField(ENTRY_TYPE_VALUE, format.value)
                              if (format.value === 'basic' && values[SAVE_AS_TYPE_VALUE] === 'pdf') {
                                await changeFormField(SAVE_AS_TYPE_VALUE, 'xlsx')
                                await changeFormField(SELECTED_SAVE_AS_TYPE, 'Excel (.xlsx)')
                              }
                            }}
                          />
                          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_16} />
                        </Fragment>
                      )
                    })}
                  </Flex>
                  <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_6} />
                  <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8}>
                    <Text
                      weight={Text.WEIGHTS.BOLD}
                      type={Text.TYPES.BODY_MEDIUM}
                    >
                      {t('SAVE_AS')}
                    </Text>
                  </Spacing>
                  <Flex direction={Flex.DIRECTION.ROW}>
                    {optsFileType.map((file, index) => {
                      if (values && values[ENTRY_TYPE_VALUE] === 'basic' && file.value === 'pdf') {
                        return null
                      }
                      return (
                        <Fragment key={index}>
                          <Radio
                            value={values && values[SELECTED_SAVE_AS_TYPE]
                              ? file.label === values[SELECTED_SAVE_AS_TYPE]
                              : file.label === 'Excel (.xlsx)'}
                            label={file.label}
                            onClick={async () => {
                              await changeFormField(SAVE_AS_TYPE_VALUE, file.value)
                              await changeFormField(SELECTED_SAVE_AS_TYPE, file.label)
                            }}
                          />
                          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_16} />
                        </Fragment>
                      )
                    })}
                  </Flex>
                  {values && values[ENTRY_TYPE_VALUE] === 'grid' && (
                    <>
                      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_6} />
                      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8}>
                        <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM}>
                          {t('SORT_EMPLOYEES')}
                        </Text>
                      </Spacing>
                      <Flex direction={Flex.DIRECTION.ROW}>
                        {sortingFormat.map((sort, index) => {
                          return (
                            <Fragment key={index}>
                              <Radio
                                value={values && values[SORTING_EMP_TYPE_VALUE] === sort.value} label={sort.label}
                                onClick={async () => {
                                  await changeFormField(SORTING_EMP_TYPE_VALUE, sort.value)
                                }}
                              />
                              <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_16} />
                            </Fragment>
                          )
                        })}
                      </Flex>
                    </>
                  )}
                  {values && values[ENTRY_TYPE_VALUE] === 'grid' && (
                    <>
                      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_6} />
                      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8}>
                        <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM}>
                          {t('PRINT_COLOR')}
                        </Text>
                      </Spacing>
                      <Flex direction={Flex.DIRECTION.ROW}>
                        {printColorType.map((colorType, index) => {
                          return (
                            <Fragment key={index}>
                              <Radio
                                value={values && values[COLOR_TYPE_VALUE] === colorType.value} label={colorType.label}
                                onClick={async () => {
                                  await changeFormField(COLOR_TYPE_VALUE, colorType.value)
                                }}
                              />
                              <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_16} />
                            </Fragment>
                          )
                        })}
                      </Flex>
                    </>
                  )}
                </Flex>
                <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_6} />
                {values && values[ENTRY_TYPE_VALUE] === 'grid' && (
                  <Flex
                    direction={Flex.DIRECTION.COLUMN}
                    justify={Flex.POSITION.SPC_BETWEEN}
                    align={Flex.POSITION.START}
                  >
                    <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8}>
                      <Text
                        weight={Text.WEIGHTS.BOLD}
                        type={Text.TYPES.BODY_MEDIUM}
                      >
                        {t('PRINT')}
                      </Text>
                    </Spacing>
                    <FormField name={SHIFTS}>
                      <Checkbox>{t('SHIFTS_REPORT')}</Checkbox>
                    </FormField>
                    <FormField name={POSITION}>
                      <Checkbox>{t('SHIFT_POSITION')}</Checkbox>
                    </FormField>
                    <FormField name={UNAVAILABILITY}>
                      <Checkbox>{t('ROW_TOOLTIP_CONTRACT_UNAVS')}</Checkbox>
                    </FormField>
                    <FormField name={LOCALITY}>
                      <Checkbox>{t('LOCALITIES')}</Checkbox>
                    </FormField>
                    <FormField name={EMPLOYEES_WITHOUT_SHIFTS}>
                      <Checkbox>{t('EMPLOYEE_WITHOUT_SHIFT')}</Checkbox>
                    </FormField>
                    <FormField name={NOTES_DAY}>
                      <Checkbox>{t('NOTES_DAY')}</Checkbox>
                    </FormField>
                    <FormField name={NOTES_SHIFT}>
                      <Checkbox>{t('NOTES_SHIFT')}</Checkbox>
                    </FormField>
                    <FormField name={HIDDEN_EMPLOYEES}>
                      <Checkbox>{t('HIDDEN_EMPLOYEES')}</Checkbox>
                    </FormField>
                    <FormField name={SIGNATURE}>
                      <Checkbox>{t('SIGNATURE')}</Checkbox>
                    </FormField>
                    <FormField name={STATISTICS}>
                      <Checkbox>{t('STATISTICS')}</Checkbox>
                    </FormField>
                  </Flex>
                )}
                {values && values[LOCALITY] && localities && (
                  <>
                    <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_6} />
                    <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8}>
                      <Text
                        weight={Text.WEIGHTS.BOLD}
                        type={Text.TYPES.BODY_MEDIUM}
                      >
                        {t('LOCALITIES')}
                      </Text>
                    </Spacing>
                    <Dropdown
                      sortedOptions={false}
                      singleSelect
                      size={Dropdown.SIZES.FULL_WIDTH}
                      type={Dropdown.TYPES.VARIABLE}
                      style={Dropdown.STYLES.OUTLINED}
                      options={localities?.map((loc) => ({
                        label: loc.name,
                        value: loc.id
                      }))}
                      value={values[SELECTED_LOCALITY_VALUE] ? values[SELECTED_LOCALITY_VALUE] : handleInitSelectedLocality()}
                      placeholder={t('LOCALITIES')}
                      onChange={(val) => { changeFormField(SELECTED_LOCALITY_VALUE, val) }}
                    />
                  </>
                )}
              </Flex>
            </Fragment>
          ]}
          footerContent={
            <Flex grow={1}>
              <Button
                label={t('CLOSE')}
                onClick={() => setModal(null)}
                size={Button.SIZES.FULL}
              />
              <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
              <Button
                size={Button.SIZES.FULL}
                style={Button.STYLES.CONTAINED}
                htmlType={Button.HTML_TYPES.SUBMIT}
                label={t('EXPORT_SUBMIT')}
              />
            </Flex>
          }
          isLoading={isLoading}
        />
      </form>
    )
  }
}

export default connect(ExportExcel)
