import isLoading from './is-loading'
import requestPositions from '../request/positions'
import addError from '../ac/add-error'
import setPositions from '../action/set-positions'
import store from '../store'

export default (localWsId) => {
  return async (dispatch) => {
    const { auth, workspaceId: storeWsId } = store.getState()
    const workspaceId = localWsId || storeWsId
    if (!workspaceId) return

    // if load-positions is already running, try waiting for it to finish first
    let { loading } = store.getState()
    if (loading.includes('load-positions')) {
      let w = 0
      const maxW = 60
      while (loading.includes('load-positions') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      if (w < maxW) return store.getState().positions
    }

    dispatch(isLoading('load-positions'))
    const result = await requestPositions({ id: workspaceId }, auth)
    if (result.error) {
      await dispatch(addError(result.error))
    } else {
      // store the result in the store
      await dispatch(setPositions(result))
    }
    await dispatch(isLoading('load-positions', true))
    return result
  }
}
