import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query WorkspaceEventsQuery (
    $id: ID!
    $period: PeriodFreeInput
    $type: EventType
    $limit: Int
  ) {
  workspace(
    id: $id
  ) {
    events (
      period: $period
      type: $type
      limit: $limit
    ) {
      id
      type
      period { start, end }
      title
      description
    }
  }
}`

async function requestWorkspaceEvents (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('workspace-events', errors)
  }
  return data.workspace.events
}

export default requestWorkspaceEvents
