import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation NotificationsGroupUpdate(
  $workspaceId: ID!, 
  $data: [NotificationsGroupSettingsUpdateInput!]!
) {
  notificationsGroupUpdate (
    workspaceId: $workspaceId,
    data: $data
  ) {
    id
    name
  }
}`

async function requestNotificationsGroupUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('notifications-group-update', errors)
  }
  return data.notificationsGroupUpdate
}

export default requestNotificationsGroupUpdate
