import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query ShiftAssignmentsQuery (
  $workspaceId: ID!,
  $period: PeriodFreeInput!,
) {
  workspace(
    id: $workspaceId
  ) {
    shifts (
      period: $period
      uncommitted: true
    ) {
      id
      userId
      period {
        start
        end
      }
      localityId
      offer {
        status
      }
    }
  }
}`

async function requestShiftAssignments (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shifts', errors)
  }
  return data.workspace.shifts
}

export default requestShiftAssignments
