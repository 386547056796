import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const mapStateToProps = ({ modal, sidebar, auth }) => {
  return {
    scrollDisabled: modal || sidebar,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
