import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestAvailabilitiesUser (variables, token) {
  const query = `query AvailabilitiesUserQuery (
    $user: ID!,
    $workspace: ID!,
    $period: PeriodFreeInput!
  ) {
    user (
      id: $user
    ) {
      availabilities (
        period: $period
        workspace: $workspace
      ) ${q.AVAILABILITY}
    }
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('availabilities', errors)
  }
  return data.user.availabilities
}

export default requestAvailabilitiesUser
