import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation EmployeeCreateMutation (
    $workspace: ID!,
    $userData: UserInputData!, 
    $roleData: RoleInputData, 
  ) {
    employeeCreate (
      workspace: $workspace
      userData: $userData
      roleData: $roleData
    ) {
      id
      roles {
        contracts {
          id
          period {
            start
            end
          }
        }
      }
    }
}`

async function requestEmployeeCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('employee-create', errors)
  }
  return data.employeeCreate
}

export default requestEmployeeCreate
