import React from 'react'
import { Icon } from '@ui'
import { t } from 'i18next'

import { calendarUtil } from '@app/util'

import './index.scss'

class ShiftAttribute extends React.Component {
  render () {
    const { attribute, hideDeletion, onClickDelete, className, style, short } = this.props

    if (!attribute || !attribute.id || !attribute.name || !attribute.shortName) {
      console.log('Problem with shift attribute object:', attribute)
      return null
    }

    const cN = ['ds-shift-attribute']
    if (className) cN.push(className)

    let titleTxt = attribute.readOnly ? t(attribute.name) : attribute.name
    if (attribute.description || attribute.readOnly) titleTxt += (': ' + (attribute.readOnly ? t('SHIFT_FLAG_DESC.' + attribute.id) : attribute.description).replace(new RegExp('[ .]+$'), '') + '.')

    return (
      <div className={cN.join(' ')} style={style} title={titleTxt}>
        <div className='ds-shift-attribute-txt'>
          <Icon ico={calendarUtil.getAttributeIcon(attribute)} />
          <span>
            {short
              ? (attribute.readOnly ? t(attribute.shortName) : attribute.shortName)
              : (attribute.readOnly ? t(attribute.name) : attribute.name)}
          </span>
        </div>
        {!hideDeletion && (
          <Icon
            ico='cancel'
            onClick={onClickDelete}
            size={Icon.SIZES.SMALL}
          />
        )}
      </div>
    )
  }
}

export default ShiftAttribute
