import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation VerifyMfaFactorMutation($code: String!, $type: MFAMethods!) {
    verifyMfaFactor(type: $type, code: $code)
}
`

async function requestVerifyMfaFactor (variables) {
  const response = await fetchRequest({ query, variables })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('verify-mfa-factor', errors)
  }
  return data.verifyMfaFactor
}

export default requestVerifyMfaFactor
