import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query ShiftsEmployeeQuery (
  $id: ID!,
  $workspace: ID,
  $period: PeriodFreeInput,
) {
  user(
    id: $id
  ) {
    shifts (
      period: $period
      workspace: $workspace
    ) {
      transfer {
        ${q.OFFER_PARAMS_TRANSFER}
      }
      ${q.SHIFT_PARAMS_BASIC}
    }
  }
}`

async function requestShiftsEmployee (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shifts-employee', errors)
  }
  return data.user.shifts
}

export default requestShiftsEmployee
