import React from 'react'
import classNames from 'classnames/bind'
import { t } from 'i18next'

import {
  Button,
  Cycle,
  Modal,
  Icon
} from '@ui'

import styles from './index.scss'
import connect from './connect'

const cx = classNames.bind(styles)

class CyclePreset extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      focusedCycle: 0
    }
  }

  render () {
    const { setModal, preset, onSelect } = this.props
    if (!preset) return null

    return (
      <Modal
        size={Modal.SIZES.L}
        className='ds-cycle-preset'
        headerContent={<div className='ds-title'>{preset.name}</div>}
        sections={[
          <div key={0} className={cx('ds-cycle-preset')}>
            <div className={cx('ds-presets-cycle-selection')}>
              {preset.cycles.length && preset.cycles.map((cycle, i) => {
                return (
                  <div
                    key={`${i}`}
                    className={cx('ds-presets-cycle-name', { 'is-selected': this.state.focusedCycle === i })}
                    onClick={() => {
                      this.setState(s => Object.assign({}, s, { focusedCycle: i }))
                    }}
                  >
                    {cycle.title}
                  </div>
                )
              })}
            </div>

            {preset.cycles[this.state.focusedCycle] && (
              <Cycle
                startDate={preset.cycles[this.state.focusedCycle].startDate}
                shifts={preset.cycles[this.state.focusedCycle].shifts}

              />
            )}

            {onSelect
              ? (
                <div className={cx('ds-modal-footer')}>
                  <div style={{ marginRight: 'auto' }}>
                    <Button
                      size={Button.SIZES.LARGE}
                      onClick={() => { setModal(null) }}
                      ico={Icon.ICONS.arrowLeft}
                      label={t('BACK')}
                    />
                  </div>
                  <Button
                    size={Button.SIZES.LARGE}
                    style={Button.STYLES.CONTAINED}
                    label={t('SELECT')}
                    onClick={() => {
                      onSelect()
                      this.props.close()
                    }}
                  />
                </div>
              )
              : null}
          </div>
        ]}
      />
    )
  }
}

export default connect(CyclePreset)
