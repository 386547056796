import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation WorkspaceUpdateMutation (
    $id: ID!
    $data: WorkspaceInputData!
  ) {
    workspaceUpdate (
      id: $id
      data: $data
    ) {
      id
      name
    }
}`

async function requestWorkspaceUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('workspace-update', errors)
  }
  return data.workspaceUpdate
}

export default requestWorkspaceUpdate
