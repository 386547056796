import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation RoleUpdateMutation (
  $workspace: ID!,
  $user: ID!,
  $data: RoleInputData,
) {
  roleUpdate (
    workspace: $workspace
    user: $user
    data: $data
  ) {
    id
  }
}`

async function requestRoleUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('role-update', errors)
  }
  return data.roleUpdate
}

export default requestRoleUpdate
