export const GLOBAL_SINGLE_PERSON = 1
export const GLOBAL_POSITION = 2
export const GLOBAL_DATEPICKER = 3
export const GLOBAL_LOCALITY = 4
export const GLOBAL_ALL_USERS = 5
export const GLOBAL_INPUT_TYPE = 6

export const ERRORS = 'ERRORS'
export const VALUES = 'VALUES'
export const META = 'META'

export const NO_LOCALITY = 'noLocality'
export const NO_POSITION = 'noPosition'

// SHIFT START TIMES
export const SHIFT_MORNING_START_TIME = '06:00:00'
export const SHIFT_AFTERNOON_START_TIME = '14:00:00'
export const SHIFT_NIGHT_START_TIME = '22:00:00'

// COLORS
export const COLORS = [
  '7b61ff',
  '41b9ca',
  'f44c4c',
  'd1258d',
  'a4c910',
  'feb700',
  'ff61d3',
  '122587',
  '6b2927',
  '00ab26',
  'ff7a00',
  '636e68',
  '1abc9f',
  '4cadf4',
  '775465',
  '4c53f4',
  '8710b0',
  'd783ff',
  '957878',
  '11695e',
  'cf6fc0',
  'ff6161',
  '117350',
  'aaaaaa',
  '968d91',
  '8491a0',
  '7a87ff',
  'ff6d6d',
  '00868e',
  'dc0068',
  '396ba7',
  '0e1344'
]

export const STYLES = {
  colors: {
    primary: '#0045FF',
    red: '#F44C4C',
    orange: '#FF8A00',
    green: '#31A847'
  }
}
