import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { t } from 'i18next'

import { bytesToSize } from '@app/util'
import {
  Tooltip,
  Icon,
  Spacing,
  Flex,
  Text
} from '@ui'

import styles from './index.scss'

const cx = classNames.bind(styles)

export const FileItem = ({
  isDelete,
  name,
  size,
  onClick,
  toDownload,
  ext
}) => {
  let iconType = ext.toLowerCase()
  if (Object.keys(Icon.ICONS).includes(iconType)) {
    iconType = 'file-' + iconType
  } else {
    iconType = 'file'
  }

  return (
    <Tooltip
      keepSame
      anchor={
        <div className={cx('ds-file-item')} onClick={toDownload ? onClick : undefined}>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8}>
            <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4}>
              <Flex align={Flex.POSITION.CENTER}>
                <Icon ico={iconType} />
                <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
                <Text>{name}</Text>
                {isDelete && (
                  <>
                    <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
                    <Icon onClick={onClick} className={cx('ds-file-item-icon-remove')} ico='cancel' />
                  </>
                )}
              </Flex>
            </Spacing>
          </Spacing>
        </div>
      }
    >
      <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_8}>
        <Flex direction={Flex.DIRECTION.COLUMN}>
          <Text>{t('NAME_V2')}: {name}</Text>
          <Text>{t('NF_SIZE')}: {bytesToSize(size)}</Text>
        </Flex>
      </Spacing>
    </Tooltip>
  )
}
FileItem.propTypes = {
  name: PropTypes.string.isRequired,
  isDelete: PropTypes.bool,
  toDownload: PropTypes.bool,
  onClick: PropTypes.func,
  iconSize: PropTypes.number
}
FileItem.defaultProps = {
  isDelete: false,
  toDownload: false,
  onClick: () => {}
}
