import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { createTransferGroup } from '@app/ac'

const mapStateToProps = ({
  organizationId,
  loading,
  auth,
  organization
}) => {
  return {
    auth,
    loading,
    organizationId,
    organization
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createTransferGroup
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
