import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { setModal } from '@app/ac'

const mapStateToProps = ({ workspaces, workspaceId, employees, positions }) => {
  const ws = workspaces.find(w => w.id === workspaceId)
  return {
    workspace: ws,
    cycles: ws?.cycles,
    localities: ws ? ws.localities : null,
    positions,
    employees
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setModal
}, dispatch)

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
