import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ShiftAttributeAddMultiMutation (
  $workspace: ID!,
  $ids: [ID!]!,
  $attributes: [CustomAttributeValueInput!]!
  $standBy: Boolean
) {
  shiftUpdateMultiAttributeAdd (
    workspace: $workspace
    ids: $ids
    attributes: $attributes
    standBy: $standBy
  ) {
    id
  }
}`

async function requestShiftAttributeAddMulti (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-attribute-add-multi', errors)
  }
  return data.shiftUpdateMultiAttributeAdd
}

export default requestShiftAttributeAddMulti
