import { useEffect, useState } from 'react'
import moment from 'moment'
import { t } from 'i18next'
import classNames from 'classnames'
import { toast, useToasterStore } from 'react-hot-toast'

import { Icon } from '@ui'

import { StatusBadge } from './StatusBadge'

import connect from './connect'
import './notification-card.scss'

const TYPES = {
  POPUP: 'popup',
  SIDEBAR: 'sidebar'
}

export const NotificationCard = connect(({
  notification,
  onOpen,
  type,
  onClose,
  setSidebar
}) => {
  const [multipleToastId, setId] = useState(null)
  const { message, created, read } = notification
  const isRelease = notification.type === 'release'
  const { toasts } = useToasterStore()
  const isMultiple = !!multipleToastId

  useEffect(() => {
    if (type === TYPES.POPUP && !isRelease) {
      const classicNotifications = toasts.filter((item) => item.typeIdentifier === 'classic')
      if (classicNotifications.length > 1) {
        const sorted = classicNotifications.sort((a, b) => a.createdAt > b.createdAt ? 1 : 0)
        const sliced = sorted.slice(1)
        sliced.map((item) => toast.remove(item.id))
        setId(sorted[0].id)
      }
    }
  }, [])
  return (
    <div className='card-container'>
      <div className={classNames('ds-notification-card', type, {
        'is-release': isRelease,
        'is-multiple': isMultiple
      })}
      >
        <div className='top'>
          <div className='top-group'>
            <div className='title-group'>
              <div className='title'> {message?.title} </div>
              {(read || type === TYPES.POPUP) ? null : (
                <div className='badge'>
                  <StatusBadge
                    type={isRelease ? StatusBadge.TYPES.RELEASE : StatusBadge.TYPES.OTHER}
                  />
                </div>
              )}
            </div>
            <div className='time'>{moment(created).format('LT')}</div>
          </div>
          {type === TYPES.POPUP && !isRelease && (
            <Icon
              ico={Icon.ICONS.close1}
              size={Icon.SIZES.SMALL}
              onClick={onClose}
            />
          )}
        </div>
        <div className='bottom'>
          <div className='text'>{message?.text}</div>
          {onOpen && !isRelease && (
            <div
              onClick={isMultiple ? () => {
                setSidebar('notification')
                toast.remove(isMultiple)
              } : onOpen} className='open-button'
            >
              {isMultiple ? t('SHOW_ALL_SHORT') : t('NOTIFICATION_NOT_VIEW')}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

NotificationCard.TYPES = TYPES
