import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestShiftCreateMulti from '@app/request/shift-create-multi'

export default (shifts) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return

    dispatch(isLoading('create-shifts'))
    const res = await requestShiftCreateMulti({ workspace: workspaceId, items: shifts }, auth)
    if (res.error) dispatch(addError(res.error))

    dispatch(isLoading('create-shifts', true))
    return res
  }
}
