import React, { Fragment } from 'react'
import { HeadCell } from './head-cell'
import { Sort } from './Sort'
import { t } from 'i18next'
import { calcStickyProperties } from '@ui/table/helpers'

export const TableHead = ({
  headerGroups
}) => (
  <div className='table-head'>
    {headerGroups.map((headerGroup, index) => {
      const headerGroupProps = { ...headerGroup.getHeaderGroupProps() }
      const hasFilter = headerGroup.headers.some((item) => !!item.Filter)
      return (
        <Fragment key={headerGroupProps.key + index}>
          <div {...headerGroupProps}>
            {headerGroup.headers.map((column, cellIndex) => {
              const {
                isNextSticky,
                isLast,
                stickyOffset
              } = calcStickyProperties(cellIndex, headerGroup.headers)
              const sortProps = { ...column.getSortByToggleProps() }
              const handleClick = sortProps.onClick
              return (
                <HeadCell
                  key={'tabh_' + column.id}
                  stickyOffset={stickyOffset}
                  isLast={isLast}
                  isSticky={column.sticky || (isNextSticky && column.id === 'selection')}
                  column={column}
                  isNextSticky={isNextSticky}
                >
                  <div className='head-title' onClick={handleClick}>
                    {column.id === 'selection' && !hasFilter && column.render('Header')}
                    {column.tlKey && (typeof column.tlKey === 'string' ? t(column.tlKey) : column.tlKey)}
                    {column.extraColHeaderContent ? column.extraColHeaderContent : null}
                    {column.canSort && <Sort sortProps={sortProps} col={column} />}
                  </div>
                </HeadCell>
              )
            })}
          </div>
          {hasFilter && (
            <div {...headerGroup.getHeaderGroupProps()} key={headerGroup.key + 'filter'}>
              {headerGroup.headers.map((column, cellIndex) => {
                const {
                  isNextSticky,
                  isLast,
                  stickyOffset
                } = calcStickyProperties(cellIndex, headerGroup.headers)
                return (
                  <HeadCell
                    key={'tabh_' + column.id + 'filter'}
                    column={column}
                    isNextSticky={isNextSticky}
                    stickyOffset={stickyOffset}
                    isLast={isLast}
                    isSticky={column.sticky || (isNextSticky && column.id === 'selection')}
                    isFilter
                  >
                    {column.id !== 'selection' ? column.Filter && column.render('Filter') : column.render('Header')}
                  </HeadCell>
                )
              })}
            </div>
          )}
        </Fragment>
      )
    })}
  </div>
)

TableHead.propTypes = {}
TableHead.defaultProps = {}
