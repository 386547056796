/* eslint-disable react/jsx-fragments */
import { Fragment, useState, useEffect } from 'react'
import { t } from 'i18next'
import classNames from 'classnames/bind'

import {
  Icon,
  Spacing,
  Position,
  Locality,
  Button,
  Toggle,
  Dropdown, Link, Flex
} from '@ui'
import { notification } from '@app/util'
import requestOfferResolve from '@app/request/offer-resolve'
import requestOffersCancel from '@app/request/offers-cancel'
import requestOfferToTransfer from '@app/request/offer-to-transfer'

import {
  getOfferTypeOptions,
  getExistingOfferCandidatesHTML,
  loadCandidatesForShift
} from './helpers'
import ListShifts from './list-shifts'
import styles from './index.scss'
import connect from './connect'

const cx = classNames.bind(styles)

const TabShow = (props) => {
  const {
    // from store:
    workspace,
    positions,
    setModal,
    auth,
    updateLiveShift,
    // setOffers,
    // offers,
    employees,
    calendarMultiselect,
    setCalendarMultiSelect,
    // from component's properties:
    relevantShifts,
    setRelevantShifts,
    isResolveTab,
    renderChildBtns,
    // from HOC:
    isPluginEnabled
  } = props

  const selectedShifts = (relevantShifts && relevantShifts.length) ? relevantShifts.filter(s => s.isSelected) : []

  const [selectedSingleShift, setSelectedSingleShift] = useState(selectedShifts.length === 1 ? selectedShifts[0] : null)
  const selectedShiftsPosition = selectedSingleShift && positions.find(p => p.id === selectedSingleShift.positionId)

  const offerTypeOptions = getOfferTypeOptions(selectedSingleShift, selectedShifts)
  const localitiesAreProbablyRelevant = isPluginEnabled('localities') && (selectedShifts.find(s => s.localityId))
  const transfersSupported = workspace && workspace.transferGroups && workspace.transferGroups.length && workspace.transferGroups.reduce((a, s) => { return a + (s.workspaces ? s.workspaces.length : 0) }, 0)
  const offer = selectedSingleShift && selectedSingleShift.hasOffer

  // if we're in 'resolve' tab and there is exactly one candidate with positive reply, preselect it
  const preSelectedCand = isResolveTab
    ? offer && offer.announceAction && offer.announceAction.result && offer.announceAction.result.replies.filter(r => r.reply === 'yes').length === 1
      ? offer.announceAction.result.replies[0].userId
      : null
    : null

  const [loadingDetails, setLoadingDetails] = useState(false)
  const [candidateDetails, setCandidateDetails] = useState([])
  const [candidateDetailsTransfer, setCandidateDetailsTransfer] = useState([])
  const [selectedCandidate, setSelectedCandidate] = useState(preSelectedCand)

  // reloads candidates for a single shift if it's selected
  const reloadCandsForSingleShift = (sss) => {
    const offer = sss ? sss.hasOffer : null
    if (offer) {
      loadCandidatesForShift(sss, {
        realShiftId: sss.id,
        filterByLocality: offer.audience.options.filterByLocality,
        filterByPosition: offer.audience.options.filterByPosition,
        filterBySkill: offer.audience.options.filterBySkill,
        filterByWarnings: offer.audience.options.filterByWarnings,
        auth,
        workspace,
        loadTransfers: (offer.transfer && offer.transfer.slaves && offer.transfer.slaves.length > 0),
        offerType: offer.type,
        beforeLoadCallback: () => { setLoadingDetails(true) },
        afterLoadCallback: () => { setLoadingDetails(false) }
      }).then(res => {
        setCandidateDetails(res.candidates)
        setCandidateDetailsTransfer(res.candidatesTransfer)
      })
    }
  }

  const footerButtons = [
    <Fragment key={0}>
      {selectedShifts.length > 0 && (
        <Button
          style={Button.STYLES.CONTAINED}
          color={Button.COLORS.RED}
          label={t(selectedShifts.length === 1
            ? isResolveTab ? 'OFF_BTN_CANCEL_FINISHED_OFFER' : 'OFF_BTN_CANCEL_OFFER'
            : isResolveTab ? 'OFF_BTN_CANCEL_FINISHED_OFFERS' : 'OFF_BTN_CANCEL_OFFERS'
          )}
          onClick={() => {
            requestOffersCancel({
              workspace: workspace.id,
              offers: selectedShifts.map(s => s.hasOffer.id)
            }, auth)
            setModal(null)
          }}
        />
      )}

      {selectedShifts.filter(evt => evt.userId).length > 0 && (
        <Button
          label={t('OFF_BTN_UNASSIGN')}
          onClick={() => {
            requestOffersCancel({
              workspace: workspace.id,
              offers: selectedShifts.map(s => s.hasOffer.id)
            }, auth).then(res => {
              updateLiveShift(null, { userId: null }, selectedShifts.filter(evt => evt.userId).map(evt => evt.id))
            })
            setModal(null)
          }}
        />
      )}

      <Button
        disabled={!selectedCandidate || (selectedShifts.length > 1)}
        style={Button.STYLES.CONTAINED}
        label={t('ASSIGN')}
        onClick={() => {
          setLoadingDetails(true)

          const vars = offer.type === 'drop'
            ? {
              workspace: workspace.id,
              offer: offer.id,
              user: selectedCandidate
            }
            : {
              workspace: workspace.id,
              offer: offer.id,
              user: (offer && offer.swaps) ? (offer.swaps.find(sw => sw.shiftId === selectedCandidate) || {}).userId : null,
              shift: selectedCandidate
            }
          requestOfferResolve(vars, auth).then(res => {
            setLoadingDetails(false)
            if (res && !res.error) {
              setRelevantShifts(relevantShifts.filter(s => s.id !== offer.shiftId))
              if (calendarMultiselect && calendarMultiselect.sourceEvents) {
                setCalendarMultiSelect({
                  sourceEvents: calendarMultiselect.sourceEvents.filter(evt => evt.id !== offer.shiftId)
                })
              }
            } else if (res.error) {
              if (res.error.message === 'Cannot assign this shift, multiple shifts on one day or at the same time are not allowed.') {
                notification.error({ message: 'ERR_MESSAGE.offerAssignmentShiftExists' })
              } else {
                notification.error({ message: res.error.message })
              }
            }
          })
        }}
      />
    </Fragment>]

  useEffect(() => {
    renderChildBtns(footerButtons)
  }, [selectedShifts.length, selectedCandidate])

  useEffect(() => {
    if (selectedShifts && selectedShifts.length === 1 && candidateDetails.length === 0) {
      reloadCandsForSingleShift(selectedShifts[0])
    }
  }, [])

  return (
    <div className={cx('ds-mod-content', 'ds-mod-content-show')}>
      <ListShifts
        disabled={loadingDetails}
        relevantShifts={relevantShifts}
        setRelevantShifts={(relShifts) => {
          setRelevantShifts(relShifts)
          if (relShifts.filter(s => s.isSelected).length === 1) {
            const sss = relShifts.find(s => s.isSelected)
            setSelectedSingleShift(sss)
            reloadCandsForSingleShift(sss)
            setSelectedCandidate(null)
          }
        }}
        noShiftsContent={(
          <div className={cx('ds-mod-row', 'no-shifts-content')}>
            {isResolveTab
              ? (
                <Fragment>
                  <div style={{ marginBottom: '8ex' }}>{t('OFF_SHIFTS_FALLBACK_RESOLVE')}</div>
                  <Link
                    type={Link.TYPES.ROUTER_LINK}
                    to='/settings/contest-settings'
                  >
                    <Button label={t('OFF_SHIFTS_FALLBACK_RESOLVE_BTN')} />
                  </Link>
                </Fragment>)
              : t('OFF_SHIFTS_FALLBACK_SHOW')}
          </div>
        )}
        subtitleTxt={isResolveTab ? 'OFF_TAB_RESOLVE' : 'OFF_TAB_SHOW'}
      />

      <div className={cx('ds-mod-off-wrap')}>
        <div className={cx('ds-mod-off-twocolumn')}>
          <div className={cx('ds-mod-off-details')}>

            <div className={cx('ds-mod-row')}>
              <div
                className={cx('ds-title', { 'is-disabled': relevantShifts.length === 0 })}
              >{t('OFF_OFFER_ACTION')}
              </div>
            </div>

            {selectedShifts.length === 0 ? (
              <div className={cx('ds-mod-row', { 'is-disabled': relevantShifts.length === 0 })}>
                {t('OFF_SHOW_NOT_SELECTED')}
              </div>
            ) : selectedShifts.length > 1
              ? (
                <div className={cx('ds-mod-row', { 'is-disabled': relevantShifts.length === 0 })}>
                  {t('OFF_SHOW_NOT_MULTIPLE_SELECTED')}
                </div>
              ) : (
                <Fragment>

                  <div
                    className={cx('ds-mod-row', 'is-section-title', { 'is-disabled': relevantShifts.length === 0 })}
                    style={{ fontSize: '87.5%' }}
                  >
                    {t('OFF_DETAIL_ACTION_RUNNING')}
                  </div>

                  {/* offer type */}
                  <div className={cx('ds-mod-row')}>
                    <Dropdown
                      singleSelect
                      size={Dropdown.SIZES.FULL_WIDTH}
                      type={Dropdown.TYPES.VARIABLE}
                      style={Dropdown.STYLES.LIGHT}
                      disabled
                      value={[offerTypeOptions.find(o => o.value === offer.type)].filter(Boolean)}
                      options={offerTypeOptions}
                      onChange={() => {}}
                    />
                  </div>

                  {/* audience setup */}
                  {offer.type === 'drop' && (
                    <div className={cx('ds-mod-audience-setup-rows', 'tab-show')}>
                      <div className={cx('ds-mod-row', 'is-section-title')}>
                        {t('OFF_DETAIL_AUDIENCE')}
                      </div>

                      {((!offer.audience || !offer.audience.options) ||
                        (!offer.audience.options.filterByPosition &&
                         !offer.audience.options.filterByPositions?.length &&
                         !offer.audience.options.filterByLocality &&
                         !offer.audience.options.filterByLocalities?.length &&
                         !offer.audience.options.filterBySkill &&
                         !offer.audience.options.filterByWarnings)
                      ) && (
                        <div className={cx('ds-mod-row')}>
                          {t('OFF_AUDIENCE_EVERYONE_EXISTING_OFF')}
                          {offer.audience.excludes && offer.audience.excludes.length ? (' ' + t('OFF_AUDIENCE_EVERYONE_EXISTING_OFF_EXC')) : null}
                        </div>
                      )}

                      {/* with position */}
                      {(offer.audience.options.filterByPosition || offer.audience.options.filterByPositions?.length > 0) && (
                        <div className={cx('ds-mod-row')}>
                          <Icon ico={Icon.ICONS.positions} />
                          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                          {t('OFF_AUDIENCE_POS_1')}
                          <Fragment>
                            <Spacing
                              size={Spacing.SIZES.SIZE_8}
                              type={Spacing.TYPES.HORIZONTAL}
                            >
                              <Flex>
                                {(offer?.audience?.options?.filterByPositions || [])
                                  .map(pid => positions.find(p => p.id === pid))
                                  .concat((offer?.audience?.options?.filterByPosition && !offer?.audience?.options?.filterByPositions?.length) ? selectedShiftsPosition : null)
                                  .filter(Boolean)
                                  .map((p, i) => <><Position key={i} {...p} /><Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} /></>)}
                              </Flex>
                            </Spacing>

                            {(offer.audience.options.filterBySkill && selectedSingleShift.idealSkill) ? (
                              <Fragment>{t('OFF_AUDIENCE_POS_2')}&nbsp;
                                <i>{t('SKILL_NAME_' + selectedSingleShift.idealSkill)}</i>
                              </Fragment>) : null}
                          </Fragment>
                        </div>
                      )}

                      {/* with locality */}
                      {((offer.audience.options.filterByLocality && selectedSingleShift.localityId) || offer.audience.options.filterByLocalities?.length > 0) && (
                        <div className={cx('ds-mod-row')}>
                          <Icon ico='location' />
                          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                          {t('OFF_AUDIENCE_LOC')}
                          <Spacing
                            size={Spacing.SIZES.SIZE_8}
                            type={Spacing.TYPES.HORIZONTAL}
                          >
                            <Flex>
                              {(offer?.audience?.options?.filterByLocalities || []).concat(offer?.audience?.options?.filterByLocality ? selectedSingleShift.localityId : null).filter(Boolean).map((lid, i) => <><Locality key={i} id={lid} /><Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} /></>)}
                            </Flex>
                          </Spacing>

                        </div>
                      )}

                      {/* without warnings */}
                      {offer.audience.options.filterByWarnings && (
                        <div className={cx('ds-mod-row')}>
                          <Icon ico={Icon.ICONS.warning} />
                          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                          {t('OFF_AUDIENCE_NOWARN')}
                        </div>
                      )}

                      {/* transfer employees */}
                      {transfersSupported && !isResolveTab
                        ? (
                          <div
                            className={cx('ds-mod-row')}
                            style={{ marginTop: (offer.transfer ? '0.6ex' : '1.6ex'), alignItems: 'flex-start' }}
                          >
                            {!offer.transfer && (
                              <Toggle
                                value={Boolean(offer.transfer)}
                                onChange={(val) => {
                                  if (val) {
                                    requestOfferToTransfer({
                                      workspace: workspace.id,
                                      offer: offer.id
                                    }, auth).then(res => {
                                      if (res && res.id) {
                                        const updatedSss = Object.assign({}, selectedSingleShift, { hasOffer: Object.assign({}, selectedSingleShift.hasOffer, { transfer: true }) })
                                        setSelectedSingleShift(updatedSss)
                                        reloadCandsForSingleShift(updatedSss)
                                      }
                                    })
                                  }
                                }}
                              />
                            )}

                            {offer.transfer && (
                              <>
                                <div style={{ minWidth: '1.25rem' }}>
                                  <Icon ico={Icon.ICONS.check} />
                                </div>
                                <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                              </>
                            )}

                            <div style={{ paddingTop: (offer.transfer ? undefined : '0.8ex') }}>
                              {t('OFF_AUDIENCE_TRANS')}
                              <div className={cx('ds-mod-trgrps')}>
                                {(offer.transfer && offer.transfer.master)
                                  ? t('OFF_AUDIENCE_TRANS_WS_ORIGIN')
                                  : (offer?.transfer?.slaves?.length)
                                    ? t('OFF_AUDIENCE_TRANS_WS')
                                    : null}
                              </div>
                              <div className={cx('ds-mod-trgrps')}>
                                {(offer.transfer && offer.transfer.master && offer.transfer.master.workspaceName)
                                  ? offer.transfer.master.workspaceName
                                  : workspace.transferGroups.reduce((a, s) => { return a.concat(s.workspaces.filter(ws => !a.find(w => w.name === ws.name))) }, []).filter(ws => Array.isArray(offer?.transfer?.slaves) && (offer.transfer.slaves.some(s => s.workspaceId === ws.id))).map(ws => ws.name).join(', ')}
                              </div>
                            </div>
                          </div>
                        ) : null}
                    </div>)}
                </Fragment>
              )}
          </div>
          <div className={cx('ds-mod-off-audience', { 'ds-mod-off-audience-loading': !!loadingDetails })}>

            <div className={cx('ds-mod-row')}>
              <div
                className={cx('ds-title', { 'is-disabled': !relevantShifts.length })}
              >{t('OFF_AUDIENCE' + (offer && offer.type === 'swap' ? '_SWAP' : ''))}
              </div>
            </div>

            {selectedShifts.length === 0 && (
              <div
                className={cx('ds-mod-row', 'ds-mod-audience-explain', { 'is-disabled': !relevantShifts.length })}
              >{t('OFF_AUDIENCE_NOTSEL')}
              </div>
            )}

            {selectedShifts.length > 1 && (
              <div
                className={cx('ds-mod-row', 'ds-mod-audience-explain', { 'is-disabled': !relevantShifts.length })}
              >{t('OFF_AUDIENCE_MULTI')}
              </div>
            )}

            {selectedShifts.length === 1 && getExistingOfferCandidatesHTML(offer, {
              workspace,
              shift: selectedSingleShift,
              localitiesAreProbablyRelevant,
              candidateDetails,
              candidateDetailsTransfer,
              transferSlaves: offer?.transfer?.slaves,
              loadingDetails,
              selectedCandidateId: selectedCandidate,
              handleSelectCandidate: setSelectedCandidate,
              positions,
              employees
            })}
          </div>
        </div>

      </div>
    </div>
  )
}

export default connect(TabShow)
