import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation TemplateApplyLiveMutation (
  $workspace: ID!,
  $template: ID!,
  $targetDates: [String!]!
) {
  shiftTemplateApply (
    workspace: $workspace
    template: $template,
    targetDates: $targetDates
  ) {
    id
  }
}`

async function requestTemplateApplyLive (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('template-apply-live', errors)
  }
  return data.shiftTemplateApply
}

export default requestTemplateApplyLive
