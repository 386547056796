import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { Icon, Dropdown } from '@ui'
import { miscUtil } from '@app/util'

export const TableConfigTooltip = ({
  allColumns,
  setHiddenColumns,
  hiddenColumns
}) => {
  const columns = hiddenColumns ? allColumns.filter((elem) => !hiddenColumns.includes(elem.id)) : []
  const filterCertainKeys = (columns) => columns.filter((column) =>
    column.id !== 'selection' &&
    column.id !== 'menu' &&
    !!column.tlKey
  )
  const mapToOptions = (filteredColumns) => filteredColumns.map((elem) => ({
    groupTitle: t('TABLE_CONFIG_TITLE'),
    value: elem.id,
    label: typeof elem.tlKey === 'string' ? t(elem.tlKey) : miscUtil.getNodeText(elem.tlKey)
  }))

  const handleFilter = (selected) => {
    const filteredAll = filterCertainKeys(allColumns)
    const allIds = filteredAll.map((elem) => elem.id)
    const selectedIds = selected.map((elem) => elem.value)
    const hidden = allIds.filter((elem) => !selectedIds.includes(elem))
    setHiddenColumns(hidden)
  }

  const columnOptions = mapToOptions(filterCertainKeys(allColumns))

  if (!allColumns || allColumns.length < 2) return null // if less than 2 columns, display nothing
  return (
    <Dropdown
      disableSort
      size={Dropdown.SIZES.LARGE}
      type={Dropdown.TYPES.STATIC}
      style={Dropdown.STYLES.OUTLINED}
      options={columnOptions}
      icon={<Icon ico='filters' size={Icon.SIZES.SMALL} />}
      value={mapToOptions(filterCertainKeys(columns))}
      placeholder={t('COLUMNS')}
      searchable
      onChange={(val) => handleFilter(val)}
    />
  )
}
TableConfigTooltip.propTypes = {
  allColumns: PropTypes.array
}
TableConfigTooltip.defaultProps = {
  allColumns: []
}
