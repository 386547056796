import React from 'react'
import classNames from 'classnames'
import { Err } from '@ui'
import PropTypes from 'prop-types'

import './Textarea.scss'

const STYLES = Object.freeze({
  DEFAULT: 'default'
})

const SIZES = Object.freeze({
  MEDIUM: 'medium',
  LARGE: 'large',
  FULL_WIDTH: 'full-width'
})

export const Textarea = ({
  label,
  style,
  size,
  disabled,
  errorMessage,
  hasError,
  placeholder,
  value,
  onChange,
  required,
  tabIndex,
  autoFocus,
  onBlur,
  onFocus,
  maxLength,
  rows,
  autocomplete,
  onKeyPress,
  onKeyDown,
  onSubmit,
  onClick
}) => {
  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 13) onSubmit()
  }
  return (
    <div
      onClick={onClick}
      className={classNames(
        'ds-textarea',
        style,
        size,
        { disabled: disabled }
      )}
    >
      {label && <div className='input-label'>{label}</div>}
      <div className='group-container'>
        <div className='input-container'>
          <textarea
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            value={value === null ? undefined : value}
            required={required}
            tabIndex={tabIndex}
            maxLength={maxLength}
            autoComplete={autocomplete}
            onChange={({ target }) => onChange(target.value)}
            onKeyPress={(e) => onKeyPress(e)}
            onKeyDown={(e) => onKeyDown ? onKeyDown(e) : handleKeyDown(e)}
            onBlur={onBlur}
            onFocus={onFocus}
            rows={rows === null ? undefined : rows}
          />
        </div>
      </div>
      {(hasError || errorMessage) && <Err message={errorMessage} />}
    </div>
  )
}

Textarea.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  autocomplete: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func
}
Textarea.defaultProps = {
  label: null,
  style: STYLES.DEFAULT,
  size: SIZES.MEDIUM,
  disabled: false,
  value: null,
  placeholder: '',
  hasError: false,
  autocomplete: 'off',
  errorMessage: null,
  autoFocus: false,
  tabIndex: 1,
  required: false,
  onClick: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
  onChange: () => {},
  onSubmit: () => {},
  onBlur: () => {}
}
Textarea.STYLES = STYLES
Textarea.SIZES = SIZES
