import React from 'react'
import moment from 'moment'
import { t } from 'i18next'

import requestOffersCreate from '@app/request/offers-create'
import { Button, Input, Position, Modal } from '@ui'

import connect from './connect'
import './index.scss'

class RequestOffer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      note: null
    }
  }

  render () {
    const { isLoading, positions, shiftDetail, workspaceId, auth } = this.props
    const details = shiftDetail
    const position = details
      ? positions.find((p) => p.id === details.positionId)
      : null

    return (
      <Modal
        className='ds-modal-request-offer'
        size={Modal.SIZES.S}
        isLoading={isLoading || !details}
        headerContent={
          <div className='ds-title'>{t('REQUEST_CONTEST_TITLE')}</div>
        }
        children={
          <div className='ds-modal-content-section'>
            {/* Date & Time */}
            <div className='ds-reqoff-row'>
              <div className='ds-reqoff-col-label'>
                {t('REQUEST_CONTEST_YOUR_SHIFT')}
              </div>
              <div className='ds-reqoff-col-value'>
                <div className='ds-vsr-time'>
                  {moment(details.period.start).format('D.M.YYYY - H:mm - ') +
                    moment(details.period.end).format('H:mm')}
                </div>
                <Position lg {...position} skill={details.idealSkill} />
              </div>
            </div>

            {/* Note */}
            <div className='ds-reqoff-row'>
              <div className='ds-reqoff-col-label'>
                {t('NOTE')}
              </div>
              <div className='ds-reqoff-col-value'>
                <Input
                  size={Input.SIZES.FULL_WIDTH}
                  placeholder=''
                  value={this.state.note}
                  onChange={(val) => {
                    this.setState((s) => Object.assign({}, s, { note: val }))
                  }}
                />
              </div>
            </div>

            {/* Notice */}
            <div className='ds-reqoff-row' style={{ marginTop: '1.6ex' }}>
              <b>{t('REQUEST_CONTEST_NOTICE_1')}</b>{' '}
              {t('REQUEST_CONTEST_NOTICE_2') + ' '}
              {t('REQUEST_CONTEST_NOTICE_3')}
            </div>
          </div>
        }
        footerContent={
          <div className='ds-reqoff-row'>
            <Button
              style={Button.STYLES.CONTAINED}
              color={Button.COLORS.RED}
              label={t('YES')}
              onClick={() => {
                requestOffersCreate(
                  {
                    workspace: workspaceId,
                    shifts: [details.id],
                    options: {
                      type: 'drop',
                      reason:
                        this.state.note === '' ? undefined : this.state.note
                    }
                  },
                  auth
                ).then((res) => {
                  this.props.loadShiftDetail(details.id, false, true)
                  this.props.close()
                })
              }}
            />
            <Button
              label={t('NO')}
              onClick={() => this.props.close()}
            />
          </div>
        }
      />
    )
  }
}

export default connect(RequestOffer)
