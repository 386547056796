import { bindActionCreators, compose } from 'redux'
import { reduxForm, getFormSyncErrors, change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import {
  AnnounceShift,
  RevertAnnounceShift,
  UpdateAnnounceShiftFrom,
  UpdateAnnounceShiftTo,
  UpdateAnnounceShiftByPosition,
  UpdateAnnounceShiftByLocality,
  UpdateAnnounceShiftByPeople
} from '@app/ac/announce-shifts'
import { SHIFT_ANNOUNCE_FORM } from './constants'
import { VALUES, ERRORS } from '@app/const/globals'

const mapStateToProps = (state) => {
  const { employees, positions, auth, workspaceId, announceShifts, workspaces } = state
  return {
    [ERRORS]: getFormSyncErrors(SHIFT_ANNOUNCE_FORM)(state),
    [VALUES]: getFormValues(SHIFT_ANNOUNCE_FORM)(state),
    employees,
    positions,
    auth,
    workspaceId,
    announceShifts,
    workspaces
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeFormField: (field, value) => change(SHIFT_ANNOUNCE_FORM, field, value),
    AnnounceShift,
    RevertAnnounceShift,
    UpdateAnnounceShiftFrom,
    UpdateAnnounceShiftTo,
    UpdateAnnounceShiftByPosition,
    UpdateAnnounceShiftByLocality,
    UpdateAnnounceShiftByPeople
  }, dispatch)
}

export default (component) => {
  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: SHIFT_ANNOUNCE_FORM,
      validate: () => {
        return {}
      }
    }))(component)
}
