import moment from 'moment'
import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import setShifts from '../action/set-shifts'
import requestShiftDeleteMulti from '@app/request/shift-delete-multi'

export default (day) => {
  return async (dispatch) => {
    const { auth, shifts, workspaceId } = store.getState()
    if (!workspaceId || !shifts) return

    // we don't know what shift period we have in store
    // so we have to filter out the removed ones and set the rest
    const keep = []
    const remove = []
    shifts.forEach((sf) => {
      const sameDay = moment(day).isSame(moment(sf.period.start), 'day')
      if (sameDay) {
        remove.push(sf)
      } else {
        keep.push(sf)
      }
    })

    dispatch(isLoading('clear-live-day'))

    const result = await requestShiftDeleteMulti({
      workspace: workspaceId,
      shifts: remove.map(sf => sf.id)
    }, auth)
    if (result && result.error) dispatch(addError(result.error))

    dispatch(setShifts(keep))
    dispatch(isLoading('clear-live-day', true))
  }
}
