"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mutateTerminalGeneratePINForUser = void 0;
const client_1 = require("@apollo/client");
const apollo_1 = require("@app/util/apollo");
const error_handler_1 = require("@app/util/error-handler");
const terminalGeneratePINForUserMutation = (0, client_1.gql) `
  mutation TerminalGeneratePINForUser($workspaceId: ID!, $userId: ID!, $useEmployeeId: Boolean) {
      terminalGeneratePINForUser(workspace: $workspaceId, user: $userId, useEmployeeId: $useEmployeeId)
    }`;
const mutateTerminalGeneratePINForUser = (variables) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, errors } = yield (0, apollo_1.mutate)(terminalGeneratePINForUserMutation, { variables });
    (0, error_handler_1.processErrors)(errors);
    return data === null || data === void 0 ? void 0 : data.terminalGeneratePINForUser;
});
exports.mutateTerminalGeneratePINForUser = mutateTerminalGeneratePINForUser;
