import React from 'react'
import { t } from 'i18next'

import { Flex, Text, Toggle, Input, Spacing } from '@ui'

export function CapacityPlanning ({ disabled, toggle, nameInput, productivityInput }) {
  return (
    <div>
      <Flex align='center' justify='space-between'>
        <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>
          {t('CAPP_TITLE')}
        </Text>
        <Toggle disabled={disabled} value={toggle.value} onChange={toggle.handleChange} />
      </Flex>
      {toggle.value && (
        <>
          <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14}>
            <Input
              size={Input.SIZES.FULL_WIDTH}
              value={nameInput.value}
              label={t('CAPP_UNIT_NAME')}
              type='text'
              placeholder={t('CAPP_PRODUCTIVITY_NAME_PLACEHOLDER')}
              hasError={nameInput.hasError}
              onChange={nameInput.handleChange}
              disabled={disabled}
            />
          </Spacing>
          <div style={{ position: 'relative' }}>
            <Input
              value={productivityInput.value}
              label={t('CAPP_PRODUCTIVITY_NAME')}
              type='number'
              placeholder='0'
              min={0}
              max={9999}
              onChange={productivityInput.handleChange}
              onBlur={productivityInput.handleBlur}
              disabled={disabled}
              size={Input.SIZES.LARGE}
            />
            <Text
              type={Text.TYPES.BODY_LARGE}
              weight={Text.WEIGHTS.BOLD}
              color={Text.COLORS.PRIMARY}
              style={{ position: 'absolute', left: '8.25rem', bottom: '0.7rem' }}
            >
              {t('CAPP_PRODUCTIVITY_PER_HOUR')}
            </Text>
          </div>
        </>
      )}
    </div>
  )
}
