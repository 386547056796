import store from '../store'
import {
  ReadNotificationsAction,
  ResolveNotificationAction
} from '../action/set-notifications'
import requestNotificationUpdate from '@app/request/notification-update'

export const ReadNotifications = () => {
  return async (dispatch) => {
    // mark unread notifications as read on BE
    const { auth, me, notifications } = store.getState()

    if (!notifications) return

    notifications
      .filter((notification) => !notification.read)
      .forEach((notification) => {
        requestNotificationUpdate(
          {
            userId: me.id,
            id: notification.id,
            read: true
          },
          auth
        )
      })

    // mark them all as read in store
    await dispatch(ReadNotificationsAction())
  }
}

export const ResolveNotification = (data = {}) => {
  const { auth, me } = store.getState()
  // mark this notif as resolved on BE
  requestNotificationUpdate({
    userId: me.id,
    id: data,
    resolve: true
  }, auth)

  return async (dispatch) => {
    await dispatch(ResolveNotificationAction(data))
  }
}
