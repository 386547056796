import storage from '../util/storage'
import initial from './initial'
import jwtDecode from 'jwt-decode'

export default () => {
  const stored = storage.get('ds-store')
  if (!stored) {
    return initial
  }

  const version = stored.version
  if (version !== initial.version) {
    return initial
  }

  if (stored.auth) {
    try {
      const decoded = jwtDecode(stored.auth)
      // expired
      if (Date.now() > 1000 * decoded.exp) {
        stored.auth = null
        storage.set('ds-store', stored)
        window.location.replace('/login')
      }
    } catch (err) {
      // invalid
      stored.auth = null
    }
  }

  return stored
}
