import store from '../store'
import isLoading from './is-loading'
import loadTemplates from './load-templates'
import requestTemplateCreate from '../request/template-create'
import requestRequirementCreate from '../request/requirement-create'
import addError from '../ac/add-error'
import { t } from 'i18next'
import routeUtil from '@app/util/routeUtil'

// NOTE: This creates a new shift for the Day Template.
//       If you want to create a new Shift Template, use the '/ac/create-shift-template.js'

export default (templateId, form) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !form) return

    dispatch(isLoading('create-template-shift'))
    if (!templateId) {
      const template = await requestTemplateCreate({
        workspace: workspaceId,
        name: t('DEFAULT_DAY')
      }, auth)
      if (template.error) {
        dispatch(addError(template.error))
        dispatch(isLoading('create-template-shift', true))
        return
      }
      templateId = template.id
    }

    const count = form.employees || 1
    const copyCount = (form.copies && !isNaN(form.copies)) ? form.copies : 1
    for (let c = 1; c <= copyCount; c++) {
      for (let i = 0; i < count; i++) {
        const result = await requestRequirementCreate({
          workspace: workspaceId,
          template: templateId,
          position: form.positionId,
          locality: form.localityId,
          start: form.start,
          duration: form.duration,
          pauses: form.pauses,
          pausesFixed: form.pausesFixed,
          customAttributes: form.customAttributes,
          agenda: form.agenda,
          idealSkill: form.idealSkill ? parseInt(form.idealSkill) : null,
          requirementsArrayIndex: form.requirementsArrayIndex || undefined
        }, auth)
        if (result && result.error) {
          dispatch(addError(result.error))
        }
      }
    }

    await dispatch(loadTemplates())
    routeUtil.navigate(`/workspace/day-templates/edit/${templateId}`)
    dispatch(isLoading('create-template-shift', true))
  }
}
