function canCopy () {
  return !!document.execCommand
}

function copy (str) {
  const el = document.createElement('textarea')
  if (el) {
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }
};

export default {
  canCopy,
  copy
}
