import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationSubscriptionUpdate(
    $organization: ID!,
    $data: BillingSettingsInput!
  ) {
  organizationSubscriptionUpdate(
    organization: $organization,
    data: $data
  ) {
     details {
      companyName
      street
      zipCode
      city
      regNumber
      vatPayer
      billingEmail
    }
   }
}`

async function requestOrganizationSubscriptionUpdate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationRoleUpdate
}

export default requestOrganizationSubscriptionUpdate
