import { AxiosFetch } from '@app/util/axiosFetch'
import FormData from 'form-data'
import { apiEndpoints } from '@app/const/config'

const { location: { hostname } } = window
const { [hostname]: { backend } } = apiEndpoints

const FromB64Data = (b64Data, contentType = '', sliceSize = 512) => {
  // eslint-disable-next-line no-undef
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  // eslint-disable-next-line no-undef
  const blob = new Blob(byteArrays, { type: contentType })

  return blob
}

export const uploadFile = (files, workspaceId, auth) => {
  return Promise.all(
    files.map((file) => new Promise(resolve => {
      const { type, name } = file
      // eslint-disable-next-line no-undef
      const reader = new FileReader()
      reader.onload = () => resolve({
        acceptedFile: reader.result,
        name,
        type
      })
      reader.readAsDataURL(file)
    }))
  ).then((res) => {
    return Promise.all(
      res.map((file) => {
        const { acceptedFile, name, type } = file

        return new Promise(resolve => {
          const realData = acceptedFile.split(';')[1].split(',')[1]

          const operations = {
            query: `mutation ($file: Upload!) {
              fileUpload (workspace: "${workspaceId}", type: attachment, file: $file) {
                size
                filename
                type
                id
                url
                created
                author
              }
            }`,
            variables: {
              file: null
            }
          }
          const map = {
            0: ['variables.file']
          }

          const form = new FormData()

          form.append('operations', JSON.stringify(operations))
          form.append('map', JSON.stringify(map))
          form.append('0', FromB64Data(realData, type), name)

          const data = {
            method: 'post',
            headers: {
              Authorization: `Bearer ${auth}`
            },
            data: form
          }

          resolve(AxiosFetch(backend, data).then((response) => {
            const { data: { fileUpload } } = response
            return fileUpload
          }))
        })
      })
    )
  })
}
