import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setSidebar from '@app/ac/set-sidebar'

const mapStateToProps = ({ sidebar }) => {
  return {
    sidebar
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onClose: () => setSidebar(null)
    },
    dispatch
  )
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
