import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

// Notifications
const query = `
  mutation NotificationUpdateMutation (
    $userId: ID!,
    $id: ID!,
    $read: Boolean,
    $resolve: Boolean
  ) {
    notificationUpdate(
      user: $userId
      id: $id
      read: $read
      resolve: $resolve
    ) {
      id
    }
  }
`

async function requestNotificationUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('notification-update', errors)
  }
  return data.notificationUpdate
}

export default requestNotificationUpdate
