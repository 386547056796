import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation SendMfaFactorMutation($type: MFAMethods!, $email: String) {
    sendMfaVerification(type: $type, email: $email) {
      sent
      param
    }
}
`

async function requestSendMfaFactor (variables) {
  const response = await fetchRequest({ query, variables })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('sendMfaFactor', errors)
  }
  return data.sendMfaVerification
}

export default requestSendMfaFactor
