import moment from 'moment-business-days'

export const EmployeesMapper = (employees) => Object.keys(employees).map((idx) => {
  return { userId: idx, ...employees[idx], label: employees[idx].name }
})

const findUserIdsByLocalityId = (localities, id) => {
  const locality = localities.find((loc) => loc.id === id)
  if (locality && locality.assigns) {
    return locality.assigns.map((tt) => tt.userId)
  }
  return []
}

export const LocalitiesMapper = (values, workspaces) => {
  const userIds = new Set()

  for (const value of values) {
    for (const workspace of workspaces) {
      if (workspace.localities) {
        const userIdsForLocality = findUserIdsByLocalityId(workspace.localities, value.id)
        userIdsForLocality.forEach((userId) => userIds.add(userId))
      }
    }
  }

  return Array.from(userIds).map((userId) => ({ userId }))
}

export const SinglePersonMapper = (values) => values.map((item) => ({ userId: item.id }))

export const DatePickerMapper = (values, shifts) => shifts.map((item) => moment(item.period.start).format('YYYY-MM-DD') === values[0] && item.published && item.userId && { userId: item.userId }).filter(Boolean)

export const findInList = (list, find, index, segment) => list && list.filter(Boolean).length && list.filter(Boolean).map((item) => item[index] ? (!item[index].toLowerCase().indexOf(find.toLowerCase()) && {
  value: item[index],
  label: item[index],
  id: item.id,
  segment,
  employeeId: item.employeeId,
  color: item.color,
  index: item.index,
  categoryID: item.categoryID
}) : '')

export const AnnounceShiftSortMapper = (selectedChangedShifts) => {
  const days = []
  if (selectedChangedShifts && selectedChangedShifts.length) {
    selectedChangedShifts.forEach(cs => {
      const date = moment(cs.shift.period.start).format('YYYY-MM-DD')
      let day = days.find(d => d.date === date)
      if (!day) {
        day = { date, positions: [], changes: 1 }
        days.push(day)
      } else {
        day.changes += 1
      }
      let position = day.positions.find(p => p.id === cs.shift.positionId)
      if (!position) {
        position = { id: cs.shift.positionId, shifts: [] }
        day.positions.push(position)
      }
      position.shifts.push(cs)
    })
  }

  return days.sort((a, b) => (a.date > b.date) ? 1 : -1)
}

export const getUniqueChangesMapper = (arr) => {
  return [...new Map(arr.map((item) => [item.type, item])).values()]
}

export const announceShiftChangesMapper = (arrayValues, start, end, state, index) => arrayValues && arrayValues.length && arrayValues.map(
  (item) => AnnounceShiftSortMapper(state.known.filter(
    (s) =>
      s.shift[index] === item.id &&
      moment(s.shift.period.start).endOf('day').isSameOrAfter(start) &&
      moment(s.shift.period.end).startOf('day').isSameOrBefore(end)
  ).filter(Boolean))).filter(Boolean).flat(1)

export const monthList = (t, onlyPast = false) => {
  const ret = []
  for (var i = -12; i <= (onlyPast ? 0 : 12); i++) {
    const m = moment().add(i, 'months')
    ret.push({
      label: m.format('MMMM YYYY'),
      value: m.format('YYYY-MM'),
      groupTitle: m.format('YYYY')
    })
  }

  return ret
}
