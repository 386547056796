import React, { useState } from 'react'
import styles from './index.scss'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const Toggle = ({ value, className, style, onChange, disabled, onDisabledClick, onClick }) => {
  const [toggleValue, setToggleValue] = useState(value)

  const handleToggle = (e) => {
    if (disabled === true) {
      if (onDisabledClick) {
        onDisabledClick()
        if (onClick) onClick(e)
      }
      return
    }

    if (onChange) {
      onChange(!toggleValue)
    }
    setToggleValue(!toggleValue)
    if (onClick) onClick(e)
  }

  return (
    <div className={cx(`ds-toggle ${className}`, { 'is-disabled': disabled })} style={style} onClick={(e) => handleToggle(e)}>
      <div className={cx(`ds-toggle-btn ${(value) && 'ds-is-active'} `)}>
        <div className={cx('ds-inner-circle')} />
      </div>
      <input type='checkbox' className={cx('ds-html-input')} value={toggleValue} />
    </div>
  )
}

Toggle.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onDisabledClick: PropTypes.func
}

Toggle.defaultProps = {
  value: false,
  className: '',
  style: null,
  onChange: () => {},
  onDisabledClick: null
}

export default Toggle
