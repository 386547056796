"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShiftOperations = void 0;
const util_1 = require("@app/util");
const useShiftOperations = () => {
    const SHIFT_OPERATIONS = Object.freeze({
        singleShift: (0, util_1.t)('CONTRACT_OPT_SELECTOPT_singleShift'),
        twoShift: (0, util_1.t)('CONTRACT_OPT_SELECTOPT_twoShift'),
        threeShift: (0, util_1.t)('CONTRACT_OPT_SELECTOPT_threeShift'),
    });
    return SHIFT_OPERATIONS;
};
exports.useShiftOperations = useShiftOperations;
