/* eslint-disable react/jsx-fragments,no-case-declarations,no-unused-vars */
import {
  GLOBAL_SINGLE_PERSON,
  GLOBAL_ALL_USERS,
  GLOBAL_DATEPICKER,
  GLOBAL_LOCALITY,
  GLOBAL_INPUT_TYPE
} from '@app/const/globals'
import {
  EmployeesMapper,
  LocalitiesMapper,
  SinglePersonMapper,
  DatePickerMapper,
  findInList
} from '@app/util/mappers'

export const WithMapper = (data) => (WrapperComponent) => {
  const ReceiversMapper = ({ audienceBy, values, employees, positions, workspaces, shifts }) => {
    switch (audienceBy) {
      case GLOBAL_DATEPICKER:
        return DatePickerMapper(values, shifts)
      case GLOBAL_SINGLE_PERSON:
        return SinglePersonMapper(values)
      case GLOBAL_ALL_USERS:
        return EmployeesMapper(employees)
      case GLOBAL_LOCALITY:
        return LocalitiesMapper(values, workspaces)
      default:
        return []
    }
  }

  const OptionsMapper = ({
    mapper,
    employees = {},
    positions = [],
    assign = '',
    workspaces = [],
    type = {
      holidays: [],
      unvCats: [],
      shiftCats: [],
      saturday: {},
      sunday: {},
      night: {}
    }
  }) => {
    const { shaver } = data

    switch (mapper) {
      case GLOBAL_SINGLE_PERSON:
        return findInList(Object.values(employees), assign, shaver, GLOBAL_SINGLE_PERSON)
      case GLOBAL_LOCALITY:
        return findInList(Object.values(workspaces.map((item) => item.localities)).filter(Boolean).flat(1), assign, shaver, GLOBAL_LOCALITY) || []
      case GLOBAL_INPUT_TYPE:
        const { holidays, unvCats, shiftCats, saturday, sunday, night } = type
        return findInList([holidays, unvCats, shiftCats, saturday, sunday, night].flat(1), assign, shaver, GLOBAL_INPUT_TYPE)
      default:
        return []
    }
  }

  const Wrapper = (props) => {
    const { mapperProps } = data
    return <WrapperComponent {...props} ReceiversMapper={ReceiversMapper} OptionsMapper={OptionsMapper} {...mapperProps} />
  }

  return Wrapper
}
