import React from 'react'
import { Separator } from '@ui'
import classNames from 'classnames'

import './navPanelStyle.scss'

export const NavPanel = ({ main, sub, dark }) => (
  <div className={classNames('nav-panel', { dark })}>
    <div className='main-panel'>
      {main}
    </div>
    <Separator side={Separator.SIDES.RIGHT} />
    {sub && (
      <>
        <div className='sub-panel'>
          {sub}
        </div>
        <Separator side={Separator.SIDES.RIGHT} />
      </>
    )}
  </div>
)
