import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationPerformance(
   $organizationId: ID!,
   $period: PeriodInput!,
   $splitBy: CalendarUnit!,
   $filter: OrganizationFilter!
   ) {
  organization(id: $organizationId) {
    performance(period: $period, splitBy: $splitBy, filter: $filter) {
      summary {
        revenuePlan {
          amount
          currency
        }
        revenueReality {
          amount
          currency
        }
        revenueKPI
        productionPlan {
          amount
          currency
        }
        productionReality {
          amount
          currency
        }
        productionKPI
        hoursPlan
        hoursReality
        hoursModified
        hoursModifier
        hoursDifference
        hoursFlexible
        hoursKPI
        fullTimePlan
        fullTimePercentage
        fullTimeNorm
        currency
      }
      details {
        period {
          start
          end
        }
        revenuePlan {
         amount
         currency
        }
        revenueReality {
         amount
         currency
        }
        productionPlan {
         amount
         currency
        }
        productionReality {
         amount
         currency
        }
        currency
        target
        targetId
        revenueKPI
        productionKPI
        hoursPlan
        hoursReality
        hoursModified
        hoursModifier
        hoursFlexible
        hoursDifference
        hoursKPI
        fullTimePlan
        fullTimePercentage
        fullTimeNorm
      }
    }
  }
}`

async function requestOrganizationPerformance (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrganizationPerformance
