import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationRoleCreateMutation(
    $organizationId: ID!,
    $name: String!,
    $countries: [ID!]!,
    $areas: [ID]!,
    $permissions: [OrganizationPermission!]!) {
        organizationRoleCreate(
            organization: $organizationId,
            name: $name,
            countries: $countries,
            areas: $areas,
            permissions: $permissions
            ) {
                id
                name
                organizationId
                countries
                areas
                assigns {
                  userId
                }
                permissions
            }
    }`

async function requestOrganizationRoleCreate (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationRoleCreate
}

export default requestOrganizationRoleCreate
