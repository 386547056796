import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { gql } from '@apollo/client'
import { ApolloClientFetch } from '@app/util/apollo-client-fetch'
import { CALENDAR_EXPORT_TOKEN } from '@app/request/query'
import {
  requestShifts,
  requestTimeOffsWorkspace
} from '@app/request'

import {
  Button,
  Modal,
  Select,
  Alert,
  Flex,
  Spacing,
  PeriodSelect,
  RadioGroup,
  Dropdown
} from '@ui'
import { miscUtil } from '@app/util'
import './index.scss'
import connect from './connect'
import { buildURI } from '@app/util/export/csv/csv'
import { createExportName } from '@app/util/export/export-name'
import { parseTable } from '@app/util/export/parse-table'

const ExportAttendance = ({
  setModal,
  isLoading,
  language,
  auth,
  workspaceId,
  ws,
  close,
  isPluginEnabled,
  defaultMonth,
  filteredRows,
  columns,
  loadWorkspaceDetail,
  organization
}) => {
  const isOrgUI = miscUtil.isDayswapsProInterface()

  const myCountryPermissions = []
  if (isOrgUI && organization?.countries?.length) {
    organization.countries.forEach(c => {
      miscUtil.getMyCountryPermissions(c.id).forEach(p => {
        if (!myCountryPermissions.includes(p)) myCountryPermissions.push(p)
      })
    })
  }
  const hasPayrollAttendanceCountryPermission = myCountryPermissions.includes('payroll_attendance') || myCountryPermissions.includes('payroll_attendance_lvl2') || myCountryPermissions.includes('payroll_attendance_lvl3')

  const supportedExportTypes = {
    tableExport: {
      displayUnapprovedWarning: false,
      fileFormats: [
        { value: 'xlsx', label: t('ATTEN_EXPORT_T.xlsx') }
      ]
    },
    paymentDocumentation: {
      displayUnapprovedWarning: true,
      fileFormats: [
        // xlsx
        { value: 'xlsx', label: t('ATTEN_EXPORT_T.xlsx') },
        // pamica
        (isPluginEnabled('pamica') || (ws?.country?.toUpperCase() === 'CZ') || (isOrgUI && organization?.countries?.some(c => c.code === 'CZ'))) ? { value: 'pamica', label: t('ATTEN_EXPORT_T.pamica') } : null,
        // baris
        (isPluginEnabled('baris')) ? { value: 'baris', label: t('ATTEN_EXPORT_T.baris') } : null,
        // ksportal
        (isPluginEnabled('ksportal') || (ws?.country?.toUpperCase() === 'CZ') || (isOrgUI && organization?.countries?.some(c => c.code === 'CZ'))) ? { value: 'ks', label: t('ATTEN_EXPORT_T.ks') } : null,
        // helios
        { value: 'helios', label: t('ATTEN_EXPORT_T.helios') },
        // accace
        (['HU', 'HR'].includes(ws?.country?.toUpperCase()) || (isOrgUI && organization?.countries?.some(c => c.code === 'HU' || c.code === 'HR'))) ? { value: 'accace', label: t('ATTEN_EXPORT_T.accace') } : null
      ].filter(Boolean)
    },
    planExport: {
      displayUnapprovedWarning: false,
      fileFormats: [
        // ksportal
        { value: 'ksPlan', label: t('ATTEN_EXPORT_T.ks_plan') },
        // helios
        { value: 'heliosPlan', label: t('ATTEN_EXPORT_T.helios_plan') }
      ].filter(Boolean)
    },
    bonuses: {
      displayUnapprovedWarning: false,
      fileFormats: [
        // ksportal
        { value: 'ksBonuses', label: 'KS' }
      ].filter(Boolean)
    },
    timeSheet: {
      displayUnapprovedWarning: true,
      fileFormats: [
        // timeSheet
        { value: 'timeSheet', label: t('ATTEN_EXPORT_T.timeSheet') }
      ].filter(Boolean)
    },
    employeeBulkReport: {
      displayUnapprovedWarning: false,
      fileFormats: [
        // employeeBulkReport xlsx
        { value: 'employeeBulkReport', label: t('ATTEN_EXPORT_T.xlsx') }
      ].filter(Boolean)
    }
  }

  // some supportedExportTypes might be only supported in DS PRO
  if (!isOrgUI) {
    delete supportedExportTypes.employeeBulkReport // batch employee report only makes sense in DS PRO
  }
  const [selectedExportType, setSelectedExportType] = useState('tableExport')

  const selMon = defaultMonth ? moment(defaultMonth) : moment()
  const [selectedMonthStart, setSelectedMonthStart] = useState(moment(selMon).startOf('month'))
  const [selectedMonthEnd, setSelectedMonthEnd] = useState(moment(selMon).endOf('month'))
  const [selectedFormat, setSelectedFormat] = useState('xlsx')

  const defaultCountry = (isOrgUI && organization && organization.countries && organization.countries.length)
    ? ws?.country && organization?.countries?.some(c => c.code === ws.country)
      ? organization.countries.find(c => c.code === ws.country)?.id
      : organization.countries[0].id
    : null
  const [selectedCountryId, setSelectedCountryId] = useState(defaultCountry)

  const [selectedApproved, setSelectedApproved] = useState(undefined) // values: All = undefined / Approved = true / Awaiting approval = false
  const [selectedContractStartFilter, setSelectedContractStartFilter] = useState(false)
  const [selectedContractStartFilterPeriod, setSelectedContractStartFilterPeriod] = useState({ start: selMon.startOf('month'), end: selMon.endOf('month') })
  const [unapprovedRecords, setUnapprovedRecords] = useState(false)

  const selectedCountryObj = organization.countries.find(c => c.id === selectedCountryId)
  const selectedCountryWorkspaceOptions = []
  if (selectedCountryObj) {
    selectedCountryObj.areas.forEach(ar => {
      if (ar?.workspaces) {
        ar.workspaces.forEach(w => {
          selectedCountryWorkspaceOptions.push({ value: w.id, label: w.name })
        })
      }
    })
  }

  const [multiSelectedWSIds, setMultiSelectedWSIds] = useState(workspaceId && selectedCountryWorkspaceOptions.some(opt => opt.value === workspaceId) ? [workspaceId] : [])

  useEffect(() => {
    updateUnapprovedRecordsState()
  }, [])

  const updateUnapprovedRecordsState = async (periodOverride = null) => {
    if (!supportedExportTypes[selectedExportType].displayUnapprovedWarning) return false
    const period = periodOverride || { start: selectedMonthStart, end: selectedMonthEnd }
    let resultShifts = await requestShifts({
      id: workspaceId,
      period: period
    },
    auth, {
      withAttendance: true
    })
    if (resultShifts && resultShifts?.length) resultShifts = resultShifts?.filter((s) => !!s.userId) // we don't consider unassigned shifts in attendance table or here

    const resultTimeOffs = await requestTimeOffsWorkspace({
      workspace: workspaceId,
      period: period
    }, auth)

    const res = (Array.isArray(resultShifts) ? resultShifts : []).concat(resultTimeOffs)
    if (res.some(r => Boolean(r.attendanceApproved) === false)) {
      if (!unapprovedRecords) setUnapprovedRecords(true)
    } else {
      if (unapprovedRecords) setUnapprovedRecords(false)
    }
  }

  const valWorkspace = (isOrgUI && ['ksPlan', 'ksBonuses'].includes(selectedFormat))
    ? undefined
    : (isOrgUI && multiSelectedWSIds.length && selectedFormat === 'ks')
      ? undefined
      : workspaceId

  const valWorkspaces = (isOrgUI && multiSelectedWSIds.length && selectedFormat === 'ks')
    ? multiSelectedWSIds
    : undefined

  return (
    <Modal
      className='ds-export-attendance'
      size={Modal.SIZES.M}
      headerContent={
        <div className='ds-title'> {t('EXPORT')}</div>
      }
      isLoading={isLoading}
      sections={[
        (Object.keys(supportedExportTypes).filter(k => !!supportedExportTypes[k].fileFormats.length).length >= 2) && (
          <>
            <Select
              label={t('ATTEN_EXPORT_ETYPE')}
              options={Object.keys(supportedExportTypes).filter(k => !!supportedExportTypes[k].fileFormats.length).map(k => { return { label: t('ATTEN_EXPORT_ET.' + k), value: k } })}
              value={selectedExportType}
              onChange={(v) => {
                setSelectedExportType(v)
                setSelectedFormat(supportedExportTypes[v].fileFormats.length ? supportedExportTypes[v].fileFormats[0].value : null)
              }}
            />
          </>
        ),
        <>
          {(supportedExportTypes[selectedExportType].displayUnapprovedWarning && unapprovedRecords) ? (
            <>
              <Alert
                text={t('ATTEN_EXPORT_NOT_APPROVED')}
                type={Alert.TYPES.ERROR}
                size={Alert.SIZES.FULL_WIDTH}
              />
              <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.VERTICAL} />
            </>
          ) : null}

          <PeriodSelect
            singleSelector
            displayType={PeriodSelect.DISPLAY_TYPE.MONTH}
            forbiddenFuture={selectedExportType === 'paymentDocumentation'}
            label={t('ATTEN_EXPORT_MONTH')}
            value={{ start: selectedMonthStart, end: selectedMonthEnd }}
            onChange={async (period) => {
              if (period && period.start && period.end) {
                await setSelectedMonthStart(moment(period.start))
                await setSelectedMonthEnd(moment(period.end))
                updateUnapprovedRecordsState({ start: period.start, end: period.end })
              }
            }}
          />

          <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.VERTICAL} />

          <Select
            label={t('EXPORT_FORMAT')}
            options={supportedExportTypes[selectedExportType].fileFormats}
            value={selectedFormat}
            onChange={(v) => {
              if (!isOrgUI && v === 'pamica' && !isPluginEnabled('pamica')) {
                setModal('plugin-detail', { plugin: { plugin: 'pamica' } })
              } if (!isOrgUI && v === 'ks' && !isPluginEnabled('ksportal')) {
                setModal('plugin-detail', { plugin: { plugin: 'ksportal' } })
              } else {
                setSelectedFormat(v)
              }
            }}
          />

          {(
            (selectedExportType === 'paymentDocumentation' && selectedFormat === 'ks' && isOrgUI && organization?.countries?.length > 1) ||
            (selectedExportType === 'employeeBulkReport' && selectedFormat === 'employeeBulkReport' && isOrgUI && organization?.countries?.length > 1)
          ) && (
            <Dropdown
              label={t('ORG_SP_WORKSPACES_COUNTRIES')}
              singleSelect
              sortedOptions={false}
              size={Dropdown.SIZES.LARGE}
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.LIGHT}
              options={organization.countries.map(c => { return { value: c.id, label: c.code } })}
              value={selectedCountryObj ? { value: selectedCountryObj.id, label: selectedCountryObj.code } : []}
              placeholder={t('ORG_SP_WORKSPACES_COUNTRIES')}
              onChange={(v) => {
                setMultiSelectedWSIds([])
                setSelectedCountryId(v.value)
              }}
            />
          )}

          {selectedExportType === 'paymentDocumentation' && selectedFormat === 'ks' && isOrgUI && (
            <Dropdown
              label={t('WORKSPACES')}
              sortedOptions={false}
              size={Dropdown.SIZES.LARGE}
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.LIGHT}
              options={selectedCountryWorkspaceOptions}
              value={selectedCountryWorkspaceOptions.filter(opt => multiSelectedWSIds.includes(opt.value))}
              placeholder={t('WORKSPACES')}
              onChange={(vals) => {
                setMultiSelectedWSIds(vals.map(v => v.value))
              }}
            />
          )}

          {selectedExportType === 'paymentDocumentation' && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.VERTICAL} />
              <RadioGroup
                label={t('ATTEN_EXPORT_APPROVED_LABEL')}
                direction={RadioGroup.DIRECTIONS.VERTICAL}
                options={[
                  {
                    value: undefined,
                    label: t('ATTEN_EXPORT_APPROVED_ALL')
                  },
                  {
                    value: true,
                    label: t('ATTEN_EXPORT_APPROVED_TRUE')
                  },
                  {
                    value: false,
                    label: t('ATTEN_EXPORT_APPROVED_FALSE')
                  }
                ]}
                onChange={(val) => setSelectedApproved(val)}
                value={selectedApproved}
              />
            </>
          )}

          {selectedExportType === 'planExport' && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.VERTICAL} />
              <RadioGroup
                label={t('EMPLOYEES')}
                direction={RadioGroup.DIRECTIONS.HORIZONTAL}
                options={[
                  {
                    value: false,
                    label: t('ATTEN_EXPORT_CS_FILT_FALSE')
                  },
                  {
                    value: true,
                    label: t('ATTEN_EXPORT_CS_FILT_TRUE')
                  }
                ]}
                onChange={(val) => setSelectedContractStartFilter(val)}
                value={selectedContractStartFilter}
              />

              {!!selectedContractStartFilter && (
                <>
                  <Spacing size={Spacing.SIZES.SIZE_14} type={Spacing.TYPES.VERTICAL} />
                  <PeriodSelect
                    label={t('ATTEN_EXPORT_CS_FILT_PERIOD_LABEL')}
                    value={selectedContractStartFilterPeriod}
                    onChange={(period) => {
                      if (period && period.start && period.end) setSelectedContractStartFilterPeriod(period)
                    }}
                  />
                </>
              )}
            </>
          )}

        </>
      ].filter(Boolean)}
      footerContent={
        <Flex stretch>
          <Button
            label={t('CLOSE')}
            onClick={() => {
              setModal(null)
            }}
            size={Button.SIZES.FULL}
          />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            label={t('ATTEN_EXPORT_SUBMIT')}
            disabled={
              isLoading || // disabled while loading
              (!valWorkspace && !valWorkspaces && !(['ksPlan', 'ksBonuses', 'employeeBulkReport'].includes(selectedFormat) && organization?.id)) || // disabled when required props are not set. required are either 'workspace' or 'workspaces' (or in case of 'ksPlan'/'ksBonuses'/'employeeBulkReport formats, we don't require WS specification - only organization is enough)
              (isOrgUI && ['ksPlan', 'employeeBulkReport'].includes(selectedFormat) && !hasPayrollAttendanceCountryPermission) // 'ksPlan' and 'employeeBulkReport' require one of the payroll_attendance* org permissions if we're in DS PRO
            }
            loading={isLoading}
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
            onClick={(e) => {
              if (selectedExportType === 'tableExport') {
                const [config, reducedRows] = parseTable(columns, filteredRows)

                const uri = buildURI(reducedRows, '\uFEFF', config, ',', '')

                const workspaceOrOrgName = miscUtil.isDayswapsProInterface() ? ws.organization?.name ?? '' : ws.name

                const filename = createExportName(t('ATTENDANCE'), workspaceOrOrgName, selectedMonthStart, selectedMonthEnd, '.csv')

                const link = document.createElement('a')
                if (link.download !== undefined) {
                  link.setAttribute('href', uri)
                  link.setAttribute('download', filename)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
                return
              }
              const variables = {
                format: selectedFormat,
                period: {
                  start: selectedMonthStart.toISOString(),
                  end: selectedMonthEnd.toISOString()
                },
                type: 'basic',
                pdfFromXlsx: false,
                language,
                withPositions: true,
                includeUnavailabilities: true,
                includeShifts: true,
                splitByLocality: false,
                showEveryoneOnWs: false,
                dayNotes: false,
                shiftNotes: true,
                attendanceApproved: selectedExportType === 'paymentDocumentation' ? selectedApproved : undefined,
                contractStartPeriod: (selectedExportType === 'planExport' && !!selectedContractStartFilter && selectedContractStartFilterPeriod && selectedContractStartFilterPeriod.start && selectedContractStartFilterPeriod.end) ? selectedContractStartFilterPeriod : undefined,

                workspace: valWorkspace,
                workspaces: valWorkspaces,

                organization: isOrgUI ? organization.id : undefined,
                country: isOrgUI ? selectedCountryId : undefined
              }
              ApolloClientFetch(auth, 'CalendarExportTokenQuery')
                .query({
                  query: gql`
                      ${CALENDAR_EXPORT_TOKEN}
                    `,
                  variables
                })
                .then((res) => {
                  const {
                    data: {
                      calendarExportToken: { url }
                    }
                  } = res
                  window.open(url, '_blank')
                  close()
                })
            }}
          />
        </Flex>
      }
    />
  )
}

export default connect(ExportAttendance)
