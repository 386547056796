import moment from 'moment-timezone'
import 'moment/locale/cs'
import store from '@app/store'

function en () {
  // better for fulltext sorting
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'seconds',
      m: '1 minute',
      h: '1 hour',
      d: '1 day',
      M: '1 month',
      y: '1 year',
      mm: '%d minutes',
      hh: '%d hours',
      dd: '%d days',
      MM: '%d months',
      yy: '%d years'
    },
    week: {
      dow: 1
    }
  })
  moment.locale('en')
}

// sets the locale of moment.js
//   - if no argument is given, use the store.locale value (or default to english)
//   - otherwise, use the given argument
function setLocale (lng) {
  if (!lng) {
    const localeInStore = store.getState().locale
    setLocale(localeInStore || 'en')
  } else {
    if (lng === 'en') {
      return en()
    } else {
      moment.locale(lng)
    }
  }
}

function setTimezone (tz) {
  moment.tz.setDefault(tz)
}

export default {
  setLocale,
  setTimezone
}
