import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import newMfaFactor from '@app/ac/new-mfa-factor'
import verifyMfaFactor from '@app/ac/verify-mfa-factor'
import sendMfaFactor from '@app/ac/send-mfa-factor'

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    newMfaFactor,
    verifyMfaFactor,
    sendMfaFactor
  }, dispatch)
}

export default (component) => {
  return connect(mapDispatchToProps)(component)
}
