"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRememberTableConfig = void 0;
const ac_1 = require("@app/ac");
const store_1 = require("@app/store");
const updatePath = (obj, path, value) => {
    const keys = path.split('.');
    let current = obj;
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (i === keys.length - 1) {
            current[key] = value;
        }
        else {
            if (!current[key] || typeof current[key] !== 'object') {
                current[key] = {};
            }
            current = current[key];
        }
    }
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useRememberTableConfig = (tableKey) => {
    const tableConfigInStore = (0, store_1.useRootSelector)(state => state.tablesConfig);
    const workspaceId = (0, store_1.useRootSelector)(state => state.workspaceId);
    const dispatch = (0, store_1.useRootDispatch)();
    const rememberTableConfig = (conf) => {
        const newTablesConfig = Object.assign({}, tableConfigInStore[tableKey]);
        if (!Object.keys(newTablesConfig).includes(workspaceId))
            newTablesConfig[workspaceId] = {};
        conf.forEach(([key, value]) => {
            updatePath(newTablesConfig, `${workspaceId}.${key}`, value);
        });
        void dispatch((0, ac_1.updateTablesConfig)(tableKey, newTablesConfig));
    };
    return rememberTableConfig;
};
exports.useRememberTableConfig = useRememberTableConfig;
