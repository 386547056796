import storage from '../util/storage'

// whitelist of store keys
// to saved in localStorage
// do not store sensitive data
export default ({
  auth,
  calendarFilters,
  version,
  workspaceId,
  organizationId,
  showAdminPanel,
  tablesConfig,
  enforcedLocality
}) => {
  storage.set('ds-store', {
    auth,
    calendarFilters,
    tablesConfig,
    version,
    workspaceId,
    organizationId,
    showAdminPanel,
    enforcedLocality
  })
}
