import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation OffersToTransfer (
    $workspace: ID!
    $offer: ID!
  ) {
    offerToTransfer (
      workspace: $workspace
      offer: $offer
    ){
      id
    }
  }`

async function requestOfferToTransfer (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('offers-to-transfer', errors)
  }
  return data.offerToTransfer
}

export default requestOfferToTransfer
