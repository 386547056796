import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import {
  Button,
  Flex,
  Icon,
  Spacing,
  Text
} from '@ui'
import { t } from 'i18next'
import { uploadFile } from '@app/util/upload-file'

export const UploadButton = ({ workspaceId, auth, onUpload }) => {
  const [, setFiles] = useState([])
  const onDrop = useCallback((newFiles) => {
    uploadFile(newFiles, workspaceId, auth).then((res) => {
      setFiles(() => {
        const newArr = newFiles.map((item, index) => res[index])
        onUpload(newArr)
        return newArr
      })
    })
  }, [])

  const {
    getRootProps
  } = useDropzone({
    multiple: true,
    onDrop
  })
  return (
    <Flex direction={Flex.DIRECTION.COLUMN}>
      <Text
        text={t('ATTACHMENT')}
        type={Text.TYPES.BODY_MEDIUM}
        weight={Text.WEIGHTS.BOLD}
      />
      <Spacing size={Spacing.SIZES.SIZE_6} />
      <div {...getRootProps()}>
        <Button
          label={t('ATTACHMENT_UPLOAD')}
          onClick={() => null}
          ico={Icon.ICONS.upload}
          style={Button.STYLES.OUTLINED}
          color={Button.COLORS.PRIMARY}
        />
      </div>
    </Flex>
  )
}

UploadButton.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  auth: PropTypes.string.isRequired,
  onUpload: PropTypes.func
}
UploadButton.defaultProps = {
  onUpload: () => null
}
