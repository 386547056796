import React, { useState } from 'react'
import moment from 'moment'
import { t } from 'i18next'

import { getWorkspaceOptions } from '@app/component/pages/organization/helpers'
import { requestOrgInventoryBonusGenerate } from '@app/request'

import {
  Button,
  Dropdown,
  Modal,
  PeriodSelect,
  Text,
  Flex,
  Spacing
} from '@ui'

import connect from './connect'

export const BonusesCreate = connect(({
  setModal,
  countries,
  organizationId,
  auth
}) => {
  const [form, setForm] = useState({
    workspace: null,
    period: {
      start: moment(),
      end: moment()
    }
  })

  const handleCreate = async () => {
    await requestOrgInventoryBonusGenerate({
      workspaces: [form.workspace.value],
      organization: organizationId,
      period: {
        end: form.period.end ? moment(form.period.end).endOf('month') : null,
        start: form.period.start ? moment(form.period.start).startOf('month') : null
      }
    }, auth)
    setModal(null)
  }

  return (
    <Modal
      size={Modal.SIZES.XS}
      isLoading
      headerContent={<Text weight={Text.WEIGHTS.BOLD}>{t('ORG_BONUSES_STOCKTAKING_GENERATE')}</Text>}
      footerContent={
        <Flex justify={Flex.POSITION.END} stretch>
          <Button
            size={Button.SIZES.LARGE}
            style={Button.STYLES.CONTAINED}
            label={t('GENERATE')}
            onClick={handleCreate}
          />
        </Flex>
      }
    >
      <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
        <Spacing size={Spacing.SIZES.SIZE_16}>
          <Text>{t('ORG_BONUSES_STOCKTAKING_GENERATE_INFO')}</Text>
        </Spacing>
        <Dropdown
          style={Dropdown.STYLES.LIGHT}
          size={Dropdown.SIZES.LARGE}
          type={Dropdown.TYPES.VARIABLE}
          label={t('WORKSPACES')}
          placeholder={t('CHOOSE')}
          options={getWorkspaceOptions(countries)}
          onChange={(val) => setForm({ ...form, workspace: val })}
          value={form.workspace}
          singleSelect
        />
        <Spacing size={Spacing.SIZES.SIZE_16} />
        <PeriodSelect
          displayType={PeriodSelect.DISPLAY_TYPE.MONTH}
          label={t('PERIOD')}
          onChange={(v) => setForm({ ...form, period: v })}
          value={form.period}
          singleSelector
        />
      </Spacing>
    </Modal>
  )
})

BonusesCreate.propTypes = {}
BonusesCreate.defaultProps = {}
