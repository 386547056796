import React from 'react'
import { t } from 'i18next'

import { LogoWide } from '@app/image'
import { miscUtil, i18nConfig } from '@app/util'
import {
  Icon,
  Flex,
  Text,
  Spacing,
  Button,
  Card
} from '@ui'

import './index.scss'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError: null,
      errInfo: null,
      browserInfo: null,
      loading: true,
      isExpanded: window.location.hostname === 'localhost'
    }
  }

  async init () {
    await i18nConfig()
    const browser = miscUtil.getBrowser()
    this.setState((oldState) => ({ ...oldState, browserInfo: browser, loading: false }))
  }

  componentDidMount () {
    this.init()
  }

  componentDidCatch (error, info) {
    this.setState({
      hasError: error,
      errInfo: info
    })
    document.title = 'Dayswaps Error'
    console.log(window.location.href, error, info)
  }

  render () {
    if (this.state.loading) return null
    if (this.state.hasError) {
      return (
        <div className='ds-error-boundary'>
          <div className='content'>
            <Flex justify={Flex.POSITION.SPC_BETWEEN} stretch>
              <LogoWide />
              <div>
                {window.location.hostname === 'localhost' && (
                  <Icon
                    ico={Icon.ICONS.close1}
                    size={Icon.SIZES.LARGE}
                    onClick={() => this.setState({ hasError: null })}
                  />
                )}
              </div>
            </Flex>
            <Spacing size={Spacing.SIZES.SIZE_96} />
            <Flex grow={1}>
              <Flex direction={Flex.DIRECTION.COLUMN} align={Flex.POSITION.CENTER}>
                <Text
                  text={t('ERROR_SCREEN_TITLE')}
                  type={Text.TYPES.HEADLINE1}
                  weight={Text.WEIGHTS.BOLD}
                  color={Text.COLORS.WHITE}
                />
                <Spacing size={Spacing.SIZES.SIZE_12} />
                <Text
                  type={Text.TYPES.PARAGRAPH}
                  color={Text.COLORS.WHITE}
                  weight={Text.WEIGHTS.MEDIUM}
                  align={Text.ALIGN.CENTER}
                >
                  {t('ERROR_SCREEN_TITLE_RETRY')}
                  <br />
                  {t('ERROR_SCREEN_HELP_TROUBLESHOOTING')}
                </Text>
                <Spacing size={Spacing.SIZES.SIZE_24} />
                <Button
                  size={Button.SIZES.EXTRA}
                  style={Button.STYLES.CONTAINED}
                  label={t('RETURN_BACK')}
                  bold
                  onClick={() => {
                    window.history.back()
                    this.setState({ hasError: null })
                    window.location.reload()
                  }}
                />
                <Spacing size={Spacing.SIZES.SIZE_32} />
                <Button
                  style={Button.STYLES.UNDERLINED}
                  color={Button.COLORS.WHITE}
                  onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
                >
                  <Flex>
                    <div>{t('WS_SETTINGS_CARD_PLUGINS_SETTINGS_SHOW_DETAIL')}</div>
                    <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                    <Icon
                      ico={this.state.isExpanded ? Icon.ICONS.arrowDown : Icon.ICONS.arrowUp}
                      size={Icon.SIZES.SMALL}
                      color={Icon.COLORS.WHITE}
                    />
                  </Flex>
                </Button>
                <Spacing size={Spacing.SIZES.SIZE_16} />
                {this.state.isExpanded && (
                  <Card width='50%'>
                    <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                      <Flex justify={Flex.POSITION.SPC_BETWEEN}>
                        {this.state.browserInfo && (
                          <Text color={Text.COLORS.BLACK} weight={Text.WEIGHTS.BOLD}>
                            {this.state.browserInfo.name + ' ' + this.state.browserInfo.version}
                          </Text>
                        )}
                        <Icon
                          ico={Icon.ICONS.duplicate}
                          onClick={() => miscUtil.copyToClipBoard(this.state.hasError?.stack)}
                          color={Icon.COLORS.DARK_BLUE}
                        />
                      </Flex>
                      <Spacing size={Spacing.SIZES.SIZE_8} />
                      <Text type={Text.TYPES.BODY_MEDIUM}>
                        {window.location.href + '\n'}
                        {(this.state.hasError.stack) ? this.state.hasError.stack : null}
                      </Text>
                    </Spacing>
                  </Card>
                )}
              </Flex>
            </Flex>
          </div>
        </div>
        /* <>
          <a
             target='_blank'
             rel='noopener noreferrer'
             href='http://help.dayswaps.com/cs/articles/3704734-technicke-problemy-na-strane-prohlizece'
        </> */
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
