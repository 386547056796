import React from 'react'
import { t } from 'i18next'
import {
  Button,
  Flex,
  Spacing,
  Text,
  Link
} from '@ui'

export const NotFound = ({ isOrg }) => {
  return (
    <Flex
      align={Flex.POSITION.CENTER}
      justify={Flex.POSITION.CENTER}
      direction={Flex.DIRECTION.COLUMN}
      flex={1}
      stretch
    >
      <Spacing size={Spacing.SIZES.SIZE_96} />
      <Text
        type={Text.TYPES.HEADLINE1}
        text='Ooops?'
        color={Text.COLORS.DARK_BLUE}
      />
      <Spacing size={Spacing.SIZES.SIZE_8} />
      <Text
        type={Text.TYPES.HEADLINE1}
        text={t('DID_NOT_EXPECT')}
        color={Text.COLORS.DARK_BLUE}
      />
      <Spacing size={Spacing.SIZES.SIZE_32} />
      <Link
        to={isOrg ? '/organization' : '/'}
        type={Link.TYPES.ROUTER_LINK}
      >
        <Button
          size={Button.SIZES.EXTRA}
          style={Button.STYLES.CONTAINED}
          label={t('RETURN_BACK')}
          bold
        />
      </Link>
    </Flex>
  )
}
