import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  ReadNotifications,
  ResolveNotification
} from '@app/ac/set-notification'
import setSidebar from '@app/ac/set-sidebar'

const mapStateToProps = ({ notifications }) => {
  return {
    notifications
  }
}

export default (component) => {
  return compose(
    withRouter,
    connect(mapStateToProps, {
      setSidebar,
      ResolveNotification,
      ReadNotifications
    })
  )(component)
}
