import React from 'react'
import { t } from 'i18next'

import {
  Text,
  Flex,
  Spacing,
  Icon,
  Alert
} from '@ui'

export const Auction = (
  {
    isInvalidStart,
    isInvalidEnd,
    isPlanningDisabled,
    validShifts
  }) => {
  return (
    <Flex direction={Flex.DIRECTION.COLUMN}>
      <Flex align={Flex.POSITION.CENTER}>
        <Icon ico={Icon.ICONS.offer} color={Icon.COLORS.PRIMARY} size={Icon.SIZES.SMALL} />
        <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />
        <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>Burzy</Text>
      </Flex>
      {isInvalidStart && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_FORBIDDEN_DAY')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {isInvalidEnd && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SELECT_END_DATE')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {isPlanningDisabled && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_EMPTY_SHIFTS')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {!isPlanningDisabled && !isInvalidStart && !isInvalidEnd && validShifts.length > 0 && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_INFO_AUCTION_SHIFTS', { x: validShifts.length })}
            type={Alert.TYPES.INFO}
            size={Alert.SIZES.FULL_WIDTH}
            customIco={Icon.ICONS.tip}
          />
        </>)}
    </Flex>)
}
