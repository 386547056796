import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation RequirementCreateMutation (
  $workspace: ID!,
  $template: ID!,
  $position: ID!,
  $locality: ID,
  $start: Int!,
  $duration: Int!,
  $idealSkill: Int,
  $pauses: [PauseData!],
  $pausesFixed: Boolean,
  $requirementsArrayIndex: Int,
  $agenda: [ShiftAgendaTaskInput!]
) {
  requirementCreate (
    workspace: $workspace
    template: $template
    requirementsArrayIndex: $requirementsArrayIndex
    data: {
      position: $position
      locality: $locality
      start: $start
      duration: $duration
      idealSkill: $idealSkill
      pauses: $pauses
      pausesFixed: $pausesFixed
      agenda: $agenda
    }
  ) {
    id
  }
}`

async function requestRequirementCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('requirement-create', errors)
  }
  return data.requirementCreate
}

export default requestRequirementCreate
