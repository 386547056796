import moment from 'moment'

import setCalendarMultiSelect from '../ac/set-calendar-multi-select'
import store from '../store'

import { notification } from '@app/util'

export default (target) => {
  return async (dispatch) => {
    if (!target.date) return
    const { calendarMultiSelect, shifts } = store.getState()

    // if we're not currently selecting targets (calendarMultiSelect.isSelectingTargets === false), just
    // un-select sources that we have selected (like with desktop iconst - when you have some selected and
    // you click the background, they're de-selected)
    if (!calendarMultiSelect.isSelectingTargets) {
      dispatch(setCalendarMultiSelect(Object.assign({}, calendarMultiSelect, { sourceEvents: [], isActive: false })))
      return false
    }

    // prevent selecting cells from the offers row
    if (target.userId === 'offers') {
      notification.error({ code: 'cannotMultiSelectContest' })
      return false
    }
    // prevent selecting cells from unassigned row if copying unavailability
    if (calendarMultiSelect.action === 'copy' && calendarMultiSelect.sourceEvents.length && target.userId === 'unassigned') {
      const shiftOrUnav = shifts.find(s => s.id === calendarMultiSelect.sourceEvents[0].id) ? 'shift' : 'unav'
      if (shiftOrUnav === 'unav') {
        notification.error({ code: 'cannotMultiSelectUnassignedForUnav' })
        return false
      }
    }

    // update the store.calendarMultiSelect
    const foundIdx = calendarMultiSelect.targets.findIndex((t) => moment(t.date).format('YYYY-MM-DD') === moment(target.date).format('YYYY-MM-DD') && t.userId === target.userId)
    if (foundIdx === -1) {
      calendarMultiSelect.targets.push(target)
    } else {
      calendarMultiSelect.targets = calendarMultiSelect.targets.filter((t, i) => i !== foundIdx)
    }

    dispatch(setCalendarMultiSelect(calendarMultiSelect))
  }
}
