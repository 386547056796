import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationWorkspaceRevenueSetMutation(
  $organization: ID!,
  $revenue: [WorkspaceDailyRevenueInput!]!) {
  organizationRevenueSet(organization: $organization,  revenue: $revenue) {
    date
    workspaces {
      date
      amountPlan
      workspaceId
    }
  }
}`

async function requestRevenuePlanSet (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationRevenueSet
}

export default requestRevenuePlanSet
