/* eslint-disable no-case-declarations */
import aT from '../const/action-type'
import initial from '../store/initial'
import { AnnounceShiftSortMapper, announceShiftChangesMapper } from '@app/util/mappers'
import moment from 'moment'

export default (state = initial.announceShifts, action) => {
  const {
    data,
    type
  } = action
  switch (type) {
    case aT.ANNOUNCE_SHIFTS_SORT:
      return {
        announce: AnnounceShiftSortMapper(data),
        known: data,
        periodEnd: data.length ? data.map((item) => item.shift.period.start).reduce((a, b) => (a > b ? a : b)) : '',
        periodStart: data.length ? data.map((item) => item.shift.period.start).reduce((a, b) => (a < b ? a : b)) : ''
      }
    case aT.UPDATE_ANNOUNCE_SHIFT_FROM:
      const { periodEnd, val: from } = data
      return {
        ...state,
        announce: AnnounceShiftSortMapper(state.known.filter((s) => moment(s.shift.period.start).endOf('day').isSameOrAfter(from) && moment(s.shift.period.start).startOf('day').isSameOrBefore(periodEnd))),
        periodStart: moment(from).format('YYYY-MM-DD')
      }
    case aT.UPDATE_ANNOUNCE_SHIFT_TO:
      const { periodStart, val: to } = data
      return {
        ...state,
        announce: AnnounceShiftSortMapper(state.known.filter((s) => moment(s.shift.period.start).endOf('day').isSameOrAfter(periodStart) && moment(s.shift.period.start).startOf('day').isSameOrBefore(to))),
        periodEnd: moment(to).format('YYYY-MM-DD')
      }
    case aT.UPDATE_ANNOUNCE_BY_POSITION:
      const { shiftCheckboxes: shiftCheckboxesPosition, periodEnd: endPosition, periodStart: startPosition } = data
      return {
        ...state,
        announce: announceShiftChangesMapper(shiftCheckboxesPosition, startPosition, endPosition, state, 'positionId'),
        periodEnd: moment(endPosition).format('YYYY-MM-DD'),
        periodStart: moment(startPosition).format('YYYY-MM-DD')
      }
    case aT.UPDATE_ANNOUNCE_BY_LOCALITY:
      const { shiftCheckboxes: shiftCheckboxesLocality, periodEnd: endLocality, periodStart: startLocality } = data
      return {
        ...state,
        announce: announceShiftChangesMapper(shiftCheckboxesLocality, startLocality, endLocality, state, 'localityId'),
        periodEnd: moment(endLocality).format('YYYY-MM-DD'),
        periodStart: moment(startLocality).format('YYYY-MM-DD')
      }
    case aT.UPDATE_ANNOUNCE_BY_PEOPLE:
      const { shiftCheckboxes: shiftCheckboxesPeople, periodEnd: endPeople, periodStart: startPeople } = data
      return {
        ...state,
        announce: announceShiftChangesMapper(shiftCheckboxesPeople, startPeople, endPeople, state, 'userId'),
        periodEnd: moment(endPeople).format('YYYY-MM-DD'),
        periodStart: moment(startPeople).format('YYYY-MM-DD')
      }
    case aT.REVERT_ANNOUNCE:
      const { date, posId, id } = data
      return {
        ...state,
        announce: state.announce.map((item) => item.date === date ? {
          date: item.date,
          positions: [
            ...item.positions.map((pos) => pos.id === posId ? {
              ...pos,
              shifts: pos.shifts.map((shift) => shift.shift.id !== id && shift).filter(Boolean)
            } : pos)],
          changes: item.positions.map((pos) => pos.shifts.length).flat(1).reduce((a, b) => a + b) - 1
        } : item)
      }
    default:
      return state
  }
}
