import { errorUtil, fetchRequest } from '@app/util'

const query = `mutation AreaCreateMutation(
  $organization: ID!,
  $country: ID!
  $name: String!
) {
  areaCreate (
    organization: $organization
    country: $country
    name: $name
  ) {
    id
    name
    workspaces {
      id
    }
  }
}`

async function requestAreaCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data
}

export default requestAreaCreate
