import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `mutation TimeOffUpdateMultiMutation (
  $workspace: ID!,
  $data: [TimeOffUpdateData!]!
) {
  timeOffBulkUpdate (
    workspace: $workspace
    data: $data
  ) {
    timeOffs {
      id
      period {
        start
        end
      }
      recurrence {
        id
      }
    }
    conflictingShifts {
      ${q.SHIFT_PARAMS_BASIC}
    }
  }
}`

async function requestTimeOffUpdateMulti (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('timeoff-update-multi', errors)
  }
  return data.timeOffBulkUpdate
}

export default requestTimeOffUpdateMulti
