import store from '../store'
import isLoading from './is-loading'
import loadTemplates from './load-templates'
import requestRequirementUpdate from '../request/requirement-update'
import addError from '../ac/add-error'
import propsUtil from '@app/util/props'

export default (templateId, shiftHash, form) => {
  return async (dispatch) => {
    const { auth, templates, workspaceId } = store.getState()
    if (!workspaceId || !templateId || !shiftHash || !form) return

    const shift = propsUtil.getShift({ templateId, templates, shiftHash })
    if (!shift) return

    dispatch(isLoading('edit-shift'))

    const position = form.positionId || shift.positionId
    const locality = form.localityId || shift.localityId
    const start = form.start === null ? shift.start : form.start
    const duration = form.duration || shift.duration
    const pauses = form.pauses || shift.pauses
    const pausesFixed = (typeof form.pausesFixed !== typeof null || typeof form.pausesFixed !== typeof undefined)
      ? form.pausesFixed
      : shift.pausesFixed
    const agenda = form.agenda || shift.agenda

    let idSk
    if (typeof form.idealSkill !== typeof undefined) {
      if (typeof form.idealSkill !== typeof null) {
        idSk = parseInt(form.idealSkill)
      } else {
        idSk = null
      }
    }
    const result = await requestRequirementUpdate({
      workspace: workspaceId,
      template: templateId,
      requirement: shift.id,
      position,
      locality,
      start,
      duration,
      idealSkill: idSk,
      pauses,
      pausesFixed,
      agenda
    }, auth)
    if (result && result.error) {
      await dispatch(addError(result.error))
    }

    await dispatch(loadTemplates())
    dispatch(isLoading('edit-shift', true))
  }
}
