import requestEmployees from '../request/employees'
import loadEmployeeWarnings from '../ac/load-employee-warnings'
import isLoading from './is-loading'
import addError from '../ac/add-error'
import setEmployees from '../action/set-employees'
import store from '../store'
import loadPositions from './load-positions'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import {
  miscUtil,
  permissionsUtil
} from '@app/util'
import { NO_LOCALITY } from '@app/const/globals'
import { PERMISSIONS } from '@app/const'

export default (opts) => {
  return async (dispatch) => {
    const {
      excludeExistingEmployee,
      customWsId,
      userIds,
      locality,
      skipEmployeeWarnings
    } = (opts || {})

    const { auth, workspaceId: storeWsId, organization } = store.getState()
    let { positions, loading } = store.getState()
    const workspaceId = customWsId || storeWsId // customWsId is passed here when we're getting employees of a WS we're not in - for example in DS PRO, when we're some ORG manager who's not actually part of WS
    if (!workspaceId) return

    // to load employees we either need to read permission to employees/calendar/backoffice on the WS, or we must have correct permissions
    // in the workspace's organization (when we're some ORG manager, looking at several workspaces in which we have no Role in DS PRO)
    if (customWsId
      ? (!permissionsUtil.canReadOneOf([PERMISSIONS.WORKSPACE.EMPLOYEES, PERMISSIONS.CALENDAR, PERMISSIONS.BACKOFFICE]) && (!organization || !organization.allAllowedWorkspaces || !organization.allAllowedWorkspaces.includes(workspaceId)))
      : !permissionsUtil.canReadOneOf([PERMISSIONS.WORKSPACE.EMPLOYEES, PERMISSIONS.CALENDAR, PERMISSIONS.BACKOFFICE])) {
      return []
    }

    // if load-employees is already running, try waiting for it to finish first
    if (loading.includes('load-employees')) {
      let w = 0
      const maxW = 60
      while (loading.includes('load-employees') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      if (w < maxW) return store.getState().employees
    }

    await dispatch(isLoading('load-employees'))

    // make sure we have the positions loaded
    if (!positions || positions.length === 0) {
      positions = await dispatch(loadPositions())
    }

    // make sure we have WS and its localities loaded (they're loaded as part of WS detail)
    let ws = store.getState().workspaces?.find(w => w.id === workspaceId)
    if (!ws || !ws.localities) {
      await dispatch(loadWorkspaceDetail(workspaceId))
      ws = store.getState().workspaces.find(w => w.id === workspaceId)
    }

    // if 'load-workspace-detail' or 'load-me' is running, try waiting for it to finish first, because we need to have the
    // workspace data to check if we should enforce some locality (next step)
    if (loading.includes('workspace-detail') || loading.includes('me')) {
      let w = 0
      const maxW = 60
      while ((loading.includes('workspace-detail') || loading.includes('me')) && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
    }

    // on very big workspaces, we 'enforce a locality' - we only allow a manager to load employees and shifts with the
    // same locality as he has. we check if we should enforce the locality here.
    // const enforcedLocality = miscUtil.getEnforcedLocality(me.id, store.getState()?.workspaces.find(w => w.id === workspaceId))
    ws = store.getState().workspaces.find(w => w.id === workspaceId)
    const enforcedLocality = miscUtil.getEnforcedLocality(ws)

    // load employees from the API
    let requestReturnedError = false
    const result = await requestEmployees({
      id: workspaceId,
      locality: typeof locality === typeof undefined
        ? (enforcedLocality === NO_LOCALITY // if enforcedLocality is set to NO_LOCALITY constant, we want employees without locality and BE returns them if we pass locality: null
          ? null
          : (enforcedLocality || undefined))
        : (locality || undefined),
      userIds: userIds
    }, auth)
    if (result.error) {
      requestReturnedError = true
      dispatch(addError(result.error))
      dispatch(isLoading('load-employees', true))
    }

    const { employees } = store.getState()
    const alreadyLoadedEmpIds = Object.keys(employees)
    const newlyLoadedOrUpdatedEmpIds = []

    const loadedEmployees = Array.isArray(result.members)
      ? result.members
        .filter((m) => { // if we got some error from API request, we check the validity of each loaded member (they need to have 'user' prop and at least one role in 'roles' array)
          if (!requestReturnedError) return true
          if (!m.user) return false
          if (!Array.isArray(m.roles) || !m.roles.length) return false
          return true
        })
        .filter((m) => m.roles && m.roles.filter(r => !r.hidden).length)
        .filter(m => !!m.user && Array.isArray(m.roles))
        .map((m) => {
        // prepare the store object representing this employee
          const thisEmpRecord = miscUtil.getEmployeeObjectForStore(m.user, m.roles[0])

          // check if this employee is new or updated - those will need more requests later
          const alr = alreadyLoadedEmpIds.find(eid => eid === m.user.id)
          if (!alr) {
            newlyLoadedOrUpdatedEmpIds.push(m.user.id)
          } else {
            if (miscUtil.safeStringify(thisEmpRecord) !== miscUtil.safeStringify(employees[m.user.id])) {
              newlyLoadedOrUpdatedEmpIds.push(m.user.id)
            }
          }

          return thisEmpRecord
        })
      : []

    // merge loaded employees with the ones we already had in store - this prevents
    // the deletion of some extra employee data loaded asynchronously by other requests,
    // such as employee warnings (however, if 'customWsId' was passed, we ignore previously
    // loaded employees, because they probably belonged to different WS)
    const finalEmployees = customWsId ? {} : Object.assign({}, employees)
    if (excludeExistingEmployee) delete finalEmployees[excludeExistingEmployee]
    loadedEmployees.forEach((ee) => {
      if (finalEmployees[ee.id]) {
        finalEmployees[ee.id] = Object.assign({}, finalEmployees[ee.id], ee)
      } else {
        finalEmployees[ee.id] = ee
      }
    })

    // save merged employee data to store
    await dispatch(setEmployees(finalEmployees))
    await dispatch(isLoading('load-employees', true))

    // asynchronously load the employee warings for newly loaded employees (that request is slow)
    if (newlyLoadedOrUpdatedEmpIds.length && !skipEmployeeWarnings) {
      dispatch(loadEmployeeWarnings({ users: newlyLoadedOrUpdatedEmpIds }))
    }

    return finalEmployees
  }
}
