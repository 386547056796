import store from '../store'
import isLoading from './is-loading'
import setTablesConfig from '../action/set-tables-config'

export default (tableName, update) => {
  return async (dispatch) => {
    const { tablesConfig } = store.getState()
    if (!tableName) return false

    dispatch(isLoading('update-table-config'))

    const newTablesConfig = Object.assign({}, tablesConfig)
    if (!Object.keys(newTablesConfig).includes(tableName)) newTablesConfig[tableName] = {}
    newTablesConfig[tableName] = Object.assign({}, newTablesConfig[tableName], update)

    dispatch(setTablesConfig(newTablesConfig))

    dispatch(isLoading('update-table-config', true))
    return newTablesConfig
  }
}
