"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mutate = void 0;
const _1 = require(".");
const __1 = require("..");
const mutate = (mutation, { variables, }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield _1.client.mutate({
        mutation,
        variables,
    });
    if (result.errors) {
        console.log('API request returned some errors:');
        console.log(JSON.stringify({ errors: result.errors, variables, mutation }, null, 2));
    }
    return ({
        data: result.data,
        errors: result.errors ? __1.errorUtil.reduceErrors(Array(result.errors)) : undefined,
    });
});
exports.mutate = mutate;
