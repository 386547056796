import React, { useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import countryList, { getName } from 'country-list'
import currencyCodes from 'currency-codes'

import {
  Button,
  Modal,
  Flex,
  Spacing,
  Dropdown
} from '@ui'

import connect from './connect'

const CountryCreate = ({
  close,
  countries,
  createCountry,
  organizationId
}) => {
  const [form, setForm] = useState({
    organization: organizationId,
    code: undefined,
    currency: undefined
  })

  const countryOptions = countryList.getData().map(co => {
    return {
      value: co.code.toLowerCase(),
      label: co.name
    }
  })

  const allCurrenciesSorted = currencyCodes.data.sort((a, b) => { return countries.filter(c => a.countries.includes(getName(c.code))).length > countries.filter(c => b.countries.includes(getName(c.code))).length ? -1 : 1 })
  const currencyOptions = allCurrenciesSorted.map(cur => {
    return {
      label: cur.code + ': ' + cur.currency,
      value: cur.code.toLowerCase()
    }
  })

  return (
    <Modal
      size={Modal.SIZES.S}
      footerContent={
        <Flex stretch>
          <Button
            onClick={close}
            size={Button.SIZES.FULL}
            label={t('CLOSE')}
          />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            onClick={() => createCountry(form)}
            style={Button.STYLES.CONTAINED}
            size={Button.SIZES.FULL}
          >
            {t('SAVE')}
          </Button>
        </Flex>
      }
      headerContent={<div className='ds-title'>{t('ORG_SP_WORKSPACES_COUNTRIES_ADD_NEW')}</div>}
      sections={[
        <>
          <Dropdown
            label={t('ORG_SP_WORKSPACES_COUNTRIES')}
            type={Dropdown.TYPES.VARIABLE}
            style={Dropdown.STYLES.LIGHT}
            size={Dropdown.SIZES.FULL_WIDTH}
            searchable
            singleSelect
            placeholder={t('CHOOSE')}
            options={countryOptions}
            value={countryOptions.find(co => co.value === form.code)}
            onChange={(val) => {
              setForm({ ...form, code: val.value })
            }}
          />
          <Dropdown
            label={t('CURRENCY')}
            type={Dropdown.TYPES.VARIABLE}
            style={Dropdown.STYLES.LIGHT}
            size={Dropdown.SIZES.FULL_WIDTH}
            searchable
            disableSort
            singleSelect
            placeholder={t('CHOOSE')}
            options={currencyOptions}
            value={currencyOptions.find(co => co.value === form.currency)}
            onChange={(val) => {
              setForm({ ...form, currency: val.value })
            }}
          />
        </>
      ]}
    />
  )
}

CountryCreate.propTypes = {
  close: PropTypes.func.isRequired
}
CountryCreate.defaultProps = {}

export default connect(CountryCreate)
