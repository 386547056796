import store from '../store'
import isLoading from '@app/ac/is-loading'
import { loadEmployees, loadWorkspaceDetail, setWorkspaceId, loadNotifications } from '@app/ac/index'
import setAssistantConversation from '@app/action/set-assistant-conversation'
import switchWorkspace from '@app/action/switch-workspace'
import setOrganizationId from '@app/ac/set-organization-id'
import { routeUtil } from '@app/util'

export default (ws) => {
  return async (dispatch) => {
    const { organizationId } = store.getState()
    dispatch(isLoading('workspace-switch'))
    if (ws.organizationId !== organizationId) {
      await dispatch(setOrganizationId(ws.organizationId))
    }
    await dispatch(setWorkspaceId(ws.id, { resetCalendarFilters: true }))
    await dispatch(switchWorkspace())
    await dispatch(loadEmployees())
    await dispatch(loadNotifications())
    await dispatch(loadWorkspaceDetail())
    await dispatch(setAssistantConversation(null))
    routeUtil.navigate('/')
    dispatch(isLoading('workspace-switch', true))
  }
}
