import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query WorkspaceSelectQuery {
  me {
    workspaces {
      id
      name
      customId
      enforceLocalities
      localities { name, shortName, id, assigns { userId }}
    }
    organizations {
      hidden
      id
      name
    }
  }
}`

async function requestWorkspaceSelect (token) {
  const response = await fetchRequest({ query, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-exchanges-real', errors)
  }
  return data.me
}

export default requestWorkspaceSelect
