import store from '../store'
import isLoading from './is-loading'
import loadPositions from './load-positions'
import requestPositionAssign from '../request/position-assign'
import addError from '../ac/add-error'

export default (user, position, contract, reload = true) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return

    dispatch(isLoading('assign-position'))
    const result = await requestPositionAssign({
      workspace: workspaceId,
      user,
      position,
      contract
    }, auth)
    if (result.error) {
      dispatch(addError(result.error))
    } else {
      if (reload) {
        await dispatch(loadPositions())
      }
    }
    dispatch(isLoading('assign-position', true))
    return result
  }
}
