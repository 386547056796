import store from '../store'
import isLoading from './is-loading'
import requestUpdateWarnings from '../request/warnings-update'
import addError from '../ac/add-error'

export default (variables) => {
  return async (dispatch) => {
    const { auth, workspaceId, loading } = store.getState()
    if (!workspaceId) return

    // remember that we're running this update at the moment, so we never try
    // to run it twice in parallel. this is an expensive operation for the BE
    const updateId = (variables.user && variables.period && variables.period.start && variables.period.end)
      ? variables.user + variables.period.start.toString() + variables.period.end.toString()
      : null
    if (updateId && loading.includes(updateId)) return

    dispatch(isLoading('update-warnings'))
    if (updateId) dispatch(isLoading('update-warnings-' + updateId))

    const res = await requestUpdateWarnings(Object.assign({}, variables, { workspaceId: workspaceId }), auth)
    if (res.error) await dispatch(addError(res.error))

    dispatch(isLoading('update-warnings', true))
    if (updateId) dispatch(isLoading('update-warnings-' + updateId, true))
  }
}
