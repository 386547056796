import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ShiftTemplateCreateMutation (
  $workspace: ID!,
  $name: String!,
  $data: ShiftData
) {
  shiftTemplateCreate (
    workspace: $workspace
    name: $name
    data: $data
  ) {
    id
  }
}`

async function requestShiftTemplateCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-template-create', errors)
  }
  return data.shiftTemplateCreate
}

export default requestShiftTemplateCreate
