import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation OffersCreate (
    $workspace: ID!
    $shifts: [ID!]!
    $options: OfferOptions!
  ) {
    offerCreateMulti (
      workspace: $workspace
      shifts: $shifts
      options: $options
    ){
      id
      shiftId
      status
      swaps {
        shiftId
      }
      announceAction {
        targetUsers
      }
      notifyEmployees
    }
  }`

async function requestOffersCreate (variables, token, batchDoneCallback) {
  // how many offers can we create in one request (batch)
  const maxOffersInOneBatch = 15

  // divide the variables?.shifts into batches of 'maxOffersInOneBatch' or fewer elements, because we want to divide
  // the big request into multiple smaller ones, so the API doesn't timeout (offer creation is slow)
  let batches = []
  if (variables?.shifts?.length > maxOffersInOneBatch) {
    for (let i = 0; i < variables.shifts.length; i += maxOffersInOneBatch) {
      batches.push(Object.assign({}, variables, { shifts: variables.shifts.slice(i, i + maxOffersInOneBatch) }))
    }
  } else {
    batches = [variables]
  }

  // call the requests
  const responses = []
  let offersCreatedSoFar = 0
  for (const batch of batches) {
    const response = await fetchRequest({ query, variables: batch, token })
    // call the provided callback function after each batch, with provided arguments:
    //  - 1: how many offers were created so far
    //  - 2: how many total offers are we creating
    offersCreatedSoFar += batch.shifts.length
    if (batchDoneCallback) batchDoneCallback(offersCreatedSoFar, variables?.shifts?.length)

    if (!response) return errUtil.noResponse()
    responses.push(response)
  }

  const results = []
  for (const response of responses) {
    const { errors, data } = response
    if (errors) {
      return errUtil.apiError('offers-create', errors)
    } else {
      results.concat(data.offerCreateMulti)
    }
  }

  return results
}

export default requestOffersCreate
