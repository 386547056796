import React from 'react'
import { t } from 'i18next'

import connect from './connect'
import './index.scss'

const Locality = ({ id, localities, name, shortName }) => {
  const loc = localities && localities.find(l => l.id === id)
  return (
    <div className='ds-locality-badge-wrapper'>
      <div
        className='ds-locality-tag'
        title={loc
          ? loc.name
          : name || t('GLOBAL_NO_LOCALITY')}
      >
        <div className='ds-locality-name'>
          {loc
            ? loc.shortName
            : shortName || t('GLOBAL_NO_LOCALITY')}
        </div>
      </div>
    </div>
  )
}

export default connect(Locality)
