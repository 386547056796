import { Component } from 'react'
import connect from './connect'

export const withDoubleClick = (WrappedComponent) => {
  let doubleClickTimeout = null

  class Wrapper extends Component {
    handleDoubleClick (fn) {
      if (doubleClickTimeout !== null) {
        // this is double-click
        clearTimeout(doubleClickTimeout)
        doubleClickTimeout = null

        // call the function
        fn()
      } else {
        // this is single-click
        doubleClickTimeout = setTimeout(() => {
          clearTimeout(doubleClickTimeout)
          doubleClickTimeout = null
        }, 400)
      }
    }

    render () {
      return <WrappedComponent onDoubleClick={this.handleDoubleClick} {...this.props} />
    }
  }

  return connect(Wrapper)
}
