
import fetchRequest from '../util/fetch'
import { WorkspaceName } from '@app/queries'
import isLoading from './is-loading'
import addError from '../ac/add-error'
import setWorkspaces from '../action/set-workspaces'
import store from '../store'

export default (ids, silent) => {
  return async (dispatch) => {
    if (!ids?.length) return
    let { loading, workspaces } = store.getState()

    // if workspace-names is already running, try waiting for it to finish first
    if (loading.includes('workspace-names')) {
      let w = 0
      const maxW = 60
      while (loading.includes('workspace-names') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      if (w < maxW) return workspaces.filter(w => ids.includes(w.id))
    }

    if (!silent) dispatch(isLoading('workspace-names'))
    const results = []
    let errors = []

    for (const wid of ids) {
      const res = await fetchRequest({
        query: WorkspaceName,
        variables: {
          id: wid
        }
      })
      if (res?.data?.workspace) {
        results.push({
          id: wid,
          name: res.data.workspace.name || ''
        })
      }
      if (res?.errors?.length) errors = errors.concat(res?.errors)
    }

    if (errors?.length) {
      for (const err in errors) {
        await dispatch(addError(err))
      }
      if (!silent) dispatch(isLoading('workspace-names', true))
    }

    if (results?.length) {
      const { workspaces } = store.getState()
      const newWorkspaces = [...workspaces]

      results.filter(resultWS => !newWorkspaces.some(ws => ws.id === resultWS.id)).forEach(resultWS => {
        newWorkspaces.push(resultWS)
      })

      for (let i = 0; i < newWorkspaces.length; i++) {
        const rslt = results.find(r => r.id === newWorkspaces[i].id)
        if (rslt && rslt.name !== '') newWorkspaces[i].name = rslt.name
      }

      await dispatch(setWorkspaces(newWorkspaces))
    }
    if (!silent) dispatch(isLoading('workspace-names', true))

    return results
  }
}
