/* eslint-disable react/jsx-fragments */
import './index.scss'
import React, { Fragment } from 'react'
import connect from './connect'
import { t } from 'i18next'
import { Dropdown } from '@ui'
import moment from 'moment'
import requestShiftCoworkers from '@app/request/shift-coworkers'
import {
  calendarUtil,
  miscUtil
} from '@app/util'
import { Modal } from '@ui/modal'
import {
  DateAndTime,
  Pauses,
  ShiftAttributes,
  PositionAndLocality,
  Workspace,
  Coworkers,
  SwapOptions,
  Agenda,
  StandbyActivities
} from './components'
import {
  FooterReplyToOffer,
  FooterSaveContractIdChange,
  FooterStartOffer,
  FooterUpdateStandbyActivities
} from './components/footers'

class ViewShiftAsEmployee extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      coworkers: [],
      standByActivities: [], // this is updated whenever user changes something on standByActivities
      selectedSwap: null, // if the shift has a 'swap' offer, this contains the selected swap option
      selectedContractId: null
    }
  }

  setStateAsArrowFn = (newState) => {
    this.setState(newState)
  }

  componentDidMount () {
    // load the shiftDetails
    this.loadDetails()
  }

  loadDetails = () => {
    const { shift, loadShiftDetail, auth, loadEmployeeShifts, loadWorkspaceNames, me, workspaces } = this.props

    if (!shift) return
    loadShiftDetail(shift, false, true).then((sdRes) => {
      // load the coworkers
      requestShiftCoworkers({ shift }, auth).then((res) => {
        this.setState((s) => Object.assign({}, s, { coworkers: res.filter(c => c.user.id !== me.id) }))
      })

      // if there is a 'swap' offer on this shift, we also load all the user's future shifts, so we can render the swap options
      if (sdRes && sdRes.offer && sdRes.offer.swaps && sdRes.offer.swaps.length) {
        loadEmployeeShifts({
          period: {
            start: moment().startOf('day'),
            end: moment().add(3, 'months').endOf('day')
          },
          user: me.id
        })
      }

      // if the WS names of this shift is unknown, load it
      const ws = workspaces.find(w => w.id === sdRes.workspaceId)
      const transferInfo = (sdRes?.offer && sdRes?.offer?.transfer) || (sdRes?.transfer)
      const hasMaster = transferInfo?.master
      if (!hasMaster?.workspaceName && ws?.id && (typeof ws?.name !== 'string')) {
        loadWorkspaceNames([sdRes.workspaceId])
      }

      // set state.standByActivities
      if (sdRes.standByActivities) {
        this.setState((s) => Object.assign({}, s, { standByActivities: sdRes.standByActivities }))
      }
    })
  }

  render () {
    const { isLoading, shiftDetail, positions, workspaces, setModal, updateLiveShift, me, workspaceId, isEmployeeCalendar, isPluginEnabled, auth, employee, shifts } = this.props

    // set the contents of 'details' accordingly
    const details = shiftDetail
    if (details && details.idealSkill) details.idealSkill = parseInt(details.idealSkill)
    const ws = details && workspaces ? workspaces.find(w => w.id === details.workspaceId) : null

    const transferInfo = (details && details.offer && details.offer.transfer) || (details && details.transfer)
    const hasMaster = transferInfo && transferInfo.master

    const offer = details && details.offer && details.offer.status !== 'resolved' ? details.offer : null
    const iAmCandidate = offer && offer.announceAction && offer.announceAction.targetUsers && offer.announceAction.targetUsers.includes(me.id)
    const iAmCurrentlyAssigned = Boolean(details?.userId === me?.id)
    const isTooLateToStartOffer = details && ws && ws.settings && ws.settings.offers && ws.settings.offers.minHoursBeforeShift && moment(details.period.start).diff(moment(), 'hours') <= ws.settings.offers.minHoursBeforeShift
    const attribs = calendarUtil.getShiftAttributes(details, ws)

    const iHaveAccessToShiftsWS = calendarUtil.iHaveCalendarAccessInWorkspace(details?.workspaceId, me)

    const isAgendaDisplayed = Boolean(details?.agenda && details?.agenda?.length)
    const supportedAttributes = calendarUtil.getSupportedShiftAttributes(ws, details) // prepare attributes for agenda tasks

    const isStandByShift = Boolean(details?.standBy)
    const isStandbyActivitiesDisplayed = Boolean(isStandByShift && !offer && moment(details.period.start).isBefore(moment()))

    let relevantContracts = []
    let selectedContractVal = null
    const optsContracts = []
    if (hasMaster && !isEmployeeCalendar) {
      relevantContracts = Array.isArray(employee?.contracts)
        ? employee.contracts
          .filter((contract) => contract.period && (
            moment(contract.period.start).isBefore(moment(details.period.start)) || !contract.period.start
          ) && (
            moment(contract.period.end).isAfter(moment(details.period.start)) || !contract.period.end
          )
          )
        : []

      relevantContracts.forEach(con => {
        const ct = ws.contractTypes?.find((wct) => wct.id === con.type)
        optsContracts.push({
          label: ((ct?.name || con.type || '') + (con.contractId ? (' ' + con.contractId) : '')).trim(),
          value: con.id
        })
      })

      const contractId = this.state.selectedContractId || shiftDetail.contractId

      const valueOption = contractId && optsContracts.find((o) => o.value === contractId)
      selectedContractVal = valueOption || null
    }
    const isContractIdManuallyChanged = (!!this.state.selectedContractId && this.state.selectedContractId !== shiftDetail.contractId)
    const isCalendarLocked = Boolean(details && miscUtil.isMyCalendarLocked(details.period?.start, ws))

    return (
      <Modal
        size={(isAgendaDisplayed || isStandbyActivitiesDisplayed || offer?.swaps?.length) ? Modal.SIZES.M : Modal.SIZES.S}
        className='ds-modal-view-shift-emp'
        headerContent={<div className='ds-title'>{t('VIEW_SHIFT_TITLE_DETAIL' + (hasMaster ? '_TRANSFER' : ''))}</div>}
        isLoading={isLoading}
        sections={!details ? [] : [
          // Data Section
          <Fragment key={0}>
            {/* Date & Time */}
            <DateAndTime details={details} />

            {/* Pauses */}
            <Pauses details={details} />

            {/* OverTime */}
            {details.overTime
              ? (
                <div className='ds-viewshift-row'>
                  <div className='ds-viewshift-col-label'>
                    {t('OVERTIME')}
                  </div>
                  <div className='ds-viewshift-col-value'>
                    {t('YES')}
                  </div>
                </div>
              )
              : null}

            {/* Shift Attributes */}
            <ShiftAttributes details={details} attribs={attribs} />

            {/* Position & Locality */}
            <PositionAndLocality details={details} ws={ws} hasMaster={hasMaster} positions={positions} />

            {/* Workspace */}
            <Workspace ws={ws} hasMaster={hasMaster} />

            {/* Coworkers */}
            <Coworkers hasMaster={hasMaster} coworkers={this.state.coworkers} />

            {/* Contract selection on slave shifts in manager's calendar */}
            {(hasMaster && !isEmployeeCalendar) && (
              <div className='ds-viewshift-row'>
                <div className='ds-viewshift-col-label'>
                  {t('AGREEMENT')}
                </div>
                <div className='ds-viewshift-col-value'>
                  <Dropdown
                    singleSelect
                    searchable
                    style={Dropdown.STYLES.LIGHT}
                    type={Dropdown.TYPES.VARIABLE}
                    options={optsContracts}
                    value={selectedContractVal}
                    onChange={({ value }) => {
                      this.setState(s => Object.assign({}, s, { selectedContractId: value }))
                    }}
                    placeholder={t('CHOOSE_CONTRACT')}
                    disabled={isCalendarLocked}
                  />
                </div>
              </div>
            )}

            {/* Swap options */}
            <SwapOptions state={this.state} setState={this.setStateAsArrowFn} offer={offer} shifts={shifts} iAmCurrentlyAssigned={iAmCurrentlyAssigned} />

            {/* Note */}
            {details.note && (
              <div className='ds-viewshift-row'>
                <div className='ds-viewshift-col-label'>{t('NOTE')}</div>
                <div className='ds-viewshift-col-value'>{details.note}</div>
              </div>)}
          </Fragment>,

          // Agenda Section
          (isAgendaDisplayed ? <Agenda key={1} details={details} supportedAttributes={supportedAttributes} auth={auth} loadDetails={this.loadDetails} workspaceId={workspaceId} disabled={isCalendarLocked} /> : null),

          // Standby Activities Section
          (isStandbyActivitiesDisplayed ? <StandbyActivities key={2} details={details} state={this.state} setState={this.setStateAsArrowFn} disabled={isCalendarLocked} /> : null)

        ].filter(Boolean)}
        footerContent={
          // BEHOLD, THE LOGIC!

          !details // if we still don't have details
            ? null // render nothing
            // if we have details ...
            : isContractIdManuallyChanged // if we've changed slave shift's contractId as manager
              ? <FooterSaveContractIdChange details={details} close={this.props.close} selectedContractId={this.state.selectedContractId} updateLiveShift={updateLiveShift} /> // show button to save contractId change
              // if we didn't touch shift's contractId ...
              : offer // if there is an active offer on the shift
                ? iAmCurrentlyAssigned // if I'm the current assignee on this shift (meaning that I requested this offer, but it's still ongoing)
                  ? <>{t('REQUEST_CONTEST_NOTICE_2')}</> // tell me the shift is still mine, while the offer is being resolved
                  : offer.announceAction?.myReply // if we already replied to the offer
                    ? <>{t('CONT_ANSWER_ANSWERED_' + offer.announceAction.myReply.reply?.toUpperCase())}</> // show us what our reply was
                    // if we haven't replied to the offer yet
                    : <FooterReplyToOffer isEmployeeCalendar={Boolean(isEmployeeCalendar)} iAmCandidate={iAmCandidate} details={details} offer={offer} hasMaster={Boolean(hasMaster)} meId={me?.id} close={this.props.close} selectedSwap={this.state.selectedSwap} allowMultipleShiftsOnOneDay={Boolean(ws?.settings?.allowMultipleShiftsOnOneDay)} /> // render buttons with offer replies
                // if there is no active offer on the shift ...
                : isStandByShift // if this is a standby shift
                  ? <FooterUpdateStandbyActivities details={details} originalStandByActivities={details.standByActivities} standByActivities={this.state.standByActivities} workspaceId={workspaceId} auth={auth} /> // or allow us to modify activities on this standby shift
                  // if this is a regular, non-standby shift with no offer
                  : details.workspaceId === workspaceId // if it's on currently selected WS
                    ? <FooterStartOffer isTooLateToStartOffer={isTooLateToStartOffer} details={details} meId={me?.id} setModal={setModal} close={this.props.close} isPluginEnabled={isPluginEnabled} /> // allow us to start offer on it
                    : iHaveAccessToShiftsWS // or just tell us to switch to the shift's WS first
                      ? <>{t('MY_DASHBOARD_SWITCH_WS')}</>
                      : <>{t('VIEW_SHIFT_SHIFT_IS_EXTERNAL')}</>
        }
      />
    )
  }
}

export default connect(ViewShiftAsEmployee)
