import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import { Button, Icon } from '@ui'

import './style.scss'

export const EditInfoPanel = ({
  onCancel,
  onSave,
  count,
  savingLoading,
  isDisabled,
  customText
}) => (
  <div className={'edit-info-panel' + (isDisabled ? ' has-problem' : '')}>
    <div className='left-side'>
      <Icon ico={Icon.ICONS.warning} color={Icon.COLORS.WHITE} />
      <div className='panel-text'>
        {(!!isDisabled && typeof isDisabled === 'string')
          ? isDisabled
          : (customText || t('EDITED_PANEL', { x: count }))}
      </div>
    </div>
    <div className='button-group'>
      <Button
        size={Button.SIZES.SMALL}
        color={Button.COLORS.WHITE}
        label={t('CANCEL')}
        onClick={onCancel}
        bold
      />
      <Button
        size={Button.SIZES.SMALL}
        style={Button.STYLES.CONTAINED}
        color={Button.COLORS.WHITE}
        label={t('SAVE')}
        onClick={onSave}
        loading={savingLoading}
        disabled={isDisabled}
        bold
      />
    </div>
  </div>
)

EditInfoPanel.propTypes = {
  count: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  customText: PropTypes.string,
  isDisabled: PropTypes.oneOfType(PropTypes.bool, PropTypes.string),
  savingLoading: PropTypes.bool
}
EditInfoPanel.defaultProps = {
  count: 0,
  onCancel: null,
  onSave: null,
  customText: null,
  isDisabled: false,
  savingLoading: false
}
