"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadRoleDetails = void 0;
const create_ac_1 = require("@app/ac/util/create-ac");
const request_role_details_1 = require("../request/request-role-details");
const util_1 = require("@app/util");
const user_details_1 = require("@app/reducer/user-details");
exports.loadRoleDetails = (0, create_ac_1.createAC)((dispatch, args) => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield (0, request_role_details_1.requestRoleDetails)(args);
    if ((0, util_1.isUndefined)(data))
        return;
    dispatch((0, user_details_1.setRole)(data));
    return data;
}));
