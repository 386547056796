function sortByKey (arr, key, reverse) {
  arr.sort((a, b) => {
    if (a[key] > b[key]) {
      return reverse ? -1 : 1
    }
    if (a[key] < b[key]) {
      return reverse ? 1 : -1
    }
    return 0
  })
  return arr
}

function sortTable (dataset, sort, format) {
  if (!sort || !sort.field) return dataset
  return [...dataset].sort((ee1, ee2) => {
    const v1 = format(ee1, sort.field)
    const v2 = format(ee2, sort.field)
    if (sort.direction === 'asc') {
      return v1 >= v2 ? 1 : -1
    }
    if (sort.direction === 'desc') {
      return v1 >= v2 ? -1 : 1
    }
    return 0
  })
}

// return sorted array of employees, based on:
//   1) 'external' (internal are before external)
//   2) 'lastName' and then 'firstName' (if 'lastName' exists)
//   3) 'name' (if 'lastName' didn't exist)
function sortEmployees (empsPar) {
  const emps = Array.isArray(empsPar) ? empsPar : Object.values(empsPar)

  emps.sort((a, b) => {
    // Check 'external' property
    const aExternal = a.external === true
    const bExternal = b.external === true
    if (!aExternal && bExternal) return -1
    if (aExternal && !bExternal) return 1

    // Check 'lastName' or 'name' property
    const aLastName = (a.lastName || a.name)?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() || ''
    const bLastName = (b.lastName || b.name)?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() || ''
    if (aLastName < bLastName) return -1
    if (aLastName > bLastName) return 1

    // Check 'firstName' property, if 'lastName'/'name' are the same or nonexistent
    const aFirstName = (a.firstName?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '').toLowerCase()
    const bFirstName = (b.firstName?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '').toLowerCase()
    if (aFirstName < bFirstName) return -1
    if (aFirstName > bFirstName) return 1

    return 0
  })

  return emps
}

export default {
  sortTable,
  sortByKey,
  sortEmployees
}
