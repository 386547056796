import isLoading from './is-loading'
import setCalendarSearch from '../action/set-calendar-search'

export default (calendarSearch) => {
  return async (dispatch) => {
    dispatch(isLoading('set-calendar-search'))

    // update the store.calendarSearch
    await dispatch(setCalendarSearch(calendarSearch))

    dispatch(isLoading('set-calendar-search', true))
  }
}
