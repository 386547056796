import fetchRequest from '../../util/fetch'
import errUtil from '../../util/error'
import q from '../query'

const query = `query OrganizationWarnings(
  $period: PeriodInput!, 
  $filter: OrganizationFilter!, 
  $organizationId: ID!
) {
organization(id: $organizationId) {
  eventWarnings(period: $period, filter: $filter) {
    eventId
    eventType
    userId
    date
    userName
    warnings ${q.EVENT_WARNING}
    workspaceId
  }
}
}`

async function requestOrgWarnings (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('warnings', errors)
  }
  return data.organization.eventWarnings
}

export default requestOrgWarnings
