import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { createPosition, setModal } from '@app/ac'

const mapStateToProps = ({
  loading,
  organizationId,
  organization
}) => {
  return {
    loading,
    organizationId,
    organization
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    close: () => setModal(null),
    createPosition
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
