import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestShiftAttributeDelete from '../request/shift-attribute-delete'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'

export default (attributeId) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !attributeId) return

    dispatch(isLoading('delete-shift-attribute'))

    let result = null
    result = await requestShiftAttributeDelete({
      id: attributeId,
      workspace: workspaceId
    }, auth)

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('delete-shift-attribute', true))
    return result
  }
}
