import { setUser } from '@sentry/browser'
import moment from 'moment'

import store from '@app/store'
import {
  setAuth,
  setMe,
  setWorkspaces
} from '@app/action'
import { routeUtil, miscUtil, timeUtil } from '@app/util'
import { requestMe } from '@app/request'

import isLoading from './is-loading'
import addError from './add-error'
import setModal from './set-modal'
import setLocale from './set-locale'
import setWorkspaceId from './set-workspace-id'
import loadNotifications from './load-notifications'
import setCalendar from '@app/action/set-calendar'

export default (forceReload, silent) => {
  return async (dispatch) => {
    const {
      auth,
      locale
    } = store.getState()

    if (!auth) return
    // LOAD and SET ME
    if (!silent) await dispatch(isLoading('first-load'))
    if (!silent) dispatch(isLoading('me'))
    const meResult = await getMe()
    if (!meResult) return
    await dispatch(setMe(meResult))
    if (!silent) await dispatch(isLoading('me', true))

    await setUserLocale()
    await detectUnsupportedBrowser()

    const hasRoles = await checkForRoles()
    if (!hasRoles) return

    await setUserWorkspaces(meResult)
    await dispatch(setWorkspaceId(null, { autoSelect: true }))

    await setCalendarToStore()
    if (!silent) await dispatch(isLoading('first-load', true))

    // identify the user for 3rd party tracking/analytics tools
    await identifyForSentry()
    await updateWindowTrackingData()

    await dispatch(loadNotifications())

    // FUNCTIONS
    async function getMe () {
      const result = await requestMe(auth)
      if (result.error) {
        dispatch(addError(result.error))
        dispatch(setAuth(null))
        stopLoading()
        if (result.error.type === 'invalid-token') {
          routeUtil.navigate('/registration/invalid')
        }
        return
      }
      return result
    }

    async function checkForRoles () {
      if (
        !Object.keys(meResult?.acl?.workspaces).length &&
        !Object.keys(meResult?.acl?.organizations).length &&
        !window.location.pathname.includes('/accept-invite') // if we're in a process of accepting an invite, don't redirect us anywhere
      ) {
        stopLoading()
        routeUtil.navigate('/no-workspace')
        return false
      }
      return true
    }

    async function setUserWorkspaces (meResult) {
      if (!silent) dispatch(isLoading('workspaces'))
      if (meResult?.acl?.workspaces) {
        Object.keys(meResult?.acl?.workspaces).map(wid => { return { id: wid } })
        await dispatch(setWorkspaces(Object.keys(meResult?.acl?.workspaces).map(wid => { return { id: wid } })))
      }
      if (!silent) await dispatch(isLoading('workspaces', true))
    }

    async function setUserLocale () {
      if (!locale || locale !== meResult.language) {
        await dispatch(setLocale(meResult.language))
      }
    }

    async function detectUnsupportedBrowser () {
      const browser = miscUtil.getBrowser()
      let supported = true
      console.log('Detected browser: ', browser.name, browser.version)
      if (browser.name === 'Edge' && parseInt(browser.version) < 79) supported = false // old Edge
      if (browser.name === 'Firefox' && parseInt(browser.version) < 52) supported = false // old Firefox
      if (browser.name === 'IE') supported = false // any IE

      if (!supported) {
        dispatch(setModal('unsupported-browser', { browser, noClose: true }))
        return false
      }
    }

    async function identifyForSentry () {
      if (window.location.hostname !== 'localhost') {
        setUser({ email: meResult.email, id: meResult.id })
      }
    }

    async function updateWindowTrackingData () {
      window.trackingData = window.trackingData || {}
      window.trackingData.userId = meResult.id
      window.trackingData.firstName = meResult.firstName
      window.trackingData.lastName = meResult.lastName
      window.trackingData.email = meResult.email
      window.trackingData.mailingVerificationHash = meResult.mailingVerificationHash
      window.trackingData.language = meResult.language
      window.trackingData.telephone = meResult.telephone
      window.trackingData.created = parseInt(moment(meResult.created).format('X'))
    }

    async function setCalendarToStore () {
      const isMobile = window.matchMedia('only screen and (max-device-width: 768px)').matches
      const currentCalendarInStore = store.getState().calendar
      await dispatch(setCalendar(Object.assign({}, currentCalendarInStore, {
        view: (isMobile ? 'day' : 'month'),
        date: timeUtil.getInitialCalendarDate()
      })))
    }

    function stopLoading () {
      dispatch(isLoading('me', true))
      dispatch(isLoading('log-in', true))
      dispatch(isLoading('first-load', true))
    }
  }
}
