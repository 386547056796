import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import setModal from '@app/ac/set-modal'
import setSidebar from '@app/ac/set-sidebar'
import logout from '@app/ac/log-out'

const mapStateToProps = ({
  me,
  newsFeed,
  organizationId,
  workspaceId,
  workspaces,
  tasks,
  notifications,
  organization,
  sidebar,
  auth,
  employees,
  positions,
  templates,
  loading
}) => {
  const workspace = workspaces.find((ws) => ws.id === workspaceId)
  const isOrgOwner = Boolean(organizationId && me?.acl?.organizations && Object.keys(me.acl.organizations).includes(organizationId) && me.acl.organizations[organizationId].includes('organization_owner'))

  return {
    sidebar,
    auth,
    workspace,
    isOrgHidden: me.organizations && me.organizations.find((organization) => organization.id === organizationId)?.hidden,
    isOrgOwner,
    isLoading: loading.includes('log-out') || loading.includes('load-stripe-url'),
    workspaces,
    workspaceId,
    organizationId,
    organization,
    tasks,
    me,
    subscription: organization?.settings?.billing,
    notifications,
    newsFeed,
    employees,
    positions,
    templates
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    setSidebar,
    logout
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
