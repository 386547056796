import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestTimeOff (variables, token) {
  const query = `query TimeOffQuery (
    $id: ID!,
    $workspace: ID,
    $user: ID
  ) {
    timeOff (
      id: $id
      workspace: $workspace
      user: $user
    ) ${q.TIME_OFF}
  }`

  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { data } = response
  return data?.timeOff
}

export default requestTimeOff
