import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import miscUtil from '@app/util/misc'

const mapStateToProps = ({ calendar, workspaceId, positions, shifts, auth }) => {
  const positionsWithResource = positions ? positions.filter(p => !p.archived && p.productionResourceType) : []
  const shiftsWithResource = shifts ? shifts.filter(s => positionsWithResource.find(p => p.id === s.positionId)) : []
  return {
    positionsWithResource,
    shiftsWithResourceHash: miscUtil.safeStringify(shiftsWithResource),
    auth,
    calendar,
    workspaceId
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    // ...
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
