import { t } from 'i18next'

import { notification } from '@app/util'

import store from '../store'
import requestTemplateCreate from '../request/template-create'
import addError from '../ac/add-error'

import isLoading from './is-loading'
import loadTemplates from './load-templates'

export default (form, requirements) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !form) return

    dispatch(isLoading('save-day-as-template'))
    const reqs = []
    requirements.forEach((r) => {
      for (var i = 0; i < r.reqIds.length; i++) {
        reqs.push({
          positionId: r.positionId,
          start: r.start,
          duration: r.duration,
          idealSkill: r.idealSkill,
          localityId: r.localityId,
          pausesFixed: r.pausesFixed,
          pauses: r.pauses,
          agenda: r.agenda
        })
      }
    })

    // create the template
    const template = await requestTemplateCreate({
      workspace: workspaceId,
      name: form.name || t('DEFAULT_DAY'),
      color: form.color,
      requirements: reqs
    }, auth)
    if (template.error) {
      dispatch(addError(template.error))
      dispatch(isLoading('save-day-as-template', true))
      return
    } else {
      notification.success({ code: 'dayTemplateSaved' })
    }

    // load new templates
    await dispatch(await loadTemplates())

    dispatch(isLoading('save-day-as-template', true))
  }
}
