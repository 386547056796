import { requestCountryCreate, requestCountryDelete, requestCountryUpdate } from '@app/request'
import { setOrg } from '@app/action'
import { notification } from '@app/util'

import aT from '../const/action-type'
import {
  addError,
  setModal,
  getOrganization
} from '../ac'
import store from '../store'

import isLoading from './is-loading'

export const createCountry = (form) => {
  return async (dispatch) => {
    const { auth, organization } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = (newItem) => ({
      ...oldOrg,
      countries: [
        ...oldOrg.countries,
        newItem
      ]
    })

    dispatch(setOrg(newOrg({ id: '1', ...form })))
    dispatch(isLoading(aT.SET_WORKSPACES))
    const result = await requestCountryCreate(form, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    } else {
      dispatch(setOrg(newOrg(result.countryCreate)))
      dispatch(setModal(null))
    }
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}

export const deleteCountry = (id) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = {
      ...oldOrg,
      countries: oldOrg.countries.filter((country) => country.id !== id)
    }

    dispatch(isLoading(aT.SET_WORKSPACES))
    dispatch(setOrg(newOrg))
    const result = await requestCountryDelete({ country: id, organization: organizationId }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    } else {
      dispatch(setModal(null))
    }
    dispatch(isLoading(aT.SET_WORKSPACES, true))
  }
}

export const updateCountry = (id, data) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()
    const oldOrg = { ...organization }
    const countryIndex = oldOrg.countries.findIndex((country) => country.id === id)
    const countries = [...oldOrg.countries]
    countries[countryIndex] = {
      ...countries[countryIndex],
      ...data
    }
    const newOrg = {
      ...oldOrg,
      ...countries
    }

    await dispatch(setOrg(newOrg))
    const result = await requestCountryUpdate({
      organization: organizationId,
      country: id,
      data
    }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      await dispatch(setOrg(oldOrg))
    } else {
      notification.success({ code: 'success' })
      dispatch(getOrganization(true))
    }
  }
}
