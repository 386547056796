import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import { Icon, Dropdown, Flex } from '@ui'

export const SelectFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  configColumn: { rowToStr, rowToFilterType },
  handleOnCollapsed
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) =>
      options.add(
        rowToFilterType ? rowToFilterType(row.original) : rowToStr(row.original)
      )
    )
    return [...options.values()]
  }, [id, preFilteredRows])

  const transferForDropdown = (array) => {
    const unique = new Set()
    array && array.map((item) => {
      if (typeof item === 'string' || typeof item === 'number') {
        unique.add(item)
      } else if (Array.isArray(item)) {
        item.map((item2) => {
          ![...unique].some((key) => key.value === item2.value) &&
          unique.add(item2)
        })
      } else {
        item && ![...unique].some((key) => key.value === item.value) &&
        unique.add(item)
      }
    })
    return [...unique].map((option) => {
      if (
        typeof option === 'string' ||
        typeof option === 'number' ||
        typeof option === 'boolean' ||
        option === null
      ) {
        return ({
          id: option,
          label: option,
          value: isNaN(option) ? option : parseFloat(option)
        })
      } else {
        return ({
          id: `${option.value}`,
          searchableParam: option.searchableParam,
          label: option.label,
          value: typeof option.value === 'boolean' ? `${option.value}` : isNaN(option.value) ? option.value : parseFloat(option.value)
        })
      }
    })
  }
  const transferredOptions = transferForDropdown(options)
  return (
    <Flex direction={Flex.DIRECTION.COLUMN}>
      <Dropdown
        cb={handleOnCollapsed || undefined}
        type={Dropdown.TYPES.VARIABLE}
        style={Dropdown.STYLES.LIGHT}
        size={Dropdown.SIZES.LARGE}
        options={transferredOptions}
        value={transferredOptions.filter((option) => filterValue && filterValue.includes(option.value))}
        onChange={(items) => setFilter(items && items.length ? items.map((item) => item.value) : null)}
        placeholder={t('SEARCH')}
        icon={<Icon ico='search' size={Icon.SIZES.SMALL} />}
        searchable
      />
    </Flex>
  )
}

SelectFilter.propTypes = {
  setAllFilters: PropTypes.func.isRequired,
  filterValue: PropTypes.arrayOf(PropTypes.string),
  handleOnCollapsed: PropTypes.func
}
SelectFilter.defaultProps = {
  rowToString: () => null,
  rowToFilterType: null,
  filterValue: [],
  handleOnCollapsed: null
}
