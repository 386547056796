import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.cycleDetail, action) => {
  switch (action.type) {
    case aT.SET_CYCLE_DETAIL:
      return action.data
    case aT.RESET_STORE:
      return initial.cycleDetail
    case aT.SWITCH_WORKSPACE:
      return initial.cycleDetail
    default:
      return state
  }
}
