import axios, { CancelToken } from 'axios'

const handleError = (error) => console.log(error)

const handleResponse = (res) => {
  if (res) {
    const { data } = res
    return data
  } else {
    return null
  }
}

export const AxiosFetch = (url, params = {}) => {
  const cancelToken = params && params.abort ? new CancelToken(params.abort) : null
  const headersFromParams = params && params.headers
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(headersFromParams || {})
  }
  const requestParams = {
    withCredentials: false,
    ...params,
    cancelToken,
    url,
    headers
  }
  return axios(requestParams)
    .catch(handleError)
    .then(handleResponse)
}
