import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  loadWorkspaceDetail,
  setModal
} from '@app/ac'

const mapStateToProps = ({
  workspaceId,
  loading,
  workspaces,
  auth
}) => {
  return {
    auth,
    workspaceId,
    ws: workspaces.find(w => w.id === workspaceId),
    isLoading: loading.includes('unavailability-add') || loading.includes('load-employees')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    loadWorkspaceDetail
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
