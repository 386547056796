import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import loadEmployees from '@app/ac/load-employees'
import loadPositions from '@app/ac/load-positions'
import loadTemplates from '@app/ac/load-templates'
import setModal from '@app/ac/set-modal'
import setCalendar from '@app/ac/set-calendar'
import setCalendarMultiSelect from '@app/ac/set-calendar-multi-select'

const mapStateToProps = ({
  calendar, calendarMultiSelect, workspaceId, workspaces,
  loading, shifts, offers, me,
  positions, availabilities, timeOffs, employees,
  auth, sidebar
}) => {
  const openSidebarType = sidebar ? sidebar.type : undefined
  return {
    openSidebarType,
    calendar,
    calendarMultiSelect,
    workspaceId,
    workspaces,
    me,
    changedShiftsOnWS: calendar ? calendar.changedShiftsCount : 0,
    isLoading: loading.includes('me') || loading.includes('workspaces'),
    isLoadingEmployees: loading.includes('load-employees'),
    isLoadingPositions: loading.includes('load-positions'),
    isLoadingPublish: loading.includes('publish-shifts'),
    isLoadingShiftsOrCalendar: loading.includes('load-shifts') || loading.includes('set-calendar'),
    shifts,
    offersNum: offers.length, // don't remove - ensures needed re-render when new offers are created
    availabilities,
    timeOffs,
    employees,
    positions,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadEmployees,
    loadPositions,
    loadTemplates,
    setModal,
    setCalendarMultiSelect,
    setCalendar
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
