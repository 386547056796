import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.myRole, action) => {
  switch (action.type) {
    case aT.SET_MY_ROLE:
      return action.data
    case aT.RESET_STORE:
      return initial.myRole
    default:
      return state
  }
}
