/* eslint-disable no-undef */

// variables __DS_ENV__ and endpoints are defined in Webpack config
export const env = __DS_ENV__
export const apiEndpoints = endpoints

// languages
const supportedLanguages = [
  {
    label: 'Česky',
    value: 'cs'
  },
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Slovensky',
    value: 'sk'
  },
  {
    label: 'українська',
    value: 'uk'
  },
  {
    label: 'Hrvatska',
    value: 'hr'
  },
  {
    label: 'български',
    value: 'bg'
  },
  {
    label: 'Magyar',
    value: 'hu'
  },
  {
    label: 'Română',
    value: 'ro'
  },
  {
    label: 'Español',
    value: 'es'
  },
  {
    label: 'Deutsch',
    value: 'de'
  },
  {
    label: 'Italiano',
    value: 'it'
  },
  {
    label: 'Polski',
    value: 'pl'
  },
  {
    label: 'Português',
    value: 'pt'
  },
  {
    label: 'Français',
    value: 'fr'
  },
  {
    label: 'Русский',
    value: 'ru'
  }
]

export default {
  supportedLanguages,
  env,
  // fallback initial location for map
  defaultCoords: { lat: 50.0696529, lng: 14.4050442 },
  // in registration form
  termsConditionsLink: 'https://dayswaps.com/compliance/',
  // google maps etc.
  googleApiKey: 'AIzaSyANDo9XpA5nK8G5dBouSMHlISpThHYDLbA',

  // IP geo location
  estimateLocationUrl: 'https://www.googleapis.com/geolocation/v1/geolocate?key=',
  // reverse geocoding (country from GPS coords)
  estimateCountryUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key='
}
