import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import saveDayAsTemplate from '@app/ac/save-day-as-template'

const mapStateToProps = ({ workspaceId, shifts, loading }) => {
  return {
    workspaceId,
    shifts,
    isLoading: loading.includes('save-day-as-template')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveDayAsTemplate,
    setModal
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
