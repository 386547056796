import store from '../store'
import addError from '../ac/add-error'
import isLoading from './is-loading'
import requestActionReply from '@app/request/reply-action'

export default (opts) => {
  const {
    reply, // must be 'yes' or 'no' string
    id,
    data
  } = opts

  return async (dispatch) => {
    dispatch(isLoading('accept-or-deny-request'))
    const { auth } = store.getState()

    const result = await requestActionReply({
      actions: [id],
      reply,
      ...(data && {
        data: {
          timeOffData: { ...data }
        }
      })
    }, auth)

    if (result.error) {
      dispatch(addError(result.error))
    }

    dispatch(isLoading('accept-or-deny-request', true))
    return result
  }
}
