import { t } from 'i18next'

export const setTitleAndMetaDescription = (uri) => {
  // adjust the document title and meta description based on the subpage
  if (!uri) return
  const metaDesc = [...document.getElementsByTagName('META')].find(el => el.getAttribute('name') === 'description')

  // special case: Login subpage
  if (uri === '/login') {
    document.title = t('META_TITLE_LOGIN')
    if (metaDesc) metaDesc.setAttribute('content', t('META_DESCRIPTION_LOGIN'))
    return
  }

  // special case: Register subpage
  if (uri === '/register') {
    document.title = t('META_TITLE_REGISTER')
    if (metaDesc) metaDesc.setAttribute('content', t('META_DESCRIPTION_REGISTER'))
    return
  }

  // base case
  document.title = t('DAYSWAPS')
  if (metaDesc) metaDesc.setAttribute('content', t('META_DESCRIPTION_BASE'))
}
