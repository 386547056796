import { client } from '@app/util'

import requestLogin from '../request/login'
import setAuth from '../action/set-auth'

import isLoading from './is-loading'
import addError from './add-error'
import firstLoad from './first-load'

export default (params) => {
  return async (dispatch) => {
    const doLogin = async (token) => {
      await dispatch(setAuth(token))
      await client.resetStore()
      await dispatch(firstLoad())
    }
    dispatch(isLoading('log-in'))
    if (params.auth) {
      await doLogin(params.auth)
      dispatch(isLoading('log-in', true))
    } else {
      const result = await requestLogin(params)
      if (result.error) {
        dispatch(addError(result.error))
      } else {
        if (result.mfaEnabled && !result.token) {
          dispatch(isLoading('log-in', true))
          return { mfa: true, availableMethods: result.mfaMethods }
        }
        await doLogin(result.token)
      }
      dispatch(isLoading('log-in', true))
      return result
    }
  }
}
