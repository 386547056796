import { stringUtil } from '..'

export function translationsMapper (translations) {
  return {
    // Probably not being used right now - just fallback values
    'Allow access to camera': 'Allow access to camera',
    'Allow access to microphone': 'Allow access to microphone',
    'An error has occurred during recording': 'An error has occurred during recording',
    'An error has occurred during the recording processing':
      'An error has occurred during the recording processing',
    'Attach files': '📎 ' + translations.ATTACHMENT + '...',
    'Cannot seek in the recording': 'Cannot seek in the recording',
    'Channel Missing': translations.LOADING_DATA,
    'Close emoji picker': translations.CLOSE,
    'Commands matching': 'Commands matching',
    'Emoji matching': 'Emoji matching',
    'Error adding flag': 'Error adding flag',
    'Error fetching reactions': translations.ERROR_SCREEN_TITLE,
    'Error muting a user ...': 'Error muting a user ...',
    'Error pinning message': 'Error pinning message',
    'Error removing message pin': 'Error removing message pin',
    'Error reproducing the recording': 'Error reproducing the recording',
    'Error starting recording': 'Error starting recording',
    'Error unmuting a user ...': 'Error unmuting a user ...',
    'Failed to play the recording': 'Failed to play the recording',
    Flag: 'Flag',
    'Load more': 'Load more',
    'Mark as unread': 'Mark as unread',
    'Error marking message unread. Cannot mark unread messages older than the newest 100 channel messages.':
      translations['ERR_MESSAGE.BAD_USER_INPUT'],
    Menu: 'Menu',
    'Message has been successfully flagged': 'Message has been successfully flagged',
    'Message pinned': 'Message pinned',
    'Messages have been marked unread.': 'Messages have been marked unread.',
    Mute: 'Mute',
    'No results found': translations.TABLE_NO_DATA,
    'Open emoji picker': 'Open emoji picker',
    'People matching': 'People matching',
    Pin: 'Pin',
    'Pinned by': 'Pinned by',
    'Recording format is not supported and cannot be reproduced':
      'Recording format is not supported and cannot be reproduced',
    Search: 'Search',
    'Searching...': 'Searching...',
    'Slow Mode ON': 'Slow Mode ON',
    'This message did not meet our content guidelines':
      'This message did not meet our content guidelines',
    'To start recording, allow the camera access in your browser':
      'To start recording, allow the camera access in your browser',
    'To start recording, allow the microphone access in your browser':
      'To start recording, allow the microphone access in your browser',
    Unmute: 'Unmute',
    Unpin: 'Unpin',
    'User uploaded content': 'User uploaded content',
    'Voice message': 'Voice message',
    live: 'live',

    // Plurals
    searchResultsCount_one: '',
    searchResultsCount_other: '',
    searchResultsCount_few: '',
    searchResultsCount_many: '',

    '{{ user }} has been muted': '{{ user }} has been muted',
    '{{ user }} has been unmuted': '{{ user }} has been unmuted',

    unreadMessagesSeparatorText_one: translations.MA_UNREAD + ' (1)',
    unreadMessagesSeparatorText_few: translations.MA_UNREAD + ' ({{ count }})',
    unreadMessagesSeparatorText_other: translations.MA_UNREAD + ' ({{ count }})',

    '{{count}} unread_one': translations.MA_UNREAD + ' ({{ count }})',
    '{{count}} unread_few': translations.MA_UNREAD + ' ({{ count }})',
    '{{count}} unread_other': translations.MA_UNREAD + ' ({{ count }})',

    replyCount_one: translations.REPLIES + ' (1)',
    replyCount_few: translations.REPLIES + ' ({{ count }})',
    replyCount_other: translations.REPLIES + ' ({{ count }})',

    // ----------

    // Hopefully edge cases
    'Missing permissions to upload the attachment':
      'Missing permissions to upload the attachment',
    'Some of the files will not be accepted': 'Some of the files will not be accepted',
    'Wait until all attachments have uploaded': translations.LOADING_DATA,
    "You've reached the maximum number of files":
      "You've reached the maximum number of files",
    'this content could not be displayed': translations.ERROR_SCREEN_TITLE,
    '{{ imageCount }} more': translations.AND_X_MORE.replace('{{x}}', '{{ imageCount }}'),

    // Message status
    Delivered: translations.DELIVERED,
    'Sending...': translations.SENDING + '...',

    // Other
    Cancel: translations.CANCEL,
    Close: translations.CLOSE,
    Delete: translations.DELETE,
    Ok: translations.DONE,
    Reply: translations.REPLY_VERB,
    'Reply to Message': translations.REPLY_NOUN,
    Send: translations.SEND,
    'Send Anyway': translations.SEND,

    'Drag your files here': '',
    'Drag your files here to add to your post': '',

    Edited: translations.EDITED,
    'Edit Message': translations.EDIT,
    'Edit message request failed': translations['ERR_MESSAGE.BAD_USER_INPUT'],
    'Error deleting message': translations['ERR_MESSAGE.BAD_USER_INPUT'],

    'Empty message...': '',
    'Nothing yet...': '',

    'Connection failure, reconnecting now...': translations.LOADING_DATA,
    'Error connecting to chat, refresh the page to try again.':
      translations.ERROR_SCREEN_TITLE + '. ' + translations.ERROR_SCREEN_TITLE_RETRY,
    'Error: {{ errorMessage }}': translations.ERROR_SCREEN_TITLE + ': {{ errorMessage }}',

    'Failed to mark channel as read': translations['ERR_MESSAGE.BAD_USER_INPUT'],

    'Error uploading attachment': translations['ERR_MESSAGE.BAD_USER_INPUT'],
    'Error uploading file': translations['ERR_MESSAGE.BAD_USER_INPUT'],
    'Error uploading image': translations['ERR_MESSAGE.BAD_USER_INPUT'],

    'Error · Unsent': translations['ERR_MESSAGE.BAD_USER_INPUT'],
    'Message Failed · Click to try again': translations['ERR_MESSAGE.BAD_USER_INPUT'],
    'Message Failed · Unauthorized': translations['ERR_MESSAGE.BAD_USER_INPUT'],
    'Failed to jump to the first unread message': '',
    'Send message request failed': translations['ERR_MESSAGE.BAD_USER_INPUT'],

    'File is too large: {{ size }}, maximum upload size is {{ limit }}':
      translations.MAXIMUM_FILE_SIZE_WARNING.replace('(', '')
        .replace(' MB)', '')
        .replace('MAX_FILE_SIZE_TO_UPLOAD_IN_MB', 'limit'),

    'Latest Messages': translations.NEW_MESSAGES + '!',
    'New Messages!': translations.NEW_MESSAGES + '!',

    'Message deleted': translations.MESSAGE_DELETED,
    'This message was deleted...': translations.MESSAGE_DELETED,
    New: translations.NEW,
    'No chats here yet…': '',
    'You have no channels currently': translations.TABLE_NO_DATA,
    Shuffle: translations.EMPLOYEE_PROFILE_CARD_OTHER,
    Thread: translations.THREAD,
    'Type your message': translations.NEWS_FEED_NEW_MESSAGE,
    'Unread messages': translations.MA_UNREAD,
    'Upload type: "{{ type }}" is not allowed': stringUtil.getCapitalizeWord(
      translations.NF_FILE_NOT_SUPPORTED
    ),
    '🏙 Attachment...': '📎 ' + translations.ATTACHMENT + '...',

    '{{ user }} is typing...': translations.USER_IS_TYPING,
    '{{ users }} and more are typing...': '{{ users }}',
    '{{ users }} and {{ user }} are typing...': '{{ users }}, {{ user }}',

    '{{ memberCount }} members': translations.USERS + ' {{memberCount}}',
    '{{ watcherCount }} online': translations.ONLINE + ' {{watcherCount}}',

    // message readby
    '{{ commaSeparatedUsers }} and {{ moreCount }} more':
      '{{ commaSeparatedUsers }} ' +
      translations.AND_X_MORE.replace('{{x}}', '{{ moreCount }}'),
    '{{ firstUser }} and {{ secondUser }}': '{{ firstUser }}, {{ secondUser }}',
    '{{ commaSeparatedUsers }}, and {{ lastUser }}':
      '{{ commaSeparatedUsers }}, {{ lastUser }}',

    // Aria
    'aria/Attachment': translations.ATTACHMENT,
    'aria/Cancel Reply': 'Cancel Reply',
    'aria/Cancel upload': 'Cancel upload',
    'aria/Channel list': 'Channel list',
    'aria/Channel search results': 'Channel search results',
    'aria/Close thread': 'Close thread',
    'aria/Emoji picker': 'Emoji picker',
    'aria/File input': 'File input',
    'aria/File upload': 'File upload',
    'aria/Image input': 'Image input',
    'aria/Load More Channels': 'Load More Channels',
    'aria/Menu': 'Menu',
    'aria/Message Options': 'Message Options',
    'aria/Open Message Actions Menu': 'Open Message Actions Menu',
    'aria/Open Reaction Selector': 'Open Reaction Selector',
    'aria/Open Thread': 'Open Thread',
    'aria/Reaction list': 'Reaction list',
    'aria/Retry upload': 'Retry upload',
    'aria/Send': translations.SEND,
    'aria/Download attachment': 'aria/Download attachment',
    'aria/Remove attachment': 'Remove attachment',

    //
    //
    // NEW => version 12
    'Download attachment {{ name }}': translations.ATTACHMENT + ' {{ name }}',
    'Unsupported attachment': stringUtil.getCapitalizeWord(translations.NF_FILE_NOT_SUPPORTED),
    'timestamp/DateSeparator': '{{ timestamp | timestampFormatter(calendar: true) }}',
    'timestamp/MessageTimestamp': '{{ timestamp | timestampFormatter(calendar: true) }}',
    'timestamp/SystemMessage': '{{ timestamp | timestampFormatter(format: dddd L) }}'
  }
}
