import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation WorkspaceEventDeleteMutation (
    $workspaceId: ID!
    $id: ID!
  ) {
  eventDelete (
    workspace: $workspaceId
    id: $id
  )
}`

async function requestWorkspaceEventDelete (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('workspace-event-delete', errors)
  }
  return data.eventDelete
}

export default requestWorkspaceEventDelete
