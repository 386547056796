/* eslint-disable react/jsx-fragments */
import React from 'react'
import { t } from 'i18next'
import {
  Icon,
  Button,
  Position,
  Dropdown,
  Locality,
  Text,
  Flex,
  Spacing
} from '@ui'
import { NO_LOCALITY, NO_POSITION } from '@app/const/globals'
import { sortUtil, miscUtil } from '@app/util'

import './index.scss'
import connect from './connect'
import moment from 'moment'

class Filters extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      calendarFilters,
      setCalendarFilters,
      employees,
      positions,
      isPluginEnabled,
      workspace,
      toggleFiltersActive,
      userId,
      setEnforcedLocality,
      workspaceSwitch
    } = this.props
    const positionsForFilters = positions ? positions.filter(pos => !pos.archived) : []

    // if this workspace has enforcedLocalities, we will display locality filter as a *singleSelect* dropdown. they must choose one.
    const myLocalities = workspace?.localities?.filter(l => l?.assigns.some(ass => ass.userId === userId)) || []
    const localitiesEnforcedOnWS = Boolean(workspace?.enforceLocalities)
    const enforcedLocality = miscUtil.getEnforcedLocality(workspace)

    // people with positions
    const optsPeopleWithPos = positionsForFilters.map(p => {
      return {
        value: p.id,
        label: <Position {...p} />,
        filterObject: { peopleWithPositionId: p.id },
        invertFilterValue: true // if this is true, the filter logic is inverted - when the option is checked, it is DISPLAYED in calendar, and when it's unchecked, it is FILTERED OUT
      }
    })
    const isCalFilterRelevantToPeopleWithPos = (f) => { return f.peopleWithPositionId && f.peopleWithPositionId !== NO_POSITION }

    // shifts with positions
    const optsShiftsWithPos = positionsForFilters.map(p => {
      return {
        value: p.id,
        label: <Position {...p} />,
        filterObject: { data: { positionId: p.id } },
        invertFilterValue: true // if this is true, the filter logic is inverted - when the option is checked, it is DISPLAYED in calendar, and when it's unchecked, it is FILTERED OUT
      }
    })
    const isCalFilterRelevantToShiftsWithPos = (fil) => {
      return (fil.data && fil.data.positionId)
    }

    // localitites
    const optsLocalitiesHidden = []
    const optsLocalities = workspace.localities
      .map(loc => {
        return {
          value: loc.id,
          label: <Locality {...loc} />,
          filterObject: { localityId: loc.id },
          invertFilterValue: true // if this is true, the filter logic is inverted - when the option is checked, it is DISPLAYED in calendar, and when it's unchecked, it is FILTERED OUT
        }
      })
      .filter(loc => {
        // if this WS has enforcedLocalities, we filter out some locality options
        if (workspace?.enforceLocalities) {
          if (myLocalities.length === 0) {
            // if we have 0 localities, we treat it as if we had all of them and leave them all in
            return true
          } else {
            // if we have more than 0 localities, we leave the ones that we have
            const ret = (myLocalities.some(ml => ml.id === loc.value))
            if (!ret) optsLocalitiesHidden.push(Object.assign({}, loc))
            return ret
          }
        }
        return true
      })
      .concat(workspace?.enforceLocalities
        // if this ws has enforcedLocalities, we add the NO_LOCALITY option
        ? [{
          value: NO_LOCALITY,
          label: <Locality />,
          filterObject: { localityId: NO_LOCALITY }, // not used, since WS has enforcedLocalities
          invertFilterValue: true // not used, since WS has enforcedLocalities
        }]
        : [])

    const isCalFilterRelevantToLocalities = (fil) => { return fil.localityId && fil.localityId !== NO_LOCALITY }

    // employees
    const optsEmployees = sortUtil.sortEmployees(Object.values(employees).filter(ee => !ee.external)).map(ee => {
      return {
        value: ee.id,
        label: ee.name,
        filterObject: { userId: ee.id },
        invertFilterValue: true // if this is true, the filter logic is inverted - when the option is checked, it is DISPLAYED in calendar, and when it's unchecked, it is FILTERED OUT
      }
    })
    const isCalFilterRelevantToEmployees = (fil) => { return fil.userId }

    // special HIDE filters
    const optsSpecialHideFilters = [
      {
        value: 'absencesHidden',
        label: t('CAL_FILTERS_ABSENCES'),
        filterObject: { type: 'timeOff' }
      },
      {
        value: NO_POSITION,
        label: t('CAL_FILTERS_POSITIONS_HIDE_NO_POS'),
        filterObject: { peopleWithPositionId: NO_POSITION }
      },
      {
        value: NO_LOCALITY,
        label: t('CAL_FILTERS_LOCALITIES_HIDE_NO_LOC'),
        filterObject: { localityId: NO_LOCALITY }
      },
      {
        value: 'withoutContractHidden',
        label: t('CAL_FILTERS_WITHOUT_CONT'),
        filterObject: { labelData: { userHasContractsInCalendarPeriod: false } }
      },
      {
        value: 'withoutShiftsHidden',
        label: t('CAL_FILTERS_WITHOUT_SHIFTS'),
        filterObject: { labelData: { userShiftsCount: 0 } }
      },
      {
        value: 'adultsHidden',
        label: t('CAL_FILTERS_ADULTS'),
        filterObject: { labelData: { user: { adult: true } } }
      },
      {
        value: 'underageHidden',
        label: t('CAL_FILTERS_UNDERAGE'),
        filterObject: { labelData: { user: { adult: false } } }
      },
      {
        value: 'rowsWithoutEventsHidden',
        label: t('CAL_FILTERS_POSITIONS_HIDE_EMPS_WITHOUT'),
        filterObject: { labelData: { userEventsCount: 0 } }
      },
      {
        value: 'externalHidden',
        label: t('EXTERNAL_EMPS_HIDE'),
        filterObject: { labelData: { user: { external: true } } }
      }
    ]
    const isCalFilterRelevantToHideFilters = (fil) => {
      return !(
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === 'absencesHidden') ? optsSpecialHideFilters.find(opt => opt.value === 'absencesHidden').filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === 'underageHidden') ? optsSpecialHideFilters.find(opt => opt.value === 'underageHidden').filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === 'adultsHidden') ? optsSpecialHideFilters.find(opt => opt.value === 'adultsHidden').filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === 'externalHidden') ? optsSpecialHideFilters.find(opt => opt.value === 'externalHidden').filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === 'withoutShiftsHidden') ? optsSpecialHideFilters.find(opt => opt.value === 'withoutShiftsHidden').filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === 'withoutContractHidden') ? optsSpecialHideFilters.find(opt => opt.value === 'withoutContractHidden').filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === NO_LOCALITY) ? optsSpecialHideFilters.find(opt => opt.value === NO_LOCALITY).filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === NO_POSITION) ? optsSpecialHideFilters.find(opt => opt.value === NO_POSITION).filterObject : null)) &&
        (miscUtil.safeStringify(fil) !== miscUtil.safeStringify(optsSpecialHideFilters.some(opt => opt.value === 'rowsWithoutEventsHidden') ? optsSpecialHideFilters.find(opt => opt.value === 'rowsWithoutEventsHidden').filterObject : null))
      )
    }

    const showFilterDate = () => {
      const { view, date } = this.props.calendar
      if (view === 'month') return t('MONTH_' + moment(date).format('M')) + moment(date).format(' YYYY')
      if (view === 'day') return moment(date).format('D. MMMM')
      if (view === 'week') return `${moment(date).startOf('week').format('D.')} - ${moment(date).endOf('week').format('D. MMMM')}`
    }

    return (
      <>
        <div className='ds-display-filters-title'>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_28}>
            <Flex>
              <Icon ico='filters' />
              <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_6} />
              <Flex direction={Flex.DIRECTION.COLUMN}>
                <Text
                  weight={Text.WEIGHTS.BOLD}
                  type={Text.TYPES.BODY_LARGE}
                  text={t('CAL_FILTERS_TITLE')}
                  nowrap
                />
                <Text
                  weight={Text.WEIGHTS.BOLD}
                  type={Text.TYPES.BODY_LARGE}
                  nowrap
                >
                  {showFilterDate()}
                </Text>
              </Flex>
            </Flex>
          </Spacing>
        </div>

        <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_28} />

        {/* filters: People with positions */}
        {positionsForFilters && positionsForFilters.length ? (
          <>
            <Dropdown
              label={t('CAL_FILTERS_POSITIONS')}
              searchable
              sortedOptions
              size={Dropdown.SIZES.LARGE}
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.OUTLINED}
              extraDropdownContentPosition={Dropdown.EXTRA_POSITION.TOP}
              options={optsPeopleWithPos}
              value={optsPeopleWithPos.filter(opt => calendarFilters.some(fil => fil.peopleWithPositionId === opt.value) !== Boolean(opt.invertFilterValue))}
              placeholder={t('SELECT')}
              onChange={(valsPar) => {
                const vals = valsPar.filter(v => !v.invertFilterValue).concat(optsPeopleWithPos.filter(opt => opt.invertFilterValue && !valsPar.some(vp => vp.value === opt.value))) // invert the values of options with 'invertFilterValue: true'
                setCalendarFilters(
                  calendarFilters.filter(f => {
                    if (!isCalFilterRelevantToPeopleWithPos(f)) return true // irrelevant calendar filters are kept
                    return vals.some(v => f.peopleWithPositionId === v.value) // filtering out the relevant 'calendarFilters' that are not in 'vals'
                  }).concat(
                    vals.filter(v => !calendarFilters.some(f => f.peopleWithPositionId === v.value)).map(v => v.filterObject) // adding new 'calendarFilters' that are in 'vals'
                  )
                )
              }}
            />
            <Spacing size={Spacing.SIZES.SIZE_28} type={Spacing.TYPES.HORIZONTAL} />
          </>
        ) : null}

        {/* filters: Shifts with positions */}
        {positionsForFilters && positionsForFilters.length ? (
          <>
            <Dropdown
              label={t('CAL_FILTERS_POSITIONS_SHIFTS')}
              searchable
              sortedOptions
              size={Dropdown.SIZES.LARGE}
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.OUTLINED}
              extraDropdownContentPosition={Dropdown.EXTRA_POSITION.TOP}
              options={optsShiftsWithPos}
              value={optsShiftsWithPos.filter(opt => calendarFilters.some(fil => (fil.data && fil.data.positionId === opt.value)) !== Boolean(opt.invertFilterValue))}
              placeholder={t('SELECT')}
              onChange={(valsPar) => {
                const vals = valsPar.filter(v => !v.invertFilterValue).concat(optsShiftsWithPos.filter(opt => opt.invertFilterValue && !valsPar.some(vp => vp.value === opt.value))) // invert the values of options with 'invertFilterValue: true'
                setCalendarFilters(
                  calendarFilters.filter(fil => { // irrelevant calendar filters are kept
                    if (!isCalFilterRelevantToShiftsWithPos(fil)) return true // irrelevant calendar filters are kept
                    return vals.some(v => // filtering out the relevant 'calendarFilters' that are not in 'vals'
                      (fil.data && fil.data.positionId === v.value))
                  }).concat(
                    vals.filter(v => !calendarFilters.some(fil =>
                      (fil.data && fil.data.positionId === v.value)
                    )).map(v => v.filterObject) // adding new 'calendarFilters' that are in 'vals'
                  )
                )
              }}
            />
            <Spacing size={Spacing.SIZES.SIZE_28} type={Spacing.TYPES.HORIZONTAL} />
          </>
        ) : null}

        {/* filters: Localities */}
        {(isPluginEnabled('localities') || calendarFilters.some(f => isCalFilterRelevantToLocalities(f))) && (
          <>
            {workspace && workspace.localities && workspace.localities.length ? (
              <>
                <Dropdown
                  label={t('CAL_FILTERS_LOCALITIES')}
                  searchable={false}
                  sortedOptions
                  singleSelect={localitiesEnforcedOnWS ? true : undefined}
                  size={Dropdown.SIZES.LARGE}
                  type={Dropdown.TYPES.VARIABLE}
                  style={Dropdown.STYLES.OUTLINED}
                  extraDropdownContentPosition={Dropdown.EXTRA_POSITION.TOP}
                  options={optsLocalities}
                  value={(localitiesEnforcedOnWS && enforcedLocality)
                    ? optsLocalities.filter(opt => opt.value === enforcedLocality)
                    : optsLocalities.filter(opt => calendarFilters.some(fil => fil.localityId === opt.value) !== Boolean(opt.invertFilterValue))}
                  placeholder={t('SELECT')}
                  onChange={async (vp) => {
                    const valsPar = (localitiesEnforcedOnWS ? [vp] : vp)
                    const vals = valsPar.filter(v => !v.invertFilterValue).concat(optsLocalities.filter(opt => opt.invertFilterValue && !valsPar.some(vp => vp.value === opt.value))) // invert the values of options with 'invertFilterValue: true'
                    if (localitiesEnforcedOnWS) {
                      await setEnforcedLocality(vp.value)
                      workspaceSwitch(workspace)
                    } else {
                      setCalendarFilters(
                        calendarFilters.filter(f => {
                          if (!isCalFilterRelevantToLocalities(f)) return true // irrelevant calendar filters are kept
                          return vals.some(v => f.localityId === v.value) // filtering out the relevant 'calendarFilters' that are not in 'vals'
                        }).concat(
                          vals.filter(v => !calendarFilters.some(f => f.localityId === v.value)).map(v => v.filterObject) // adding new 'calendarFilters' that are in 'vals'
                        ).concat(
                          optsLocalitiesHidden.map(v => v.filterObject) // and adding whatever WS localities that were just hidden from the Dropdown, because we don't have them assigned
                        )
                      )
                    }
                  }}
                />
                <Spacing size={Spacing.SIZES.SIZE_28} type={Spacing.TYPES.HORIZONTAL} />
              </>
            ) : null}
          </>
        )}

        {/* filters: Employees */}
        <Dropdown
          label={t('EMPLOYEES')}
          searchable
          sortedOptions={false}
          size={Dropdown.SIZES.LARGE}
          type={Dropdown.TYPES.VARIABLE}
          style={Dropdown.STYLES.OUTLINED}
          extraDropdownContentPosition={Dropdown.EXTRA_POSITION.TOP}
          options={optsEmployees}
          value={optsEmployees.filter(opt => calendarFilters.some(fil => (fil.userId === opt.value)) !== Boolean(opt.invertFilterValue))}
          placeholder={t('SELECT')}
          onChange={(valsPar) => {
            const vals = valsPar.filter(v => !v.invertFilterValue).concat(optsEmployees.filter(opt => opt.invertFilterValue && !valsPar.some(vp => vp.value === opt.value))) // invert the values of options with 'invertFilterValue: true'
            setCalendarFilters(
              calendarFilters.filter(fil => { // irrelevant calendar filters are kept
                if (!isCalFilterRelevantToEmployees(fil)) return true
                return vals.some(v => // filtering out the relevant 'calendarFilters' that are not in 'vals'
                  (v.value === fil.userId)
                )
              }).concat(
                vals.filter(v => !calendarFilters.some(fil => // adding new 'calendarFilters' that are in 'vals'
                  (fil.userId === v.value)
                )).map(v => v.filterObject)
              )
            )
          }}
        />
        <Spacing size={Spacing.SIZES.SIZE_28} type={Spacing.TYPES.HORIZONTAL} />

        {/* filters: Special HIDE filters */}
        <Dropdown
          label={t('CAL_FILTERS_HIDE')}
          searchable
          sortedOptions={false}
          size={Dropdown.SIZES.LARGE}
          type={Dropdown.TYPES.VARIABLE}
          style={Dropdown.STYLES.OUTLINED}
          extraDropdownContentPosition={Dropdown.EXTRA_POSITION.TOP}
          options={optsSpecialHideFilters}
          value={optsSpecialHideFilters.filter(opt => calendarFilters.some(fil =>
            (opt.value === 'absencesHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'absencesHidden').filterObject)) ||
            (opt.value === 'underageHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'underageHidden').filterObject)) ||
            (opt.value === 'adultsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'adultsHidden').filterObject)) ||
            (opt.value === 'externalHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'externalHidden').filterObject)) ||
            (opt.value === 'withoutShiftsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'withoutShiftsHidden').filterObject)) ||
            (opt.value === 'withoutContractHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'withoutContractHidden').filterObject)) ||
            (opt.value === NO_LOCALITY && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === NO_LOCALITY).filterObject)) ||
            (opt.value === NO_POSITION && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === NO_POSITION).filterObject)) ||
            (opt.value === 'rowsWithoutEventsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'rowsWithoutEventsHidden').filterObject))
          ) !== Boolean(opt.invertFilterValue))}
          placeholder={t('SELECT')}
          onChange={(valsPar) => {
            const vals = valsPar.filter(v => !v.invertFilterValue).concat(optsSpecialHideFilters.filter(opt => opt.invertFilterValue && !valsPar.some(vp => vp.value === opt.value))) // invert the values of options with 'invertFilterValue: true'
            setCalendarFilters(
              calendarFilters.filter(fil => { // irrelevant calendar filters are kept
                if (!isCalFilterRelevantToHideFilters(fil)) return true
                return vals.some(v => // filtering out the relevant 'calendarFilters' that are not in 'vals'
                  (v.value === 'absencesHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'absencesHidden').filterObject)) ||
                  (v.value === 'underageHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'underageHidden').filterObject)) ||
                  (v.value === 'adultsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'adultsHidden').filterObject)) ||
                  (v.value === 'externalHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'externalHidden').filterObject)) ||
                  (v.value === 'withoutShiftsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'withoutShiftsHidden').filterObject)) ||
                  (v.value === 'withoutContractHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'withoutContractHidden').filterObject)) ||
                  (v.value === NO_LOCALITY && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === NO_LOCALITY).filterObject)) ||
                  (v.value === NO_POSITION && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === NO_POSITION).filterObject)) ||
                  (v.value === 'rowsWithoutEventsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'rowsWithoutEventsHidden').filterObject))
                )
              }).concat(
                vals.filter(v => !calendarFilters.some(fil => // adding new 'calendarFilters' that are in 'vals'
                  (v.value === 'absencesHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'absencesHidden').filterObject)) ||
                  (v.value === 'underageHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'underageHidden').filterObject)) ||
                  (v.value === 'adultsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'adultsHidden').filterObject)) ||
                  (v.value === 'externalHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'externalHidden').filterObject)) ||
                  (v.value === 'withoutShiftsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'withoutShiftsHidden').filterObject)) ||
                  (v.value === 'withoutContractHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'withoutContractHidden').filterObject)) ||
                  (v.value === NO_LOCALITY && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === NO_LOCALITY).filterObject)) ||
                  (v.value === NO_POSITION && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === NO_POSITION).filterObject)) ||
                  (v.value === 'rowsWithoutEventsHidden' && miscUtil.safeStringify(fil) === miscUtil.safeStringify(optsSpecialHideFilters.find(opt => opt.value === 'rowsWithoutEventsHidden').filterObject))
                )).map(v => v.filterObject)
              )
            )
          }}
        />

        {/* ================================================== */}

        {calendarFilters.length
          ? (
            <>
              <div
                className='ds-link'
                onClick={() => {
                  setCalendarFilters([])
                }}
              >
                <Text type={Text.TYPES.BODY_LARGE}>
                  {t('CAL_FILTERS_DISABLE_ALL')} ({calendarFilters.length})
                </Text>
              </div>
              <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_28} />
              <Button
                label={t('CLOSE')}
                onClick={toggleFiltersActive}
                ico={Icon.ICONS.close1}
              />
            </>
          )
          : (
            <Flex justify={Flex.POSITION.END} grow={1}>
              <Button
                label={t('CLOSE')}
                onClick={toggleFiltersActive}
                ico={Icon.ICONS.close1}
              />
            </Flex>
          )}

        <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_28} />
      </>
    )
  }
}

export default connect(Filters)
