import fetchRequest from '../../util/fetch'
import errUtil from '../../util/error'
import q from '../query'

const query = `query OrganizationWarnings(
  $period: PeriodInput!, 
  $filter: OrganizationFilter!, 
  $organizationId: ID!
) {
organization(id: $organizationId) {
  employeeWarnings(period: $period, filter: $filter) {
    userId
    userName
    workspaceId
    warnings ${q.EMPLOYEE_WARNING}
  }
}
}`

async function requestOrgEmployeeWarnings (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('warnings', errors)
  }
  return data.organization.employeeWarnings
}

export default requestOrgEmployeeWarnings
