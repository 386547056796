import store from '../store'
import isLoading from './is-loading'
import requestTemplateApplyLive from '../request/template-apply-live'
import addError from '../ac/add-error'

export default (templateId, day) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !day) return

    await dispatch(isLoading('apply-template-live'))

    // apply the template
    const result = await requestTemplateApplyLive({
      workspace: workspaceId,
      template: templateId,
      targetDates: [day]
    }, auth)

    if (result && result.error) {
      await dispatch(addError(result.error))
    }

    await dispatch(isLoading('apply-template-live', true))
  }
}
