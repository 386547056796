"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentContractLabel = void 0;
const util_1 = require("@app/util");
const format_hours_1 = require("@app/util/format-hours");
const get_first_or_undefined_1 = require("./get-first-or-undefined");
const currentContractLabel = (_name, _type, _minutesOfWorkPerMonth) => {
    const label = (0, get_first_or_undefined_1.getFirstOrUndefined)([_name, _type]);
    if ((0, util_1.isUndefined)(label))
        return null;
    if ((0, util_1.isUndefined)(_minutesOfWorkPerMonth)) {
        return label;
    }
    return `${label} (${(0, format_hours_1.formatHours)(_minutesOfWorkPerMonth / 60)})`;
};
exports.currentContractLabel = currentContractLabel;
