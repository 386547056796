import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const mapStateToProps = ({ calendar }) => {
  return {
    calendar
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    // ...
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
