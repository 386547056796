import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withDoubleClick } from '@core/hoc/withDoubleClick'
import editTemplateShift from '@app/ac/edit-template-shift'
import setModal from '@app/ac/set-modal'
import createTemplateShift from '@app/ac/create-template-shift'
import deleteTemplateShift from '@app/ac/delete-template-shift'

const mapStateToProps = ({ loading, positions, workspaces, workspaceId }) => {
  const ws = workspaces.find(ws => ws.id === workspaceId)
  return {
    positions,
    workspace: ws,
    isLoading: loading.includes('load-templates'),
    isCreatingShifts: loading.includes('create-template-shift'),
    isDeletingShifts: loading.includes('delete-shift')

  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    editTemplateShift,
    setModal,
    createTemplateShift,
    deleteTemplateShift
  }, dispatch)
}

export default (component) => {
  return compose(
    withDoubleClick,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
