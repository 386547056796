import React from 'react'
import { t } from 'i18next'
import { RadioGroup, Radio } from 'react-radio-group'
import moment from 'moment'

import { Text, Spacing } from '@ui'
import { requestShiftUpdateMulti, requestShiftDeleteMulti } from '@app/request'
import requestPlanCreate from '@app/request/plan-create'
import requestOffersCancel from '@app/request/offers-cancel'

import connect from './connect'

class ActionsOnShifts extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      actionOnShifts: this.props.value || 'leave'
    }
    this.actCancelOffers = this._actCancelOffers.bind(this)
    this.actRestartOffers = this._actRestartOffers.bind(this)
    this.actDelete = this._actDelete.bind(this)
    this.actUnassign = this._actUnassign.bind(this)
    this.actAutoplan = this._actAutoplan.bind(this)
  }

  async _actCancelOffers () {
    const { workspaceId, auth, shifts } = this.props
    await requestOffersCancel({
      workspace: workspaceId,
      offers: shifts.filter(s => s.hasOffer && s.hasOffer.status && s.hasOffer.status !== 'resolved').map(s => s.hasOffer.id)
    }, auth)
  }

  async _actRestartOffers () {
    const { shifts, setModal, shiftsActionFnRef } = this.props
    shiftsActionFnRef.current = null
    await this.actCancelOffers()
    setTimeout(() => {
      setModal('offers', {
        selectedShiftIds: shifts.map(s => s.id),
        action: 'create',
        createType: 'drop'
      })
    }, 1500)
  }

  async _actDelete () {
    const { workspaceId, auth, shifts, shiftsActionFnRef } = this.props
    shiftsActionFnRef.current = null

    if (shifts.find(s => s.hasOffer && s.hasOffer.status && s.hasOffer.status !== 'resolved')) await this.actCancelOffers()

    await requestShiftDeleteMulti({
      workspace: workspaceId,
      shifts: shifts.map(s => s.id)
    }, auth)
  }

  async _actUnassign () {
    const { workspaceId, auth, shifts, shiftsActionFnRef } = this.props
    shiftsActionFnRef.current = null

    if (shifts.find(s => s.hasOffer && s.hasOffer.status && s.hasOffer.status !== 'resolved')) await this.actCancelOffers()

    await requestShiftUpdateMulti({
      workspace: workspaceId,
      shifts: shifts.map(s => s.id),
      data: {
        user: null
      }
    }, auth)
  }

  async _actAutoplan () {
    const { workspaceId, auth, shifts, shiftsActionFnRef } = this.props
    shiftsActionFnRef.current = null

    await this.actUnassign()

    let earliest = null
    let latest = null
    shifts.forEach(s => {
      if (!earliest || moment(earliest).isAfter(s.period.start)) earliest = moment(s.period.start)
      if (!latest || moment(earliest).isBefore(s.period.end)) latest = moment(s.period.end)
    })
    await requestPlanCreate({
      workspace: workspaceId,
      data: {
        selectedShifts: shifts.map(s => s.id),
        period: {
          start: earliest,
          end: latest
        }
      }
    }, auth)
  }

  componentWillunmount () {
    const { shiftsActionFnRef } = this.props
    shiftsActionFnRef.current = null
  }

  render () {
    const { labelTxt, shifts, shiftsActionFnRef } = this.props

    const opts = [
      { value: 'leave', label: t('SHIFTS_ACTION_LEAVE') },
      { value: 'delete', label: t('DELETE') },
      { value: 'unassign', label: t('SHIFTS_ACTION_UNASSIGN') },
      { value: 'autoplan', label: t('SHIFTS_ACTION_AUTOPLAN') },
      (!shifts.find(s => !s.hasOffer || s.hasOffer.status === 'resolved') ? { value: 'restartOffers', label: t('SHIFTS_ACTION_RESTART_OFFERS') } : null)
    ].filter(Boolean)

    const optChange = (val) => {
      this.setState(s => Object.assign({}, s, { actionOnShifts: val }))
      let actionFunction = () => {}
      if (val === 'delete') actionFunction = this.actDelete
      if (val === 'unassign') actionFunction = this.actUnassign
      if (val === 'autoplan') actionFunction = this.actAutoplan
      if (val === 'restartOffers') actionFunction = this.actRestartOffers

      // save the correct selected function in this.props.shiftsActionFnRef.current - it will be called from the parent
      shiftsActionFnRef.current = actionFunction
    }

    return (
      <>
        <Spacing size={Spacing.SIZES.SIZE_8}>
          <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>
            {labelTxt}
          </Text>
        </Spacing>

        <RadioGroup
          selectedValue={this.state.actionOnShifts}
          onChange={optChange}
        >
          {opts.map((opt, i) => {
            return (
              <label
                key={i}
              >
                <Radio value={opt.value} />
                {opt.label}
              </label>
            )
          })}
        </RadioGroup>

      </>
    )
  }
}

export default connect(ActionsOnShifts)
