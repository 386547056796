import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (state = initial.sidebar, action) => {
  switch (action.type) {
    case aT.SET_SIDEBAR:
      return action.data
    case aT.RESET_STORE:
      return initial.sidebar
    case aT.SWITCH_WORKSPACE:
      return initial.sidebar
    default:
      return state
  }
}
