import { Modal } from '@ui'
import { PositionEditor } from '@app/component/core'

export function PositionEditorModal (props) {
  return (
    <Modal close={props.close} size={Modal.SIZES.M} displayCloseButton={false}>
      <PositionEditor
        afterSubmit={props.afterSubmit}
        positionId={props.positionId}
        onClose={() => props.close()}
      />
    </Modal>
  )
}
