import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  setModal,
  getOrganization
} from '@app/ac'

const mapStateToProps = ({
  organizationId,
  organization,
  auth
}) => {
  return {
    organizationId,
    countries: organization.countries || [],
    organization,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    getOrganization
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
