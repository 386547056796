export default {
  // default
  ERR_UNKNOWN: 'unknown-error',
  // props.errors
  API_ERR_NO_RESPONSE: 'no-response',
  API_ERR_INVALID_TOKEN: 'invalid-token',
  API_ERR_BAD_LOGIN: 'bad-login',
  API_ERR_ALREADY_REQUESTED: 'already-requested',
  API_ERR_ALREADY_MEMBER: 'already-member',
  API_ERR_USER_NOT_FOUND: 'user-not-found',
  API_ERR_BAD_PHONE: 'bad-phone',
  API_ERR_ILLEGAL_PERIOD_DURATION: 'illegal-period-duration',
  API_ERR_INVALID_ACTIVATE: 'invalid-activate',
  API_ERR_USER_EXISTS: 'user-exists',
  API_ERR_ALREADY_ANSWERED: 'already-answered',
  API_ERR_WRONG_OLD_PASSWORD: 'wrong-old-password',
  API_ERR_CANT_DELETE_STANDBY: 'cannot-delete-standby-activities',
  // state.errors
  FORM_ERR_MISSING_REQUIRED: 'missing-required',
  FORM_ERR_MISSING_REQUIRED_POSITION: 'missing-position',
  FORM_ERR_MISSING_PASSWORD: 'missing-password',
  FORM_ERR_SHORT_PASSWORD: 'short-password',
  FORM_ERR_MISMATCHED_PASSWORD: 'mismatched-password',
  FORM_ERR_INVALID_EMAIL: 'invalid-email',
  FORM_ERR_BAD_PHONE: 'bad-phone',
  FORM_ERR_UNAVAILABLE_EMAIL: 'email-unavailable',
  FORM_ERR_NOT_EMAIL_OR_NAME: 'not-email-or-name',
  FORM_ERR_INVALID_NAME: 'invalid-name',
  FORM_ERR_ALREADY_MEMBER: 'already-member',
  FORM_ERR_SAME_NAME_DUMMY: 'same-name-dummy',
  FORM_ERR_LAST_MANAGER: 'last-manager',
  FORM_ERR_MISSING_NOTIFICATION_CHANNEL: 'missing-notification-channel',
  FORM_ERR_MUST_AGREE_TERMS: 'must-check-agree'
}
