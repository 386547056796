import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query Action($actionId: ID!) {
  action(id: $actionId) {
    data
    id
  }
}`

async function requestActionReply (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('action-reply-multi', errors)
  }
  return data.action
}

export default requestActionReply
