import React from 'react'
import moment from 'moment'
import { t } from 'i18next'
import {
  flip
} from '@floating-ui/react-dom'

import {
  Tooltip,
  Textarea,
  Spacing,
  Separator,
  CircledNumber,
  Flex,
  Text,
  Link
} from '@ui'
import { calendarUtil, miscUtil, permissionsUtil } from '@app/util'
import connect from './connect'
import './index.scss'
import classNames from 'classnames'
import { PERMISSIONS } from '@app/const'

class HeaderCol extends React.Component {
  constructor (props) {
    super(props)
    this.resetState = this._resetState.bind(this)
    this.state = {
      dayNote: null
    }
  }

  _resetState () {
    this.setState((s) => Object.assign({}, { dayNote: undefined }))
  }

  shouldComponentUpdate (nextProps, nextState) {
    // update if state changes
    if (miscUtil.safeStringify(nextState) !== miscUtil.safeStringify(this.state)) {
      return true
    }
    if (miscUtil.safeStringify(this.props.customCalendar && (this.props.cols || '')) !== miscUtil.safeStringify(nextProps.customCalendar && (nextProps.cols || ''))) {
      return true
    }
    // update if anything relevant in calendar changes
    if ((this.props.calendar ? this.props.calendar.date : '') !== (nextProps.calendar ? nextProps.calendar.date : '')) {
      this.resetState()
      return true
    }
    if (miscUtil.safeStringify(this.props.col ? this.props.col : '') !== miscUtil.safeStringify(nextProps.col ? nextProps.col : '')) {
      return true
    }
    if ((this.props.calendar ? this.props.calendar.view : '') !== (nextProps.calendar ? nextProps.calendar.view : '')) {
      this.resetState()
      return true
    }
    if (miscUtil.safeStringify(this.props.calendar ? this.props.calendar.loadedPeriods : '') !== miscUtil.safeStringify(nextProps.calendar ? nextProps.calendar.loadedPeriods : '')) {
      return true
    }

    // update if workspace events change
    if (miscUtil.safeStringify(this.props.workspaceEvents) !== miscUtil.safeStringify(nextProps.workspaceEvents)) {
      return true
    }

    // update if workspace warnings filter change
    if (miscUtil.safeStringify(this.props.warnings) !== miscUtil.safeStringify(nextProps.warnings)) {
      return true
    }

    // update if holidays change
    if (miscUtil.safeStringify(this.props.holidays) !== miscUtil.safeStringify(nextProps.holidays)) {
      return true
    }
    return false
  }

  render () {
    const {
      col,
      cols,
      withoutTooltip,
      customChildren,
      labelWidthRem,
      setModal,
      setCalendar,
      clearLiveDay,
      createWorkspaceEvent,
      deleteWorkspaceEvent,
      toggleTargetCalendarMultiSelect,
      workspaceEvents,
      holidays,
      workspace,
      isCyclePreview,
      warnings
    } = this.props
    const isDayView = (typeof col.hour !== typeof undefined)
    const isToday = !isDayView && moment(col.date).isSame(moment(), 'day')
    const isWeekend = col.date && (moment(col.date).isoWeekday() === 6 || moment(col.date).isoWeekday() === 7)

    const day = moment(col.date).format('YYYY-MM-DD')
    const dayIsClosed = workspaceEvents.find((evt) => evt.type === 'closed' && moment(evt.period.start).format('YYYY-MM-DD') === col.date?.format('YYYY-MM-DD'))
    const dayIsHoliday = holidays.find((hol) => hol.type === 'public' && moment(hol.date).format('YYYY-MM-DD') === col.date?.format('YYYY-MM-DD'))
    const noteEvt = isDayView ? null : workspaceEvents.reverse().find((evt) => evt.type === 'note' && moment(evt.period.start).format('YYYY-MM-DD') === col.date?.format('YYYY-MM-DD'))

    const dayIsLocked = !!workspace && !!workspace.calendarLockedUntil && moment(workspace.calendarLockedUntil).isAfter(moment(col.date).endOf('day'))
    const colStyle = {
      width: 'calc((100% - ' + labelWidthRem.toString() + 'rem) / ' + cols.length.toString() + ')',
      minWidth: 'calc((100% - ' + labelWidthRem.toString() + 'rem) / ' + cols.length.toString() + ')',
      maxWidth: 'calc((100% - ' + labelWidthRem.toString() + 'rem) / ' + cols.length.toString() + ')'
    }
    const warningsArray = warnings ? Object.keys(warnings).map(w => {
      return {
        name: w,
        value: warnings[w]
      }
    }) : []
    let mainContent = null
    if (customChildren) {
      mainContent = col
    } else {
      mainContent = (
        <div
          className={isDayView || withoutTooltip ? classNames(
            'ds-c-col-dayview ds-c-col',
            calendarUtil.getColIdentifier(day, 'day'),
            isCyclePreview ? 'is-preview' : '',
            {
              'is-today': isToday,
              'is-weekend': isWeekend,
              'has-note': noteEvt?.description
            })
            : 'tooltip-anchor '}
          style={isDayView ? colStyle : null}
          key={isDayView ? col.hour : null}
          onClick={(e) => {
            // toggle target selection
            if (document.getElementsByClassName('is-multiselect-active').length) {
              toggleTargetCalendarMultiSelect({ date: col.date.format('YYYY-MM-DD'), userId: 'day' })
              e.stopPropagation()
            }
          }}
        >
          {/* text row 1: day of week */}
          {!isDayView
            ? <div className='ds-c-col-weekday'>{col.date.format('ddd')}</div>
            : null}

          {/* text row 2: day no. in month or time in day */}
          {!isDayView
            ? <div className='ds-c-col-monthday'>{col.date.format('D')}</div>
            : <div className='ds-c-col-hour'>{col.hour.toString() + ':00'}</div>}
        </div>
      )
    }
    if (isDayView || withoutTooltip || customChildren) {
      return mainContent
    } else {
      return (
        <div
          key={'header_col_' + (typeof col.hour === typeof undefined ? col.date.format('YYYY-MM-DD') : col.hour.toString())}
          className={classNames(
            'ds-c-col',
            calendarUtil.getColIdentifier(day, 'day'),
            {
              'is-today': isToday,
              'is-weekend': isWeekend,
              'has-note': noteEvt?.description,
              'is-holiday': dayIsHoliday,
              'is-closed': dayIsClosed
            }
          )}
          style={colStyle}
        >

          <Tooltip
            width='14rem'
            position={Tooltip.POSITIONS.BOTTOM_END}
            anchor={mainContent}
            className='ds-c-tooltip-content'
            middleware={[flip({
              fallbackPlacements: [
                Tooltip.POSITIONS.BOTTOM_START,
                Tooltip.POSITIONS.BOTTOM_END,
                Tooltip.POSITIONS.BOTTOM
              ],
              fallbackStrategy: 'initialPlacement',
              padding: 32
            })]}
          >
            <div className='ds-title'>{col.date.format('dddd D.M. YYYY')}</div>
            {dayIsHoliday
              ? <div className='ds-c-tt-holiday'>{dayIsHoliday.name}</div>
              : null}

            {permissionsUtil.canWrite(PERMISSIONS.CALENDAR) && (
              <>
                {/* view day */}
                <div
                  className='ds-c-header-tooltip-btn'
                  onClick={(e) => {
                    setCalendar({ date: day, view: 'day' })
                  }}
                >
                  {t('VIEW_DAY_SCHEDULE')}
                </div>

                {/* copy day */}
                <div
                  className='ds-c-header-tooltip-btn'
                  onClick={(e) => {
                    setModal('copy-period', {
                      forceCalendarSettings: {
                        view: 'day',
                        date: day
                      }
                    })
                  }}
                >
                  {t('COPY_DAY')}
                </div>

                {/* save as template */}
                <div
                  className='ds-c-header-tooltip-btn'
                  onClick={(e) => {
                    setModal('template-create', { date: day })
                  }}
                >
                  {t('SAVE_DAY_AS_TEMPLATE')}
                </div>

                {/* apply template */}
                {!dayIsLocked && (
                  <div
                    className='ds-c-header-tooltip-btn'
                    onClick={(e) => {
                      setModal('template-apply', { date: day })
                    }}
                  >
                    {t('APPLY_TEMPLATE_LIVE')}
                  </div>
                )}
                {/* clear day */}
                {!dayIsLocked && (
                  <div
                    className='ds-c-header-tooltip-btn'
                    onClick={(e) => {
                      setModal('confirm', {
                        title: 'CLEAR_DAY',
                        subtitle: t('CLEAR_DAY_CONFIRM_SUBTITLE'),
                        text: (
                          <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_16}>
                            <div
                              style={{ width: '100%' }}
                            >{t('CLEAR_DAY_CONFIRM_TXT_X', { x: moment(day).format('D. M. YYYY') })}
                            </div>
                          </Spacing>
                        ),
                        confirmLabel: t('CLEAR_DAY'),
                        onConfirm: () => {
                          clearLiveDay(day)
                        },
                        cancelLabel: t('CANCEL'),
                        onCancel: () => {
                        },
                        overSidebar: true,
                        buttonColor: 'red'
                      })
                    }}
                  >
                    {t('CLEAR_DAY')}
                  </div>
                )}

                {/* set as Closed / Open */}
                {dayIsClosed
                  ? (
                    <div
                      className='ds-c-header-tooltip-btn'
                      onClick={(e) => {
                        deleteWorkspaceEvent({
                          id: dayIsClosed.id
                        })
                      }}
                    >
                      {t('SET_AS_OPEN')}
                    </div>
                  )
                  : (
                    <div
                      className='ds-c-header-tooltip-btn'
                      onClick={(e) => {
                        createWorkspaceEvent({
                          type: 'closed',
                          period: {
                            start: col.date.clone().add(12, 'hours').startOf('day'),
                            end: col.date.clone().add(12, 'hours').endOf('day')
                          }
                        })
                      }}
                    >
                      {t('SET_AS_CLOSED')}
                    </div>
                  )}
              </>)}

            {(permissionsUtil.canWrite(PERMISSIONS.CALENDAR) || !!noteEvt?.description) && (
              <>
                {/* Note */}
                <Textarea
                  disabled={!permissionsUtil.canWrite(PERMISSIONS.CALENDAR)}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  label={t('NOTE') + ':'}
                  placeholder=''
                  size={Textarea.SIZES.FULL_WIDTH}
                  value={this.state.dayNote !== undefined
                    ? this.state.dayNote
                    : noteEvt && noteEvt.description !== undefined
                      ? noteEvt.description
                      : ''}
                  rows={typeof this.state.dayNote === 'string' && (this.state.dayNote.length > 40 || (this.state.dayNote.match(/\n/g) || []).length > 1)
                    ? 12
                    : typeof noteEvt?.description === 'string' && (noteEvt.description.length > 40 || (noteEvt.description.match(/\n/g) || []).length > 1)
                      ? 12
                      : undefined}
                  onChange={(val) => {
                    this.setState((s) => Object.assign({}, s, { dayNote: val }))
                  }}
                  onBlur={() => {
                    if (this.state.dayNote && this.state.dayNote.trim() !== '') {
                      const noteEvt = workspaceEvents.find((evt) => evt.type === 'note' && moment(evt.period.start).format('YYYY-MM-DD') === col.date.format('YYYY-MM-DD'))
                      if (noteEvt) {
                        deleteWorkspaceEvent({
                          id: noteEvt.id
                        })
                      }
                      createWorkspaceEvent({
                        type: 'note',
                        period: {
                          start: col.date.clone().add(12, 'hours').startOf('day'),
                          end: col.date.clone().add(12, 'hours').endOf('day')
                        },
                        description: this.state.dayNote
                      })
                    } else {
                      if (typeof this.state.dayNote === 'string' && this.state.dayNote.trim() === '') { // manual deletion of the note (when user changes the note to empty string)
                        const noteEvt = workspaceEvents.find((evt) => evt.type === 'note' && moment(evt.period.start).format('YYYY-MM-DD') === col.date.format('YYYY-MM-DD'))
                        if (noteEvt) {
                          deleteWorkspaceEvent({
                            id: noteEvt.id
                          })
                        }
                      }
                    }
                  }}
                />
              </>)}

            {warningsArray.length > 0 && (
              <>
                <Separator side={Separator.SIDES.BOTTOM} size={Separator.SIZES.FULL_WIDTH} />
                <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                  <Flex
                    direction={Flex.DIRECTION.COLUMN}
                  >
                    <>
                      <Flex
                        justify={Flex.POSITION.SPC_BETWEEN}
                      >
                        <Text
                          type={Text.TYPES.BODY_LARGE}
                          weight={Text.WEIGHTS.BOLD}
                        >{t('WARNINGS')}
                        </Text>
                        <Spacing size={Spacing.SIZES.SIZE_32} type={Spacing.TYPES.HORIZONTAL} />
                        <Link
                          to='/backoffice/reports/warnings'
                          type={Link.TYPES.ROUTER_LINK}
                        >
                          <Text
                            color={Text.COLORS.PRIMARY}
                            type={Text.TYPES.BODY_MEDIUM}
                            weight={Text.WEIGHTS.BOLD}
                            style={{ textDecoration: 'underline' }}
                          >
                            {t('WARNINGS_HOW_RESOLVE')}
                          </Text>
                        </Link>
                      </Flex>
                      <Spacing size={Spacing.SIZES.SIZE_8} />
                      <Flex
                        direction={Flex.DIRECTION.COLUMN}
                      >
                        {warningsArray.map(war => {
                          return (
                            <Flex
                              key={`${day}${war.name}${war.value}`}
                              direction={Flex.DIRECTION.COLUMN}
                            >
                              <Flex
                                justify={Flex.POSITION.SPC_BETWEEN}
                                align={Flex.POSITION.CENTER}
                              >
                                <Text
                                  type={Text.TYPES.BODY_MEDIUM}
                                  color={Text.COLORS.DANGER}
                                  weight={Text.WEIGHTS.MEDIUM}
                                >
                                  {t('WARNING_' + war.name)}
                                </Text>
                                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                                <CircledNumber number={war.value} color={CircledNumber.COLORS.DANGER} />
                              </Flex>
                              <Spacing size={Spacing.SIZES.SIZE_6} />
                            </Flex>
                          )
                        })}
                      </Flex>
                    </>
                  </Flex>
                </Spacing>
              </>
            )}
          </Tooltip>
        </div>
      )
    }
  }
}

export default connect(HeaderCol)
