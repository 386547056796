import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query PositionsQuery ($id: ID!) {
  workspace(
    id: $id
  ) {
    positions (
      archived: true
    ) {
      id,
      name,
      productionResourceType {
        name,
        defaultHourlyProductivity
      },
      color,
      archived,
      purpose,
      productionResourceType {
        name
        defaultHourlyProductivity
      }
    }
  }
}`

async function requestPositions (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('positions', errors)
  }
  return data.workspace.positions
}

export default requestPositions
