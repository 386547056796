import React from 'react'
import { Dropdown } from '@ui'
import { t } from 'i18next'
import { getWorkspaceOptions } from '@app/component/pages/organization/helpers'
import { miscUtil } from '@app/util'
import requestEmployees from '@app/request/employees'
import connect from './connect-transfer-creation'

export const TransferCreation = connect(({
  // from passed props:
  handleChange,
  setOriginWorkspaceEmps,
  detailsState,
  errors,
  // connected from store:
  workspaceId,
  countries,
  allAllowedWorkspaces,
  setWorkspaceId,
  loadPositions,
  auth
}) => {
  const optsWorkspaces = countries ? getWorkspaceOptions(countries).filter((country) => allAllowedWorkspaces.includes(country.value)) : []

  // reset the WS-specific form fields to null after changing selected WS
  const resetFormAfterWsChange = () => {
    handleChange('userId', null)
    handleChange('positionId', null)
    handleChange('localityID', null)
    handleChange('customAttributes', null)
  }

  return (
    <>
      <Dropdown
        singleSelect
        searchable
        style={Dropdown.STYLES.LIGHT}
        type={Dropdown.TYPES.VARIABLE}
        options={optsWorkspaces}
        value={optsWorkspaces.filter(w => w.value === detailsState.createTransferFrom)}
        onChange={({ value }) => {
          handleChange('createTransferFrom', value)
          resetFormAfterWsChange()

          requestEmployees({ id: value }, auth).then(resEmps => {
            const loadedEmps = resEmps.members.filter((m) => m.roles && m.roles.filter(r => !r.hidden).length).map((m) => {
              // convert to a store object representing this employee
              return miscUtil.getEmployeeObjectForStore(m.user, m.roles[0])
            })
            setOriginWorkspaceEmps(loadedEmps)
          })
        }}
        placeholder={t('SELECT')}
        label={t('EDIT_SHIFT_CREATE_TRANSFER_WS_FROM')}
        hasError={errors && errors.includes('transfer-ws-from-missing')}
        errorMessage={(errors && errors.includes('transfer-ws-from-missing')) ? t('EDIT_SHIFT_CREATE_TRANSFER_WS_FROM_MISSING') : null}
      />

      <Dropdown
        singleSelect
        searchable
        style={Dropdown.STYLES.LIGHT}
        type={Dropdown.TYPES.VARIABLE}
        options={optsWorkspaces}
        value={optsWorkspaces.filter(w => w.value === detailsState.createTransferTo)}
        onChange={({ value }) => {
          handleChange('createTransferTo', value)
          resetFormAfterWsChange()

          // workspace was switched - stuff needs to be set and reloaded
          if (value !== workspaceId) {
            setWorkspaceId(value, { silent: true })
            loadPositions(value)
          }
        }}
        placeholder={t('SELECT')}
        label={t('EDIT_SHIFT_CREATE_TRANSFER_WS_TO')}
        hasError={errors && errors.includes('transfer-ws-to-missing')}
        errorMessage={(errors && errors.includes('transfer-ws-to-missing')) ? t('EDIT_SHIFT_CREATE_TRANSFER_WS_TO_MISSING') : null}
      />
    </>
  )
})
