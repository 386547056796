import { requestOrganizationPositionCreate } from '@app/request'
import { setOrg } from '@app/action'

import { addError, setModal } from '../ac'
import store from '../store'

import isLoading from './is-loading'

export const createPosition = (form) => {
  return async (dispatch) => {
    const { auth, organization } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = (newItem) => ({
      ...oldOrg,
      countries: organization.countries.map((country) => {
        if (country.id === form.country) {
          return {
            ...country,
            positions: [
              ...country.positions,
              { id: newItem.id, name: newItem.name, color: newItem.color }
            ]
          }
        } else {
          return country
        }
      })
    }
    )

    dispatch(setOrg(newOrg({ id: '1', color: form.data.color, name: form.data.name })))
    const result = await requestOrganizationPositionCreate(form, auth)
    dispatch(isLoading('org-position-create'))
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(...oldOrg))
    } else {
      dispatch(setOrg(newOrg(result.organizationPositionCreate)))
      dispatch(setModal(null))
    }
    dispatch(isLoading('org-position-create', true))
  }
}
