import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client'
import { apiEndpoints } from '@app/const/config'
import { setContext } from '@apollo/client/link/context'
import store from '@app/store'
import { RetryLink } from '@apollo/client/link/retry'

const host = window.location.hostname
const BASE_URL = apiEndpoints[host].backend
const httpLink = new HttpLink({ uri: BASE_URL })

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: 30000,
    jitter: false
  },
  attempts: {
    max: 2,
    retryIf (error, _operation) {
      const consoleReport = 'Request failure detected. Details below.\n' +
        '-- operation name:' + _operation?.operationName + '\n' +
        '-- variables:\n' +
        JSON.stringify(_operation?.variables, null, 2) + '\n' +
        '-- query:\n' +
        _operation?.query?.loc?.source?.body + '\n' +
        '-- error:\n' +
        JSON.stringify(error, null, 2)
      console.log(consoleReport)

      return !!error
    }
  }
})

const authLink = setContext((operation, { headers }) => {
  const { auth } = store.getState()
  return {
    uri: `${BASE_URL}${operation.operationName}`,
    headers: {
      ...headers,
      authorization: auth ? `Bearer ${auth}` : ''
    }
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: from([
    retryLink,
    authLink,
    httpLink
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache', // we used to have 'network-only' here, but it caused some problems - pages displayed old data after being reloaded with F5
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  connectToDevTools: true
})
