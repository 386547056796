/* eslint-disable react/jsx-fragments */
import { Fragment, useState, useEffect } from 'react'
import { t } from 'i18next'

import {
  Loader,
  Icon,
  Position,
  Locality,
  Toggle,
  Button,
  Checkbox,
  Flex,
  Spacing,
  Dropdown,
  Link
} from '@ui'
import { notification } from '@app/util'
import requestActionReply from '@app/request/reply-action'
import requestOfferToTransfer from '@app/request/offer-to-transfer'
import requestOffersCancel from '@app/request/offers-cancel'

import {
  getOfferTypeOptions,
  loadCandidatesForShift,
  getNewOfferCandidatesHTML
} from './helpers'
import ListShifts from './list-shifts'
import styles from './index.scss'
import connect from './connect'

import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const TabApproveRequest = (props) => {
  const {
    // from store:
    positions,
    employees,
    workspace,
    auth,
    setModal,
    updateLiveShift,
    deleteLiveShift,
    // from component's properties:
    setRelevantShifts,
    renderChildBtns,
    // from HOC:
    isPluginEnabled
  } = props
  const {
    // from component's properties:
    relevantShifts
  } = props

  const selectedShifts = (relevantShifts && relevantShifts.length) ? relevantShifts.filter(s => s.isSelected) : []
  const [selectedSingleShift, setSelectedSingleShift] = useState(selectedShifts.length === 1 ? selectedShifts[0] : null)

  const selectedShiftsPosition = selectedSingleShift && positions.find(p => p.id === selectedSingleShift.positionId)
  const localitiesAreProbablyRelevant = isPluginEnabled('localities') && Array.isArray(workspace?.localities) && (workspace?.localities.length > 0)
  const warningsSupported = isPluginEnabled('laborlaw')
  const transfersSupported = workspace && workspace.transferGroups && workspace.transferGroups.length && workspace.transferGroups.reduce((a, s) => { return a + (s.workspaces ? s.workspaces.length : 0) }, 0)
  const offer = selectedSingleShift && selectedSingleShift.hasOffer
  const [offerTypeOptions, setOfferTypeOptions] = useState(getOfferTypeOptions(selectedSingleShift, selectedShifts))

  // update the set of offer type options according to current shift selection (this is called when shift selection changes)
  const updateOfferTypeOptions = (relShifts) => {
    let oto = [...offerTypeOptions]

    const sShifts = (relShifts && relShifts.length) ? relShifts.filter(s => s.isSelected) : []
    const sss = sShifts.length === 1 ? sShifts[0] : null

    // for multi-shift selection, adjust the options and their wording
    if (!sss) {
      const thereAreDrops = sShifts.find(s => s.hasOffer && s.hasOffer.type === 'drop')
      const thereAreSwaps = sShifts.find(s => s.hasOffer && s.hasOffer.type === 'swap')

      // drops and swaps are selected
      if (!!thereAreSwaps && !!thereAreDrops) {
        oto = [{
          value: 'drop',
          label: (
            <div className={cx('ds-mod-opt', 'ds-mod-opt-drop')}>
              <Icon ico={Icon.ICONS.switch} />
              <div>
                <div className={cx('ds-opt-l1')}>{t('OFF_TYPE_DROPORSWAP_MULTI')}</div>
                <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_DROPORSWAP_MULTI_SUBTITLE')}</div>
              </div>
            </div>)
        }].concat(getOfferTypeOptions(sss, sShifts).filter(opt => !['swap', 'drop'].includes(opt.value)))
      }

      // only swaps selected
      if (!!thereAreSwaps && !thereAreDrops) {
        oto = [{
          value: 'swap',
          label: (
            <div className={cx('ds-mod-opt', 'ds-mod-opt-drop')}>
              <Icon ico={Icon.ICONS.switch} />
              <div>
                <div className={cx('ds-opt-l1')}>{t('OFF_TYPE_SWAP_MULTI')}</div>
                <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_SWAP_MULTI_SUBTITLE')}</div>
              </div>
            </div>)
        }].concat(getOfferTypeOptions(sss, sShifts).filter(opt => !['swap', 'drop'].includes(opt.value)))
      }

      // only drops selected
      if (!thereAreSwaps && !!thereAreDrops) {
        oto = [{
          value: 'drop',
          label: (
            <div className={cx('ds-mod-opt', 'ds-mod-opt-drop')}>
              <Icon ico='assign' />
              <div>
                <div className={cx('ds-opt-l1')}>{t('OFF_TYPE_DROP_MULTI')}</div>
                <div className={cx('ds-opt-l2')}>{t('OFF_TYPE_DROP_MULTI_SUBTITLE')}</div>
              </div>
            </div>)
        }].concat(getOfferTypeOptions(sss, sShifts).filter(opt => !['swap', 'drop'].includes(opt.value)))
      }
    }

    // for single-shift selection, prevent changing the offer type when approving - this isn't supported on BE
    if (sss) {
      oto = getOfferTypeOptions(sss, sShifts).filter(opt => { if (sss.hasOffer && ['swap', 'drop'].includes(opt.value)) { return opt.value === sss.hasOffer.type } else { return true } })
    }

    setOfferTypeOptions(oto)
    if (oto && oto.length) setOptOfferType(oto[0].value)
  }

  const ssHasMaster = selectedSingleShift && selectedSingleShift.hasOffer && selectedSingleShift.hasOffer.transfer && selectedSingleShift.hasOffer.transfer.master
  const [optOfferType, setOptOfferType] = useState((offer ? offer.type : null) || (offerTypeOptions.length ? offerTypeOptions[0].value : null))
  const [optTransfers, setOptTransfers] = useState(offer ? (!!offer.transfer) : false)

  // set audience option defaults according to WS settings.offers.defaultAudienceOptions
  const wsDefOpts = workspace && workspace.settings && workspace.settings.offers && workspace.settings.offers && workspace.settings.offers.defaultAudienceOptions
  const defaultOptWithPosition = (offer && offer.transfer && offer.transfer.master) ? false : (wsDefOpts ? wsDefOpts.filterByPosition : true)
  const defaultOptWithLocality = localitiesAreProbablyRelevant ? (wsDefOpts ? wsDefOpts.filterByLocality : true) : false
  const defaultOptWithSkill = wsDefOpts ? (wsDefOpts.filterBySkill ? 'sameAsShift' : 'any') : 'any'
  const defaultOptWithoutWarnings = warningsSupported ? (wsDefOpts ? wsDefOpts.filterByWarnings : true) : false
  const defaultOptEveryone = (!defaultOptWithPosition && !defaultOptWithLocality && (defaultOptWithSkill === 'any') && !defaultOptWithoutWarnings)

  const [optEveryone, setOptEveryone] = useState(defaultOptEveryone)
  const [optWithPosition, setOptWithPosition] = useState(defaultOptWithPosition)
  const [optWithSkill, setOptWithSkill] = useState(defaultOptWithSkill)
  const [optWithLocality, setOptWithLocality] = useState(defaultOptWithLocality)
  const [optWithoutWarnings, setOptWithoutWarnings] = useState(defaultOptWithoutWarnings)
  const [optNotify, setOptNotify] = useState((offer && offer.notifyEmployees) || true)

  const [candidates, setCandidates] = useState([])
  const [candidatesTransfer, setCandidatesTransfer] = useState([])
  const [extraIncludes, setExtraIncludes] = useState((offer && offer.swaps) ? offer.swaps.map(sw => sw.shiftId) : [])
  const [extraExcludes, setExtraExcludes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadCandidates = (sss) => {
    if (sss) {
      let owp = optWithPosition
      let ows = optWithSkill === 'sameAsShift'
      let ot = optTransfers
      if (sss.hasOffer && sss.hasOffer.transfer && !!sss.hasOffer.transfer.master) {
        // make sure some options are set to false for external shifts (with master)
        owp = false
        ows = false
        ot = false
      }

      loadCandidatesForShift(sss, {
        realShiftId: undefined,
        filterByLocality: !optEveryone && optWithLocality,
        filterByPosition: !optEveryone && owp,
        filterBySkill: !optEveryone && ows,
        filterByWarnings: !optEveryone && optWithoutWarnings,
        auth,
        workspace,
        loadTransfers: ot,
        offerType: optOfferType,
        beforeLoadCallback: () => { setIsLoading(true) },
        afterLoadCallback: () => { setIsLoading(false) }
      }).then(res => {
        setCandidates(res.candidates)
        setCandidatesTransfer(res.candidatesTransfer)
      })
    } else {
      if (candidates.length) setCandidates([])
      if (candidatesTransfer.length) setCandidatesTransfer([])
    }
  }

  // when user changes the audience setup, get the list of candidates
  useEffect(() => {
    loadCandidates(selectedSingleShift)
  }, [optEveryone, optWithSkill, optWithoutWarnings, JSON.stringify(optWithLocality), JSON.stringify(optWithPosition), optTransfers, optOfferType])

  // load candidates on mount
  useEffect(() => {
    loadCandidates(selectedSingleShift)
    updateOfferTypeOptions(relevantShifts)
  }, [])

  // generate error message if submit is not allowed
  const problemWithSubmit = () => {
    const externalCandsCount = candidatesTransfer.reduce((a, s) => { return a + parseInt(s.candidatesCount) }, 0)

    if (selectedShifts.length === 0) return 'mustSelectShifts'

    if (optOfferType === 'unassign') {
      if (selectedShifts.filter(evt => evt.userId).length === 0) return 'noShiftsToUnassign'
    }

    if (optOfferType === 'drop') {
      const selectedCands = candidates.filter(cn => cn.suitable === true && !extraExcludes.includes(cn.userId))
        .map(c => c.userId)
        .concat(candidates.filter(cn => cn.suitable === false && extraIncludes.includes(cn.userId)).map(c => c.userId))
      if (selectedShifts.length === 1 && selectedCands.length === 0 && externalCandsCount === 0) return 'noSelectedCandsDrop'
    }

    if (optOfferType === 'swap') {
      if (selectedShifts.length > 1) return 'cantRunBatchSwaps'
      if (extraIncludes.length === 0) return 'noSelectedCandsSwap'
    }

    return null
  }

  const handleSubmit = () => {
    // unassign
    if (optOfferType === 'unassign') {
      requestOffersCancel({
        workspace: workspace.id,
        offers: selectedShifts.map(s => s.hasOffer.id)
      }, auth).then(res => {
        updateLiveShift(null, { userId: null }, selectedShifts.filter(evt => evt.userId).map(evt => evt.id))
      })

      setModal(null)
    }

    // delete
    if (optOfferType === 'delete') {
      requestOffersCancel({
        workspace: workspace.id,
        offers: selectedShifts.map(s => s.hasOffer.id)
      }, auth).then(res => {
        deleteLiveShift(null, selectedShifts.map(evt => evt.id))
      })
      setModal(null)
    }

    // approve drop/swap offers
    if (['drop', 'swap'].includes(optOfferType)) {
      setIsLoading(true)
      requestActionReply({
        actions: selectedShifts.map(s => s.hasOffer.approvalAction.id),
        reply: 'yes',
        data: {
          options: {
            audience: {
              options: {
                filterByLocality: Array.isArray(optWithLocality) ? undefined : optWithLocality,
                filterByLocalities: Array.isArray(optWithLocality) ? optWithLocality : undefined,
                filterByPosition: Array.isArray(optWithPosition) ? undefined : optWithPosition,
                filterByPositions: Array.isArray(optWithPosition) ? optWithPosition : undefined,
                filterBySkill: optWithSkill === 'sameAsShift',
                filterByWarnings: optWithoutWarnings
              },
              includes: extraIncludes,
              excludes: extraExcludes
            },
            // reason: '',
            notifyEmployees: optNotify
          }
        }
      }, auth).then(res => {
        if (res && res.error) {
          notification.error({ title: 'ERR_MESSAGE.offerApprovalFailed', message: res.error.message })

          if (optTransfers && selectedSingleShift && selectedSingleShift.hasOffer) {
            requestOfferToTransfer({
              workspace: workspace.id,
              offer: selectedSingleShift.hasOffer.id
            }, auth)
          }
        } else {
          notification.success({ code: 'offerApproved' + (selectedShifts.length > 1 ? 'Multi' : '') })
        }
        setIsLoading(false)
        setModal(null)
      })
    }
  }

  const getSubmitBtnText = () => {
    let ret = ''
    if (['drop', 'swap'].includes(optOfferType)) {
      const hasMaster = selectedSingleShift && selectedSingleShift.hasOffer && selectedSingleShift.hasOffer.transfer && selectedSingleShift.hasOffer.transfer.master

      ret = t('OFF_BTN_APPROVE_' + optOfferType.toUpperCase())
      ret += ' (' + selectedShifts.length.toString() + ((optTransfers && selectedSingleShift && !hasMaster) ? (' ' + t('OFF_LIST_TRANSFER')) : '') + ')'
      if (optNotify) ret += ' ' + t('OFF_BTN_APPROVE_NOTIFY')
    }

    if (optOfferType === 'unassign') {
      ret = t('OFF_TYPE_FREE_MULTI')
      ret += ' (' + selectedShifts.length.toString() + ')'
    }

    if (optOfferType === 'delete') {
      ret = t('OFF_TYPE_DELETE' + (selectedShifts.length > 1 ? '_MULTI' : ''))
      ret += ' (' + selectedShifts.length.toString() + ')'
    }

    return ret
  }

  const footerButtons = [
    <Fragment key={0}>
      <Button
        label={t('OFF_BTN_NO_ACTION')}
        onClick={() => { setModal(null) }}
      />
      {selectedShifts.length > 0 && (
        <Button
          label={t('OFF_BTN_APPROVE_DENY' + (!selectedSingleShift ? '_MULTI' : '')) + ' (' + selectedShifts.length.toString() + ')'}
          onClick={() => {
            requestOffersCancel({
              workspace: workspace.id,
              offers: selectedShifts.map(s => s.hasOffer.id)
            }, auth)
            setModal(null)
          }}
        />
      )}
      {selectedShifts.length > 0 && (
        <Button
          label={getSubmitBtnText()}
          style={Button.STYLES.CONTAINED}
          color={optOfferType === 'delete' ? Button.COLORS.RED : Button.COLORS.PRIMARY}
          disabled={problemWithSubmit() || isLoading}
          loading={isLoading}
          onClickActiveWhenDisabled
          onClick={() => {
            const problem = problemWithSubmit()
            if (problem) {
              notification.error({ title: '', message: t('OFF_SUBMIT_PROBLEM.' + problem) })
            } else {
              handleSubmit()
            }
          }}
        />
      )}
    </Fragment>
  ]
  useEffect(() => {
    renderChildBtns(footerButtons)
  }, [selectedShifts.length, JSON.stringify(optWithLocality), JSON.stringify(optWithPosition), optWithSkill, optWithoutWarnings, optTransfers, optNotify, candidates.length, extraIncludes.length, extraExcludes.length])

  // prepare the positionsList
  const positionsList = positions.filter(pos => !pos.archived).map((pos) => {
    return {
      label: (
        <Position
          name={pos.name}
          color={pos.color}
        />
      ),
      value: pos.id
    }
  })

  // prepare the localitiesList
  const localitiesOnWS = workspace && workspace.localities && workspace.localities
  const localitiesList = (localitiesOnWS || []).map(loc => {
    return {
      label: <Locality {...loc} />, // (loc.shortName ? '(' + loc.shortName + ') ' : '') + loc.name
      value: loc.id
    }
  })

  return (
    <div className={cx('ds-mod-content', 'ds-mod-content-show')}>
      <ListShifts
        disabled={isLoading}
        relevantShifts={relevantShifts}
        setRelevantShifts={(relShifts) => {
          setRelevantShifts(relShifts)
          if (relShifts.filter(s => s.isSelected).length === 1) {
            const sss = relShifts.find(s => s.isSelected)
            setSelectedSingleShift(sss)
            loadCandidates(sss)
          } else {
            setSelectedSingleShift(null)
          }
          setExtraIncludes([])
          setExtraExcludes([])

          // update offer type options accordingto new selection
          updateOfferTypeOptions(relShifts)
        }}
        noShiftsContent={(
          <Fragment>
            <div className={cx('ds-mod-row', 'no-shifts-content')}>
              {t('OFF_SHIFTS_FALLBACK_APPROVE_1')}
            </div>

            {workspace && workspace.settings && workspace.settings.offers && !workspace.settings.offers.managerApprovesRequest && (
              <div className={cx('ds-mod-row', 'no-shifts-content')}>
                <div style={{ marginBottom: '8ex' }}>{t('OFF_SHIFTS_FALLBACK_APPROVE_2')}</div>
                <Link
                  type={Link.TYPES.ROUTER_LINK}
                  to='/settings/contest-settings'
                >
                  <Button label={t('OFF_SHIFTS_FALLBACK_RESOLVE_BTN')} />
                </Link>
              </div>
            )}
          </Fragment>
        )}
        subtitleTxt='OFF_TAB_APPROVE'
      />

      <div className={cx('ds-mod-off-wrap')}>
        <div className={cx('ds-mod-off-twocolumn')}>
          <div className={cx('ds-mod-off-details')}>

            <div className={cx('ds-mod-row')}>
              <div className={cx('ds-title', { 'is-disabled': relevantShifts.length === 0 })}>{t('OFF_OFFER_ACTION')}</div>
            </div>
            <div className={cx('ds-mod-row', 'is-section-title', { 'is-disabled': relevantShifts.length === 0 })} style={{ fontSize: '87.5%' }}>
              {t('OFF_APPROVE_ACTION')}
            </div>

            {selectedShifts.length === 0
              ? <div className={cx('ds-mod-row', { 'is-disabled': relevantShifts.length === 0 })}>{t('OFF_SHOW_NOT_SELECTED')}</div>
              : (
                <Fragment>

                  {/* offer type selection */}
                  <div className={cx('ds-mod-row')}>
                    <Dropdown
                      singleSelect
                      size={Dropdown.SIZES.FULL_WIDTH}
                      type={Dropdown.TYPES.VARIABLE}
                      style={Dropdown.STYLES.LIGHT}

                      disabled={isLoading}
                      value={[offerTypeOptions.find(o => o.value === optOfferType)].filter(Boolean)}
                      options={offerTypeOptions}
                      onChange={(val) => {
                        if (candidates.length) setCandidates([])
                        if (candidatesTransfer.length) setCandidatesTransfer([])
                        if (extraIncludes.length) setExtraIncludes([])
                        if (extraExcludes.length) setExtraExcludes([])
                        setOptOfferType(val.value)
                      }}
                    />
                  </div>

                  {/* audience setup */}
                  {selectedSingleShift && optOfferType === 'drop' && (
                    <div className={cx('ds-mod-audience-setup-rows')}>
                      <div className={cx('ds-mod-row', 'is-section-title')}>
                        {t('OFF_WHO_IS_AUDIENCE')}
                      </div>

                      {/* select everyone */}
                      <div className={cx('ds-mod-row', { 'is-true': optEveryone })} style={{ marginBottom: '0.4ex' }}>
                        <Toggle
                          disabled={isLoading}
                          value={optEveryone}
                          onChange={(val) => {
                            if (val === true || val === 'true') {
                              setOptWithPosition(false)
                              setOptWithLocality(false)
                              setOptWithoutWarnings(false)
                              setExtraIncludes(candidates?.filter(cn => !cn.suitable).map(cn => cn.userId) || [])
                            } else {
                              setOptWithPosition(true)
                              setOptWithLocality(Boolean(localitiesAreProbablyRelevant))
                              setOptWithoutWarnings(true)
                              setExtraIncludes([])
                            }
                            setOptEveryone(val)
                          }}
                        />
                        {t('OFF_AUDIENCE_EVERYONE')}
                      </div>

                      {/* with position */}
                      {ssHasMaster // don't display position option for external transfers
                        ? null
                        : (
                          <div className={cx('ds-mod-row', { 'is-true': optWithPosition })}>
                            <Checkbox
                              checked={Boolean(optWithPosition)}
                              onChange={(val) => {
                                setOptWithPosition(val)
                              }}
                              disabled={optEveryone || isLoading}
                            />

                            <Spacing
                              size={Spacing.SIZES.SIZE_8}
                              type={Spacing.TYPES.HORIZONTAL}
                            >
                              <div style={{ whiteSpace: 'nowrap' }}>{t('OFF_AUDIENCE_POS_1')}</div>
                            </Spacing>

                            {selectedSingleShift
                              ? (
                                <Fragment>
                                  <Spacing
                                    size={Spacing.SIZES.SIZE_8}
                                    type={Spacing.TYPES.HORIZONTAL}
                                  >
                                    <Dropdown
                                      label={null}
                                      searchable
                                      type={Dropdown.TYPES.VARIABLE}
                                      style={Dropdown.STYLES.BORDERLESS}
                                      size={Dropdown.SIZES.LARGE}
                                      options={positionsList}
                                      value={
                                        Array.isArray(optWithPosition)
                                          ? positionsList.filter((pos) => optWithPosition.includes(pos.value))
                                          : positionsList.find((pos) => pos.value === selectedShiftsPosition.id)
                                      }
                                      placeholder={t('SELECT_JOB_POSITION')}
                                      onChange={(values) => {
                                        if (Array.isArray(values) && values.length === 0) {
                                          setOptWithPosition(false)
                                        } else {
                                          setOptWithPosition(values.map(v => v.value))
                                        }
                                      }}
                                      disabled={!optWithPosition}
                                    />
                                  </Spacing>

                                  {selectedSingleShift.idealSkill ? t('OFF_AUDIENCE_POS_2') : null}
                                  {selectedSingleShift.idealSkill && (
                                    <Dropdown
                                      label={null}
                                      singleSelect
                                      sortedOptions={false}
                                      size={Dropdown.SIZES.LARGE}
                                      type={Dropdown.TYPES.VARIABLE}
                                      style={Dropdown.STYLES.BORDERLESS}
                                      options={[
                                        { value: 'any', label: t('OFF_AUDIENCE_ANY_SKILL') },
                                        { value: 'sameAsShift', label: t('SKILL_NAME_' + selectedSingleShift.idealSkill) }
                                      ]}
                                      value={[{
                                        value: optWithSkill,
                                        label: (optWithSkill === 'any' ? t('OFF_AUDIENCE_ANY_SKILL') : t('SKILL_NAME_' + selectedSingleShift.idealSkill))
                                      }]}
                                      placeholder={null}
                                      onChange={(v) => {
                                        setOptWithSkill(v.value)
                                      }}
                                      disabled={optEveryone}
                                    />
                                  )}

                                </Fragment>
                              )
                              : <div style={{ whiteSpace: 'nowrap' }}>{' ' + t('OFF_AUDIENCE_LOC_MULTI')}</div>}
                          </div>
                        )}

                      {/* with locality */}
                      {localitiesAreProbablyRelevant && (
                        <div className={cx('ds-mod-row', { 'is-true': optWithLocality })}>
                          <Flex align={Flex.POSITION.CENTER}>
                            <Checkbox
                              checked={Boolean(optWithLocality)}
                              onChange={(val) => {
                                setOptWithLocality(val)
                              }}
                              disabled={optEveryone || isLoading}
                            />
                            <Spacing
                              size={Spacing.SIZES.SIZE_8}
                              type={Spacing.TYPES.HORIZONTAL}
                            >
                              <div style={{ whiteSpace: 'nowrap' }}>{t('OFF_AUDIENCE_LOC')}</div>
                            </Spacing>

                            <Dropdown
                              label={null}
                              searchable
                              type={Dropdown.TYPES.VARIABLE}
                              style={Dropdown.STYLES.BORDERLESS}
                              size={Dropdown.SIZES.LARGE}
                              options={localitiesList}
                              value={
                                Array.isArray(optWithLocality)
                                  ? localitiesList.filter((loc) => optWithLocality.includes(loc.value))
                                  : localitiesList.find((loc) => loc.value === selectedSingleShift?.localityId)
                              }
                              placeholder={t('SELECT')}
                              onChange={(values) => {
                                if (Array.isArray(values) && values.length === 0) {
                                  setOptWithLocality(false)
                                } else {
                                  setOptWithLocality(values.map(v => v.value))
                                }
                              }}
                              disabled={!optWithLocality}
                            />

                            {!selectedSingleShift && (' ' + t('OFF_AUDIENCE_LOC_MULTI'))}
                          </Flex>
                        </div>
                      )}

                      {/* without warnings */}
                      <div className={cx('ds-mod-row', { 'is-true': optWithoutWarnings })}>
                        <Flex align={Flex.POSITION.CENTER}>
                          <Checkbox
                            checked={optWithoutWarnings}
                            onChange={(val) => {
                              setOptWithoutWarnings(val)
                            }}
                            disabled={optEveryone || isLoading}
                          />
                          <Spacing
                            size={Spacing.SIZES.SIZE_8}
                            type={Spacing.TYPES.HORIZONTAL}
                          >
                            <div style={{ whiteSpace: 'nowrap' }}>{t('OFF_AUDIENCE_NOWARN')}</div>
                          </Spacing>
                        </Flex>
                      </div>

                      {/* transfer employees */}
                      {transfersSupported && !ssHasMaster
                        ? (
                          <div className={cx('ds-mod-row', { 'is-true': optTransfers })} style={{ marginTop: '0.4ex' }}>
                            <Toggle
                              value={optTransfers}
                              onChange={(val) => {
                                setOptTransfers(val)
                              }}
                              disabled={isLoading}
                            />
                            {t('OFF_AUDIENCE_TRANS')}
                          </div>
                        ) : null}

                    </div>
                  )}

                  {['drop', 'swap'].includes(optOfferType) && (
                    <div className={cx('ds-mod-notification-setup-rows')}>
                      <div className={cx('ds-mod-row', 'is-section-title')}>
                        {t('OFF_NOTIF_TITLE')}
                      </div>
                      <div className={cx('ds-mod-row', { 'is-true': optNotify })}>
                        <Toggle
                          value={optNotify}
                          onChange={(val) => {
                            setOptNotify(val)
                          }}
                          disabled={isLoading}
                        />
                        <div style={{ position: 'relative' }}>
                          {t('OFF_NOTIF_TXT_1')}
                          <div className={cx('is-note')}>{t('OFF_NOTIF_TXT_2')}</div>
                        </div>
                      </div>
                    </div>
                  )}

                </Fragment>
              )}
          </div>

          <div className={cx('ds-mod-off-audience')}>

            <div className={cx('ds-mod-row')}>
              <div className={cx('ds-title', { 'is-disabled': !relevantShifts.length })}>{t('OFF_AUDIENCE' + (offer && offer.type === 'swap' ? '_SWAP' : ''))}</div>
            </div>

            {selectedShifts.length === 0 && (
              <div className={cx('ds-mod-row', 'ds-mod-audience-explain', { 'is-disabled': !relevantShifts.length })}>{t('OFF_AUDIENCE_NOTSEL')}</div>
            )}

            {selectedShifts.length > 1 && (
              <div className={cx('ds-mod-row', 'ds-mod-audience-explain', { 'is-disabled': !relevantShifts.length })}>{t('OFF_AUDIENCE_MULTI')}</div>
            )}

            {selectedShifts.length === 1 && !isLoading && (
              getNewOfferCandidatesHTML({
                warningsSupported,
                localitiesAreProbablyRelevant,
                employees,
                workspace,
                optOfferType,
                optTransfers,
                setOptTransfers,
                selectedSingleShift,
                candidates,
                candidatesTransfer,
                extraExcludes,
                extraIncludes,
                setExtraExcludes,
                setExtraIncludes,
                positions
              })
            )}

            {isLoading && <Loader size={Loader.SIZES.SMALL} />}

          </div>

        </div>
      </div>

    </div>
  )
}

export default connect(TabApproveRequest)
