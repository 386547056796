import React, { useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import {
  Button,
  Dropdown,
  Input,
  Modal,
  Flex,
  Spacing
} from '@ui'
import { requestEmailCheckPublic } from '@app/request'

import connect from './connect'

const WorkspaceCreate = ({
  close,
  countries,
  createWorkspace,
  isLoading
}) => {
  const [form, setForm] = useState({
    name: undefined,
    area: undefined,
    email: undefined,
    customId: undefined
  })

  const [emailWasAlreadyChecked, setEmailWasAlreadyChecked] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [areaError, setAreaError] = useState(false)

  const [checkingEmail, setCheckingEmail] = useState(false)
  const areOptions = countries.map((country) => {
    return country.areas.map((area) => ({
      label: area?.name,
      value: area?.id,
      country: country?.id
    }))
  }).flat()

  const validateInputs = async (form) => {
    if (!form.area) {
      setAreaError(true)
    } else {
      setAreaError(false)
    }
    if (!form.name || form.name.trim() === '' || form.name.trim().length < 2) {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }

  const validateEmail = async () => {
    if (!form.email || form.email.trim() === '') {
      setEmailError(true)
      return false
    }
    setCheckingEmail(true)
    const res = await requestEmailCheckPublic({ email: form.email })
    setEmailError(!res) // we need the email to be in use, so we can add that user as manager. requestEmailCheckPublic returns true if the email is used.
    setCheckingEmail(false)
    setEmailWasAlreadyChecked(true)
    return res
  }

  const canSave = () => {
    if (checkingEmail) return false
    if (!emailWasAlreadyChecked) return false
    if (!form.area) return false
    if (!form.name || form.name.trim() === '' || form.name.trim().length < 2) return false
    if (!form.email || form.email.trim() === '') return false
    if (emailError) return false
    return true
  }

  return (
    <Modal
      isLoading={isLoading}
      size={Modal.SIZES.M}
      footerContent={
        <Flex stretch>
          <Button
            onClick={close}
            label={t('CLOSE')}
            size={Button.SIZES.FULL}
          />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
            onClick={async () => {
              await validateInputs(form)
              if (!emailWasAlreadyChecked) validateEmail()
              if (canSave()) {
                createWorkspace(form)
              }
            }}
            onClickActiveWhenDisabled
            label={t('SAVE')}
            disabled={!canSave()}
            loading={checkingEmail}
          />
        </Flex>
      }
      headerContent={<div className='ds-title'>{t('ORG_SP_WORKSPACES_WORKSPACE_ADD_NEW')}</div>}
    >
      <Spacing size={Spacing.SIZES.SIZE_12} />
      <Spacing size={Spacing.SIZES.SIZE_12} type={Spacing.TYPES.HORIZONTAL}>
        <Input
          size={Input.SIZES.FULL_WIDTH}
          label={t('ORG_SP_WORKSPACES_WORKSPACE_NAME_LABEL')}
          placeholder={t('ORG_SP_WORKSPACES_WORKSPACE_NAME_PLACEHOLDER')}
          onChange={(val) => {
            setForm({ ...form, name: val })
            validateInputs({ ...form, name: val })
          }}
          value={form.name}
          required
          hasError={nameError}
          autoFocus
        />
        <Spacing size={Spacing.SIZES.SIZE_8} />
        <Dropdown
          type={Dropdown.TYPES.VARIABLE}
          size={Dropdown.SIZES.FULL_WIDTH}
          style={Dropdown.STYLES.LIGHT}
          label={t('ORG_SP_WORKSPACES_AREA')}
          placeholder={t('CHOOSE')}
          onChange={(val) => {
            setForm({ ...form, area: val })
            validateInputs({ ...form, area: val })
          }}
          options={areOptions}
          value={form.area}
          singleSelect
          hasError={areaError}
        />
        <Spacing size={Spacing.SIZES.SIZE_8} />
        <Input
          size={Input.SIZES.FULL_WIDTH}
          type={Input.TYPES.EMAIL}
          label={t('ORG_SP_WORKSPACES_WORKSPACE_EMAIL_LABEL')}
          placeholder={t('EMAIL_PLACEHOLDER')}
          onChange={(val) => setForm({ ...form, email: val })}
          onBlur={validateEmail}
          value={form.email}
          required
          hasError={emailError}
        />
        <Spacing size={Spacing.SIZES.SIZE_8} />
        <Input
          size={Input.SIZES.FULL_WIDTH}
          label={t('ID')}
          onChange={(val) => {
            setForm({ ...form, customId: val })
            validateInputs({ ...form, customId: val })
          }}
          value={form.customId}
        />
      </Spacing>
    </Modal>
  )
}

WorkspaceCreate.propTypes = {
  close: PropTypes.func.isRequired
}
WorkspaceCreate.defaultProps = {}

export default connect(WorkspaceCreate)
