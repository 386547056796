import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import {
  loadShiftDetail,
  loadWorkspaceNames,
  loadEmployeeShifts,
  setModal,
  deleteLiveShift,
  updateLiveShift
} from '@app/ac'

const mapStateToProps = ({ loading, shiftDetail, positions, workspaces, auth, me, workspaceId, shifts, employees }) => {
  return {
    auth,
    shiftDetail,
    positions,
    workspaces,
    workspaceId,
    me,
    shifts,
    isLoading: loading.includes('load-shift-detail') || loading.includes('load-shifts') || loading.includes('update-live-shift') || loading.includes('edit-shift') || loading.includes('workspace-names'),
    employee: (employees && shiftDetail?.userId && Object.keys(employees).includes(shiftDetail.userId)) ? employees[shiftDetail.userId] : null
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadShiftDetail,
    loadWorkspaceNames,
    loadEmployeeShifts,
    deleteLiveShift,
    updateLiveShift,
    setModal
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
