import { timeUtil, numberUtil } from '@app/util'

function getShift (props) {
  const { templateId, templates, shiftHash } = props
  if (!templateId) return null
  const template = templates.find((tpl) => tpl.id === templateId)
  if (!template) return null
  return template.requirements.find((sf) => sf.hash === shiftHash)
}

function getTemplateDetails (tpl) {
  if (!tpl || !tpl.requirements) {
    return {
      shiftCount: 0,
      hours: 0,
      positions: [],
      start: null,
      end: null
    }
  }

  const shifts = tpl.requirements
  const duration = shifts.reduce((acc, sf) => {
    return acc + sf.duration
  }, 0)
  const shiftCount = shifts ? shifts.length : 0
  const positions = shifts.reduce((acc, sf) => {
    if (!acc.includes(sf.positionId)) {
      acc.push(sf.positionId)
    }
    return acc
  }, [])

  return {
    shiftCount,
    positions,
    hours: numberUtil.round2decimals(duration / 60) || 0,
    start: timeUtil.formatMinutes(timeUtil.earliest(shifts)),
    end: timeUtil.formatMinutes(timeUtil.latest(shifts))
  }
}

function role (me, workspaceId) {
  const meRole = me.roles && me.roles.find(rl => rl.workspaceId === workspaceId)
  return meRole && meRole.role
}

export default {
  getShift,
  getTemplateDetails,
  role
}
