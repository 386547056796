import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation PositionCreateMutation (
  $workspace: ID!,
  $name: String!,
  $color: String,
  $productionResourceType: ProductionResourceTypeInput
) {
  positionCreate (
    workspace: $workspace
    data: {
      name: $name
      color: $color
      productionResourceType: $productionResourceType
    }
  ) {
    id
  }
}`

async function requestPositionCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('position-create', errors)
  }
  return data.positionCreate
}

export default requestPositionCreate
