import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `query EmployeesQuery (
  $id: ID!
  $locality: ID
  $userIds: [ID!]
) {
  workspace (
    id: $id
  ) {
    members (
      locality: $locality
      userIds: $userIds
    ) ${q.MEMBER}
  }
}`

async function requestEmployees (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    const error = errUtil.apiError('employees', errors)
    return { ...data?.workspace, ...error } // in case of an error, we still return the data, but add the 'error' prop and we deal with that in /ac/load-employees.js
  }
  return data.workspace
}

export default requestEmployees
