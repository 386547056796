/* eslint-disable react/jsx-fragments */
import React from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Loader,
  Icon,
  Button,
  Flex,
  Spacing,
  Text
} from '@ui'
import {
  colorUtil,
  sortUtil,
  timeUtil
} from '@app/util'

import connect from './connect'
import './index.scss'

const HOURS = timeUtil.hours()

// onDrag fires a lot, skip some
let cooldown = false

// we need to know real row width
// to convert drag distance into minutes
let rowWidth = null
function onRowRef (row) {
  if (row && row.offsetWidth) {
    rowWidth = row.offsetWidth
  }
}

class Shifts extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shiftHash: null,
      beforeDrag: null,
      delta: null,
      change: null
    }

    this.handleOnClick = this._onClick.bind(this)
    this.handleOnStart = this._onStart.bind(this)
    this.handleOnMove = this._onMove.bind(this)
    this.handleOnEnd = this._onEnd.bind(this)
  }

  _getX (e) {
    if (e.clientX) return e.clientX
    const ret = window.dragPageX || 0
    if (ret < 0) return 0
    return ret
  }

  componentDidMount () {
    // set up the drag&drop handler 'document.ondragover'
    document.ondragover = (e) => {
      window.dragPageX = e.pageX
      window.dragPageY = e.pageY
    }
  }

  _onClick (shiftHash, e) {
    const { templateId, setModal, readOnly, quickActionsTooltipOnClick } = this.props

    if (readOnly) return
    if (quickActionsTooltipOnClick) {
      if (e.target) {
        const tooltip = e.target.parentNode.querySelector(':scope > .is-qa-tooltip-on-click')
        if (tooltip) tooltip.click()
      }
      return
    }

    if (templateId) setModal('edit-shift', { type: 'template', templateId, shiftHash })
  }

  _onStart (e, shiftHash, change) {
    this.setState({
      change,
      shiftHash,
      beforeDrag: this._getX(e),
      delta: 0
    })
  }

  _onMove (e) {
    if (cooldown) return
    cooldown = true
    setTimeout(() => { cooldown = false }, 20)

    this.setState({
      delta: this._getX(e) - this.state.beforeDrag
    })
  }

  _onEnd (e) {
    const { beforeDrag, shiftHash } = this.state
    this.setState({
      delta: this._getX(e) - beforeDrag
    }, () => {
      const { editTemplateShift, templateId, customEditShiftFunction } = this.props

      let st = this.getStart(this.modifiedShift())
      if (st >= 1440) st -= 1440
      if (st < 0) st += 1440
      const update = {
        start: st,
        duration: this.getDuration(this.modifiedShift())
      }
      if (customEditShiftFunction) {
        customEditShiftFunction(shiftHash, update)
      } else {
        editTemplateShift(templateId, shiftHash, update)
      }
      this.setState(s => Object.assign({}, s, { change: null }))
    })
  }

  modifiedShift () {
    const { shiftHash } = this.state
    return this.props.shifts.find((sf) => sf.hash === shiftHash)
  }

  getMinDelta () {
    return Math.round((this.state.delta / rowWidth) * 24 * 60)
  }

  getStart (sf) {
    const modSf = this.modifiedShift()
    if (!modSf || modSf.hash !== sf.hash || !['start', 'both'].includes(this.state.change)) {
      return sf.start
    }

    // 15 minute steps
    const newStart = Math.round((modSf.start + this.getMinDelta()) / 15) * 15
    if (newStart < 0) {
      return 0
    }
    return newStart
  }

  getDuration (sf) {
    const modSf = this.modifiedShift()
    if (!modSf || modSf.hash !== sf.hash || !['duration', 'both'].includes(this.state.change)) {
      return sf.duration
    }

    let newDuration = 0
    if (this.state.change === 'duration') {
      newDuration = modSf.duration + this.getMinDelta()
      if (newDuration < 60) {
        newDuration = 60
      }
    }
    if (this.state.change === 'both') {
      if (this.getStart(modSf) === 0) {
        newDuration = modSf.start + modSf.duration
      } else {
        newDuration = modSf.duration - this.getMinDelta()
      }
    }

    // 15 minute steps
    return Math.round((newDuration) / 15) * 15
  }

  render () {
    const {
      sm, isFiltered, isLoading, workspace, templateId, hideShiftsCountSection, readOnly,
      customPositions, customDeleteShiftFunction,
      isCreatingShifts, isDeletingShifts, deleteTemplateShift
    } = this.props
    let { positions, shifts } = this.props
    const minRows = this.props.minRows || 10

    if (!positions || !positions.length) positions = customPositions
    if (!positions || !positions.length) return null

    if (isFiltered) {
      shifts = sortUtil.sortByKey(shifts, 'disabled')
    }

    const cN = ['ds-shifts']
    if (sm) cN.push('is-sm')

    return (
      <>
        <div className={cN.join(' ')}>
          <div className='ds-shifts-head ds-focus-day-template'>
            {HOURS.map((hr) => <div key={hr} className='ds-sh-col'>{(parseInt(hr) % 6 === 0) ? hr : '  '}</div>)}
          </div>
          <div className='ds-shifts-hours'>
            {HOURS.map((hr) => {
              if (sm && hr % 6 !== 0) return null
              return <div key={hr} className='ds-shifts-hour' />
            })}
          </div>
          {shifts.map((sf, i) => {
            const position = positions.find((pos) => pos.id === sf.positionId)
            if (!position) return null
            const color = position.color || colorUtil.idToColor(position.id, positions)
            const start = this.getStart(sf)
            const duration = this.getDuration(sf)
            const isOverflow = start + duration > 24 * 60
            const cN = ['ds-shifts-row']
            const locality = sf.localityId && workspace && workspace.localities
              ? workspace.localities.find(l => l.id === sf.localityId)
              : null

            if (isOverflow) cN.push('is-overflow')
            if (isFiltered && sf.disabled) cN.push('is-disabled')

            return (
              <div
                key={sf.hash + i.toString()}
                className={cN.join(' ')}
                ref={onRowRef}
              >
                <div
                  className='ds-shifts-shift'
                  style={{
                    left: timeUtil.relMin(start),
                    width: timeUtil.relMin(duration),
                    background: `#${color}`
                  }}
                  onClick={(e) => {
                    this.props.onDoubleClick(() => { this.handleOnClick(sf.hash, e) })
                  }}
                >

                  {!isLoading && !readOnly && (
                    <div className='ds-ss-stretch is-forward'>
                      <div
                        className='ds-ss-stretch-drag'
                        draggable
                        onDragStart={(e) => this.handleOnStart(e, sf.hash, 'both')}
                        onDrag={this.handleOnMove}
                        onDragEnd={this.handleOnEnd}
                      />
                    </div>
                  )}
                  {isLoading
                    ? <div className='ds-ss-loading'><Loader size={Loader.SIZES.TINY} /></div>
                    : (
                      <div
                        className='ds-ss-drag'
                        draggable
                        onDragStart={(e) => this.handleOnStart(e, sf.hash, 'start')}
                        onDrag={this.handleOnMove}
                        onDragEnd={this.handleOnEnd}
                      >
                        <div className='ds-ss-hover-tools'>
                          {templateId && (
                            <Button
                              onClick={(e) => {
                                this.handleOnClick(sf.hash, e)
                              }}
                            >
                              <Icon ico='edit' />
                            </Button>
                          )}

                          <Button
                            disabled={isCreatingShifts}
                            onClick={(e) => {
                              // create the copies
                              this.props.createTemplateShift(templateId, {
                                start: sf.start,
                                duration: sf.duration,
                                positionId: sf.positionId,
                                idealSkill: sf.idealSkill,
                                localityId: sf.localityId,
                                pauses: sf.pauses,
                                pausesFixed: sf.pausesFixed,
                                copies: 1,
                                requirementsArrayIndex: i + 1,
                                agenda: sf.agenda
                              })
                            }}
                          >
                            <Icon ico={Icon.ICONS.duplicate} />
                          </Button>

                          <Button
                            disabled={isDeletingShifts}
                            onClick={(e) => {
                              if (customDeleteShiftFunction) {
                                customDeleteShiftFunction(sf.hash)
                              } else {
                                deleteTemplateShift(this.props.templateId, sf)
                              }
                            }}
                          >
                            <Icon ico={Icon.ICONS.delete} />
                          </Button>

                        </div>
                      </div>
                    )}

                  <div className='ds-ss-title'>
                    {position.name}
                    {locality ? ' (' + locality.shortName + ')' : ''}
                  </div>

                  {!isLoading && (
                    <div className='ds-ss-time'>
                      <div className='ds-ss-time-left'>
                        {timeUtil.formatMinutes(start)}
                      </div>

                      <div className='ds-ss-time-mid'>
                        {sf.pauses && sf.pauses.length
                          ? sf.pauses.length === 1
                            ? t('PAUSE_SHORT_X', { dur: sf.pauses[0].duration.toString() + 'm', start: moment('1970-01-01').startOf('day').add(sf.start + sf.pauses[0].start, 'minutes').format('H:mm') })
                            : sf.pauses.length.toString() + ' ' + t('PAUSES_SHORT').toLowerCase()
                          : ''}
                        {sf.pauses && sf.pauses.length === 0 && sf.pausesFixed
                          ? t('PAUSE_NO_PAUSE')
                          : ''}
                      </div>

                      <div className='ds-ss-time-right'>
                        {timeUtil.formatMinutes(start + duration)}
                      </div>
                    </div>
                  )}

                  {!isLoading && !readOnly && (
                    <div className='ds-ss-stretch'>
                      <div
                        className='ds-ss-stretch-drag'
                        draggable
                        onDragStart={(e) => this.handleOnStart(e, sf.hash, 'duration')}
                        onDrag={this.handleOnMove}
                        onDragEnd={this.handleOnEnd}
                      />
                    </div>
                  )}
                </div>

                {!sm && (
                  <div
                    className='ds-shifts-row-add'
                    onClick={(e) => {
                      this.props.setModal('extra-shift', {
                        type: 'template',
                        day: 'day-template',
                        templateId: this.props.templateId,
                        templateAddToRequirementsArrayIdx: i + 1,
                        customPositions,
                        hidePausesSection: !templateId
                      })
                    }}
                  >
                    <Flex align={Flex.POSITION.CENTER}>
                      <Icon ico={Icon.ICONS.plus} size={Icon.SIZES.SMALL} />
                      <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                      <Text text={t('ADD')} type={Text.TYPES.BODY_LARGE} color={Text.COLORS.PRIMARY} />
                    </Flex>
                  </div>)}
              </div>
            )
          })}

          {[...Array(Math.max(0, minRows - shifts.length)).keys()].map((i) => (
            <div
              key={i}
              className='ds-shifts-row'
            >
              <div
                className='ds-shifts-row-add'
                onClick={(e) => {
                  this.props.setModal('extra-shift', {
                    type: 'template',
                    day: 'day-template',
                    templateId: this.props.templateId || undefined,
                    templateAddToRequirementsArrayIdx: shifts.length
                  })
                }}
              >
                {'+ ' + t('ADD')}
              </div>
            </div>
          ))}

        </div>

        {(shifts && shifts.length && !hideShiftsCountSection)
          ? (
            <div className='ds-shifts-shift-counter'>
              <div>{t('SHIFTS_ON_POS')}</div>
              <div className='ds-shifts-counts'>
                {positions.map((pos, i) => {
                  const shiftsCount = shifts.filter((sf) => sf.positionId === pos.id).length
                  if (!shiftsCount) return null
                  return (
                    <div className='ds-shifts-count-badge' key={i} style={{ background: `#${pos.color || colorUtil.idToColor(pos.id, positions)}` }}>
                      <span className='ds-shifts-count-badge-pos'>{pos.name}</span>
                      <span> {shiftsCount}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
          : null}

      </>
    )
  }
}

export default connect(Shifts)
