import isLoading from './is-loading'
import requestWorkspaceEvents from '../request/workspace-events'
import addError from '../ac/add-error'
import setWorkspaceEvents from '../action/set-workspace-events'
import store from '../store'
import moment from 'moment'

export default (variables) => {
  return async (dispatch) => {
    const { auth, workspaceId, workspaceEvents } = store.getState()
    if (!workspaceId) return
    if (!variables.period) return

    dispatch(isLoading('load-workspace-events'))
    let params = { id: workspaceId }
    if (variables) params = Object.assign({}, params, variables)

    // get the events for the period from BE
    let resultEvents = await requestWorkspaceEvents(params, auth)
    if (resultEvents.error) {
      dispatch(addError(resultEvents.error))
      dispatch(isLoading('load-workspace-events', true))
      resultEvents = []
    }

    // and add all the other, previously loaded events to the list
    resultEvents = (resultEvents || []).concat(workspaceEvents.filter(oe => moment(oe.period.end).isBefore(variables.period.start) || moment(oe.period.start).isAfter(variables.period.end)))
    await dispatch(setWorkspaceEvents(resultEvents))

    dispatch(isLoading('load-workspace-events', true))
    return resultEvents
  }
}
