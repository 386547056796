import React from 'react'
import { t } from 'i18next'

import {
  Text,
  Flex,
  Spacing,
  Icon,
  Alert,
  Select
} from '@ui'

import connect from './connect'

export const Autoplanning = connect((
  {
    workspace,
    locality,
    isInvalidStart,
    isInvalidEnd,
    isPlanningDisabled,
    withoutPosition,
    validShifts,
    cb
  }) => {
  const isLocSelectVisible = workspace?.plugins.find(p => p.plugin === 'localities' && p.enabled && workspace?.localities?.length)
  const localityOptions = isLocSelectVisible
    ? [
      { value: 'any', label: t('PLAN_SETUP_MODAL_LOCALITIES_ANY') },
      { value: 'no', label: t('GLOBAL_NO_LOCALITY') }
    ].concat(workspace.localities.map(l => { return { value: l.id, label: '(' + l.shortName + ') ' + l.name } }))
    : []

  return (
    <Flex direction={Flex.DIRECTION.COLUMN}>
      <Flex align={Flex.POSITION.CENTER}>
        <Icon ico={Icon.ICONS.update} color={Icon.COLORS.PRIMARY} size={Icon.SIZES.SMALL} />
        <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />
        <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>{t('PLAN_SETUP_MODAL_RADIO_AUTO')}</Text>
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_10} />
      {isLocSelectVisible && (
        <div style={{ width: '16.5rem' }}>
          <Select
            options={localityOptions}
            value={locality}
            label={t('LOCALITY')}
            onChange={(val) => {
              cb(val)
            }}
          />
        </div>)}
      {withoutPosition && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_FORBIDDEN_DAY')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {isInvalidStart && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_FORBIDDEN_DAY')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {isInvalidEnd && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SELECT_END_DATE')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {(isPlanningDisabled || validShifts.length === 0) && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_EMPTY_SHIFTS')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {!isPlanningDisabled && !isInvalidStart && !isInvalidEnd && validShifts.length > 0 && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_INFO_AUTO_SHIFTS', { x: validShifts.length })}
            type={Alert.TYPES.INFO}
            size={Alert.SIZES.FULL_WIDTH}
            customIco={Icon.ICONS.tip}
          />
        </>)}
    </Flex>
  )
})
