"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collapse = exports.COLORS = void 0;
const react_1 = __importStar(require("react"));
const _ui_1 = require("@ui");
require("./style.scss");
var COLORS;
(function (COLORS) {
    COLORS["DEFAULT"] = "DEFAULT";
    COLORS["PRIMARY"] = "PRIMARY";
})(COLORS = exports.COLORS || (exports.COLORS = {}));
const Collapse = ({ label = '', color = COLORS.PRIMARY, classname = '', children = null, }) => {
    const [isVisible, setIsVisible] = (0, react_1.useState)(false);
    return (react_1.default.createElement("div", { className: `ds-collapse ${isVisible ? 'is-visible' : ''} ${classname !== null && classname !== void 0 ? classname : ''}` },
        react_1.default.createElement("div", { className: `ds-collapse-title ${(color === null || color === void 0 ? void 0 : color.toLowerCase()) || ''}`, onClick: () => setIsVisible(!isVisible) },
            react_1.default.createElement("span", null, label),
            react_1.default.createElement(_ui_1.Icon, { color: color === COLORS.PRIMARY ? _ui_1.Icon.COLORS.PRIMARY : _ui_1.Icon.COLORS.BLACK, ico: isVisible ? _ui_1.Icon.ICONS.arrowUp : _ui_1.Icon.ICONS.arrowDown })),
        isVisible && (react_1.default.createElement(react_1.default.Fragment, null, children))));
};
exports.Collapse = Collapse;
Collapse.COLORS = COLORS;
