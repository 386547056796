import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationTransferGroupDelete(
  $organization: ID!,
  $id: ID!
) {
  organizationTransferGroupDelete(
    organization: $organization,
    id: $id) {
      id
    }
}`

async function requestOrganizationTransfersGroupDelete (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationTransferGroupDelete
}

export default requestOrganizationTransfersGroupDelete
