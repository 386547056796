import isLoading from './is-loading'
import store from '../store'
import i18nConfig from '@app/util/i18n'
import momentUtil from '@app/util/moment'
import setLocale from '@app/action/set-locale'

export default (newLocale) => {
  return async (dispatch) => {
    const { locale } = store.getState()

    // no localeCode passed - preload from store
    if (!newLocale) {
      newLocale = locale
    }

    dispatch(isLoading('set-locale'))

    const code = await i18nConfig(newLocale)
    await momentUtil.setLocale(newLocale)
    dispatch(setLocale(code))

    dispatch(isLoading('set-locale', true))
  }
}
