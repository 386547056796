import isLoading from './is-loading'
import addError from './add-error'
import setAssistantConversation from '../action/set-assistant-conversation'
import store from '../store'

import fetchRequest from '../util/fetch'
import { AssistantConversation } from '@app/queries'

export default (variables) => {
  return async (dispatch) => {
    const { workspaceId, me } = store.getState()
    if (!workspaceId || !me || !me.id) return

    dispatch(isLoading('load-assistant-conversation'))

    const res = await fetchRequest({
      query: AssistantConversation,
      variables: {
        workspace: workspaceId,
        user: me.id
      }
    })

    if (res?.errors?.length) {
      res.errors.forEach(error => {
        dispatch(addError(error))
      })
      dispatch(isLoading('load-assistant-conversation', true))
    } else {
      dispatch(setAssistantConversation(res?.data?.role?.assistantConversation))
    }

    dispatch(isLoading('load-assistant-conversation', true))
    return res?.data?.role?.assistantConversation
  }
}
