import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestLocalityDelete from '../request/locality-delete'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'

export default (localityId) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !localityId) return

    dispatch(isLoading('delete-locality'))

    let result = null
    result = await requestLocalityDelete({
      id: localityId,
      workspace: workspaceId
    }, auth)

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('delete-locality', true))
    return result
  }
}
