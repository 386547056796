import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import createShift from '@app/ac/create-shift'
import setCalendarMultiSelect from '@app/ac/set-calendar-multi-select'
import setCalendar from '@app/ac/set-calendar'
import setCycleDetailCalendar from '@app/ac/set-cycle-detail-calendar'
import setCycleDetailLastSaved from '@app/ac/set-cycle-detail-last-saved'
import setCycleDetail from '@app/ac/set-cycle-detail'

const mapStateToProps = ({ workspaceEvents, positions, holidays, calendarSearch, calendarMultiSelect, workspaces, workspaceId, loading, cycleDetail, employees }) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    calendarSearch,
    calendarMultiSelect,
    workspaceEvents,
    positions,
    holidays,
    workspace: ws,
    cycleDetail,
    isCreatingShiftFromContextMenu: loading?.includes('create-shift'),
    isCreatingEventsFromMultiSelect: loading?.includes('create-shifts') || loading?.includes('set-availability-or-time-off'),
    employees
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    setCalendarMultiSelect,
    setCalendar,
    setCycleDetail,
    setCycleDetailLastSaved,
    setCycleDetailCalendar,
    createShift
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
