import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestWarnings (variables, token) {
  const query = `query WarningsQuery (
    $id: ID!,
    $period: PeriodInput!,
    $users: [ID!]
  ) {
    workspace(
      id: $id
    ) {
      eventWarnings (
        period: $period
        users: $users
      ) {
        userId,
        date,
        eventType,
        userName,
        eventId,
        warnings ${q.EVENT_WARNING}
      }
    }
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('warnings', errors)
  }
  return data.workspace.eventWarnings
}

export default requestWarnings
