import { errorUtil, fetchRequest } from '@app/util'

const query = `mutation AreaDeleteMutation(
  $organization: ID!,
  $area: ID!
) { 
  areaDelete (
    organization: $organization
    area: $area
  )
}`

async function requestAreaDelete (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data
}

export default requestAreaDelete
