import isLoading from './is-loading'
import setCalendar from '../action/set-calendar'
import store from '../store'
import {
  loadChangedShiftsCount,
  loadShifts,
  loadRoleStatsMulti,
  loadOffers,
  loadAvailabilitiesAndTimeOffs,
  loadWorkspaceEvents,
  loadHolidays,
  loadExternalEmployees,
  loadEmployees
} from '@app/ac'
import {
  calendarUtil,
  miscUtil,
  routeUtil
} from '@app/util'
import requestWorkspaceRolesArchived from '@app/request/workspace/roles-archived'
import { NO_LOCALITY } from '@app/const/globals'
import moment from 'moment'

export default (update, forceReloadAll) => {
  return async (dispatch) => {
    // if workspace details are being loaded in the background, wait for it to finish - we need some WS data
    // below - especially 'Workspace.plugins' and 'Workspace.enforceLocalities'
    let { loading, auth } = store.getState()
    if (loading.includes('workspace-detail')) {
      let w = 0
      const maxW = 60
      while (loading.includes('workspace-detail') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
    }

    const { workspaces, workspaceId } = store.getState()
    const ws = workspaces.find(w => w.id === workspaceId)

    let { calendar, calendarFilters } = store.getState()
    calendar = calendar || {}

    const dateIsUpdated = update.date && calendar.date && !moment(update.date).isSame(calendar.date, 'day')
    calendar = Object.assign({}, calendar, update)

    // save the new calendar date in browser's storage - it will be
    // used as a default date when user logs in again
    if (calendar.date && moment(calendar.date).isValid()) {
      window.localStorage.setItem('ds-calendar-date', moment(calendar.date).format('YYYY-MM-DD'))
    }

    // also save the calendar's displayStatistics, displayCapacities, displayRowTemplates, displayRowUnassigned and displayRowOffers
    // props in browser's storage, so the open capacities/statistics persist
    // when the page is refreshed
    if (typeof update.displayStatistics !== typeof undefined) window.localStorage.setItem('ds-calendar-statistics', update.displayStatistics)
    if (typeof update.displayCapacities !== typeof undefined) window.localStorage.setItem('ds-calendar-capacities', update.displayCapacities)
    if (typeof update.displayRowTemplates !== typeof undefined) window.localStorage.setItem('ds-calendar-row-templates', update.displayRowTemplates)
    if (typeof update.displayRowUnassigned !== typeof undefined) window.localStorage.setItem('ds-calendar-row-unassigned', update.displayRowUnassigned)
    if (typeof update.displayRowOffers !== typeof undefined) window.localStorage.setItem('ds-calendar-row-offers', update.displayRowOffers)

    if (typeof calendar.displayStatistics === typeof undefined) calendar.displayStatistics = ([true, 'true'].includes(window.localStorage.getItem('ds-calendar-statistics')))
    if (typeof calendar.displayCapacities === typeof undefined) calendar.displayCapacities = ([true, 'true'].includes(window.localStorage.getItem('ds-calendar-capacities')))
    if (typeof calendar.displayRowTemplates === typeof undefined) calendar.displayRowTemplates = (![false, 'false'].includes(window.localStorage.getItem('ds-calendar-row-templates'))) // this one is true by default
    if (typeof calendar.displayRowUnassigned === typeof undefined) calendar.displayRowUnassigned = (![false, 'false'].includes(window.localStorage.getItem('ds-calendar-row-unassigned'))) // this one is true by default
    if (typeof calendar.displayRowOffers === typeof undefined) calendar.displayRowOffers = (![false, 'false'].includes(window.localStorage.getItem('ds-calendar-row-offers'))) // this one is true by default

    dispatch(isLoading('set-calendar'))
    const calendarPeriod = calendarUtil.getReloadPeriod(calendar)

    // find out if we need to load data and for which period
    if (!calendar.loadedPeriods) calendar.loadedPeriods = []
    let needToLoadPeriod = null
    let foundInLoadedPeriods = false
    if (calendarPeriod) {
      for (var i = 0; i < calendar.loadedPeriods.length; i++) {
        const per = calendar.loadedPeriods[i]
        // calendarPeriod is inside the period 'per' -> reload is not needed
        if (moment(per.start).isSameOrBefore(calendarPeriod.start) && moment(per.end).isSameOrAfter(calendarPeriod.end)) {
          needToLoadPeriod = null
          foundInLoadedPeriods = true
          break
        }
        // calendarPeriod covers the whole 'per' -> extend 'per' and reload it
        if (moment(per.start).isAfter(calendarPeriod.start) && moment(per.end).isBefore(calendarPeriod.end)) {
          calendar.loadedPeriods[i] = Object.assign({}, calendarPeriod)
          needToLoadPeriod = Object.assign({}, calendarPeriod)
          foundInLoadedPeriods = true
          break
        }
        // calendarPeriod overlaps period 'per' -> we extend 'per' and reload the new part only
        if (moment(per.start).isBefore(calendarPeriod.end) && moment(per.start).isAfter(calendarPeriod.start)) {
          calendar.loadedPeriods[i] = Object.assign({}, { start: calendarPeriod.start, end: per.end })
          needToLoadPeriod = Object.assign({}, { start: calendarPeriod.start, end: per.start })
          foundInLoadedPeriods = true
          break
        }
        if (moment(per.end).isAfter(calendarPeriod.start) && moment(per.end).isBefore(calendarPeriod.end)) {
          calendar.loadedPeriods[i] = Object.assign({}, { start: per.start, end: calendarPeriod.end })
          needToLoadPeriod = Object.assign({}, { start: per.end, end: calendarPeriod.end })
          foundInLoadedPeriods = true
          break
        }
      }
    }
    if (!foundInLoadedPeriods && calendarPeriod) {
      calendar.loadedPeriods.push(calendarPeriod)
      needToLoadPeriod = calendarPeriod
    }

    // if forceReloadAll, just replace all loaded periods with one big period and set 'needToLoadPeriod' to that
    if (forceReloadAll) {
      const wholePeriod = { start: null, end: null }
      calendar.loadedPeriods.forEach(per => {
        if (!wholePeriod.start || moment(per.start).isBefore(wholePeriod.start)) wholePeriod.start = per.start
        if (!wholePeriod.end || moment(per.end).isAfter(wholePeriod.end)) wholePeriod.end = per.end
      })
      if (wholePeriod.start && wholePeriod.end) {
        needToLoadPeriod = wholePeriod
        calendar.loadedPeriods = [wholePeriod]
      }
    }

    // update the store.calendar
    if (miscUtil.safeStringify(store.getState().calendar) !== miscUtil.safeStringify(calendar)) {
      await dispatch(setCalendar(calendar))
    } else {
      console.log('Warning! Unnecessary set-calendar call:', update, '=>', calendar)
    }

    // load the shifts and unavs for calendar if this period wasn't loaded before
    if (needToLoadPeriod) {
      // on very big workspaces, we 'enforce a locality' - we only loaded the employees with the
      // same locality as our logged manager has and here we want to only get their shifts (nobody else's).
      // we check if we should enforce the locality here.
      const enforcedLocality = await miscUtil.getEnforcedLocality()

      // we don't want to load warnings if they're hidden via calendarFilters
      const warningsHidden = calendarFilters && calendarFilters.length && calendarFilters.find(fil => fil.hideWarnings === 'all' || fil.hideWarnings === true)

      // we need employees to be loaded for some logic below, so we ensure that here
      let { employees } = store.getState()
      if (!employees || !Object.keys(employees).length) {
        employees = await dispatch(loadEmployees())
      }
      const idsOfEmpsWithEnforcedLocality = enforcedLocality
        ? enforcedLocality === NO_LOCALITY
          ? Object.values(employees).filter(ee => ee.localities && !ee.localities.length).map(ee => ee.id)
          : Object.values(employees).filter(ee => ee.localities && ee.localities.includes(enforcedLocality)).map(ee => ee.id)
        : null

      const idsOfEmpsWithEnforcedLocalityIncludingArchived = idsOfEmpsWithEnforcedLocality ? [...idsOfEmpsWithEnforcedLocality] : []
      if (enforcedLocality) {
        // on locality-based workspaces, we also include all the archived roles, so we can
        // load the shifts & timeOffs for them also (we don't need this on non-locality-based,
        // since we don't filter shifts/timeOffss queries by userIds there)
        const archivedRoles = await requestWorkspaceRolesArchived({
          workspaceId,
          users: undefined
        }, auth)
        if (archivedRoles?.length) {
          archivedRoles.forEach(r => {
            if (!idsOfEmpsWithEnforcedLocality.includes(r.userId)) idsOfEmpsWithEnforcedLocalityIncludingArchived.push(r.userId)
          })
        }
      }

      // load the shifts
      const resultShifts = await dispatch(loadShifts({
        period: needToLoadPeriod,
        users: (enforcedLocality
          ? [null, ...idsOfEmpsWithEnforcedLocalityIncludingArchived]
          : undefined
        ),
        warningsHidden: warningsHidden,
        locality: enforcedLocality || undefined,
        forManagerCalendar: true
      }))

      // load (un)availabilities and timeOffs
      let resultUnavsOrTimeOffs = []
      if (enforcedLocality) {
        resultUnavsOrTimeOffs = await dispatch(loadAvailabilitiesAndTimeOffs(needToLoadPeriod, {
          usersForTimeOffs: idsOfEmpsWithEnforcedLocalityIncludingArchived,
          usersForTimeAvailabilities: idsOfEmpsWithEnforcedLocality
        }))
      } else {
        resultUnavsOrTimeOffs = await dispatch(loadAvailabilitiesAndTimeOffs(needToLoadPeriod, {
          users: undefined
        }))
      }

      // we'll also load external employees if needed:
      let needToLoadExternalEmployees = null

      // if we loaded some shifts or timeOffs assigned to an employee that isn't in our employees list...
      let allLoadedEvents = []
      if (Array.isArray(resultShifts)) allLoadedEvents = allLoadedEvents.concat(resultShifts)
      if (Array.isArray(resultUnavsOrTimeOffs)) allLoadedEvents = allLoadedEvents.concat(resultUnavsOrTimeOffs.filter(evt => !!evt.categoryId /* timeOffs only */))
      const eventsWithUnknownEmps = (allLoadedEvents?.length && employees && Object.keys(employees).length)
        ? allLoadedEvents.filter(s => s.userId && !employees[s.userId])
        : []

      // (case 1: locality is NOT enforced)
      // ...we need to just load external emps for the events of unknown people
      if (!enforcedLocality && eventsWithUnknownEmps.length) {
        needToLoadExternalEmployees = { loadForEvents: eventsWithUnknownEmps }
      }

      // (case 2: locality enforced)
      // we first need to load additional internal employees with those specific IDs and if we still don't have everyone, only then we'll load the external emps.
      // explanation: if enforcedLocality == true, we called loadShifts() with explicitly specified list of users from our locality + archived users,
      // which means that we might be missing some internal employees
      if (enforcedLocality && eventsWithUnknownEmps.length) {
        const unknownEmpIds = [...new Set(eventsWithUnknownEmps.map(s => s.userId))]
        const loadedUnknownEmps = await dispatch(loadEmployees({ userIds: unknownEmpIds, locality: null }))

        const remainingEventsWithUnknownEmp = eventsWithUnknownEmps.filter(s => !Object.keys(loadedUnknownEmps || {}).includes(s.userId))
        if (remainingEventsWithUnknownEmp.length) {
          needToLoadExternalEmployees = {
            loadForEvents: remainingEventsWithUnknownEmp
          }
        }
      }

      // actually load the external emps
      if (needToLoadExternalEmployees) {
        const resultExtEmps = await dispatch(loadExternalEmployees(needToLoadExternalEmployees))
        // if we got some external employees here due to 'enforcedLocality' being true, we need to also load their shifts
        if (enforcedLocality && resultExtEmps && resultExtEmps.length) {
          await dispatch(loadShifts({ period: needToLoadPeriod, users: resultExtEmps.map(u => u.id), warningsHidden: true }))
        }
      }
    }

    // load employees' role stats for the period, such as planned vs. contracted work hours
    if (dateIsUpdated || (calendar.date && !calendar.roleStats)) {
      dispatch(loadRoleStatsMulti(calendar.date))
    }

    // load changedShiftsCount if it's still undefined
    if (calendar.date && (typeof calendar.changedShiftsCount === 'undefined') && !store.getState()?.loading?.includes('load-changed-shifts-count')) {
      dispatch(loadChangedShiftsCount())
    }

    // load workspace events if this period wasn't loaded before
    if (needToLoadPeriod) {
      dispatch(loadWorkspaceEvents({ type: null, period: needToLoadPeriod }))
    }

    // load the offers on WS
    if (calendarPeriod && (needToLoadPeriod || dateIsUpdated) && ws && ws.plugins && ws.plugins.find(p => p.plugin === 'offers' && p.enabled)) {
      dispatch(loadOffers(calendarPeriod))
    }

    // load the holidays for calendar if this period wasn't loaded before
    if (needToLoadPeriod) {
      dispatch(loadHolidays({ period: needToLoadPeriod }))
    }

    // and update the url route accordingly in case of /schedule subpage
    if (window.location.pathname.includes('/schedule') && calendar.view && calendar.date) {
      routeUtil.navigate('/schedule/' + calendar.view + '/' + moment(calendar.date).format('YYYY-MM-DD'))
    }

    dispatch(isLoading('set-calendar', true))
  }
}
