import setOrganizationId from '../action/set-organization-id'
import isLoading from './is-loading'

export default (orgId) => {
  return async (dispatch) => {
    await dispatch(isLoading('set-organization-id'))
    await dispatch(setOrganizationId(orgId || null))
    await dispatch(isLoading('set-organization-id', true))
    return orgId
  }
}
