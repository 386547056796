import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation WorkspaceCreateMutation(
  $organization: ID!
  $area: ID!
  $email: String!
  $name: String
  $customId: String
) {
  workspaceCreate (
    organization: $organization
    area: $area
    managerEmail: $email
    data: {
      name: $name
      customId: $customId
    }
  ) {
    id
    name
  }
}`

async function requestWorkspaceCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('something went wrong', errors)
  }
  return data.workspaceCreate
}

export default requestWorkspaceCreate
