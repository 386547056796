import {
  AnnounceShiftAction,
  UpdateAnnounceShiftFromAction,
  UpdateAnnounceShiftToAction,
  UpdateAnnounceShiftByPositionAction,
  UpdateAnnounceShiftByLocalityAction,
  UpdateAnnounceShiftByPeopleAction,
  RevertAnnounceShiftAction
} from '../action/announce-shifts'

export const AnnounceShift = (data = {}) => {
  return async (dispatch) => {
    await dispatch(AnnounceShiftAction(data))
  }
}

export const UpdateAnnounceShiftFrom = (data = {}) => {
  return async (dispatch) => {
    await dispatch(UpdateAnnounceShiftFromAction(data))
  }
}

export const UpdateAnnounceShiftTo = (data = {}) => {
  return async (dispatch) => {
    await dispatch(UpdateAnnounceShiftToAction(data))
  }
}

export const UpdateAnnounceShiftByPosition = (data = {}) => {
  return async (dispatch) => {
    await dispatch(UpdateAnnounceShiftByPositionAction(data))
  }
}

export const UpdateAnnounceShiftByLocality = (data = {}) => {
  return async (dispatch) => {
    await dispatch(UpdateAnnounceShiftByLocalityAction(data))
  }
}

export const UpdateAnnounceShiftByPeople = (data = {}) => {
  return async (dispatch) => {
    await dispatch(UpdateAnnounceShiftByPeopleAction(data))
  }
}

export const RevertAnnounceShift = (data = {}) => {
  return async (dispatch) => {
    await dispatch(RevertAnnounceShiftAction(data))
  }
}
