import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestEmployeeDetail (variables, reload, token) {
  const userShort = `user {
      inviteExisting
      notificationPreferences {
        channels {
          app {
            enabled
            origins {
              name
              enabled
            }
          }
          email {
            enabled
            origins {
              name
              enabled
            }
          }
          mobile {
            enabled
            origins {
              name
              enabled
            }
          }
        }
      }
      hasPassword
      language
    }
  `
  const userComplete = `user {
      id
      email
      firstName
      lastName
      name
      telephone
      inviteExisting
      accessRoles {
        id
        name
        workspaceId
      }
      notificationPreferences {
        channels {
          app {
            enabled
            origins {
              name
              enabled
            }
          }
          email {
            enabled
            origins {
              name
              enabled
            }
          }
          mobile {
            enabled
            origins {
              name
              enabled
            }
          }
        }
      }
      hasPassword
      language
      dummy
      workspaces {
        id
        employeeWarnings(users: [$user]) {
          warnings {
            type
            data
          }
          userId
        }
      }
    }
  `
  const query = `query EmployeeDetail($workspace: ID!, $user: ID!) {
  role(workspace: $workspace, user: $user) {
    customData {
      lastName
      firstName
      address
      telephone
      note
      employeeId
      kmToWork
    }
    birthdate
    contracts {
      type
      period {
        start
        end
      }
      options
      id
      default
      contractId
      compiled {
        rules
        options
      }
      positions {
        ${q.POSITION_ASSIGNMENT}
      }
    }
    customAttributes {
      attributeId
      value
    }
    vacationDays
    calendarOrder
    terminateDate
    terminalPIN
    hidden
    cycleId
    cycleGroup
    contract:contractNew ${q.CONTRACT}
    ${reload ? userComplete : userShort}
  }
}`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    errUtil.apiError('role', errors)
  }
  return data.role
}

export default requestEmployeeDetail
