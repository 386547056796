import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation PositionAssignDeleteMutation (
  $workspace: ID!,
  $user: ID!,
  $position: ID!
  $contract: ID!
) {
  positionAssignDelete (
    workspace: $workspace
    user: $user
    position: $position
    contract: $contract
  )
}`

async function requestPositionAssignDelete (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('position-assign-delete', errors)
  }
  return data.positionAssignDelete
}

export default requestPositionAssignDelete
