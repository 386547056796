import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withPlugin } from '@core/hoc/withPlugin'
import { setModal } from '@app/ac'

const mapStateToProps = ({
  auth,
  me,
  workspaceId,
  workspaces,
  organization,
  organizationId,
  calendar
}) => {
  const workspace = workspaces?.find(w => w.id === workspaceId)
  const isOrgOwner = Boolean(organizationId && me?.acl?.organizations && Object.keys(me.acl.organizations).includes(organizationId) && me.acl.organizations[organizationId].includes('organization_owner'))
  return {
    auth,
    me,
    subscription: organization?.settings?.billing,
    isOrgHidden: me.organizations && me.organizations.find((organization) => organization.id === organizationId)?.hidden,
    organization,
    workspaceId,
    workspace,
    isOrgOwner
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
