import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

async function requestUpdateWarnings (variables, token) {
  const query = `mutation WarningsUpdateMutation (
    $workspaceId: ID!,
    $period: PeriodInput!,
    $user: ID
  ) {
    warningsPushRequest(
      workspace: $workspaceId
      period: $period
      user: $user
    )
  }`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('warnings-update', errors)
  }
  return data
}

export default requestUpdateWarnings
