import requestEmployeeWarnings from '../request/employee-warnings'
import isLoading from './is-loading'
import addError from '../ac/add-error'
import setEmployees from '../action/set-employees'
import store from '../store'

export default (variables) => {
  return async (dispatch) => {
    const { auth, workspaceId, calendarFilters } = store.getState()
    let { employees } = store.getState()
    if (!workspaceId) return

    // filter out the external employees - we don't want to compute emp. warnings for them
    const variablesFiltered = variables.users
      ? Object.assign({}, variables, { users: variables.users.filter(uid => employees[uid] && !employees[uid].external) })
      : variables

    dispatch(isLoading('load-employee-warnings'))
    const result = await requestEmployeeWarnings(Object.assign({ workspaceId }, variablesFiltered), auth)
    if (result.error) {
      dispatch(addError(result.error))
      dispatch(isLoading('load-employee-warnings', true))
      return
    }

    employees = store.getState().employees
    const newEmps = Object.assign({}, employees)
    const hiddenWarnings = calendarFilters.find(fil => fil.hideWarnings) || { hideWarnings: [] }
    Object.keys(newEmps).forEach(uid => {
      const res = result.find(ew => ew.userId === uid)
      const allUnhiddenWarnings = res ? res.warnings.filter(ew2 => !hiddenWarnings || !hiddenWarnings.hideWarnings || !hiddenWarnings.hideWarnings.includes(ew2.type)) : []
      if (res) {
        newEmps[uid] = Object.assign({}, newEmps[uid], { employeeWarnings: allUnhiddenWarnings })
      }
    })
    await dispatch(setEmployees(newEmps))

    dispatch(isLoading('load-employee-warnings', true))
  }
}
