import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import loadPositions from '@app/ac/load-positions'
import loadEmployees from '@app/ac/load-employees'
import savePosition from '@app/ac/save-position'
import deletePosition from '@app/ac/delete-position'
import setModal from '@app/ac/set-modal'
import { withPlugin } from '@core/hoc/withPlugin'

const mapStateToProps = ({ employees, errors, positions, workspaces, workspaceId }) => {
  return {
    employees,
    errors: errors,
    positions,
    workspace: workspaces.find((ws) => ws.id === workspaceId)
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadPositions,
    loadEmployees,
    savePosition,
    deletePosition,
    setModal
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
