function getShortcut (string, toUpperCase = false) {
  const shortcut = string
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')

  if (toUpperCase) {
    return shortcut.toUpperCase()
  }
  return shortcut
}

function getCapitalizeWord (string) {
  if (string) return string[0].toUpperCase() + string.substring(1)
  return ''
}

export default { getShortcut, getCapitalizeWord }
