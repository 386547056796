import './index.scss'
import React from 'react'
import connect from './connect'
import moment from 'moment'
import { Button, Icon } from '@ui'

class DaySelect extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selected: [],
      displayedMonth: (this.props.value && moment(this.props.value).isValid())
        ? moment(this.props.value).format('YYYY-MM')
        : (this.props.values && this.props.values.length && moment(this.props.values[0]).isValid())
          ? moment(this.props.values[0]).format('YYYY-MM')
          : (this.props.calendar && this.props.calendar.date)
            ? moment(this.props.calendar.date).format('YYYY-MM')
            : moment().format('YYYY-MM')
    }
  }

  componentDidMount () {
    if (this.props.value) { // single value passed
      this.setState((s) => Object.assign({}, s, { selected: [moment(this.props.value).format('YYYY-MM-DD')] }))
    } else if (this.props.values) { // list of values passed
      this.setState((s) => Object.assign({}, s, { selected: this.props.values.map(v => moment(v).format('YYYY-MM-DD')) }))
    }

    if (this.props.executeOnMonthSelect) this.props.executeOnMonthSelect(moment(this.state.displayedMonth).format('YYYY-MM-DD'))
  }

  getCalRows () {
    const rows = []
    let i = 0
    let forward = moment(this.state.displayedMonth).add(i, 'weeks').startOf('week')
    let back = moment(this.state.displayedMonth).add(-i, 'weeks').endOf('week')

    while (forward.format('YYYY-MM') === this.state.displayedMonth || back.format('YYYY-MM') === this.state.displayedMonth) {
      const rowBack = []
      const rowForward = []
      for (var j = 0; j < 7; j++) {
        const db = back.clone().startOf('week').add(j, 'days')
        const df = forward.clone().startOf('week').add(j, 'days')
        rowBack.push({
          day: db,
          isSelected: !!this.state.selected.find(s => s === db.format('YYYY-MM-DD')),
          thisMonth: db.format('YYYY-MM') === this.state.displayedMonth,
          isToday: db.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        })
        rowForward.push({
          day: df,
          isSelected: !!this.state.selected.find(s => s === df.format('YYYY-MM-DD')),
          thisMonth: df.format('YYYY-MM') === this.state.displayedMonth,
          isToday: df.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        })
      }
      if (back.format('YYYY-MM') === this.state.displayedMonth) {
        rows.unshift({ days: rowBack, date: back.clone().startOf('week') })
      }
      if (forward.format('YYYY-MM') === this.state.displayedMonth) {
        rows.push({ days: rowForward, date: forward.clone().startOf('week') })
      }

      i += 1
      forward = moment(this.state.displayedMonth).add(i, 'weeks').startOf('week')
      back = moment(this.state.displayedMonth).add(-i, 'weeks').endOf('week')
    }

    // make sure we don't have duplicate weeks in the list
    if (rows.length >= 2 && rows[0].date.format('YYYY-MM-DD') === rows[1].date.format('YYYY-MM-DD')) {
      rows.shift()
    }

    return rows
  }

  toggleDaySelection (dayMoment) {
    // prevent selecting past weeks if props.preventPast
    if (this.props.forbiddenPast && dayMoment.startOf('day').isBefore(moment().startOf('day'))) return

    // set the state
    const curr = this.state.selected.find(s => s === dayMoment.format('YYYY-MM-DD'))
    let newSel = this.state.selected
    if (curr) {
      if (!this.props.singleDaySelection) newSel = newSel.filter(s => s !== curr) // if it's the current choice, turn it off only if singleDaySelection == False, to keep it and prevent Invalid Date state
    } else {
      if (!this.props.singleDaySelection) {
        newSel.push(dayMoment.format('YYYY-MM-DD'))
      } else {
        // if props.singleDaySelection == True, always replace the whole state.selected array
        // with the new day selection instead of adding to it
        newSel = [dayMoment.format('YYYY-MM-DD')]
      }
    }
    this.setState((s) => Object.assign({}, s, { selected: newSel }))

    // onChange(d) call
    if (this.props.onChange) this.props.onChange(newSel)
  }

  render () {
    const { className } = this.props
    const cN = ['ds-day-select']
    if (className) cN.push(className)
    const rows = this.getCalRows()
    let titleTxt = moment(this.state.displayedMonth).format('MMMM YYYY')
    titleTxt = titleTxt.charAt(0).toUpperCase() + titleTxt.slice(1)

    return (
      <div className={cN.join(' ')}>
        <div className='ds-day-select-controls'>
          <Button
            style={Button.STYLES.LIGHT}
            ico={Icon.ICONS.arrowLeft}
            onClick={(e) => {
              const newMonth = moment(this.state.displayedMonth, 'YYYY-MM').add(-1, 'month')
              this.setState((s) => Object.assign({}, s, { displayedMonth: newMonth.format('YYYY-MM') }))

              if (this.props.executeOnMonthSelect) this.props.executeOnMonthSelect(newMonth.format('YYYY-MM-DD'))
              e.stopPropagation()
            }}
          />
          <div className='ds-title'>{titleTxt}</div>
          <Button
            style={Button.STYLES.LIGHT}
            ico={Icon.ICONS.arrowRight}
            onClick={(e) => {
              const newMonth = moment(this.state.displayedMonth, 'YYYY-MM').add(1, 'month')
              this.setState((s) => Object.assign({}, s, { displayedMonth: newMonth.format('YYYY-MM') }))

              if (this.props.executeOnMonthSelect) this.props.executeOnMonthSelect(newMonth.format('YYYY-MM-DD'))
              e.stopPropagation()
            }}
          />
        </div>

        <div className='ds-day-select-cal-weekdays'>
          <div className='ds-day-select-cal-weekdays-day'>{moment().startOf('week').add(0, 'days').format('ddd')}</div>
          <div className='ds-day-select-cal-weekdays-day'>{moment().startOf('week').add(1, 'days').format('ddd')}</div>
          <div className='ds-day-select-cal-weekdays-day'>{moment().startOf('week').add(2, 'days').format('ddd')}</div>
          <div className='ds-day-select-cal-weekdays-day'>{moment().startOf('week').add(3, 'days').format('ddd')}</div>
          <div className='ds-day-select-cal-weekdays-day'>{moment().startOf('week').add(4, 'days').format('ddd')}</div>
          <div className='ds-day-select-cal-weekdays-day'>{moment().startOf('week').add(5, 'days').format('ddd')}</div>
          <div className='ds-day-select-cal-weekdays-day'>{moment().startOf('week').add(6, 'days').format('ddd')}</div>
        </div>

        {rows.map((r, iw) =>
          <div
            key={'week_' + iw.toString()}
            className={['ds-day-select-cal-week', this.state.selected === r.date.format('YYYY-MM-DD') ? 'selected-week' : null].join(' ')}
          >
            {r.days.map((d, id) =>
              <div
                onClick={(e) => {
                  this.toggleDaySelection(d.day)
                  if (!this.props.singleDaySelection) e.stopPropagation()
                }}
                key={'day_' + iw.toString() + '_' + id.toString()}
                className={[
                  'ds-day-select-cal-day',
                  d.thisMonth ? null : 'other-month',
                  d.isToday ? 'today' : null,
                  d.isSelected ? 'is-selected' : null,
                  this.props.forbiddenPast && d.day.startOf('day').isBefore(moment().startOf('day')) ? 'is-past-forbidden' : null,
                  'date_' + moment(d.day).format('YYYY-MM-DD')
                ].join(' ')}
              >
                {moment(d.day).format('D')}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default connect(DaySelect)
