import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationRevenue(
  $organizationId: ID!
  $filter: OrganizationFilter!
  $period: PeriodInput!
) {
  organization(id: $organizationId) {
    revenue(period: $period, filter: $filter) {
      workspaces {
        workspaceId
        date
        amountPlan
        amountReality
        currency
      }
      date
    }
  }
}`

async function requestOrganizationRevenue (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrganizationRevenue
