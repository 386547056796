import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

async function requestWorkspaceLocalities (variables, token) {
  const query = `query Workspace($workspaceId: ID!) {
  workspace(id: $workspaceId) {
    localities {
      assigns {
        userId
      }
      name
      id
      shortName
    }
  }
}`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('workspace', errors)
  }
  return data.workspace.localities
}

export default requestWorkspaceLocalities
