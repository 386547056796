import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import copyShiftPeriod from '@app/ac/copy-shift-period'

const mapStateToProps = ({ calendar, workspaceId, loading }) => {
  return {
    calendar,
    workspaceId,
    isLoading: loading.includes('shift-period-copy')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    copyShiftPeriod
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
