import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  loadEmployees,
  loadShifts,
  assignLocality,
  unassignLocality,
  deleteLocality,
  saveLocality,
  setModal,
  loadWorkspaceDetail
} from '@app/ac'

const mapStateToProps = ({ loading, workspaces, workspaceId, shifts, positions }) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    isLoading: loading.includes('workspace-detail') || loading.includes('load-employees') || loading.includes('load-shifts') || loading.includes('assign-locality'),
    workspace: ws,
    shifts,
    positions
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    loadEmployees,
    loadShifts,
    saveLocality,
    assignLocality,
    unassignLocality,
    deleteLocality,
    loadWorkspaceDetail
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
