"use strict";
// Borrowed from https://github.com/react-csv/react-csv/blob/master/src/core.js
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildURI = exports.toCSV = exports.string2csv = exports.jsons2csv = exports.arrays2csv = exports.joiner = exports.elementOrEmpty = exports.getHeaderValue = exports.jsons2arrays = exports.removeNewLines = exports.jsonsHeaders = exports.isArrays = exports.isJsons = exports.isSafari = void 0;
/**
 * Simple safari detection based on user agent test
 */
const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
exports.isSafari = isSafari;
const isJsons = (array) => Array.isArray(array) && array.every(row => (typeof row === 'object' && !(row instanceof Array)));
exports.isJsons = isJsons;
const isArrays = (array) => Array.isArray(array) && array.every(row => Array.isArray(row));
exports.isArrays = isArrays;
const jsonsHeaders = (array) => Array.from(array.map(json => Object.keys(json))
    .reduce((a, b) => new Set([...a, ...b]), []));
exports.jsonsHeaders = jsonsHeaders;
const removeNewLines = (input) => input.replace(/(\r\n|\n|\r)/gm, ' ');
exports.removeNewLines = removeNewLines;
const jsons2arrays = (jsons, headers) => {
    headers = headers || (0, exports.jsonsHeaders)(jsons);
    // allow headers to have custom labels, defaulting to having the header data key be the label
    let headerLabels = headers;
    let headerKeys = headers;
    if ((0, exports.isJsons)(headers)) {
        headerLabels = headers.map((header) => (0, exports.removeNewLines)(header.label));
        headerKeys = headers.map((header) => header.key);
    }
    const data = jsons.map((object) => headerKeys.map((header) => (0, exports.getHeaderValue)(header, object)));
    return [headerLabels, ...data];
};
exports.jsons2arrays = jsons2arrays;
const getHeaderValue = (property, obj) => {
    const foundValue = property
        .replace(/\[([^\]]+)]/g, '.$1')
        .split('.')
        .reduce((o, p, _, arr) => {
        // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
        const value = o[p];
        if (value === undefined || value === null) {
            arr.splice(1);
        }
        else {
            return value;
        }
        return undefined;
    }, obj);
    // if at any point the nested keys passed do not exist then looks for key `property` in object obj
    return (foundValue === undefined) ? ((property in obj) ? obj[property] : '') : foundValue;
};
exports.getHeaderValue = getHeaderValue;
const elementOrEmpty = (element) => (typeof element === 'undefined' || element === null) ? '' : element;
exports.elementOrEmpty = elementOrEmpty;
const joiner = (data, separator = ',', enclosingCharacter = '"') => {
    return data
        .filter(e => e)
        .map(row => row
        .map((element) => (0, exports.elementOrEmpty)(element))
        .map(column => `${enclosingCharacter}${column}${enclosingCharacter}`)
        .join(separator))
        .join('\n');
};
exports.joiner = joiner;
const arrays2csv = (data, headers, separator, enclosingCharacter) => (0, exports.joiner)(headers ? [headers, ...data] : data, separator, enclosingCharacter);
exports.arrays2csv = arrays2csv;
const jsons2csv = (data, headers, separator, enclosingCharacter) => (0, exports.joiner)((0, exports.jsons2arrays)(data, headers), separator, enclosingCharacter);
exports.jsons2csv = jsons2csv;
const string2csv = (data, headers, separator) => (headers) ? `${headers.join(separator)}\n${data}` : data.replace(/"/g, '""');
exports.string2csv = string2csv;
const toCSV = (data, headers, separator, enclosingCharacter) => {
    if ((0, exports.isJsons)(data))
        return (0, exports.jsons2csv)(data, headers, separator, enclosingCharacter);
    if ((0, exports.isArrays)(data))
        return (0, exports.arrays2csv)(data, headers, separator, enclosingCharacter);
    if (typeof data === 'string')
        return (0, exports.string2csv)(data, headers, separator);
    throw new TypeError('Data should be a "String", "Array of arrays" OR "Array of objects" ');
};
exports.toCSV = toCSV;
const buildURI = (data, uFEFF, headers, separator, enclosingCharacter) => {
    const csv = (0, exports.toCSV)(data, headers, separator, enclosingCharacter);
    const type = (0, exports.isSafari)() ? 'application/csv' : 'text/csv';
    const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type });
    const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;
    const URL = window.URL || window.webkitURL;
    return (typeof URL.createObjectURL === 'undefined')
        ? dataURI
        : URL.createObjectURL(blob);
};
exports.buildURI = buildURI;
