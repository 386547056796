import addFresh from '../action/add-fresh'

export default (type, id) => {
  return async (dispatch) => {
    dispatch(addFresh({
      type,
      id
    }))
  }
}
