import isLoading from './is-loading'
import addError from '../ac/add-error'
import requestShift from '@app/request/shift'
import setShiftDetail from '../action/set-shift-detail'
import store from '../store'

export default (shift, includeCandidates = true, includeOffer = false) => {
  return async (dispatch) => {
    const { auth, shifts } = store.getState()
    let { loading } = store.getState()

    // if load-employees is already running, try waiting for it to finish first
    if (loading.includes('load-shift-detail')) {
      let w = 0
      const maxW = 10
      while (loading.includes('load-shift-detail') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      if (w < maxW) return store.getState().shiftDetail
    }

    dispatch(isLoading('load-shift-detail'))

    // if we have this already loaded, use it
    let found = null
    let result = null
    if (!includeCandidates && !includeOffer && shifts && shifts.length) {
      found = shifts.find((s) => s.id === shift)
    }

    if (found) {
      dispatch(setShiftDetail(found))
      result = found
    } else {
      // otherwise, make an API request
      result = await requestShift({ shift }, auth, {
        withCandidates: includeCandidates
      })
      if (result) {
        if (result.error) {
          dispatch(addError(result.error))
        } else {
          dispatch(setShiftDetail(result))
        }
      }
    }

    dispatch(isLoading('load-shift-detail', true))
    return result
  }
}
