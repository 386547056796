import isLoading from './is-loading'
import addError from './add-error'
import requestSendMfaFactor from '@app/request/send-mfa-factor'

export default (params) => {
  return async (dispatch) => {
    dispatch(isLoading('send-mfa-factor'))
    const result = await requestSendMfaFactor(params)
    if (result.error) {
      dispatch(addError(result.error))
    }

    dispatch(isLoading('send-mfa-factor', true))
    return result
  }
}
