import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

const query = `mutation ShiftUpdateMutation (
  $workspace: ID!,
  $shift: ID!,
  $data: ShiftUpdateData!
) {
  shiftUpdate (
    workspace: $workspace
    id: $shift
    data: $data
  ) ${q.SHIFT_BASIC}
}`

async function requestShiftUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-update', errors)
  }
  return data.shiftUpdate
}

export default requestShiftUpdate
