import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (state = initial.locale, action) => {
  switch (action.type) {
    case aT.SET_LOCALE:
      return action.data
    default:
      return state
  }
}
