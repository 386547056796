"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actionType = {
    ADD_ERROR: 'add-error',
    ADD_LOADING: 'add-loading',
    ADD_SUCCESS: 'add-success',
    SET_AUTH: 'set-auth',
    SET_ME: 'set-me',
    SET_VARIABLES: 'set-variables',
    SET_CLIENT_METRICS: 'set-client-metrics',
    SET_WORKSPACES: 'set-workspaces',
    SET_WORKSPACE_ID: 'set-workspace-id',
    SET_ORGANIZATION_ID: 'set-organization-id',
    SET_EMPLOYEES: 'set-employees',
    RESET_STORE: 'reset-store',
    RESET_ERRORS: 'reset-errors',
    SET_INDUSTRIES: 'set-industries',
    SET_LOCATION: 'set-location',
    SET_SORT: 'set-sort',
    SET_SIDEBAR: 'set-sidebar',
    SET_LOCALE: 'set-locale',
    SET_ENFORCED_LOCALITY: 'set-enforced-locality',
    FINISHED_LOADING: 'finished-loading',
    CLEAR_SUCCESS: 'clear-success',
    SET_MODAL: 'set-modal',
    SET_EMPLOYEE_DETAIL: 'set-employee-detail',
    SET_POSITIONS: 'set-positions',
    SET_TEMPLATES: 'set-templates',
    SET_CURRENT_PLAN: 'set-current-plan',
    ADD_FRESH: 'add-fresh',
    RESET_FRESH: 'reset-fresh',
    ADD_HISTORY: 'add-history',
    SET_SHIFTS: 'set-shifts',
    SET_EXPORT_TOKEN: 'set-export-token',
    SET_SHIFT_DETAIL: 'set-shift-detail',
    SET_OFFERS: 'set-offers',
    SET_WORKSPACE_EVENTS: 'set-workspace-events',
    SET_HOLIDAYS: 'set-holidays',
    SET_DUMMY_SHIFT_CANDIDATES: 'set-dummy-shift-candidates',
    SET_AVAILABILITIES: 'set-availabilities',
    SET_TIME_OFFS: 'set-time-offs',
    SET_CHANGED_SHIFTS: 'set-changed-shifts',
    SET_CALENDAR: 'set-calendar',
    SET_CALENDAR_MULTI_SELECT: 'set-calendar-multi-select',
    SET_CALENDAR_FILTERS: 'set-calendar-filters',
    SET_CALENDAR_SEARCH: 'set-calendar-search',
    SET_CALENDAR_SCROLL: 'set-calendar-scroll',
    SET_CALENDAR_ROLE_VACATION_FUND: 'set-calendar-role-vacation-fund',
    SET_TABLES_CONFIG: 'set-tables-config',
    SET_MY_ROLE: 'set-my-role',
    SET_NOTIFICATIONS: 'set-notifications',
    READ_NOTIFICATIONS: 'read-notifications',
    RESOLVE_NOTIFICATIONS: 'resolve-notifications',
    LOAD_MORE_NOTIFICATIONS: 'load-more-notifications',
    SET_TASKS: 'set-tasks',
    SET_WORKSPACE_STATS: 'set-workspace-stats',
    SET_REPLY: 'set-reply',
    SHOW_ADMIN_PANEL: 'show-admin-panel',
    SET_NEWS_FEED: 'creat-news-feed',
    ADD_NEWS_FEED: 'add-news-feed',
    UPDATE_NEWS_FEED: 'update-news-feed',
    DELETE_NEWS_FEED: 'delete-news-feed',
    COMMENT_NEWS_FEED: 'comment-news-feed',
    LIKE_NEWS_FEED: 'like-news-feed',
    READ_NEWS_FEED: 'read-news-feed',
    PIN_NEWS_FEED: 'pin-news-feed',
    ANNOUNCE_SHIFTS_SORT: 'announce-shifts-sort',
    UPDATE_ANNOUNCE_SHIFT_FROM: 'update-announce-shift-from',
    UPDATE_ANNOUNCE_SHIFT_TO: 'update-announce-shift-to',
    UPDATE_ANNOUNCE_BY_POSITION: 'update-announce-by-position',
    UPDATE_ANNOUNCE_BY_LOCALITY: 'update-announce-by-locality',
    UPDATE_ANNOUNCE_BY_PEOPLE: 'update-announce-by-people',
    REVERT_ANNOUNCE: 'revert-announce',
    SET_ORG: 'set-org',
    SET_ORG_DETAILS: 'set-org-details',
    SET_ORG_TRANSFERS_GROUPS: 'set-org-transfers-groups',
    SET_CYCLE_DETAIL: 'set-cycle-detail',
    SWITCH_WORKSPACE: 'switch-workspace',
    SET_ACCESS_ROLES: 'access-roles',
    SET_ORG_CALENDAR_LOCKING_SETTINGS: 'set-org-calendar-locking-settings',
    SET_CHAT_UNREAD_MESSAGES: 'set-chat-unread-messages',
    SET_ASSISTANT_CONVERSATION: 'set-assistant-conversation',
};
exports.default = actionType;
