import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationProduction($organizationId: ID!, $period: PeriodInput!, $filter: OrganizationFilter!) {
  organization(id: $organizationId) {
    production(period: $period, filter: $filter) {
      amount
      date
      workspaceId
    }
  }
}`

async function requestOrganizationProduction (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization.production
}

export default requestOrganizationProduction
