import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  Icon,
  Text,
  Spacing
} from '@ui'

import './styles.scss'

const DIRECTIONS = Object.freeze({
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
})

export const RadioGroup = ({
  options,
  value,
  onChange,
  direction,
  disabled,
  label
}) => {
  return (
    <>
      {label && (
        <>
          <Text
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
            text={label}
            color={disabled ? Text.COLORS.GRAY_LIGHT : Text.COLORS.DEFAULT}
          />
          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.VERTICAL} />
        </>)}

      <div className={classNames('radio-group', direction, disabled)}>
        <div className='hidden'>
          {/* HACK PRELOADING ICON */}
          <Icon ico={Icon.ICONS.radioChecked} />
        </div>
        {options.map((option) => {
          return (
            <div
              key={option.value}
              className='radio-option'
              onClick={() => !disabled ? onChange(option.value) : null}
            >
              <Icon
                color={Icon.COLORS.PRIMARY}
                ico={value === option.value ? Icon.ICONS.radioChecked : Icon.ICONS.radioUnchecked}
                size={Icon.SIZES.SMALL}
                disabled={disabled}
              />
              <div className='text'>{option.label}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  onChange: PropTypes.func,
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
  label: PropTypes.string
}
RadioGroup.defaultProps = {}
RadioGroup.DIRECTIONS = DIRECTIONS
