"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.terminalGeneratePINForUser = void 0;
const create_ac_1 = require("@app/ac/util/create-ac");
const terminal_generate_pin_for_user_1 = require("../request/terminal-generate-pin-for-user");
const user_details_1 = require("@app/reducer/user-details");
exports.terminalGeneratePINForUser = (0, create_ac_1.createAC)((dispatch, { workspaceId, userId, useEmployeeId }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield (0, terminal_generate_pin_for_user_1.mutateTerminalGeneratePINForUser)({ workspaceId, userId, useEmployeeId });
    dispatch((0, user_details_1.setUserTerminalPIN)(res));
}));
