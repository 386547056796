import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (state = initial.fresh, action) => {
  if (action.type === aT.ADD_FRESH) {
    const { type, id } = action.data
    const add = {}
    add[type] = [...state[type], id]
    return Object.assign({}, state, add)
  }

  if (action.type === aT.RESET_FRESH) {
    const reset = {}
    reset[action.data] = []
    return Object.assign({}, state, reset)
  }

  if (action.type === aT.RESET_STORE) {
    return initial.fresh
  }

  return state
}
