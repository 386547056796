import store from '../store'
import isLoading from './is-loading'
import requestShiftDelete from '../request/shift-delete'
import requestShiftDeleteMulti from '../request/shift-delete-multi'
import setShifts from '@app/action/set-shifts'
import setShiftDetail from '@app/action/set-shift-detail'
import addError from '../ac/add-error'
import { notification } from '@app/util'

export default (shift, shiftsMulti, workspaceIdOverride) => {
  return async (dispatch) => {
    const { auth } = store.getState()
    let { workspaceId } = store.getState()
    if (workspaceIdOverride) workspaceId = workspaceIdOverride
    if (!workspaceId || (!shift && !shiftsMulti)) return

    dispatch(isLoading('delete-live-shift'))
    let result
    if (!shift && shiftsMulti && shiftsMulti.length) {
      result = await requestShiftDeleteMulti({
        workspace: workspaceId,
        shifts: shiftsMulti
      }, auth)

      // if API returned true, all the shifts were deleted - we can
      // just remove them from the store right away, instead of waiting
      // for websockets update (which might never come if connection is unstable)
      if (result && !result.error) {
        await dispatch(setShifts(store.getState().shifts.filter(sf => !shiftsMulti.includes(sf.id))))
      }
    } else {
      result = await requestShiftDelete({
        workspace: workspaceId,
        shift
      }, auth)

      // if there was no error, we can just remove the shift from
      // the store instead of waiting for websockets update (which
      // might never come with unstable connections)
      if (result && !result.error) {
        await dispatch(setShifts(store.getState().shifts.filter(sf => sf.id !== shift)))
      }
    }

    // display error message if there was any
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(isLoading('delete-live-shift', true))
      return
    } else {
      // otherwise display a success message
      notification.success({ code: 'success' })
    }

    // update store.shiftDetail if necessary
    const { shiftDetail } = store.getState()
    if (shiftDetail && shiftDetail.id === shift) {
      await dispatch(setShiftDetail(null))
    }

    dispatch(isLoading('delete-live-shift', true))
    return true
  }
}
