import isLoading from './is-loading'
import setCycleDetail from '../action/set-cycle-detail'
import store from '../store'

export default (cycle) => {
  return async (dispatch) => {
    const { cycleDetail } = store.getState()

    if (!cycle) return

    dispatch(isLoading('update-cycle-detail'))
    const isUpdate = cycleDetail?.data.find(cyc => cyc.data.id === cycle.data.id)

    if (isUpdate) {
      cycleDetail.data.forEach((detail, index) => {
        if (detail.data.id === cycle.data.id) {
          cycleDetail.data[index] = cycle
        }
      })
    }

    await dispatch(setCycleDetail({ calendar: cycleDetail.calendar, data: cycleDetail.data, lastSavedCycleShift: cycleDetail.lastSavedCycleShift }))
    dispatch(isLoading('update-cycle-detail', true))
  }
}
