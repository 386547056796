import React, { cloneElement, PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './error-field.scss'

const cx = classNames.bind(styles)

export class ErrorField extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.string,
    active: PropTypes.string,
    touched: PropTypes.string,
    message: PropTypes.string
  };

  static defaultProps = {
    children: null,
    error: '',
    active: 'false',
    touched: 'false',
    message: ''
  };

  render () {
    const { children, error, active, touched, message, forceError, ...rest } = this.props

    return (
      <div className={cx('error-field', { 'has-error': (forceError || (error && (active === 'true' || touched === 'true'))) })}>
        {children && cloneElement(children, { touched, error, active, ...rest })}
        <div className={cx('hint')}>{
          forceError
            ? <span>{forceError}</span>
            : error && (active === 'true' || touched === 'true') && <span>{message || error}</span>
        }
        </div>
      </div>
    )
  }
}
