import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getOrganization, setModal } from '@app/ac'

const mapStateToProps = ({ organizationId, auth }) => {
  return {
    organizationId,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getOrganization,
    setModal
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
