import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestShiftAttributeAddMulti from '../request/shift-attribute-add-multi'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'

export default (shiftIds, attribIds) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !shiftIds || !attribIds) return

    const attributeIds = [...attribIds]

    dispatch(isLoading('add-shift-attributes'))
    let result = null

    result = await requestShiftAttributeAddMulti({
      workspace: workspaceId,
      ids: shiftIds,
      attributes: attributeIds.map(aid => { return { attributeId: aid, value: true } })
    }, auth)

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('add-shift-attributes', true))
    return result || {}
  }
}
