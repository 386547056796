/* eslint-disable react/jsx-fragments,no-extra-boolean-cast */
import { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, Spacing } from '@ui'

import connect from './connect'
import './index.scss'

class DisplayImage extends Component {
  render () {
    const { imgUrl } = this.props

    return (
      <Modal
        className='ds-modal-display-image'
        size={Modal.SIZES.L}
        children={
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_20}>
            <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_16}>
              <img className='display-image-img' src={imgUrl} />
            </Spacing>
          </Spacing>
        }
      />
    )
  }
}

DisplayImage.propTypes = {
  imgUrl: PropTypes.string.isRequired
}

export default connect(DisplayImage)
