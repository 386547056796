import { fetchRequest, errorUtil } from '@app/util'
import q from '../query'

const query = `query OrganizationBonuses($organizationId: ID!, $period: PeriodInput!, $filter: OrganizationFilter!, $contracts: [String!]) {
  organization(id: $organizationId) {
    bonuses(period: $period, filter: $filter, contracts: $contracts) ${q.BONUS_ITEM}
  }
}`

async function requestOrganizationBonuses (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization.bonuses
}

export default requestOrganizationBonuses
