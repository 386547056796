import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationRoleAssign($organization: ID!, $role: ID!) {
  organizationRoleDelete(organization: $organization, role: $role)
}`

async function requestOrganizationRoleDelete (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data
}

export default requestOrganizationRoleDelete
