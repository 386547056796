import isLoading from './is-loading'
import requestChangedShiftsCount from '../request/changed-shifts-count'
import addError from '../ac/add-error'
import setCalendar from '../action/set-calendar'
import { miscUtil, permissionsUtil } from '@app/util'
import { PERMISSIONS } from '@app/const'

import store from '../store'

export default () => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return
    if (!permissionsUtil.canWrite(PERMISSIONS.CALENDAR)) return

    // Only reload changed shifts if we're on /schedule/ page (looking at the manager's calendar).
    // Otherwise, reset 'store.calendar.changedShiftsCount' to undefined, so it's loaded again when it's needed.
    // We want to avoid unnecessary calls to requestChangedShiftsCount() when they're not 100% needed, because it's it slows down the BE.
    let changedShiftsCountIsNeededNow = false
    if (!changedShiftsCountIsNeededNow && window.location.pathname.includes('/schedule/')) changedShiftsCountIsNeededNow = true
    if (!changedShiftsCountIsNeededNow) {
      const cal = store.getState().calendar
      if (cal) {
        await dispatch(setCalendar(Object.assign({}, cal, {
          changedShiftsCount: undefined
        })))
      }
      return false
    }

    const enforcedLocality = miscUtil.getEnforcedLocality()

    dispatch(isLoading('load-changed-shifts-count'))
    const result = await requestChangedShiftsCount({
      workspaceId,
      locality: enforcedLocality || undefined
    }, auth)
    if (result.error) {
      dispatch(addError(result.error))
    } else {
      let { calendar } = store.getState()
      if (!calendar) calendar = {}
      calendar.changedShiftsCount = result.changedShiftsCount
      await dispatch(setCalendar(calendar))
    }
    dispatch(isLoading('load-changed-shifts-count', true))
  }
}
