import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import {
  setModal,
  setSidebar,
  createPlan,
  createCyclesPlan,
  setCalendarMultiSelect
} from '@app/ac'

const mapStateToProps = ({ shifts, workspaces, workspaceId, templates, unavailabilities, positions, auth, calendar }) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    shifts,
    templates,
    workspace: ws,
    workspaceId,
    auth,
    loadedPeriods: calendar?.loadedPeriods,
    unavailabilities,
    positions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setModal,
  setSidebar,
  setCalendarMultiSelect,
  createPlan,
  createCyclesPlan
}, dispatch)

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
