import store from '../store'
import addError from '../ac/add-error'
import isLoading from './is-loading'
import requestEmployeeCreate from '@app/request/employee-create'
import requestPositionAssign from '@app/request/position-assign'
import loadRoleStatsMulti from '@app/ac/load-role-stats-multi'
import { assignLocality, loadEmployees } from '@app/ac'
import { miscUtil } from '@app/util'
import moment from 'moment'
import { NO_LOCALITY } from '@app/const/globals'

export default (invites) => {
  return async (dispatch) => {
    dispatch(isLoading('send-invites'))
    const { auth, workspaces, workspaceId, calendar } = store.getState()

    const results = []
    for (let i = 0; i < invites.length; i++) {
      const email = invites[i].email ? invites[i].email.toLowerCase() : null
      const firstName = invites[i].firstName
      const lastName = invites[i].lastName
      const hours = invites[i].hours
      const contractType = invites[i].contractType
      const positionId = invites[i].positionId

      // here BE creates a dummy employee and, if en email was given, also creates an Invite
      const createdEmployee = await requestEmployeeCreate({
        workspace: workspaceId,
        userData: {
          email,
          firstName,
          lastName
        },
        customData: {
          firstName,
          lastName
        },
        roleData: {
          contracts: [{
            type: contractType,
            period: {
              start: moment().startOf('day'),
              end: null
            },
            options: {
              minutesOfWorkPerMonth: Math.round(hours * 60)
            }
          }]
        }
      }, auth)
      results.push(createdEmployee)

      // assign a position to the new dummy employee
      if (!createdEmployee.error && positionId) {
        const contractOfCreatedEmployee = createdEmployee.roles?.length && createdEmployee.roles[0]?.contracts?.length ? createdEmployee.roles[0].contracts[0] : null
        const assignedPosition = await requestPositionAssign({
          workspace: workspaceId,
          user: createdEmployee.id,
          position: positionId,
          contract: contractOfCreatedEmployee.id
        }, auth)
        results.push(assignedPosition)
      }

      // if we're on a locality-based WS, we also auto-assign currently enforced (loaded) locality to newly added user
      const enforcedLocality = miscUtil.getEnforcedLocality()
      if (enforcedLocality && !createdEmployee.error) {
        const ws = workspaces.find(w => w.id === workspaceId)
        if (ws.enforceLocalities) {
          let assignedLocality = null
          if (enforcedLocality !== NO_LOCALITY) {
            assignedLocality = await dispatch(assignLocality(createdEmployee.id, enforcedLocality))
            results.push(assignedLocality)
          }

          // if the user was created while we're on enforcedlocality, websockets will not add it to store (because at the
          // time of addition, the new user didn't have correct locality yet).
          // therefore, we need an extra reload here.
          if (!assignedLocality?.error) {
            await dispatch(loadEmployees())
          }
        }
      }
    }

    const errors = results.filter((r) => r.error)
    if (errors.length) {
      errors.map((err) => dispatch(addError(err.error)))
    } else {
      // if we have calendar, load calendar.roleStats again, so we don't miss the data for the newly added users
      if (calendar && calendar.date) {
        dispatch(loadRoleStatsMulti(calendar.date))
      }
    }

    dispatch(isLoading('send-invites', true))
    return results
  }
}
