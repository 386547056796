import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation LocalityCreateMutation (
  $workspace: ID!,
  $data: LocalityInput!
) {
  localityCreate (
    workspace: $workspace
    data: $data
  ) {
    id
  }
}`

async function requestLocalityCreate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('locality-create', errors)
  }
  return data.localityCreate
}

export default requestLocalityCreate
