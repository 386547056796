import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query MeQuery {
  me {
    id
    chatToken
    firstName
    lastName
    name
    email
    hasPassword
    created
    accessRoles {
      id
      name
    }
    acl {
      workspaces
      organizations
      countries
      #areas
      #users
    }
    notificationPreferences {
      channels {
        app {
          enabled
          origins {
            name
            enabled
          }
        }
        mobile {
          enabled
          origins {
            name
            enabled
          }
        }
        email {
          enabled
          origins {
            name
            enabled
          }
        }
      }
    }
    mailingVerificationHash
    telephone
    language
    roles {
      archived
      workspaceId
      enforceMfa
      contracts {
        period {
          start
          end
        }
      }
      contractNew {
        type
      }
    }
    mfa {
      enabled
      factors {
        name
        verified
        sid
        type
      }
    }
    organizations {     # required for WS switching
      id
      hidden
      name
      settings {
        billing {
          paid
          trial
          expiration
        }
      }
    }
  }
}`

async function requestMe (token) {
  const response = await fetchRequest({ query, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('me', errors)
  }
  return data.me
}

export default requestMe
