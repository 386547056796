import React from 'react'
import { t } from 'i18next'

import { Spacing, Text } from '@ui'

import { OtherPagesLayout } from '../OtherPagesLayout'

const Offline = () => {
  return (
    <OtherPagesLayout offline>
      <Spacing size={Spacing.SIZES.SIZE_48} />
      <Text
        type={Text.TYPES.HEADLINE1}
        text={t('INTERNET_UNAVAILABLE')}
        align={Text.ALIGN.CENTER}
      />
    </OtherPagesLayout>
  )
}

export default Offline
