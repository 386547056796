import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation AvailabilityRemoveMultiMutation (
  $workspace: ID!,
  $data: [AvailabilityRemoveData!]!
) {
  availabilityBulkRemove (
    workspace: $workspace
    data: $data
  )
}`

async function requestAvailabilityDeleteMulti (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('availability-remove-multi', errors)
  }
  return data.availabilityBulkRemove
}

export default requestAvailabilityDeleteMulti
