import store from '../store'
import isLoading from './is-loading'
import requestRoleUpdate from '../request/role-update'
import addError from '../ac/add-error'
import setEmployees from '../action/set-employees'
import setEmployeeDetail from '../action/set-employee-detail'

export default (userId, cycleId, cycleGroup = 0) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return

    dispatch(isLoading('assign-cycle'))
    const result = await requestRoleUpdate({
      workspace: workspaceId,
      user: userId,
      data: { cycleId, cycleGroup }
    }, auth)
    if (result.error) {
      dispatch(addError(result.error))
    } else {
      const { employees, employeeDetail } = store.getState()

      const newEmps = Object.assign({}, employees)
      if (newEmps[userId]) newEmps[userId] = Object.assign({}, newEmps[userId], { cycleId: cycleId, cycleGroup: cycleGroup })
      await dispatch(setEmployees(newEmps))

      if (employeeDetail && employeeDetail.id === userId) {
        await dispatch(setEmployeeDetail(Object.assign({}, employeeDetail, { cycleId: cycleId, cycleGroup: cycleGroup })))
      }
    }

    dispatch(isLoading('assign-cycle', true))
  }
}
