import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { t } from 'i18next'

import { Dropdown, Icon } from '@ui'
import './style.scss'

export const Pagination = ({
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  itemsCount
}) => {
  const pageOptionsArray = [
    { label: t('TABLE_PAGINATION_ALL'), value: 1000000000 },
    { label: 10, value: 10 },
    { label: 30, value: 30 },
    { label: 50, value: 50 },
    { label: 100, value: 100 }
  ]
  return (
    <div className='table-pagination'>
      <div className='pagination-button-group'>
        <div
          className={classNames(
            'pagination-left',
            { disabled: !canPreviousPage }
          )}
          onClick={canPreviousPage ? () => previousPage() : null}
        >
          <Icon
            ico={Icon.ICONS.arrowLeft}
            color={Icon.COLORS.PRIMARY}
            disabled={!canPreviousPage}
          />
        </div>
        <div
          className={classNames(
            'pagination-right',
            { disabled: !canNextPage }
          )}
          onClick={canNextPage ? () => nextPage() : null}
        >
          <Icon
            ico={Icon.ICONS.arrowRight}
            color={Icon.COLORS.PRIMARY}
            disabled={!canNextPage}
          />
        </div>
      </div>
      <span className='pagination-page'>{t('TABLE_PAGINATION_PAGE', { x: pageIndex + 1, y: pageOptions.length })}</span>
      <span className='pagination-show'>{t('TABLE_PAGINATION_SHOW')}</span>
      <Dropdown
        size={Dropdown.SIZES.SMALL}
        style={Dropdown.STYLES.LIGHT}
        type={Dropdown.TYPES.VARIABLE}
        singleSelect
        value={pageOptionsArray.find((option) => option.value === pageSize)}
        onChange={({ value }) => setPageSize(value)}
        options={pageOptionsArray}
      />
      <span className='pagination-from'>{t('TABLE_PAGINATION_ITEMS', { x: itemsCount })}</span>
    </div>
  )
}

Pagination.propTypes = {
  gotoPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired
}
