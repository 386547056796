import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestTimeOffsById (variables, token) {
  const query = `query TimeOffs($workspaceId: ID!, $ids: [ID!]) {
  workspace(id: $workspaceId) {
    timeOffs(ids: $ids) ${q.TIME_OFF}
  }
}`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('timeOffs', errors)
  }
  return data.workspace.timeOffs
}

export default requestTimeOffsById
