import { bindActionCreators, compose } from 'redux'
import {
  reduxForm,
  getFormSyncErrors,
  change,
  getFormValues
} from 'redux-form'
import { connect } from 'react-redux'

import loadExportToken from '@app/ac/load-export-token'
import { VALUES, ERRORS } from '@app/const/globals'
import setModal from '@app/ac/set-modal'
import { EXPORT_FORM } from './constants'

const mapStateToProps = (state) => {
  const { exportToken, loading, auth, me, workspaceId, workspaces } = state
  const ws = workspaces && workspaceId && workspaces.find(ws => ws.id === workspaceId)

  return {
    [ERRORS]: getFormSyncErrors(EXPORT_FORM)(state),
    [VALUES]: getFormValues(EXPORT_FORM)(state),
    auth,
    me,
    workspaceId,
    exportToken,
    isLoading: loading.includes('load-export-token'),
    localities: ws ? ws.localities : []
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeFormField: (field, value) => change(EXPORT_FORM, field, value),
    loadExportToken,
    setModal
  }, dispatch)
}

export default (component) => {
  return compose(
    reduxForm({
      form: EXPORT_FORM
    }),
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
