const ERROR_HINTS = {
  INPUT_REQUIRED: 'Input is required'
}

export const RequiredFieldValidation = (input) => {
  const { INPUT_REQUIRED } = ERROR_HINTS
  switch (true) {
    case !input:
      return INPUT_REQUIRED
    default:
      return ''
  }
}
