import React from 'react'

import './sort.scss'
import classNames from 'classnames'

export const Sort = ({ col, sortProps }) => (
  <div
    className={classNames('ds-sort',
      {
        'is-desc': col.isSorted && col.isSortedDesc,
        'is-asc': col.isSorted && !col.isSortedDesc
      }
    )}
    {...sortProps}
    title=''
  >
    <div className='ds-sort-el' />
  </div>
)
