import React, { useState } from 'react'
import { t } from 'i18next'

import {
  Button,
  Dropdown,
  Input,
  Modal,
  Flex,
  Spacing, Text
} from '@ui'

import connect from './connect'

export const PermissionsAdd = connect(({ createRole, countries }) => {
  const [form, setForm] = useState({
    name: '',
    region: null
  })
  const countriesGroup = t('ORG_SP_WORKSPACES_COUNTRIES')
  const areasGroup = t('ORG_SP_WORKSPACES_AREAS')

  const trueRegion = () => {
    if (form.region.groupTitle === countriesGroup) {
      return ({
        countries: [form.region.value],
        areas: []
      })
    } else {
      return ({
        areas: [form.region.value],
        countries: []
      })
    }
  }

  const c = countries || []
  const allOptions = [
    ...c.map((country) => ({
      groupTitle: countriesGroup,
      label: country.code,
      value: country.id
    })),
    ...c.map((country) => country.areas
      .map((area) => ({
        groupTitle: areasGroup,
        label: area.name,
        value: area.id
      }))
    ).flat()
  ]

  return (
    <Modal
      headerContent={
        <Text
          type={Text.TYPES.BODY_LARGE}
          weight={Text.WEIGHTS.BOLD}
          text={t('ORG_SP_PEOPLE_PERMISSIONS_ADD')}
        />
      }
      size={Modal.SIZES.S}
      footerContent={
        <Flex justify={Flex.POSITION.END} stretch>
          <Button
            style={Button.STYLES.CONTAINED}
            label={t('CREATE')}
            onClick={() => createRole({ name: form.name, ...trueRegion() })}
          />
        </Flex>
      }
    >
      <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_16}>
        <Input
          size={Input.SIZES.FULL_WIDTH}
          label={t('NAME')}
          value={form.name}
          onChange={(val) => setForm({ ...form, name: val })}
        />
        <Spacing size={Spacing.SIZES.SIZE_14} />
        <Dropdown
          placeholder={t('CHOOSE')}
          label={t('ORG_SP_PEOPLE_PERMISSIONS_COVERAGE')}
          options={allOptions}
          value={form.region}
          type={Dropdown.TYPES.VARIABLE}
          style={Dropdown.STYLES.LIGHT}
          size={Dropdown.SIZES.FULL_WIDTH}
          onChange={(val) => setForm({ ...form, region: val })}
          singleSelect
        />
      </Spacing>
    </Modal>
  )
})

PermissionsAdd.propTypes = {}
PermissionsAdd.defaultProps = {}
