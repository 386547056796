import { t } from 'i18next'

import { Text } from '@ui'
import classNames from 'classnames'

import './status-badge.scss'

const TYPES = Object.freeze({
  SUMMARY: 'summary',
  RELEASE: 'release',
  OTHER: 'other'
})

const byCountTranslationMapper = (count) => {
  switch (true) {
    case count === 1:
      return t('NOTIFICATION_NEW_1').toLowerCase()
    case count > 1 && count <= 4:
      return t('NOTIFICATION_NEW_2').toLowerCase()
    case count > 4:
      return t('NOTIFICATION_NEW_3').toLowerCase()
    default:
      return t('NOTIFICATION_NEW_1').toLowerCase()
  }
}

export function StatusBadge ({ count, type }) {
  const reversedColors = type === TYPES.RELEASE
  let result

  if (type === TYPES.SUMMARY) {
    result = `${count} ${byCountTranslationMapper(count)}`
  } else {
    result = t('NOTIFICATION_NEW_1')
  }

  return (
    <div
      className={classNames('ds-notifications-status-badge', {
        reversed: reversedColors
      })}
    >
      <Text
        type={Text.TYPES.BODY_SMALL}
        color={reversedColors ? Text.COLORS.PRIMARY : Text.COLORS.WHITE}
        weight={Text.WEIGHTS.BOLD}
        style={{ display: 'block' }}
      >
        {result}
      </Text>
    </div>
  )
}

StatusBadge.TYPES = TYPES
