"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.roleUpdate = void 0;
const create_ac_1 = require("@app/ac/util/create-ac");
const role_update_1 = require("../request/role-update");
const util_1 = require("@app/util");
const user_details_1 = require("@app/reducer/user-details");
const access_role_assign_1 = require("../request/access-role-assign");
const access_role_unassign_1 = require("../request/access-role-unassign");
exports.roleUpdate = (0, create_ac_1.createAC)((dispatch, args) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const data = yield (0, role_update_1.mutateRoleUpdate)({
        workspaceId: args.workspaceId,
        userId: args.userId,
        data: {
            hidden: args.roleData.hidden,
            cycleId: args.roleData.cycleId,
            cycleGroup: args.roleData.cycleGroup,
            calendarOrder: args.roleData.calendarOrder,
        },
    });
    if ((0, util_1.isUndefined)(data))
        return;
    dispatch((0, user_details_1.setRole)(data));
    if ((0, util_1.isDefined)(args.unassignAccessRole)) {
        const unassignTask = yield (0, access_role_unassign_1.mutateAccessRoleUnassign)({
            workspaceId: args.workspaceId,
            roles: [{
                    roleId: (_a = args.unassignAccessRole.id) !== null && _a !== void 0 ? _a : '',
                    userId: args.userId,
                }],
        });
        console.log(unassignTask);
    }
    if ((0, util_1.isDefined)(args.assignAccessRole)) {
        const assignTask = yield (0, access_role_assign_1.mutateAccessRoleAssign)({
            workspaceId: args.workspaceId,
            roles: [{
                    roleId: (_b = args.assignAccessRole.id) !== null && _b !== void 0 ? _b : '',
                    userId: args.userId,
                }],
        });
        console.log(assignTask);
    }
}));
