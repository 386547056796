/* eslint-disable react/jsx-fragments */
import './index.scss'
import React, { Fragment } from 'react'
import connect from './connect'
import {
  Button,
  Link
} from '@ui'
import Radio from '@ui/radio'
import { Modal } from '@ui/modal'
import { t } from 'i18next'
import moment from 'moment'

class CycleSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: props.ee && props.ee.cycleId ? props.ee.cycleId : null
    }
    this.handleOnSubmit = this._onSubmit.bind(this)
    this.getListSectionHeader = this._getListSectionHeader.bind(this)
  }

  async _onSubmit () {
    const { assignCycle, ee } = this.props
    await assignCycle(ee.id, this.state.selected)
    this.props.setModal(null)
  }

  _getListSectionHeader (columns) {
    return (
      <div className='ds-sd-th'>
        <div className='ds-sd-thc ds-sda-thc-title'>
          <span className='ds-sd-th-label'>{columns[0]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-length'>
          <span className='ds-sd-th-label'>{columns[1]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-shift-count'>
          <span className='ds-sd-th-label'>{columns[2]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-hours'>
          <span className='ds-sd-th-label'>{columns[3]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-starts'>
          <span className='ds-sd-th-label'>{columns[4]}</span>
        </div>
        <div className='ds-sd-thc ds-sda-thc-assign' />
      </div>
    )
  }

  render () {
    const { setModal, workspace, isLoading, ee } = this.props
    const cycles = workspace.cycles
    if (!ee || !ee.id || !cycles) return null

    return (
      <Modal
        size={Modal.SIZES.M}
        className='ds-cycle-select'
        isLoading={isLoading}
        footerContent={
          <Fragment>
            <Button
              label={t('CLOSE')}
              onClick={() => setModal(null)}
            />
            <Button
              style={Button.STYLES.CONTAINED}
              color={Button.COLORS.GREEN}
              label={t('CYCLE_SELECT_APPLY')}
              disabled={isLoading}
              loading={isLoading}
              onClick={(e) => {
                this.handleOnSubmit()
              }}
            />
          </Fragment>
        }
        headerContent={<div className='ds-title'>{t('CYCLE_SELECT_TITLE')}</div>}
        sections={isLoading ? [] : [
          <div key={0} className='ds-sd-cycles-list'>
            {this.getListSectionHeader([t('CYC_TITLE_CYC'), t('CYC_LENGTH'), t('SHIFT_COUNT'), t('CYC_HOURS'), t('CYC_STARTS')])}

            <div key='no_cycle' className='ds-sd-tb-wrapper is-no-cycle' onClick={() => this.setState({ selected: null })}>
              <div className={['ds-sd-tb', !this.state.selected ? 'is-selected' : ''].join(' ')}>
                <div className='ds-sd-tbc ds-sda-tbc-radio'>
                  <Radio value={!this.state.selected} />
                </div>
                {t('CYCLE_SELECT_NO_CYCLE')}
              </div>
            </div>

            {cycles.map((cyc) => {
              const hrs = (cyc.shifts && cyc.shifts.length)
                ? Math.round(cyc.shifts.reduce((a, s) => {
                  let ret = s.duration
                  if (s.pauses && s.pauses.length) {
                    ret -= s.pauses.reduce((c, p) => { return c + p.duration }, 0)
                  }
                  return a + Math.max(0, ret)
                }, 0) / 60 * 100) / 100
                : 0

              return (
                <div key={cyc.id} className='ds-sd-tb-wrapper'>
                  <div
                    className={['ds-sd-tb', this.state.selected === cyc.id ? 'is-selected' : ''].join(' ')}
                    onClick={() => this.setState({ selected: cyc.id })}
                  >
                    <div className='ds-sd-tbc ds-sda-tbc-radio'>
                      <Radio value={this.state.selected === cyc.id} />
                    </div>
                    <div className='ds-sd-tbc ds-sda-tbc-title'>
                      {cyc.title}
                    </div>
                    <div className='ds-sd-tbc ds-sda-tbc-length'>
                      {t('DAYS_X', { x: cyc.length })}
                    </div>
                    <div className='ds-sd-tbc ds-sda-tbc-shift-count'>
                      {cyc.shifts.length}
                    </div>
                    <div className='ds-sd-tbc ds-sda-tbc-hours'>
                      {hrs.toString() + ' ' + t('HOUR_SHORT')}
                    </div>
                    <div className='ds-sd-tbc ds-sda-tbc-starts'>
                      {moment(cyc.startDate).format('D.M.YYYY')}
                    </div>
                  </div>
                </div>
              )
            })}

            {window.location.pathname !== '/workspace/cycles/'
              ? (
                <div key='new_cycle' className='ds-sd-tb-wrapper is-new-cycles'>
                  <Link
                    type={Link.TYPES.ROUTER_LINK}
                    to='/workspace/cycles/'
                  >
                    <div className='ds-sd-tb'>
                  + {t('EDIT_CYCLES')}
                    </div>
                  </Link>
                </div>
              )
              : null}

          </div>
        ]}
      />
    )
  }
}

export default connect(CycleSelect)
