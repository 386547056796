import aT from '../const/action-type'

export default (data) => {
  return {
    type: aT.SET_TIME_OFFS,
    data: Array.isArray(data) ? data.reduce((acc, current) => { // here we remove objects from data that have duplicate 'id' prop
      const x = acc.find(item => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
      : data
  }
}
