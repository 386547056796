import isLoading from './is-loading'
import addError from '../ac/add-error'
import setNotifications from '../action/set-notifications'
import requestNotifications from '@app/request/notifications'
import store from '../store'

export default () => {
  return async (dispatch) => {
    const { auth } = store.getState()

    dispatch(isLoading('load-notifications'))
    const result = await requestNotifications({ offset: 0, limit: 20 }, auth)
    if (result.error) {
      dispatch(addError(result.error))
    } else {
      dispatch(setNotifications(result))
    }
    dispatch(isLoading('load-notifications', true))
  }
}
