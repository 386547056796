import isLoading from './is-loading'
import requestCyclesPlan from '../request/cycles-plan'
import addError from '../ac/add-error'
import store from '../store'
import moment from 'moment'

export default (period, cycleId, employeeIds, createUnassigned, fromUnassigned, overrides, batchDoneCallback) => {
  return async (dispatch) => {
    // how many days can we plan in one request
    const maxDaysInOneBatch = 7

    const { auth, workspaceId } = store.getState()
    if (!period || !period?.start || !period?.end || !workspaceId) return
    dispatch(isLoading('create-cycles-plan'))

    // iterate over the period, batch by batch (we divide the period into smaller batches)
    let currentStart = period.start.clone()

    const results = []
    let daysPlannedSoFar = 0
    while (currentStart.isSameOrBefore(period.end)) {
      // calculate the end of the current batch, but not exceeding the period's end
      let currentEnd = currentStart.clone().add(maxDaysInOneBatch - 1, 'days').endOf('day')
      if (currentEnd.isAfter(period.end)) {
        currentEnd = period.end.clone()
      }

      // request cycles plan for the current batch (subperiod)
      const res = await requestCyclesPlan({
        period: { start: currentStart, end: currentEnd },
        workspace: workspaceId,
        cycles: cycleId || undefined,
        users: employeeIds || undefined,
        createUnassigned: createUnassigned || undefined,
        fromUnassigned: fromUnassigned || undefined,
        overrides: overrides || undefined
      }, auth)
      results.push(res)

      daysPlannedSoFar += currentEnd.clone().add(5, 'minutes').diff(currentStart, 'days')
      if (batchDoneCallback) batchDoneCallback(daysPlannedSoFar, moment(period.end).diff(period.start, 'days'))
      currentStart = currentEnd.add(1, 'days').startOf('day')
    }

    if (results.some(r => r.error)) {
      dispatch(addError(results.find(r => r.error).error))
    }
    await dispatch(await isLoading('create-cycles-plan', true))
    return results
  }
}
