import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query MetaQuery {
  meta {
    version
    commit
    locales
    frontend (type: "web") {
      time
    }
  }
}`

async function meta () {
  let response = null
  try {
    response = await fetchRequest({
      query
    })
    if (!response) return errUtil.noResponse()

    const { errors, data } = response
    if (errors) {
      return errUtil.apiError('meta', errors)
    }
    return data.meta
  } catch (error) {
    // we usually get an error here when the auth token is no longer valid.
    // in that case we return null, so that the app calls resetStore() function to delete the store.auth, but we want to do it silently, without raising an error,
    // so it doesn't mess up the Sentry reporting.
    console.log('Auth token no longer valid.')
    return null
  }
}

export default meta
