import { bindActionCreators, compose } from 'redux'
import { withPlugin } from '@core/hoc/withPlugin'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'

const mapStateToProps = ({ calendar, calendarMultiSelect, calendarFilters, modal }) => {
  return {
    calendar: calendar || {},
    calendarFilters,
    usersMentionedInMultiselect: calendarMultiSelect ? [...new Set(calendarMultiSelect.targets.map(t => t.userId).concat(calendarMultiSelect.sourceEvents.map(e => e.userId)))] : []
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
