import isLoading from './is-loading'
import setCycleDetail from '../action/set-cycle-detail'
import store from '../store'

export default () => {
  return async (dispatch) => {
    const { cycleDetail } = store.getState()

    dispatch(isLoading('reset-cycle-detail'))
    cycleDetail.data = []
    await dispatch(setCycleDetail({ calendar: cycleDetail.calendar, data: cycleDetail.data, lastSavedCycleShift: null }))
    dispatch(isLoading('reset-cycle-detail', true))
  }
}
