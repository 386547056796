import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation TimeOffRemoveMultiMutation (
  $workspace: ID!,
  $data: [TimeOffRemoveData!]!
) {
  timeOffBulkRemove (
    workspace: $workspace
    data: $data
  )
}`

async function requestTimeOffDeleteMulti (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('time-off-remove-multi', errors)
  }
  return data.timeOffBulkRemove
}

export default requestTimeOffDeleteMulti
