import React from 'react'
import { t } from 'i18next'
import classNames from 'classnames/bind'

import pluginsConfig from '@app/const/plugin-config'
import {
  notification, useAccessRole
} from '@app/util'
import {
  Icon,
  Button,
  Modal,
  Text,
  Flex,
  Spacing,
  Link
} from '@ui'
import styles from './index.scss'
import connect from './connect'
import { usePluginDeactivationModalOverride } from './use-plugin-deactivation-modal-override'
import { PERMISSIONS } from '@app/const'

const cls = classNames.bind(styles)
const cx = (classSuffix) => cls(`ds-pg-dt-${classSuffix}`)

const PluginDetail = (props) => {
  const { hasWritePermission } = useAccessRole(PERMISSIONS.SETTINGS.PLUGINS)
  const { plugin, close, togglePlugin, calendar } = props
  const pConf = pluginsConfig.SUPPORTED_PLUGINS && pluginsConfig.SUPPORTED_PLUGINS.find(rec => rec.id === plugin.plugin)
  const isAdmin = (document.querySelector('.ds-adminpanel') || window.location.hostname === 'localhost' || window.location.hostname === 'app.local.dayswaps.top')
  if (!pConf) return null

  const pluginId = plugin.id

  const overridePluginDeactivation = usePluginDeactivationModalOverride()

  return (
    <Modal
      size={Modal.SIZES.S}
      className='ds-pg-dt-wrapper'
      sections={[
        <div key={0} className={cx('desc-panel')}>
          <Flex
            align={Flex.POSITION.CENTER}
            flex='1'
            direction={Flex.DIRECTION.COLUMN}
          >
            {pConf.icon ? (
              <div className='ds-pg-card-title' style={{ color: pConf.color, borderColor: pConf.color }}>
                {<Icon ico={pConf.icon} />}{t('PLUGIN_NAME.' + plugin.plugin)}
              </div>
            ) : pConf.logo}
            <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL} />
            <Text
              type={Text.TYPES.BODY_LARGE}
              color={Text.COLORS.BLACK}
            >
              {t(`PLUGIN_DESCRIPTION.${pConf.id}`)}
            </Text>
          </Flex>
          <Flex justify={Flex.POSITION.SPC_EVENLY} align={Flex.POSITION.END} flex='1'>
            {plugin.enabled && pConf.help
              ? (
                <a href={pConf.help} target='_blank' rel='noopener noreferrer'>
                  <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.CENTER}>
                    <Text color={Text.COLORS.PRIMARY} type={Text.TYPES.BODY_MEDIUM}>
                      {t('WS_SETTINGS_CARD_PLUGINS_SETTINGS_DETAIL_HELP')}
                    </Text>
                    <div className='ds-caret-right' />
                  </Flex>
                </a>)
              : null}
            {plugin.enabled && pConf.settings
              ? (
                <Link
                  to={pConf.settings}
                  type={Link.TYPES.A_TAG}
                >
                  <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.CENTER}>
                    <Text color={Text.COLORS.PRIMARY} type={Text.TYPES.BODY_MEDIUM}>
                      {t('SETTINGS')}
                    </Text>
                    <div className='ds-caret-right' />
                  </Flex>
                </Link>)
              : null}
            {pConf.web
              ? (
                <a href={pConf.web} target='_blank' rel='noopener noreferrer'>
                  <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.CENTER}>
                    <Text color={Text.COLORS.PRIMARY} type={Text.TYPES.BODY_MEDIUM}>
                      {t('WS_SETTINGS_CARD_PLUGINS_SETTINGS_DETAIL_WEBSITE')}
                    </Text>
                    <div className='ds-caret-right' />
                  </Flex>
                </a>)
              : null}
          </Flex>
        </div>
      ]}
      footerContent={
        <Flex grow={1} justify={Flex.POSITION.CENTER}>
          {!plugin.onlyOnRequest
            ? (
              <div key={1} className={cx('btn-row')}>
                <Button
                  disabled={!hasWritePermission}
                  size={Button.SIZES.EXTRA}
                  style={Button.STYLES.CONTAINED}
                  color={(plugin.enabled || pConf.requireEnabled) ? Button.COLORS.RED : Button.COLORS.PRIMARY}
                  onClick={() => {
                    if (pConf.onlyOnRequest && !isAdmin) {
                      window.Intercom('show')
                    } else {
                      if (!pConf.requireEnabled) {
                        // pluginToggle(plugin)
                        togglePlugin(plugin, calendar)
                      } else {
                        notification.warn({ message: t('PLUGIN_REQUIRED_MESSAGE') })
                      }
                    }
                    close()
                  }}
                  label={(plugin.enabled || pConf.requireEnabled) ? t('WS_SETTINGS_CARD_PLUGINS_SETTINGS_DETAIL_DISABLE') : t('WS_SETTINGS_CARD_PLUGINS_SETTINGS_DETAIL_ENABLE')}
                />
              </div>
            )
            : (
              <div key={1} className={cx('btn-row')}>
                <Button
                  disabled={!hasWritePermission}
                  size={Button.SIZES.EXTRA}
                  style={Button.STYLES.CONTAINED}
                  onClick={() => {
                    if (pConf.onlyOnRequest && !isAdmin) {
                      window.Intercom('show')
                    } else {
                      if (!pConf.requireEnabled) {
                        // pluginToggle(plugin)
                        overridePluginDeactivation(pluginId, () => togglePlugin(plugin, calendar))
                      } else {
                        notification.warn({ message: t('PLUGIN_REQUIRED_MESSAGE') })
                      }
                    }
                    close()
                  }}
                  className={plugin.enabled ? 'enabled-btn' : 'enable-btn'}
                >
                  <span className='toggle-btn-default-label'>
                    {(plugin.enabled || pConf.requireEnabled) ? t('WS_SETTINGS_CARD_PLUGINS_SETTINGS_DISABLE_REQUEST') : t('WS_SETTINGS_CARD_PLUGINS_SETTINGS_ENABLE_REQUEST')}
                  </span>
                </Button>
              </div>
            )}
        </Flex>
      }
    />
  )
}

export default connect(PluginDetail)
