import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { setModal } from '@app/ac'
import loadShiftDetail from '@app/ac/load-shift-detail'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import updateLiveShift from '@app/ac/update-live-shift'
import updateTemplateShift from '@app/ac/edit-template-shift'
import { withPlugin } from '@core/hoc/withPlugin'
import setSidebar from '@app/ac/set-sidebar'
import setCalendarMultiSelect from '@app/ac/set-calendar-multi-select'
import createShift from '@app/ac/create-shift'
import createShiftTemplate from '@app/ac/create-shift-template'
import updateShiftTemplate from '@app/ac/update-shift-template'
import addTemplateShift from '@app/ac/create-template-shift'
import deleteLiveShift from '@app/ac/delete-live-shift'
import deleteTemplateShift from '@app/ac/delete-template-shift'
import setCycleDetail from '@app/ac/set-cycle-detail'
import deleteCycleDetail from '@app/ac/delete-cycle-detail'
import updateCycleDetail from '@app/ac/update-cycle-detail'
import loadPositions from '@app/ac/load-positions'

const mapStateToProps = ({
  loading,
  shiftDetail,
  employees,
  positions,
  templates,
  calendar,
  workspaces,
  workspaceId,
  organizationId,
  me,
  auth,
  cycleDetail
}) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    shiftDetail,
    employees,
    positions,
    templates,
    calendar,
    workspace: ws,
    workspaceId,
    organizationId,
    auth,
    me,
    cycleDetail,
    isLoading:
      loading.includes('load-shifts') ||
      loading.includes('load-shift-detail') ||
      loading.includes('create-template-shift') ||
      loading.includes('create-shift') ||
      loading.includes('create-shift-template') ||
      loading.includes('add-review-shift') ||
      loading.includes('add-prepare-shift') ||
      loading.includes('update-review-shift') ||
      loading.includes('update-live-shift') ||
      loading.includes('update-shift-template') ||
      loading.includes('edit-shift') ||
      loading.includes('workspace-detail') ||
      loading.includes('load-positions')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateLiveShift,
    updateTemplateShift,
    loadShiftDetail,
    loadWorkspaceDetail,
    setSidebar,
    setModal,
    setCalendarMultiSelect,
    createShift,
    createShiftTemplate,
    updateShiftTemplate,
    addTemplateShift,
    deleteLiveShift,
    deleteTemplateShift,
    setCycleDetail,
    deleteCycleDetail,
    updateCycleDetail,
    loadPositions
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
