import {
  Alert,
  Flex,
  Avatar,
  Spacing,
  ShiftAttribute,
  Position,
  Locality
} from '@ui'
import {
  requestAvailabilityDeleteMulti,
  requestTimeOffDeleteMulti,
  requestOffersCancel
} from '@app/request'
import {
  loadRoleStatsMulti,
  deleteLiveShift,
  updateLiveShift,
  createShifts,
  createPlan,
  setModal,
  setCalendar,
  setCalendarMultiSelect,
  setAvailabilityOrTimeOff,
  addShiftAttributes,
  setSidebar
} from '@app/ac'
import {
  sortUtil,
  miscUtil,
  calendarUtil,
  notification
} from '@app/util'
import { BatchActions } from '@app/component/modals/batch-actions'
import { t } from 'i18next'
import store from '@app/store'
import moment from 'moment'

// creates multiple new shifts on selected WS according to passed shift template (requires the createShifts function from /ac/create-shifts.js to be passed as an argument)
async function createShiftsFromShiftTemplateMulti (tpl, targets) {
  const details = tpl.shift
  const dispatch = store.dispatch
  await dispatch(createShifts(
    targets.map(targ => {
      let uid = targ.userId
      if (uid === 'unassigned') uid = null
      const addDay = (!moment(details.period.start).isSame(details.period.end, 'day')) ? 1 : 0
      return {
        period: {
          start: moment(targ.date).startOf('day').hour(moment(details.period.start).hour()).minute(moment(details.period.start).minute()),
          end: moment(targ.date).add(addDay, 'days').hour(moment(details.period.end).hour()).minute(moment(details.period.end).minute())
        },
        position: details.positionId,
        locality: details.localityId,
        idealSkill: details.idealSkill,
        pauses: details.pauses ? [...details.pauses.map(p => Object.assign({}, p))] : [],
        pausesFixed: details.pausesFixed,
        customAttributes: details.customAttributes,
        note: details.note,
        user: uid,
        overTime: details.overTime,
        standBy: details.standBy,
        standByActivities: details.standByActivities,
        agenda: details.agenda
      }
    })))
  multiSelectClear()
}

const getMultiSelectSourceType = (srcEvent) => {
  if (srcEvent.name && srcEvent.shift) return 'shiftTemplate' // shift template
  if (srcEvent.positionId) return 'shift' // shift
  if (srcEvent.categoryId) return 'timeOff' // timeOff
  if (!srcEvent.categoryId) return 'unav'
  return null
}

// based on a current multiselect selection,
// returns allowed actions array: each element represents one
// currently allowed action (element.type: 'delete', 'autoplan', etc.) and its numeric value
// represents number of affected events (element.count)
function getMultiSelectAllowedActionTypes (isPluginEnabled = () => { }) {
  const { calendarMultiSelect, workspaces, workspaceId } = store.getState()

  const selectedShifts = []
  const selectedUnavs = []
  const selectedTimeOffs = []
  const selectedShiftTemplates = []
  if (Array.isArray(calendarMultiSelect.sourceEvents)) {
    calendarMultiSelect.sourceEvents.forEach(evt => {
      const srcType = getMultiSelectSourceType(evt)
      if (srcType === 'shift') selectedShifts.push(evt)
      if (srcType === 'unav') selectedUnavs.push(evt)
      if (srcType === 'timeOff') selectedTimeOffs.push(evt)
      if (srcType === 'shiftTemplate') selectedShiftTemplates.push(evt)
    })
  }

  const ret = []
  const singleSourceHasMaster = (calendarMultiSelect && calendarMultiSelect.sourceEvents && calendarMultiSelect.sourceEvents.length) && ((calendarMultiSelect.sourceEvents[0].hasOffer && calendarMultiSelect.sourceEvents[0].hasOffer.transfer && calendarMultiSelect.sourceEvents[0].hasOffer.transfer.master) || (calendarMultiSelect.sourceEvents[0].transfer && calendarMultiSelect.sourceEvents[0].transfer.master))

  // actions on shift templates
  if (selectedShiftTemplates.length !== 0) {
    // apply a shift template to calendar
    if (selectedShiftTemplates.length === 1) ret.push({ type: 'applyShiftTemplate', count: 1 })
    // update a shift template
    if (selectedShiftTemplates.length === 1) ret.push({ type: 'updateShiftTemplate', count: 1 })
  } else {
    // actions on shifts and availabilities / timeOffs
    const workspace = workspaces.find(w => w.id === workspaceId)

    const isSelectedEventInLockedPeriod = workspace?.calendarLockedUntil && selectedUnavs.concat(selectedTimeOffs).concat(selectedShifts).some(details =>
      miscUtil.isMyCalendarLocked(moment(details?.period?.start), workspace)
    )

    // updateSingleEvent
    if ((!isSelectedEventInLockedPeriod) && ((selectedShifts.length + selectedUnavs.length + selectedTimeOffs.length) === 1)) {
      ret.push({ type: 'updateSingleEvent', count: 1 })
    }

    // switchTwoShifts
    if ((!isSelectedEventInLockedPeriod) && (selectedShifts.length === 2 && selectedUnavs.length === 0 && selectedTimeOffs.length === 0)) {
      ret.push({ type: 'switchTwoShifts', count: 2 })
    }

    // batchUpdateShifts
    if (
      (!isSelectedEventInLockedPeriod) &&
      (selectedShifts.filter(s => !s.hasOffer).length > 1) &&
      (selectedUnavs.length === 0) &&
      (selectedTimeOffs.length === 0)
    ) ret.push({ type: 'batchUpdateShifts', count: selectedShifts.filter(s => !s.hasOffer).length })

    // copy
    if (
      (!isSelectedEventInLockedPeriod) &&
      (calendarMultiSelect?.sourceEvents?.length === 1) &&
      (!singleSourceHasMaster)
    ) ret.push({ type: 'copy', count: 1 })

    // unassign
    if (
      (!isSelectedEventInLockedPeriod) &&
      (selectedShifts.filter(s => !s.hasOffer && s.userId).length > 0) &&
      (selectedUnavs.length === 0) &&
      (selectedTimeOffs.length === 0) &&
      (!singleSourceHasMaster)
    ) ret.push({ type: 'unassign', count: selectedShifts.filter(s => !s.hasOffer && s.userId).length })

    if (isPluginEnabled('offers')) {
      // view offers
      if (
        (selectedShifts.filter(s => s.hasOffer && s.status !== 'resolved').length > 0) &&
        (selectedUnavs.length === 0) &&
        (selectedTimeOffs.length === 0)
      ) ret.push({ type: 'viewOffers', count: selectedShifts.filter(s => s.hasOffer && s.status !== 'resolved').length })

      // cancel offers
      if (
        (selectedShifts.filter(s => s.hasOffer && (!s.hasOffer.transfer || !s.hasOffer.transfer.master)).length > 0) &&
        (selectedUnavs.length === 0) &&
        (selectedTimeOffs.length === 0)
      ) ret.push({ type: 'cancelOffers', count: selectedShifts.filter(s => s.hasOffer && (!s.hasOffer.transfer || !s.hasOffer.transfer.master)).length })

      // start drop offers
      if (
        (!isSelectedEventInLockedPeriod) &&
        (selectedShifts.filter(s => !s.hasOffer && moment(s.period.start).isAfter(moment().add(1, 'hour'))).length > 0) &&
        (selectedUnavs.length === 0) &&
        (selectedTimeOffs.length === 0) &&
        !singleSourceHasMaster
      ) ret.push({ type: 'startDropOffers', count: selectedShifts.filter(s => !s.hasOffer && moment(s.period.start).isAfter(moment().add(1, 'hour'))).length })

      // start swap offers
      if (
        (!isSelectedEventInLockedPeriod) &&
        (selectedShifts.filter(s => !s.hasOffer && s.userId && moment(s.period.start).isAfter(moment().add(1, 'hour'))).length === 1) &&
        (selectedUnavs.length === 0) &&
        (selectedTimeOffs.length === 0) &&
        (!singleSourceHasMaster)
      ) ret.push({ type: 'startSwapOffers', count: selectedShifts.filter(s => !s.hasOffer && s.userId && moment(s.period.start).isAfter(moment().add(1, 'hour'))).length })
    }

    // autoplan
    if (
      (!isSelectedEventInLockedPeriod) &&
      (selectedShifts.filter(s => !s.hasOffer && !s.userId).length > 0) &&
      (selectedUnavs.length === 0) &&
      (selectedTimeOffs.length === 0)
    ) ret.push({ type: 'autoplan', count: selectedShifts.filter(s => !s.hasOffer && !s.userId).length })

    // deselectShifts
    if (
      (selectedTimeOffs.length + selectedUnavs.length > 0) &&
      (selectedShifts.length > 0)
    ) ret.push({ type: 'deselectShifts', count: selectedShifts.length })

    // deselectTimeOffs
    if (
      (selectedShifts.length + selectedUnavs.length > 0) &&
      (selectedTimeOffs.length > 0)
    ) ret.push({ type: 'deselectTimeOffs', count: selectedTimeOffs.length })

    // deselectUnavs
    if (
      (selectedShifts.length + selectedTimeOffs.length > 0) &&
      (selectedUnavs.length > 0)
    ) ret.push({ type: 'deselectUnavs', count: selectedUnavs.length })

    // delete
    if (
      (!isSelectedEventInLockedPeriod) &&
      (calendarMultiSelect?.sourceEvents?.length > 0) &&
      (!selectedShifts?.find(s => s.hasOffer)) &&
      (!singleSourceHasMaster)
    ) ret.push({ type: 'delete', count: calendarMultiSelect.sourceEvents.length })
  }

  return ret
}

// cancel the multiselection / clear all the selected events / targets, set action to null, and hide column's context menu if it's on
function multiSelectClear () {
  const dispatch = store.dispatch
  const { calendar } = store.getState()
  dispatch(
    setCalendarMultiSelect({
      action: null,
      sourceEvents: [],
      targets: [],
      isSelectingTargets: false,
      isActive: false
    }))

  if (calendar.contextMenuForColumn) {
    dispatch(setCalendar({ contextMenuForColumn: undefined }))
  }
}

async function multiSelectCopyShiftToTargets (details) {
  const { calendarMultiSelect } = store.getState()
  const dispatch = store.dispatch

  const shifts = calendarMultiSelect.targets.map(targ => {
    let uid = targ.userId
    if (uid === 'day') uid = details.userId
    if (uid === 'unassigned') {
      uid = null
      details.contractId = null
      details.contractType = null
    }
    if (uid === 'offers') return false
    const addDay = (!moment(details.period.start).isSame(details.period.end, 'day')) ? 1 : 0

    return {
      period: {
        start: moment(targ.date).startOf('day').hour(moment(details.period.start).hour()).minute(moment(details.period.start).minute()),
        end: moment(targ.date).add(addDay, 'days').hour(moment(details.period.end).hour()).minute(moment(details.period.end).minute())
      },
      contractId: details.contractId,
      contractType: details.contractType,
      position: details.positionId,
      locality: details.localityId,
      idealSkill: details.idealSkill,
      pauses: details.pauses ? [...details.pauses.map(p => Object.assign({}, p))] : [],
      pausesFixed: details.pausesFixed,
      note: details.note,
      user: uid,
      overTime: details.overTime,
      standBy: details.standBy,
      standByActivities: details.standByActivities,
      customAttributes: details.customAttributes,
      agenda: details.agenda
    }
  }).filter(Boolean)
  if (shifts?.length) await dispatch(createShifts(shifts))
}

async function multiSelectCopyUnavailabilityToTargets (details) {
  const { calendarMultiSelect } = store.getState()
  const dispatch = store.dispatch

  const evts = []
  for (var i = 0; i < calendarMultiSelect.targets.length; i++) {
    let uid = calendarMultiSelect.targets[i].userId
    if (uid === 'day') uid = details.userId
    if (uid === 'unassigned' || uid === 'offers') return false

    const sDateStr = calendarMultiSelect.targets[i].date
    const sTime = moment(sDateStr + 'T' + moment(details.period.start).format('HH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss')
    const eTime = moment(sDateStr + 'T' + moment(details.period.end).format('HH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss')
    if (eTime.isBefore(sTime) || eTime.isSame(sTime, 'minute')) eTime.add(1, 'day')
    evts.push({
      period: {
        start: sTime,
        end: eTime
      },
      periodType: details.periodType,
      user: uid,
      available: details.available,
      categoryId: details.categoryId,
      note: details.note,
      workMinutesBy: details.workMinutesBy,
      workMinutes: details.workMinutes
    })
  }
  const result = await dispatch(setAvailabilityOrTimeOff({ evts }))
  if (result?.conflictingShifts?.length) {
    dispatch(setModal('timeoff-conflict', {
      conflictingShifts: result.conflictingShifts,
      resultTimeOffs: result.timeOffs
    }))
  }
}

function multiSelectExecuteAction (action) {
  const dispatch = store.dispatch
  const {
    calendarMultiSelect,
    shifts,
    workspaces,
    workspaceId,
    auth,
    calendar,
    employees,
    positions
  } = store.getState()
  if (!calendarMultiSelect.sourceEvents.length) return

  // copy
  if (action === 'copy') {
    const evt = calendarMultiSelect.sourceEvents[0]
    const srcType = getMultiSelectSourceType(evt)

    if (srcType === 'shift') {
      // copy shift
      dispatch(() => {
        multiSelectCopyShiftToTargets(evt).then(() => {
          multiSelectClear()
        })
      })
    } else if (srcType === 'unav' || srcType === 'timeOff') {
      // copy availability or timeOff
      dispatch(() => {
        multiSelectCopyUnavailabilityToTargets(evt).then(() => {
          multiSelectClear()
        })
      })
    }
  }

  // applyShiftTemplate
  if (action === 'applyShiftTemplate') {
    const tpl = calendarMultiSelect.sourceEvents[0]
    createShiftsFromShiftTemplateMulti(tpl, calendarMultiSelect.targets)
  }

  // updateShiftTemplate
  if (action === 'updateShiftTemplate') {
    const tpl = calendarMultiSelect.sourceEvents[0]
    dispatch(setModal('edit-shift-template', { shiftTemplateId: tpl?.id }))
  }

  // deselectUnavs
  if (action === 'deselectShifts') {
    dispatch(setCalendarMultiSelect({
      sourceEvents: calendarMultiSelect.sourceEvents.filter(ev => getMultiSelectSourceType(ev) !== 'shift')
    }))
  }

  // deselectUnavs
  if (action === 'deselectUnavs') {
    dispatch(setCalendarMultiSelect({
      sourceEvents: calendarMultiSelect.sourceEvents.filter(ev => getMultiSelectSourceType(ev) !== 'unav')
    }))
  }

  // deselectTimeOffs
  if (action === 'deselectTimeOffs') {
    dispatch(setCalendarMultiSelect({
      sourceEvents: calendarMultiSelect.sourceEvents.filter(ev => getMultiSelectSourceType(ev) !== 'timeOff')
    }))
  }

  // delete
  if (action === 'delete') {
    const shiftIds = [...new Set(calendarMultiSelect.sourceEvents.filter(r => getMultiSelectSourceType(r) === 'shift').map(r => r.id))]
    const availIds = [...new Set(calendarMultiSelect.sourceEvents.filter(r => getMultiSelectSourceType(r) === 'unav' && (calendarUtil.isEventAvailabilityOrTimeOff(r) === 'availability' || calendarUtil.isEventAvailabilityOrTimeOff(r) === 'unavailability')).map(r => r.id))]
    const timeOffIds = [...new Set(calendarMultiSelect.sourceEvents.filter(r => getMultiSelectSourceType(r) === 'timeOff' && calendarUtil.isEventAvailabilityOrTimeOff(r) === 'timeOff').map(r => r.id))]
    const eventTypesCount = (shiftIds.length > 0) + (availIds.length > 0) + (timeOffIds.length > 0)

    dispatch(setModal('confirm', {
      title: t('MULTI_ACTION_DEL_CONFIRM_TITLE'),
      type: Alert.TYPES.ERROR,
      confirmLabel: t('DELETE'),
      repeatingTimeOffs: true,
      onConfirm: async (opts) => {
        let deletedSomething = false
        let errorHappened = false

        // delete shift instances
        if (shiftIds?.length && (!opts?.whatToDelete || opts?.whatToDelete === 'all' || opts?.whatToDelete === 'shifts')) {
          deletedSomething = true
          await dispatch(deleteLiveShift(null, shiftIds))
        }
        // delete availability/unavailability instances
        if (availIds?.length && (!opts?.whatToDelete || opts?.whatToDelete === 'all' || opts?.whatToDelete === 'avails')) {
          deletedSomething = true
          const r = await requestAvailabilityDeleteMulti({
            workspace: workspaceId,
            data: availIds.map(i => { return { id: i, recurrenceRemoveFollowing: false } })
          }, auth)
          if (r?.error) errorHappened = true
        }
        // delete timeOff instances
        if (timeOffIds?.length && (!opts?.whatToDelete || opts?.whatToDelete === 'all' || opts?.whatToDelete === 'timeOffs')) {
          deletedSomething = true
          const r = await requestTimeOffDeleteMulti({
            workspace: workspaceId,
            data: timeOffIds.map(i => ({ id: i, recurrenceRemoveFollowing: false }))
          }, auth)
          if (r?.error) errorHappened = true
        }

        // if we deleted some unavs, we need to reload the role stats, because the deletion might have changed the numbers there
        if (deletedSomething && calendar && calendar.date) {
          dispatch(loadRoleStatsMulti(calendar.date))
        }

        // display a success message here if we deleted soething else than shifts (shifts deletion already has a success message displayed in deleteLiveShift() function)
        if (deletedSomething && !errorHappened && !shiftIds?.length) {
          notification.success({ code: 'success' })
        }
      },

      cancelLabel: t('CANCEL'),
      onCancel: () => { },
      subtitle: t('MULTI_ACTION_DEL_CONFIRM_DELETE_SUBTITLE_X', { x: calendarMultiSelect.sourceEvents.length }),
      recordsList: calendarMultiSelect.sourceEvents,
      onConfirmBonusOptions: eventTypesCount > 1
        ? [
          { label: t('ALL') + ' (' + calendarMultiSelect.sourceEvents?.length + ')', onConfirmFnParamsObject: { whatToDelete: 'all' } },
          (shiftIds.length > 0 ? { label: t('EMPLOYEE_PROFILE_CARD_SHIFTS') + ' (' + shiftIds.length + ')', onConfirmFnParamsObject: { whatToDelete: 'shifts' } } : null),
          (availIds.length > 0 ? { label: t('WS_SETTINGS_AVAIL_MODE_UNAV') + ' (' + availIds.length + ')', onConfirmFnParamsObject: { whatToDelete: 'avails' } } : null),
          (timeOffIds.length > 0 ? { label: t('TIMEOFFS') + ' (' + timeOffIds.length + ')', onConfirmFnParamsObject: { whatToDelete: 'timeOffs' } } : null)
        ].filter(Boolean)
        : undefined,
      onConfirmBonusOptionsTitle: t('DELETE'),
      buttonColor: 'red',
      overSidebar: true

    }))

    multiSelectClear()
  }

  // unassign
  if (action === 'unassign') {
    dispatch(updateLiveShift(null, { userId: null }, calendarMultiSelect.sourceEvents.filter(evt => !evt.hasOffer && evt.userId && shifts.find(s => s.id === evt.id)).map(evt => evt.id)))
    multiSelectClear()
  }

  // switchTwoShifts
  if (action === 'switchTwoShifts') {
    const s1 = Object.assign({}, calendarMultiSelect.sourceEvents[0])
    const s2 = Object.assign({}, calendarMultiSelect.sourceEvents[1])

    const newS1Start = moment(s1.period.start).clone().set({ year: parseInt(moment(s2.period.start).format('YYYY')), month: parseInt(moment(s2.period.start).format('M')) - 1, date: parseInt(moment(s2.period.start).format('D')) })
    const newS1Period = {
      start: newS1Start,
      end: newS1Start.clone().add(moment(s1.period.end).diff(s1.period.start, 'minutes'), 'minutes')
    }
    const newS2Start = moment(s2.period.start).clone().set({ year: parseInt(moment(s2.period.start).format('YYYY')), month: parseInt(moment(s1.period.start).format('M')) - 1, date: parseInt(moment(s1.period.start).format('D')) })
    const newS2Period = {
      start: newS2Start,
      end: newS2Start.clone().add(moment(s2.period.end).diff(s2.period.start, 'minutes'), 'minutes')
    }

    dispatch(updateLiveShift(s1.id, {
      userId: s2.userId,
      period: newS1Period
    }))

    dispatch(updateLiveShift(s2.id, {
      userId: s1.userId,
      period: newS2Period
    }))
    multiSelectClear()
  }

  // view existing offers
  if (action === 'viewOffers') {
    const relevantShifts = calendarMultiSelect.sourceEvents.filter(evt => evt.hasOffer && evt.hasOffer.status !== 'resolved' && shifts.find(s => s.id === evt.id))
    const offers = relevantShifts.map(s => s.hasOffer)
    dispatch(setModal('offers', {
      selectedShiftIds: relevantShifts.map(s => s.id),
      action: offers.find(off => off.status === 'managerRequestApproval')
        ? 'approve'
        : offers.find(off => off.status === 'managerResultApproval')
          ? 'resolve'
          : offers.find(off => off.status === 'employeeReplies')
            ? 'show'
            : 'create'
    }))
    multiSelectClear()
  }

  // cancel offers
  if (action === 'cancelOffers') {
    const offersToResolve = calendarMultiSelect.sourceEvents.filter(evt => evt.hasOffer && (!evt.hasOffer.transfer || !evt.hasOffer.transfer.master))
    requestOffersCancel({
      workspace: workspaceId,
      offers: offersToResolve.map(s => s.hasOffer.id)
    }, auth).then(res => {
      // and also, unassign the users from shifts once all the offers are resolved
      dispatch(updateLiveShift(null, { userId: null }, offersToResolve.filter(evt => shifts.find(s => s.id === evt.id)).map(evt => evt.id)))

      multiSelectClear()
    })
  }

  // start drop offers
  if (action === 'startDropOffers') {
    const relevantShifts = calendarMultiSelect.sourceEvents.filter(evt => !evt.hasOffer && shifts.find(s => s.id === evt.id && moment(s.period.start).isAfter(moment().add(1, 'hour'))))
    dispatch(setModal('offers', {
      selectedShiftIds: relevantShifts.map(s => s.id),
      action: 'create',
      createType: 'drop'
    }))
    multiSelectClear()
  }

  // start swap offers
  if (action === 'startSwapOffers') {
    const relevantShifts = calendarMultiSelect.sourceEvents.filter(evt => !evt.hasOffer && shifts.find(s => s.id === evt.id && moment(s.period.start).isAfter(moment().add(1, 'hour'))))
    dispatch(setModal('offers', {
      selectedShiftIds: relevantShifts.map(s => s.id),
      action: 'create',
      createType: 'swap'
    }))
    multiSelectClear()
  }

  // autoplan
  if (action === 'autoplan') {
    const relevantShifts = calendarMultiSelect.sourceEvents.filter(evt => !evt.hasOffer && !evt.userId && shifts.find(s => s.id === evt.id))
    let earliest = null
    let latest = null
    relevantShifts.forEach(s => {
      if (!earliest || moment(earliest).isAfter(s.period.start)) earliest = moment(s.period.start)
      if (!latest || moment(earliest).isBefore(s.period.end)) latest = moment(s.period.end)
    })
    if (earliest && latest) {
      dispatch(createPlan({
        type: 'selected',
        data: {
          selectedShifts: relevantShifts.map(s => s.id),
          period: { start: moment(earliest).startOf('day'), end: moment(latest).endOf('day') }
          // nothing else is specified here, which makes the planner use defaults
        }
      }))

      dispatch(setSidebar('planning'))
    }
  }

  if (action === 'batchUpdateShifts') {
    const batchUpdateStartEndValues = []
    for (var m = 0; m < 1440; m += 30) {
      batchUpdateStartEndValues.push(moment().startOf('day').add(m, 'minutes').format('HH:mm'))
    }

    const batchUpdatesSkillValues = [
      { label: t('SKILL_LETTER_1') + ': ' + t('SKILL_NAME_1'), value: 1 },
      { label: t('SKILL_LETTER_2') + ': ' + t('SKILL_NAME_2'), value: 2 },
      { label: t('SKILL_LETTER_3') + ': ' + t('SKILL_NAME_3'), value: 3 },
      { label: t('SKILL_LETTER_4') + ': ' + t('SKILL_NAME_4'), value: 4 },
      { label: t('SKILL_LETTER_5') + ': ' + t('SKILL_NAME_5'), value: 5 },
      { label: t('SKILL_ANY'), value: 'any' }]

    const workspace = workspaces.find(w => w.id === workspaceId)

    dispatch(setModal('batch-actions', {
      records: calendarMultiSelect.sourceEvents,
      actions: [
        // set employee
        {
          actionLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_USERID'),
          actionFunction: async (recs, param) => {
            dispatch(updateLiveShift(null, { userId: param }, recs.map(s => s.id)))
          },
          actionParameterLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_USERID'),
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.OPTIONS_LIST,
          actionParameterListOptions: sortUtil.sortEmployees(Object.values(employees).filter((ee) => !ee.hidden && !ee.external)).map(ee => ee.id).map(empId => {
            return {
              value: empId,
              label: (
                <Flex align={Flex.POSITION.CENTER}>
                  <Avatar profile={employees[empId]} size='20' />
                  <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL}>
                    {employees[empId].name}
                  </Spacing>
                </Flex>
              )
            }
          }).concat([{ value: null, label: t('MULTI_ACTION_UNASSIGN') }]),
          isDisabled: (recs, param) => false
        },
        // set start time
        {
          actionLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_START'),
          actionFunction: async (recs, param) => {
            const dates = [] // updating start/end on multiple shifts can be complicated, because they can be in multiple days  and the API only supports the whole datetime strings
            recs.forEach(evt => {
              const d = moment(evt.period.start).format('YYYY-MM-DD')
              if (!dates.includes(d)) dates.push(d)
            })
            dates.forEach(d => {
              const evtsWithDate = recs.filter(evt => moment(evt.period.start).format('YYYY-MM-DD') === d).map(evt => evt.id)
              const update = { period: {} }
              const updTime = moment('1970-01-01T' + param)
              const updTimeDate = moment(d).set({ hour: updTime.hour(), minute: updTime.minute() })
              update.period.start = updTimeDate
              dispatch(updateLiveShift(null, update, evtsWithDate, true))
            })
          },
          actionParameterLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_START'),
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.TIME,
          isDisabled: (recs, param) => !moment('1970-01-01T' + param).isValid()
        },
        // set end time
        {
          actionLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_END'),
          actionFunction: async (recs, param) => {
            const dates = [] // updating start/end on multiple shifts can be complicated, because they can be in multiple days  and the API only supports the whole datetime strings
            recs.forEach(evt => {
              const d = moment(evt.period.end).format('YYYY-MM-DD')
              if (!dates.includes(d)) dates.push(d)
            })
            dates.forEach(d => {
              const evtsWithDate = recs.filter(evt => moment(evt.period.end).format('YYYY-MM-DD') === d).map(evt => evt.id)
              const update = { period: {} }
              const updTime = moment('1970-01-01T' + param)
              const updTimeDate = moment(d).set({ hour: updTime.hour(), minute: updTime.minute() })
              update.period.end = updTimeDate
              dispatch(updateLiveShift(null, update, evtsWithDate, true))
            })
          },
          actionParameterLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_END'),
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.TIME,
          isDisabled: (recs, param) => !moment('1970-01-01T' + param).isValid()
        },
        // delete pauses
        {
          actionLabel: (t('DELETE') + ' ' + t('PAUSES_SHORT')).toLowerCase(),
          actionFunction: async (recs, param) => {
            dispatch(updateLiveShift(null, { pauses: [], pausesFixed: true }, recs.map(s => s.id)))
          },
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.NONE,
          isDisabled: (recs, param) => false
        },

        // set attribute
        {
          actionLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_ATTRIBUTE'),
          actionFunction: async (recs, param) => {
            if (param === 'noAttribute') {
              dispatch(updateLiveShift(null, {
                customAttributes: []
              }, recs.map(evt => evt.id)))
            } else {
              dispatch(addShiftAttributes(recs.map(evt => evt.id), [param]))
            }
          },
          actionParameterLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_ATTRIBUTE'),
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.OPTIONS_LIST,
          actionParameterListOptions: calendarUtil.getSupportedShiftAttributes(workspace).map(att => {
            return {
              value: att.id,
              label: <ShiftAttribute attribute={att} hideDeletion />
            }
          }).concat([{
            value: 'noAttribute',
            label: <>{t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_ATTRIBUTE_NOATT')}</>
          }]),
          isDisabled: (recs, param) => !param
        },
        // set position
        {
          actionLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_POSITION_ID'),
          actionFunction: async (recs, param) => {
            dispatch(updateLiveShift(null, { positionId: param }, recs.map(s => s.id)))
          },
          actionParameterLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_POSITION_ID'),
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.OPTIONS_LIST,
          actionParameterListOptions: positions.filter(p => !p.archived).map(pos => {
            return {
              value: pos.id,
              label: <Position {...pos} />
            }
          }),
          isDisabled: (recs, param) => !param
        },
        // set locality
        {
          actionLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_LOCALITYID'),
          actionFunction: async (recs, param) => {
            dispatch(updateLiveShift(null, { localityId: param }, recs.map(s => s.id)))
          },
          actionParameterLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_LOCALITYID'),
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.OPTIONS_LIST,
          actionParameterListOptions: workspace.localities.map(loc => {
            return {
              value: loc.id,
              label: <Locality {...loc} />
            }
          }).concat([{
            value: null,
            label: <>{t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_LOCALITYID_NOLOC')}</>
          }]),
          isDisabled: (recs, param) => false
        },
        // set ideal skill
        {
          actionLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_IDEALSKILL'),
          actionFunction: async (recs, param) => {
            dispatch(updateLiveShift(null, { idealSkill: param }, recs.map(s => s.id)))
          },
          actionParameterLabel: t('MULTI_ACTION_SELECT_BATCH_UPDATE_TYPE_IDEALSKILL'),
          actionParameterType: BatchActions.ACTION_PARAMETER_TYPES.OPTIONS_LIST,
          actionParameterListOptions: batchUpdatesSkillValues,
          isDisabled: (recs, param) => !param
        }
      ]

    }))
  }
}

export default {
  createShiftsFromShiftTemplateMulti,
  getMultiSelectAllowedActionTypes,
  multiSelectClear,
  multiSelectExecuteAction,
  multiSelectCopyShiftToTargets,
  multiSelectCopyUnavailabilityToTargets
}
