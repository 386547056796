import aT from '../const/action-type'
import initial from '../store/initial'
//
export default (state = initial.workspaces, action) => {
  switch (action.type) {
    case aT.SET_WORKSPACES:
      return [...action.data]
    case aT.RESET_STORE:
      return initial.workspaces
    default:
      return state
  }
}
