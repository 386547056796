import { bindActionCreators, compose } from 'redux'
import { reduxForm, getFormSyncErrors, change, getFormValues, getFormMeta } from 'redux-form'
import { connect } from 'react-redux'
import { RequiredFieldValidation } from '@app/const/validation'
import setModal from '@app/ac/set-modal'
import loadShifts from '@app/ac/load-shifts'
import { WithMapper } from '@core/hoc/withMapper'
import { withPlugin } from '@core/hoc/withPlugin'
import { SetFeeds, AddFeed, UpdateFeed } from '@app/ac/set-news-feed'
import { NEWS_FEED_MODAL, SUBJECT, AUDIENCE_BY, TEXT } from './constants'
import { VALUES, ERRORS, META } from '@app/const/globals'

const mapStateToProps = (state) => {
  const { auth, employees, workspaceId, positions, workspaces, shifts, me } = state
  return {
    [ERRORS]: getFormSyncErrors(NEWS_FEED_MODAL)(state),
    [VALUES]: getFormValues(NEWS_FEED_MODAL)(state),
    [META]: getFormMeta(NEWS_FEED_MODAL)(state),
    auth,
    employees,
    positions,
    workspaceId,
    workspaces,
    shifts,
    me
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeFormField: (field, value) => change(NEWS_FEED_MODAL, field, value),
    setModal,
    loadShifts,
    AddFeed,
    SetFeeds,
    UpdateFeed
  }, dispatch)
}

export default (component) => {
  return compose(
    WithMapper({
      shaver: 'name'
    }),
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: NEWS_FEED_MODAL,
      validate: ({
        [SUBJECT]: subject,
        [AUDIENCE_BY]: audienceBy,
        [TEXT]: text
      }) => {
        return {
          [SUBJECT]: RequiredFieldValidation(subject),
          [AUDIENCE_BY]: RequiredFieldValidation(audienceBy),
          [TEXT]: RequiredFieldValidation(text)
        }
      }
    }))(component)
}
