import ColorHash from 'color-hash'
import { COLORS, STYLES } from '@app/const/globals'

const colorHash = new ColorHash()

function getDefaultColors () {
  return COLORS
}

function contrastText (color) {
  if (color[0] === '#') {
    color = color.substring(1)
  }
  const r = color.substring(0, 2)
  const g = color.substring(2, 4)
  const b = color.substring(4, 6)

  const contrast = Math.sqrt((r * r * 0.241) + (g * g * 0.691) + (b * b * 0.068))
  return contrast > 165 ? 'dark' : 'light'
}

function idToColor (id, objects) {
  if (!id) return '666666'
  if (objects) {
    const obj = objects.find((p) => p.id === id)
    const objCol = obj && obj.color ? obj.color : null
    if (objCol) return objCol
  }
  return colorHash.hex(id.slice(-6)).slice(-6)
}

function getProgressColor (progress = 0, breakpoints = [
  { minProgress: 0, color: STYLES.colors.red },
  { minProgress: 41, color: STYLES.colors.orange },
  { minProgress: 91, color: STYLES.colors.green }
]) {
  if (typeof progress !== 'number') {
    throw new Error('Invalid type. Progress must be a number.')
  }

  return breakpoints.find((breakpoint, index) => {
    const nextBreakpoint = breakpoints[index + 1]

    if (!nextBreakpoint) {
      return breakpoint
    }

    if (progress >= breakpoint.minProgress && progress < nextBreakpoint.minProgress) {
      return breakpoint
    }
  }).color
}

export default {
  contrastText,
  idToColor,
  getDefaultColors,
  getProgressColor
}
