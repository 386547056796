import store from '../store'
import isLoading from './is-loading'
import loadPositions from './load-positions'
import requestPositionDelete from '../request/position-delete'
import addError from '../ac/add-error'

export default (id, form) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return

    dispatch(isLoading('delete-position'))
    const result = await requestPositionDelete({
      workspace: workspaceId,
      id
    }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      return
    }

    await dispatch(loadPositions())
    dispatch(isLoading('save-position', true))
  }
}
