import React from 'react'
import { Row } from './Row'
import { Cell } from './Cell'
import { calcStickyProperties } from '@ui/table/helpers'

export const TableBody = ({
  getTableBodyProps,
  tableData,
  prepareRow,
  rowOnMouseLeave,
  editedRows,
  setRows
}) => (
  <div className='table-body' {...getTableBodyProps()}>
    {tableData.map((row) => {
      prepareRow(row)
      return (
        <Row
          key={'tab_' + row.id}
          rowOnMouseLeave={rowOnMouseLeave}
          className='row'
          {...row.getRowProps()}
        >
          {row.cells.map((cell, cellIndex) => {
            const cellId = cell.column.id
            const rowId = cell.row.id
            const desiredRowIndex = editedRows.findIndex(
              (row) => row.id === rowId
            )
            const handleChange = (value) => {
              const tempRows = [...editedRows]
              if (desiredRowIndex >= 0) {
                tempRows[desiredRowIndex][cellId] = value
                setRows(tempRows)
              } else {
                setRows([
                  ...editedRows,
                  {
                    id: rowId,
                    rowId: cell.row.original.id,
                    [cellId]: value
                  }
                ])
              }
            }
            const {
              isNextSticky,
              isLast,
              stickyOffset
            } = calcStickyProperties(cellIndex, row?.cells)
            return (
              <Cell
                key={'tab_' + row.id + '_' + cell.column.id}
                cell={cell}
                originalRow={row.original}
                editRows={handleChange}
                editValue={editedRows[desiredRowIndex]}
                allEditedRows={editedRows}
                isSticky={cell.column.sticky || (isNextSticky && cell.column.id === 'selection')}
                isLast={isLast}
                stickyOffset={stickyOffset}
                isNextSticky={isNextSticky}
              />
            )
          })}
        </Row>
      )
    })}
  </div>
)
