import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { Icon } from '@ui'

export const NoData = ({ customNoDataText }) => {
  const [delayed, setDelay] = useState(false)
  useEffect(() => {
    const t = setTimeout(() => setDelay(true), 300)
    return () => { clearTimeout(t) }
  }, [])
  return delayed && (
    <div className='no-data'>
      <div className='container'>
        <Icon
          ico={Icon.ICONS.warning}
          size={Icon.SIZES.EXTRA}
        />
        <div>{t('TABLE_NO_DATA')}</div>
        {customNoDataText && (
          <div className='custom'>
            {customNoDataText}
          </div>
        )}
      </div>
    </div>
  )
}
