import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setModal from '@app/ac/set-modal'
import saveShiftAttribute from '@app/ac/save-shift-attribute'
import deleteShiftAttribute from '@app/ac/delete-shift-attribute'
import loadShifts from '@app/ac/load-shifts'
import loadEmployees from '@app/ac/load-employees'

const mapStateToProps = ({ loading, workspaces, workspaceId, positions, shifts }) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    isLoading: loading.includes('workspace-detail') || loading.includes('load-employees') || loading.includes('load-shifts') || loading.includes('save-shift-attribute') || loading.includes('delete-shift-attribute'),
    workspace: ws,
    positions,
    shifts
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    saveShiftAttribute,
    deleteShiftAttribute,
    loadShifts,
    loadEmployees
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
