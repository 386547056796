import { setOrg } from '@app/action'
import {
  requestOrganizationRoleAssign,
  requestOrganizationRoleAssignDelete,
  requestOrganizationRoleCreate,
  requestOrganizationRoleDelete,
  requestOrganizationRoleUpdate
} from '@app/request'
import {
  addError,
  getOrganization,
  setModal
} from '@app/ac'

import store from '../store'
import { organizationPermissions } from '@app/const'

export const createRole = (form) => {
  return async (dispatch) => {
    const { auth, organization, organizationId } = store.getState()
    const oldOrg = { ...organization }

    const newOrg = (newItem) => ({
      ...oldOrg,
      roles: [
        ...oldOrg.roles,
        newItem
      ]
    })
    const result = await requestOrganizationRoleCreate({ ...form, organizationId, permissions: [] }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    } else {
      dispatch(setOrg(newOrg(result)))
      dispatch(setModal(null))
    }
  }
}

export const deleteRole = (id) => {
  return async (dispatch) => {
    const { organization, organizationId, auth } = store.getState()
    const oldOrg = { ...organization }
    const newOrg = ({
      ...oldOrg,
      roles: oldOrg.roles.filter((role) => role.id !== id)
    })
    dispatch(setOrg(newOrg))
    const result = await requestOrganizationRoleDelete({ organization: organizationId, role: id }, auth)
    if (result && result.error) {
      dispatch(addError(result.error))
      dispatch(setOrg(oldOrg))
    }
  }
}

export const updateRole = (data) => {
  return async (dispatch) => {
    const { organizationId, auth, organization } = store.getState()

    await updateAssigns(organization, organizationId, data, auth)

    for (const row of data) {
      let permissions = [...row.rowId.permissions]
      Object.entries(row)
        .map(([key, value]) => {
          if (
            key !== 'id' &&
            key !== 'rowId' &&
            key !== 'name' &&
            key !== 'manager'
          ) {
            const allPossibleKeyOptions = organizationPermissions[key].map((item) => item.value)
            if (Array.isArray(value)) {
              if (!value.length) {
                const currentPermissions = allPossibleKeyOptions
                  .filter((item) => row.rowId.permissions.includes(item))
                permissions = permissions.filter((item) => !currentPermissions.includes(item))
              } else {
                const selectedPerm = value.map((item) => item.value)
                const addList = selectedPerm.filter((x) => !permissions.includes(x))
                const oldPlusNew = [...permissions, ...addList]
                const allPossible = allPossibleKeyOptions.filter((x) => permissions.includes(x))
                const difference = allPossible.filter((x) => !selectedPerm.includes(x))
                const final = oldPlusNew.filter((item) => !difference.includes(item))
                permissions = final
              }
            } else {
              return value.value
            }
          }
        })

      await requestOrganizationRoleUpdate({
        organizationRoleUpdateOrganization2: organizationId,
        organizationRoleUpdateRole2: row.rowId.id,
        ...(row.name && { name: row.name }),
        ...(permissions.length && { permissions: permissions })
      }, auth)
    }

    dispatch(getOrganization(false))
  }
}

const updateAssigns = async (organization, organizationId, data, auth) => {
  const orgRoles = organization.roles.map((role) => ({
    id: role.id,
    assigns: role.assigns.map((ass) => ass.userId)
  }))

  const roles = data.map((row) => {
    if (row.manager) {
      if (row.manager.length) {
        return row.manager
          .map((manager) => ({
            roleId: row.rowId.id,
            ...(manager.email ? { email: manager.value } : { userId: manager.value })
          }))
      } else {
        return ({
          roleId: row.rowId.id,
          noManager: true
        })
      }
    }
  })
    .flat()
    .filter((item) => !!item)
  const newAssigns = roles.filter((role) => {
    const desiredRole = orgRoles.find((item) => item.id === role.roleId)
    return desiredRole && !desiredRole.assigns.includes(role.userId)
  }).filter((item) => !item.noManager)
  const deleteAssigns = orgRoles
    .filter((role) => roles.map((editRole) => editRole.roleId).includes(role.id))
    .map((item) => {
      const desiredRole = roles.filter((role) => item.id === role.roleId).map((b) => b.userId)
      const assigns = desiredRole.length ? item.assigns.filter((c) => !desiredRole.includes(c)) : item.assigns
      return ({
        id: item.id,
        assigns
      })
    })
    .filter((item) => !!item)
    .map((orgRole) => orgRole.assigns.map((item) => ({
      roleId: orgRole.id,
      userId: item
    })))
    .flat()

  newAssigns.length && await requestOrganizationRoleAssign({
    organization: organizationId,
    roles: newAssigns
  }, auth).catch((e) => addError(e))

  deleteAssigns.length && await requestOrganizationRoleAssignDelete({
    organization: organizationId,
    roles: deleteAssigns
  }, auth).catch((e) => addError(e))
}
