import fetchRequest from '../util/fetch'
import errUtil from '../util/error'
import q from './query'

async function requestShifts (variables, token, options) {
  const query = `query ShiftsQuery (
  $id: ID!,
  $period: PeriodFreeInput!,
  $user: ID,
  $users: [ID],
  $locality: ID,
  $useOrFilter: Boolean
) {
  workspace(
    id: $id
  ) {
    shifts (
      period: $period
      user: $user
      users: $users
      locality: $locality
      useOrFilter: $useOrFilter
      uncommitted: ${(typeof options?.includeUncommitted !== 'undefined' ? Boolean(options?.includeUncommitted).toString() : 'true')}
    ) {
      ${q.SHIFT_PARAMS_BASIC}

      ${(options?.withHistory) ? q.SHIFT_PARAMS_HISTORY : ''}
      ${(options?.withAttendance) ? q.SHIFT_PARAMS_ATTENDANCE : ''}
      ${(options?.withStats) ? q.SHIFT_PARAMS_STATS : ''}

      transfer {
        ${q.OFFER_PARAMS_TRANSFER}
      }
    }
  }
}`

  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shifts', errors)
  }
  return data.workspace.shifts
}

export default requestShifts
