import aT from '../const/action-type'
import initial from '../store/initial'
import arraySort from 'array-sort'

export default (state = initial.newsFeed, action) => {
  const { data, type } = action
  switch (type) {
    case aT.SET_NEWS_FEED:
      return arraySort([...data], ['pinned', 'created'], { reverse: true })
    case aT.ADD_NEWS_FEED:
      return arraySort([{ ...data }, ...state], ['pinned', 'created'], { reverse: true })
    case aT.UPDATE_NEWS_FEED:
      return arraySort([{ ...data }, ...state.filter((item) => item.id !== data.id)], ['pinned', 'created'], { reverse: true })
    case aT.DELETE_NEWS_FEED:
      return arraySort([...state.filter((item) => item.id !== data)], ['pinned', 'created'], { reverse: true })
    case aT.COMMENT_NEWS_FEED:
      return arraySort([
        {
          ...state.find((item) => item.id === data.id),
          reactions: [
            ...state.find((item) => item.id === data.id).reactions.concat([data?.reaction].filter(Boolean))
          ]
        },
        ...state.filter((item) => item.id !== data.id)
      ], ['pinned', 'created'], { reverse: true })
    case aT.LIKE_NEWS_FEED:
      return arraySort([
        {
          ...state.find((item) => item.id === data.id),
          likes: [
            ...state.find((item) => item.id === data.id).likes.filter((like) => like.userId !== data.userId).concat(data.like)
          ].filter((likes) => !!Object.values(likes).length)
        },
        ...state.filter((item) => item.id !== data.id)
      ], ['pinned', 'created'], { reverse: true })
    case aT.READ_NEWS_FEED:
      return arraySort([
        {
          ...state.find((item) => item.id === data.id),
          targets: [
            ...state.find((item) => item.id === data.id).targets.map((target) => target.userId === data.userId ? { ...data.target } : { ...target })
          ]
        },
        ...state.filter((item) => item.id !== data.id)
      ], ['pinned', 'created'], { reverse: true })
    case aT.PIN_NEWS_FEED:
      return arraySort([
        {
          ...state.find((item) => item.id === data.id),
          pinned: data.pinned
        },
        ...state.filter((item) => item.id !== data.id)
      ], ['pinned', 'created'], { reverse: true })
    default:
      return state
  }
}
