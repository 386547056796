import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query HolidaysQuery (
    $country: String!
    $period: PeriodInput!
    $lang: Language
  ) {
  holidays(
    country: $country
    period: $period
    lang: $lang
  ) {
    date
    name
    type
  }
}`

async function requestHolidays (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('holidays', errors)
  }
  return data.holidays
}

export default requestHolidays
