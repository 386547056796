import React, { useState } from 'react'
import { t } from 'i18next'

import {
  Button,
  Dropdown,
  Flex,
  Input,
  Modal,
  PhoneInput,
  Spacing,
  Text
} from '@ui'
import { getCountryOptions } from '@app/component/pages/organization/helpers'

import { requestExternalContactCreate } from '@app/request/organization'

import connect from './connect'
import { notification } from '@app/util'

export const AcountantAdd = connect(({
  countries,
  setModal,
  organizationId,
  getOrganization,
  auth
}) => {
  const [country, setCountry] = useState(getCountryOptions(countries)[0])
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    type: 'accountant'
  })
  const handleSave = async () => {
    const res = await requestExternalContactCreate({
      country: country.value,
      data: form,
      organization: organizationId
    }, auth)
    if (res) {
      notification.success({ code: 'success' })
      await getOrganization()
      setModal(null)
    }
  }
  return (
    <Modal
      size={Modal.SIZES.M}
      footerContent={
        <Flex grow={1}>
          <Button
            size={Button.SIZES.FULL}
            onClick={() => setModal(null)}
            label={t('CLOSE')}
          />
          <Button
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
            onClick={() => handleSave()}
            label={t('ADD')}
          />
        </Flex>
      }
      headerContent={
        <Text
          text={t('ORG_SP_PEOPLE_ACCOUNTANT_ADD')}
          weight={Text.WEIGHTS.BOLD}
          type={Text.TYPES.BODY_LARGE}
        />
      }
    >
      <Spacing
        size={Spacing.SIZES.SIZE_16}
        type={Spacing.TYPES.BOTH}
      >
        <Input
          size={Input.SIZES.FULL_WIDTH}
          value={form.firstName}
          label={t('FIRST_NAME')}
          placeholder='Karel'
          onChange={(val) => setForm({ ...form, firstName: val })}
        />
        <Spacing size={Spacing.SIZES.SIZE_14} />
        <Input
          size={Input.SIZES.FULL_WIDTH}
          value={form.lastName}
          label={t('LAST_NAME')}
          placeholder='Novak'
          onChange={(val) => setForm({ ...form, lastName: val })}
        />
        <Spacing size={Spacing.SIZES.SIZE_14} />
        <Dropdown
          style={Dropdown.STYLES.LIGHT}
          type={Dropdown.TYPES.VARIABLE}
          size={Dropdown.SIZES.FULL_WIDTH}
          singleSelect
          options={getCountryOptions(countries)}
          value={country}
          label={t('WS_SETTINGS_WS_COUNTRY')}
          placeholder={t('CHOOSE')}
          onChange={(val) => setCountry(val)}
        />
        <Spacing size={Spacing.SIZES.SIZE_14} />
        <Input
          value={form.email}
          size={Input.SIZES.FULL_WIDTH}
          label={t('EMAIL')}
          placeholder={t('EMAIL_PLACEHOLDER')}
          onChange={(val) => setForm({ ...form, email: val })}
        />
        <Spacing size={Spacing.SIZES.SIZE_14} />
        <PhoneInput
          value={form.telephone}
          label={t('PHONE')}
          onChange={(val) => setForm({ ...form, telephone: val })}
        />
      </Spacing>
    </Modal>
  )
})

AcountantAdd.propTypes = {}
AcountantAdd.defaultProps = {}
