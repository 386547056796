import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation OrganizationRoleAssignDeleteMulti($organization: ID!, $roles: [RoleAssignDelete!]!) {
  organizationRoleAssignDeleteMulti(organization: $organization, roles: $roles) {
    id
  }
}`

async function requestOrganizationRoleAssignDelete (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organizationRoleAssignDeleteMulti
}

export default requestOrganizationRoleAssignDelete
