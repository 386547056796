import { PositionEditor } from '@app/component/core'
import { Sidebar } from '@ui'

import './position-editor-sidebar.scss'

export function PositionEditorSidebar (props) {
  return (
    <Sidebar
      onClose={props.onClose}
      width='17.375rem'
      side={Sidebar.SIDES.RIGHT}
      contentClassName='ds-position-editor-sidebar'
    >
      <PositionEditor
        onClose={props.onClose}
        afterSubmit={props.sidebarData.afterSubmit}
        positionId={props.sidebarData.positionId}
      />
    </Sidebar>
  )
}
