import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation ShiftApproveMulti (
  $workspace: ID!,
  $shifts: [ID!]!,
) {
  shiftUpdateMultiAttendanceApprove (
    workspace: $workspace
    ids: $shifts
  ) {
    id
  }
}`

async function requestShiftApproveMulti (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('shift-approve-multi', errors)
  }
  return data.shiftUpdateMultiAttendanceApprove
}

export default requestShiftApproveMulti
