"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccessRole = void 0;
const react_redux_1 = require("react-redux");
const useAccessRole = (defaultPermission) => {
    const meAclWorkspaces = (0, react_redux_1.useSelector)(({ me }) => me.acl.workspaces, react_redux_1.shallowEqual);
    const workspaceId = (0, react_redux_1.useSelector)(({ workspaceId }) => workspaceId, react_redux_1.shallowEqual);
    const aclWorkspaces = meAclWorkspaces && Object.keys(meAclWorkspaces);
    const currentWsPermissions = (aclWorkspaces === null || aclWorkspaces === void 0 ? void 0 : aclWorkspaces.length) ? meAclWorkspaces[workspaceId] : [];
    const meAclOrgs = (0, react_redux_1.useSelector)(({ me }) => me.acl.organizations, react_redux_1.shallowEqual);
    const organizationId = (0, react_redux_1.useSelector)(({ organizationId }) => organizationId, react_redux_1.shallowEqual);
    const aclOrganizations = meAclOrgs && Object.keys(meAclOrgs);
    const currentOrgPermissions = (aclOrganizations === null || aclOrganizations === void 0 ? void 0 : aclOrganizations.length) ? meAclOrgs[organizationId] : [];
    const orgCanAccess = (permission) => {
        if (typeof permission === 'string') {
            return currentOrgPermissions === null || currentOrgPermissions === void 0 ? void 0 : currentOrgPermissions.includes(`${permission}`);
        }
        else {
            return currentOrgPermissions === null || currentOrgPermissions === void 0 ? void 0 : currentOrgPermissions.some((orgPermission) => {
                return Object.values(permission).some((permissionItem) => {
                    return orgPermission === `${permissionItem}`;
                });
            });
        }
    };
    const canRead = (permission) => {
        if (typeof permission === 'string') {
            return (currentWsPermissions === null || currentWsPermissions === void 0 ? void 0 : currentWsPermissions.includes(`${permission}_read`)) || (currentWsPermissions === null || currentWsPermissions === void 0 ? void 0 : currentWsPermissions.includes(`${permission}_write`));
        }
        else {
            return currentWsPermissions === null || currentWsPermissions === void 0 ? void 0 : currentWsPermissions.some((wsPermission) => {
                return Object.values(permission).some((permissionItem) => {
                    return wsPermission === `${permissionItem}_read` || wsPermission === `${permissionItem}_write`;
                });
            });
        }
    };
    const canWrite = (permission) => {
        if (typeof permission === 'string') {
            return currentWsPermissions === null || currentWsPermissions === void 0 ? void 0 : currentWsPermissions.includes(`${permission}_write`);
        }
        else {
            return currentWsPermissions === null || currentWsPermissions === void 0 ? void 0 : currentWsPermissions.some((wsPermission) => {
                return Object.values(permission).some((permissionItem) => {
                    return wsPermission === `${permissionItem}_write`;
                });
            });
        }
    };
    const hasWritePermission = !!defaultPermission && canWrite(defaultPermission);
    return ({
        canRead,
        canWrite,
        hasWritePermission,
        orgCanAccess,
    });
};
exports.useAccessRole = useAccessRole;
