"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeCardFooter = void 0;
const ui_1 = require("@app/component/ui");
const util_1 = require("@app/util");
const react_1 = __importStar(require("react"));
const employee_card_context_1 = require("../util/employee-card-context");
const EmployeeCardFooter = () => {
    var _a, _b;
    const { employee, setFooterExtraContentTarget } = (0, employee_card_context_1.useEmployeeCard)();
    const extraFooterContent = (0, react_1.useRef)(null);
    react_1.default.useEffect(() => {
        setFooterExtraContentTarget(extraFooterContent.current);
    }, []);
    return (react_1.default.createElement("div", { style: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        } },
        (0, util_1.isDefined)((_a = employee === null || employee === void 0 ? void 0 : employee.user) === null || _a === void 0 ? void 0 : _a.id) ? (react_1.default.createElement(ui_1.Text, { type: ui_1.Text.TYPES.BODY_SMALL },
            "DS ID:",
            react_1.default.createElement("span", { style: { cursor: 'pointer' }, onClick: () => { var _a; return util_1.miscUtil.copyToClipBoard((_a = employee === null || employee === void 0 ? void 0 : employee.user) === null || _a === void 0 ? void 0 : _a.id); } }, (_b = employee === null || employee === void 0 ? void 0 : employee.user) === null || _b === void 0 ? void 0 : _b.id))) : (react_1.default.createElement("div", null)),
        react_1.default.createElement("div", { ref: extraFooterContent })));
};
exports.EmployeeCardFooter = EmployeeCardFooter;
