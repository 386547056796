function getValue (key, props, form, def = {}) {
  if (form[key] !== null) {
    return form[key]
  }

  if (!props || props[key] === null) {
    if (def[key]) return def[key]
    return null
  }

  return props[key]
}

function isChanged (key, props, form) {
  if (!form[key]) return false
  if (!props) {
    return !!form[key]
  }
  if (props[key] === form[key]) {
    return false
  }
  return true
}

function isChangedDays (key, props, form) {
  if (!form[key]) return false
  if (!props) {
    return !!form[key]
  }
  if (props[key].length !== form[key].length) {
    return true
  }
  const matches = props[key].map((pd) => !!form[key].find((fd) => fd === pd))
  return matches.length !== props[key].length
}

export default {
  getValue,
  isChanged,
  isChangedDays
}
