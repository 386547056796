import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  setOrganizationId,
  setWorkspaceId,
  setEnforcedLocality,
  setModal,
  getOrganization,
  workspaceSwitch
} from '@app/ac'

const mapStateToProps = ({ auth, workspaces, workspaceId, me, organizationId, loading }) => {
  return {
    auth,
    organizationId,
    me,
    workspaces,
    workspaceId,
    isLoading: loading.includes('setting-timezone') ||
      loading.includes('me') ||
      loading.includes('workspaces') ||
      loading.includes('workspace-switch') ||
      loading.includes('ws')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setOrganizationId,
    setWorkspaceId,
    setEnforcedLocality,
    setModal,
    getOrganization,
    workspaceSwitch
  }, dispatch)
}

export default (component) => {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
