import store from '../store'
import isLoading from './is-loading'
import requestShiftTemplateUpdate from '../request/shift-template-update'
import requestShiftTemplateDelete from '../request/shift-template-delete'
import addError from '../ac/add-error'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'

export default (templateId, shiftData, templateName) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId || !templateId) return

    dispatch(isLoading('update-shift-template'))

    let result
    if (!shiftData) {
      // deletion
      result = await requestShiftTemplateDelete({
        workspace: workspaceId,
        id: templateId
      }, auth)
    } else {
      // update
      const update = Object.assign({}, shiftData)
      if (typeof update.period === typeof undefined) delete update.period
      if (typeof update.positionId === typeof undefined) delete update.positionId
      if (typeof update.localityId === typeof undefined) delete update.localityId
      if (typeof update.idealSkill === typeof undefined) delete update.idealSkill
      if (typeof update.pauses === typeof undefined) delete update.pauses
      if (typeof update.pausesFixed === typeof undefined) delete update.pausesFixed
      if (typeof update.overTime === typeof undefined) delete update.overTime
      if (typeof update.standBy === typeof undefined) delete update.standBy
      if (typeof update.standByActivities === typeof undefined) delete update.standByActivities
      if (typeof update.customAttributes === typeof undefined) delete update.customAttributes
      if (typeof update.note === typeof undefined) delete update.note
      if (typeof update.agenda === typeof undefined) delete update.agenda

      result = await requestShiftTemplateUpdate({
        workspace: workspaceId,
        name: templateName,
        id: templateId,
        data: {
          period: update.period,
          position: update.positionId,
          locality: update.localityId,
          idealSkill: update.idealSkill,
          pauses: update.pauses,
          pausesFixed: update.pausesFixed,
          overTime: update.overTime,
          standBy: update.standBy,
          standByActivities: update.standByActivities,
          customAttributes: update.customAttributes,
          note: update ? update.note : null,
          agenda: update.agenda
        }
      }, auth)
    }

    if (result && result.error) {
      dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('update-shift-template', true))
  }
}
