import store from '../store'
import isLoading from './is-loading'
import requestPositionCreate from '../request/position-create'
import requestPositionUpdate from '../request/position-update'
import {
  requestPositionAssign,
  requestPositionAssignDelete
} from '../request'
import addFresh from './add-fresh'
import addError from './add-error'
import loadPositions from './load-positions'
import moment from 'moment'

export default (id, form) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    if (!workspaceId) return

    dispatch(isLoading('save-position'))
    const { name, color, productionResourceType, assigns, unAssigns } = form
    const params = { workspace: workspaceId }
    if (form.name) {
      params.name = form.name
    }
    if (form.color) {
      params.color = form.color
    }
    if (typeof form.productionResourceType !== typeof undefined) {
      params.productionResourceType = form.productionResourceType
    }

    let result = null
    if (!id) {
      result = await requestPositionCreate(params, auth)
      id = result.id
      if (!result.error) {
        dispatch(addFresh('positions', id))
      }
    }

    // update position's data like color, name or productionResourceType
    if (id && (color || name || (typeof productionResourceType !== typeof undefined))) {
      params.id = id
      result = await requestPositionUpdate(params, auth)
    }
    if (result && result.error) {
      dispatch(addError(result.error))
    }

    // reload positions if something changed, since we're not subscribed to this via WSS
    if (result && !result.error) {
      await dispatch(loadPositions())
    }

    // assign people
    const { employees } = store.getState()
    if (Array.isArray(assigns) && assigns.length) {
      for (const userId of assigns) {
        const ee = employees[userId]
        if (!ee) return
        const activeContracts = (ee.contracts || []).filter(c => !((c.period?.start && moment(c.period.start).isAfter(moment())) || (c.period?.end && moment(c.period.end).isBefore(moment()))))
        for (const con of activeContracts) {
          await requestPositionAssign({
            workspace: workspaceId,
            position: id,
            user: userId,
            contract: con.id
          }, auth)
        }
      }
    }

    // unassign people
    if (Array.isArray(unAssigns) && unAssigns.length) {
      for (const userId of unAssigns) {
        const ee = employees[userId]
        if (!ee) return
        const activeContracts = (ee.contracts || []).filter(c => !((c.period?.start && moment(c.period.start).isAfter(moment())) || (c.period?.end && moment(c.period.end).isBefore(moment()))))
        for (const con of activeContracts) {
          await requestPositionAssignDelete({
            workspace: workspaceId,
            position: id,
            user: userId,
            contract: con.id
          }, auth)
        }
      }
    }

    dispatch(isLoading('save-position', true))
    return result
  }
}
